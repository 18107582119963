import 'react-datepicker/dist/react-datepicker.css'

import { useMask } from '@react-input/mask'
import { format, isValid, parse } from 'date-fns'
import React from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { useWindowSize } from 'usehooks-ts'

import { Icon2 } from '../icon2'
import * as Styled from './styled'

const dateFormat = 'dd/LL/yyyy'

const formatDate = (date?: Date | null): string =>
  date ? format(date, dateFormat) : ''
const parseDate = (dateString: string): Date | null => {
  if (dateString.length !== 10) {
    return null
  }

  const date = parse(dateString, dateFormat, new Date())

  return isValid(date) ? date : null
}

export const DatePicker = (props: ReactDatePickerProps) => {
  const { width } = useWindowSize()
  const isMobileMode = width < 600

  const [startInputValue, setStartInputValue] = React.useState(
    formatDate(props.startDate),
  )
  const [endInputValue, setEndInputValue] = React.useState(
    formatDate(props.endDate),
  )

  const [startDate, setStartDate] = React.useState(props.startDate)
  const [endDate, setEndDate] = React.useState(props.endDate)


  const mask = {
    mask: '__/__/____',
    replacement: { _: /\d/ },
    separate: true
  }

  const fromDateRef = useMask(mask)
  const toDateRef = useMask(mask)

  React.useEffect(() => {
    setStartDate(props.startDate)
    setStartInputValue(formatDate(props.startDate))
  }, [props.startDate])

  React.useEffect(() => {
    setEndDate(props.endDate)
    setEndInputValue(formatDate(props.endDate))
  }, [props.endDate])

  React.useEffect(() => {
    setStartDate(parseDate(startInputValue))
  }, [startInputValue])

  React.useEffect(() => {
    setEndDate(parseDate(endInputValue))
  }, [endInputValue])

  React.useEffect(() => {
    // @ts-expect-error
    props.onChange([startDate, endDate])
  }, [startDate, endDate])

  return (
    <Styled.PickerWrapper>
      <Styled.Row>
        <Styled.InputWrapper
          value={startInputValue}
          ref={fromDateRef}
          label="From"
          suffix={isMobileMode ? undefined : <Icon2 name="calendar" />}
          placeholder="DD/MM/YYYY"
          onChange={(event) => setStartInputValue(event.target.value)}
        />
        <Styled.InputWrapper
          value={endInputValue}
          ref={toDateRef}
          label="To"
          suffix={isMobileMode ? undefined : <Icon2 name="calendar" />}
          placeholder="DD/MM/YYYY"
          onChange={(event) => setEndInputValue(event.target.value)}
        />
      </Styled.Row>
      <ReactDatePicker
        {...props}
        inline
        disabledKeyboardNavigation={props.selectsRange}
        startDate={startDate}
        endDate={endDate}
      />
    </Styled.PickerWrapper>
  )
}
