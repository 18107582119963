import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { PartnerWidget } from './types';


export const PARTNER_WIDGET_KEY = 'partner-widget';

const getPartnerPage = async () => {
  const res = await axios.get<PartnerWidget>(`/ib/v1/partner/widget`);
  return res.data;
};

interface Options<T> {
  select?: (data: PartnerWidget) => T;
}


export const useGetPartnerPage = <T=PartnerWidget>(options?: Options<T>) => {
  return useQuery({
    queryKey: [PARTNER_WIDGET_KEY],
    queryFn: getPartnerPage,
    // refetchOnWindowFocus: false,
    staleTime: 1000,
    select: options?.select,
  })
};
