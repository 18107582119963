import { Text } from 'ui/atoms'

import logo from './images/nova-logo.svg'
import * as Styled from './styled'

export const NovaActivity = ({ onClose }: { onClose?: () => void }) => {
  return (
    <Styled.Wrapper>
      {onClose ? <Styled.CloseButton onClick={onClose} /> : null}
      <Styled.Link href="https://hwnova.site/" target='_blank'>
        <Styled.Logo src={logo} width="60" height="15" alt="Nova" />
        <Text level={3} weight="medium">
          Meet the new Headway product
        </Text>
      </Styled.Link>
    </Styled.Wrapper>
  )
}
