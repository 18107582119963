import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProcessingProvider } from 'services/money/get/types';

import { WithdrawalOtp } from '../../withdrawal/molecules';
import { setOtpFormEvent } from './model';
import { useSbmWithdrawal } from './hooks/useSbmWithdrawal';

interface Props {
  getValues: () => Record<string, any>;
  triggerFn: () => Promise<boolean>;
  gatewayProvider: ProcessingProvider;
}

export const WithdrawalOtpForm: React.FC<Props> = ({ getValues, triggerFn, gatewayProvider }) => {
  const [isOtpInvalid, setIsOtpInvalid] = useState(false);

  const { onCreateWithdrawal, isLoading, isError} = useSbmWithdrawal({ gatewayProvider });

  const onResendCodeFn = async () => {
    try {
      const fields = getValues();

      setIsOtpInvalid(false);
      const resp = await onCreateWithdrawal({
        ...fields,
      });

      if (!resp) return;
    } catch (error) {
      
    }
  }

  const onCheckCode = async (code: string) => {
    try {
      const fields = getValues();
      const isValidData = await triggerFn();

      if (!isValidData) return;

      setIsOtpInvalid(false);
      const resp = await onCreateWithdrawal({
        ...fields,
        code
      });

      if (!resp) return;

      if (code && resp.id === '0') {
        setIsOtpInvalid(true);
        return;
      }

      // if (resp.id) {
      //   console.log(fields, resp);
      //   navigate({
      //     pathname: `/${path.money.resultWithdrawal()}`,
      //     search: `?status=pending&amount=${fields?.amount}&id=${resp.id}&currency=${fields?.currency}&isResultAmount=true`
      //   })
      // }

    } catch (error) {
      
    }
  }


  return (
    <WithdrawalOtp
      onBack={() => setOtpFormEvent(false)}
      // @ts-ignore
      onResendCode={ onResendCodeFn }
      isCodeError={ isError || isOtpInvalid }
      onCheckCode={onCheckCode}
      isLoading={isLoading}
  /> 
  )
}
