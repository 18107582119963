import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { AccountPlatform, AccountType } from '../types'

export const GET_TARIFFS_LIMITS_KEY = ['tariffs-limits']

const getTariffsLimits = async (accountType: AccountType) => {
  const res = await axios.get<{
    availableByTariffs: {
      accountPlatform: AccountPlatform
      name: string
      numAvailable: number
    }[]
    isPossibleCreateAccount: boolean
  }>('/account/v1/tariffs-limits', { params: { accountType } })

  return res.data
}

export const useGetTariffsLimits = (
  accountType: AccountType,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: GET_TARIFFS_LIMITS_KEY,
    queryFn: () => getTariffsLimits(accountType),
    enabled,
  })
}
