import React from 'react'
import { GiftHistoryItemStatuses } from 'services/giftshop/get/types'

import * as S from './styled'

interface Props {
  status: GiftHistoryItemStatuses
}

const statusLables: Record<GiftHistoryItemStatuses, string> = {
  CANCELED: 'Canceled',
  CREDITED: 'Credited',
  IN_PROGRESS: 'In progress',
  NEW: 'New',
  MANUAL: 'Manual',
  SENT: 'Sent',
  REVISED: 'Revised',
  BONUS: 'Bonus',
}

export const TableStatus: React.FC<Props> = ({ status }) => {
  const label = statusLables[status] || status
  const isCanceled = status === 'CANCELED'

  return (
    <S.GiftStatus
      level={2}
      weight={isCanceled ? 'medium' : 'regular'}
      isCanceled={isCanceled}
    >
      {label}
    </S.GiftStatus>
  )
}
