import React from 'react';
import { RegisterOptions, useForm, useFormContext } from 'react-hook-form';

import { WithdrawalFormListItem } from 'services/payments/get/WithdrawalFormTypes';
import { FormInput } from 'ui/molecules';

type Props = {
  formFields?: WithdrawalFormListItem[];
}

export const CoinPayment: React.FC<Props> = ({ formFields = [] }) => {
  const { register, formState: { errors, dirtyFields } } = useFormContext();

  const getFieldItem = (item: WithdrawalFormListItem) => {
    if (item.type === 'FIELD_TYPE_AMOUNT') return null;

    if (item.type === 'FIELD_TYPE_TEXT') {
      const registerFields: RegisterOptions = {
        required: 'Complete this field.',
      };
      
      // TODO на беке и на фронте будут доработки по валидации
      // item.validators.some(validItem => {
      //   if (validItem.type === 'VALIDATOR_TYPE_JS_REGEXP') {
      //     registerFields.pattern = { 
      //       value: new RegExp(validItem.jsRegexpValidatorParams?.pattern)!,
      //       message: validItem.validationErrorMessage || '',
      //     }
      //     return true;
      //   }
      // });

      return (<FormInput
        {...register(item.name, registerFields)}
        key={item.name}
        disabled={false}
        // inputClasses='lg:!w-[440px] w-full'
        className='lg:!w-[440px] w-full'
        errorMessage={ errors?.[item.name]?.message as string }
        placeholder={item.title || ''}
        maxLength={Number(item.params.FIELD_TYPE_TEXT?.maxLength) || undefined}
      />)
    }


    return null;
  };
  
  if (!formFields) return;

  return (
    <div className='mt-[48px] flex flex-col gap-[16px] w-full items-center'>
      {
        formFields.map(fieldItem => getFieldItem(fieldItem))
      }
    </div>
  )
}
