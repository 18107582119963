import React, { useMemo, useState } from 'react';

import { ThreeLevelBanner } from './ThreeLevelBanner';
import { CashBoosterBanner } from './CashBoosterBanner';
import { deleteCarouselItemEvent } from 'ui/molecules/carousel/model';

import { Carousel } from 'ui/molecules/carousel';


export const BannersSlider = () => {
  const isThreeBanner = localStorage.getItem('IB_BANNER');
  const isBoosterBanner = localStorage.getItem('IB_BANNER_CASH_BOOSTER');

  const [ibBanner, setIbBanner] = useState(!isThreeBanner);
  const [cashBooster, setCashBooster] = useState(!isBoosterBanner);

  const closeIbBanner = () => { 
    setIbBanner(false);
    deleteCarouselItemEvent();
  }

  const closeCashBooster = () => { 
    setCashBooster(false);
    deleteCarouselItemEvent();
  }

  const bannersList = useMemo(() => {
    const banners: any[] = [];
    if (ibBanner) {
      banners.push(
        <ThreeLevelBanner onClose={closeIbBanner} key='ibBanner' />,
      );
    }

    if (cashBooster) {
      banners.push(
        <CashBoosterBanner onClose={closeCashBooster} key={'cashBooster'} />
      );
    }

    return banners;
  }, [ibBanner, cashBooster]);

  if (!bannersList?.length) return null;

  return (
    <div className='mt-[2rem] scrollbar-hide'>
        <Carousel autoPlay interval={20000}>
          {bannersList?.map((item, index) => (
            item
          ))}
        </Carousel>
    </div>
  )
}
