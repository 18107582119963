const {
  NODE_ENV,
  REACT_APP_VERSION = '',
  REACT_APP_API_BASEURL = '',
  REACT_APP_CAPTCHA_INVISIBLE_SITE_KEY = '',
  REACT_APP_CAPTCHA_NOT_A_ROBOT_SITE_KEY = '',
  REACT_APP_GOOGLE_CLIENT_ID = '',
  REACT_APP_FACEBOOK_APP_ID = '',
  REACT_APP_FACEBOOK_APP_VERSION = '',
  REACT_APP_CROWDIN_API = '',
  REACT_APP_GOOGLE_CLIENT_SECRET = '',
} = process.env

export const env = {
  isDev: NODE_ENV !== 'production',
  baseUrl: REACT_APP_API_BASEURL,
  appVersion: REACT_APP_VERSION,
  captchaInvisibleKey: REACT_APP_CAPTCHA_INVISIBLE_SITE_KEY,
  captchaNotRobotKey: REACT_APP_CAPTCHA_NOT_A_ROBOT_SITE_KEY,
  crowdinApi: REACT_APP_CROWDIN_API,
  google: {
    clientId: REACT_APP_GOOGLE_CLIENT_ID,
    clientSecret: REACT_APP_GOOGLE_CLIENT_SECRET,
  },
  facebook: {
    appId: REACT_APP_FACEBOOK_APP_ID,
    appVersion: REACT_APP_FACEBOOK_APP_VERSION,
  },
}

export const SITE_EMAIL = 'feedback@hw.site'
