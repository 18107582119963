import styled from 'styled-components/macro'

export const Label = styled.label`
  margin-bottom: 4px;
  display: inline-block;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
`

export const TextArea = styled.textarea<{ isError?: boolean }>`
  border: ${({ isError }) =>
    isError ? '1px solid var(--color-opal-600);' : '1px solid var(--color-gray-400)'};
  padding: 16px 12px;
  width: 100%;
  min-height: 148px;
  display: block;
  font: inherit;
  transition: border-color var(--transition), background-color var(--transition);
  border-radius: 8px;
  resize: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    background-color: var(--color-gray-200);
  }

  &:focus {
    border-color: var(--color-sunray);
  }
`

export const Error = styled.div`
  font-size: var(--body-font-size-medium);
  line-height: 1;
  color: var(--color-opal-600);
  margin-top: 6px;
`