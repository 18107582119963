import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '@uidotdev/usehooks';

import { path } from 'libs/path';
import { accountChoosed } from 'pages/money/depositChoice/model';
import { useGetExchangerWallets } from 'services/wallet/get/useGetExchangerWallets';
import { Button, Icon2, Title, Text } from 'ui/atoms';

import { MoneyHistory } from './molecules/moneyHistory';
import * as S from './styled'

export const ExchangerArea = () => {
  const [t] = useTranslation();
  const nav = useNavigate();

  const { width } = useWindowSize()
  const isMobileMode = width && width < 600

  const { data, isLoading } = useGetExchangerWallets({ noRefetch: true })

  const wallet = useMemo(() => {
    return data?.items[0]
  }, [data])

  const goToDeposits = () => {
    accountChoosed(wallet?.exchangerWalletID!);
    nav(path.money.depositTab())
  };

  const goToWithdrawals = () => {
    accountChoosed(wallet?.exchangerWalletID!);
    nav(path.money.withdrawalTab())
  };

  return (
    <>
      <S.Wrapper>
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <Title level={isMobileMode ? 4 : 3}>Exchanger wallet</Title>
            <Icon2 name="wallet" className="text-sunray.500" />
          </div>
          <div className='flex items-center'>
            <Text
              withTranslate={false}
              level={1}
              weight='medium'
              color='subtext'
            >
              {wallet?.name}
            </Text>
            {wallet?.gatewayLogo && 
              <img src={wallet.gatewayLogo} alt='gateway_logo' className='w-[32px] h-[32px] rounded-full ml-[12px]' />
            }
          </div>
        </div>
        <S.AmountLabel>{t('Amount')}</S.AmountLabel>
        <S.BottomContainer>
          <Title level={isMobileMode ? 2 : 1} withTranslate={false}>
            ${wallet?.balance}
          </Title>
          <S.WalletActions>
            <Button
              size="medium"
              design="tertiary-brand"
              prefix={<Icon2 name="arrowUpOnSquare" />}
              onClick={goToWithdrawals}
            >
              Withdraw
            </Button>
            <Button
              size="medium"
              design="tertiary-brand"
              prefix={<Icon2 name="arrowDownOnSquare" />}
              onClick={goToDeposits}
            >
              Deposit
            </Button>
            <S.TransferButton
              size="medium"
              prefix={<Icon2 name="arrowRightCircle" />}
              to={path.exchanger.transfer()}
              state={{ amount: wallet?.balance, id: wallet?.exchangerWalletID }}
            >
              {t('Transfer to client')}
            </S.TransferButton>
          </S.WalletActions>
        </S.BottomContainer>
      </S.Wrapper>

      <S.Wrapper className="mt-[2rem]">
        <MoneyHistory data={data} isLoading={isLoading} />
      </S.Wrapper>
    </>
  )
}
