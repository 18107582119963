import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'

import { toPath } from '../../../../features/router'
import { path } from '../../../../libs'
import { Button, RadioSingle, Tag, Title } from '../../../../ui/atoms'
import { setRecordedAccountData } from '../model'
import { useAccountCreatePlatform } from './hooks'
import check from './images/check.svg'
import cross from './images/cross.svg'
import MT4logo from './images/logo-mt4.svg'
import MT5logo from './images/logo-mt5.svg'
import { setActivePlatform } from './model'

const MT = {
  PAAP_MT5: {
    logo: MT5logo,
    timeframes: 21,
    analitycs: 'yes',
    options: ['Forex', 'Crypto', 'Commodities', 'Stocks', 'Indicies'],
  },
  PAAP_MT4: {
    logo: MT4logo,
    timeframes: 6,
    analitycs: 'no',
    options: ['Forex', 'Crypto', 'Commodities'],
  },
  options: ['Forex', 'Crypto', 'Commodities', 'Stocks', 'Indicies'],
}

export const AccountsCreateChoosePlatform = () => {
  const [t] = useTranslation()

  const {
    //
    activePlatforms,
    activePlatform,
    accountType,
    recordedAccountData,
  } = useAccountCreatePlatform()

  return (
    <>
      <AccountPlatforms>
        <Title level={1}>Choose Trading Platform</Title>
        <AccountPlatformsList>
          {activePlatforms.map((platform, key) => {
            const { accountPlatform, isDisabled, disableReason } = platform
            const isReccomend = accountPlatform === 'PAAP_MT5'
            const isActive = accountPlatform === activePlatform

            return (
              <DisableContainer isDisabled={isDisabled} key={key}>
                <AccountPlatform
                  isActive={isActive}
                  onClick={() => {
                    setActivePlatform(accountPlatform)

                    if (recordedAccountData.activePlatform) {
                      setRecordedAccountData({
                        ...recordedAccountData,
                        activePlatform: accountPlatform,
                      })
                    }
                  }}
                >
                  {isReccomend && (
                    <ReccommendTag design="sunray-dark">
                      {t('Recommended')}
                    </ReccommendTag>
                  )}
                  <PlatformState>
                    <RadioSingle value="" checked={isActive} size="large" />
                  </PlatformState>
                  <div className="w-full">
                    <AccountPlatformLogo src={MT[accountPlatform].logo} />
                    <AccountPlatformDetails>
                      <AccountPlatformDetail>
                        <span>{t('Timeframes')}</span>
                        <span>{MT[accountPlatform].timeframes}</span>
                      </AccountPlatformDetail>
                      <AccountPlatformDetail>
                        <span>{t('Advanced analytics')}</span>
                        <span>{MT[accountPlatform].analitycs}</span>
                      </AccountPlatformDetail>
                    </AccountPlatformDetails>
                  </div>
                </AccountPlatform>
                {isDisabled && <DisableWarning>{disableReason}</DisableWarning>}
              </DisableContainer>
            )
          })}
        </AccountPlatformsList>
      </AccountPlatforms>
      <AccountPlatformOptions>
        {MT.options.map((name, key) => (
          <AccountPlatformOption
            key={key}
            checked={MT[activePlatform]?.options.includes(name)}
          >
            {t(name)}
          </AccountPlatformOption>
        ))}
      </AccountPlatformOptions>
      <AccountCreateNextButton
        size="medium"
        name="account-create-to-platform"
        onClick={() => {
          setRecordedAccountData({ ...recordedAccountData, activePlatform })
          toPath(path.accounts.create.settings(accountType))
        }}
        disabled={
          activePlatform !== 'PAAP_MT4' && activePlatform !== 'PAAP_MT5'
        }
      >
        Next
      </AccountCreateNextButton>
    </>
  )
}

const AccountPlatforms = styled.div`
  margin-top: 60px;
  margin-bottom: 40px;
`

const AccountPlatformsList = styled.div`
  margin-top: 60px;
  display: flex;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

type BlockProps = {
  isActive: boolean
}

const AccountPlatform = styled.div<BlockProps>`
  cursor: pointer;
  position: relative;
  border: 1px solid var(--color-gray-300);
  padding: 20px;
  display: flex;
  align-items: center;
  transition: border var(--transition);
  border-radius: 8px;

  &:hover {
    border-color: var(--color-sunray-500);
  }

  ${(p) =>
    p.isActive &&
    css`
      outline: 1px solid var(--color-sunray-500);
      border-color: var(--color-sunray-500);
    `}
`

const PlatformState = styled.div`
  margin-inline-end: 10px;
`

const AccountCreateNextButton = styled(Button)`
  margin-top: auto;
  margin-inline-start: auto;
  min-width: 260px;

  @media (max-width: 600px) {
    width: 100%;
  }
`

const AccountPlatformLogo = styled.div<{ src: string }>`
  width: 158px;
  height: 30px;
  background-image: url(${(p) => p.src});
  background-repeat: no-repeat;
  background-size: contain;
`

const AccountPlatformDetails = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  text-transform: capitalize;

  span:last-child {
    font-weight: 500;
    max-width: 110px;
    text-align: end;
  }
`

const AccountPlatformDetail = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

const AccountPlatformOptions = styled.div`
  display: grid;
  gap: 24px;
  margin-inline-start: 20px;
  font-weight: 500;
  font-size: var(--body-font-size-medium);

  @media (max-width: 600px) {
    margin-bottom: 32px;
  }

  label {
    align-items: center;
  }
`

const AccountPlatformOption = styled.label<{checked: boolean}>`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    margin-inline-end: 8px;
    width: 20px;
    height: 20px;
    background-image: url(${props => props.checked ? check : cross});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
`

const DisableContainer = styled.div<{ isDisabled: boolean }>`
  cursor: pointer;
  width: 352px;

  @media (max-width: 600px) {
    width: 100%;
  }

  ${(p) => p.isDisabled && `cursor: no-drop;`} ${AccountPlatform} {
    ${(p) =>
      p.isDisabled &&
      `
      pointer-events: none;
      opacity: 0.7;
    `}
  }
`

const DisableWarning = styled.div`
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);
  padding: 8px;
  cursor: auto;

  a {
    color: var(--color-azure-500);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

const ReccommendTag = styled(Tag)`
  position: absolute;
  top: -10px;
  inset-inline-end: 10px;
  font-weight: 500;
  line-height: 0;

  &[data-design='sunray-dark'] {
    font-size: var(--body-font-size-small);
  }
`
