import { RiskLevel } from 'services/copytrade/types'

export const riskLabelMap: { [k in RiskLevel]?: string } = {
  PASR_LOW: 'Low',
  PASR_MEDIUM: 'Medium',
  PASR_HIGH: 'High',
}

export const colorSchemeMap: {
  [k in RiskLevel]?: { fontColor: string; backgroundColor: string }
} = {
  PASR_LOW: {
    fontColor: 'var(--color-eucalyptus-700)',
    backgroundColor: 'var(--color-eucalyptus-100)',
  },
  PASR_MEDIUM: {
    fontColor: 'var(--color-orange-800)',
    backgroundColor: 'var(--color-orange-100)',
  },
  PASR_HIGH: {
    fontColor: 'var(--color-opal-600)',
    backgroundColor: 'var(--color-opal-100)',
  },
}
