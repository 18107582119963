import styled, {css} from 'styled-components/macro'

export const Container = styled.div`
  display: grid;
  gap: 8px;
`

export const LineWrapper = styled.div``

export const Line = styled.div<{
  progress: number
  type: 'profit' | 'orders'
  isRounded?: boolean
}>`
  position: relative;
  height: 12px;
  background-color: var(--color-opal-500);

  ${({ isRounded }) => isRounded && css`
    border-radius: 3px;
  `}

  &::before {
    content: '';
    position: absolute;
    width: ${({ progress }) => progress}%;
    height: 100%;
    background-color: var(--color-eucalyptus-500);
  }
`

export const ParamWrapper = styled.div`
  display: flex;
  align-items: baseline;
  height: 9px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-tiny);
  font-weight: 500;
`

export const ParamTitle = styled.span`
  margin-inline-start: 4px;
  font-size: var(--body-font-size-tiny);
  line-height: var(--body-line-height-tiny);
  color: var(--color-gray-600);
`
