import Tippy from '@tippyjs/react'
import { Trans, useTranslation } from 'react-i18next'

import { icons } from '../../images/icons'
import * as Styled from './styled'

export const StatisticsTable = ({
  className,
  place,
  points,
  lotsTraded,
  pointsInfo,
  isResult = false,
}: {
  className?: string
  place: number
  points: string
  lotsTraded: string
  pointsInfo?: {
    fromNewTraders: string
    fromOldTraders: string
  } | null
  isResult?: boolean
}) => {
  const [t] = useTranslation()

  return (
    <Styled.Wrapper
      className={className}
      isResult={isResult}
      isWinner={place > 0 && place <= 10}
    >
      <Styled.PlaceCell>
        <Styled.Title>{t('Your place')}</Styled.Title>
        {place > 10 || place === 0 || !isResult ? (
          <Styled.PlaceMedal>{place}</Styled.PlaceMedal>
        ) : (
          <Styled.MedalImage src={icons[`largeMedal_${place}`]} />
        )}
      </Styled.PlaceCell>
      <Styled.PointsWrapper>
        <div className="flex items-center justify-center gap-[4px]">
          <Styled.Title>{t('Points')}</Styled.Title>
          {!!pointsInfo && (
            <Tippy
              content={
                <>
                  <Trans
                    t={t}
                    i18nKey="From new traders: 8000"
                    values={{ value: pointsInfo.fromNewTraders }}
                    defaults={'From new traders: {{value}}'}
                  />
                  <br />
                  <Trans
                    t={t}
                    i18nKey="From old traders: 854"
                    values={{
                      value: pointsInfo.fromOldTraders,
                    }}
                    defaults={'From old traders: {{value}}'}
                  />
                </>
              }
            >
              <Styled.Hint />
            </Tippy>
          )}
        </div>
        <Styled.PointsAmount>{points}</Styled.PointsAmount>
      </Styled.PointsWrapper>
      <Styled.LotsTraded>
        <Styled.Title>{t('Lots traded')}</Styled.Title>
        {lotsTraded}
      </Styled.LotsTraded>
    </Styled.Wrapper>
  )
}
