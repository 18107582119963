import styled from 'styled-components/macro'

import { accordionArrow, errorIcon, rightArrow } from 'styles/img'
import { statusColors, statusIcons } from 'pages/money/transactionsNew/utils/constants';


export const Wrapper = styled.div`
  cursor: pointer;
  border: 1px solid var(--color-gray-300);
  border-radius: 8px;
`

export const VisibleInfo = styled.div<{ opened: boolean }>`
  position: relative;
  padding-top: 16px;
  padding-inline-end: 64px;
  padding-bottom: 16px;
  padding-inline-start: 12px;
  display: flex;
  align-items: center;

  @media (max-width: 1199px) {
    padding-top: 12px;
    padding-inline-end: 44px;
    padding-bottom: 12px;
    padding-inline-start: 12px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    inset-inline-end: 22px;
    width: 20px;
    height: 20px;
    transform: ${(props) =>
      props.opened ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)'};
    transition: transform 0.3s;
    background-image: url(${accordionArrow});
    background-size: contain;
    background-position: center center;

    @media (max-width: 1199px) {
      inset-inline-end: 12px;
    }
  }
`

export const LeftIcon = styled.img`
  margin-inline-end: 12px;
  width: 32px;
  height: auto;
  display: block;
`

export const InfoGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 8px;
  font-size: var(--body-font-size-medium);
  font-weight: 500;

  @media (max-width: 1199px) {
    gap: 4px;
  }
`

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 1199px) {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr minmax(56px, 1fr);
    gap: 4px 12px;
    align-items: start;
  }
`

export const RightAlign = styled.span`
  text-align: end;

  @media (max-width: 1199px) {
    word-break: break-all;
  }
`

export const TransferArrow = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${rightArrow});
  background-size: contain;
  background-position: center center;
`

export const LightText = styled.span`
  margin-inline-end: 8px;
  color: var(--color-gray-600);
  font-weight: 400;
`

export const HiddenInfo = styled.div`
  cursor: default;
  position: relative;
  padding: 0 12px 16px;
  padding-top: 24px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    inset-inline-start: 12px;
    width: calc(100% - 24px);
    height: 1px;
    background-color: var(--color-gray-300);
  }
`

export const DetailsGrid = styled.div`
  display: grid;
  gap: 8px;

  @media (max-width: 600px) {
    gap: 0;
  }
`

export const DetailsRow = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    font-size: var(--body-font-size-medium);
  }
`

export const DetailsTitle = styled.div`
  color: var(--color-gray-600);
`

export const DetailsValue = styled.div`
  font-weight: 500;
  text-align: end;
`

export const Buttons = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(118px, min-content));
  justify-content: end;
  gap: 20px;

  @media (max-width: 600px) {
    margin-top: 24px;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 16px;
  }
`

export const Warning = styled.div<{ error: boolean }>`
  margin-top: 8px;
  border: 1px solid
    ${(props) =>
      props.error ? 'rgba(152, 56, 53, 0.20)' : 'rgba(0, 77, 161, 0.20)'};
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: ${(props) =>
    props.error ? 'var(--color-opal-100)' : 'var(--color-azure-100)'};

  &::before {
    content: '';
    flex-shrink: 0;
    margin-inline-end: 12px;
    width: 24px;
    height: 24px;
    background-image: url(${errorIcon});
    background-repeat: no-repeat;
    background-position: center center;
  }
`

export const Status = styled.div<{ status: string; small?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) =>
    props.small ? statusColors[props.status] : 'var(--color-gray-700)'};

  &::before {
    content: '';
    margin-inline-end: 8px;
    width: 20px;
    height: 20px;
    background-image: ${(props) =>
      props.small ? 'none' : `url(${statusIcons[props.status]})`};
    background-size: contain;
    background-position: center center;
  }
`

export const TypeWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`