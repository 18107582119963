import { useMutation } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';


export type GetDepositLimitsResp = {
  minLimit: string;
  maxLimit: string;
}

export type DepositLimitsReqBody = {
  currency: string;
  gatewayID: string
}



const getWithdrawalLimits = async (data: DepositLimitsReqBody) => {
  const res = await axios.get<GetDepositLimitsResp>(`/payments/v1/withdrawal/gateway-limits`, {
    params: data,
  });

  return res.data;
};

export const useGetWithdrawalLimits = () => {
  return useMutation({
    mutationFn: (data: DepositLimitsReqBody) => getWithdrawalLimits(data),
    retry: retryFn,
  })
};
