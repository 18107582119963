import i18next from 'i18next'
import { useEffect } from 'react'
import {
  useGetGiftList,
  useGetWidgetInfo,
  useTargetGift,
} from 'services/giftshop'
import { useIsMounted } from 'usehooks-ts'

export const useTarget = () => {
  const { refetch: refetchGiftList } = useGetGiftList(false)
  const { refetch: refetchWidgetInfo } = useGetWidgetInfo(false)
  const { mutateAsync, isLoading, isSuccess } = useTargetGift()

  useEffect(() => {
    if (isSuccess) {
      refetchGiftList()
      refetchWidgetInfo()
    }
  }, [isSuccess])

  return {
    mutateAsync,
    isLoading,
  }
}

export const useLanguageChange = (refetch: () => void ) => {
  const isMounted = useIsMounted()

  useEffect(() => {
    i18next.on('languageChanged', () => {
      if (isMounted()) {
        refetch()
      }
    })
  }, [])
}
