import { useStore } from 'effector-react'
import * as React from 'react'
import { ZendeskAPI } from 'react-zendesk'
import styled from 'styled-components/macro'
import { Container, Logo } from 'ui/atoms'
import { Alerts, LanguageSwitcher } from 'ui/molecules'
import { $isGlobalError, setGlobalError } from 'ui/templates/generic/model'

export const AuthHeader = () => {
  const isGlobalError = useStore($isGlobalError)

  return (
    <AuthHeaderWrap>
      <AuthHeaderContainer>
        <Logo size="medium" />
        <AuthHeaderLanguageSwitcher />
        {isGlobalError && (
          <div className="mt-[20px] w-full">
            <Alerts
              type="warning"
              title="Internal server error"
              onClose={() => setGlobalError(false)}
            >
              <div className="text-[16px] font-normal">
                We are fixing it already. Please try again later or{' '}
                <span
                  onClick={() => ZendeskAPI('messenger', 'open')}
                  className="!cursor-pointer !text-orange-500"
                >
                  contact support
                </span>
                .
              </div>
            </Alerts>
          </div>
        )}
      </AuthHeaderContainer>
    </AuthHeaderWrap>
  )
}

const AuthHeaderWrap = styled.div`
  width: 100%;
`

const AuthHeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 32px 32px 40px;

  @media (max-width: 540px) {
    padding: 40px 16px 24px;
  }
`

const AuthHeaderLanguageSwitcher = styled(LanguageSwitcher)`
  margin-inline-start: auto;
`
