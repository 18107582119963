import { AccountTariffName } from 'pages/accounts/create/types'
import { Account, AccountPlatform } from 'services/accounts/types'
import { AccountSourceItem } from 'services/payments/get/types'

export const steps = ['Account', 'Details', 'Depositing']

export const getSourceItems = (data?: Account[]): AccountSourceItem[] => {
  return (
    data?.map((account) => ({
      currency: account.currency,
      balance: `${account.balance}`,
      name: account.name,
      accountName: account.name,
      accountPlatform: account.platform as AccountPlatform,
      accountType: account.tariffName as AccountTariffName,
      id: account.accountID,
      type: 'OT_ACCOUNT',
    })) || []
  )
}
