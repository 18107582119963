import { useInfiniteQuery } from '@tanstack/react-query'
import { useWindowSize } from '@uidotdev/usehooks'
import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoSizer, Grid, InfiniteLoader } from 'react-virtualized'
import {
  GET_ALL_COPYTRADE_STRATEGIES_KEY,
  fetchStrategies,
} from 'services/copytrade/get/useGetAllStrategies'
import { Button, Icon2 } from 'ui/atoms'

import { $strategiesFilters, resetStrategiesFilters } from '../model'
import { TraderCard } from '../molecules/traderCard'
import { TraderFilter } from '../molecules/traderFilter'
import { TraderList } from '../molecules/traderList/TraderList'
import * as Styled from './styled'

export const TradersList = () => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isTabletMode = width && width < 1360
  const isMobileMode = width && width < 600

  const { userID } = useStore($userProfile)

  const strategiesFilters = useStore($strategiesFilters)

  const [isListView, setIsListView] = useState(false)

  // infinite scroll
  const { data, fetchNextPage, hasNextPage, isLoading, refetch } =
    useInfiniteQuery(
      [GET_ALL_COPYTRADE_STRATEGIES_KEY],
      ({ pageParam = 0 }) =>
        fetchStrategies(strategiesFilters, pageParam, !!userID),
      {
        getNextPageParam: (lastPage, allPages) => {
          if (
            allPages.flatMap((page) => page.items).length <
            lastPage.totalRecords
          ) {
            return allPages.length
          }

          return undefined
        },
        enabled: false,
      },
    )

  const strategies = data?.pages.flatMap((page) => page.items) || []

  const loadMoreRows = () => {
    if (hasNextPage) {
      return fetchNextPage()
    }

    return Promise.resolve()
  }

  const getColumnCount = () => (isTabletMode ? 1 : isListView ? 1 : 3)

  const rowCount = isListView
    ? strategies.length
    : Math.ceil(strategies.length / getColumnCount())

  const isRowLoaded = ({ index }: { index: number }) => {
    return !!strategies[index * getColumnCount()]
  }

  const gridGap = isMobileMode ? 12 : 16
  const rowsGap = 8

  useEffect(() => {
    resetStrategiesFilters()
  }, [])

  useEffect(() => {
    refetch()
  }, [strategiesFilters])

  return (
    <Styled.Wrapper>
      <TraderFilter
        currentView={isListView ? 'list' : 'cards'}
        onChangeView={
          isTabletMode ? undefined : (view) => setIsListView(view === 'list')
        }
      />

      {isLoading ? (
        'loading...'
      ) : strategies.length > 0 ? (
        <Styled.InfiniteScroll>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={hasNextPage ? rowCount + 1 : rowCount}
              >
                {({ onRowsRendered, registerChild }) => (
                  <Grid
                    columnWidth={width / getColumnCount()}
                    columnCount={getColumnCount()}
                    height={height}
                    onSectionRendered={({ rowStartIndex, rowStopIndex }) => {
                      onRowsRendered({
                        startIndex: rowStartIndex,
                        stopIndex: rowStopIndex,
                      })
                    }}
                    ref={registerChild}
                    rowCount={rowCount}
                    rowHeight={
                      isMobileMode
                        ? 181 + gridGap
                        : isListView
                        ? 140 + rowsGap
                        : 201 + gridGap
                    }
                    cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                      const paddingRight =
                        columnIndex < getColumnCount() - 1 ? gridGap : 0
                      const paddingBottom = gridGap

                      const index = rowIndex * getColumnCount() + columnIndex
                      const strategy = strategies[index]

                      if (!strategy) {
                        return null
                      }

                      return (
                        <div
                          key={key}
                          style={{
                            ...style,
                            paddingRight,
                            paddingBottom,
                          }}
                        >
                          {!isListView || isTabletMode || isMobileMode ? (
                            <TraderCard {...strategy} />
                          ) : (
                            <TraderList strategy={strategy} />
                          )}
                        </div>
                      )
                    }}
                    width={width}
                    overscanRowCount={5}
                  />
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Styled.InfiniteScroll>
      ) : (
        <Styled.NoResultsWrapper>
          <Styled.NoResultsTitle level={isMobileMode ? 4 : 2}>
            {t('No results found')}
          </Styled.NoResultsTitle>
          <Styled.NoResultsText>
            {t(
              'There are no strategies matching your criteria. Please try to reset the filters.',
            )}
          </Styled.NoResultsText>
          <Button
            name="reset-filters"
            design="secondary"
            size={isMobileMode ? 'small' : 'large'}
            onClick={() => resetStrategiesFilters()}
            prefix={<Icon2 name="XCircleIconOutline" />}
          >
            Reset filters
          </Button>
        </Styled.NoResultsWrapper>
      )}
    </Styled.Wrapper>
  )
}
