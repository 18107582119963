import { useEffect, useState } from 'react'
import { Icon2 } from 'ui/atoms'

import * as Styled from './styled'
import { ModalProps } from './types'

export const Modal = ({
  open,
  closable = true,
  children,
  onClose,
  width = 360,
}: ModalProps) => {
  // для красивой анимации
  const [shouldRender, setShouldRender] = useState(open)

  // для закрытия по нажатию на esc
  const handleEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose()
    }
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
      
      setShouldRender(true)

      window.addEventListener('keydown', handleEsc)

      return () => {
        window.removeEventListener('keydown', handleEsc)
      }
    } else {
      document.body.style.overflow = 'visible'

      // оставляем модальное окно в dom до завершения анимации
      const timer = setTimeout(() => setShouldRender(false), 250)
      return () => clearTimeout(timer)
    }
  }, [open])

  if (!shouldRender) {
    return null
  }

  return (
    <Styled.Overlay isOpen={open} onClick={onClose}>
      <Styled.Content
        onClick={(event) => event.stopPropagation()}
        width={width}
      >
        <Styled.CloseButton onClick={onClose} type="button">
          <Icon2 name="xMarkIcon" />
        </Styled.CloseButton>
        {children}
      </Styled.Content>
    </Styled.Overlay>
  )
}
