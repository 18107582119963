import { useQuery } from '@tanstack/react-query'
import type { Account } from 'services/accounts/types'
import { axios } from 'services/axios'

export const GET_TRADER_COPYABLE_ACCOUNTS_KEY = [
  'copytrade-trader-copyable-accounts',
]

const getTraderCopyableAccounts = async () => {
  const res = await axios.get<{ items: Account[] }>(
    '/copytrade/v1/trader/available-copyable-accounts',
  )

  return res.data.items
}

export const useGetTraderCopyableAccounts = ({
  enabled = true,
}: {
  enabled?: boolean
}) => {
  return useQuery({
    queryKey: GET_TRADER_COPYABLE_ACCOUNTS_KEY,
    queryFn: getTraderCopyableAccounts,
    enabled,
  })
}
