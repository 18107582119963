import Tippy from '@tippyjs/react'
import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAddStrategyToFavorives, useLikeStrategy } from 'services/copytrade'
import { Strategy } from 'services/copytrade/types'

import {
  $strategiesComparisonList,
  addStrategyToCompare,
  removeStrategyFromCompare,
} from '../../model'
import { SignupTooltip } from '../signupTooltip'
import * as Styled from './styled'

export const InteractButtons = ({
  isExtended,
  disabledOptions,
  strategyData,
}: {
  isExtended?: boolean
  disabledOptions?: ('like' | 'compare' | 'favoutites')[]
  strategyData: Strategy
}) => {
  const [t] = useTranslation()

  const { userID } = useStore($userProfile)

  // так как бесконечный список багует при обновлении через setQueryData,
  // меняем состояние кнопки явно через стейт
  const [isSimpleFavorites, setIsSimpleFavorites] = useState(
    strategyData.isFavorites,
  )

  const strategiesComparisonList = useStore($strategiesComparisonList)
  const isComparisonActive = strategiesComparisonList.some(
    (strategy) => strategy.strategyID === strategyData.strategyID,
  )

  const { mutateAsync: likeStrategy, isLoading: isLikeLoading } =
    useLikeStrategy()
  const {
    mutateAsync: addToFavorites,
    isLoading: isFavoritesLoading,
    isSuccess,
  } = useAddStrategyToFavorives()

  useEffect(() => {
    if (isSuccess) {
      setIsSimpleFavorites(!isSimpleFavorites)
    }
  }, [isSuccess])

  return (
    <Styled.Wrapper>
      {isExtended && (
        <>
          {!userID ? (
            <SignupTooltip enabled>
              <Styled.ExtendedButton type="button" isDisabled>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.49281 18.5C7.06823 18.5 6.67296 18.2635 6.51759 17.8684C6.18349 17.0187 6 16.0933 6 15.125C6 13.3759 6.59874 11.7667 7.60244 10.491C7.75335 10.2993 7.97456 10.1821 8.20214 10.094C8.67496 9.91091 9.09254 9.57968 9.4141 9.16967C10.1873 8.18384 11.1617 7.3634 12.2755 6.77021C12.9977 6.38563 13.6243 5.81428 13.9281 5.05464C14.1408 4.5231 14.25 3.95587 14.25 3.38338V2.75C14.25 2.33579 14.5858 2 15 2C16.2426 2 17.25 3.00736 17.25 4.25C17.25 5.40163 16.9904 6.49263 16.5266 7.46771C16.261 8.02604 16.6336 8.75 17.2519 8.75H20.3777C21.4044 8.75 22.3233 9.44399 22.432 10.4649C22.4769 10.8871 22.5 11.3158 22.5 11.75C22.5 14.5976 21.5081 17.2136 19.851 19.2712C19.4634 19.7525 18.8642 20 18.2462 20H14.2302C13.7466 20 13.2661 19.922 12.8072 19.7691L9.69278 18.7309C9.23393 18.578 8.75342 18.5 8.26975 18.5H7.49281Z"
                    fill="var(--color-gray-100)"
                  />
                  <path
                    d="M2.33149 10.7271C1.79481 12.0889 1.5 13.5725 1.5 15.125C1.5 16.3451 1.68208 17.5226 2.02056 18.632C2.27991 19.482 3.10418 20 3.99289 20H4.90067C5.3462 20 5.62137 19.5017 5.42423 19.1022C4.83248 17.9029 4.5 16.5528 4.5 15.125C4.5 13.4168 4.97588 11.8198 5.8023 10.4593C6.0473 10.0559 5.77404 9.5 5.30212 9.5H4.24936C3.41733 9.5 2.63655 9.95303 2.33149 10.7271Z"
                    fill="var(--color-gray-100)"
                  />

                  <path
                    d="M6.63257 10.25C7.43892 10.25 8.16648 9.80416 8.6641 9.16967C9.43726 8.18384 10.4117 7.3634 11.5255 6.77021C12.2477 6.38563 12.8743 5.81428 13.1781 5.05464C13.3908 4.5231 13.5 3.95587 13.5 3.38338V2.75C13.5 2.33579 13.8358 2 14.25 2C15.4926 2 16.5 3.00736 16.5 4.25C16.5 5.40163 16.2404 6.49263 15.7766 7.46771C15.511 8.02604 15.8836 8.75 16.5019 8.75M16.5019 8.75H19.6277C20.6544 8.75 21.5733 9.44399 21.682 10.4649C21.7269 10.8871 21.75 11.3158 21.75 11.75C21.75 14.5976 20.7581 17.2136 19.101 19.2712C18.7134 19.7525 18.1142 20 17.4962 20H13.4802C12.9966 20 12.5161 19.922 12.0572 19.7691L8.94278 18.7309C8.48393 18.578 8.00342 18.5 7.51975 18.5H5.90421M16.5019 8.75H14.25M5.90421 18.5C5.98702 18.7046 6.07713 18.9054 6.17423 19.1022C6.37137 19.5017 6.0962 20 5.65067 20H4.74289C3.85418 20 3.02991 19.482 2.77056 18.632C2.43208 17.5226 2.25 16.3451 2.25 15.125C2.25 13.5725 2.54481 12.0889 3.08149 10.7271C3.38655 9.95303 4.16733 9.5 4.99936 9.5H6.05212C6.52404 9.5 6.7973 10.0559 6.5523 10.4593C5.72588 11.8198 5.25 13.4168 5.25 15.125C5.25 16.3185 5.48232 17.4578 5.90421 18.5Z"
                    stroke="var(--color-neutral)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {strategyData.numLikes}
              </Styled.ExtendedButton>
            </SignupTooltip>
          ) : (
            <Tippy
              content={t('Start copying the strategy to like it.')}
              maxWidth={165}
              placement="bottom"
              disabled={!disabledOptions?.includes('like') || !userID}
            >
              <Styled.ExtendedButton
                type="button"
                isDisabled={disabledOptions?.includes('like') || !userID}
                isActive={strategyData.isLiked}
                activeColor="var(--color-azure-400)"
                disabled={isLikeLoading}
                onClick={() =>
                  likeStrategy({
                    strategyID: strategyData.strategyID,
                    isLiked: !strategyData.isLiked,
                  })
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.49281 18.5C7.06823 18.5 6.67296 18.2635 6.51759 17.8684C6.18349 17.0187 6 16.0933 6 15.125C6 13.3759 6.59874 11.7667 7.60244 10.491C7.75335 10.2993 7.97456 10.1821 8.20214 10.094C8.67496 9.91091 9.09254 9.57968 9.4141 9.16967C10.1873 8.18384 11.1617 7.3634 12.2755 6.77021C12.9977 6.38563 13.6243 5.81428 13.9281 5.05464C14.1408 4.5231 14.25 3.95587 14.25 3.38338V2.75C14.25 2.33579 14.5858 2 15 2C16.2426 2 17.25 3.00736 17.25 4.25C17.25 5.40163 16.9904 6.49263 16.5266 7.46771C16.261 8.02604 16.6336 8.75 17.2519 8.75H20.3777C21.4044 8.75 22.3233 9.44399 22.432 10.4649C22.4769 10.8871 22.5 11.3158 22.5 11.75C22.5 14.5976 21.5081 17.2136 19.851 19.2712C19.4634 19.7525 18.8642 20 18.2462 20H14.2302C13.7466 20 13.2661 19.922 12.8072 19.7691L9.69278 18.7309C9.23393 18.578 8.75342 18.5 8.26975 18.5H7.49281Z"
                    fill="var(--color-gray-100)"
                  />
                  <path
                    d="M2.33149 10.7271C1.79481 12.0889 1.5 13.5725 1.5 15.125C1.5 16.3451 1.68208 17.5226 2.02056 18.632C2.27991 19.482 3.10418 20 3.99289 20H4.90067C5.3462 20 5.62137 19.5017 5.42423 19.1022C4.83248 17.9029 4.5 16.5528 4.5 15.125C4.5 13.4168 4.97588 11.8198 5.8023 10.4593C6.0473 10.0559 5.77404 9.5 5.30212 9.5H4.24936C3.41733 9.5 2.63655 9.95303 2.33149 10.7271Z"
                    fill="var(--color-gray-100)"
                  />

                  <path
                    d="M6.63257 10.25C7.43892 10.25 8.16648 9.80416 8.6641 9.16967C9.43726 8.18384 10.4117 7.3634 11.5255 6.77021C12.2477 6.38563 12.8743 5.81428 13.1781 5.05464C13.3908 4.5231 13.5 3.95587 13.5 3.38338V2.75C13.5 2.33579 13.8358 2 14.25 2C15.4926 2 16.5 3.00736 16.5 4.25C16.5 5.40163 16.2404 6.49263 15.7766 7.46771C15.511 8.02604 15.8836 8.75 16.5019 8.75M16.5019 8.75H19.6277C20.6544 8.75 21.5733 9.44399 21.682 10.4649C21.7269 10.8871 21.75 11.3158 21.75 11.75C21.75 14.5976 20.7581 17.2136 19.101 19.2712C18.7134 19.7525 18.1142 20 17.4962 20H13.4802C12.9966 20 12.5161 19.922 12.0572 19.7691L8.94278 18.7309C8.48393 18.578 8.00342 18.5 7.51975 18.5H5.90421M16.5019 8.75H14.25M5.90421 18.5C5.98702 18.7046 6.07713 18.9054 6.17423 19.1022C6.37137 19.5017 6.0962 20 5.65067 20H4.74289C3.85418 20 3.02991 19.482 2.77056 18.632C2.43208 17.5226 2.25 16.3451 2.25 15.125C2.25 13.5725 2.54481 12.0889 3.08149 10.7271C3.38655 9.95303 4.16733 9.5 4.99936 9.5H6.05212C6.52404 9.5 6.7973 10.0559 6.5523 10.4593C5.72588 11.8198 5.25 13.4168 5.25 15.125C5.25 16.3185 5.48232 17.4578 5.90421 18.5Z"
                    stroke="var(--color-neutral)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {strategyData.numLikes}
              </Styled.ExtendedButton>
            </Tippy>
          )}
        </>
      )}

      {isExtended ? (
        <SignupTooltip enabled={!userID}>
          <Styled.ExtendedButton
            type="button"
            isDisabled={disabledOptions?.includes('favoutites') || !userID}
            isActive={strategyData.isFavorites}
            activeColor="var(--color-sunray-500)"
            disabled={isFavoritesLoading}
            onClick={() =>
              !!userID &&
              addToFavorites({
                strategyID: strategyData.strategyID,
                isFavorites: !strategyData.isFavorites,
              })
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                fill="var(--color-gray-100)"
                fillRule="evenodd"
                d="M10.79 3.21c.448-1.077 1.975-1.077 2.423 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.75 2.305l-4.118 3.527 1.258 5.273c.27 1.136-.965 2.033-1.961 1.425L12 18.354 7.375 21.18c-.997.608-2.232-.29-1.961-1.425l1.258-5.273-4.118-3.527c-.886-.76-.415-2.212.75-2.305l5.403-.433L10.79 3.21Z"
                clipRule="evenodd"
              />

              <path
                stroke="var(--color-neutral)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M11.48 3.499a.563.563 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.562.562 0 0 0-.181.557l1.284 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.563.563 0 0 0-.586 0L6.983 20.54a.562.562 0 0 1-.84-.61l1.284-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.563.563 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
              />
            </svg>
            {strategyData.numFavorites}
          </Styled.ExtendedButton>
        </SignupTooltip>
      ) : (
        <SignupTooltip enabled={!userID}>
          <Styled.Button
            type="button"
            isActive={isSimpleFavorites}
            isDisabled={!userID}
            onClick={(event) => {
              event.preventDefault()
              
              if (!userID) {
                return
              }

              addToFavorites({
                strategyID: strategyData.strategyID,
                isFavorites: !isSimpleFavorites,
              })
            }}
            disabled={isFavoritesLoading}
          >
            <svg
              width="32"
              height="32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="32"
                height="32"
                rx="16"
                fill={
                  isSimpleFavorites
                    ? 'var(--color-sunray-500)'
                    : 'var(--color-gray-200)'
                }
              />

              <path
                fill="var(--color-gray-100)"
                fillRule="evenodd"
                d="M16.865 8.884c-.321-.772-1.415-.772-1.736 0l-1.831 4.402-4.752.38c-.834.067-1.172 1.107-.537 1.651l3.621 3.102-1.106 4.637c-.194.813.69 1.456 1.404 1.02l4.069-2.485 4.068 2.485c.714.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.636-.544.298-1.584-.535-1.65l-4.753-.381-1.83-4.402Z"
                clipRule="evenodd"
              />

              <path
                d="M15.48 7.499a.563.563 0 0 1 1.04 0l2.125 5.111a.562.562 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.562.562 0 0 0-.181.557l1.284 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0l-4.725 2.886a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.563.563 0 0 1 .321-.988l5.518-.442a.562.562 0 0 0 .475-.345L15.48 7.5Z"
                stroke="var(--color-neutral)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Styled.Button>
        </SignupTooltip>
      )}
      <SignupTooltip enabled={!userID}>
        <Styled.Button
          type="button"
          isActive={isComparisonActive}
          activeStrokeColor="var(--color-sunray-700)"
          onClick={(event) => {
            event.preventDefault()

            if (!userID) {
              return
            }

            isComparisonActive
              ? removeStrategyFromCompare({
                  strategyID: strategyData.strategyID,
                  userID,
                })
              : addStrategyToCompare({ strategy: strategyData, userID })
          }}
          isDisabled={!userID}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
          >
            <rect
              width="32"
              height="32"
              fill={
                isComparisonActive
                  ? 'var(--color-sunray-100)'
                  : 'var(--color-gray-200)'
              }
              rx="16"
            />
            {/* <rect width="32" height="32" rx="16" fill="#F8F0DD" /> */}
            <path
              stroke="var(--color-neutral)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M10.156 13a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5ZM16 11h4.167a1.667 1.667 0 0 1 1.666 1.667V18.5"
            />
            <path
              stroke="var(--color-neutral)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M18.5 13.5 16 11l2.5-2.5M21.836 23a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5ZM15.997 21h-4.166a1.667 1.667 0 0 1-1.667-1.667V13.5"
            />
            <path
              stroke="var(--color-neutral)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M13.5 18.5 16 21l-2.5 2.5"
            />
          </svg>
        </Styled.Button>
      </SignupTooltip>
    </Styled.Wrapper>
  )
}
