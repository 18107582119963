import styled from 'styled-components/macro'

export const Container = styled.div`
  display: grid;
  gap: 36px;

  @media (max-width: 570px) {
    gap: 24px;
  }
`

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const CategoriesWrapper = styled.div`
  display: grid;
  gap: 40px;

  @media (max-width: 570px) {
    gap: 24px;
  }
`

export const GiftsWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 570px) {
    margin-top: 12px;
    grid-template-columns: 1fr;
  }

  @media (max-width: 470px) {
    gap: 12px;
  }
`

export const ModalContent = styled.div`
  margin-top: -2rem; // todo: temp
  display: grid;
  gap: 16px;
  text-align: center;
`