import { useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';

import { GetGateways, Operation } from './types';

export const GET_GATEWAYS_KEY = ['gateways'];

const getGateways = async (operation: Operation) => {
  const gatewaysData = await axios.get<GetGateways>(
    `/ps-list/v2/${operation}/gateways`,
  )

  return gatewaysData.data.gateways;
}

export const useGetGateways = (
  operation: Operation | undefined = 'deposit',
) => {
  return useQuery({
    queryKey: [GET_GATEWAYS_KEY, operation],
    queryFn: () => getGateways(operation),
    retry: retryFn,
    staleTime: 2000,
    refetchOnWindowFocus: false,
  })
}
