import styled, { css } from 'styled-components/macro';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const ActivityContainer = styled.div<{ url: string }>`
  position: relative;
  /* background-color: var(--color-gray-100); */
  box-shadow: var(--box-shadow-4);
  border-radius: 8px;
  width: 128px;
  min-height: 128px;
  min-width: 128px;
  padding: 16px;
  padding-top: 10px;
  transition: var(--transition);

  /* background-color: rgba(0, 0, 0, 0.1); */
  background-image: url(${(p) => p.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  border: 1px solid rgba(224, 149, 30, 0.50);
  /* background: var(--gray-g-0-white-ffffff, #FFF); */

  /* Shadow Level - 4 */
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06), 0px 4px 6px -1px rgba(38, 33, 25, 0.10);

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;

      &:hover {
        box-shadow: var(--box-shadow-6);
      }
    `}
`

export const Wrapper = styled.div`
  padding: 64px 20px;
  background-color: var(--color-gray-100);
  box-shadow: 0px 4px 6px -1px rgba(38, 33, 25, 0.1),
    0px 2px 4px -1px rgba(38, 33, 25, 0.06);
  border-radius: 0px 8px 8px 8px;
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const ResultBlock = styled.div`
  display: flex;
  padding: 48px 0px;
  margin-top: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--gray-g-300-layout-bg-f-5-f-5-f-5, #F5F5F5);
  width: 100%;
  min-height: 500px;
  @media (max-width: 900px) {
    padding: 10px;
  }
`

export const Img = styled(LazyLoadImage)`
  /* background-image: url(${(p) => p.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  border-radius: 16px;

  max-height: 530px;
`;