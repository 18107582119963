import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import { yup } from 'libs/validators'
import * as React from 'react'
import { $error, postResetPassword, resetError } from 'services/auth/model'
import styled from 'styled-components/macro'
import { Back, Button, Field, Form } from 'ui/atoms'

import { AuthLayout, AuthSectionText, AuthSectionTitle } from '../../../layout'
import { submitForm } from './model'

export const PasswordRecoveryEntry = () => {
  const error = useStore($error)
  const user = useStore($userProfile)
  const loading = useStore(postResetPassword.pending)

  return (
    <AuthLayout type="login">
      <AuthSectionTitle level={2}>Password recovery</AuthSectionTitle>
      <AuthSectionText>
        Please enter your registered email address.
      </AuthSectionText>
      <Form
        initial={{ email: user.email }}
        validation={yup.password.recovery}
        onSubmit={submitForm}
      >
        <EmailInput
          name="email"
          placeholder="Type your Email"
          label="Email"
          autofocus
          error={error?.fields?.email}
        />
        <RegisterButton
          name="send-recovery-code-submit"
          size="large"
          design="primary"
          type="submit"
          disabled={loading}
          onClick={() => resetError()}
        >
          Submit
        </RegisterButton>
      </Form>
      <Back to={path.login.email()} onClick={resetError} />
    </AuthLayout>
  )
}

const RegisterButton = styled(Button)`
  width: 100%;
  margin-top: 40px;
`

const EmailInput = styled(Field)`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`
