import styled, { css } from 'styled-components/macro'

export const Wrapper = styled.div<{ size: 'medium' | 'large' | 'small' }>`
  padding: 2px 10px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  font-weight: 500;
  text-align: center;
  color: var(--color-opal-600);
  white-space: nowrap;
  border-radius: 20px;
  background-color: var(--color-opal-100);

  ${({ size }) => {
    if (size === 'large') {
      return css`
        padding: 8px 12px;
        font-size: var(--body-font-size-medium);
        line-height: 16px;
      `
    }
    if (size === 'small') {
      return css`
        font-size: var(--body-font-size-tiny);
        line-height: var(--body-line-height-tiny);
      `
    }
  }}
`
