import styled from 'styled-components/macro'
import { Title } from 'ui/atoms'
import { LazyLoadImage } from "react-lazy-load-image-component";

import background from './img/background.jpg'
import backgroundWeb from './img/background.webp'

export const Wrapper = styled.div`
  position: relative;
  padding-top: 16px;
  padding-inline-end: 60px;
  padding-bottom: 16px;
  padding-inline-start: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url(${background});
  background-image: image-set(url(${backgroundWeb}), url(${background}));
  background-size: cover;
  background-position: center center;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
    0px 4px 6px -1px rgba(38, 33, 25, 0.1);

  @media (max-width: 985px) {
    padding-inline-end: 40px;
  }

  @media (max-width: 700px) {
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
  }
`

export const BannerTitle = styled(Title)`
  margin-bottom: 12px;

  @media (max-width: 700px) {
    margin-bottom: 8px;
  }
`

export const Icons = styled.div`
  flex-shrink: 0;
  margin-inline-end: 20px;
  display: grid;
  gap: 16px;

  @media (max-width: 985px) {
    margin-inline-start: 20px;
  }

  @media (max-width: 900px) {
    gap: 8px;
  }

  @media (max-width: 590px) {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
`

export const Icon = styled(LazyLoadImage)`
  width: 150px;
  height: auto;

  @media (max-width: 900px) {
    width: 120px;
  }

  @media (max-width: 700px) {
    width: 101px;
  }
`

export const QR = styled(LazyLoadImage)`
  width: 108px;
  height: auto;

  @media (max-width: 985px) {
    display: none;
  }
`

export const Close = styled.button`
  cursor: pointer;
  position: absolute;
  top: 6px;
  inset-inline-end: 6px;
  width: 24px;
  height: 24px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: rgba(38, 33, 25, 0.6);

  @media (max-width: 700px) {
    width: 20px;
    height: 20px;
  }

  &::before {
    content: '✕';
    font-size: 14px;
    line-height: 1;
    color: var(--color-gray-400);

    @media (max-width: 700px) {
      font-size: 12px;
    }
  }
`