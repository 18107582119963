import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'

const exchange = async (data: {
  fromExchangerWalletID: string
  toAccountID: string
  amount: string
}) => {
  const res = await axios.post<{ transferID: string }>(
    '/exchangers/v1/transfer',
    data,
  )

  return res.data
}

export const useExchange = () => {
  return useMutation({
    mutationFn: exchange,
    retry: retryFn,
  })
}
