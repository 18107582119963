import { Captcha, useCaptcha } from 'features/captcha'
import { path } from 'libs/path'
import { yup } from 'libs/validators'
import styled from 'styled-components/macro'
import { Back, Button, Field, Form } from 'ui/atoms'

import { AuthLayout } from '../../layout'
import { useEmailRegisterPage } from './hooks'
import { submitForm } from './model'
import { RegisterByEmail } from './types'

const initial = {
  email: '',
  firstName: '',
  lastName: '',
}

export const RegisterEmail = () => {
  const {
    isLoading: isVerificationLoading,
    ref: recaptchaRef,
    execute,
  } = useCaptcha()

  const { form } = useEmailRegisterPage()
  const { error, loading } = form

  const isLoading = loading || isVerificationLoading

  return (
    <AuthLayout type="register">
      <Form
        initial={initial}
        onSubmit={(values: RegisterByEmail) =>
          execute(() =>
            submitForm({
              ...values,
              email: values.email.trim(),
              partnerKey: localStorage.getItem('partnerId') as string,
            }),
          )
        }
        validation={yup.email.validate}
        error={error?.form}
      >
        <Field
          name="email"
          label="Email"
          placeholder="Type your Email"
          autofocus
          error={error?.fields?.email}
        />
        <Field
          name="firstName"
          label="First name"
          placeholder="Type your First name"
          error={error?.fields?.firstName}
        />
        <Field
          name="lastName"
          label="Last name"
          placeholder="Type your Last name"
          error={error?.fields?.lastName}
        />
        <Captcha ref={recaptchaRef} />
        <RegisterButton
          name="register"
          size="large"
          design="primary"
          type="submit"
          loading={isLoading}
          disabled={isLoading}
        >
          Register
        </RegisterButton>
      </Form>
      <BackButton to={path.register.entry()} />
    </AuthLayout>
  )
}

const RegisterButton = styled(Button)`
  width: 100%;
  margin-top: 32px;
`

const BackButton = styled(Back)`
  width: 100%;
`
