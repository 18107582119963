import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { useIsMobile } from 'libs/hooks/useIsMobile'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetStrategyHistory } from 'services/copytrade/get/useGetStrategyHistory'
import { SpinnerWithBlur } from 'ui/atoms'
import { Pagination } from 'ui/atoms/pagination/Pagination'
import { THead, TableWrapper } from 'ui/molecules'

import { HistoryItem } from './HistoryItem'
import { NoCopying } from './NoCopying'

const PER_PAGE = 10

type Props = {
  onStartCopying: () => void;
  isSkipRequest?: boolean;
}

export const StrategyHistory: React.FC<Props> = ({ onStartCopying, isSkipRequest } ) => {
  const { strategyID } = useParams()

  const { userID } = useStore($userProfile)

  const [page, setPage] = useState(1)

  const isMobile = useIsMobile()

  const { data, isRefetching } = useGetStrategyHistory({
    strategyID: strategyID!,
    page,
    perPage: PER_PAGE,
    enabled: !!userID && !isSkipRequest,
  })

  const onChangePage = (newPage: number) => {
    setPage(newPage)
  }

  if (!data)
    return <NoCopying isMobile={isMobile} onStartCopying={onStartCopying} />

  return (
    <div className="">
      <TableWrapper className="lg:w-[70vw] xl:w-full w-[90vw] relative">
        <SpinnerWithBlur size="xl" isShow={isRefetching} />
        <table className="w-full" border={1}>
          <THead
            trClasses=""
            textLevel={isMobile ? 4 : 3}
            heads={[
              { label: 'Instrument' },
              { label: 'Opening date' },
              { label: 'Opening price' },
              { label: 'Trading volume' },
              { label: 'Closing date' },
              { label: 'Closing price' },
              { label: 'Commission' },
              { label: 'Swap' },
              { label: 'Profit', align: 'right' },
            ]}
          />
          <tbody>
            {data?.items.map((item, index) => (
              <HistoryItem
                index={index}
                item={item}
                key={`${item.openedTs}-${index}`}
                isNoLastElem={index !== data.items.length - 1}
              />
            ))}
          </tbody>
        </table>
      </TableWrapper>

      {data && Number(data?.totalRecords) > PER_PAGE && (
        <Pagination
          pageSize={PER_PAGE}
          current={page}
          total={Number(data.totalRecords)}
          onChange={onChangePage}
          classNames="mt-[2.4rem]"
        />
      )}
    </div>
  )
}
