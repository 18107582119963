import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { usePutUserLanguage } from 'services/user/mutations/usePutLanguage'
import { Icon2, Text } from 'ui/atoms'
import { langs } from 'ui/constants'

import * as S from './styles'

export const AccountSettings = ({
  language,
  allLanguages,
}: {
  language: string
  allLanguages?: string[]
}) => {
  const [t] = useTranslation()

  const { mutateAsync } = usePutUserLanguage()

  const { hash } = useLocation()
  const cleanedHash = hash.split('?')[0]

  useEffect(() => {
    if (cleanedHash) {
      document
        .querySelector(cleanedHash)
        ?.scrollIntoView({ behavior: 'instant' })
    }
  }, [cleanedHash])

  const handleSelect = (value: string) => {
    mutateAsync(
      langs.find((lang) => t(lang.label, { lng: lang.value }) === value)
        ?.value || '',
    )
  }

  return (
    <S.InformationBlockItem id="communication_language">
      <Text>Communication language</Text>
      <S.LanguageSearch
        trigger={
          <div className="flex cursor-pointer items-center">
            <Text>{langs.find((lang) => lang.value === language)?.label}</Text>
            &nbsp;
            <Icon2 size="base" name="chevronDown" className="text-sunray.500" />
          </div>
        }
        onSelect={handleSelect}
        items={allLanguages?.map((availableLang) =>
          t(langs.find((lang) => lang.value === availableLang)?.label || '', {
            lng: availableLang,
          }),
        )}
        searchEnabled={false}
      />
    </S.InformationBlockItem>
  )
}
