import styled from 'styled-components/macro'

import { LinkForShare } from '../linkForShare'

export const Container = styled.div`
  @media (max-width: 600px) {
    text-align: center;
  }
`

export const MediaWrapper = styled.div`
  margin: 20px 0;
  overflow: hidden;
  border-radius: 8px;

  @media (max-width: 600px) {
    margin: 16px 0;
  }

  img,
  video {
    width: 100%;
    height: auto;
    max-height: 60vh;
    object-fit: contain;

    @media (max-width: 600px) {
      max-height: 44vh;
    }
  }
`

export const LinkWrapper = styled(LinkForShare)`
  margin: 20px 0;

  @media (max-width: 600px) {
    margin-top: 16px;
    margin-bottom: 24px;
  }
`
