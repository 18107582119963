import * as React from 'react'
import styled from 'styled-components/macro'

import { Icon, Icons, Title } from '../../atoms'

type WidgetProps = {
  title?: string
  icon?: Icons
  extra?: React.ReactNode
  children?: React.ReactNode
}

export const Widget = ({ title, icon, extra, children }: WidgetProps) => {
  return (
    <WidgetContainer>
      <WidgetHeader>
        {title && (
          <WidgetTitle>
            <Title level={2}>{title}</Title>
            {icon && <WidgetTitleIcon name={icon} />}
          </WidgetTitle>
        )}
        {extra || null}
      </WidgetHeader>
      <WidgetContent>{children}</WidgetContent>
    </WidgetContainer>
  )
}

const WidgetContainer = styled.div`
  width: 100%;
  height: 100%;
`

const WidgetHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const WidgetTitle = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
`

const WidgetTitleIcon = styled(Icon)`
  margin-inline-start: 6px;
`

const WidgetContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`
