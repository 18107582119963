import { useQuery } from '@tanstack/react-query'
import { AccountTariffName } from 'pages/accounts/create/types'
import { axios } from 'services/axios'

export const GET_EXCHANGE_RECIPIENTS_KEY = 'exchange-recipients';

type Params = {
  fromExchangerWalletID: string
  toMTLogin: string
}

const getExchangeRecipients = async (params?: Params) => {
  const res = await axios.get<{
    recipients: {
      accountID: string
      firstName: string
      middleName: string
      lastName: string
      platform: string;
      tariffName: AccountTariffName;
    }[]
  }>('/exchangers/v1/transfer/recipient', { params })

  return res.data.recipients
}

type Options = {
  params: Params;
  enabled?: boolean;
}

export const useGetExchangeRecipients = ({ params, enabled }: Options) => {
  return useQuery({
    queryKey: [GET_EXCHANGE_RECIPIENTS_KEY, params?.fromExchangerWalletID, params?.toMTLogin],
    queryFn: () => getExchangeRecipients(params),
    enabled: enabled,
    cacheTime: 0,
  })
}
