import * as React from 'react'
import styled from 'styled-components/macro'

import { Icon } from '../../atoms'

type SlideProps = {
  label: string
  children: React.ReactNode
}

type SliderProps = {
  items: SlideProps[]
  extra?: React.ReactNode
}

export const Slider = ({ items, extra }: SliderProps) => {
  const [activeLabel, setActiveLabel] = React.useState(items[0]?.label || 0)

  return (
    <SliderContainer>
      <SlideLabels>
        {items.map(({ label }, key) => (
          <SlideLabel
            key={key}
            onClick={() => setActiveLabel(label)}
            data-active={label === activeLabel}
          >
            {label}
          </SlideLabel>
        ))}
        {extra && <SliderExtra>{extra}</SliderExtra>}
      </SlideLabels>
      <SlideContent>
        {items.map(({ children, label }, key) => (
          <SlideContainer key={key} data-active={label === activeLabel}>
            {children}
          </SlideContainer>
        ))}
      </SlideContent>
    </SliderContainer>
  )
}

const SliderContainer = styled.div``

const SlideLabels = styled.div`
  display: flex;
  padding-bottom: 12px;
  gap: 8px;
`

const SlideLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  height: 30px;
  border-radius: 4px;
  font-weight: 500;
  background-color: var(--color-gray-300);
  transition: var(--transition);
  cursor: pointer;

  &[data-active='true'] {
    background-color: var(--color-gray-400);
  }

  &:hover {
    background-color: var(--color-gray-400);
  }
`

const SliderExtra = styled.div`
  margin-inline-start: auto;
`

const SlideContent = styled.div`
  padding-bottom: 12px;
`

const SlideContainer = styled.div`
  display: none;
  opacity: 0;
  width: 100%;

  &[data-active='true'] {
    display: block;
    opacity: 1;
  }
`
