import ar from './ar.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import id from './id.json'
import ja from './ja.json'
import ms from './ms.json'
import pt from './pt.json'
import th from './th.json'
import vi from './vi.json'

export const resources = { en, es, ar, fr, id, ms, th, vi, ja, pt }
