import styled from 'styled-components/macro'

export const Container = styled.div`
  width: calc(75% - 8px);
  position: relative;
  padding-bottom: 2rem;

  @media (max-width: 1050px) {
    width: 100%;
  }
`
