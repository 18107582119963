import styled from 'styled-components/macro'

import { accordionArrow } from 'styles/img';

export const Wrapper = styled.div`
  display: grid;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 849px) {
    flex-direction: column;
    gap: 12px;
  }

  input[type='text'] {
    padding: 12px;
    height: 40px;
  }
`

export const DatePickerWrapper = styled.div`
  position: relative;
  padding-top: 32px;
  padding-bottom: 8px;

  &::before {
    content: '';
    position: absolute;
    top: 16px;
    inset-inline-start: 16px;
    width: calc(100% - 32px);
    height: 1px;
    background-color: var(--color-gray-300);
  }

  input[type='text'] {
    background-image: none;
  }
`

export const Buttons = styled.div`
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(2, 140px);
  justify-content: end;
  gap: 20px;

  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 100px);
    gap: 16px;
  }
`

export const PickerButtons = styled(Buttons)`
  margin-top: 8px;
`

export const AccountsWrapper = styled.div`
  padding: 8px 0;
  display: grid;
  gap: 24px;
`

export const ContentTitle = styled.div`
  margin-bottom: 4px;
  padding-inline-start: 16px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);
  font-weight: 500;
`

export const SelectOption = styled.div`
  padding: 10px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
`
