import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { ExchangerWallets } from './types';

export const GET_EXCHANGER_WALLETS_KEY = ['exchanger-wallets'];

const getWallet = async () => {
  const res = await axios.get<ExchangerWallets>(`/exchangers/v1/wallets`);

  return res.data;
};

type Options = {
  enabled?: boolean;
  noRefetch?: boolean;
}

export const useGetExchangerWallets = ({ enabled, noRefetch = false }: Options): UseQueryResult<ExchangerWallets> => {
  return useQuery({
    queryKey: GET_EXCHANGER_WALLETS_KEY,
    queryFn: getWallet,
    retry: retryFn,
    staleTime: 2000,
    refetchOnWindowFocus: !noRefetch,
    refetchOnReconnect: !noRefetch,
    enabled,
  })
};
