import styled from 'styled-components/macro'
import { Button as BaseButton } from 'ui/atoms'

type WrapperProps = {
  mainUrl: string;
  webpUrl: string;
};

export const Wrapper = styled.div<WrapperProps>`
  padding-top: 128px;
  width: 100%;
  display: grid;
  justify-items: center;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 128px auto;
  background-position: top center;
  background-image: url(${(props) => props.mainUrl});
  background-image: image-set(url(${(props) => props.webpUrl}), url(${(props) => props.mainUrl}))
`

export const Text = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
  color: var(--color-gray-600);
`

export const Button = styled(BaseButton)`
  min-width: 160px;
`
