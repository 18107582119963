import { useStore } from 'effector-react'
import * as React from 'react'

import { $newNameSliced, mounted, patchAccountName, unmounted } from './model'

export const useRename = () => {
  const newName = useStore($newNameSliced)
  const isLoading = useStore(patchAccountName.pending)

  React.useEffect(() => {
    mounted()
    return () => unmounted()
  }, [])

  return { newName, isLoading }
}
