import { path } from 'libs/path'

import { BackButton } from '../molecules/backButton'
import { StrategyForm } from '../molecules/strategyForm'

export const CreateStrategy = () => {
  return (
    <>
      <BackButton to={path.copytrade.traderEntry()} />
      <StrategyForm />
    </>
  )
}
