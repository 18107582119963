import { useTranslation } from 'react-i18next'
import { Icon2, Icons } from 'ui/atoms/icon2'

import * as Styled from './styled'

export const TransferFeatures = (props: {
  data: { icon: Icons; label: string }[]
}) => {
  const [t] = useTranslation()

  return (
    <Styled.Wrapper>
      {props.data.map((item, index) => (
        <Styled.Item key={index}>
          <Icon2 name={item.icon} className="text-sunray.500" />
          {t(item.label)}
        </Styled.Item>
      ))}
    </Styled.Wrapper>
  )
}
