import { createEvent, restore, sample } from 'effector'
import { InternalTransferProps, Wallet, service } from 'services'
import { Account } from 'services/accounts/types'
import { DestinationType } from 'services/internal-transfers/types';
import { SourceType } from 'services/payments/get/types';
import { ExchangerWalletItem } from 'services/wallet/get/types';

type Holder = { id: string; type: SourceType, currency?: string; balance?: string }

type CombineWallets = {
  id: string;
  balance: string;
  type: SourceType;
} | ExchangerWalletItem;

export const transfersMounted = createEvent()
export const resetTransfer = createEvent()

export const sourceSelected = createEvent<Holder>()
export const destinationSelected = createEvent<Holder | null>()

export const submit = createEvent()

export const setAmount = createEvent<string>()
export const $amount = restore(setAmount, '').reset(resetTransfer)

export const $isTransferCompleted = restore(
  service.internalTransfers.postInternalTransfer.doneData.map(() => true),
  false,
).reset(resetTransfer)

export const getHolderType = (type: SourceType): DestinationType => {
  if (type === 'OT_ACCOUNT') return 'PUBLIC_API_INTERNAL_TRANSFER_ACCOUNT_ID_TYPE_ACCOUNT';
  if (type === 'OT_WALLET') return 'PUBLIC_API_INTERNAL_TRANSFER_ACCOUNT_ID_TYPE_WALLET';
  if (type === 'OT_EXCHANGER_WALLET') return 'PUBLIC_API_INTERNAL_TRANSFER_ACCOUNT_ID_TYPE_EXCHANGER_WALLET';

  return 'PUBLIC_API_INTERNAL_TRANSFER_ACCOUNT_ID_TYPE_ACCOUNT';
}

const setRequest = (
  source: Holder,
  destination: Holder,
  amount: string,
): InternalTransferProps => ({
  srcID: source.id,
  srcIDType: getHolderType(source.type),
  dstID: destination.id,
  dstIDType: getHolderType(destination.type),
  amount,
})

sample({
  clock: [transfersMounted],
  fn: () => ({}),
  target: service.internalTransfers.getInternalTransferSources,
})

export const $sources = restore<{ accounts: Account[]; wallets: (Wallet | ExchangerWalletItem)[] }>(
  service.internalTransfers.getInternalTransferSources.doneData.map(
    (doneData) => {
      const wallets: any[] = [];

      doneData.body.wallets?.forEach(item => wallets.push({
        ...item,
        id: item.walletID,
        type: 'OT_WALLET',
        balance: String(item.balance),
      }));

      doneData.body?.exchangerWallets?.forEach(item => wallets.push({
        ...item,
        id: item.exchangerWalletID,
        type: 'OT_EXCHANGER_WALLET',
      }))
  
      return {
        accounts: doneData.body.accounts,
        wallets,
      }
    },
  ),
  { accounts: [], wallets: [] },
)

sample({
  clock: sourceSelected,
  fn: (source) =>
    source
      ? {
          query: {
            srcID: source.id,
            srcIDType: getHolderType(source.type),
          },
        }
      : {},
  target: service.internalTransfers.getInternalTransferDestinations,
})

export const $source = restore(sourceSelected, null).reset(resetTransfer)
export const $destination = restore(destinationSelected, null).reset(resetTransfer)

export const $destinations = restore(
  service.internalTransfers.getInternalTransferDestinations.doneData.map(
    (doneData) => {
      const wallets: CombineWallets[] = [];

      doneData.body.wallets?.forEach(item => wallets.push({
        ...item,
        id: item.walletID,
        type: 'OT_WALLET',
        balance: String(item.balance),
      }));

      doneData.body?.exchangerWallets?.forEach(item => wallets.push({
        ...item,
        id: item.exchangerWalletID,
        type: 'OT_EXCHANGER_WALLET',
      }))
  
      return {
        accounts: doneData.body.accounts,
        wallets,
      }
    },
  ),
  { wallets: [], accounts: [] },
).reset(resetTransfer)

sample({
  source: {
    source: $source,
    destination: $destination,
    amount: $amount,
  },
  clock: [setAmount, destinationSelected],
  filter: ({ source, destination, amount }) => {
    const amountNum = +amount;
    const balance = Number(source?.balance || 0);

    if (!source || !destination) return false;
    if (balance <= 0 || amountNum > balance) return false;
    
    return amountNum > 0;
  },
  fn: ({ source, destination, amount }) => ({ 
    query: setRequest(source as Holder, destination as Holder, amount) 
  }),
  target: service.internalTransfers.getInternalTransferCalculate,
})

const exchangeValuesDropEvent = createEvent<string | boolean | number>();

export const $exchangeValues = restore(
  service.internalTransfers.getInternalTransferCalculate.doneData.map(
    (doneData) => doneData.body,
  ),
  null,
)
// Если в ивент приходит пустое значение, то стейт сбрасываем
.on(exchangeValuesDropEvent, (state, payload) => {
  if (!payload) return null;
  return state;
})
.reset(resetTransfer);

export const $exchangeError = restore(
  service.internalTransfers.getInternalTransferCalculate.failData.map(
    (item): boolean | string => {
      const errorFromBe = item.body.details?.validationErrors?.items?.[0]?.msg || true;
      return errorFromBe;
    },
  ),
  false,
).on(exchangeValuesDropEvent, (state, payload) => {
  if (!payload) return false;
  return state;
}).reset(resetTransfer);

export const changedSourceEvent = sample({
  source: {
    amount: $amount,
    source: $source,
  },
  clock: [sourceSelected],
  filter: (src) => Boolean(src?.amount),
  fn: (src, clk) => {
    if (src.source?.currency !== clk.currency) {
      return '';
    }

    return src.amount;
  },
  greedy: true,
  target: [setAmount, exchangeValuesDropEvent],
})

sample({
  clock: service.internalTransfers.getInternalTransferCalculate.doneData,
  fn: () => false,
  target: $exchangeError,
})

sample({
  source: {
    source: $source,
    exchangeValues: $exchangeValues,
  },
  clock: setAmount,
  filter(src, clk) {
    const amountNumber = +clk;
    return !amountNumber && Boolean(src.exchangeValues);
  },
  fn: () => {
    return null;
  },
  target: $exchangeValues,
})

sample({
  source: {
    amount: $amount,
    source: $source,
  },
  clock: setAmount,
  filter(src, clk) {
    const amountNumber = +clk;
    return !!amountNumber && amountNumber > 0;
  },
  fn: ({ amount, source }) => {
    const amountNum = +amount;
    const balance = Number(source?.balance || 0);
    if (balance === 0) return true;
    
    return amountNum <= 0 || amountNum > balance;
  },
  target: $exchangeError,
})

sample({
  source: {
    source: $source,
    destination: $destination,
    amount: $amount,
  },
  clock: submit,
  fn: ({ source, destination, amount }) => ({
    body: setRequest(source as Holder, destination as Holder, amount),
  }),
  target: service.internalTransfers.postInternalTransfer,
})
