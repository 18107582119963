import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';

import { GET_USER_SOCIALS_KEY } from '../get/useGetUserSocials';
import { SocialNameType } from '../get/type';
import { AxiosError, AxiosResponse } from 'axios';

const socialTypeMap = {
  'apple': 'PUBLIC_API_SOCIAL_TYPE_APPLE',
  'google': 'PUBLIC_API_SOCIAL_TYPE_GOOGLE',
  'facebook': 'PUBLIC_API_SOCIAL_TYPE_FACEBOOK',
}

interface RequestData {
  accessToken?: string;
  socialType: string;
  authorizationCode?: string;
}

interface postLinkSocialData {
  authorizationCode?: string;
  accessToken?: string;
  socialType: SocialNameType;
}

const postLinkSocial = async (reqData: postLinkSocialData): Promise<{}> => {

  const data: RequestData = {
    socialType: socialTypeMap[reqData.socialType],
    // authorizationCode: reqData.authorizationCode,
    // accessToken: reqData.accessToken,
  }

  if (reqData.authorizationCode) {
    data.authorizationCode = reqData.authorizationCode;
  }

  if (reqData.accessToken) {
    data.accessToken = reqData.accessToken;
  }

  const resp = await axios.post<{}, AxiosResponse<{}>, RequestData>('/auth/v1/link-social', data);
  return resp.data;
}

export const usePostLinkSocial = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, postLinkSocialData>({
    mutationFn: postLinkSocial,
    retry: retryFn,
    onSuccess() {
      queryClient.invalidateQueries(GET_USER_SOCIALS_KEY);
    },
  })
}