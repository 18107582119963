import * as React from 'react'
import styled from 'styled-components/macro'

type Props = {
  className?: string
}

export const Background = ({ className }: Props) => {
  return <BackgroundContainer className={className} />
}

const BackgroundContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  top: 0;
  inset-inline-start: 0;
  backdrop-filter: blur(6px);
`
