import * as React from 'react'
import styled from 'styled-components/macro'
import { useOnClickOutside } from 'usehooks-ts'

import { Input, InputProps } from '../../atoms'

export type SearchProps = {
  trigger: React.ReactNode
  items?: string[]
  onSelect: (item: string) => void
  searchEnabled?: boolean
} & Pick<InputProps, 'className' | 'name' | 'label' | 'placeholder'>

export const DropdownSearch = ({
  className,
  trigger,
  name,
  label,
  items = [],
  placeholder,
  onSelect,
  searchEnabled = true
}: SearchProps) => {
  const ref = React.useRef(null)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [isVisible, setVisibility] = React.useState(false)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const filtered = items.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  useOnClickOutside(ref, () => {
    setVisibility(false)
  })

  return (
    <SearchWrap ref={ref}>
      <SearchTrigger
        onClick={() => {
          setVisibility(!isVisible)
        }}
      >
        {trigger}
      </SearchTrigger>
      {isVisible && (
        <SearchContainer className={className}>
          {searchEnabled && <Input
            name={name}
            label={label}
            placeholder={placeholder}
            onChange={handleInputChange}
          />}
          <SearchListContainer>
            <DropdownList>
              {filtered.map((label, key) => (
                <DropdownItem
                  key={key}
                  onClick={() => {
                    onSelect(label)
                    setVisibility(false)
                  }}
                >
                  <DropdownItemLabel>{label}</DropdownItemLabel>
                </DropdownItem>
              ))}
            </DropdownList>
          </SearchListContainer>
        </SearchContainer>
      )}
    </SearchWrap>
  )
}

const SearchContainer = styled.span`
  z-index: 10;
  background-color: var(--color-gray-100);
  box-shadow: var(--box-shadow-6);
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 8px 8px;
`

const SearchWrap = styled.span``

const SearchTrigger = styled.span``

const SearchListContainer = styled.div`
  padding: 8px 0;
`

const DropdownList = styled.div`
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  max-height: 220px;
  height: 100%;
  overflow: scroll;
`

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: 9px 16px 9px 16px;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    color: var(--color-sunray-500);
  }
`

const DropdownItemLabel = styled.span`
  width: 100%;
  text-align: start;
`
