import { Effect, createEffect, createEvent, restore, sample } from 'effector';
import { $queue, clearQueue } from 'features/domain';

import { Request } from 'libs/request'

export const setTokenRefreshingEvent = createEvent<boolean>();
export const newTokenEvent = createEvent<string>();
export const $isTokenRefreshing = restore<boolean>(setTokenRefreshingEvent, false);

$isTokenRefreshing.watch((state, payload: boolean | undefined) => {
  // console.log(state, payload, '----WATCH-----');

  if (payload) {
    setInterval(() => {
      setTokenRefreshingEvent(false);
    }, 50000);
  }
});

const multipleRequestsFx = createEffect(
  async ({
    payloads,
  }: {
    payloads: {
      fx: Effect<unknown, unknown, unknown>
      params: Request<object>
    }[]
  }) => {
    for (const payload of payloads) {
      await payload.fx(payload.params)
    }
  },
)

sample({
  source: $queue,
  clock: newTokenEvent,
  fn: (payloads, accessToken) => ({
    payloads,
    accessToken,
  }),
  target: [multipleRequestsFx],
})

sample({
  clock: multipleRequestsFx.done,
  target: clearQueue,
})