import React from 'react'
import styled from 'styled-components/macro';

import { Icon } from '../icon';

interface Props {
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

export const Spinner2: React.FC<Props> = ({ className, size }) => {
  return (
    <Spinner
      name="spinner"
      data-visible={true}
      size={ size }
      className={ className }
    /> 
  )
}


const Spinner = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-inline-start: 1rem;
  animation: spin 0.5s linear infinite;
  animation-delay: 0.1s;
  transition: var(--transition);
  opacity: 1;
`