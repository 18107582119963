import React from 'react';
import { useStore } from 'effector-react';
import { useDebounce } from 'usehooks-ts';

import { service } from 'services';


import {
  $amount,
  $destination,
  $destinations,
  $exchangeError,
  $exchangeValues,
  $isTransferCompleted,
  $source,
  $sources,
  destinationSelected,
  resetTransfer,
  setAmount,
  sourceSelected,
  submit,
  transfersMounted
} from './model'

export const useTransferWidget = () => {
  const [amountValue, setAmountValue] = React.useState('')
  const debouncedAmount = useDebounce(amountValue, 400)

  const sources = useStore($sources)
  const destinations = useStore($destinations)

  const source = useStore($source)
  const destination = useStore($destination)
  const amount = useStore($amount)
  const calculatedAmount = useStore($exchangeValues)?.calculatedAmount
  const exchangeRate = useStore($exchangeValues)?.exchangeRate
  const exchangeError = useStore($exchangeError)
  const isTransferCompleted = useStore($isTransferCompleted)
  const isLoading = useStore(service.internalTransfers.postInternalTransfer.pending)

  React.useEffect(() => {
    transfersMounted();
    return () => {
      resetTransfer();
    }
  }, []);

  React.useEffect(() => {
    setAmount(debouncedAmount)
  }, [debouncedAmount])

  React.useEffect(() => {
    if (!amount) {
      setAmountValue('')
    }
  }, [amount])

  return {
    amount: amountValue,
    setAmount: setAmountValue,
    calculatedAmount,
    exchangeRate,
    submit,
    source,
    destination,
    sources,
    destinations,
    setSource: sourceSelected,
    setDestination: destinationSelected,
    resetTransfer,
    isTransferCompleted,
    isLoading,
    errors: {
      amount: exchangeError,
    },
  }
}
