import { useStore } from 'effector-react'
import { path } from 'libs/path'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetPartnerPage as useGetWidgetInfo } from 'services/ib/get/useGetPartnerPage'
import { Spinner2 } from 'ui/atoms'
import { Accordion, Tabs } from 'ui/molecules'
import { TabContent } from 'ui/molecules/tabs'

import { Dashboard } from './Dashboard'
import { HaventIbPartner } from './HaventIbPartner'
import { Materials } from './Materials'
import {
  $isIBDashboardInfoVisible,
  $isIBMaterialsInfoVisible,
  setIBDashboardInfoVisibility,
  setIBMaterialsInfoVisibility,
} from './models/other'
import * as Styled from './styled'

export const IBArea = () => {
  const [t] = useTranslation()

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const { data, isLoading } = useGetWidgetInfo()

  const isIBDashboardInfoVisible = useStore($isIBDashboardInfoVisible)
  const isIBMaterialsInfoVisible = useStore($isIBMaterialsInfoVisible)

  const [activeTab, setActiveTab] = useState<'IB Dashboard' | 'Ad materials'>(
    'Ad materials',
  )

  useEffect(() => {
    if (pathname === path.ib.entry()) {
      setActiveTab('IB Dashboard')
    } else if (pathname === path.ib.materials()) {
      setActiveTab('Ad materials')
    }
  }, [pathname])

  if (isLoading || !data) {
    return (
      <div className="h-[calc(100vh_-_146px)] grid place-items-center">
        <Spinner2 size="medium" />
      </div>
    )
  }

  if (data.widgetInfo.partnerStatus === 'PARTNER_STATUS_NOT_PARTNER') {
    return <HaventIbPartner />
  }

  const isShowMaterials = data.widgetInfo.isShowMaterials

  const tabs = [
    {
      label: 'IB Dashboard',
      children: (
        <TabContent>
          <Accordion
            title="IB Area"
            isOpened={isIBDashboardInfoVisible}
            onToggle={() =>
              setIBDashboardInfoVisibility(!isIBDashboardInfoVisible)
            }
          >
            <Styled.InfoText>
              {t(
                'Welcome to the partner workspace. Here, you can find your referral link and check current partnership status, IB wallet balance, and detailed statistics of your work. Attract new clients to Headway and monitor your progress in the IB Area!',
              )}
            </Styled.InfoText>
          </Accordion>
        </TabContent>
      ),
      onClick: () => navigate(path.ib.entry()),
    },
    {
      label: 'Ad materials',
      children: (
        <TabContent>
          {isShowMaterials ? (
            <Accordion
              title="Ad materials"
              isOpened={isIBMaterialsInfoVisible}
              onToggle={() =>
                setIBMaterialsInfoVisibility(!isIBMaterialsInfoVisible)
              }
            >
              <Styled.InfoText>
                {t(
                  'Use the advertising materials approved by the Headway team. We offer the content for social media and websites as well as our branding guides and landing pages. Select a language, an offer (a feature you would like to promote), and a publishing platform – and you will see the desired materials with a unique link for sharing. Make the most of your partnership with Headway!',
                )}
              </Styled.InfoText>
            </Accordion>
          ) : null}
        </TabContent>
      ),
      onClick: () => navigate(path.ib.materials()),
      disabled: !isShowMaterials,
    },
  ]

  return (
    <>
      <Tabs
        value={activeTab}
        fullHeight={false}
        withOutWrapp
        items={isShowMaterials ? tabs : [tabs[0]]}
      />
      <Styled.ContentWrapper>
        {activeTab === 'IB Dashboard' ? (
          <Dashboard data={data} />
        ) : isShowMaterials ? (
          <Materials />
        ) : null}
      </Styled.ContentWrapper>
    </>
  )
}
