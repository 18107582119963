import { createEvent, sample, restore } from 'effector'

import { setActiveAccountId } from '../list/model'
import { $account, getAccount } from '../model'

export const mounted = createEvent<string>()

export const setBalanceModalId = createEvent<string | null>()

export const $balanceModalId = restore(setBalanceModalId, null)

sample({
  clock: mounted,
  fn: (accountID) => {
    return {
      query: {
        accountID,
      },
    }
  },
  target: [getAccount],
})

sample({
  clock: mounted,
  target: setActiveAccountId,
})

$account.reset([mounted])
