import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'ui/molecules';

import { path } from 'libs/path';
import { TransactionStatus } from '../molecules/transactionStatus/TransactionStatus';

const errorStatuses = ['declined', 'cancelled', 'rejected'];
const pendingStatuses = ['authorized', 'initialized'];

export const ResultDepositPage = () => {
  let [searchParams] = useSearchParams();

  const [t] = useTranslation();

  const navigate = useNavigate();

  const resultProps = () => {
    const obj = {
      amount: '',
      currency: searchParams.get('currency') as string,
      id: searchParams.get('id') as string,
      status: searchParams.get('status') as string,
      title: '',
      subTitle: '',
    }

    const amountQuery = searchParams.get('amount');
    const isResultAmount = searchParams.get('isResultAmount') as string;
    
    if (amountQuery) {
      let resultAmount: number | string;
     if ((obj.currency !== 'JPY' && obj.currency !== 'VND') && (isResultAmount !== 'true')) {
        // praxis умножает на 100
        resultAmount = (+amountQuery / 100).toFixed(2).toString();
      } else {
        resultAmount = amountQuery;
      }
     
      obj.amount = resultAmount;
    }

    if (obj.status === 'requested') obj.status = 'pending';
    if (errorStatuses.includes(obj.status)) obj.status = 'error';
    if (pendingStatuses.includes(obj.status)) obj.status = 'pending';

    if (obj.status === 'pending') {
      obj.title = t('Payment is in process')
      obj.subTitle = t('We are processing your transaction now – it may take some time. See the current status of your transaction in the Transaction History (My Accounts section).')
    }

    if (obj.status === 'success' || obj.status === 'approved') {
      obj.title = 'Success'
      obj.subTitle = 'Your payment is processed successfully. Start trading now!'
    }

    if (obj.status === 'error') {
      obj.title = 'Payment failed'
      obj.subTitle = 'The transaction has failed. Please, try again or contact our Customer Care for more details.'
    }
    
    return obj;
  }

  useEffect(() => {
    if (!searchParams.get('currency') && !searchParams.get('id') && !searchParams.get('status')) {
      navigate(path.money.entry());
    }
  }, []);

  return (
    <Tabs
      items={[
        {
          label: 'Deposit',
          children: 
            <>
             <TransactionStatus
               {...resultProps() as any}
             />
            </>,
          onClick: () => navigate(path.money.entry())
        },
        {
          label: 'Withdrawal',
          children: <></>,
          onClick: () => navigate(path.money.withdrawalTab())
        },
      ]}
    />
  )
}