import { createEvent, restore } from 'effector'

import { AccountPlatformName } from '../types'

export const setActivePlatform = createEvent<AccountPlatformName>()

export const $activePlatform = restore<AccountPlatformName>(
  setActivePlatform,
  'PAAP_MT4',
)
