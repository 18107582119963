import axiosLib from 'axios'
import { sleepFn } from 'libs/sleep'
import { setGlobalError } from 'ui/templates/generic/model'

import { $isTokenRefreshing, setTokenRefreshingEvent } from './axiosStateModel'
import { successReq } from './requestInterceptors'
import { logoutUser, refreshTokens } from './utilsFn'

export const axios = axiosLib.create({
  baseURL: '/api',
})

axios.interceptors.request.use(successReq, (error) => {
  return Promise.reject(error)
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (
      error.config.url === '/auth/v1/login/refresh' &&
      error.response.status === 403
    ) {
      logoutUser()
    }

    const originalRequest = error.config

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      const isTokenRefreshing = $isTokenRefreshing.getState()

      if (isTokenRefreshing) {
        await interval()
        return axios(originalRequest)
      }

      setTokenRefreshingEvent(true)

      await refreshTokens()

      return axios(originalRequest)
    }

    if (
      error.response.status === 500 ||
      (error.response.status === 404 &&
        // todo: возможно, нужно передоговориться с беком о кодах ошибок :/
        error.config.url !== '/exchangers/v1/transfer/recipient')
    ) {
      setGlobalError(true)

      setTimeout(() => {
        setGlobalError(false)
      }, 10000)
    }

    return Promise.reject(error)
  },
)

// Зацикливаем промисы, пока токен не обновится
const interval = async (): Promise<any> => {
  const isTokenRefreshing = $isTokenRefreshing.getState()

  if (isTokenRefreshing) {
    await sleepFn(200)
    return interval()
  } else return true
}
