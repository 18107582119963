import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Design } from '../types'

type Props = {
  children?: React.ReactNode
  className?: string
  to: string
  isOpenInNewTab?: boolean;
  reloadDocument?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  design?: Design
}

export const Link: React.FC<Props> = ({
  className, isOpenInNewTab,
  children,
  to,
  onClick,
  design = 'blue',
  reloadDocument,
}) => {
  const [t] = useTranslation()
  let content = children

  if (typeof children === 'string') {
    content = t(String(children))
  }

  return (
    <LinkContainer
      className={className}
      to={to}
      onClick={onClick}
      target={ isOpenInNewTab ? '_blank' : undefined }
      id={`navigate-to-${to}`}
      reloadDocument={reloadDocument}
      data-design={design}
    >
      {content}
    </LinkContainer>
  )
}

export const LinkContainer = styled(RouterLink)`
  font-weight: 400;
  color: var(--color-azure);
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);

  &[data-design='black'] {
    color: var(--color-gray-700);
  }

  &[data-design='sunray'] {
    color: var(--color-sunray);
  }

  &[data-design='opal'] {
    color: var(--color-opal);
  }

  &[data-design='gray'] {
    color: var(--color-gray-500);
  }
`
