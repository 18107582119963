import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { RegisterBySocials } from 'services/registration/oath/types'

const registerBySocial = async (data: RegisterBySocials) => {
  const res = await axios.post<RegisterBySocials>(
    `/auth/v1/register-by-social`,
    data,
  )
  return res.data
}

export const useRegisterBySocial = () => {
  return useMutation({
    mutationFn: registerBySocial,
  })
}
