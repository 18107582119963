import styled, { css } from "styled-components/macro";
import { Text } from "ui/atoms";


export const FilterTab = styled.div<{isActive?: boolean}>`
  border-radius: 8px;
  padding: 6px 12px;
  cursor: pointer;
  min-width: 60px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background: ${({ isActive }) => (isActive ? css`var(--color-gray-400)` : css`var(--color-gray-300)` ) };
  &:hover {
    background-color: var(--color-gray-400);
  }
`

export const GiftStatus = styled(Text)<{ isCanceled?: boolean }>`
  color: ${({isCanceled}) => isCanceled ? css`var(--color-opal-600)`  : css`var(--color-gray-700)` }
`