import { useCallback, useEffect, useState } from "react";
import { useStoreMap, useUnit } from "effector-react";

import { useGetDepositLimits } from "services/payments/mutations/useGetDepositLimits";
import { CurrenciesItem } from "services/payments/get/DepositFormTypes";

import { $customGatewayId } from "../../model";
import { $amountField, $calcAmount, $destenation, onResetCalcAmountEvent, onSetAmountCurrencyEvent, updateAmountFieldEvent } from "../model";

type Options = {
  defaultCurrencyObj: CurrenciesItem;
}

export const useCurrency = ({ defaultCurrencyObj }: Options) => {
  const gatewayId = useUnit($customGatewayId);
  const { destenationCurrency, isFixRate } = useStoreMap($destenation, (state) => ({ 
    destenationCurrency: state?.currency || '',
    isFixRate: state?.isFixRate,
  }));

  const calcAmount = useUnit($calcAmount);
  const activeCurrency = useStoreMap($amountField, (state) => (state!.activeCurrency || defaultCurrencyObj.currencyCode));

  const [limits, setLimits] = useState({
    min: defaultCurrencyObj.minLimit,
    max: defaultCurrencyObj.maxLimit,
  });

  const { mutateAsync, isLoading } = useGetDepositLimits();
  
   const onFetchLimits = useCallback(
    async (newCurrency: string) => {
      if (!gatewayId) return;

      try {
        let currency = newCurrency;
        
        if (isFixRate && newCurrency === 'USD') currency = 'USF';

        const resp = await mutateAsync({ gatewayID: gatewayId, currency });
        setLimits({
          max: resp.maxLimit,
          min: resp.minLimit
        })
      } catch (error) {
      }
    },
    [gatewayId, isFixRate],
  );

  const isFlipCurrency = defaultCurrencyObj.currencyCode !== destenationCurrency;

  const onFlipCurrency = useCallback(
    () => {
      if (!isFlipCurrency) return;

      const newCurrency = activeCurrency === defaultCurrencyObj.currencyCode 
        ? destenationCurrency 
        : defaultCurrencyObj.currencyCode;
      
      if (calcAmount && calcAmount.currency === newCurrency) {
        updateAmountFieldEvent({
          activeCurrency: calcAmount.currency,
          amount: calcAmount.convertedAmount,
        });
        return;
      }

      onSetAmountCurrencyEvent(newCurrency);
    },
    [activeCurrency, defaultCurrencyObj.currencyCode, destenationCurrency, isFlipCurrency, calcAmount],
  );

  // Меняем лимиты и активную валюту, если валюта аккаунта равна валюте гетвея
  useEffect(() => {
    if (isFlipCurrency) return;

    // setActiveCurrency(defaultCurrencyObj.currencyCode);
    onSetAmountCurrencyEvent(defaultCurrencyObj.currencyCode);
    setLimits({
      max: defaultCurrencyObj.maxLimit,
      min: defaultCurrencyObj.minLimit,
    });
    onResetCalcAmountEvent();
  }, [defaultCurrencyObj, isFlipCurrency]);

  // если выбрали новую валюту, загружаем лимиты
  // не загружаем лимиты, если они пришли в defaultCurrencyObj (контент гетвея)
  useEffect(() => {
    if (!isFlipCurrency) return;
    if (activeCurrency === defaultCurrencyObj.currencyCode) {
      setLimits({
        max: defaultCurrencyObj.maxLimit,
        min: defaultCurrencyObj.minLimit,
      });
      return;
    }

    setLimits({
      max: '',
      min: '',
    });

    onFetchLimits(activeCurrency);
  }, [activeCurrency, isFlipCurrency]);

  useEffect(() => {
    if (!activeCurrency || activeCurrency !== defaultCurrencyObj.currencyCode) return;
    onSetAmountCurrencyEvent(defaultCurrencyObj.currencyCode);
  }, []);
  
  return {
    activeCurrency,
    limits,
    isFlipCurrency,
    isLoadingLimits: isLoading,
    onFlipCurrency
  }
}