import { path } from 'libs/path'
import { useNavigate } from 'react-router-dom'
import { Text, Title } from 'ui/atoms'
import { useWindowSize } from 'usehooks-ts'

import image from './images/404.svg'
import * as Styled from './styled'

export const Error404 = ({ isInner }: { isInner: boolean }) => {
  const navigate = useNavigate()

  const { width } = useWindowSize()
  const isMobileMode = width < 600

  return (
    <Styled.Wrapper hasBorder={isInner}>
      <div>
        <Title level={isMobileMode ? 2 : 1}>Page not found</Title>
        <Text level={isMobileMode ? 2 : 1} className="mt-[16px]">
          The page you’re trying to reach doesn’t exist. Or the address is
          incorrect.
        </Text>
      </div>
      <Styled.Image src={image} alt="404" />
      {isInner && (
        <Styled.Button
          name="dashboard"
          onClick={() => navigate(path.dashboard.entry())}
        >
          Go to dashboard
        </Styled.Button>
      )}
    </Styled.Wrapper>
  )
}
