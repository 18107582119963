import { path } from 'libs/path'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetStrategyForTrader } from 'services/copytrade'

import { BackButton } from '../molecules/backButton'
import { StrategyForm } from '../molecules/strategyForm'

export const EditStrategy = () => {
  const { strategyID } = useParams()

  const { data, isFetching } = useGetStrategyForTrader(strategyID || '')

  useEffect(() => {
    // BottomSheet isOpen bug
    document.body.style.overflow = 'visible'
  }, [])

  return (
    <>
      <BackButton to={path.copytrade.traderEntry()} />
      {isFetching ? (
        'loading...'
      ) : data ? (
        <StrategyForm mode="edit" initialValues={data} />
      ) : null}
    </>
  )
}
