import styled from 'styled-components/macro'
import { InputField, InputSuffix } from 'ui/atoms/input/styled'

export const Wrapper = styled.div`
  ${InputField} {
    padding-inline-end: 49px;
  }

  ${InputSuffix} {
    pointer-events: initial;
    top: 1px;
    inset-inline-end: 13px;
    padding-inline-start: 8px;
    height: calc(100% - 2px);
    transition: background-color (--transition);
  }
`
