import { useMutation } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { CreateWithdrawalParams, CreateWithdrawalResp } from './types';

const postCreateWithdrawal = async (data: CreateWithdrawalParams) => {
  const res = await axios.post<CreateWithdrawalResp>(`/payments/v2/create-withdrawal`, data);

  return res.data;
};

export const usePostCreateWithdrawal = () => {
  return useMutation({
    mutationFn: (data: CreateWithdrawalParams) => postCreateWithdrawal(data),
    retry: retryFn,
  })
};
