enum OAuthSocialType {
  PUBLIC_API_SOCIAL_TYPE_INVALID,
  PUBLIC_API_SOCIAL_TYPE_GOOGLE,
  PUBLIC_API_SOCIAL_TYPE_FACEBOOK,
}

export type OAuthBySocial = {
  socialType: OAuthSocialType
  authorizationCode?: string
  accessToken?: string
  partnerKey?: string
}

export type RegistrationParams = {
  firstName: string
  lastName: string
  email: string
  socialAccessToken: string
}

export enum EAuthSocialName {
  google = 1,
  facebook = 2,
}
