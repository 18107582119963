import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Button, Icon, Size } from '../'

type Props = {
  className?: string
  to?: string
  onClick?: () => void
  size?: Size
}

export const Back = ({ to, className, onClick, size = 'large' }: Props) => {
  const navigate = useNavigate()

  return (
    <BackButton
      className={className}
      name={`navigate-to-${to}`}
      design="tertiary"
      prefix={
        <Icon
          name="arrowLeftOutline"
          size={size === 'large' ? 'medium' : 'small'}
        />
      }
      onClick={() => {
        if (to) {
          navigate(to)
        }

        if (onClick) {
          onClick()
        }
      }}
      size={size}
    >
      Back
    </BackButton>
  )
}

const BackButton = styled(Button)`
  margin: 20px auto 0 auto;
  width: 100%;
`
