import styled, { css } from 'styled-components/macro';

interface StepWrappeType {
  isFirst?: boolean;
  isLast?: boolean;
  count: number;
}
export const StepWrapper = styled.div<StepWrappeType>`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 8.2rem;
  /* width: ${(props) => `calc(100% / ${props.count})`}; */
  ${(props) => {
    if (props.isFirst) return css`
      align-items: start;
      width: 70px;
      flex: 1;
    `

    if (props.isLast) return css`
      /* align-items: end; */
      width: auto;
    `
    return css`
      /* align-items: center; */
      flex: 1;

    `
  }}
`;


interface RoadProps {
  isActive?: boolean;
}
export const Road = styled.div<RoadProps>`
  border: 3px solid;
  flex: 1;
  ${(props) => {
    if (props.isActive) return css`
      border-color: var(--color-sunray-400);
    `

    return css`
      border-color: var(--color-gray-300);
    `
  }}
`

interface PointType {
  isActive?: boolean;
  isNextStep?: boolean;
}
export const Point = styled.div<PointType>`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 8px 0;
  ${(props) => {
    if (props.isActive) return css`
      background-color: var(--color-sunray-500);
    `

    if (props.isNextStep) return css`
      border: 2px solid var(--color-sunray-500);
    `
    return css`
      background-color: var(--color-gray-300);
    `
  }}

`;