import { Text } from 'ui/atoms'
import { path } from 'libs/path'

import * as Styled from './styled'

export const Activity = ({ onClose }: { onClose?: () => void }) => {
  return (
    <Styled.Wrapper>
      {onClose ? <Styled.CloseButton onClick={onClose} /> : null}
      <Styled.Link to={path.copytrade.investorEntry()}>
        <Text level={3} weight="medium">
          Earn more in Copytrade
        </Text>
      </Styled.Link>
    </Styled.Wrapper>
  )
}
