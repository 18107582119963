import React, { PropsWithChildren } from 'react'
import { Text } from 'ui/atoms'

type Props = PropsWithChildren & {
  isActive?: boolean
  isMobile?: boolean
  isDisabled?: boolean
  onSetTime: () => void
  className?: string
}

export const TimeFilter: React.FC<Props> = ({
  children,
  isMobile,
  isActive,
  isDisabled,
  onSetTime,
  className = '',
}) => {
  return (
    <div
      className={`
        min-w-[48px] h-[32px] py-[6px] px-[10px] flex items-center justify-center rounded
        bg-gray.300 cursor-pointer
        ${isActive ? '!bg-sunray.500' : ''}
        ${isDisabled ? 'cursor-not-allowed' : ''}
        ${isDisabled && isActive ? '!bg-gray.400' : ''}
        ${className}
      `}
      onClick={onSetTime}
    >
      <Text
        level={isMobile ? 3 : 2}
        weight="medium"
        className={isDisabled && !isActive ? '!text-gray.500' : ''}
      >
        {children}
      </Text>
    </div>
  )
}
