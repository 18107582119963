import styled from 'styled-components/macro'
import { Button, Title } from 'ui/atoms'
import { CopyIcon as CopyIconUi } from 'ui/molecules/CopyButton/CopyIcon'

import background from './img/background.jpg'
import backgroundWeb from './img/background.webp'
import cross from './img/cross.svg'
import dollar from './img/dollar.svg'
import success from './img/success.png'
import successWeb from './img/success.webp'

export const Container = styled.div`
  position: relative;
  border-radius: 8px;
  height: 100%;
  background-color: var(--color-gray-100);
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
    0px 4px 6px -1px rgba(38, 33, 25, 0.1);
`

export const RegularWrapper = styled.div`
  padding: 24px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--color-gray-100);
`

export const ActiveContent = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-items: start;
`

export const BonusTitle = styled(Title)`
  padding-inline-end: 32px;
  background-image: url(${dollar});
  background-repeat: no-repeat;
  background-size: 24px auto;
  background-position: right center;
`

export const BonusProgress = styled.div`
  margin-inline-start: 4px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  color: var(--color-gray-600);
`

export const ValuesWrapper = styled.div<{ smallGap?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => (props.smallGap ? '40px' : '80px')};
`

export const ValueWrapper = styled.div`
  display: grid;
  gap: 4px;
`

export const SettingsWrapper = styled.div`
  margin-top: 12px;
  border: 1px solid var(--color-gray-300);
  padding: 24px 12px;
  display: grid;
  gap: 24px;
  border-radius: 8px;
`

export const CopyIcon = styled(CopyIconUi)`
  margin-inline-start: 8px;
`

export const ColorWrapper = styled.div`
  height: 100%;
  min-height: 288px;
  border-radius: 8px;
  padding: 16px 20px 20px;
  display: grid;
  background-image: url(${background});
  background-image: image-set(url(${backgroundWeb}), url(${background}));
  background-size: cover;
  background-position: left bottom;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
    0px 4px 6px -1px rgba(38, 33, 25, 0.1);
`

export const StartContent = styled.div`
  max-width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const StartTitle = styled(Title)`
  margin-bottom: 16px;
`

export const RegularText = styled.div`
  line-height: 150%;
`

export const TopText = styled(RegularText)`
  margin-bottom: 32px;
`

export const GetButton = styled(Button)`
  margin-top: auto;
  min-width: 153px;
`

export const ResultWrapper = styled.div`
  display: flex;
  gap: 40px;
`

export const ResultTitle = styled(Title)`
  margin-bottom: 12px;
`

export const ResultRow = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 150%;
`

export const Withdrawal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const FixedButton = styled(Button)`
  width: 160px;

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const DownloadLink = styled.a`
  font-weight: 500;
  color: var(--color-bronze-500);
  transition: color var(--transition);

  &:hover {
    color: var(--color-gray-500);
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  inset-inline-end: 8px;
  width: 24px;
  height: 24px;
  background-image: url(${cross});
  background-size: contain;
  background-position: center center;
`

export const SuccessWrapper = styled.div`
  margin-top: -32px;
  padding-top: 200px;
  width: 100%;
  display: grid;
  gap: 12px;
  justify-items: center;
  text-align: center;
  background-image: url(${success});
  background-image: image-set(url(${successWeb}), url(${success}));
  background-position: top center;
  background-size: 200px auto;
  background-repeat: no-repeat;
`

export const TotalProfit = styled.div`
  border: 1px solid var(--color-gray-300);
  padding: 16px;
  display: flex;
  gap: 40px;
  line-height: 150%;
  border-radius: 8px;
`
