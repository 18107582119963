import { RcFile } from 'rc-upload/lib/interface';
import { axios } from 'services/axios';


export const fetcher = async (uploadUrl: string, file: RcFile) => {
	const formData = new FormData();
	formData.append('file', file);
	const resp = await axios.post(uploadUrl, formData);

	const getFile = await axios.get(uploadUrl, {
		params: {
			uid: file.uid,
			fileName: resp?.data.fileName,
		},
		responseType: 'blob',
	});

	const src = URL.createObjectURL(getFile.data);

	return { src, uid: file.uid, fileName: resp?.data.fileName };
}