import { useState } from 'react'

import * as Styled from './styled'

export const PageSizes = ({
  sizes,
  onChange,
  activeSize,
}: {
  sizes: number[]
  onChange: (size: number) => void
  activeSize: number
}) => {
  return (
    <div className="flex gap-[4px]">
      {sizes.map((size, index) => (
        <Styled.Item
          isActive={size === activeSize}
          onClick={() => onChange(size)}
          key={index}
          type="button"
        >
          {size}
        </Styled.Item>
      ))}
    </div>
  )
}
