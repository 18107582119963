import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { up } from 'styled-breakpoints'
import styled from 'styled-components/macro'

const levels: Record<number, string> = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
}

type TitleProps = {
  className?: string;
  level?: 1 | 2 | 3 | 4;
  withTranslate?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

export const Title: React.FC<TitleProps> = ({
  className,
  level = 1,
  children,
  onClick,
  withTranslate = true,
}) => {
  const [t] = useTranslation()

  return (
    <TitleWrap
      className={className}
      as={levels[level] as any}
      level={level}
      onClick={onClick}
    >
      {withTranslate ? t(children as string) : children}
    </TitleWrap>
  )
}

const map = ({ level }: TitleProps) => ({
  'data-level': level,
})

const TitleWrap = styled.div.attrs(map)`
  font-family: var(--font--primary);

  /* h1 */

  &[data-level='1'] {
    ${up('sm')} {
      font-size: 3.2rem;
      line-height: 4rem;
      font-weight: 500;
    }
  }

  /* h2 */

  &[data-level='2'] {
    ${up('sm')} {
      font-size: 2.4rem;
      line-height: 3rem;
      font-weight: 500;
    }
  }

  /* h3 */

  &[data-level='3'] {
    ${up('sm')} {
      font-size: 2rem;
      line-height: 2.5rem;
      font-weight: 500;
    }
  }

  /* h4 */

  &[data-level='4'] {
    ${up('sm')} {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 500;
    }
  }
`
