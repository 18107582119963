import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Navigation, Pagination } from 'swiper/modules'
import { Button } from 'ui/atoms'

import * as Styled from './styled'

export const WidgetSlider = ({
  children,
  fullHeight = false,
}: {
  children: React.ReactNode
  fullHeight?: boolean
}) => {
  return (
    <Styled.Wrapper
      style={{ height: fullHeight ? '100%' : 'auto' }}
      modules={[Navigation, Pagination]}
      navigation={{
        prevEl: '.prev-slider-button',
        nextEl: '.next-slider-button',
      }}
      pagination={{ clickable: true }}
      spaceBetween={12}
      speed={1}
      breakpoints={{
        601: {
          allowTouchMove: false,
        },
      }}
    >
      {children}
      <Styled.Arrows>
        <Button
          name="swiper-prev"
          className="prev-slider-button"
          design="secondary"
          size="small"
        />
        <Button
          name="swiper-next"
          className="next-slider-button"
          design="secondary"
          size="small"
        />
      </Styled.Arrows>
    </Styled.Wrapper>
  )
}
