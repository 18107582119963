import React from 'react'
import { ProfileRequestStatus, ProfileStatus } from 'services/profile/getProfile'
import { Text } from 'ui/atoms'

import * as S from '../informationBlock/styles'
import { AddressChangeForm } from './AddressChangeForm'
import { CountrySelect } from './CountrySelect'
import { PhoneChangeForm } from './PhoneChangeForm'

interface Props {
  country?: string
  phone?: string
  email?: string
  address?: string
  verificationStatus: ProfileStatus;
  profileStatus?: ProfileRequestStatus;
  updatingDocumentRequest?: boolean;
}

export const PersonalFields: React.FC<Props> = ({
  country = '',
  phone,
  email,
  verificationStatus,
  address,
  profileStatus,
  updatingDocumentRequest,
}) => {
  return (
    <>
      <S.InformationBlockItem>
        <Text>Phone number</Text>
        <PhoneChangeForm currentPhone={phone} />
      </S.InformationBlockItem>

      <S.InformationBlockItem>
        <Text>Email</Text>
        <Text className="break-all">{email}</Text>
      </S.InformationBlockItem>

      <S.InformationBlockItem>
        <Text>Country</Text>
        <CountrySelect
          country={country}
          status={verificationStatus}
          profileStatus={profileStatus}
          updatingDocumentRequest={ updatingDocumentRequest }
        />
      </S.InformationBlockItem>

      <S.InformationBlockItem>
        <Text>Address</Text>
        <AddressChangeForm currentAddress={address} />
      </S.InformationBlockItem>
    </>
  )
}
