import React from 'react';

import { Title, Text } from 'ui/atoms';

import { statusInfoObj } from 'pages/ib/utils/statuses';

import { ProgressStatus } from './ProgressStatus';

import * as S from './styled';

export const Progress = () => {
  return (
    <S.PartnerBlock className='mt-[2rem]'>
      <Title level={1} className='!font-medium'>Partner’s progress</Title>
      <Text className='mt-[8px]'>
        The more clients you attract, the more income you earn.
      </Text>
      <div className='flex gap-[2rem] mt-[3.2rem] flex-col lg:flex-row'>
        <ProgressStatus
          status='PARTNER_STATUS_BRONZE'
          label={statusInfoObj.PARTNER_STATUS_BRONZE.label}
          comission={statusInfoObj.PARTNER_STATUS_BRONZE.comission}
          description='Start earning without any specific requirements. 30% commission from spread for each active client’s order.'
        />
        <ProgressStatus
          status='PARTNER_STATUS_SILVER'
          label={statusInfoObj.PARTNER_STATUS_SILVER.label}
          comission={statusInfoObj.PARTNER_STATUS_SILVER.comission}
          description='In the second stage, you can get 35% from the traded spread once you have at least 5 active clients who trade at least 300 lots.'
        />
        <ProgressStatus
          status='PARTNER_STATUS_GOLD'
          label={statusInfoObj.PARTNER_STATUS_GOLD.label}
          comission={statusInfoObj.PARTNER_STATUS_GOLD.comission}
          description='Here you can get a reward of 38% from each of your clients’ spreads once you have 7 active clients who trade at least 1500 lots.'
        />
        <ProgressStatus
          status='PARTNER_STATUS_ROYAL'
          label={statusInfoObj.PARTNER_STATUS_ROYAL.label}
          comission={statusInfoObj.PARTNER_STATUS_ROYAL.comission}
          description='Here you can get a reward of 38% from each of your clients’ spreads once you have 7 active clients who trade at least 1500 lots.'
        />

      </div>
    </S.PartnerBlock>
  )
}
