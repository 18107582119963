import { useStore } from 'effector-react';
import { $bonusVisible, $mobileAdVisible } from 'features/session/model'
import { GiftshopBanner } from 'pages/giftshop/molecules/banner';
import { IBDashboardWidget } from 'pages/ib/widgets';
import React from 'react';
import { useGetPartnerPage } from 'services/ib/get/useGetPartnerPage';
import { MetaTraders, MobileApp, Tabs, Widget } from 'ui/molecules';

import { AccountsList } from '../accounts';
import * as Styled from './styled';
import {
  BonusWidget,
  TransferWidget,
  WalletsWidget,
} from './widgets';
import { walletsMounted } from './widgets/wallets/model';

export const Dashboard = () => {
  const mobileAdVisible = useStore($mobileAdVisible);
  const bonusVisible = useStore($bonusVisible);

  const { data: ibData } = useGetPartnerPage();

  return (
    <Styled.Container>
      <Tabs
        items={[
          {
            label: 'My Accounts',
            children: (
              <Widget>
                <AccountsList />
              </Widget>
            ),
          },
          {
            label: 'My Wallets',
            children: <WalletsWidget />,
            onClick: () => {
              walletsMounted()
            },
          },
        ]}
      />
      <Tabs
        items={[
          {
            label: 'Internal transfer',
            children: <TransferWidget />,
          },
          // {
          //   label: 'Deposit',
          //   children: <DepositWidget />,
          // },

          // {
          //   label: 'Withdrawal',
          //   children: <WithdrawalWidget />,
          // },
        ]}
      />
      
      <Styled.LongBanner>
        <GiftshopBanner />
      </Styled.LongBanner>

      {ibData &&
        ibData?.widgetInfo?.partnerStatus !== 'PARTNER_STATUS_NOT_PARTNER' && (
          <IBDashboardWidget />
        )}

      {bonusVisible && <BonusWidget />}
      
      <MetaTraders />

      {ibData &&
        ibData?.widgetInfo?.partnerStatus === 'PARTNER_STATUS_NOT_PARTNER' && (
          <IBDashboardWidget />
        )}

      {mobileAdVisible && (
        <Styled.LongBanner>
          <MobileApp />
        </Styled.LongBanner>
      )}
    </Styled.Container>
  )
}
