import React from 'react';

import { Text, Icon2 } from 'ui/atoms';

import * as S from './styled';

interface Props {
  steps: {
    label: string;
    helperText: string;
  }[];
  currentStep: number;
  percent?: number;
}

export const Stepper: React.FC<Props> = ({ steps = [], currentStep }) => {
  return (
    <div className='flex justify-between'>
      {
        steps.map((step, index) => (
          <S.StepWrapper
            key={index}
            count={steps.length}
            isFirst={ index === 0 }
            isLast={ index === steps.length - 1 }
          >
            <Text
              className={`
                top-0
                !font-medium
                !text-[14px]
                ${index === 0 ? '' : '' }
              `}
              level={2}
            >
              {step.label}
            </Text>

            <div className='flex items-center w-full'>
             {/* { index !== 0 && <S.Road isActive={index < currentStep} /> } */}
              <S.Point
                isActive={index < currentStep}
                isNextStep={index === currentStep}
              >
                {index < currentStep && 
                  <Icon2
                    name='check'
                    size='small'
                  />
                }
              </S.Point>
              {index !== steps.length - 1 && <S.Road isActive={index < currentStep} /> }
            </div>

            <Text className='!font-medium !text-[10px]' level={3}>{step.helperText}</Text>
          </S.StepWrapper>
        ))
      }
    </div>
  )
}
