export const setCookie = (name: string, value: string, maxAge?: number) => {
  document.cookie = `${name}=${value}; max-age=${maxAge || 31536000} ;path=/;`
}

export const getCookie = (name: string) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.cookie.match(regex)

  if (match) {
    return match[2]
  }
}
