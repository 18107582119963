import { useEffect, useState } from 'react'
import { Text } from 'ui/atoms'

import { ModalDialog } from '../'
import * as Styled from './styled'

export const LogoutModal = ({
  onLogout,
  onReset,
  onClose,
}: {
  onLogout: () => void
  onReset: () => void
  onClose: () => void
}) => {
  const [timeLeft, setTimeLeft] = useState(30)

  useEffect(() => {
    if (timeLeft === 0) {
      onLogout()
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  const handleReset = () => {
    onReset()
    onClose()

    // modal bug
    document.body.style.overflow = 'visible'
  }

  return (
    <ModalDialog
      isOpen={true}
      onOk={handleReset}
      onCancel={onLogout}
      cancelLabel="Logout"
      okLabel="Keep working"
      title="Session timeout"
    >
      <Text>Your current session will expire in</Text>
      <Styled.Timer>0:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}</Styled.Timer>
    </ModalDialog>
  )
}
