import { ChangeEvent, useEffect, useState } from 'react'
import { Input } from 'ui/atoms'

import * as Styled from './styled'
import { proportions } from './utils'

export const ProportionForm = ({
  initialValue,
  error,
  onChange,
}: {
  initialValue?: number
  error?: string
  onChange: (value: number) => void
}) => {
  const setInitialActiveTab = () =>
    proportions.some((proportion) => proportion === initialValue)
      ? initialValue
      : undefined
  const setInitialCustomValue = () =>
    proportions.every((proportion) => proportion !== initialValue)
      ? `${initialValue}`
      : ''

  const [activeTab, setActiveTab] = useState(setInitialActiveTab())
  const [customValue, setCustomValue] = useState(setInitialCustomValue())

  const [clientError, setClientError] = useState('')

  const handleTabChange = (tab: number) => {
    setCustomValue('')

    setActiveTab(tab)
  }

  const handleCustomValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setActiveTab(undefined)

    const output = event.target.value.replace(/[^.\d]/g, '').split('.')

    setCustomValue(
      output.length > 1
        ? output.shift() + '.' + output.join('').slice(0, 1)
        : output[0],
    )
  }

  useEffect(() => {
    onChange(activeTab || +customValue)
  }, [customValue, activeTab])

  useEffect(() => {
    setClientError(
      customValue && (+customValue < 0.1 || +customValue > 1000)
        ? 'Add value from 0.1 to 1000'
        : '',
    )
  }, [customValue])

  return (
    <>
      <Styled.OptionsWrapper>
        {proportions.map((proportion, index) => (
          <Styled.Option
            key={index}
            isActive={proportion === activeTab}
            onClick={() => handleTabChange(proportion)}
          >
            {proportion.toFixed(1)}
          </Styled.Option>
        ))}
      </Styled.OptionsWrapper>
      <Input
        placeholder="Add custom value"
        value={customValue}
        onChange={handleCustomValueChange}
        errorMessage={clientError || error}
      />
    </>
  )
}
