import { format } from 'date-fns'
import { useStore } from 'effector-react'
import { $wallets } from 'pages/dashboard/widgets/wallets/model'
import * as React from 'react'
import { Operation } from 'services/transaction-history/types'

import { $accounts } from '../model'
import {
  $page,
  $perPage,
  $total,
  $transactions,
  resetFilters,
  setPage,
  setPerPage,
  setStatuses,
  setTimeline,
  setTransactionsType,
  setAccountIDs,
  setWalletIDs,
  transactionsMounted,
} from './model'

const formatDate = (date: string) =>
  format(new Date(+date * 1000), 'LLL d, iiii')

export const useTransactions = () => {
  const accounts = useStore($accounts)
  const wallets = useStore($wallets)

  const page = useStore($page)
  const perPage = useStore($perPage)
  const total = useStore($total)

  const transactions = useStore($transactions)

  const handlePaginationChange = (page: number, perPage: number) => {
    setPage(page)
    setPerPage(perPage)
  }

  const handleChangeStatus = (status: string) => {
    if (status === 'all') {
      setStatuses([
        'OS_PENDING',
        'OS_MISSING_INFORMATION',
        'OS_SUCCESS',
        'OS_REJECTED',
      ])
    } else {
      setStatuses([status])
    }
  }

  React.useEffect(() => {
    transactionsMounted()
  }, [])

  return {
    accounts,
    wallets,
    transactions: Object.entries(
      transactions?.items.reduce((acc, value) => {
        if (acc[formatDate(value.createdAt)]) {
          acc[formatDate(value.createdAt)].push(value)
        } else {
          acc[formatDate(value.createdAt)] = [value]
        }
        return acc
      }, {} as { [key: string]: Operation[] }) || {},
    ),
    setTransactionsType,
    setTimeline,
    setStatus: handleChangeStatus,
    setAccounts: setAccountIDs,
    setWallets: setWalletIDs,
    page,
    perPage,
    total,
    handlePaginationChange,
    resetFilters,
  }
}
