import React from 'react';
import { usePostJoinPartner } from 'services/ib/mutation';
import { Button, Text, Title } from 'ui/atoms';

import * as S from './styled';

export const HaventIbBanner: React.FC = () => {
  const { mutateAsync, isLoading } = usePostJoinPartner();

  return (
    <>
      <S.PromoBanner>
        <S.JoinIBBannerWrapper>
          <Title className="lg:w-[40rem] !font-medium max-w-[390px]" level={1}>
            Your most promising IB program with up to 42% of our revenue
          </Title>

          <Text className="mt-[3.2rem] max-w-[200px]">
            Generous terms and easy withdrawals with no fees
          </Text>

          <Button
            design="primary"
            onClick={() => mutateAsync()}
            loading={isLoading}
            disabled={isLoading}
            className="min-w-[19.2rem] mt-[3.2rem]"
            name="joinNow"
          >
            Join now
          </Button>
        </S.JoinIBBannerWrapper>
      </S.PromoBanner>
    </>
  )
}
