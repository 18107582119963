import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { ResultWithdrawalDynamic } from './ResultWithdrawalDynamic';
import { ResultDepositPage } from '../ResultDepositPage';


export const ResolveWithdrawalPages = () => {
  let [searchParams] = useSearchParams();

  // Пока что все результирующие страницы были через депозит
  // TODO доделать и оставить только одну версию
  return !!searchParams.get('isDynamic') ? <ResultWithdrawalDynamic /> : <ResultDepositPage/>
}
