import { useMutation } from '@tanstack/react-query';

import { retryFn } from 'services/helpers/retryFn';
import { axios } from 'services/axios';


const postChangeUserPwd = async (newPassword: string) => {  
  await axios.post('/auth/v1/change-password', {
    action: 'PROFILE',
    newPassword,
  });
}

export const usePostChangeUserPwd = () => {
  return useMutation({
    mutationFn: postChangeUserPwd,
    retry: retryFn,
  })
}