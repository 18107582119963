import React, { useMemo } from 'react';
import { DepositForm } from 'services/payments/get/DepositFormTypes';

import { CreateDepositResponse } from 'services/payments/mutations/useCreateDeposit';
import { Button, Icon2, Text } from 'ui/atoms';

import { BtcForm, BtcPayload } from './BTC';

type Props = {
  resp: CreateDepositResponse;
  depositData: DepositForm;
  onSuccessCreateDep: (resp: CreateDepositResponse) => void;
  onBack: () => void;
}

export const ApprovePagesCombine: React.FC<Props> = ({ resp, onBack, depositData }) => {

  const approveForm = useMemo(() => {
    if (resp.requestType === 'RT_CRYPTO') {
      return (
        <BtcForm
          address={resp.URL}
          payload={resp.payload as BtcPayload}
          transactionID={ resp.transactionID }
        />
      );
    }

    return null
  }, [resp]);

  return (
    <div className='w-full lg:mb-[1.6rem]'>
      <Button
        name='backButton'
        design='tertiary'
        onClick={ onBack }
      >
        <Icon2 name='arrowLeft' className='me-3'/>
        <Text level={2} weight='medium'>Back</Text>
      </Button>

      <div className='flex justify-center w-full items-center flex-col'>
        <img src={depositData?.gatewayLogo} alt='gateway_logo' className='w-[64px] h-[64px] rounded-full' />
      </div>

      { approveForm }
    </div>
  )
}
