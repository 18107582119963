import { useQuery } from '@tanstack/react-query';

import { retryFn } from 'services/helpers/retryFn';
import { axios } from 'services/axios';

import { LoginHistoryListData } from './types';


// export const GET_USER_LOGIN_HISTORY = (page: string | number) => ['user', 'socials'];

const getLoginHistoryList = async (page: string | number, perPage: number = 12) => {
  try {
    const profileData = await axios.get<LoginHistoryListData>('/auth/v1/history-logins', {
      params: {
        page,
        perPage,
      }
    });
  
    return profileData.data;
  } catch (error) {
    
  }
}

interface GetLoginHistoryOptions {
  page: string | number;
  perPage?: number;
}


export const useGetLoginHistoryList = ({ page, perPage }: GetLoginHistoryOptions)  => {
  return useQuery({
    queryKey: ['auth', 'login-history', page],
    queryFn: () => getLoginHistoryList(page, perPage),
    retry: retryFn,
    staleTime: 1000,
    // Держим старое значение, пока не появится новое
    // Чтобы не прыгали данные
    keepPreviousData: true,
  })
}