import React from 'react';

import { Gateway } from 'services/money/get/types';

import { CustomFormDep, CustomFormDepProps } from './CustomFormDep';
import { Exchanger } from './Exchanger';

type Props = {
  gateway: Gateway;
} & CustomFormDepProps;

export const DepositFormResolver: React.FC<Props> = (props) => {
  if (props.gateway.processingProvider === 'PPP_MANUAL') {
    return <Exchanger gatewayId={props.gatewayId}  onBack={props.onBack} />
  }
  
  return (
    <CustomFormDep { ...props } />
  )
};
