import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

type Props = {
  className?: string
  items?: React.ReactNode[];
  itemClasses?: string;
}

export const List = ({ className, items = [], itemClasses = '' }: Props) => {
  const [t] = useTranslation()

  return (
    <Container className={className}>
      {items.map((text, key) => (
        <Item
          key={key}
          className={itemClasses}
        >
          {typeof text === 'string' ? t(text) : text}
        </Item>
      ))}
    </Container>
  )
}

const Container = styled.ul`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
`

const Item = styled.li`
  &:before {
    content: '•';
    color: var(--color-sunray);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin: 6px 0;
  }
`
