import { useStore } from 'effector-react'
import * as React from 'react'

import { $authSocials, $registrationAvailability, mounted } from './model'

export const useRegistrationEntryPage = () => {
  const registrationAvailability = useStore($registrationAvailability)
  const authSocials = useStore($authSocials)

  React.useEffect(() => {
    mounted()
  }, [mounted])

  return {
    registrationAvailability,
    authSocials: authSocials.filter(({ isEnabled }) => isEnabled),
  }
}
