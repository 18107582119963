import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { TraderStrategiesList } from '../types'

export const GET_TRADER_STRATEGIES_KEY = ['copytrade-trader-strategies']

const getTraderStrategies = async () => {
  const res = await axios.get<TraderStrategiesList>(
    '/copytrade/v1/trader/strategies',
  )

  return res.data.items
}

export const useGetTraderStrategies = () => {
  return useQuery({
    queryKey: GET_TRADER_STRATEGIES_KEY,
    queryFn: getTraderStrategies,
  })
}
