import { useMutation } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { CalculatedV1Resp, DepositCalculateBody } from './types';
import { delEmptyNumFloat } from 'ui/molecules/hooksForm/FormInput';

const postCalculateWithdrawal = async (data: DepositCalculateBody) => {
  const reqData = {
    ...data,
    amount: delEmptyNumFloat(data.amount),
  }

  const res = await axios.post<CalculatedV1Resp>(`/payments/v1/calculate-withdrawal`, reqData);

  return res.data;
};

export const useCalculateWithdrawal = () => {
  return useMutation({
    mutationFn: (data: DepositCalculateBody) => postCalculateWithdrawal(data),
    retry: retryFn,
  })
};
