import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { ScrollWithBlur } from 'ui/atoms/scrollWithBlur'

type TabProps = {
  label: string
  children: React.ReactNode
  onClick?: () => void
  disabled?: boolean
}

type TabsProps = {
  items: TabProps[]
  destroyUnactive?: boolean
  defaultValue?: string | null
  value?: string
  classes?: string
  fullHeight?: boolean
  withOutWrapp?: boolean
  tabLabelClasses?: string;
  contentWrapClasses?: string
}

export const Tabs: React.FC<TabsProps> = ({
  items,
  defaultValue,
  destroyUnactive,
  value,
  contentWrapClasses,
  classes = '',
  fullHeight = true,
  withOutWrapp,
  tabLabelClasses,
}) => {
  const [t] = useTranslation()

  const [activeLabel, setActiveLabel] = React.useState(
    defaultValue || items[0]?.label || 0,
  )

  useEffect(() => {
    if (!value) return

    setActiveLabel(value)
  }, [value])

  const renderItems = () => {
    if (destroyUnactive) {
      const elem = items.find((tabElem) => tabElem.label === activeLabel)

      if (!elem) return null

      return <TabContainer data-active={true}>{elem.children}</TabContainer>
    }

    return items.map(({ children, label }, key) => (
      <TabContainer data-active={label === activeLabel} key={key}>
        {children}
      </TabContainer>
    ))
  }

  return (
    <TabsContainer
      className={classes}
      data-height={fullHeight ? 'full' : 'default'}
    >
      <TabLabels className=''>
        {items.map(({ label, onClick, disabled }, key) => (
          <TabLabel
            className={ tabLabelClasses }
            key={key}
            onClick={
              !disabled
                ? () => {
                    setActiveLabel(label)
                    onClick && onClick()
                  }
                : undefined
            }
            data-active={label === activeLabel}
            data-disabled={disabled}
          >
            {t(label)}
          </TabLabel>
        ))}
      </TabLabels>
      {withOutWrapp ? (
        renderItems()
      ) : (
        <TabContent className={contentWrapClasses}>{renderItems()}</TabContent>
      )}
    </TabsContainer>
  )
}

export const TabContent = styled.div`
  position: relative;
  padding: 24px 20px;
  background-color: var(--color-gray-100);
  box-shadow: 0px 4px 6px -1px rgba(38, 33, 25, 0.1),
    0px 2px 4px -1px rgba(38, 33, 25, 0.06);
  border-start-start-radius: 0;
  border-start-end-radius: 8px;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;

  @media (max-width: 599px) {
    padding: 24px 12px;
  }
`

const TabsContainer = styled.div`
  width: 100%;

  &[data-height='full'] {
    height: 100%;

    ${TabContent} {
      height: calc(100% - 41px);
      min-height: 433px;
    }
  }
`

const TabLabels = styled(ScrollWithBlur)`
  display: flex;
  flex-direction: row;
`

const TabLabel = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  height: 41px;
  background-color: var(--color-gray-400);
  border-start-start-radius: 8px;
  border-start-end-radius: 8px;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
  transition: var(--transition);
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  font-weight: 500;
  cursor: pointer;

  &[data-active='true'] {
    background-color: var(--color-gray-100);
  }

  &[data-disabled='true'] {
    background-color: transparent;
    cursor: not-allowed;
    color: var(--color-gray-500);
    user-select: none;
  }
`

const TabContainer = styled.div`
  display: none;
  opacity: 0;
  width: 100%;
  height: 100%;

  &[data-active='true'] {
    display: block;
    opacity: 1;
  }
`
