import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { langs } from 'ui/constants'

import { Dropdown } from '../dropdown'
import * as Flags from './images'

type Props = {
  className?: string
}

const defaultLang = langs[0]

export const LanguageSwitcher: React.FC<Props> = ({ className }) => {
  const { i18n, t } = useTranslation()

  const activeLanguage = langs.some((lang) => lang.value === i18n.language)
    ? i18n.language
    : defaultLang.value

  const activeLangFlag = langs.find((lang) => lang.value === activeLanguage)?.icon || 'englishFlag';

  return (
    <LanguageSwitcherWrap className={className}>
      <Dropdown
        name="language-switcher"
        label={
          <ActiveLang
            // @ts-expect-error
            urlMain={Flags[activeLangFlag]}
            // @ts-expect-error
            urlWebp={Flags[`${activeLangFlag}Web`]}
          >
            {activeLanguage.toLocaleUpperCase()}
          </ActiveLang>
        }
        items={langs.map(({ label, value, icon }) => ({
          label: (
            <LangItem
              // @ts-expect-error
              urlMain={Flags[icon] as string}
              // @ts-expect-error
              urlWebp={Flags[`${icon}Web`] as string}
            >
              {t(label, { lng: value })}
            </LangItem>
          ),
          onClick: () => {
            i18n.changeLanguage(value)
          },
        }))}
      />
    </LanguageSwitcherWrap>
  )
}

const LanguageSwitcherWrap = styled.div`
  position: relative;
`

const ActiveLang = styled.div<{urlMain: string, urlWebp: string}>`
  padding-inline-start: 32px;
  background-size: 24px auto;
  background-repeat: no-repeat;
  background-image: ${({ urlMain }) => `url(${urlMain})`};
  background-image: ${({ urlMain, urlWebp }) => `image-set(url(${urlWebp}), url(${urlMain}))`};
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'right center' : 'left center'};
`

const LangItem = styled.div<{urlMain: string, urlWebp: string}>`
  padding-inline-start: 42px;
  min-height: 20px;
  background-size: 30px auto;
  background-repeat: no-repeat;
  background-image: ${({ urlMain }) => `url(${urlMain})`};
  background-image: ${({ urlMain, urlWebp }) => `image-set(url(${urlWebp}), url(${urlMain}))`};
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'right center' : 'left center'};
`
