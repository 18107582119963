import { useStoreMap, useUnit } from 'effector-react';
import React, { useMemo } from 'react';

import { PaymentMethod } from 'services/money/get/types';
import { Text, Title } from 'ui/atoms';
import { $amountField, $calcAmount, $destenation } from './model';
import { $customWithdrawalStore } from '../../model/withdrawalStore';


type Props = {
  defaultCurrency: string;
}

type TextObjType = {
  PMT_EXCHANGER: 'You will send',
  default: 'money.customForm.willReceive',
} & Partial<Record<PaymentMethod, string>>;

const textObj: TextObjType = {
  'PMT_EXCHANGER': 'You will send',
  default: 'money.customForm.willReceive',
};

export const ReciveBlock = ({ defaultCurrency,  }: Props) => {
  const destenationCurrency = useStoreMap($destenation, (state) => state?.currency);
  const amountField = useUnit($amountField);
  const calcAmount = useUnit($calcAmount);
  const paymentMethod = useStoreMap($customWithdrawalStore, (state) => state?.paymentMethodType);

  const amount = useMemo(() => {
    if (amountField.errorType) {
      return '0.00'
    }

    if (calcAmount.currency === defaultCurrency) return calcAmount.convertedAmount;

    return amountField.amount;
  }, [amountField, destenationCurrency, calcAmount]);

  return (
    <div className='mt-[44px] flex flex-col items-center'>
      <Text weight='medium' level={2}  className='!text-gray.600'>{textObj[paymentMethod!] || textObj.default }</Text>
      <div className='flex items-end mt-[12px]'>
        <Title withTranslate={ false } level={1} className='!text-eucalyptus.600 mr-[8px]'>{amount || '0.00'}</Title>
        <Text withTranslate={ false } weight='medium' className='mb-[4px] !text-gray.600'>
          { defaultCurrency || 'IDR' }
        </Text>
      </div>
    </div>
  )
}
