import { useQuery } from '@tanstack/react-query';
import { omitBy } from 'lodash';

import { axios } from 'services/axios';
import { PartnersList } from './types';
import { SortObjParams } from 'pages/ib/models/tableSortStore';


export const PARTNER_PARTNERS_LIST_KEY = 'PARTNER_PARTNERS_LIST_KEY';

const getPartners = async (params: Options) => {
  const sortDirection = params.sort?.direction && (params.sort?.direction === 'asc' ? 'PASD_ASC' : 'PASD_DESC');

  const paramsObj = {
    page: params.page,
    perPage: params.perPage,
    'filter.partnerID': params.filter?.partnerID,
    'filter.commissionPeriod.from': params.filter?.commissionPeriod?.from,
    'filter.commissionPeriod.to': params.filter?.commissionPeriod?.to,
    'filter.partnerLevel': params.filter?.partnerLevel,
    'sorting.field': params.sort?.name,
    'sorting.direction': sortDirection,
  };
  const res = await axios.get<PartnersList>(`/ib/v1/partner/partners-accounts`, {
    params: omitBy(paramsObj, (val) => !val),
  });

  return res.data;
};

interface Options {
  page: number;
  perPage: number;
  sort?: SortObjParams | null;
  filter?: {
    partnerID?: string;
    partnerLevel?: string;
    commissionPeriod?: {
      to?: string;
      from?: string;
    }
  }
}

export const useGetPartners = ({ page = 1, perPage = 10, sort, filter }: Options) => {
  return useQuery({
    queryKey: [PARTNER_PARTNERS_LIST_KEY, page, perPage, sort, filter],
    queryFn: () => getPartners({ page, perPage, sort, filter }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 1000,
  })
};
