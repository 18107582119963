import * as React from 'react'
import styled from 'styled-components/macro'

import { Button } from '../../atoms'

type Props = {
  className?: string
  items: {
    label: string
    value: string
    onClick?: () => void
    isActive?: boolean
  }[]
}

export const Steps = ({ className, items }: Props) => {
  const [activeValue, setActiveValue] = React.useState<string>(items[0].value)

  return (
    <StepsContainer className={className}>
      <StepsList>
        {items.map(({ label, value, isActive, onClick }, key) => (
          <StepsItem data-active={value === activeValue} key={key}>
            <StepsButton
              design={isActive ? 'primary' : 'secondary'}
              name={`${value}-step`}
              onClick={() => {
                setActiveValue(value)
                onClick && onClick()
              }}
              disabled={!isActive}
            >
              {label}
            </StepsButton>
          </StepsItem>
        ))}
      </StepsList>
    </StepsContainer>
  )
}

const StepsContainer = styled.div``

const StepsList = styled.div`
  display: flex;
`

const StepsItem = styled.div`
  display: flex;
  align-items: center;

  &:after {
    content: '';
    display: inline-block;
    width: 40px;
    height: 1px;
    background-color: var(--color-gray-400);
    margin: 0 8px;

    @media (max-width: 450px) {
      width: 10px;
    }
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`

const StepsButton = styled(Button)`
  text-transform: capitalize;
  min-width: 140px;

  &[data-size='large'] {
    height: 44px;

    @media (max-width: 600px) {
      min-width: 0;
      padding: 5px;
    }
  }
`
