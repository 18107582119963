import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';

import { AxiosError } from 'axios';
import { GET_PROFILE_KEY } from 'services/profile/getProfile/useGetProfile';
import { ProfileContent } from 'services/profile/getProfile';


const putChangeUserAddress = async (address: string) => {  
  await axios.put('/user/v1/address', {
    address,
  });
}

export const usePutChangeUserAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putChangeUserAddress,
    retry: retryFn,
    onSuccess(data, address) {
    // Эта функция перезаписывает кэш
    queryClient.setQueryData
      (
        GET_PROFILE_KEY, 
        (oldData: any) => ({ 
          ...oldData as ProfileContent, 
          address,
        }),
      );
    },
  })
}