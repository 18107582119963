import { setAccessToken, setRefreshToken, Session, logout } from 'features/session';

import { axios } from './axiosInstance';
import { QueryCache } from '@tanstack/react-query';
import { newTokenEvent, setTokenRefreshingEvent } from './axiosStateModel';

export const refreshTokens = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken')?.replaceAll(`"`, '');

    const res = await axios.patch<Session>('/auth/v1/login/refresh', {
      refreshToken,
    });
  
    if  (!res.data?.identificationResult) return;
    
    setAccessToken(res.data.identificationResult.accessToken);
    newTokenEvent(res.data.identificationResult.accessToken)
    setRefreshToken(res.data.identificationResult.refreshToken);
  
    return res.data.identificationResult;
  } catch (error) {
    return error;
  } finally {
    setTokenRefreshingEvent(false);
  }
}

export const logoutUser = async () => {
    const queryClient = new QueryCache();

    queryClient.clear();
    logout();
    return;
  }
