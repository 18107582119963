import 'tippy.js/animations/shift-away.css'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'ui/atoms'
import { useElementSize } from 'usehooks-ts'

import * as Styled from './styled'
import type { CheckboxSelectProps } from './types'

// todo: объединить с обычным селектом, добавить mode = multiple 👀
export const CheckboxSelect = ({
  size = 'small',
  ...props
}: CheckboxSelectProps) => {
  const [t] = useTranslation()

  const [ref, { width }] = useElementSize()

  const [isOpened, setIsOpened] = useState(false)

  const allSelected = props.values.length === props.options.length

  const handleClickOption = (value: string | number) => {
    let newValues = props.values

    if (props.values.some((selectedValue) => selectedValue === value)) {
      newValues = newValues.filter((newValue) => newValue !== value)
    } else {
      newValues.push(value)
    }

    props.onChange(newValues)
  }

  const handleSelectAll = () => {
    props.onChange(
      allSelected ? [] : props.options.map((option) => option.value),
    )
  }

  const currentLabel =
    props.values.length === 0 || allSelected
      ? t(props.allOptionsLabel || 'All')
      : props.values
          .map(
            (selectedValue) =>
              props.options.find((option) => option.value === selectedValue)
                ?.label || '',
          )
          .join(', ')

  return (
    <Styled.Tippy
      animation="shift-away"
      duration={100}
      arrow={false}
      visible={isOpened}
      maxWidth={width}
      appendTo="parent"
      offset={[0, 8]}
      onClickOutside={() => setIsOpened(false)}
      content={
        <Styled.OptionsList>
          <Styled.OptionWrapper onClick={handleSelectAll}>
            <div className="flex items-center gap-[8px] overflow-hidden">
              <Checkbox size="large" design="brand" checked={allSelected} />
              <span className="truncate">
                {t(props.allOptionsLabel || 'All')}
              </span>
            </div>
          </Styled.OptionWrapper>
          {props.options.map((option) => (
            <Styled.OptionWrapper
              key={option.value}
              onClick={() => handleClickOption(option.value)}
            >
              <div className="flex items-center gap-[8px] overflow-hidden">
                <Checkbox
                  size="large"
                  design="brand"
                  checked={props.values.some((value) => value === option.value)}
                />
                <span className="truncate">{option.label}</span>
              </div>
            </Styled.OptionWrapper>
          ))}
        </Styled.OptionsList>
      }
      interactive
      placement="bottom-start"
    >
      <Styled.Container
        onClick={() => setIsOpened(!isOpened)}
        ref={ref}
        className={props.className}
        size={size}
      >
        {typeof currentLabel === 'string' ? (
          <Styled.ValueField readOnly value={currentLabel} />
        ) : (
          <Styled.ValueField as="div">{currentLabel}</Styled.ValueField>
        )}
        <Styled.Chevron isOpened={isOpened}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
          >
            <path
              stroke="var(--color-gray-700)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M3.75 13.125 10 6.875l6.25 6.25"
            />
          </svg>
        </Styled.Chevron>
      </Styled.Container>
    </Styled.Tippy>
  )
}
