import styled from 'styled-components/macro'
import { Button } from 'ui/atoms'

import defaultBackground from '../../images/strategy-image.svg'

export const Wrapper = styled.div`
  flex-shrink: 0;
  display: grid;
  justify-items: center;
  gap: 12px;
`

export const Preview = styled.div<{ background?: string }>`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-image: url(${({ background }) => background || defaultBackground});
  background-position: center center;
  background-size: cover;
`

export const UploadButton = styled(Button)`
  min-width: 140px;

  @media (max-width: 599px) {
    min-width: auto;
    width: 100%;
  }
`

export const ErrorText = styled.div`
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  text-align: center;
  color: var(--color-opal-600);
`
