import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export const GET_IB_LANDINGS_FILTERS_KEY = ['ib-landings-filters']

const getLandingsFilters = async () => {
  const res = await axios.get<{
    languages: { value: string; isSelected: string }[]
  }>('/ib-materials/v1/landings-filters')

  return res.data.languages
}

export const useGetLandingsFilters = () => {
  return useQuery({
    queryKey: GET_IB_LANDINGS_FILTERS_KEY,
    queryFn: getLandingsFilters,
  })
}
