import styled from 'styled-components/macro'
import { Button } from 'ui/atoms'

import emptyIcon from '../images/empty-list.svg'

export const Wrapper = styled.div`
  padding: 20px;
  display: grid;
  gap: 20px;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  box-shadow: 0px 4px 6px -1px rgba(38, 33, 25, 0.1),
    0px 2px 4px -1px rgba(38, 33, 25, 0.06);

  @media (max-width: 599px) {
    padding: 20px 12px;
    gap: 24px;
  }
`

export const EmptyBlock = styled.div`
  margin: 0 auto;
  padding-top: 140px;
  max-width: 560px;
  display: grid;
  justify-items: center;
  text-align: center;
  background-image: url(${emptyIcon});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 128px auto;
`

export const CreateButton = styled(Button)`
  min-width: 208px;

  @media (max-width: 440px) {
    width: 100%;
  }
`

export const List = styled.div`
  display: grid;
  gap: 8px;
`