import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'
import React, { memo, useCallback } from 'react'
import { LoginHistoryListItem } from 'services/auth/get/types'
import { Text } from 'ui/atoms'

interface Props {
  list: LoginHistoryListItem[]
  isLoading: boolean
  isPreviousData?: boolean
}

const platformLabels = {
  WEB: 'Browser',
  IOS: 'IOS',
  ANDROID: 'Android',
}

export const Component: React.FC<Props> = ({ list = [] }) => {
  const getDate = useCallback((date: number) => {
    const loggedInAtDate = fromUnixTime(date)

    return format(loggedInAtDate, 'PPpp')
  }, [])

  return (
    <tbody className={'relative'}>
      {list?.map((item, index) => (
        <tr
          key={`${item.loggedInAt}-${index}`}
          className="border-t-[0.75px] border-gray.300"
        >
          <td className="w-[40%] p-[1.2rem] pe-[4.8rem]">
            <Text level={2} className="truncate">
              {platformLabels[item.platform]}
            </Text>
          </td>
          <td className="w-[30%] p-[1.2rem] pe-[4.8rem]">
            <Text level={2}>{item.ip}</Text>
          </td>
          <td className="p-[1.2rem] pe-[4.8rem]">
            <Text level={2}>{getDate(item.loggedInAt)}</Text>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export const Body = memo(Component)
