import styled, { css } from 'styled-components/macro'
import { Title } from 'ui/atoms'

export const Wrapper = styled.div`
  display: grid;
`

export const WelcomeTitle = styled(Title)`
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;

  @media (max-width: 599px) {
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 40px !important;
  }
`

export const ContentContainer = styled.div`
  margin: 48px auto;
  width: 100%;
  max-width: 880px;
  display: grid;

  @media (max-width: 599px) {
    margin: 16px auto;
  }
`

export const TabsWrapper = styled.div`
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-top: 56px;
  margin-bottom: -24px;
  display: flex;
  gap: 8px;

  @media (max-width: 599px) {
    margin-top: 40px;
    margin-bottom: -4px;
  }
`

export const Tab = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  padding: 0 12px;
  height: 32px;
  display: grid;
  place-items: center;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  font-weight: 500;
  transition: background-color var(--transition);
  border-radius: 4px;
  background-color: var(--color-gray-300);

  &:hover {
    background-color: var(--color-gray-200);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      pointer-events: none;
      background-color: var(--color-gray-400);

      &:hover {
        background-color: var(--color-gray-400);
      }
    `}
`
