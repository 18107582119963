import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { TraderStrategy } from '../types'

export const GET_STRATEGY_FOR_TRADER = ['copytrade-strategy-for-trader']

const getStrategyForTrader = async (strategyID: string) => {
  const res = await axios.get<TraderStrategy>('/copytrade/v1/trader/strategy', {
    params: { strategyID },
  })

  return res.data
}

export const useGetStrategyForTrader = (strategyID: string) => {
  return useQuery({
    queryKey: GET_STRATEGY_FOR_TRADER,
    queryFn: () => getStrategyForTrader(strategyID),
  })
}
