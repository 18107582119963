import * as Styled from './styled'

export const GiftPrice = ({
  value,
  className,
  size = 'medium',
}: {
  value: number
  className?: string
  size?: 'medium' | 'large'
}) => {
  return <Styled.Wrapper className={className} size={size}>{value}</Styled.Wrapper>
}
