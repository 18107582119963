import { useQuery } from '@tanstack/react-query'
import { setWidgetInfo } from 'pages/giftshop/model'
import { axios } from 'services/axios'

import { WidgetInfo } from '../types'

export const GET_GIFTSHOP_WIDGET_KEY = ['giftshop-widget']

export const getWidgetInfo = async () => {
  const res = await axios.get<{ widgetInfo: WidgetInfo }>(
    '/gift-shop/v1/widget',
  )

  setWidgetInfo(res.data.widgetInfo)

  return res.data.widgetInfo
}

export const useGetWidgetInfo = (enabled = true) => {
  return useQuery({
    queryKey: GET_GIFTSHOP_WIDGET_KEY,
    queryFn: getWidgetInfo,
    enabled
  })
}
