import styled, { css } from 'styled-components/macro'

import info from '../../images/info-icon.svg'
import point from '../../images/point-large.png'

export const PlaceCell = styled.div`
  @media (max-width: 599px) {
    grid-column: span 2;
  }
`

export const LotsTraded = styled.div``
export const PointsWrapper = styled.div``

export const Title = styled.div`
  margin-bottom: 12px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);

  @media (max-width: 599px) {
    margin-bottom: 8px;
  }
`

export const PlaceMedal = styled.div`
  padding: 0 14px;
  display: inline-block;
  background-image: radial-gradient(
    50% 50% at 50% 50%,
    #f4d91e 0%,
    #e9bd1e 100%
  );
  border-radius: 100px;
`

export const MedalImage = styled.img`
  margin-top: -8px;
  width: 86px;
  height: auto;
  display: block;
`

export const PointsAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &::before {
    content: '';
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    background-image: url(${point});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
`

export const Hint = styled.div`
  cursor: pointer;
  margin-bottom: 12px;
  width: 24px;
  height: 24px;
  background-image: url(${info});
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 599px) {
    margin-bottom: 8px;
  }
`

export const Wrapper = styled.div<{ isResult: boolean; isWinner: boolean }>`
  padding: 2px 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(130px, 1fr));
  gap: 40px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 599px) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 24px;
    font-size: 20px;
    line-height: 32px;
  }

  ${({ isResult }) =>
    isResult &&
    css`
      ${Title} {
        margin-bottom: 8px;
        font-size: var(--body-font-size-medium);
        line-height: var(--body-line-height-medium);
        font-weight: 400;
        color: var(--color-gray-600);
      }

      @media (min-width: 600px) {
        ${PlaceCell} {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }

        ${PointsWrapper} {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }

        ${LotsTraded} {
          grid-row: 1 / 2;
        }
      }
    `}

  ${({ isWinner, isResult }) =>
    isWinner &&
    isResult &&
    css`
      @media (min-width: 600px) {
        align-items: end;

        ${PointsWrapper} {
          padding-bottom: 10px;
        }

        ${LotsTraded} {
          padding-bottom: 10px;
        }
      }

      @media (max-width: 599px) {
        gap: 12px 24px;
      }

      ${PlaceCell} {
        display: grid;
        justify-items: center;
      }
    `}
`
