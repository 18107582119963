import { useMask } from '@react-input/mask'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { $userProfile } from 'features/user'
import { A3codes } from 'libs/A3codes'
import { yup } from 'libs/validators'
import {
  BenefitsImage,
  BenefitsImages,
  Container,
  Footer,
  Mondatory,
  MondatoryHeader,
  Person,
  UploadError,
  Uploads,
  UploadsFormat,
} from 'pages/dashboard/verification/pages/request/styled'
import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Alerts, Dialog } from 'ui/molecules'
import { Banner, Field, FieldSelect, List, Title, Tooltip, Text } from 'ui/atoms'

import { UploadDocuments } from '../../atoms'
import { AcceptTypes } from '../../atoms/upload/component'
import { $verification } from '../../model'
import { PersonProps } from '../../types'
import { BenefitsDialog } from '../styled'
import {
  blurry,
  blurryWeb,
  clear,
  clearWeb,
  cropped,
  croppedWeb,
  reflective,
  reflectiveWeb,
} from './images'
import { $errors, setCountry, submitEditForm, submitForm, $country } from './model'

import { RequestButton } from './RequestButton';
import { useIsMobile } from 'libs/hooks/useIsMobile'

const availibleFiles: AcceptTypes[] = ['jpg', 'jpeg', 'png', 'pdf', 'gif']

export const RequestPage = () => {
  const [searchParams] = useSearchParams()
  const isMobile = useIsMobile();

  const [t] = useTranslation();

  const dateOfBirthRef = useMask({
    mask: '____-__-__',
    replacement: { _: /\d/ },
    showMask: true,
    separate: true,
  })

  const user = useStore($userProfile)
  const verification = useStore($verification)
  const errors = useStore($errors)
  const currentCountry = useStore($country);
  const isRejected = verification?.status === 'PAVRS_REJECTED'
  const isBanned = verification?.status === 'PAVRS_BANNED'
  const isAccepted = verification?.status === 'PAVRS_ACCEPTED'

  const isDisabled = useMemo(() => {
    const isEditVerificationMode = !!searchParams.get('edit')
    if (isEditVerificationMode) return false

    return isBanned || isAccepted
  }, [isBanned, isAccepted, searchParams])

  const initialValues = {
    firstName: verification?.firstName || user.firstName,
    lastName: verification?.lastName || user.lastName,
    middleName: verification?.middleName || '',
    dateOfBirth: verification?.dateOfBirth || '',
    address: verification?.address || '',
    country: verification?.country || user.country,
    documentNumber: verification?.documentNumber || '',
  }

  const isHaveAllert = (currentCountry || (verification?.country || user.country)) === 'NGA';

  useEffect(() => {
    return () => {
      setCountry('');
    }
  }, []);

  const defaultUploadPlace = useMemo(() => ([
    { title: t('Upload a document'), icon: 'cloudArrowUp' as const, id: '1' }, 
    { title: t('Upload a document'), icon: 'cloudArrowUp' as const, id: '2' }
  ]), [t]);
  
  return (
    <>
      {isRejected && (
        <Banner
          design="sunray"
          title={verification.reason}
          icon="exclamationTriangle"
          textClasses={isMobile ? '!text-[14px]' : undefined}
          titleClaases={isMobile ? '!text-[14px]' : undefined}
        >
          {verification.comment}
        </Banner>
      )}
      {isBanned && (
        <Banner
          design="opal"
          title={verification.reason}
          icon="exclamationCircle"
          textClasses={isMobile ? '!text-[14px]' : undefined}
          titleClaases={isMobile ? '!text-[14px]' : undefined}
        >
          {verification.comment}
        </Banner>
      )}

      <Container
        initial={initialValues}
        onSubmit={(values) => {
          if (!!searchParams.get('edit')) {
            submitEditForm(values as PersonProps)
            return
          }
          
          submitForm(values as PersonProps)
        }}
        validation={yup.verification}
      >
        <Person>
          <Field
            name="firstName"
            label="First name"
            placeholder="First name"
            error={errors?.fields?.firstName}
            disabled={isDisabled}
          />
          <Field
            name="middleName"
            label="Middle name"
            placeholder="Middle name"
            optional
            error={errors?.fields?.middleName}
            disabled={isDisabled}
          />
          <Field
            name="lastName"
            label="Last name"
            placeholder="Last name"
            error={errors?.fields?.lastName}
            disabled={isDisabled}
          />
          <Field
            name="dateOfBirth"
            label="Date of birth"
            placeholder="yyyy-mm-dd"
            error={errors?.fields?.dateOfBirth}
            disabled={isDisabled}
            ref={dateOfBirthRef}
          />
          <FieldSelect
            label="Country"
            name="country"
            value={verification?.country || user.country}
            placeholder="Choose country"
            onSelect={setCountry}
            disabled={isDisabled}
            options={A3codes.map(({ country, code }) => ({
              label: country,
              value: code,
            }))}
          />
          <Field
            name="address"
            label="Address"
            placeholder="Address"
            optional
            maxLength={100}
            error={errors?.fields?.address}
            disabled={isDisabled}
          />
          <Field
            name="documentNumber"
            label="ID number"
            placeholder="ID number"
            error={errors?.fields?.documentNumber}
            disabled={isDisabled}
          />
        </Person>
        <Uploads>
          <Title level={isMobile ? 4 : 3}>Upload a document</Title>

          { isHaveAllert && 
            <Alerts type='info' className='lg:max-w-[472px] w-full my-[4px]'>
              {t('verification.additionalDoc.alert', {documentName: 'NIN'})}
            </Alerts>
          }

          <Text level={isMobile ? 2 : 1}>
            National ID (front and back side) or Local Passport (two-page opening with a photo)
          </Text>
          <UploadDocuments
            name="user-info"
            accept={availibleFiles}
            limit={5}
            disabled={isDisabled}
            isResetDefaultFiles={!!searchParams.get('edit')}
            defaultUploadPlace={defaultUploadPlace}
            label={
              <>
                Upload a <span>2-page spread with a photo</span>
              </>
            }
          />
          <UploadError>{errors?.fields?.fileNames}</UploadError>

          <Text level={3} withTranslate={false}>
            {t('Supported formats:')}{' '}
            {availibleFiles.map((f) => f.toLocaleUpperCase()).join(', ')}.&nbsp;
            {t('Max file size: 1 MB.', { number: 10})}
          </Text>
        </Uploads>
        <Mondatory>
          <MondatoryHeader className={'lg:items-start items-center'}>
            <Title
              level={isMobile ? 4 : 3} 
              className={isMobile ? '!text-[14px] !leading-[21px]' : undefined}
            >
              Mandatory requirements for all documents
            </Title>
            <Dialog
              positionX="right"
              positionY="top"
              name="verification-benefits"
              confirm={{
                visibility: false,
              }}
              cancel={{
                onCancel: () => {},
                text: 'Get it',
                design: 'primary',
                visibility: true,
              }}
              content={
                <BenefitsDialog>
                  <Title level={4}>Upload only high-quality images</Title>
                  <BenefitsImages>
                    {[
                      [clear, clearWeb],
                      [blurry, blurryWeb],
                      [cropped, croppedWeb],
                      [reflective, reflectiveWeb],
                    ].map((src, key) => (
                      <BenefitsImage src={src[0]} srcSet={src[1]} key={key} />
                    ))}
                  </BenefitsImages>
                  <div />
                </BenefitsDialog>
              }
            >
              <Tooltip isActive={true} />
            </Dialog>
          </MondatoryHeader>
          <List
            itemClasses={isMobile ? '!text-[12px] !leading-[18px]' : undefined}
            items={[
              'The validity period does not exceed the current date.',
              'The photo has not been modified in a graphic editor.',
              'The document is clearly visible, the photo is not blurry.',
            ]}
          />
        </Mondatory>
        <Footer>
          <RequestButton />
        </Footer>
      </Container>
    </>
  )
}