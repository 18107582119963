import { Operation } from "services/transaction-history/types"

import {
  deposit,
  errorIcon,
  internalTransfer,
  pendingIcon,
  successIcon,
  warningIcon,
  withdrawal,
} from 'styles/img'

export const transactionTypes: Record<Operation['operationType'], string> = {
  OT_UNKNOWN: 'Unknown',
  OT_INTERNAL_TRANSFER: 'Internal transfer',
  OT_DEPOSIT: 'Deposit',
  OT_CT_DEPOSIT: 'Deposit',
  OT_WITHDRAWAL: 'Withdrawal',
  OT_CT_WITHDRAWAL: 'Withdrawal',
  OT_BONUS111_DEPOSIT: 'Deposit',
  OT_BONUS111_WITHDRAWAL: 'Withdrawal',
  OT_BONUS111_PROFIT_WITHDRAWAL: 'Withdrawal',
  OT_IB_CLIENT_CASHBACK_DEPOSIT: 'Deposit',
  OT_IB_PARTNER_REBATE_WITHDRAWAL: 'Withdrawal',
  OT_EXCHANGER_TRANSFER_FROM_CLIENT: 'Transfer from client',
  OT_EXCHANGER_CLIENT_TRANSFER_DEPOSIT: 'Deposit',
  OT_EXCHANGER_TRANSFER_TO_CLIENT: 'Transfer to client',
  OT_EXCHANGER_CLIENT_TRANSFER_WITHDRAWAL: 'Withdrawal',
  OT_ANNIVERSARY_CONTESTS_PRIZE_PAYMENT: 'Anniversary Contest',
}

export const transactionIcons: Record<Operation['operationType'], string> = {
  OT_UNKNOWN: internalTransfer,
  OT_INTERNAL_TRANSFER: internalTransfer,
  OT_DEPOSIT: deposit,
  OT_CT_DEPOSIT: deposit,
  OT_WITHDRAWAL: withdrawal,
  OT_CT_WITHDRAWAL: withdrawal,
  OT_BONUS111_DEPOSIT: deposit,
  OT_BONUS111_WITHDRAWAL: withdrawal,
  OT_BONUS111_PROFIT_WITHDRAWAL: withdrawal,
  OT_IB_CLIENT_CASHBACK_DEPOSIT: deposit,
  OT_EXCHANGER_TRANSFER_TO_CLIENT: withdrawal,
  OT_IB_PARTNER_REBATE_WITHDRAWAL: withdrawal,
  OT_EXCHANGER_CLIENT_TRANSFER_DEPOSIT: deposit,
  OT_EXCHANGER_TRANSFER_FROM_CLIENT: deposit,
  OT_EXCHANGER_CLIENT_TRANSFER_WITHDRAWAL: withdrawal,
  OT_ANNIVERSARY_CONTESTS_PRIZE_PAYMENT: deposit,
}

export const transactionStatuses: Record<string, string> = {
  OS_UNKNOWN: 'Unknown',
  OS_SUCCESS: 'Approved',
  OS_PENDING: 'Pending',
  OS_MISSING_INFORMATION: 'Missed information',
  OS_REJECTED: 'Declined',
}

export const statusColors: Record<string, string> = {
  OS_UNKNOWN: 'var(--color-opal-500)',
  OS_SUCCESS: 'var(--color-eucalyptus-600)',
  OS_PENDING: 'var(--color-azure-600)',
  OS_MISSING_INFORMATION: 'var(--color-orange-600)',
  OS_REJECTED: 'var(--color-opal-500)',
}

export const statusIcons: Record<string, string> = {
  OS_UNKNOWN: errorIcon,
  OS_SUCCESS: successIcon,
  OS_PENDING: pendingIcon,
  OS_MISSING_INFORMATION: warningIcon,
  OS_REJECTED: errorIcon,
}