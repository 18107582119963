import { path } from 'libs/path';
import { useTranslation } from 'react-i18next'
import { Title } from 'ui/atoms'

import { useTransactions } from '../../hooks';
import { noTransactions, noTransactionsWeb, notFound, notFoundWeb } from 'styles/img';
import * as Styled from './styled'

export const NotFound = ({ hasFilters = false }: { hasFilters?: boolean }) => {
  const [t] = useTranslation()

  const { resetFilters } = useTransactions()

  return (
    <Styled.Wrapper
      mainUrl={hasFilters ? notFound : noTransactions}
      webpUrl={hasFilters ? notFoundWeb : noTransactionsWeb}
    >
      <Title level={2}>
        {hasFilters ? 'No results found' : 'No transactions yet'}
      </Title>
      <Styled.Text>
        {hasFilters
          ? t('There are no transactions matching your criteria. Please try to reset the filters.')
          : t('Here you will find the history of all transactions: deposits, withdrawals, and internal transfers.')}
      </Styled.Text>
      <Styled.Button
        name="button"
        design={hasFilters ? 'secondary' : 'primary'}
        onClick={() => resetFilters()}
        href={hasFilters ? undefined : path.money.entry()}
      >
        {hasFilters ? 'Reset filters' : 'Deposit'}
      </Styled.Button>
    </Styled.Wrapper>
  )
}
