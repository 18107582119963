import styled from "styled-components/macro";

export const InformationBlockItem = styled.div`
  width: 100%;
  height: 4.8rem;
  padding: 1.2rem 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`
