import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CreateDepositResponse } from 'services/payments/mutations/useCreateDeposit';
import { SourceItem } from 'services/payments/get/types';
import { useGetWithdrawalForm } from 'services/payments/get/useGetWithdrawalForm';
import { AccountSelect } from 'pages/money/molecules/newAccountSelect';
import { ProcessingProvider } from 'services/money/get/types';

import { Alert } from 'ui/molecules/alerts/styled';
import { Button, Icon2, Spinner2, Title, Text } from 'ui/atoms';
import { onResetCalcAmountEvent, setDestenationEvent, updateAmountFieldEvent } from './model';
import { AmountField } from './amount/AmountField';
import { ReciveBlock } from './ReciveBlock';
import { SubmitButton } from './SubmitButton';
import { H2pForm, CoinPayment } from './form';

import { Form } from 'pages/money/molecules/dynamicForm';
import { setPageDataEvent } from '../model';

type Props = {
  gatewayId: string;
  destinationList: SourceItem[];
  destination?: SourceItem;
  gatewayProvider: ProcessingProvider;
  onSuccessCreateDep: (url: CreateDepositResponse) => void;
  onBack: () => void;
}

export const WithdrawalForm = ({ onBack, gatewayId, destinationList, destination, gatewayProvider }: Props) => {
  const { data, isFetching } = useGetWithdrawalForm(gatewayId);

  const isFirstSetData = useRef(true);

  const [t] = useTranslation();

  const defaultCurrency = useMemo(() => {
    if (!data?.currencies) return undefined;

    return data.currencies[0];
  }, [data?.currencies]);

  const isMaintenance = data?.maintenanceInfo?.isMaintenance;

  const onChangeDestenation = (val: SourceItem) => {
    setDestenationEvent(val);
    onResetCalcAmountEvent();
    updateAmountFieldEvent({
      activeCurrency: defaultCurrency?.currencyCode as string,
      amount: '',
      errorType: undefined,
    });
  }

  useEffect(() => {
    if (!data || !isFirstSetData.current) return;

    setPageDataEvent(data);
    isFirstSetData.current = false;
  }, [data]);
  
  
  const amountPrecisions = useMemo(() => {
    if (!data) return {};

    const precisionsObj: Record<string, number> = {};

    data.fields.some(field => {
      const isAmount = field.type === 'FIELD_TYPE_AMOUNT';

      if (isAmount) {
        field.params?.FIELD_TYPE_AMOUNT?.byCurrencies?.forEach(item => {
          precisionsObj[item.currency] = item.precision;
        });
      }

      return isAmount
    })
    return precisionsObj;
  }, [data]);
  
  if (!isFetching && !data) return (
    <div className='w-full mb-[4rem]'>
      <Button
        name='backButton'
        design='tertiary'
        // className='w-[16rem]'
        onClick={ onBack }
      >
        <Icon2 name='arrowLeft' className='me-3'/>
        <Text level={2} weight='medium'>Back</Text>
      </Button>
    </div>
  );  

  const getProviderForm = () => {
    if (!gatewayProvider) return null;

    // if (gatewayProvider === 'PPP_HELP2PAY') {
    //   return <H2pForm formFields={ data?.fields } />;
    // }

    if (gatewayProvider === 'PPP_COINPAYMENTS') {
      return <CoinPayment formFields={ data?.fields } />;
    }

    return <Form formFields={data?.fields} />;
  }
  
  return (
    <>
      <div className='w-full mb-[4rem]'>
        <Button
          name='backButton'
          design='tertiary'
          // className='w-[16rem]'
          onClick={ onBack }
        >
          <Icon2 name='arrowLeft' className='me-3'/>
          <Text level={2} weight='medium'>Back</Text>
        </Button>
        {isFetching
          ? (
            <div className='w-full flex justify-center'>
              <Spinner2 size='large'/>
            </div>
          ) : (
            <>
              <div className='flex justify-center w-full items-center flex-col gap-[24px]'>
                {data?.maintenanceInfo?.maintenanceMessage && (
                  <Alert type='pending' className='lg:w-[520px] w-full'>
                    { data.maintenanceInfo.maintenanceMessage }
                  </Alert>)
                }
                <img src={data?.gatewayLogo} alt='gateway_logo' className='w-[64px] h-[64px] rounded-full' />
                <div className='flex'>
                  <Title level={2}>{data?.gatewayName}</Title>
                  { data?.gatewayNameLink &&
                    <Link to={data.gatewayNameLink} target='_blank'>
                      <Icon2
                        size='small'
                        name='arrowTopRightOnSquare'
                        className='!w-[16px] text-bronze.500 ml-[1rem]'
                      />
                    </Link>
                  }
                </div>
              </div>

              <div className='mt-[40px] w-full flex flex-col items-center'>
                  <AccountSelect
                    onSelect={onChangeDestenation}
                    classes='lg:w-[440px] w-full'
                    data={destinationList}
                    defaultValue={ destination?.id as string  }
                    isDisabled={ isMaintenance }
                  />

                  <AmountField
                    defaultCurrency={defaultCurrency}
                    gatewayId={ gatewayId }
                    isDisabled={ isMaintenance }
                    amountPrecisions={ amountPrecisions }
                  />

                  {getProviderForm()}
              </div>

              <div className='mt-[48px]'>
                { data?.commissionString && (
                  <div className='flex justify-center'>
                    <Icon2 name='checkCircle' className='mr-[12px] text-eucalyptus.500' />
                    <Text weight='medium' className='!text-gray.600'>{`${data?.commissionString}`}</Text>
                  </div>
                )}

                {data?.processingTime && (
                  <div className='flex justify-center mt-[16px]'>
                    <Icon2 name='clockSolid' className='mr-[12px] text-eucalyptus.500' />
                    <Text weight='medium' className='!text-gray.600'>
                      {t('money.customForm.processing', { processingTime: data?.processingTime || '1 min' } )}
                    </Text>
                  </div>
                )}
              </div>

              <ReciveBlock defaultCurrency={ defaultCurrency!.currencyCode } />
      
              {gatewayProvider === 'PPP_MANUAL' && data?.warningMessage &&  (
                <div className='mt-[36px] flex justify-center w-full'>
                  <Icon2 size='small' name='exclamationCircle' className='!text-opal.500 mr-[6px]'/>
                  <div className='max-w-[414px]'>
                    <Text level={3}>
                      {data?.warningMessage}
                    </Text>
                  </div>
                </div>
              )}

              <SubmitButton
                isDisabled={ isMaintenance }
                gatewayProvider={ gatewayProvider }
              />
            </>
          )}
      </div>
    </>
  )
}
