import { $isTokenRefreshing } from "services/axios/axiosStateModel";
import { refreshToken } from "./model";

export const isNeedRefreshReq = (clk: any[]): boolean => {
  const isTokenRefreshing = $isTokenRefreshing.getState();

  if (isTokenRefreshing) return false;

  let isAlreadyFetch = false
  refreshToken.pending.map(is => {
    if (!is) isAlreadyFetch = true;
  })

  if (clk?.length === 0) {
    return false;
  }

  return isAlreadyFetch;
}