import { path } from 'libs/path'
import { Text } from 'ui/atoms'

import * as Styled from './styled'

export const GiftshopActivity = ({ onClose }: { onClose?: () => void }) => {
  return (
    <Styled.Wrapper>
      {onClose ? <Styled.CloseButton onClick={onClose} /> : null}
      <Styled.Link to={path.giftshop.entry()}>
        <Text level={3} weight="medium">
          Trade and get gifts
        </Text>
      </Styled.Link>
    </Styled.Wrapper>
  )
}
