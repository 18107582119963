import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, Icon2, TipyTooltip } from 'ui/atoms';

export const GainTooltip = () => {
  const [t] = useTranslation();

  return (
    <TipyTooltip
      content={
        <div>
          <Text level={3}>
            The ratio of profit to the trader’s investments.
          </Text>
        </div>
      }
    >
      <div>
        <Icon2
          name='questionMarkCircle'
          className='!text-bronze.500'
          size='small'
        />
      </div>
    </TipyTooltip>
  )
}
