import { useStore } from 'effector-react';
import { $userProfile } from 'features/user';
import { path } from 'libs/path';
import { formatNumber, getNumberColor } from 'pages/copytrade/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Strategy } from 'services/copytrade/types';
import { Text } from 'ui/atoms';
import { useWindowSize } from 'usehooks-ts';

import { CopyingLabel } from '../copyingLabel';
import { InteractButtons } from '../interactButtons';
import { RiskLabel } from '../riskLabel';
import { WinRateProgress } from '../winRateProgress';
import { GraphV2 } from './GraphV2';
import * as S from './styled';

type Props = {
  strategy: Strategy;
}

export const TraderList: React.FC<Props> = ({ strategy }) => {
  const [t] = useTranslation()

  const {userID} = useStore($userProfile)

  const { width } = useWindowSize();
  const isMobileMode = width < 600;

  return (
    <S.ListItem
      className='w-full'
      to={path.copytrade.strategyForInvestor(strategy.strategyID)}
    >
      {strategy.isCopying ? <CopyingLabel /> : null}
  
      {/* Name block */}
      <div className='max-w-[312px] flex-1'>
        <S.TraderPreview
          name={strategy.strategyTitle}
          imageUrl={strategy.strategyImageLink}
          achievementTitle={strategy.achievement?.title}
          size='xMedium'
        />
      </div>

      {/* Графики */}
      <div className='max-w-[216px] flex-1'>
        { strategy.performanceChart?.items && <GraphV2 lines={strategy.performanceChart?.items} />}
      </div>

      {/* // INFO */}
      <div className='min-w-[112px]'>
        <div className='flex gap-[6px]'>
          <Text weight='medium' level={1} color='subtext'>Gain</Text>
          <S.GainValue
            level={isMobileMode ? 2 : 1}
            colorOwnProp={getNumberColor(strategy.gain)}
            withTranslate={false}
            weight='medium'
          >
            {+strategy.gain > 0 ? '+' : ''}
            {formatNumber(strategy.gain)}%
          </S.GainValue>
        </div>

        <div className="flex gap-[8px] text-s-medium text-gray.600 font-medium mt-[8px]">
          {t('Commission')}
          <span className="text-gray.700">
            ${formatNumber(strategy.commission)}
          </span>
        </div>

        <div className="py-[2px] flex gap-[8px] text-s-medium text-gray.600 font-medium mt-[4px]">
          {t('Investors')}
          <span className="text-gray.700">
            {formatNumber(strategy.numInvestors)}
          </span>
        </div>
      </div>

      <div className='max-w-[192px] flex-1'>
        <div className="flex gap-[6px] text-medium text-gray.600 font-medium mb-[18px]">
          {t('Win rate')}
          <span className="text-gray.700">{strategy.winRate}%</span>
        </div>

        <WinRateProgress
          profitProgress={+strategy.winRate}
          profit={strategy.profit}
          loss={strategy.loss}
          profitableOrders={strategy.numProfitableOrders}
          lossOrders={strategy.numLossOrders}
          valuesPosition="top"
        />
      </div>

      <div className='max-w-[10rem] flex flex-col items-end min-w-[10rem]'>
        <InteractButtons strategyData={ strategy } />
        <div className='mt-[22px] w-fit'>
          <RiskLabel risk={strategy.risk} size='medium' />
        </div>
      </div>

    </S.ListItem>
  )
}
