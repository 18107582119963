import { useStore } from 'effector-react'
import { useParams } from 'react-router-dom'

import { $activePlatforms, $recordedAccountData } from '../model'
import { $activePlatform } from './model'

export const useAccountCreatePlatform = () => {
  const {
    //
    accountType = 'real',
  } = useParams()

  const activePlatform = useStore($activePlatform)
  const activePlatforms = useStore($activePlatforms)

  const recordedAccountData = useStore($recordedAccountData)

  return {
    activePlatform,
    accountType,
    activePlatforms,
    recordedAccountData,
  }
}
