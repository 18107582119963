import { UserProfile } from 'features/user'
import styled, { css } from 'styled-components/macro'
import { Button, Icon, Logo, Switch } from 'ui/atoms'
import { LanguageSwitcher } from 'ui/molecules'

export const Container = styled.header<{ isFixed: boolean }>`
  width: 100%;
  box-shadow: 0px 1px 0px 0px #2621190f;
  background-color: var(--color-gray-100);

  @media (max-width: 1050px) {
    position: relative;
    z-index: 5;
  }

  ${({ isFixed }) =>
    isFixed &&
    css`
      @media (max-width: 1050px) {
        position: fixed;
        top: 0;
        left: 0;
      }
    `}
`

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  min-height: 56px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media (max-width: 880px) {
    flex-wrap: wrap;
  }

  @media (max-width: 599px) {
    padding: 11px 12px;
  }
`

export const MenuSwitch = styled(Switch)`
  /* margin-inline-end: 4rem; */
  padding: 0 6px;
`

export const HeaderLogo = styled(Logo)``

export const DepositButton = styled(Button)`
  margin-inline-start: auto;
`

export const DepositButtonIcon = styled(Icon)`
  margin-inline-end: -2px;
`

export const HeaderLanguageSwitcher = styled(LanguageSwitcher)`
  margin-inline-start: 3rem;
`

export const HeaderNotificationsSwitch = styled(Switch)`
  margin-inline-start: 3rem;
`

export const HeaderUser = styled(UserProfile)`
  margin-inline-start: 4rem;

  @media (max-width: 1050px) {
    margin-inline-start: 0;
  }
`
