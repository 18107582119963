import { updateUser } from 'features/user/model'
import { A3codes, getCodeByCountry, getCountryByCode } from 'libs/A3codes'
import { onShowUserDataChangeModal } from 'pages/dashboard/profile/model'
import {
  editableStatusesFree,
  editableStatusesWithModal,
} from 'pages/dashboard/profile/utils/EditableStatuses'
import React from 'react'
import {
  ProfileRequestStatus,
  ProfileStatus,
} from 'services/profile/getProfile'
import { usePatchUserCountry } from 'services/user/mutations'
import { Icon2, Text } from 'ui/atoms'

import * as S from './styles'

interface Props {
  country: string
  status: ProfileStatus
  profileStatus?: ProfileRequestStatus
  updatingDocumentRequest?: boolean
}

export const CountrySelect: React.FC<Props> = ({
  country,
  status,
  profileStatus,
  updatingDocumentRequest,
}) => {
  const { mutateAsync } = usePatchUserCountry()

  const canChange =
    profileStatus !== 'IN_PROGRESS' &&
    !updatingDocumentRequest &&
    editableStatusesFree.includes(status)

  const onSelect = async (selectedCountry: string) => {
    try {
      const code = getCodeByCountry(selectedCountry)
      await mutateAsync({ country: code })
      updateUser({ country: code })
    } catch (error) {}
  }

  if (!canChange)
    return (
      <div className="flex cursor-pointer items-center">
        <Text>{getCountryByCode(country as string) || 'unknown'}</Text>&nbsp;
        {profileStatus !== 'IN_PROGRESS' &&
          !updatingDocumentRequest &&
          editableStatusesWithModal.includes(status) && (
            <Icon2
              size="base"
              name="chevronDown"
              className="text-sunray.500"
              onClick={() => onShowUserDataChangeModal()}
            />
          )}
      </div>
    )

  return (
    <>
      <S.CountrySearch
        trigger={
          <div className="flex cursor-pointer items-center">
            <Text>{getCountryByCode(country as string) || 'unknown'}</Text>
            &nbsp;
            <Icon2 size="base" name="chevronDown" className="text-sunray.500" />
          </div>
        }
        placeholder="Search"
        onSelect={onSelect}
        items={A3codes.map(({ country }) => country)}
      />
    </>
  )
}
