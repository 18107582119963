import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import {
  InternalTransferCalculateResponse,
  InternalTransferProps,
} from '../types'

export const CALCULATE_EXCHANGE_AMOUNT_KEY = ['calculate-exchange-amount']

const calculateExchangeAmount = async (params?: InternalTransferProps) => {
  const res = await axios.get<InternalTransferCalculateResponse>(
    '/internal-transfers/v1/internal-transfer/calculate',
    { params },
  )

  return res.data
}

export const useCalculateExchangeAmount = (params?: InternalTransferProps) => {
  return useQuery({
    queryKey: CALCULATE_EXCHANGE_AMOUNT_KEY,
    queryFn: () => calculateExchangeAmount(params),
    enabled: false
  })
}
