import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";

import { GiftHistoryItem } from 'services/giftshop/get/types';
import { THead, Td, Table as TableUi } from 'ui/molecules';
import { Text } from 'ui/atoms';

import CoinImage from '../images/color-coin.png';
import CoinImageWeb from '../images/color-coin.webp';

import * as S from './styled';
import { TableStatus } from './TableStatus';

type Props = {
  items: GiftHistoryItem[];
}

const tableHead = [
  {
    label: 'Date',
    name: 'date',
  },
  {
    label: 'Balance',
    name: 'crowns',
    renderTh: (val: string) => (
      <div className='flex items-center gap-[8px]'>
        <LazyLoadImage src={ CoinImage } srcSet={CoinImageWeb} alt="coin" className='w-[16px] h-[16px]' />
        <Text weight='medium' level={2}>{val}</Text>
      </div>
    )
  },
  {
    label: 'Gift',
    name: 'gift',
  },
  {
    label: 'Operation status',
    name: 'status',
    align: 'right',
  },
];

export const Table: React.FC<Props> = ({ items }) => {
  return (
    <TableUi heads={tableHead} >
      { items.map((item, index) => (
        <tr key={`${item.date}-${item.crowns}-${index}`} className="border-b-[0.75px] border-gray.300 border-solid">
          <Td>
            <Text level={2} withTranslate={ false }>{item.date}</Text>
          </Td>
          <Td>
            <Text level={2} withTranslate={ false }>{item.crowns}</Text>
          </Td>
          <Td>
            <Text level={2} withTranslate={ false }>{item.gift?.title}</Text>
          </Td>
          <Td align='right'>
            <TableStatus status={item.status} />
          </Td>
        </tr>
      )) }
    </TableUi>
  )
}
