import { useEffect } from 'react'
import ReactModal, { Props } from 'react-modal'
import { ButtonDesign } from 'ui/atoms'
import { useWindowSize } from 'usehooks-ts'

import * as Styled from './styled'

interface ModalDialogProps extends Props {
  title?: string
  text?: React.ReactNode
  okLabel?: string
  isButtonsVisible?: boolean
  cancelLabel?: string
  loading?: boolean
  disableCancelBtn?: boolean
  disableOkBtn?: boolean
  contentStyles?: any
  closeModalButton?: boolean
  titleClasses?: string
  cancelButtonType?: ButtonDesign
  onOk: () => void
  onCancel?: (e: any) => void
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
  loading,
  disableCancelBtn,
  disableOkBtn,
  isButtonsVisible = true,
  closeModalButton = false,
  cancelButtonType = 'tertiary',
  contentStyles = {},
  titleClasses = '',
  shouldCloseOnOverlayClick = true,
  ...props
}) => {
  const { width } = useWindowSize()
  const isMobileMode = width < 400

  useEffect(() => {
    document.body.style.overflow = props.isOpen ? 'hidden' : 'visible'
  }, [props.isOpen])

  return (
    <ReactModal
      {...props}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 3,
          display: 'grid',
          placeItems: 'center',
          padding: 8,
          backdropFilter: 'blur(1.5px)',
          backgroundColor: '#26211933',
          ...props.style?.overlay,
        },
        content: {
          overflow: 'visible',
          position: 'static',
          border: 'none',
          padding: '24px 20px 20px',
          width: isMobileMode ? 340 : 380,
          borderRadius: 8,
          boxShadow:
            '0px 24px 48px -12px rgba(38, 33, 25, 0.16), 0px 0px 1.5px 0px rgba(38, 33, 25, 0.09)',
          ...contentStyles,
        },
      }}
    >
      {props.title ? (
        <Styled.ModalHeader>
          <Styled.ModalTitle level={3} className={titleClasses}>
            {props.title}
          </Styled.ModalTitle>
          {closeModalButton && (
            // @ts-ignore
            <Styled.ModalCloseIcon name="xMark" onClick={props.onCancel} />
          )}
        </Styled.ModalHeader>
      ) : null}
      {props.text ? (
        <Styled.ModalText level={1}>{props.text}</Styled.ModalText>
      ) : null}

      {props.children ? (
        <div className="mt-[2rem]">{props.children}</div>
      ) : null}

      {isButtonsVisible && (
        <Styled.ButtonsWrapper
          className={props.cancelLabel ? 'justify-end' : 'justify-center'}
        >
          {props.cancelLabel && (
            <Styled.SimpleButton
              name="cancel"
              design={cancelButtonType}
              onClick={(e) => {
                e.stopPropagation()

                props.onCancel?.(e)
              }}
              size="medium"
              disabled={disableCancelBtn}
            >
              {props.cancelLabel}
            </Styled.SimpleButton>
          )}

          <Styled.SimpleButton
            name="ok"
            onClick={(e) => {
              // todo: починить модалку целиком 👀
              e.stopPropagation()

              props.onOk()
            }}
            size="medium"
            disabled={disableOkBtn}
            loading={loading}
          >
            {props.okLabel || 'Okay'}
          </Styled.SimpleButton>
        </Styled.ButtonsWrapper>
      )}
    </ReactModal>
  )
}
