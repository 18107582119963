import 'tippy.js/animations/shift-away.css'

import { MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react'

import { SignupTooltip } from '../signupTooltip'
import * as Styled from './styled'

export const FilterSelect = ({
  title,
  options,
  children,
  customDropdownContent,
  onChange,
  className,
  contentWidth,
  notFoundTitle,
  notFoundText,
  isContentVisible,
  onVisibilityChange,
  isDisabled,
  defaultValue
}: {
  title?: string | null
  options: { title: string; value: string | null }[]
  children?: ReactNode
  customDropdownContent?: ReactNode
  onChange: (value: string | null) => void
  className?: string
  contentWidth?: number
  notFoundTitle?: string | null
  notFoundText?: string | null
  isContentVisible?: boolean
  onVisibilityChange?: (isVisible: boolean) => void
  isDisabled?: boolean
  defaultValue?: string | null
}) => {
  const [isOpened, setOpened] = useState(
    typeof isContentVisible !== 'undefined' ? isContentVisible : false,
  )

  const [selectedValue, setSelectedValue] = useState<string | null>(null)

  const handleCrossClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    setSelectedValue(null)

    onChange(null)
  }

  const handleValueChange = (value: string | null) => {
    setOpened(false)

    setSelectedValue(value)

    onChange(value)
  }

  const tooltipContent = useMemo(() => {
    if (!!customDropdownContent) {
      return (
        <div style={{ width: contentWidth || 'auto' }}>
          {customDropdownContent}
        </div>
      )
    }

    if (options.length > 0) {
      return (
        <Styled.OptionsWrapper style={{ width: contentWidth || 'auto' }}>
          {options.map(({ title, value }) => (
            <Styled.Option
              key={value}
              isActive={value === selectedValue || value === defaultValue}
              onClick={() => handleValueChange(value)}
            >
              {title}
            </Styled.Option>
          ))}
        </Styled.OptionsWrapper>
      )
    }

    return (
      <Styled.NotFoundBlock style={{ width: contentWidth || 'auto' }}>
        {notFoundTitle && (
          <Styled.NotFoundTitle>{notFoundTitle}</Styled.NotFoundTitle>
        )}
        {notFoundText && (
          <Styled.NotFoundText>{notFoundText}</Styled.NotFoundText>
        )}
      </Styled.NotFoundBlock>
    )
  }, [options, customDropdownContent])

  const content = useMemo(() => {
    return children ? (
      <div onClick={() => setOpened(!isOpened)}>{children}</div>
    ) : (
      <Styled.Wrapper
        isDisabled={isDisabled}
        isActive={!!selectedValue}
        onClick={() => !isDisabled && setOpened(!isOpened)}
      >
        {options.find((option) => option.value === selectedValue)?.title ||
          title}
        {!selectedValue ? (
          <Styled.ChevronButton as="span" isOpened={isOpened} />
        ) : (
          <Styled.CrossButton onClick={handleCrossClick} />
        )}
      </Styled.Wrapper>
    )
  }, [isDisabled, children, isOpened, selectedValue, options])

  useEffect(() => {
    if (typeof isContentVisible !== 'undefined') {
      setOpened(isContentVisible)
    }
  }, [isContentVisible])

  useEffect(() => {
    onVisibilityChange?.(isOpened)
  }, [isOpened])

  return isDisabled ? (
    <SignupTooltip>{content}</SignupTooltip>
  ) : (
    <Styled.Tooltip
      animation="shift-away"
      duration={100}
      interactive
      visible={isOpened}
      onClickOutside={() => setOpened(false)}
      content={tooltipContent}
      arrow={false}
      className={className}
      placement="bottom-start"
    >
      {content}
    </Styled.Tooltip>
  )
}
