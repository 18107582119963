import 'tippy.js/animations/shift-away.css'

import { useEffect, useState } from 'react'
import { useElementSize } from 'usehooks-ts'

import * as Styled from './styled'
import type { SelectProps } from './types'

export const Select = (props: SelectProps) => {
  // todo: разобраться, почему useMeasure из @uidotdev/usehooks багует
  const [ref, { width }] = useElementSize()

  const [isOpened, setIsOpened] = useState(false)

  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    if (props.defaultValue) {
      setSelectedValue(props.defaultValue)
    }
  }, [props.defaultValue])

  const handleClickOption = (value: string) => {
    setSelectedValue(value)
    props.onChange(value)
    setIsOpened(false)
  }

  const currentLabel =
    props.options.find((option) => option.value === selectedValue)?.label || ''

  return (
    <Styled.Tippy
      animation="shift-away"
      duration={100}
      arrow={false}
      visible={isOpened}
      maxWidth={width}
      appendTo="parent"
      offset={[0, 8]}
      onClickOutside={() => setIsOpened(false)}
      content={
        <Styled.OptionsList>
          {props.options.map((option) => (
            <Styled.OptionWrapper
              key={option.value}
              isActive={option.value === selectedValue}
              onClick={() => handleClickOption(option.value)}
            >
              <span className="truncate">{option.label}</span>
            </Styled.OptionWrapper>
          ))}
        </Styled.OptionsList>
      }
      interactive
      placement="bottom-start"
    >
      <Styled.Container
        onClick={() => setIsOpened(!isOpened)}
        ref={ref}
        className={props.className}
      >
        {typeof currentLabel === 'string' ? (
          <Styled.ValueField readOnly value={currentLabel} />
        ) : (
          <Styled.ValueField as="div">{currentLabel}</Styled.ValueField>
        )}
        <Styled.Chevron isOpened={isOpened}>
          {/* todo: поменять на нормальный компонент Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
          >
            <path
              stroke="var(--color-gray-700)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M3.75 13.125 10 6.875l6.25 6.25"
            />
          </svg>
        </Styled.Chevron>
      </Styled.Container>
    </Styled.Tippy>
  )
}
