import * as React from 'react'
import styled, { css } from 'styled-components/macro'
import { Button, ButtonDesign } from 'ui/atoms'
import { useOnClickOutside } from 'usehooks-ts'

type Props = {
  name: string
  className?: string
  children: React.ReactNode
  content: React.ReactNode
  positionX?: 'left' | 'right'
  positionY?: 'top' | 'bottom'
  direction?: 'row' | 'column'
  isLoading?: boolean
  isDisabled?: boolean
  confirm: {
    onConfirm?: () => void
    text?: string
    design?: ButtonDesign
    visibility?: boolean
  }
  cancel: {
    onCancel?: () => void
    text?: string
    design?: ButtonDesign
    visibility?: boolean
  }
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export const Dialog = ({
  name,
  className,
  children,
  content,
  positionX = 'left',
  positionY = 'bottom',
  direction = 'row',
  isLoading,
  isDisabled,
  confirm,
  cancel,
  onClick,
}: Props) => {
  const [isVisible, setVisible] = React.useState(false)

  const ref = React.useRef(null)

  useOnClickOutside(ref, () => {
    if (isVisible) {
      cancel?.onCancel && cancel.onCancel()
    }
    setVisible(false)
  })

  return (
    <Container
      className={className}
      ref={ref}
      id={`${name}-dialog-window`}
      onClick={onClick}
    >
      <Block>
        <Trigger
          onClick={() => {
            setVisible(!isVisible)
          }}
        >
          {children}
        </Trigger>
        <Content
          data-visible={isVisible}
          data-position-x={positionX}
          data-position-y={positionY}
        >
          {content}
          <Footer data-direction={direction}>
            {cancel?.visibility && (
              <FooterButton
                name={`${name}-dialog-cancel`}
                design={cancel.design}
                onClick={() => {
                  cancel.onCancel && cancel.onCancel()
                  setVisible(false)
                }}
              >
                {cancel.text}
              </FooterButton>
            )}
            {confirm?.visibility && (
              <FooterButton
                name={`${name}-dialog-confirm`}
                design={confirm.design}
                onClick={() => {
                  confirm.onConfirm && confirm.onConfirm()
                  setVisible(false)
                }}
                loading={isLoading}
                disabled={isDisabled}
              >
                {confirm.text}
              </FooterButton>
            )}
          </Footer>
        </Content>
      </Block>
    </Container>
  )
}

const Content = styled.div`
  position: absolute;
  z-index: 1000;
  top: 28px;
  box-shadow: 0 0 1.5px rgba(38, 33, 25, 0.085),
    0 24px 48px -12px rgba(38, 33, 25, 0.16);
  border-radius: 8px;
  background-color: var(--color-gray-100);
  transition: var(--transition);
  width: 370px;

  padding: 20px 20px 20px 20px;

  @media (max-width: 470px) {
    width: 300px;
  }

  &[data-position-x='left'] {
    ${({ theme }) =>
      theme.dir === 'rtl'
        ? css`
            right: 0;
          `
        : css`
            left: 0;
          `}
  }

  &[data-position-x='right'] {
    ${({ theme }) =>
      theme.dir === 'rtl'
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}
  }

  &[data-position-y='top'] {
    top: 28px;
    transform: translateY(calc(-100% - 36px));
  }

  &[data-position-y='bottom'] {
    top: 28px;
  }

  &[data-visible='false'] {
    height: 0;
    overflow: hidden;
    opacity: 0;
    z-index: -1;
  }

  &[data-visible='true'] {
    opacity: 1;
    z-index: 100;
    height: auto;
  }
`

const Container = styled.div`
  position: relative;
`

const Trigger = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`

const Block = styled.div`
  position: relative;
`

const Footer = styled.div`
  display: flex;
  width: 100%;

  &[data-direction='row'] {
    flex-direction: row;
    gap: 18px;
  }

  &[data-direction='column'] {
    flex-direction: column-reverse;
    gap: 12px;
  }

  button {
    &[data-size='medium'] {
      width: 100%;
      height: 40px;
      min-width: 154px;
    }
  }
`

const FooterButton = styled(Button)`
  width: 100%;
`
