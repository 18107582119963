import { useMutation, useQueryClient } from '@tanstack/react-query';

import { retryFn } from 'services/helpers/retryFn';
import { axios } from 'services/axios';
import { ProfileContent } from 'services/profile/getProfile';
import { GET_PROFILE_KEY } from 'services/profile/getProfile/useGetProfile';

interface RequestData {
  country: string;
}

const patchChangeUserCountry = async (fields: RequestData) => {
  await axios.patch('/user/v1/country', fields);
}


export const usePatchUserCountry = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchChangeUserCountry,
    // если ответ с 401 ошибкой, то перезапрос не делаем
    // 2 попытки на перезапрос
    retry: retryFn,
    onSuccess(data, variables) {
      queryClient.setQueryData
      (
        GET_PROFILE_KEY, 
        (oldData: any) => ({ ...oldData as ProfileContent, country: variables.country }),
      );
    },
  })
}