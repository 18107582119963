export const isFacebookBrowser = () => {
  return (
    window.navigator.userAgent.match(/FBAN|FBAV/i) ||
    // @ts-expect-error
    typeof FB_IAB !== 'undefined' ||
    // @ts-expect-error
    typeof FBAV !== 'undefined' ||
    document.documentElement.classList.contains('in-app-browser')
  )
}

export const isWebView = () => {
  return /webview|wv|ip((?!.*Safari)|(?=.*like Safari))/i.test(
    window.navigator.userAgent,
  )
}
