import largeMedal_1 from './medal-01-large.png'
import medal_1 from './medal-01.png'
import largeMedal_2 from './medal-02-large.png'
import medal_2 from './medal-02.png'
import largeMedal_3 from './medal-03-large.png'
import medal_3 from './medal-03.png'
import largeMedal_4 from './medal-04-large.png'
import medal_4 from './medal-04.png'
import largeMedal_5 from './medal-05-large.png'
import medal_5 from './medal-05.png'
import largeMedal_6 from './medal-06-large.png'
import medal_6 from './medal-06.png'
import largeMedal_7 from './medal-07-large.png'
import medal_7 from './medal-07.png'
import largeMedal_8 from './medal-08-large.png'
import medal_8 from './medal-08.png'
import largeMedal_9 from './medal-09-large.png'
import medal_9 from './medal-09.png'
import largeMedal_10 from './medal-10-large.png'
import medal_10 from './medal-10.png'

export const icons: Record<string, string> = {
  medal_1,
  medal_2,
  medal_3,
  medal_4,
  medal_5,
  medal_6,
  medal_7,
  medal_8,
  medal_9,
  medal_10,
  largeMedal_1,
  largeMedal_2,
  largeMedal_3,
  largeMedal_4,
  largeMedal_5,
  largeMedal_6,
  largeMedal_7,
  largeMedal_8,
  largeMedal_9,
  largeMedal_10,
}
