export const formatNumber = (value: string | number) => {
  return new Intl.NumberFormat('en-US').format(+value)
}

export const getNumberColor = (value: string | number) => {
  return +value > 0
    ? 'var(--color-eucalyptus-500)'
    : +value < 0
    ? 'var(--color-opal-500)'
    : 'var(--color-neutral)'
}