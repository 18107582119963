import styled from 'styled-components'
import { Button as UiButton } from 'ui/atoms'

import close from '../../images/close-banner.svg'
import background from '../../images/deposit-banner.jpg'

export const Wrapper = styled.div`
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px;
  padding: 12px;
  max-width: 380px;
  min-height: 100px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px #2621190f;
  background-color: var(--color-sunray-100);
  background-image: url(${background});
  background-size: auto 100%;
  background-position: right center;

   @media (max-width: 599px) {
    margin-bottom: 24px;
   }
`

export const TextWrapper = styled.div`
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);

  @media (max-width: 599px) {
    font-size: var(--body-font-size-tiny);
    line-height: var(--body-line-height-tiny);
  }
`

export const Title = styled.div`
  margin-bottom: 8px;
  font-size: var(--body-font-size-regular);
  line-height: 20px;
  font-weight: 500;

  @media (max-width: 599px) {
    margin-bottom: 4px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const JoinButton = styled(UiButton)`
  margin-inline-start: 10px;
  min-width: 123px;

  @media (max-width: 599px) {
    margin-inline-start: 16px;
  }
`

export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  background-image: url(${close});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`