import styled from 'styled-components/macro'
import { Input as UiInput } from 'ui/atoms'
import { InputField, InputSuffix } from 'ui/atoms/input/styled'

import { StatisticsTable } from '../statisticsTable'

export const Container = styled.div`
  border: 1px solid var(--color-sunray-300);
  overflow: hidden;
  border-radius: 16px;
`

export const TopWrapper = styled.div`
  padding: 32px 24px;
  display: grid;
  justify-items: center;
  text-align: center;
  background-color: var(--color-sunray-050);

  @media (max-width: 599px) {
    padding: 24px;
  }
`

export const BottomWrapper = styled.div`
  border-top: 1px solid var(--color-sunray-300);
  padding: 32px 0;
  display: grid;
  grid-template-columns: 420px;
  justify-content: center;
  text-align: center;

  @media (max-width: 599px) {
    padding: 32px 24px;
    grid-template-columns: 1fr;
  }
`

export const Table = styled(StatisticsTable)`
  margin-bottom: 32px;

  @media (max-width: 599px) {
    margin-bottom: 24px;
  }
`

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: 0.5fr;
  justify-content: center;
  gap: 16px;
`

export const Input = styled(UiInput)`
  margin: 16px 0;

  ${InputField} {
    padding-inline-end: 49px;
  }

  ${InputSuffix} {
    pointer-events: initial;
    top: 1px;
    inset-inline-end: 13px;
    padding-inline-start: 8px;
    height: calc(100% - 2px);
    transition: background-color (--transition);
  }
`