import { createEvent, createStore } from 'effector';

const default_store = {
  page: 1,
  perPage: 10,
  strategyId: '',
}

type FinancialHistoryStore = {
  page: number;
  perPage: number;
  strategyId: string;
}

export const financialFilterReset = createEvent();
export const setStrategyFilterEvent = createEvent<string>();
export const setPageFinancialEvent = createEvent<number>();

export const $financialHistory = createStore<FinancialHistoryStore>(default_store);

// обновляем фильтр по стратегии
$financialHistory.on(setStrategyFilterEvent, (prevState, strategyId) => ({
  perPage: prevState.perPage,
  page: default_store.page,
  strategyId,
}));

// обновляем page
$financialHistory.on(setPageFinancialEvent, (prevState, page) => ({
  ...prevState,
  page,
}));

$financialHistory.reset(financialFilterReset);