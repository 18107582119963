import styled, { css } from 'styled-components/macro'

export const LandingWrapper = styled.div`
  border-bottom: 1px solid var(--color-gray-300);
  height: 82px;
  max-width: 765px;
  display: flex;
  align-items: center;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);

  @media (max-width: 850px) {
    max-width: auto;
    height: 100px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &:last-child {
    border-bottom: none;
  }
`

export const LandingName = styled.div`
  flex-shrink: 0;
  width: 150px;
  margin-inline-end: 12px;
  font-weight: 500;

  @media (max-width: 850px) {
    margin-inline-end: 0;
    margin-bottom: 8px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const LandingLink = styled.div`
  margin-inline-end: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 600px) {
    margin-inline-end: 8px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const LandingButtons = styled.div`
  display: grid;
  grid-template-columns: 36px 36px;
  gap: 4px;
`

export const SocialMediaGrid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;

  @media (max-width: 880px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 12px;
  }

  @media (max-width: 440px) {
    grid-template-columns: minmax(0, 1fr);
  }
`

export const WebsiteBannersGrid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;

  @media (max-width: 720px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 600px) {
    grid-template-columns: minmax(0, 1fr);
    gap: 12px;
  }
`

export const MediaWrapper = styled.div`
  border: 1px solid var(--color-gray-300);
  overflow: hidden;
  text-align: center;
  border-radius: 8px;
`

export const MediaActions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 11px;
  display: grid;
  grid-template-columns: 36px 36px;
  justify-content: end;
  gap: 12px;
  transition: opacity var(--transition);
  background-color: #2621194d;
  opacity: 0;

  @media (max-width: 440px) {
    padding: 15px;
  }
`

export const MediaFile = styled.div<{ isBanner?: boolean }>`
  position: relative;
  width: 100%;
  padding-bottom: ${({ isBanner }) => (isBanner ? '52%' : '100%')};
  border-radius: 6px;
  overflow: hidden;

  &:hover {
    ${MediaActions} {
      opacity: 1;
    }
  }

  img,
  video {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
`

export const FormatTag = styled.div`
  position: absolute;
  bottom: 11px;
  right: 11px;
  padding: 0 4px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  font-weight: 500;
  border-radius: 4px;
  background-color: var(--color-gray-400);

  @media (max-width: 440px) {
    bottom: 15px;
    right: 15px;
  }

  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      right: auto;
      left: 11px;

      @media (max-width: 440px) {
        right: auto;
        left: 15px;
      }
    `}
`
