import { Text } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'

export const LeavePageModal = ({
  isOpen,
  onOk,
  onCancel,
}: {
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  return (
    <ModalDialog
      isOpen={isOpen}
      cancelLabel="Cancel"
      onCancel={onCancel}
      okLabel="Leave"
      onOk={onOk}
      title="Leave page?"
    >
      <Text>Changes that you made may not be saved.</Text>
    </ModalDialog>
  )
}
