import { useQuery } from '@tanstack/react-query'
import { User } from 'features/user'
import { axios } from 'services/axios'

export const GET_USER_KEY = 'user'

interface Options {
  onSuccess?: (usr: User) => void
}

const getUser = async () => {
  const profileData = await axios.get<User>('/user/v1/user')

  return profileData.data
}

export const useGetUser = (options: Options = {}) => {
  return useQuery({
    queryKey: [GET_USER_KEY],
    queryFn: getUser,
    refetchOnWindowFocus: false,
    staleTime: 100000,
  })
}
