import * as Styled from './styled'

export const TraderPreview = ({
  imageUrl,
  achievementTitle,
  name,
  size = 'small',
  className,
}: {
  imageUrl: string
  name: string
  achievementTitle?: string
  size?: 'small' | 'medium' | 'xMedium' | 'large'
  className?: string
}) => {
  return (
    <Styled.OuterContainer className={className} size={size}>
      <Styled.ImageWrapper>
        <img src={imageUrl} alt={name} />
      </Styled.ImageWrapper>
      <Styled.TextWrapper>
        <Styled.Name>{name}</Styled.Name>
        {achievementTitle && (
          <div className="text-[10px] leading-[14px] font-medium text-gray.600">
            {achievementTitle}
          </div>
        )}
      </Styled.TextWrapper>
    </Styled.OuterContainer>
  )
}
