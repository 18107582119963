import axios, { AxiosError } from 'axios'
import { useStore } from 'effector-react'
import { useIsMobile } from 'libs/hooks/useIsMobile'
import { path } from 'libs/path'
import { ResponseError } from 'libs/request'
import { ChangeEvent, useState } from 'react'
import { Trans } from 'react-i18next'
import { useBlocker, useNavigate } from 'react-router-dom'
import { ZendeskAPI } from 'react-zendesk'
import {
  useCreateStrategy,
  useGetTraderCopyableAccounts,
  useUpdateStrategy,
} from 'services/copytrade'
import { TraderStrategy } from 'services/copytrade/types'
import { SourceItem } from 'services/payments/get/types'
import { Button, Input, Text, Title } from 'ui/atoms'

import {
  $publishedAccount,
  setStrategyCreationAlertVisible,
  setStrategyUpdateAlertVisible,
} from '../../model'
import { WarningText } from '../../startCopying/styled'
import { ImageUpload } from '../imageUpload'
import { LeavePageModal } from '../leavePageModal'
import { StrategyTextArea } from '../strategyTextArea'
import * as Styled from './styled'
import {
  DEFAULT_ACCOUNT_ID,
  FormMode,
  FormValues,
  formatValues,
  getSourceItem,
} from './utils'

export const StrategyForm = ({
  mode = 'create',
  initialValues,
}: {
  mode?: FormMode
  initialValues?: TraderStrategy
}) => {
  const navigate = useNavigate()

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      [
        path.copytrade.editStrategy(initialValues?.strategyID || ''),
        path.copytrade.createStrategy(),
      ].some((path) => path === currentLocation.pathname) &&
      nextLocation.pathname !== `${path.copytrade.traderEntry()}/`
    )
  })

  const publishedAccount = useStore($publishedAccount)

  const { data: accounts, isLoading: isAccountsLoading } =
    useGetTraderCopyableAccounts({
      enabled: mode === 'create',
    })
  const { mutateAsync: createStrategy, isLoading: createStrategyLoading } =
    useCreateStrategy()
  const { mutateAsync: updateStrategy, isLoading: updateStrategyLoading } =
    useUpdateStrategy()

  const isMobileMode = useIsMobile()

  const [checked, setChecked] = useState(false)
  const [values, setValues] = useState<FormValues>({
    copyableAccountID: '',
    commission: initialValues?.commission || '',
    title: initialValues?.title || '',
    description: initialValues?.description || '',
    imageName: '',
    imageUrl: initialValues?.imageLink || '',
  })
  const [errors, setErrors] = useState<Record<string, string>>({})

  const handleComissionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const output = event.target.value.replace(/[^.\d]/g, '').split('.')

    setValues({
      ...values,
      commission:
        output.length > 1 ? output.shift() + '.' + output.join('') : output[0],
    })
  }

  const handleSubmit = async () => {
    try {
      if (mode === 'create') {
        await createStrategy(formatValues(values, mode))

        setStrategyCreationAlertVisible(true)

        setTimeout(() => {
          setStrategyCreationAlertVisible(false)
        }, 10000)
      } else if (initialValues) {
        await updateStrategy({
          ...formatValues(values, mode),
          strategyID: initialValues.strategyID,
        })

        setStrategyUpdateAlertVisible(true)

        setTimeout(() => {
          setStrategyUpdateAlertVisible(false)
        }, 10000)
      }

      navigate(`${path.copytrade.traderEntry()}/`)
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const knownError = error as AxiosError<ResponseError>

        setErrors(
          knownError.response?.data?.details?.validationErrors.items.reduce(
            (acc, error) => ({ ...acc, [error.field]: error.msg }),
            {},
          ) || {},
        )
      }
    }
  }

  const sourcesList: SourceItem[] =
    mode === 'edit'
      ? initialValues
        ? [getSourceItem(initialValues.copyableAccount)]
        : []
      : accounts?.map((account) => getSourceItem(account)) || []

  const isSubmitEnabled =
    ((mode === 'create' && checked && values.copyableAccountID) ||
      mode === 'edit') &&
    +values.commission >= 1 &&
    values.title

  const resetValues = () => {
    if (!initialValues) {
      return
    }

    setValues({
      ...values,
      commission: initialValues.commission || '',
      title: initialValues.title || '',
      description: initialValues.description || '',
      imageUrl: initialValues.imageLink || '',
    })

    setErrors({})
  }

  const handleLeave = () => {
    blocker.proceed?.()

    document.body.style.overflow = 'visible'
  }

  return (
    <Styled.ContentContainer>
      <Styled.TextWrapper>
        <Title level={isMobileMode ? 3 : 2}>
          {mode === 'create' ? 'Create strategy' : 'Edit strategy'}
        </Title>
        <Text level={isMobileMode ? 2 : 1}>
          Use only accounts that don’t copy other strategies and have a balance
          of $100 or more.
        </Text>
      </Styled.TextWrapper>
      <Styled.FieldsWrapper>
        <div className="mb-[16px]">
          <Styled.Select
            onSelect={(value: SourceItem) =>
              setValues({ ...values, copyableAccountID: value.id })
            }
            label="Account"
            placeholder={
              sourcesList.length === 0 && !isAccountsLoading
                ? 'No matching accounts'
                : 'Select an account'
            }
            data={sourcesList}
            defaultValue={
              mode === 'edit'
                ? DEFAULT_ACCOUNT_ID
                : publishedAccount || undefined
            }
            isDisabled={
              mode === 'edit' ||
              (sourcesList.length === 0 && !isAccountsLoading)
            }
          />
          {sourcesList.length === 0 && !isAccountsLoading && (
            <WarningText>
              Deposit $100 to a real account to create a strategy
            </WarningText>
          )}
          {sourcesList.length > 0 && (
            <div className="mt-[10px] text-body.medium leading-body.medium">
              <Trans i18nKey={'Can’t find account? Contact our Customer Care'}>
                Can’t find account? Contact our{' '}
                <span
                  onClick={() => ZendeskAPI('messenger', 'open')}
                  className="!cursor-pointer !text-bronze.500"
                >
                  Customer Care
                </span>
              </Trans>
            </div>
          )}
        </div>
        <Input
          label="Investor commission"
          placeholder="Set a commission from $1"
          value={values.commission ? `$${values.commission}` : ''}
          onChange={handleComissionChange}
          errorMessage={errors.commission}
        />
        {mode === 'edit' && (
          <div className="mt-[6px] text-gray.600 text-body.medium leading-body.medium">
            If you update the commission value, it will apply only to new
            investors.
          </div>
        )}
        <Styled.BottomWrapper>
          <ImageUpload
            imageUrl={values.imageUrl || initialValues?.imageLink}
            onChange={({ imageUrl, fileName }) =>
              setValues({
                ...values,
                imageUrl,
                imageName: fileName,
              })
            }
          />
          <div className="grid gap-[16px] w-full">
            <Input
              label="Strategy name"
              placeholder="Enter a unique name"
              value={values.title}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setValues({ ...values, title: event.target.value })
              }
              errorMessage={errors.title}
              maxLength={20}
            />
            <StrategyTextArea
              onChange={(value) => setValues({ ...values, description: value })}
              value={values.description}
              error={errors.description}
            />
          </div>
        </Styled.BottomWrapper>
      </Styled.FieldsWrapper>
      {mode === 'create' && (
        <Styled.CheckboxWrapper checked={checked} onChange={setChecked} />
      )}
      <Styled.ButtonsWrapper>
        {mode === 'create' ? (
          <Button
            type="submit"
            name="create-strategy"
            className="min-w-[216px]"
            disabled={!isSubmitEnabled || createStrategyLoading}
            loading={createStrategyLoading}
            onClick={handleSubmit}
          >
            Create strategy
          </Button>
        ) : (
          <>
            <Button
              type="reset"
              name="discard-changes"
              className="min-w-[216px]"
              design="secondary"
              onClick={resetValues}
            >
              Discard changes
            </Button>
            <Button
              type="submit"
              name="edit-strategy"
              className="min-w-[170px]"
              disabled={!isSubmitEnabled || updateStrategyLoading}
              loading={updateStrategyLoading}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </>
        )}
      </Styled.ButtonsWrapper>

      <LeavePageModal
        isOpen={blocker.state === 'blocked'}
        onOk={handleLeave}
        onCancel={() => blocker.reset?.()}
      />
    </Styled.ContentContainer>
  )
}
