import { createEvent, restore } from 'effector';
import { SourceItem } from 'services/payments/get/types';

export const accountChoosed = createEvent<string>();
export const accountChooseReset = createEvent();
export const $accountDeposit = restore<string>(accountChoosed, null).reset(accountChooseReset);


export const setCustomGatewayIdEvent = createEvent<string>();
export const resetCustomGatewayIdEvent = createEvent();
// 2791
export const $customGatewayId = restore<string>(setCustomGatewayIdEvent, '').reset(resetCustomGatewayIdEvent);
