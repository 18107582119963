import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  ChartData,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
);

type Props = {
  lines: { x: number, y: number }[];
}

export const GraphV2: React.FC<Props> = ({ lines }) => {
  const data = useMemo(() => {
    const obj: ChartData<'line', any, string> = {
      datasets: [{
        fill: false,
        borderColor: '#DBB454',
        pointBackgroundColor: '#DBB454',
        pointBorderColor: '#DBB454',
        pointRadius: lines?.length > 1 ? 0 : 1,
        borderWidth: 1,
        data: [],
      }],
    }

    obj.datasets[0].data = lines.map(item => ({
      x: item.x,
      y: item.y,
    }))
    return obj;
  }, [lines]);


  const options = useMemo(() => {
    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          position: 'center',
          offset: true,
          type: 'linear',
          grid: {
            display: false,
          },
          ticks: {
            display: false,
            BeginAtZero: true,
          },
        },
        y: {
          border: {
            display: false
          },
          offset: true,
          ticks: {
            BeginAtZero: true,
            display: true,
          },
          grid: {
            display: false,
          }
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        }
      }

    }
  }, []);

  if (!data) return;
  
  return (
    <div className='!overflow-hidden w-full mt-[1.8rem]'>
      <Line
        // @ts-ignore
        options={options}
        data={ data }
        className='lg:!h-[94px] !w-full !h-[94px] !overflow-hidden' 
      />
    </div>
  );
};
