import * as React from 'react'
import styled from 'styled-components/macro'

type ListProps = {
  name: string
  className?: string
  items: SingleProps[]
  initial?: string
  direction?: 'row' | 'column'
}

export const RadioList = ({
  className,
  items,
  name,
  direction = 'row',
}: ListProps) => {
  return (
    <RadioListContainer className={className} data-direction={direction}>
      {items.map(({ value, label, tip, disabled, onSelect, checked }) => {
        return (
          <RadioListItem key={value}>
            <RadioSingle
              value={value}
              label={label}
              name={name}
              tip={tip}
              disabled={disabled}
              onSelect={onSelect}
              checked={checked}
              // defaultChecked={value === initial}
            />
          </RadioListItem>
        )
      })}
    </RadioListContainer>
  )
}

const RadioListContainer = styled.form`
  display: flex;

  &[data-direction='row'] {
    flex-direction: row;
  }

  &[data-direction='column'] {
    flex-direction: column;
  }
`

const RadioListItem = styled.div``

type SingleProps = {
  className?: string
  checked?: boolean
  defaultValue?: string
  size?: 'default' | 'large'
  value: string
  label?: string
  name?: string
  tip?: string
  disabled?: boolean
  onSelect?: (value: string) => void
}

export const RadioSingle = ({
  className,
  checked,
  name,
  value,
  label,
  tip,
  size = 'default',
  disabled,
  onSelect,
}: SingleProps) => {
  const id = `${name}-${value}`

  return (
    <RadioSingleContainer data-disabled={disabled} data-size={size}>
      <RadioSinglInput
        type="radio"
        id={id}
        name={name}
        className={className}
        checked={checked}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          if (onSelect) {
            onSelect(e.target.value)
          }
        }}
      />
      {label && (
        <RadioSingleLabel data-disabled={disabled}>
          {label}
          {tip && <RadioSingleTip>{tip}</RadioSingleTip>}
        </RadioSingleLabel>
      )}
    </RadioSingleContainer>
  )
}

const RadioSinglInput = styled.input`
  margin-top: 6px;
`

const RadioSingleLabel = styled.span`
  font-weight: 400;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  transition: var(--transition);
  width: 100%;
  color: var(--color-gray-700);
  text-align: start;

  &[data-disabled='true'] {
    pointer-events: none;
    color: var(--color-gray-600);
  }
`

const RadioSingleTip = styled.div`
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);
`

const RadioSingleContainer = styled.label`
  padding: 6px 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  &[data-size='large'] input[type='radio'] {
    &::before {
      width: 20px;
      height: 20px;
    }

    &:checked::before {
      border: 7px solid var(--color-sunray-500);
    }
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    position: relative;

    &::before {
      content: '';
      margin-top: 5px;
      margin-inline-end: 10px;
      border: 2px solid var(--color-gray-400);
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      cursor: pointer;
    }

    &:checked {
      &::before {
        border: 5px solid var(--color-sunray-500);
      }
    }
  }

  &:hover {
    ${RadioSingleLabel} {
      color: var(--color-sunray-500);
    }
  }

  &[data-disabled='true'] {
    pointer-events: none;
  }
`
