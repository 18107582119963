import { useIsMobile } from 'libs/hooks/useIsMobile';
import React, { useMemo } from 'react';
import { useFieldArray, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Text, Title } from 'ui/atoms';
import { ServerFile, UploadFiles } from 'ui/molecules';

interface Props {
  control: Control<any>
}

export const UploadDocs: React.FC<Props> = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    name: 'fileNames',
    control: control,
  });

  const isMobile = useIsMobile();

  const [t] = useTranslation();

  const onChangeFiles = (files: ServerFile) => {
    append(files);
  }

  const onDeleteFile = (index: number) => {
    remove(index)
  }

  const defaultUploadPlace = useMemo(() => ([
    { title: t('Upload a document'), icon: 'cloudArrowUp' as const, id: '1' }, 
    { title: t('Upload a document'), icon: 'cloudArrowUp' as const, id: '2' }
  ]), [t]);
    
  return (
    <>
      <UploadFiles
        uploadUrl='/user-files/v1/verification'
        title={
          <Title level={isMobile ? 4 : 3}>
            Upload a document
          </Title>
        }
        onChangeFiles={onChangeFiles}
        onDeleteFile={onDeleteFile}
        maxSize='10'
        defaultUploadPlace={defaultUploadPlace}
        limit={5}
        files={fields as any}
        subTitle={
          <Text level={isMobile ? 2 : 1}>
            National ID (front and back side) or Local Passport (two-page opening with a photo)
          </Text>
        }
      />
    </>
  )
}
