import styled from "styled-components/macro";
import { Icon } from "ui/atoms";
import { DropdownSearch } from "ui/molecules";

export const InformationBlock = styled.div`
  padding: 1.2rem;
  margin-top: 1.2rem;
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const InformationBlockItem = styled.div`
  width: 100%;
  height: 4.8rem;
  padding: 1.2rem 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`

export const CountrySearch = styled(DropdownSearch)`
  position: absolute;
  top: auto;
  inset-inline-end: 34px;
  width: 100%;
  max-width: 240px;
`
export const Spinner = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-inline-start: 1rem;
  animation: spin 0.5s linear infinite;
  animation-delay: 0.1s;
  transition: var(--transition);
  opacity: 1;
`