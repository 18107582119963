import { path } from 'libs/path'
import { useNavigate } from 'react-router-dom'
import { useGetTraderStrategies } from 'services/copytrade'
import { Button, Icon, Text, Title } from 'ui/atoms'
import { useWindowSize } from 'usehooks-ts'

import { setPublishedAccount } from '../model'
import { StrategyRow } from '../molecules/strategyRow'
import * as Styled from './styled'

export const StrategiesList = () => {
  const navigate = useNavigate()

  const { width } = useWindowSize()
  const isMobileMode = width < 600

  const { data, isLoading } = useGetTraderStrategies()

  const isEmpty = !data || data.length === 0

  const handleCreateStrategy = () => {
    setPublishedAccount('')

    navigate(path.copytrade.createStrategy())
  }

  return (
    <Styled.Wrapper>
      <div className="flex items-center justify-between">
        <Title level={isMobileMode ? 3 : 2}>My strategies</Title>
        {!isEmpty && (
          <Button
            name="add-strategy"
            size={isMobileMode ? 'small' : 'medium'}
            design="secondary"
            prefix={<Icon name="plus" />}
            onClick={handleCreateStrategy}
          >
            Add strategy
          </Button>
        )}
      </div>
      {isLoading ? (
        'loading...'
      ) : isEmpty ? (
        <Styled.EmptyBlock>
          <Title level={isMobileMode ? 3 : 2} className="mb-[8px]">
            No strategies yet
          </Title>
          <Text
            className="!text-gray.600 mb-[32px]"
            level={isMobileMode ? 2 : 1}
          >
            Create your first strategy: Add your account with a balance over
            $100 to start.
          </Text>
          <Styled.CreateButton
            name="create-first-strategy"
            prefix={<Icon name="plus" />}
            onClick={handleCreateStrategy}
          >
            Create strategy
          </Styled.CreateButton>
        </Styled.EmptyBlock>
      ) : (
        <Styled.List>
          {data.map((item) => (
            <StrategyRow
              key={item.strategyID}
              {...item}
              isMobile={isMobileMode}
            />
          ))}
        </Styled.List>
      )}
    </Styled.Wrapper>
  )
}
