import { useDebounce, useWindowSize } from '@uidotdev/usehooks'
import axios, { AxiosError } from 'axios'
import { useStore } from 'effector-react'
import { path } from 'libs/path'
import { ResponseError } from 'libs/request'
import { AccountTariffName } from 'pages/accounts/create/types'
import { Account } from 'pages/accounts/types'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useHref } from 'react-router-dom'
import { useCalculateExchangeAmount } from 'services/internal-transfers/get/useCalculateExchangeAmount'
import { usePostInternalTransfer } from 'services/internal-transfers/mutations/usePostInternalTransfer'
import { SourceItem } from 'services/payments/get/types'
import { Button, Icon, Icon2, Spinner2, Title } from 'ui/atoms'
import { getHolderType } from 'pages/dashboard/widgets/transfer/model'

import { $internalTransferSources } from '../../model'
import * as Styled from './styled'

export const DepositAccount = ({
  currentAccount,
}: {
  currentAccount?: Account
}) => {
  const depositHref = useHref(path.deposit.entry())

  const { width } = useWindowSize()
  const isMobileMode = width && width < 600

  const internalTransferSources = useStore($internalTransferSources)

  const [isInternalTransferActive, setIsInternalTransferActive] =
    useState(false)
  const [selectedSource, setSelectedSource] = useState<SourceItem>()
  const [amount, setAmount] = useState('')
  const debouncedAmount = useDebounce(amount, 300)

  const {
    data: exchangeAmount,
    refetch,
    error: calculateError,
    isFetching: isCalculating,
  } = useCalculateExchangeAmount({
    srcID: selectedSource?.id || '',
    srcIDType: getHolderType(selectedSource?.type!),
    dstID: currentAccount?.accountID || '',
    dstIDType: 'PUBLIC_API_INTERNAL_TRANSFER_ACCOUNT_ID_TYPE_ACCOUNT',
    amount,
  })
  const { mutateAsync: postInternalTransfer, isLoading } =
    usePostInternalTransfer()

  const sources = useMemo(() => {
    const res: SourceItem[] = []

    internalTransferSources?.accounts
      .filter((account) => account.accountID !== currentAccount?.accountID)
      .forEach((filteredAccount) =>
        res.push({
          currency: filteredAccount.currency,
          balance: `${filteredAccount.balance}`,
          name: filteredAccount.accountName as string,
          accountPlatform: filteredAccount.platform,
          accountType: filteredAccount.tariffName as AccountTariffName,
          id: filteredAccount.accountID,
          type: 'OT_ACCOUNT',
        }),
      )

    internalTransferSources?.wallets.forEach((wallet) => {
      res.push({
        currency: wallet.currency,
        balance: `${wallet.balance}`,
        id: wallet.walletID,
        type: 'OT_WALLET',
      })
    })

    return res
  }, [internalTransferSources, currentAccount])

  const handleChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
    const output = event.target.value.replace(/[^.\d]/g, '').split('.')

    setAmount(
      output.length > 1 ? output.shift() + '.' + output.join('') : output[0],
    )
  }

  const handleDeposit = () => {
    window.open(depositHref, '_blank', 'noreferrer')
  }

  const handleSubmit = async () => {
    if (!selectedSource || !currentAccount || !exchangeAmount) {
      return
    }

    try {
      await postInternalTransfer({
        srcID: selectedSource.id,
        srcIDType: getHolderType(selectedSource.type),
        dstID: currentAccount.accountID,
        dstIDType: 'PUBLIC_API_INTERNAL_TRANSFER_ACCOUNT_ID_TYPE_ACCOUNT',
        amount,
      })

      setAmount('')
      setIsInternalTransferActive(false)

      window.scrollTo({ top: 0, behavior: 'instant' })
    } catch {}
  }

  useEffect(() => {
    if (debouncedAmount && selectedSource) {
      refetch()
    }
  }, [debouncedAmount, selectedSource])

  useEffect(() => {
    console.log('calculateError=', calculateError)
  }, [calculateError])

  return (
    <div>
      <Styled.ButtonsWrapper>
        <Button
          name="deposit"
          size={isMobileMode ? 'small' : 'medium'}
          prefix={
            <Icon
              name="arrowDownOnSquare"
              size={isMobileMode ? 'small' : 'medium'}
            />
          }
          onClick={handleDeposit}
        >
          Deposit
        </Button>
        or
        <Button
          name="internal-transfer"
          size={isMobileMode ? 'small' : 'medium'}
          design="secondary"
          prefix={
            <Icon2
              name="arrowsRightLeft"
              size={isMobileMode ? 'small' : 'medium'}
            />
          }
          suffix={
            <Icon2
              name={isInternalTransferActive ? 'chevronUp' : 'chevronDown'}
              size={isMobileMode ? 'small' : 'medium'}
            />
          }
          onClick={() => setIsInternalTransferActive(!isInternalTransferActive)}
        >
          Internal transfer
        </Button>
      </Styled.ButtonsWrapper>
      {isInternalTransferActive && currentAccount && (
        <Styled.InternalTransferWrapper>
          <Title level={isMobileMode ? 4 : 3}>Make internal transfer</Title>
          <Styled.SourceSelect data={sources} onSelect={setSelectedSource} />
          <Styled.AmountInput
            placeholder="Amount"
            prefix={selectedSource?.currency}
            onChange={handleChangeAmount}
            value={amount}
            suffix={isCalculating ? <Spinner2 size="small" /> : null}
            errorMessage={
              axios.isAxiosError(calculateError)
                ? (
                    calculateError as AxiosError<ResponseError>
                  ).response?.data.details?.validationErrors?.items.find(
                    (item) => item.field === 'amount',
                  )?.msg
                : ''
            }
          />
          {amount && exchangeAmount && !calculateError && selectedSource && (
            <Styled.CalculatedAmount>
              Exchange amount: {exchangeAmount.calculatedAmount}{' '}
              {currentAccount.currency}
            </Styled.CalculatedAmount>
          )}
          <Styled.InternalTransferButton
            name="transfer"
            prefix={
              <Icon2
                name="arrowsRightLeft"
                size={isMobileMode ? 'small' : 'medium'}
              />
            }
            size={isMobileMode ? 'small' : 'medium'}
            disabled={!exchangeAmount || !!calculateError || isLoading}
            loading={isLoading}
            onClick={handleSubmit}
          >
            Transfer
          </Styled.InternalTransferButton>
        </Styled.InternalTransferWrapper>
      )}
    </div>
  )
}
