import React, { useMemo } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { DepositFormListItem } from 'services/payments/get/DepositFormTypes';

import { FormInput } from 'ui/molecules';

interface Props {
  item: DepositFormListItem;
}

export const TextField: React.FC<Props> = ({ item }) => {
  const { register, formState: { errors } } = useFormContext();

  const registerFields = useMemo(() => {
    const register: RegisterOptions = {};
      
    // TODO на беке и на фронте будут доработки по валидации
    item.validators.some(validItem => {
      if (validItem.type === 'VALIDATOR_TYPE_JS_REGEXP') {
        register.pattern = { 
          value: new RegExp(validItem.jsRegexpValidatorParams?.pattern)!,
          message: validItem.validationErrorMessage || '',
        }
        return true;
      }

      if (validItem.type === 'VALIDATOR_TYPE_REQUIRED') {
        register.required = { 
          value: true,
          message: validItem.validationErrorMessage || '',
        }
      }
    });

    return register;
  }, [item]);

  return (
    <FormInput
        {...register(item.name, registerFields)}
        key={item.name}
        defaultValue={''}
        disabled={false}
        className='lg:!w-[440px] w-full'
        errorMessage={ errors?.[item.name]?.message as string }
        placeholder={item.title || ''}
        maxLength={Number(item.params.FIELD_TYPE_TEXT?.maxLength) || undefined}
      />
  )
}
