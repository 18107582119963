import 'tippy.js/dist/tippy.css';

import './styles/fonts.css';
import './styles/normalize.css';
import './styles/default.css';
import './styles/animations.css';
import './styles/typography.css';
import './libs/i18n';

import * as React from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './App'

const root = createRoot(document.getElementById('root')!)

root.render(<App />)
