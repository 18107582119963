import { createEvent, createStore, restore, sample } from 'effector'

import { toPath } from '../../../../../features/router'
import { path } from '../../../../../libs'
import { ClientError, mapResponseError } from '../../../../../libs'
import { generatePass } from '../../../../../libs/generatePass'
import { ChangePasswordProps, service } from '../../../../../services'

// set passwords

export const $temporaryPassword = createStore<string>('')
export const $oldPassword = createStore<string>('')

sample({
  clock: service.auth.getCheckResetPasswordCode.doneData,
  fn: (doneData) => doneData.body.identificationResult.generatedPassword,
  target: [$oldPassword],
})

sample({
  clock: service.auth.getCheckResetPasswordCode.doneData,
  fn: (doneData) =>
    doneData.body.identificationResult.generatedPassword || generatePass(),
  target: [$temporaryPassword],
})

export const submitForm = createEvent<{
  newPassword: string
  newPasswordRepeat: string
}>()

export const setCreateMode = createEvent<boolean>()

export const $isCreateMode = restore<boolean>(setCreateMode, false)

// regenerate password

export const setGeneratePassword = createEvent()

sample({
  clock: setGeneratePassword,
  fn: () => generatePass(),
  target: [$temporaryPassword],
})

// if password wasn't change

sample({
  source: [$temporaryPassword, $oldPassword],
  clock: submitForm,
  filter: $isCreateMode.map((is) => !is),
  target: toPath.prepend(path.register.password.recovery.success),
})

// if password was genereted on client

sample({
  source: [$temporaryPassword, $oldPassword],
  clock: submitForm,
  filter: ([temporaryPassword, oldPassword], { newPassword }) => {
    return oldPassword !== temporaryPassword && !newPassword
  },
  fn: ([temporaryPassword]) => ({
    body: {
      action: 'RESET' as const,
      newPassword: temporaryPassword,
    },
  }),
  target: service.auth.postChangePassword,
})

// if password changed by user

sample({
  source: $oldPassword,
  clock: submitForm,
  filter: (_, { newPassword, newPasswordRepeat }) => {
    return Boolean(newPassword) && newPassword === newPasswordRepeat
  },
  fn: (_, { newPassword }) => ({
    body: {
      action: 'RESET' as const,
      newPassword: newPassword,
    },
  }),
  target: service.auth.postChangePassword,
})

sample({
  clock: service.auth.postChangePassword,
  filter: ({ body }) => body?.action === 'RESET',
  target: toPath.prepend(path.register.password.recovery.success),
})

// handle errors

export const $error = createStore<ClientError<ChangePasswordProps> | null>(null)

$error
  .on(service.auth.postChangePassword.failData, (_, failData) =>
    mapResponseError<ChangePasswordProps>(failData.body),
  )
  .reset([setCreateMode, submitForm])
