import React, { useState } from 'react'

import { usePutChangeUserAddress } from 'services/user/mutations/usePutAddress';
import { Icon2, Input, Text } from 'ui/atoms';

import * as S from './styles';

interface Props {
  currentAddress?: string;
}

export const AddressChangeForm: React.FC<Props> = ({ currentAddress }) => {
    const { mutateAsync, isLoading } = usePutChangeUserAddress();

    const [isChangeMod, setIsChangeMod] = useState<boolean>(false);
  
    const [newAddress, setNewAddress] = useState(currentAddress);
  
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewAddress(e.target.value)
    }
    
    const onChangePhone = async() => {
      try {
        await mutateAsync(newAddress || '');
        setIsChangeMod(false);
      } catch (error) {
        console.log(error);
      }
    }
  
    const onDropChangeMod = () => {
      setNewAddress(currentAddress);
      setIsChangeMod(false);
    }
  
    return(
      <>
        { isChangeMod ?
          <div className='flex items-center'>
            <Input
              value={ newAddress }
              onChange={ onChange }
              replacePattern={ /[<>@]/gm }
              type='text'
              maxLength={100}
              variant='outline'
              disabled={ isLoading }
            />
            { isLoading 
              ? <S.Spinner
                name="spinner"
                data-visible={isLoading}
              /> 
              : (
                <>
                  <Icon2
                    size='base'
                    name='check'
                    onClick={isLoading ? undefined : onChangePhone}
                    className='text-sunray.500 ms-[1rem] cursor-pointer'
                  />
                  <Icon2
                    size='base'
                    name='xMarkIcon'
                    onClick={ onDropChangeMod }
                    className='text-gray.500 ms-[0.8rem] cursor-pointer'
                  />
                </>
              )  
          }
          </div>
          : (
            <>
              {currentAddress &&
                <div className='flex'>
                  <Text className='truncate max-w-[300px]'>{currentAddress}</Text>
                  
                  <Icon2
                    size='base'
                    name='pencilSolid'
                    onClick={() => setIsChangeMod(true)}
                    className='text-gray.500 ms-[0.8rem] cursor-pointer'
                  />
                </div>
              }
              {!currentAddress &&
                <div onClick={() => setIsChangeMod(true)}>
                  <Text className='cursor-pointer !text-sunray.500'>
                    Add address
                  </Text>
                </div>
              }
            </>
          )
        }
      </>
    );
}
