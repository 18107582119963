import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import { axios } from 'services/axios'

import { GET_STRATEGY_FOR_INVESTOR } from '../get/useGetStrategyForInvestor'
import type { Strategy } from '../types'
import { GET_STRATEGY_HISTORY } from '../get/useGetStrategyHistory'

const cancelCopying = async (params: {
  subscriptionID: string
  isCloseOpenedPos: boolean
}) => {
  await axios.delete('/copytrade/v1/strategy/subscription', { params })
}

export const useCancelCopying = () => {
  return useMutation({
    mutationFn: cancelCopying,
    onSuccess(_, { subscriptionID }) {
      // т.к. нам нужно корректно закрыть модальное окно, а только потом удалить стратегию из списка,
      // при помощи setTimeout "сдвигаем" это удаление в конец
      setTimeout(() => {
        queryClient.setQueryData<Strategy>(
          GET_STRATEGY_FOR_INVESTOR,
          (oldData) =>
            oldData && {
              ...oldData,
              isAvailableListsOperations: false,
              subscriptions: oldData.subscriptions?.filter(
                (subscription) =>
                  subscription.subscriptionID !== subscriptionID,
              ),
            },
        )
      }, 20)
    },
  })
}
