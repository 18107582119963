import { useStore } from 'effector-react'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { $account } from '../model'
import { mounted } from './model'

export const useAccountDetails = () => {
  const { accountID = '' } = useParams()

  const account = useStore($account)

  React.useEffect(() => {
    mounted(accountID)
  }, [])

  return {
    accountID,
    account: account || {
      accountID: '',
      name: '',
      login: '',
      currency: 'USD',
      balance: 0,
      password: '',
      leverage: '1',
      isSwapFree: false,
      isFixRate: false,
      tariffName: '',
      type: 'PAAT_INVALID',
      platform: 'PAAP_INVALID',
      status: 'PAAS_INVALID',
      mtServerInfo: {
        name: '',
        address: '',
      },
      availableActions: []
    },
  }
}
