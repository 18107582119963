import { useStore } from 'effector-react'
import { path } from 'libs/path'
import { Trans, useTranslation } from 'react-i18next'
import { Alerts as UiAlert } from 'ui/molecules'

import {
  $isAccountCreationAlertVisible,
  $isStartCopyingAlertVisible,
  $isStopCopyingAlertVisible,
  $isStrategyCreationAlertVisible,
  $isStrategyUpdateAlertVisible,
  $paymentAvailabilityParams,
  $removedStrategy,
  updateStartCopyingAccounts,
} from '../../model'
import * as Styled from './styled'

export const Alerts = ({ currentPath }: { currentPath: string }) => {
  const [t] = useTranslation()

  const removedStrategy = useStore($removedStrategy)
  const isStrategyUpdateAlertVisible = useStore($isStrategyUpdateAlertVisible)
  const isStrategyCreationAlertVisible = useStore(
    $isStrategyCreationAlertVisible,
  )
  const isStopCopyingAlertVisible = useStore($isStopCopyingAlertVisible)
  const isAccountCreationAlertVisible = useStore($isAccountCreationAlertVisible)
  const isStartCopyingAlertVisible = useStore($isStartCopyingAlertVisible)
  const paymentAvailabilityParams = useStore($paymentAvailabilityParams)

  return (
    <>
      {removedStrategy && (
        <UiAlert type="info">
          <Trans
            t={t}
            i18nKey="Your strategy “Wow so much piesel” has been unpublished."
            values={{ name: removedStrategy }}
            defaults={`Your strategy “{{name}}” has been unpublished.`}
          />
        </UiAlert>
      )}
      {isStrategyCreationAlertVisible && (
        <UiAlert type="success">
          {t('Your strategy has been successfully created.')}
        </UiAlert>
      )}
      {isStrategyUpdateAlertVisible && (
        <UiAlert type="success">
          {t('Your strategy has been successfully updated.')}
        </UiAlert>
      )}
      {isStopCopyingAlertVisible && (
        <UiAlert type="success">
          {t('Copying is stopped successfully.')}
        </UiAlert>
      )}
      {isAccountCreationAlertVisible && (
        <UiAlert type="success">
          {t('New account created successfully.')}
        </UiAlert>
      )}
      {isStartCopyingAlertVisible && (
        <UiAlert type="success">{t('Copying started successfully!')}</UiAlert>
      )}
      {paymentAvailabilityParams &&
        !paymentAvailabilityParams.isAvailable &&
        currentPath === path.copytrade.startCopying.replenishAccount() && (
          <UiAlert type="warning">
            <div className="flex justify-between">
              {t(
                'Insufficient funds. Please replenish your account and refresh the page.',
              )}
              <Styled.RefreshButton
                onClick={() => updateStartCopyingAccounts()}
              >
                {t('Refresh')}
              </Styled.RefreshButton>
            </div>
          </UiAlert>
        )}
    </>
  )
}
