import { createEvent, restore, sample } from 'effector'

import { fx } from '../../../../libs/request'
import { AuthSocials, CheckRegistrationAvailability } from './types'

export const mounted = createEvent<void>()

export const checkAuthRegistrationAvailability = fx<
  void,
  CheckRegistrationAvailability
>({ url: '/auth/v1/check-registration-availability' })

export const getAuthSocials = fx<void, AuthSocials>({
  url: '/auth/v1/socials',
})

export const $registrationAvailability = restore<CheckRegistrationAvailability>(
  checkAuthRegistrationAvailability.doneData.map((doneData) => doneData.body),
  { isForbidden: false, message: '' },
)

export const $authSocials = restore(
  getAuthSocials.doneData.map((doneData) => doneData.body.socials),
  [],
)

// export const onSetPartnerId = createEvent<string>();
// export const $partnerId = restore(onSetPartnerId);

sample({
  source: mounted,
  fn: () => ({}),
  target: [checkAuthRegistrationAvailability, getAuthSocials],
})
