import React, { useEffect } from 'react';
import { useDebounce } from 'usehooks-ts';
import { useUnit } from 'effector-react';

import { validateNumberMinMax } from 'libs/validators';
import { $amountField, $calcAmount, $destenation, onChangeCalcAmountEvent, setAmountErrorEvent } from '../model';
import { $customGatewayId } from '../../model';
import { useCalculateWithdrawal } from 'services/payments/mutations/useCalculateWithdrawal';


type Options = {
  activeCurrency: string;
  defaultCurrency: string;
  limits: {
    max: string;
    min: string;
  };
}

export const useAmountField = (amount: string | undefined, { limits, activeCurrency, defaultCurrency }: Options ) => {
  const destenation = useUnit($destenation);
  const gatewayId = useUnit($customGatewayId);
  const calcAmount = useUnit($calcAmount);
  const { errorType } = useUnit($amountField)
  const amountDebaunced = useDebounce(amount, 400);

  const { mutateAsync, isLoading } = useCalculateWithdrawal();

  // запрос на калькуляцию суммы
  const onCalcFetch = async (val: string) => {
    if (!destenation || !val || !activeCurrency) return;
    
    try {
      const resp = await mutateAsync({
        destinationID: destenation.id,
        destinationType: destenation.type,
        currency: activeCurrency,
        amount: val,
        gatewayId,
      });

      if (resp.currency === destenation.currency) {
        setAmountErrorEvent(+resp.convertedAmount > +destenation.balance ? 'MAX' : undefined );
      }

      if (activeCurrency === destenation.currency) {
        setAmountErrorEvent(+val > +destenation.balance ? 'MAX' : undefined );
      }
  
      onChangeCalcAmountEvent(resp);
    } catch (error) {
      
    }
  }

  // Валидация эмаунта
  const validateAmount = (val: string) => {
    const isValidMinMax = validateNumberMinMax(val, limits);

    if (!isValidMinMax) {
      setAmountErrorEvent('LIMIT_ERROR');
      return false;
    }

    setAmountErrorEvent(undefined);
    return isValidMinMax;
  }

  // Когда вызвать калькуляцию
  useEffect(() => {
    if (!amountDebaunced) return;
    if (!limits.max && !limits.min) return;
    // if (destenation?.currency === defaultCurrency) return;

    const isValid = validateAmount(amountDebaunced);

    if (isValid && amount) {
      // (amount !== calcAmount.convertedAmount && activeCurrency !== calcAmount.currency)
      // onChangeAmountEvent(amountDebaunced);
      onCalcFetch(amount);
    }
  }, [amountDebaunced, limits]);

  useEffect(() => {
    setAmountErrorEvent(undefined);
  }, [activeCurrency, destenation]);
  

  return {
    errorType,
    isLoadingCalc: isLoading,
    calcAmount,
  }
}