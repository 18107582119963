import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import { axios } from 'services/axios'

import { GET_STRATEGY_FOR_INVESTOR } from '../get/useGetStrategyForInvestor'
import type { Strategy } from '../types'

const addStrategyToFavorives = async (params: {
  strategyID: string
  isFavorites: boolean
}) => {
  await axios.patch<{ isFavorites: boolean }>(
    '/copytrade/v1/strategy/favorites',
    { ...params },
  )
}

export const useAddStrategyToFavorives = () => {
  return useMutation({
    mutationFn: addStrategyToFavorives,
    onSuccess(_, { isFavorites, strategyID }) {
      queryClient.setQueryData<Strategy>(
        GET_STRATEGY_FOR_INVESTOR,
        (oldData) =>
          oldData && {
            ...oldData,
            isFavorites,
            numFavorites: isFavorites
              ? oldData.numFavorites + 1
              : oldData.numFavorites - 1,
          },
      )
    },
  })
}
