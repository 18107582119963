import { useState } from 'react'

import * as Styled from './styled'

export const Tabs = ({
  items: tabs,
  onChange,
  defaultActiveValue,
  disabledActiveTab,
}: {
  defaultActiveValue?: string
  disabledActiveTab?: boolean
  items: { value: string; label: string }[]
  onChange?: (value: string) => void
}) => {
  const [activeTab, setActiveTab] = useState<string>(
    defaultActiveValue || tabs[0].value,
  )

  const handleChange = (value: string) => {
    setActiveTab(value)
    onChange?.(value)
  }

  return (
    <Styled.Wrapper>
      {tabs.map((tab) => (
        <Styled.Tab
          key={tab.value}
          active={!disabledActiveTab && activeTab === tab.value}
          onClick={() => handleChange(tab.value)}
        >
          {tab.label}
        </Styled.Tab>
      ))}
    </Styled.Wrapper>
  )
}
