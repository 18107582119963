import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 1;
  padding: 2px 8px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  font-weight: 500;
  // todo: use variable
  color: #a0753c;
  background-color: var(--color-sunray-100);
  border-radius: 8px 0 8px 0;
`