import { createEvent, createStore, restore, sample } from 'effector'
import { ClientError, createCountdown, mapResponseError } from 'libs'
import { CheckResetPasswordCodeProps, service } from 'services'

import { toPath } from 'features/router'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'

export const mounted = createEvent()

export const submitForm =
  createEvent<Pick<CheckResetPasswordCodeProps, 'code'>>()

export const submitRepeat = createEvent()

// submit form

sample({
  source: $userProfile,
  clock: submitForm,
  fn: ({ email }, { code }) => {
    return {
      query: { email: email.trim(), code },
    }
  },
  target: [service.auth.getCheckResetPasswordCode],
})

// redirect to confirm if success

sample({
  clock: service.auth.getCheckResetPasswordCode.done,
  target: toPath.prepend(() => path.register.password.recovery.change()),
})

// resend confirm timer

export const startResendTimer = createEvent<number>()
export const setTimerInitial = createEvent<number>()
export const $timerInitial = restore(setTimerInitial, 60)

const emailConfirmationTimer = createCountdown({
  name: 'checkResetPasswordCodeTimer',
  start: startResendTimer,
})

export const $resendTimer = restore<number>(emailConfirmationTimer.tick, 0)

// submit resend

sample({
  source: $userProfile,
  clock: submitRepeat,
  fn: ({ email }) => ({
    body: { email },
  }),
  target: service.auth.postResetPassword,
})

// start resend timer

sample({
  clock: service.auth.postResetPassword.doneData,
  fn: (doneData) => +doneData.body.resendAfter - Math.floor(Date.now() / 1000),
  target: setTimerInitial,
})

sample({
  clock: setTimerInitial,
  target: startResendTimer.prepend(() => $timerInitial.getState()),
})

// handle errors

export const $error = createStore<ClientError<
  Pick<CheckResetPasswordCodeProps, 'code'>
> | null>(null)

$error
  .on(service.auth.getCheckResetPasswordCode.failData, (_, failData) => {
    const err = mapResponseError<
      Pick<CheckResetPasswordCodeProps, 'code' | 'email'>
    >(failData.body)

    return err
  })
  .reset([mounted, submitForm, service.auth.postResetPassword.done])
