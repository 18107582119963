export const A3codes = [
  { country: 'Afghanistan', code: 'AFG' },
  { country: 'Aland Islands', code: 'ALA' },
  { country: 'Albania', code: 'ALB' },
  { country: 'Algeria', code: 'DZA' },
  { country: 'American Samoa', code: 'ASM' },
  { country: 'Andorra', code: 'AND' },
  { country: 'Angola', code: 'AGO' },
  { country: 'Anguilla', code: 'AIA' },
  { country: 'Antarctica', code: 'ATA' },
  { country: 'Antigua and Barbuda', code: 'ATG' },
  { country: 'Argentina', code: 'ARG' },
  { country: 'Armenia', code: 'ARM' },
  { country: 'Aruba', code: 'ABW' },
  { country: 'Australia', code: 'AUS' },
  { country: 'Austria', code: 'AUT' },
  { country: 'Azerbaijan', code: 'AZE' },
  { country: 'Bahamas', code: 'BHS' },
  { country: 'Bahrain', code: 'BHR' },
  { country: 'Bangladesh', code: 'BGD' },
  { country: 'Barbados', code: 'BRB' },
  { country: 'Belarus', code: 'BLR' },
  { country: 'Belgium', code: 'BEL' },
  { country: 'Belize', code: 'BLZ' },
  { country: 'Benin', code: 'BEN' },
  { country: 'Bermuda', code: 'BMU' },
  { country: 'Bhutan', code: 'BTN' },
  { country: 'Bolivia', code: 'BOL' },
  { country: 'Bonaire, Sint Eustatius and Saba', code: 'BES' },
  { country: 'Bosnia and Herzegovina', code: 'BIH' },
  { country: 'Botswana', code: 'BWA' },
  { country: 'Bouvet Island', code: 'BVT' },
  { country: 'Brazil', code: 'BRA' },
  { country: 'British Indian Ocean Territory', code: 'IOT' },
  { country: 'Brunei Darussalam', code: 'BRN' },
  { country: 'Bulgaria', code: 'BGR' },
  { country: 'Burkina Faso', code: 'BFA' },
  { country: 'Burundi', code: 'BDI' },
  { country: 'Cambodia', code: 'KHM' },
  { country: 'Cameroon', code: 'CMR' },
  { country: 'Canada', code: 'CAN' },
  { country: 'Cape Verde', code: 'CPV' },
  { country: 'Cayman Islands', code: 'CYM' },
  { country: 'Central African Republic', code: 'CAF' },
  { country: 'Chad', code: 'TCD' },
  { country: 'Chile', code: 'CHL' },
  { country: 'China', code: 'CHN' },
  { country: 'Christmas Island', code: 'CXR' },
  { country: 'Cocos (Keeling) Islands', code: 'CCK' },
  { country: 'Colombia', code: 'COL' },
  { country: 'Comoros', code: 'COM' },
  { country: 'Congo', code: 'COG' },
  { country: 'Congo, D.R.', code: 'COD' },
  { country: 'Cook Islands', code: 'COK' },
  { country: 'Costa Rica', code: 'CRI' },
  { country: "Cote d'Ivoire", code: 'CIV' },
  { country: 'Croatia', code: 'HRV' },
  { country: 'Cuba', code: 'CUB' },
  { country: 'Curaçao', code: 'CUW' },
  { country: 'Cyprus', code: 'CYP' },
  { country: 'Czech Republic', code: 'CZE' },
  { country: 'Denmark', code: 'DNK' },
  { country: 'Djibouti', code: 'DJI' },
  { country: 'Dominica', code: 'DMA' },
  { country: 'Dominican Republic', code: 'DOM' },
  { country: 'Ecuador', code: 'ECU' },
  { country: 'Egypt', code: 'EGY' },
  { country: 'El Salvador', code: 'SLV' },
  { country: 'Equatorial Guinea', code: 'GNQ' },
  { country: 'Eritrea', code: 'ERI' },
  { country: 'Estonia', code: 'EST' },
  { country: 'Ethiopia', code: 'ETH' },
  { country: 'Falkland Islands', code: 'FLK' },
  { country: 'Faroe Islands', code: 'FRO' },
  { country: 'Fiji', code: 'FJI' },
  { country: 'Finland', code: 'FIN' },
  { country: 'France', code: 'FRA' },
  { country: 'French Guiana', code: 'GUF' },
  { country: 'French Polynesia', code: 'PYF' },
  { country: 'French Southern Territories', code: 'ATF' },
  { country: 'Gabon', code: 'GAB' },
  { country: 'Gambia', code: 'GMB' },
  { country: 'Georgia', code: 'GEO' },
  { country: 'Germany', code: 'DEU' },
  { country: 'Ghana', code: 'GHA' },
  { country: 'Gibraltar', code: 'GIB' },
  { country: 'Greece', code: 'GRC' },
  { country: 'Greenland', code: 'GRL' },
  { country: 'Grenada', code: 'GRD' },
  { country: 'Guadeloupe', code: 'GLP' },
  { country: 'Guam', code: 'GUM' },
  { country: 'Guatemala', code: 'GTM' },
  { country: 'Guernsey', code: 'GGY' },
  { country: 'Guinea', code: 'GIN' },
  { country: 'Guinea-Bissau', code: 'GNB' },
  { country: 'Guyana', code: 'GUY' },
  { country: 'Haiti', code: 'HTI' },
  { country: 'Heard and McDonald Islands', code: 'HMD' },
  { country: 'Vatican City State', code: 'VAT' },
  { country: 'Honduras', code: 'HND' },
  { country: 'Hong Kong', code: 'HKG' },
  { country: 'Hungary', code: 'HUN' },
  { country: 'Iceland', code: 'ISL' },
  { country: 'India', code: 'IND' },
  { country: 'Indonesia', code: 'IDN' },
  { country: 'Iran, Islamic Republic of', code: 'IRN' },
  { country: 'Iraq', code: 'IRQ' },
  { country: 'Ireland', code: 'IRL' },
  { country: 'Isle of Man', code: 'IMN' },
  { country: 'Israel', code: 'ISR' },
  { country: 'Italy', code: 'ITA' },
  { country: 'Jamaica', code: 'JAM' },
  { country: 'Japan', code: 'JPN' },
  { country: 'Jersey', code: 'JEY' },
  { country: 'Jordan', code: 'JOR' },
  { country: 'Kazakhstan', code: 'KAZ' },
  { country: 'Kenya', code: 'KEN' },
  { country: 'Kiribati', code: 'KIR' },
  { country: 'Korea (North)', code: 'PRK' },
  { country: 'Korea (South)', code: 'KOR' },
  { country: 'Kuwait', code: 'KWT' },
  { country: 'Kyrgyzstan', code: 'KGZ' },
  { country: 'Lao', code: 'LAO' },
  { country: 'Latvia', code: 'LVA' },
  { country: 'Lebanon', code: 'LBN' },
  { country: 'Lesotho', code: 'LSO' },
  { country: 'Liberia', code: 'LBR' },
  { country: 'Libyan Arab Jamahiriya', code: 'LBY' },
  { country: 'Liechtenstein', code: 'LIE' },
  { country: 'Lithuania', code: 'LTU' },
  { country: 'Luxembourg', code: 'LUX' },
  { country: 'Macao', code: 'MAC' },
  { country: 'Macedonia', code: 'MKD' },
  { country: 'Madagascar', code: 'MDG' },
  { country: 'Malawi', code: 'MWI' },
  { country: 'Malaysia', code: 'MYS' },
  { country: 'Maldives', code: 'MDV' },
  { country: 'Mali', code: 'MLI' },
  { country: 'Malta', code: 'MLT' },
  { country: 'Marshall Islands', code: 'MHL' },
  { country: 'Martinique', code: 'MTQ' },
  { country: 'Mauritania', code: 'MRT' },
  { country: 'Mauritius', code: 'MUS' },
  { country: 'Mayotte', code: 'MYT' },
  { country: 'Mexico', code: 'MEX' },
  { country: 'Micronesia', code: 'FSM' },
  { country: 'Moldova', code: 'MDA' },
  { country: 'Monaco', code: 'MCO' },
  { country: 'Mongolia', code: 'MNG' },
  { country: 'Montenegro', code: 'MNE' },
  { country: 'Montserrat', code: 'MSR' },
  { country: 'Morocco', code: 'MAR' },
  { country: 'Mozambique', code: 'MOZ' },
  { country: 'Myanmar', code: 'MMR' },
  { country: 'Namibia', code: 'NAM' },
  { country: 'Nauru', code: 'NRU' },
  { country: 'Nepal', code: 'NPL' },
  { country: 'Netherlands', code: 'NLD' },
  { country: 'Netherlands Antilles', code: 'ANT' },
  { country: 'New Caledonia', code: 'NCL' },
  { country: 'New Zealand', code: 'NZL' },
  { country: 'Nicaragua', code: 'NIC' },
  { country: 'Niger', code: 'NER' },
  { country: 'Nigeria', code: 'NGA' },
  { country: 'Niue', code: 'NIU' },
  { country: 'Norfolk Island', code: 'NFK' },
  { country: 'Northern Mariana Islands', code: 'MNP' },
  { country: 'Norway', code: 'NOR' },
  { country: 'Oman', code: 'OMN' },
  { country: 'Pakistan', code: 'PAK' },
  { country: 'Palau', code: 'PLW' },
  { country: 'Palestinian Territory, Occupied', code: 'PSE' },
  { country: 'Panama', code: 'PAN' },
  { country: 'Papua New Guinea', code: 'PNG' },
  { country: 'Paraguay', code: 'PRY' },
  { country: 'Peru', code: 'PER' },
  { country: 'Philippines', code: 'PHL' },
  { country: 'Pitcairn', code: 'PCN' },
  { country: 'Poland', code: 'POL' },
  { country: 'Portugal', code: 'PRT' },
  { country: 'Puerto Rico', code: 'PRI' },
  { country: 'Qatar', code: 'QAT' },
  { country: 'Reunion', code: 'REU' },
  { country: 'Romania', code: 'ROU' },
  { country: 'Russian Federation', code: 'RUS' },
  { country: 'Rwanda', code: 'RWA' },
  { country: 'Saint Barthelemy', code: 'BLM' },
  { country: 'Saint Helena', code: 'SHN' },
  { country: 'Saint Kitts and Nevis', code: 'KNA' },
  { country: 'Saint Lucia', code: 'LCA' },
  { country: 'Saint Martin (French part)', code: 'MAF' },
  { country: 'Saint Pierre and Miquelon', code: 'SPM' },
  { country: 'Saint Vincent', code: 'VCT' },
  { country: 'Samoa', code: 'WSM' },
  { country: 'San Marino', code: 'SMR' },
  { country: 'Sao Tome and Principe', code: 'STP' },
  { country: 'Saudi Arabia', code: 'SAU' },
  { country: 'Senegal', code: 'SEN' },
  { country: 'Serbia', code: 'SRB' },
  { country: 'Seychelles', code: 'SYC' },
  { country: 'Sierra Leone', code: 'SLE' },
  { country: 'Singapore', code: 'SGP' },
  { country: 'Sint Maarten', code: 'SXM' },
  { country: 'Slovakia', code: 'SVK' },
  { country: 'Slovenia', code: 'SVN' },
  { country: 'Solomon Islands', code: 'SLB' },
  { country: 'Somalia', code: 'SOM' },
  { country: 'South Africa', code: 'ZAF' },
  { country: 'South Georgia Islands', code: 'SGS' },
  { country: 'South Sudan', code: 'SSD' },
  { country: 'Spain', code: 'ESP' },
  { country: 'Sri Lanka', code: 'LKA' },
  { country: 'Sudan', code: 'SDN' },
  { country: 'Suriname', code: 'SUR' },
  { country: 'Svalbard and Jan Mayen', code: 'SJM' },
  { country: 'Swaziland', code: 'SWZ' },
  { country: 'Sweden', code: 'SWE' },
  { country: 'Switzerland', code: 'CHE' },
  { country: 'Syrian Arab Republic', code: 'SYR' },
  { country: 'Taiwan', code: 'TWN' },
  { country: 'Tajikistan', code: 'TJK' },
  { country: 'Tanzania', code: 'TZA' },
  { country: 'Thailand', code: 'THA' },
  { country: 'Timor-Leste', code: 'TLS' },
  { country: 'Togo', code: 'TGO' },
  { country: 'Tokelau', code: 'TKL' },
  { country: 'Tonga', code: 'TON' },
  { country: 'Trinidad and Tobago', code: 'TTO' },
  { country: 'Tunisia', code: 'TUN' },
  { country: 'Turkey', code: 'TUR' },
  { country: 'Turkmenistan', code: 'TKM' },
  { country: 'Turks and Caicos Islands', code: 'TCA' },
  { country: 'Tuvalu', code: 'TUV' },
  { country: 'Uganda', code: 'UGA' },
  { country: 'Ukraine', code: 'UKR' },
  { country: 'United Arab Emirates', code: 'ARE' },
  { country: 'United Kingdom', code: 'GBR' },
  // { country: 'United States', code: 'USA' },
  { country: 'US Minor Outlying Islands', code: 'UMI' },
  { country: 'Uruguay', code: 'URY' },
  { country: 'Uzbekistan', code: 'UZB' },
  { country: 'Vanuatu', code: 'VUT' },
  { country: 'Venezuela', code: 'VEN' },
  { country: 'Viet Nam', code: 'VNM' },
  { country: 'Virgin Islands, British', code: 'VGB' },
  { country: 'Virgin Islands, U.S.', code: 'VIR' },
  { country: 'Wallis and Futuna', code: 'WLF' },
  { country: 'Western Sahara', code: 'ESH' },
  { country: 'Yemen', code: 'YEM' },
  { country: 'Zambia', code: 'ZMB' },
  { country: 'Zimbabwe', code: 'ZWE' },
  { country: 'Kosovo', code: 'XKX' },
]

export const getCountryByCode = (code: string) =>
  A3codes.find((a3) => a3.code === code)?.country || code

export const getCodeByCountry = (country: string) =>
  A3codes.find((a3) => a3.country === country)?.code || country

export const countryFlags: Record<string, string> = {
  AFG: '🇦🇫',
  ALA: '🇦🇽',
  ALB: '🇦🇱',
  DZA: '🇩🇿',
  ASM: '🇦🇸',
  AND: '🇦🇩',
  AGO: '🇦🇴',
  AIA: '🇦🇮',
  ATA: '🇦🇶',
  ATG: '🇦🇬',
  ARG: '🇦🇷',
  ARM: '🇦🇲',
  ABW: '🇦🇼',
  AUS: '🇦🇺',
  AUT: '🇦🇹',
  AZE: '🇦🇿',
  BHS: '🇧🇸',
  BHR: '🇧🇭',
  BGD: '🇧🇩',
  BRB: '🇧🇧',
  BLR: '🇧🇾',
  BEL: '🇧🇪',
  BLZ: '🇧🇿',
  BEN: '🇧🇯',
  BMU: '🇧🇲',
  BTN: '🇧🇹',
  BOL: '🇧🇴',
  BES: '🇧🇶',
  BIH: '🇧🇦',
  BWA: '🇧🇼',
  BVT: '🇧🇻',
  BRA: '🇧🇷',
  IOT: '🇮🇴',
  BRN: '🇧🇳',
  BGR: '🇧🇬',
  BFA: '🇧🇫',
  BDI: '🇧🇮',
  KHM: '🇰🇭',
  CMR: '🇨🇲',
  CAN: '🇨🇦',
  CPV: '🇨🇻',
  CYM: '🇰🇾',
  CAF: '🇨🇫',
  TCD: '🇹🇩',
  CHL: '🇨🇱',
  CHN: '🇨🇳',
  CXR: '🇨🇽',
  CCK: '🇨🇨',
  COL: '🇨🇴',
  COM: '🇰🇲',
  COG: '🇨🇬',
  COD: '🇨🇩',
  COK: '🇨🇰',
  CRI: '🇨🇷',
  CIV: '🇨🇮',
  HRV: '🇭🇷',
  CUB: '🇨🇺',
  CUW: '🇨🇼',
  CYP: '🇨🇾',
  CZE: '🇨🇿',
  DNK: '🇩🇰',
  DJI: '🇩🇯',
  DMA: '🇩🇲',
  DOM: '🇩🇴',
  ECU: '🇪🇨',
  EGY: '🇪🇬',
  SLV: '🇸🇻',
  GNQ: '🇬🇶',
  ERI: '🇪🇷',
  EST: '🇪🇪',
  ETH: '🇪🇹',
  FLK: '🇫🇰',
  FRO: '🇫🇴',
  FJI: '🇫🇯',
  FIN: '🇫🇮',
  FRA: '🇫🇷',
  GUF: '🇬🇫',
  PYF: '🇵🇫',
  ATF: '🇹🇫',
  GAB: '🇬🇦',
  GMB: '🇬🇲',
  GEO: '🇬🇪',
  DEU: '🇩🇪',
  GHA: '🇬🇭',
  GIB: '🇬🇮',
  GRC: '🇬🇷',
  GRL: '🇬🇱',
  GRD: '🇬🇩',
  GLP: '🇬🇵',
  GUM: '🇬🇺',
  GTM: '🇬🇹',
  GGY: '🇬🇬',
  GIN: '🇬🇳',
  GNB: '🇬🇼',
  GUY: '🇬🇾',
  HTI: '🇭🇹',
  HMD: '🇭🇲',
  VAT: '🇻🇦',
  HND: '🇭🇳',
  HKG: '🇭🇰',
  HUN: '🇭🇺',
  ISL: '🇮🇸',
  IND: '🇮🇳',
  IDN: '🇮🇩',
  IRN: '🇮🇷',
  IRQ: '🇮🇶',
  IRL: '🇮🇪',
  IMN: '🇮🇲',
  ISR: '🇮🇱',
  ITA: '🇮🇹',
  JAM: '🇯🇲',
  JPN: '🇯🇵',
  JEY: '🇯🇪',
  JOR: '🇯🇴',
  KAZ: '🇰🇿',
  KEN: '🇰🇪',
  KIR: '🇰🇮',
  PRK: '🇰🇵',
  KOR: '🇰🇷',
  KWT: '🇰🇼',
  KGZ: '🇰🇬',
  LAO: '🇱🇦',
  LVA: '🇱🇻',
  LBN: '🇱🇧',
  LSO: '🇱🇸',
  LBR: '🇱🇷',
  LBY: '🇱🇾',
  LIE: '🇱🇮',
  LTU: '🇱🇹',
  LUX: '🇱🇺',
  MAC: '🇲🇴',
  MKD: '🇲🇰',
  MDG: '🇲🇬',
  MWI: '🇲🇼',
  MYS: '🇲🇾',
  MDV: '🇲🇻',
  MLI: '🇲🇱',
  MLT: '🇲🇹',
  MHL: '🇲🇭',
  MTQ: '🇲🇶',
  MRT: '🇲🇷',
  MUS: '🇲🇺',
  MYT: '🇾🇹',
  MEX: '🇲🇽',
  FSM: '🇫🇲',
  MDA: '🇲🇩',
  MCO: '🇲🇨',
  MNG: '🇲🇳',
  MNE: '🇲🇪',
  MSR: '🇲🇸',
  MAR: '🇲🇦',
  MOZ: '🇲🇿',
  MMR: '🇲🇲',
  NAM: '🇳🇦',
  NRU: '🇳🇷',
  NPL: '🇳🇵',
  NLD: '🇳🇱',
  ANT: '🇧🇶',
  NCL: '🇳🇨',
  NZL: '🇳🇿',
  NIC: '🇳🇮',
  NER: '🇳🇪',
  NGA: '🇳🇬',
  NIU: '🇳🇺',
  NFK: '🇳🇫',
  MNP: '🇲🇵',
  NOR: '🇳🇴',
  OMN: '🇴🇲',
  PAK: '🇵🇰',
  PLW: '🇵🇼',
  PSE: '🇵🇸',
  PAN: '🇵🇦',
  PNG: '🇵🇬',
  PRY: '🇵🇾',
  PER: '🇵🇪',
  PHL: '🇵🇭',
  PCN: '🇵🇳',
  POL: '🇵🇱',
  PRT: '🇵🇹',
  PRI: '🇵🇷',
  QAT: '🇶🇦',
  REU: '🇷🇪',
  ROU: '🇷🇴',
  RUS: '🇷🇺',
  RWA: '🇷🇼',
  BLM: '🇧🇱',
  SHN: '🇸🇭',
  KNA: '🇰🇳',
  LCA: '🇱🇨',
  MAF: '🇲🇫',
  SPM: '🇵🇲',
  VCT: '🇻🇨',
  WSM: '🇼🇸',
  SMR: '🇸🇲',
  STP: '🇸🇹',
  SAU: '🇸🇦',
  SEN: '🇸🇳',
  SRB: '🇷🇸',
  SYC: '🇸🇨',
  SLE: '🇸🇱',
  SGP: '🇸🇬',
  SXM: '🇸🇽',
  SVK: '🇸🇰',
  SVN: '🇸🇮',
  SLB: '🇸🇧',
  SOM: '🇸🇴',
  ZAF: '🇿🇦',
  SGS: '🇬🇸',
  SSD: '🇸🇸',
  ESP: '🇪🇸',
  LKA: '🇱🇰',
  SDN: '🇸🇩',
  SUR: '🇸🇷',
  SJM: '🇸🇯',
  SWZ: '🇸🇿',
  SWE: '🇸🇪',
  CHE: '🇨🇭',
  SYR: '🇸🇾',
  TWN: '🇹🇼',
  TJK: '🇹🇯',
  TZA: '🇹🇿',
  THA: '🇹🇭',
  TLS: '🇹🇱',
  TGO: '🇹🇬',
  TKL: '🇹🇰',
  TON: '🇹🇴',
  TTO: '🇹🇹',
  TUN: '🇹🇳',
  TUR: '🇹🇷',
  TKM: '🇹🇲',
  TCA: '🇹🇨',
  TUV: '🇹🇻',
  UGA: '🇺🇬',
  UKR: '🇺🇦',
  ARE: '🇦🇪',
  GBR: '🇬🇧',
  UMI: '🇺🇲',
  URY: '🇺🇾',
  UZB: '🇺🇿',
  VUT: '🇻🇺',
  VEN: '🇻🇪',
  VNM: '🇻🇳',
  VGB: '🇻🇬',
  VIR: '🇻🇮',
  WLF: '🇼🇫',
  ESH: '🇪🇭',
  YEM: '🇾🇪',
  ZMB: '🇿🇲',
  ZWE: '🇿🇼',
  XKX: '🇽🇰',
}
