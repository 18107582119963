import {
  Event,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
} from 'effector'

type CountdownProps = {
  name: string
  start: Event<number>
  abort?: Event<void>
  timeout?: number
}

export function createCountdown({
  name,
  start,
  abort = createEvent(`${name}Reset`),
  timeout = 1000,
}: CountdownProps) {
  const $working = createStore<boolean>(true, { name: `${name}Working` })
  const tick = createEvent<number>(`${name}Tick`)
  const timerFx = createEffect(`${name}Timer`).use(() => wait(timeout))

  $working.on(abort, () => false).on(start, () => true)

  guard({
    source: start,
    filter: timerFx.pending.map((is) => !is),
    target: tick,
  })

  forward({
    from: tick,
    to: timerFx,
  })

  const willTick = guard({
    source: timerFx.done.map(({ params }: any) => params - 1),
    filter: (seconds) => seconds >= 0,
  })

  guard({
    source: willTick,
    filter: $working,
    target: tick,
  })

  return { tick }
}

function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
