import { createEvent, sample } from 'effector'

import { toPath } from '../../../../../features/router'
import { $userProfile } from '../../../../../features/user'
import { path } from '../../../../../libs'
import { ResetPasswordProps, service } from '../../../../../services'
import { setTimerInitial } from '../confirm/model'

export const submitForm = createEvent<ResetPasswordProps>()

// saving recovery profile

sample({
  source: $userProfile,
  clock: submitForm,
  fn: (userProfile, submitForm) => {
    userProfile.email = submitForm.email
    return userProfile
  },
})

// submit form

sample({
  source: submitForm,
  fn: ({ email }) => {
    return {
      body: {
        email: email.trim(),
      },
    }
  },
  target: service.auth.postResetPassword,
})

// redirect to confirm if postResetPassword succeed

sample({
  clock: service.auth.postResetPassword.doneData,
  target: toPath.prepend(() => path.register.password.recovery.confirm()),
})

// errors

sample({
  clock: service.auth.postResetPassword.failData,
  filter: (failData) => Boolean(failData.body.details?.params.resendAfter),
  fn: (failData) =>
    failData.body.details
      ? +failData.body.details?.params.resendAfter -
        Math.floor(Date.now() / 1000)
      : 0,
  target: [
    setTimerInitial,
    toPath.prepend(() => path.register.password.recovery.confirm()),
  ],
})
