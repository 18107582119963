
import React, { ChangeEvent } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { OrderForm } from 'services/giftshop/mutations/useGiftOrder'
import { Button } from 'ui/atoms'
import { FormInput } from 'ui/molecules'

import { schema } from './utils'
import { CountrySelect } from './CountrySelect'


interface Props {
  onSubmit: (values: OrderForm) => void
  disabled: boolean
}

export const GiftOrderForm: React.FC<Props> = ({ onSubmit, disabled }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    setValue
  } = useForm<OrderForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
    },
    mode: 'onTouched',
    // без any почему то форма берет типы из резолвера
    // даже явное определение типа формы через дженерик не помогает
    resolver: yupResolver(schema as any),
  })

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('contactNumber', `+${event.target.value.replace(/[^\d]/g, '')}`)
  }

  return (
    <>
      <fieldset
        className="my-[4rem] lg:w-[60rem] flex flex-col gap-[2rem] relative"
        disabled={disabled}
      >
        <FormInput
          state={'hint'}
          errorMessage={errors.firstName?.message}
          isTouchedForm={dirtyFields?.firstName}
          placeholder="First name"
          autoComplete="off"
          maxLength={32}
          {...register('firstName')}
        />
        <FormInput
          // className='mt-[1.6rem]'
          errorMessage={errors.lastName?.message}
          isTouchedForm={dirtyFields?.lastName}
          placeholder="Last name"
          autoComplete="off"
          maxLength={32}
          {...register('lastName')}
        />
        <div className="w-full flex gap-[2rem]">
          <FormInput
            {...register('contactNumber')}
            type="text"
            errorMessage={errors.contactNumber?.message}
            isTouchedForm={dirtyFields?.contactNumber}
            maxLength={15}
            // inputClasses='!ps-[2.5rem]'
            placeholder="Contact number"
            className="!w-1/2"
            onChange={handlePhoneChange}
            
          />

          <CountrySelect control={ control } error={errors?.country} />
        </div>

        <FormInput
          {...register('address')}
          type="text"
          errorMessage={errors.address?.message}
          isTouchedForm={dirtyFields?.address}
          replacePattern={/[<>@]/gm}
          maxLength={140}
          placeholder="Address"
          autoComplete="off"
        />
      </fieldset>

      <Button
        name="submit"
        className="min-w-[24rem]"
        onClick={handleSubmit(onSubmit)}
        loading={disabled}
        disabled={disabled}
      >
        Order now
      </Button>
    </>
  )
}
