import styled, { css } from 'styled-components/macro'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Title } from 'ui/atoms'

import target from '../../images/target.svg'
import { GiftPrice } from '../giftPrice'

export const Wrapper = styled.div<{ isTarget: boolean }>`
  position: relative;
  overflow: hidden;
  padding: 20px;
  height: 380px;
  display: grid;
  align-content: end;
  border-radius: 8px;
  background-color: var(--color-gray-300);

  @media (max-width: 1300px) {
    height: 300px;
  }

  @media (max-width: 900px) {
    height: 260px;
  }

  @media (max-width: 570px) {
    height: 357px;
  }

  @media (max-width: 470px) {
    padding: 16px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 25, 0.6);
    backdrop-filter: blur(12px);
    transition: opacity 0.25s;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  ${({ isTarget }) =>
    isTarget &&
    css`
      &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 32px;
        height: 32px;
        background-image: url(${target});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        @media (max-width: 470px) {
          top: 12px;
          right: 12px;
        }
      }
    `}
`

export const Name = styled(Title)`
  z-index: 2;
`

export const Image = styled(LazyLoadImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const Price = styled(GiftPrice)`
  position: absolute;
  top: 16px;
  left: 16px;

  @media (max-width: 470px) {
    top: 12px;
    left: 12px;
  }
`

export const Buttons = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  gap: 20px;
  grid-template-columns: 240px;

  @media (max-width: 1300px) {
    margin: 0 30px;
    width: calc(100% - 60px);
    grid-template-columns: 1fr;
  }
`