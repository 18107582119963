import { useStore } from 'effector-react'

import { $activeSettings } from '../model'
import { createAccount } from '../model'
import { $activeTariffName } from '../tariff'
import {
  $activeCurrency,
  $activeFixRate,
  $activeLeverage,
  $activeSwapFree,
  $initialBalance,
} from './model'

export const useAccountSettings = () => {
  const { currencies, leverages, swapFree } = useStore($activeSettings)

  const activeCurrency = useStore($activeCurrency)
  const activeFixRate = useStore($activeFixRate)
  const activeLeverage = useStore($activeLeverage)
  const activeSwapFree = useStore($activeSwapFree)
  const initialBalance = useStore($initialBalance)
  const activeTariffName = useStore($activeTariffName)

  const isCreateAccountLoading = useStore(createAccount.pending)
  const isFixRateAvailable = currencies.items.find((c) => c.currency === activeCurrency)?.fixRate;

  return {
    activeCurrency,
    activeFixRate,
    activeLeverage,
    activeSwapFree,
    initialBalance,
    currencies: currencies.items,
    currenciesDefault: currencies.default,
    leverages: leverages,
    leveragesDefault: leverages.default,
    isSwapFreeAvailable: Boolean(swapFree),
    isFixRateAvailable,
    isCreateAccountLoading,
    activeTariffName,
  }
}
