import React, { useEffect, useState } from 'react';

import { deleteCarouselItemEvent } from './model';
import * as S from './styled';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


interface Props extends React.PropsWithChildren {
  children: React.ReactNode[];
  className?: string;
  autoPlay?: boolean;
  interval?: number;
}

export const Carousel: React.FC<Props> = ({ children, className = '', autoPlay, interval }) => {
  const [selected, setSelected] = useState(0);

  const onChange = (item: number) => {
    setSelected(item);
  }

  useEffect(() => {
    const unSubcribe = deleteCarouselItemEvent.watch(() => {
      if (selected <= children?.length) {
        setSelected(prev => prev - 1);
      }
      if (selected === 0) setSelected(prev => prev + 1);
    });
  
    return () => {
      unSubcribe();
    }
  }, [selected, children]);

  return (
    <S.CarouselUi
      showThumbs={ false }
      showStatus={ false }
      className={ className }
      showArrows={ false }
      onChange={onChange}
      autoPlay={ autoPlay }
      interval={ interval }
      infiniteLoop
      
      selectedItem={selected}
      swipeable
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        return (
          <li
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            className={`${isSelected ? 'w-[12px] bg-sunray.800' : 'w-[6px]'} h-[6px] rounded-full bg-gray.300 inline-block mr-[4px] cursor-pointer`}
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />)
      }}
    >
      { children as React.ReactChild[] }
    </S.CarouselUi>
  )
}
