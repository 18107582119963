import * as React from 'react'
import styled from 'styled-components/macro'

import { Design, Icon, Icons, Size } from '..'

type TagProps = {
  name?: string
  size?: Size
  icon?: Icons
  children?: React.ReactNode
  design?: Design
  className?: string
}

export const Tag = ({
  name = 'default',
  size = 'medium',
  children,
  icon,
  design,
  className,
}: TagProps) => {
  return (
    <TagContainer
      className={className}
      data-design={design}
      data-size={size}
      id={`${name}-tag`}
    >
      {icon && <TagIcon name={icon} />}
      {children}
    </TagContainer>
  )
}

const TagContainer = styled.div<Pick<TagProps, 'design' | 'size'>>`
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;

  &[data-size='small'] {
    height: 18px;
    padding: 0 4px;
    border-radius: 4px;
    font-size: var(--body-font-size-small);
  }

  &[data-size='medium'] {
    padding: 1px 6px;
    height: 20px;
    border-radius: 3px;
    margin-inline-end: 8px;
    font-size: var(--body-font-size-medium);
  }

  &[data-size='large'] {
  }

  &[data-design='sunray-dark'] {
    background-color: var(--color-sunray-500);
    color: #fff;
  }

  &[data-design='sunray'] {
    background-color: var(--color-sunray-100);
    color: var(--color-sunray-700);
  }

  &[data-design='azure'] {
    background-color: var(--color-azure-100);
    color: var(--color-azure-800);
  }

  &[data-design='eucalyptus'] {
    background-color: var(--color-eucalyptus-100);
    color: var(--color-eucalyptus-600);
  }

  &[data-design='gray'] {
    background-color: var(--color-gray-300);
    color: var(--color-gray-600);
  }

  &[data-design='ghost'] {
    background-color: transparent;
    color: var(--color-gray-600);
    padding: 1px 0;
  }

  @media (max-width: 420px) {
    width: fit-content;
  }
`

const TagIcon = styled(Icon)``
