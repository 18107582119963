import React from 'react';

import { Text } from 'ui/atoms';

import * as S from './styled';

interface Props {
  wrapClasses?: string;
  curentProgressLabel?: string;
  progress: number;
  progressClasses?: string;
  isSkipIsNull?: boolean;
}

export const Progress: React.FC<Props> = ({ 
    curentProgressLabel, progressClasses,
    progress, wrapClasses, isSkipIsNull,
}) => {
  const progressNumber = progress > 100 ? 100 : progress;
 
  return (
      <S.progressWrap className={wrapClasses}>
        { (!!progress || !isSkipIsNull) && <S.ProgressBar width={progressNumber} className={progressClasses}>
            { curentProgressLabel && 
              <Text className='!text-[12px] !font-medium' level={2}>
                {curentProgressLabel}
              </Text>
            }
        </S.ProgressBar>}
      </S.progressWrap>
  )
}
