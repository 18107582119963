import { fx } from '../../libs'
import {
  InternalTransferCalculateResponse,
  InternalTransferDestinationsProps,
  InternalTransferDestinationsResponse,
  InternalTransferProps,
  InternalTransferSourcesProps,
  InternalTransferSourcesResponse,
} from './types'

// https://gitlab.com/headliner/dev/go/services/internal-transfers/-/blob/main/api/swagger/billing.internal-transfers.grpc.public_api.swagger.json

export const postInternalTransfer = fx<InternalTransferProps, void>({
  method: 'POST',
  url: '/internal-transfers/v1/internal-transfer',
})

export const getInternalTransferCalculate = fx<
  InternalTransferProps,
  InternalTransferCalculateResponse
>({
  method: 'GET',
  url: '/internal-transfers/v1/internal-transfer/calculate',
})

export const getInternalTransferDestinations = fx<
  InternalTransferDestinationsProps,
  InternalTransferDestinationsResponse
>({
  method: 'GET',
  url: '/internal-transfers/v1/internal-transfer/destinations',
})

export const getInternalTransferSources = fx<
  InternalTransferSourcesProps,
  InternalTransferSourcesResponse
>({
  method: 'GET',
  url: '/internal-transfers/v1/internal-transfer/sources',
  query: { includeWallets: 'true', includeAccounts: 'true', includeExchangerWallets: 'true' },
})
