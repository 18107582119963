import { createStore, createEvent } from "effector";

export interface FilterStore {
  commissionPeriod?: {
    from: string;
    to: string;
  }
  login?: string | undefined;
}

export const changePeriodEvent = createEvent<FilterStore['commissionPeriod']>();
export const changeLoginEvent = createEvent<string>();
export const filterResetEvent = createEvent();

export const $tableFilterStore = createStore<FilterStore>({
  commissionPeriod: {
    from: '',
    to: '',
  },
  login: '',
});

$tableFilterStore.on(changePeriodEvent, (state, period) => ({
  ...state,
  commissionPeriod: period,
}));

$tableFilterStore.on(changeLoginEvent, (state, login) => ({
  ...state,
  login,
}));

$tableFilterStore.reset(filterResetEvent);