import React from 'react'
import { useUnit, useStore } from 'effector-react'

import { $userProfile } from 'features/user'
import { useIsMobile } from 'libs/hooks/useIsMobile'
import { TimeFilter } from 'pages/copytrade/traderTables/statisticsTab/TimeFilter'

import { Title } from 'ui/atoms'

import { SignupTooltip } from '../signupTooltip'
import { $perfomanceFilterTime, setPerfomanceTime } from './model'

const filterVariant = [
  {
    label: '1W',
    value: 'TIME_RANGE_WEEK',
  },
  {
    label: '1M',
    value: 'TIME_RANGE_MONTH',
  },
  {
    label: '3M',
    value: 'TIME_RANGE_THREE_MONTH',
  },
  {
    label: '6M',
    value: 'TIME_RANGE_SIX_MONTH',
  },
] as const

export const HeadBlock = () => {
  const timeFilter = useUnit($perfomanceFilterTime)

  const isMobile = useIsMobile()

  const {userID} = useStore($userProfile)

  return (
    <div className="flex lg:justify-between lg:flex-row flex-col">
      <Title level={isMobile ? 3 : 2} className="lg:mb-0 mb-[15px]">
        Perfomance
      </Title>

      <div className="lg:ms-auto flex gap-[6px] mt-[10px] lg:mt-[0px]">
        {filterVariant.map((item) => (
          <SignupTooltip key={item.value} enabled={!userID && item.value !== 'TIME_RANGE_WEEK'}>
            <div className="flex-1">
              <TimeFilter
                onSetTime={() => !!userID && setPerfomanceTime(item.value)}
                isActive={timeFilter === item.value}
                isMobile={isMobile}
                isDisabled={!userID}
                className={'lg:px-[23px] py-[8px] px-[15px]'}
              >
                {item.label}
              </TimeFilter>
            </div>
          </SignupTooltip>
        ))}

      <SignupTooltip enabled={!userID}>
        <div>
          <TimeFilter
            onSetTime={() => !!userID && setPerfomanceTime('TIME_RANGE_OVERALL')}
            isActive={timeFilter === 'TIME_RANGE_OVERALL'}
            isMobile={isMobile}
            isDisabled={!userID}
            className="lg:min-w-[93px] min-w-[73px] lg:px-[23px] py-[8px] px-[15px] flex-1"
          >
            Overall
          </TimeFilter>
        </div>
      </SignupTooltip>
      </div>
    </div>
  )
}
