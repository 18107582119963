import { useMutation } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { CalculateData } from './types';

const postCalculatePayments = async (data: CalculateData) => {
  const res = await axios.post<CalculateData>(`/payments/v1/withdrawal-calculate`, data);

  return res.data;
};

export const useCalculatePayments = () => {
  return useMutation({
    mutationFn: (data: CalculateData) => postCalculatePayments(data),
    retry: retryFn,
  })
};
