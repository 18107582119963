import { ProfileStatus } from 'services/profile/getProfile';

export const editableStatusesWithModal = [
  ProfileStatus.EXPIRATION_DATE_COMING_UP,
  ProfileStatus.VERIFIED,
];

export const editableStatusesFree = [
  ProfileStatus.NOT_VERIFIED,
  ProfileStatus.REJECTED,
  ProfileStatus.EXPIRED,
];