import { isWebview } from '@dvlden/is-webview'
import { useGoogleLogin } from '@react-oauth/google'
import cryptoRandomString from 'crypto-random-string'
import { useStore } from 'effector-react'
import { Captcha, useCaptcha } from 'features/captcha'
import { env } from 'libs/constants'
import { setCookie } from 'libs/cookie'
import * as React from 'react'
import styled from 'styled-components/macro'

import { Button, Icon } from '../../ui/atoms'
import {
  $authSocialName,
  oAuthBySocial,
  setAuthSocialName,
  submitOAuthError,
  submitOAuthToken,
} from './model'

type Props = {
  disabled?: boolean
  className?: string
}

export const OAuthGoogleButton = ({ className, disabled = false }: Props) => {
  const {
    isLoading: isVerificationLoading,
    ref: recaptchaRef,
    execute,
  } = useCaptcha()

  const loginGoogle = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    onSuccess: (response) => {
      submitOAuthToken({
        authorizationCode: response.code,
        socialType: 1,
        partnerKey: localStorage.getItem('partnerId') as string,
      })
    },
    onError: (response) => submitOAuthError(response?.error || ''),
  })

  const isOathLoading = useStore(oAuthBySocial.pending)
  const loadingSocialName = useStore($authSocialName)

  const isLoading = isOathLoading || isVerificationLoading

  // в вебвью капча ломает авторизацию через гугл 
  const isWebView = isWebview(window.navigator.userAgent)

  const handleClick = () => {
    if (isWebView) {
      loginGoogle()
      setAuthSocialName('google')
    } else {
      execute(() => {
        loginGoogle()
        setAuthSocialName('google')
      })
    }
  }

  return (
    <>
      <SocialAuthButtonWrap
        className={className}
        name="oauth-google"
        design="secondary"
        size="large"
        prefix={<Icon name="google" size="medium" />}
        onClick={handleClick}
        loading={isLoading && loadingSocialName === 'google'}
        disabled={isLoading || disabled}
      >
        Google
      </SocialAuthButtonWrap>
      {!isWebView && <Captcha ref={recaptchaRef} />}
    </>
  )
}

export const OAuthFacebookButton = ({ className, disabled = false }: Props) => {
  const {
    isLoading: isVerificationLoading,
    ref: recaptchaRef,
    execute,
  } = useCaptcha()

  const isOathLoading = useStore(oAuthBySocial.pending)
  const loadingSocialName = useStore($authSocialName)

  const isLoading = isOathLoading || isVerificationLoading

  return (
    <>
      <SocialAuthButtonWrap
        className={className}
        name="oauth-facebook"
        design="secondary"
        size="large"
        prefix={<Icon name="facebook" size="medium" />}
        onClick={() =>
          execute(() => {
            const state = cryptoRandomString({ length: 10 })
            setCookie('fbState', state, 180)

            window.location.href = `https://www.facebook.com/v16.0/dialog/oauth?client_id=${env.facebook.appId}&scope=public_profile email&redirect_uri=${window.location.origin}/user/login&state=${state}`
          })
        }
        loading={isLoading && loadingSocialName === 'facebook'}
        disabled={disabled || isLoading}
      >
        Facebook
      </SocialAuthButtonWrap>
      <Captcha ref={recaptchaRef} />
    </>
  )
}

const SocialAuthButtonWrap = styled(Button)`
  width: 100%;
  justify-content: center;
  text-transform: capitalize;
`

export const OAuthAppleButton = ({ className }: Props) => {
  const [isActive, setActive] = React.useState(false)
  React.useEffect(() => {
    // hack for appleID being undefined
    // appleID takes time to load
    setTimeout(() => {
      setActive(true)
    }, 30000)
  }, [])

  return (
    <>
      {/* <LoginSocialApple
        client_id={'com.test-01.frt-v-app-01.hw.app.hwre'}
        scope={'email name'}
        redirect_uri={'https://test.local'}
        onLoginStart={() => {
          console.log('1')
        }}
        onResolve={({ provider, data }) => {
          console.log(provider)
          console.log(data)
        }}
        onReject={(err) => {
          console.log(err)
        }}
      >
        fdnj
      </LoginSocialApple> */}
      {/* {isActive ? ( */}
      {/* <AppleLogin
        clientId="com.test-01.frt-v-app-01.hw.app.hwre"
        scope="email name"
        redirectURI="https://test.local"
        callback={(res) => {
          console.log(res, 'res')
        }}
        responseMode="form_post"
        responseType="code"
        usePopup={true}
      /> */}
      {/* ) : null} */}
    </>
  )
}
