import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { resources as contestResources } from './contest'
import { resources as copytradeResources } from './copytrade'
import { resources as giftshopResources } from './giftshop'
import ar from './langs/ar.json'
import en from './langs/en.json'
import es from './langs/es.json'
import fr from './langs/fr.json'
import id from './langs/id.json'
import ja from './langs/ja.json'
import ms from './langs/ms.json'
import pt from './langs/pt.json'
import th from './langs/th.json'
import vi from './langs/vi.json'

export const enabledLangs = [
  'ar',
  'en',
  'es',
  'fr',
  'id',
  'ja',
  'ms',
  'pt',
  'th',
  'vi',
]

const resources = { en, es, ar, fr, id, ms, th, vi, ja, pt }

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    supportedLngs: enabledLangs,
    fallbackLng: 'en',
    resources: Object.entries(resources).reduce((acc, [key, value]) => {
      acc[key] = {
        cabinet: {
          ...value.cabinet,
          // @ts-expect-error
          ...giftshopResources[key].cabinet,
          // @ts-expect-error
          ...copytradeResources[key].cabinet,
          // @ts-expect-error
          ...contestResources[key].cabinet,
        },
      }
      return acc
    }, {} as any),
    defaultNS: ['cabinet'],
  })

export default i18n
