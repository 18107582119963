import styled from "styled-components";

import backgroundMobile from '../../images/points-background-mobile.jpg'
import background from '../../images/points-background.jpg'

export const Wrapper = styled.div`
  margin-top: 40px;
  min-height: 80px;
  display: grid;
  place-items: center;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  text-align: center;
  border-radius: 8px;
  background-color: #ffd600;
  background-image: url(${background});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 530px) {
    margin-top: 20px;
    padding: 10px 25%;
    min-height: 70px;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.16px;
    background-image: url(${backgroundMobile});
  }
`