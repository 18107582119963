import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'
import { ProfileContent } from 'services/profile/getProfile'
import { GET_PROFILE_KEY } from 'services/profile/getProfile/useGetProfile'

const putChangeUserPhone = async (phone: string) => {
  await axios.put('/user/v1/phone', {
    phone,
  })
}

export const usePutChangeUserPhone = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: putChangeUserPhone,
    retry: retryFn,
    onSuccess(data, phone) {
      // Эта функция перезаписывает кэш
      queryClient.setQueryData(GET_PROFILE_KEY, (oldData: any) => ({
        ...(oldData as ProfileContent),
        phone: phone.replace('+', ''),
      }))
    },
  })
}
