import { path } from 'libs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useGetBonusInfo } from 'services/bonus/get/useGetBonusInfo'

import * as Styled from './styled'

export const BonusBanner = () => {
  const { data: bonusData } = useGetBonusInfo()

  const [t] = useTranslation()

  return bonusData?.participantInfo.status === 'NOT_PARTICIPANT' ? (
    <Styled.Wrapper>
      <Styled.BannerTitle level={1}>
        $111 Bonus for Your First Steps in Forex
      </Styled.BannerTitle>
      <div className="leading-normal">
        {t('Start your trading way successfully and without initial deposit')}
      </div>
      <Link
        to={path.dashboard.entry()}
        state={{ fromBonusBanner: true }}
        className="mt-auto"
      >
        <Styled.GetButton name="get-bonus" size="medium" design="secondary">
          Get $111
        </Styled.GetButton>
      </Link>
    </Styled.Wrapper>
  ) : null
}
