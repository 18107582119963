import { useEffect, useMemo, useRef, useState } from 'react'
import { useStore, useUnit } from 'effector-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { DepositBanner } from 'pages/contest/molecules/depositBanner';
import { useGetBanners } from 'services/banners/get/useGetBanners'
import { useHideBanner } from 'services/banners/mutations/useHideBanner'
import { useGetGateways } from 'services/money/get/useGetGateways';
import { useGetSourceList } from 'services/payments/get/useGetSourceList';
import { CreateDepositResponse, useCreateDeposit } from 'services/payments/mutations/useCreateDeposit';

import { SourceItem } from 'services/payments/get/types';
import { Gateway } from 'services/money/get/types';
import { path } from 'libs/path';
import { $copyingSettings } from 'pages/copytrade/model';

import { SpinnerWithBlur, Title, Text, Link } from 'ui/atoms';

import { GatewaysGrid } from '../molecules/gatewaysGrid'
import { AccountSelect } from '../molecules/newAccountSelect'
import { TransferFeatures } from '../molecules/transferFeatures'
import { setDestenationEvent } from './CutomFormDep/model';
import { $accountDeposit, $customGatewayId, accountChooseReset, resetCustomGatewayIdEvent, setCustomGatewayIdEvent } from './model';
import { PostFrame } from './PostFrame';
import { PraxisForm } from './PraxisForm';

import { DepositFormResolver } from './CutomFormDep/DepositFormResolver';
import { SITE_EMAIL } from 'libs/constants';


export const DepositChoice = () => {
  const { data: banners, refetch: refetchBanners } =
    useGetBanners('SECTION_FINANCE')
  const { mutateAsync: hideBanner, isLoading: hideBannerLoading } =
    useHideBanner();

  const { gatewayId } = useParams();

  const [t] = useTranslation()

  const { data: getGatewaysData } = useGetGateways()
  const { data: destinationsData } = useGetSourceList('deposit')

  const nav = useNavigate();

  const customGatewayId = useUnit($customGatewayId)

  const isInitalValueAcc = useRef(true)

  const accountDepositStore = useStore($accountDeposit)
  const { investorAccountID } = useStore($copyingSettings)

  const [gateway, setGateway] = useState<Gateway | null>(null); 
  const [selectValue, setSelectValue] = useState<SourceItem>();
  const [isOpenNewTab, setIsOpenNewTab] = useState<boolean | undefined>(false)
  const [url, setUrl] = useState('')
  const [customFrame, setCustomFrame] = useState<
    CreateDepositResponse | undefined
  >(undefined)

  const { mutateAsync, isLoading: isLoadingCreateDep } = useCreateDeposit()

  const onChooseGateway = async (gateway: Gateway) => {
    setIsOpenNewTab(gateway.openInNewTab);
    if (gateway.processingProvider !== 'PPP_PRAXIS') {
      if (gateway.openInNewTab) {
        window.open(
          `${path.money.h2pCustom()}?customGatewayId=${
            gateway.gatewayID
          }&chosenDestenation=${selectValue?.id}`,
          '_blank',
          'noreferrer',
        )
        return
      }

      setGateway(gateway);
      setDestenationEvent(selectValue!);
      setCustomGatewayIdEvent(gateway.gatewayID);
      nav(path.money.depositForm(gateway.gatewayID));
      return
    }

    const resp = await mutateAsync({
      gatewayId: gateway.gatewayID,
      destinationID: selectValue!.id,
      destinationType: selectValue!.type,
    })

    if (resp?.URL) {
      setUrl(resp.URL)
    }

    nav(path.money.depositForm(gateway.gatewayID));
  }

  const onSuccessCreateDep = (resp: CreateDepositResponse) => {
    if (!resp) return

    if (resp.requestType === 'RT_GET') {
      window.location.href = resp.URL;
      return;
    }

    setCustomFrame(resp)
    setCustomGatewayIdEvent('');
    setGateway(null);
  }

  useEffect(() => {
    if (gatewayId) {
      nav(path.money.entry(), {replace: true});
    }
  }, []);
  

  const destinationsDataList = useMemo(() => {
    if (!destinationsData) return []

    return (destinationsData as SourceItem[]).map((item) => ({
      ...item,
      id: item.id,
      type: item.type,
    }))
  }, [destinationsData])

  useEffect(() => {
    return () => {
      accountChooseReset()
      resetCustomGatewayIdEvent()
    }
  }, [])

  // сетинг дефолтного значения
  useEffect(() => {
    if (!destinationsData) return
    if (!isInitalValueAcc.current) return

    // Если в сторе нет значения по дефолту, то анализируем ответ от бэка
    if (!accountDepositStore && !investorAccountID) {
      const onlyAccountsArray = destinationsData.filter(
        (item) => item.type === 'OT_ACCOUNT',
      )
      // Если всего один счет, ставим его как дефолтное значение
      if (onlyAccountsArray?.length === 1) {
        setSelectValue(onlyAccountsArray[0])
      }

      return
    }

    const finded = destinationsData.find(
      (item) => item.id === accountDepositStore || item.id === investorAccountID,
    )
    
    if (finded) {
      setSelectValue(finded)
    }

    return
  }, [destinationsData])

  const handleHideBanner = async (name: string) => {
    try {
      await hideBanner(name)
      refetchBanners()
    } catch {
      // наверное, ошибки не нужны, просто ничего не выводим и всё
    }
  }

  if (url) return ( 
    <PraxisForm
      url={url}
      resetUrl={() => {
        setUrl('');
        nav(path.money.entry(), {replace: true});
      }}
    />
  )

  if (customFrame)
    return (
      <PostFrame
        url={customFrame.URL}
        transactionID={customFrame.transactionID}
        isNewTab={isOpenNewTab}
        payload={customFrame?.payload!}
      />
    )

  if (customGatewayId && gateway)
    return (
      <DepositFormResolver
        gatewayId={customGatewayId}
        onBack={() => {
          resetCustomGatewayIdEvent();
          setGateway(null);
          nav(path.money.entry(), {replace: true});
        }}
        gateway={gateway}
        onSuccessCreateDep={onSuccessCreateDep}
        destinationList={destinationsData as SourceItem[]}
        destination={selectValue}
      />
    )

  return (
    <div className="relative h-full">
      {isLoadingCreateDep && (
        <SpinnerWithBlur size="xl" isShow={isLoadingCreateDep} />
      )}
      <Title level={2}>Select account/wallet to transfer the money</Title>
      <AccountSelect
        onSelect={setSelectValue}
        data={destinationsDataList as SourceItem[]}
        defaultValue={selectValue?.id as string}
      />
      <TransferFeatures
        data={[
          { icon: 'checkCircle', label: '0% Commission' },
          { icon: 'clockSolid', label: 'Processing time 15 to 30 minutes' },
        ]}
      />
      {banners &&
        banners.items.find(
          (banner) =>
            banner.name === 'finance.deposit.anniversary-contest.join',
        ) && (
          <DepositBanner
            onClose={() =>
              handleHideBanner('finance.deposit.anniversary-contest.join')
            }
            isDisabled={hideBannerLoading}
          />
        )}
      {getGatewaysData ? (
        <GatewaysGrid
          data={getGatewaysData}
          disabled={!selectValue}
          onChoose={onChooseGateway}
        />
      ) : null}

      <div className='mt-[56px] w-full text-center'>
        <Text withTranslate={false} level={2}>
          <Trans i18nKey={"Didn't find the right method? Write us an email"} values={{
            email: SITE_EMAIL,
          }}>
            Didn't find the right method? Write us an email <Link to={`mailto: ${SITE_EMAIL}`} className='!text-bronze.500'>email</Link>
          </Trans>
        </Text>
      </div>
    </div>
  )
}
