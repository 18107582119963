import { axios } from 'services/axios'

import { Strategy } from '../types'

export const GET_ALL_COPYTRADE_STRATEGIES_KEY = ['all-copytrade-strategies']
export const PER_PAGE = 9

type Filter = Record<string, string | number | string[] | boolean | null>

export const fetchStrategies = async (filters: Filter, pageParam = 0, isAuthorized: boolean) => {
  const res = await axios.get<{ items: Strategy[]; totalRecords: number }>(
    isAuthorized
      ? '/copytrade/v1/strategies'
      : '/copytrade/v1/strategies-for-unauthorized',
    {
      params: { ...filters, page: pageParam + 1, perPage: PER_PAGE },
      paramsSerializer: { indexes: true },
    },
  )

  return res.data
}

// эта функция используется только в copytrade и только в виртуальном списке, 
// поэтому реализация в src/pages/copytrade/tradersList/index.tsx
