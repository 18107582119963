import {
  OAuthAppleButton,
  OAuthFacebookButton,
  OAuthGoogleButton,
} from 'features/oath'
import { isFacebookBrowser } from 'features/oath/utils'
import { path } from 'libs/path'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Button, Icon2, Text, Title } from 'ui/atoms'

import { AuthLayout } from '../../layout'
import { useRegistrationEntryPage } from '../../register/entry'
import { LoginEmail } from '../email'

export const LoginEntry = () => {
  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = React.useState(0)

  const { authSocials } = useRegistrationEntryPage()

  return (
    <>
      <Helmet>
        <title>Headway login</title>
        <meta
          name="description"
          content="Headway Login Page: Sign into your Headway account and trade in the Forex market easily!"
        />
      </Helmet>

      <AuthLayout type="login">
        {currentStep === 0 ? (
          <>
            <LoginSocialsSection>
              {authSocials.map(({ name }, key) => {
                if (name === 'google' && !isFacebookBrowser())
                  return <OAuthGoogleButton key={key} />
                if (name === 'facebook')
                  return <OAuthFacebookButton key={key} />
                return null
              })}
              <OAuthAppleButton />
            </LoginSocialsSection>
            {Boolean(authSocials.length) && <Or level={1}>Or</Or>}
            <RegisterEmailButton
              name="navigate-to-second-step"
              size="large"
              prefix={<Icon2 name="envelope" size="medium" />}
              onClick={() => setCurrentStep(1)}
            >
              Sign in with Email
            </RegisterEmailButton>
            <SignIn>
              <SignInTitle level={4}>Don’t have an account?</SignInTitle>
              <SignInButton
                name={`navigate-to-${path.register.entry()}`}
                size="large"
                design="secondary"
                onClick={() => navigate(path.register.entry())}
              >
                Register
              </SignInButton>
            </SignIn>
          </>
        ) : (
          <LoginEmail onBack={() => setCurrentStep(0)} />
        )}
      </AuthLayout>
    </>
  )
}

const LoginSocialsSection = styled.div`
  & > button {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Or = styled(Text)`
  font-weight: 500;
  text-align: center;
  margin: 32px 0;
`

const RegisterEmailButton = styled(Button)`
  width: 100%;
`

const SignIn = styled.div`
  margin-top: 80px;
  text-align: center;

  @media (max-width: 540px) {
    margin-top: 40px;
  }
`

const SignInTitle = styled(Title)`
  margin-bottom: 24px;
`

const SignInButton = styled(Button)`
  width: 100%;
`
