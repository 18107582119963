import { createEvent, restore, sample } from 'effector'
import { fx } from 'libs/request'

import { Wallet } from './types'

export const walletsMounted = createEvent()

export const submitWallet = createEvent<string>()

export const getWallets = fx<void, { items: Wallet[] }>({
  method: 'GET',
  url: '/wallet/v1/wallets',
})

export const getCurrencies = fx<void, { items: string[] }>({
  method: 'GET',
  url: '/wallet/v1/list-available-currencies',
})

export const createWallet = fx<{ currency: string }, void>({
  method: 'POST',
  url: '/wallet/v1/wallet',
})

export const $currencies = restore<string[]>(
  getCurrencies.doneData.map((doneData) => doneData.body.items),
  [],
)

export const $wallets = restore<Wallet[]>(
  getWallets.doneData.map((doneData) => doneData.body.items),
  [],
)

export const $walletCreated = restore<boolean>(
  createWallet.doneData.map(() => true),
  false,
)

sample({
  clock: [walletsMounted, createWallet.doneData],
  fn: () => ({}),
  target: [getWallets, getCurrencies],
})

sample({
  clock: submitWallet,
  fn: (wallet) => ({ body: { currency: wallet } }),
  target: createWallet,
})

sample({
  clock: submitWallet,
  fn: () => false,
  target: $walletCreated,
})
