import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Contacts } from './molecules'
import { LandingPages, SocialMedia, WebsiteBanners } from './molecules/materialsTabs'
import * as Styled from './styled'

const tabs_full = [
  'Social media',
  'Website banners',
  'Brand materials',
  'Landing pages',
] as const

const tabs = ['Social media', 'Website banners', 'Landing pages'] as const

export const Materials = () => {
  const [t] = useTranslation()

  const [activeTab, setActiveTab] =
    useState<(typeof tabs_full)[number]>('Social media')

  return (
    <Styled.Background>
      <div className="max-[600px]:mx-[-12px] mb-[24px] overflow-x-scroll scrollbar-hide">
        <Styled.Tabs>
          {tabs.map((tab, index) => (
            <Styled.Tab
              key={index}
              isActive={activeTab === tab}
              onClick={() => setActiveTab(tab)}
            >
              {t(tab)}
            </Styled.Tab>
          ))}
        </Styled.Tabs>
      </div>
      {activeTab === 'Social media' && <SocialMedia />}
      {activeTab === 'Website banners' && <WebsiteBanners />}
      {activeTab === 'Brand materials' && 'Brand materials'}
      {activeTab === 'Landing pages' && <LandingPages />}
      <Contacts />
    </Styled.Background>
  )
}
