
import styled, { css } from 'styled-components/macro';

import { Checkbox as CheckboxUi } from 'ui/atoms';

export const Checkbox = styled(CheckboxUi)<{ color: string }>`
  border-color: ${(p) => p.color} !important;
  border-radius: 4px;
  background-color: ${(p) => {
    return p.checked ? p.color : `var(--color-gray-300)`;
  }} !important;
  user-select: none;
  cursor: pointer;

  ${p => {
    if (p.disabled) return css`
      border-color: var(--color-gray-500) !important;
      cursor: not-allowed;
    `;
  }}
`;


export const CheckboxLineWrapper = styled.div<{ isCheked: boolean; isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 7px 20px;
  gap: 8px;
  border-radius: 100px;
  cursor: pointer;
  background-color: ${p => p.isCheked ? 'var(--color-sunray-100)' : 'var(--color-gray-300)' };

  ${ p => {
    if (p.isDisabled) {
      return css`
        cursor: not-allowed;
      `;
    }
  }}

  @media (max-width: 900px) {
    height: 26px;
    padding: 4px 8px;
    gap: 4px;
  }
`;