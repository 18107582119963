import React, { useMemo } from 'react';
import { RegisterOptions, useFormContext, useController } from 'react-hook-form';

import { DepositFormListItem } from 'services/payments/get/DepositFormTypes';
import { SelectWithSearch } from 'ui/atoms/select/SelectWithSearch';

type Props = {
  item: DepositFormListItem;
}


export const SelectDynamic: React.FC<Props> = ({ item }) => {
  const { formState: { errors }, setValue } = useFormContext();

  const defautOptionList = useMemo(() => {
    const arr = item.params?.FIELD_TYPE_DROP_DOWN_LIST?.choices || [];

    return arr.map(item => ({
      value: item.key,
      label: item.value
    }));
  }, []);
  
  const registerFields = useMemo(() => {
    const register: RegisterOptions = {

    };
      
    // TODO на беке и на фронте будут доработки по валидации
    item.validators.some(validItem => {
      if (validItem.type === 'VALIDATOR_TYPE_REQUIRED') {
        register.required = { 
          value: true,
          message: validItem.validationErrorMessage || '',
        }
      }
    });

    return register;
  }, [item]);

  const { field: { ref, ...field } } = useController({
    name: item.name, 
    rules: registerFields,
  });

  return (
    <>
      <SelectWithSearch
        defaultValue=''
        {...field}
        inputRef={ref}
        onSelect={(selected: string) => {
          setValue(item.name, selected);
        }}
        isHaveSearch={ defautOptionList?.length > 10 }
        // isHaveSearch={ false }
        options={defautOptionList}
        autoComplete='off'
        disabled={false}
        className='lg:!w-[440px] w-full'
        errorMessage={ errors?.[item.name]?.message as string }
        placeholder={item.title || ''}
      />
    </>
  )
}
