import { createEvent, createStore } from 'effector';
import { ProcessingProvider } from 'services/money/get/types';


export type ResultStatuses = 'pending' | 'error' | 'success' 
| 'declined' | 'cancelled' | 'rejected'
| 'authorized' | 'initialized' | 'requested';

export interface ResultWithdrawalStore {
  amount: string;
  currency: string;
  id: string;
  status: ResultStatuses;
  provider: ProcessingProvider;
  title?: string;
  subTitle?: string;
  buttonText?: string;
  buttonLink?: string;
  isLinkOpenNewTab?: boolean;
}

export const resetResultPageEvent = createEvent();
export const setResultPageEvent = createEvent<ResultWithdrawalStore>();
export const $resultWithdrawalPage = createStore<ResultWithdrawalStore | null>(null).reset(resetResultPageEvent);

$resultWithdrawalPage.on(setResultPageEvent, (_, payload) => payload);