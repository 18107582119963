import { useStore } from 'effector-react'
import {
  getUserAfterLoginByEmail,
  getUserAfterLoginBySocial,
  setUserEmail,
} from 'features/user/model'
import * as React from 'react'

import { $error, loginByEmail, mounted } from './model'

export const useLoginByEmailPage = () => {
  React.useEffect(() => {
    mounted()
  }, [])

  const error = useStore($error)
  const loginLoading = useStore(loginByEmail.pending)
  const afterLoginByEmailLoading = useStore(getUserAfterLoginByEmail.pending)
  const afterLoginBySocialLoading = useStore(getUserAfterLoginBySocial.pending)

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(e.target.value)
  }

  return {
    form: {
      error,
      loading:
        loginLoading || afterLoginByEmailLoading || afterLoginBySocialLoading,
    },
    handleEmailChange,
  }
}
