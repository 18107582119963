import { path } from 'libs/path'
import { ReactElement } from 'react'
import { Button, Icon2 } from 'ui/atoms'

import * as Styled from './styled'

export const SignupTooltip = ({
  children,
  enabled = true,
}: {
  children: ReactElement
  enabled?: boolean
}) => {
  return (
    <Styled.Tippy
      content={
        <Styled.ContentWrapper>
          Sign up on Headway to start copying
          <Button
            name="sign-up"
            to={path.register.entry()}
            size="small"
            prefix={<Icon2 name="arrowLeftOnRectangle" size="small" />}
          >
            Sign up
          </Button>
        </Styled.ContentWrapper>
      }
      placement="bottom"
      disabled={!enabled}
      interactive
      maxWidth={176}
    >
      {children}
    </Styled.Tippy>
  )
}
