import styled, { css } from 'styled-components/macro'
import { Button, Title } from 'ui/atoms'

import chevron from './images/chevron-icon.svg'

export const ContentContainer = styled.div<{ isFullScreen: boolean }>`
  display: grid;
  gap: 20px;
  grid-template-columns: ${({ isFullScreen }) =>
    isFullScreen ? '1fr' : '680px'};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

export const InfoWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 8px 0;
  max-width: 800px;
  display: grid;
  justify-items: center;
  text-align: center;

  @media (max-width: 599px) {
    padding: 0;
  }
`

export const ChevronButton = styled.button<{ isOpened: boolean }>`
  position: absolute;
  top: 24px;
  right: 20px;
  z-index: 2;
  width: 24px;
  height: 24px;
  transition: transform var(--transition);
  background-image: url(${chevron});

  @media (max-width: 599px) {
    right: 12px;
  }

  ${({ isOpened }) =>
    !isOpened &&
    css`
      transform: rotate(180deg);
    `}

  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      left: 24px;
      right: auto;

      @media (max-width: 599px) {
        left: 12px;
        right: auto;
      }
    `}
`

export const VideoWrapper = styled.div`
  margin-top: 8px;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  @media (max-width: 599px) {
    margin-top: 16px;
    padding-bottom: 130%;
  }
`

export const VideoHint = styled.div`
  margin-top: 28px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  text-align: center;

  @media (max-width: 599px) {
    margin-top: 16px;
  }
`

export const SmallTitle = styled.div`
  cursor: pointer;
  display: inline-block;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  color: var(--color-bronze-500);
  font-weight: 500;

  @media (max-width: 599px) {
    display: block;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const InfoTitle = styled(Title)`
  margin-bottom: 4px;
  padding: 5px 0;

  @media (max-width: 599px) {
    margin-bottom: 10px;
    padding: 0;
    max-width: 80%;
  }
`

export const InfoText = styled.div`
  margin-bottom: 20px;
  padding: 3px 0;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);

  @media (max-width: 599px) {
    margin-bottom: 16px;
    padding: 0;
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);

    br {
      display: none;
    }
  }
`

export const Terms = styled.a`
  font-weight: 500;
  color: var(--color-bronze-500);
`

export const InfoButton = styled(Button)`
  min-width: 173px;

  @media (max-width: 599px) {
    min-width: 146px;
  }
`
