import styled, { css } from 'styled-components/macro'

export const ImageWrapper = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 50%;
  }
`

export const TextWrapper = styled.div`
  margin-inline-start: 8px;
`

export const Name = styled.div`
  margin-bottom: 4px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-small);
  font-weight: 500;
  word-break: break-word;

  &:last-child {
    margin-bottom: 0;
  }
`

export const OuterContainer = styled.div<{
  size: 'small' | 'medium' | 'xMedium' | 'large'
}>`
  display: flex;
  align-items: center;

  ${({ size }) =>
    size === 'large' &&
    css`
      ${ImageWrapper} {
        width: 64px;
        height: 64px;
      }

      ${TextWrapper} {
        margin-inline-start: 16px;
      }

      ${Name} {
        font-size: 20px;
        line-height: 25px;
      }
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      ${ImageWrapper} {
        width: 48px;
        height: 48px;
      }

      ${TextWrapper} {
        margin-inline-start: 12px;
      }

      ${Name} {
        font-size: var(--body-font-size-regular);
        line-height: var(--body-line-height-regular);
      }
    `}

  ${({ size }) =>
    size === 'xMedium' &&
    css`
      ${ImageWrapper} {
        width: 54px;
        height: 54px;
      }

      ${TextWrapper} {
        margin-inline-start: 12px;
      }

      ${Name} {
        font-size: var(--body-font-size-regular);
        line-height: var(--body-line-height-regular);
      }
    `}
`
