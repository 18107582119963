import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { Icon } from '../icon'

type Props = {
  onChange?: (value: boolean) => void
  name?: string
  children?: React.ReactNode
  className?: string
  design?: 'blue' | 'green' | 'brand' | 'red'
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
  checked?: boolean
  indeterminate?: boolean
}

export const Checkbox = ({
  name = 'default',
  className,
  onChange,
  checked = false,
  size = 'medium',
  design = 'blue',
  disabled,
  children,
  indeterminate,
}: // defaultChecked,
Props) => {
  const [t] = useTranslation()
  // const [isChecked, setChecked] = React.useState(checked)

  return (
    <CheckboxContainer>
      <CheckboxWrap data-size={size}>
        <CheckboxIcon
          name={indeterminate ? 'minus' : 'checkBold'}
          size={size}
        />
        <CheckboxField
          type="checkbox"
          id={`${name}-checkbox`}
          className={className}
          checked={checked}
          disabled={disabled}
          data-size={size}
          data-design={design}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.checked)
            }
          }}
        />
      </CheckboxWrap>
      {children && (
        <CheckboxLabel htmlFor={`${name}`}>{t(`${children}`)}</CheckboxLabel>
      )}
    </CheckboxContainer>
  )
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`

const CheckboxWrap = styled.div`
  position: relative;
  display: flex;

  &[data-size='small'] {
    width: 12px;
    height: 12px;
  }

  &[data-size='medium'] {
    width: 16px;
    height: 16px;
  }

  &[data-size='large'] {
    width: 20px;
    height: 20px;
  }
`

const CheckboxLabel = styled.label`
  font-size: var(--text-size-body-small);
  line-height: var(--line-height-body-small);
  margin-inline-start: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
`

const CheckboxIcon = styled(Icon)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  &[data-size='small'] {
    min-width: 10px;
    width: 10px;
    height: 6px;
  }

  &[data-size='medium'] {
    min-width: 10px;
    width: 10px;
    height: 10px;
  }

  &[data-size='large'] {
    min-width: 14px;
    width: 14px;
    height: 14px;
  }
`

const CheckboxField = styled.input`
  background-color: var(--color-gray-100);
  border-radius: 2px;
  appearance: none;
  outline: none;
  border: 1px solid;
  border-color: var(--border-color);
  transition: var(--transition);
  cursor: pointer;

  &[data-design='blue'] {
    ${(p) =>
      p.checked &&
      `
        background-color: var(--color-azure-500);
        border-color: var(--color-azure-500);
      `}
  }

  &[data-design='green'] {
    ${(p) =>
      p.checked &&
      `
        background-color: var(--color-eucalyptus-500);
        border-color: var(--color-eucalyptus-500);
      `}
  }

  &[data-design='red'] {
    ${(p) =>
      p.checked &&
      `
        background-color: var(--color-opal-600);
        border-color: var(--color-opal-600);
      `}

    &:disabled {
      background-color: var(--color-opal-600);
      border-color: var(--color-opal-600);
    }
  }

  &[data-design='brand'] {
    ${(p) =>
      p.checked &&
      `
        background-color: var(--color-sunray-600);
        border-color: var(--color-sunray-600);
      `}
  }

  &:disabled {
    background-color: var(--color-gray-300);
    border-color: var(--color-gray-300);
  }

  &[data-size='small'] {
    width: 12px;
    height: 12px;
  }

  &[data-size='medium'] {
    width: 16px;
    height: 16px;
  }

  &[data-size='large'] {
    width: 20px;
    height: 20px;
  }
`
