import { path } from 'libs/path'
import { Trans, useTranslation } from 'react-i18next'
import { Checkbox } from 'ui/atoms'

import * as Styled from './styled'

export const AgreementCheckbox = ({
  checked,
  onChange,
  className,
}: {
  checked: boolean
  onChange: (value: boolean) => void
  className?: string
}) => {
  const [t, i18n] = useTranslation()

  return (
    <Styled.Wrapper className={className}>
      <Checkbox
        checked={checked}
        onChange={(value) => onChange(value)}
        design="brand"
      />
      <span>
        <Trans i18nKey="I agree to the terms and conditions." t={t}>
          I agree to the{' '}
          <a
            href={path.copytrade.terms(i18n.language)}
            target="_blank"
            className="font-medium text-bronze.500"
            rel="noreferrer"
          >
            terms and conditions
          </a>
          .
        </Trans>
      </span>
    </Styled.Wrapper>
  )
}
