import styled, {css} from 'styled-components/macro'

import close from '../../images/close-icon.svg'

export const ContentContainer = styled.div`
  position: fixed;
  bottom: 16px;
  z-index: 2;
  padding: 20px;
  max-width: 1140px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  box-shadow: 0px 2px 24px -1px #2621191a, 0px 4px 6px -1px #2621191a;

  @media (max-width: 1541px) {
    max-width: 1074px;
  }

  @media (max-width: 1440px) {
    right: 96px;
    max-width: calc(100% - 96px - 280px);
  }

  @media (max-width: 1050px) {
    left: 20px;
    max-width: calc(100% - 116px);
  }

  @media (max-width: 750px) {
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 88px;
    max-width: 100%;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
  }

  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      @media (max-width: 1439px) {
        right: auto;
        left: 20px;
      }

      @media (max-width: 1050px) {
        right: 20px;
      }
    `}
`

export const ButtonsWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 16px;

  @media (max-width: 750px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

export const TagsWrapper = styled.div`
  max-height: 33vh;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Tag = styled.div`
  cursor: pointer;
  padding: 8px 36px 8px 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: var(--body-font-size-medium);
  line-height: 1.6rem;
  color: var(--color-gray-600);
  font-weight: 500;
  border-radius: 4px;
  background-color: var(--color-gray-300);
  background-image: url(${close});
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: right 6px center;
`
