import styled, { css } from 'styled-components/macro'
import { Text as UiText, Title as UiTitle } from 'ui/atoms'

import bannerCroppedMobile from '../../images/main-banner-cropped-mobile.jpg'
import bannerCropped from '../../images/main-banner-cropped.jpg'
import bannerMobile from '../../images/main-banner-full-mobile.jpg'
import banner from '../../images/main-banner-full.jpg'

export const Wrapper = styled.div<{ isFull: boolean }>`
  margin-top: -24px;
  margin-inline-start: -20px;
  margin-inline-end: -20px;
  padding: 24px 60px;
  min-height: 248px;
  display: grid;
  grid-template-columns: 0.6fr;
  align-content: center;
  background-color: #ffcb3e;
  border-radius: 8px;
  background-image: url(${bannerCropped});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;

  @media (max-width: 710px) {
    padding: 32px 24px;
    min-height: 210px;
    grid-template-columns: 1fr;
    background-image: url(${bannerCroppedMobile});
    border-radius: 0;
  }

  @media (max-width: 599px) {
    margin-inline-start: -12px;
    margin-inline-end: -12px;
  }

  ${({ isFull }) =>
    isFull &&
    css`
      min-height: 360px;
      background-image: url(${banner});

      @media (max-width: 710px) {
        padding-top: 40px;
        min-height: 400px;
        align-content: start;
        background-image: url(${bannerMobile});
      }
    `}
`

export const Timeframe = styled(UiTitle)`
  color: var(--color-gray-600);
`

export const Title = styled.div`
  margin-top: 12px;
  font-size: 53px;
  line-height: 1.15;
  font-weight: 500;

  @media (max-width: 710px) {
    margin-top: 8px;
    font-size: 32px;
    line-height: 40px;
    max-width: 60%;
  }
`

export const Text = styled(UiText)`
  margin-top: 24px;

  @media (max-width: 710px) {
    margin-top: 16px;
  }
`
