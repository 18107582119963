import { createStore, createEvent } from 'effector'
import { ClientError, fx, mapResponseError } from 'libs'

import {
  ChangePasswordProps,
  CheckResetPasswordCodeProps,
  ResetPasswordProps,
  ResetPasswordResponse,
  Session,
} from './types'

// https://gitlab.com/headliner/dev/go/services/auth/-/blob/main/api/swagger/user.auth.public_api.grpc.swagger.json

export const getCheckResetPasswordCode = fx<
  CheckResetPasswordCodeProps,
  Session
>({
  method: 'GET',
  url: '/auth/v1/check-reset-password-code',
})

export const postChangePassword = fx<ChangePasswordProps, void>({
  url: '/auth/v1/change-password',
  method: 'POST',
})

// depricated (move to getCheckResetPasswordCode)

export const postResetPassword = fx<ResetPasswordProps, ResetPasswordResponse>({
  method: 'POST',
  url: '/auth/v1/reset-password',
})

// handle errors

export const resetError = createEvent()

export const $error = createStore<ClientError<ResetPasswordProps> | null>(null)

$error
  .on(postResetPassword.failData, (_, failData) =>
    mapResponseError<ResetPasswordProps>(failData.body),
  )
  .reset(resetError)