import { MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GiftList as GiftListType } from 'services/giftshop/types'
import { Tabs, Text, Title } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'
import { useWindowSize } from 'usehooks-ts'

import { ChangeTargetModal } from '../molecules/changeTargetModal'
import { GiftCard } from '../molecules/giftCard'
import * as Styled from './styled'

export const GiftList = ({ data }: { data: GiftListType }) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobileMode = width < 571

  const [activeTab, setActiveTab] = useState<string>()
  const [activeGiftId, setActiveGiftId] = useState<number>()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleGiftClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()

    setIsModalVisible(true)
  }

  useEffect(() => {
    if (activeTab) {
      document.querySelector(`#${activeTab}`)?.scrollIntoView()
      setActiveTab(undefined)
    }
  }, [activeTab])

  return (
    <>
      <Styled.Container>
        <Styled.TabsWrapper>
          <Tabs
            items={data.items.map((category) => ({
              label: category.title,
              value: category.name,
            }))}
            disabledActiveTab
            onChange={setActiveTab}
          />
        </Styled.TabsWrapper>

        <Styled.CategoriesWrapper>
          {data.items.map((category, index) => (
            <div key={index} id={category.name} className="scroll-mt-6">
              <Title level={isMobileMode ? 3 : 2} withTranslate={false}>{category.title}</Title>
              <Styled.GiftsWrapper>
                {category.items.map((gift) => (
                  <GiftCard
                    key={gift.id}
                    {...gift}
                    onClick={!data.isRegistered ? handleGiftClick : undefined}
                    onTarget={
                      data.isExistTargetGift
                        ? () => setActiveGiftId(gift.id)
                        : undefined
                    }
                  />
                ))}
              </Styled.GiftsWrapper>
            </div>
          ))}
        </Styled.CategoriesWrapper>

        <ModalDialog isOpen={isModalVisible} onOk={() => setIsModalVisible(false)}>
          <Styled.ModalContent>
            <Title>Join Giftshop!</Title>
            <Text>
              Take part in the promo to view the details of the available gifts.
            </Text>
          </Styled.ModalContent>
        </ModalDialog>
      </Styled.Container>

      <ChangeTargetModal
        giftId={activeGiftId}
        isOpen={!!activeGiftId}
        onClose={() => setActiveGiftId(undefined)}
      />
    </>
  )
}
