import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { GET_GIFT_DETAILS_KEY } from '../get/useGetGiftDetails'
import { Gift } from '../types'

const targetGift = async (id: number) => {
  await axios.patch('/gift-shop/v1/target-gift', {
    giftID: id,
  })
}

export const useTargetGift = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: number) => targetGift(id),
    onSuccess() {
      queryClient.setQueryData<Gift>(
        GET_GIFT_DETAILS_KEY,
        (oldData) =>
          oldData && {
            ...oldData,
            isTarget: true,
          },
      )
      // todo: добавить изменение списка подарков
    },
  })
}
