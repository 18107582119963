import { useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';


export type FinancialOperationsResp = {
  items: FinancialOperationsItem[];
  totalRecords: number;
}

export type FinancialOperationsItem = {
  strategyName: string;
  operationDate: string;
  profit: string;
}

type QueryParams = {
  page: number;
  perPage: number;
  "filter.strategyID"?: number | string;
}

export const GET_FINANCIAL_OPERATIONS_KEY = 'GET_FINANCIAL_OPERATIONS_KEY';

const getFinancialOperations = async (strategyId: string, page: number) => {
  const params: QueryParams = {
    page,
    perPage: 10,
  }

  if (strategyId) params['filter.strategyID'] = strategyId;

  const res = await axios.get<FinancialOperationsResp>(`/copytrade/v1/trader/financial-operations`, {
    params,
  });

  return res.data;
}

interface Options {
  enabled?: boolean;
  strategyId: string;
  page: number;
}

export const useGetFinancialOperations = ({ strategyId, page, enabled }: Options) => {
  return useQuery({
    queryKey: [GET_FINANCIAL_OPERATIONS_KEY, strategyId, page],
    queryFn: () => getFinancialOperations(strategyId, page),
    retry: retryFn,
    staleTime: 2000,
    enabled,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })
}