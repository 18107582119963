import React from 'react'

import { Title } from 'ui/atoms';

import * as S from './styles'

interface Props extends React.PropsWithChildren {
  title: string;
}

export const InformationBlock: React.FC<Props> = ({ children, title }) => {
  return (
    <div>
    <Title level={3}>{ title }</Title>

     <S.InformationBlock>
        { children }
     </S.InformationBlock>
    </div>
  )
}
