import { useTranslation } from 'react-i18next'
import { RiskLevel } from 'services/copytrade/types'

import * as Styled from './styled'
import { colorSchemeMap, riskLabelMap } from './utils'

export const RiskLabel = ({
  risk,
  size,
  isShortLabel,
}: {
  risk: RiskLevel
  size: 'medium' | 'large' | 'small'
  isShortLabel?: boolean
}) => {
  const [t] = useTranslation()

  return (
    <Styled.Wrapper
      style={{
        color: colorSchemeMap[risk]?.fontColor,
        backgroundColor: colorSchemeMap[risk]?.backgroundColor,
      }}
      size={size}
    >
      {t(`${riskLabelMap[risk] || 'Unknown'}${isShortLabel ? '' : ' risk'}`)}
    </Styled.Wrapper>
  )
}
