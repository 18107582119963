import UiTippy from '@tippyjs/react'
import styled from 'styled-components/macro'

export const ContentWrapper = styled.div`
  display: grid;
  gap: 16px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  background-color: var(--color-gray-100);
`

export const Tippy = styled(UiTippy)`
  background-color: var(--color-gray-100);

  .tippy-content {
    padding: 16px 20px;
  }

  .tippy-arrow {
    color: var(--color-gray-100);
  }
`