import React from 'react'
import { Link } from 'react-router-dom';

import { Text } from 'ui/atoms';

import * as S from './styles';
import { path } from 'libs/path';



export const SecuritySettings = () => {
  return (
    <>
      <S.InformationBlockItem>
        <Text>Password</Text>
        <Link to={path.dashboard.profile.changePassword()}>
          <Text className='cursor-pointer !text-bronze.500'>Change password</Text>
        </Link>
      </S.InformationBlockItem>

      <S.InformationBlockItem>
        <Text>Login history</Text>
        <Link to={path.dashboard.profile.loginHistory()}>
          <Text className='cursor-pointer !text-bronze.500'>View history</Text>
        </Link>
      </S.InformationBlockItem>
    </>
  )
}
