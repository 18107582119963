import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export type StrategyFilterResp = {
  items: StrategyFilterItem[]
}

export type StrategyFilterItem = {
  strategyID: string
  title: string
}

type StrategyFilterKind = 'KIND_FINANCIAL_OPERATIONS' | 'KIND_STATISTICS'

export const GET_TRADER_STRATEGIES_FOR_FILTER =
  'copytrade-trader-strategies-for-filter'

const getTraderStrategiesForFilter = async (kind: StrategyFilterKind) => {
  const res = await axios.get<StrategyFilterResp>(
    `/copytrade/v1/trader/strategies-for-filter`,
    {
      params: { kind },
    },
  )

  return res.data
}

interface Options<T> {
  enabled?: boolean
  kind: StrategyFilterKind
  select?: (data: StrategyFilterResp) => T;
}

export const useGetTraderStrategiesForFilter = <T=StrategyFilterResp>({
  kind,
  enabled,
  select,
}: Options<T>) => {
  return useQuery({
    queryKey: [GET_TRADER_STRATEGIES_FOR_FILTER, kind],
    queryFn: () => getTraderStrategiesForFilter(kind),
    staleTime: 100,
    select,
    enabled,
  })
}
