import React, { ReactElement } from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components/macro';

import 'tippy.js/dist/tippy.css';

type Props = {
  children: ReactElement;
  content: React.ReactNode;
  showOnCreate?: boolean;
  disabled?: boolean;
  trigger?: 'mouseenter' | 'focus' | 'click' | 'manual';
  visible?: boolean;
  placement?: 'auto' | 'left' | 'right' | 'top';
};

export const StyledTippy = styled(Tippy)`
  background-color: var(--color-gray-300);
  /* padding: 4px 8px; */
  border-radius: 3px;
  .tippy-arrow {
    color: var(--color-gray-300);
    background-color: var(--color-gray-300);
  }
`;

export const Tooltip: React.FC<Props> = ({ children, content, showOnCreate, disabled, trigger, visible, placement = 'auto' }) => {
  return (
    <StyledTippy
      showOnCreate={ showOnCreate }
      disabled={ disabled }
      content={ content }
      trigger={trigger}
      visible={visible}
      placement={placement}
    >
      { children }
    </StyledTippy>
  )
}
