import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useUnit } from 'effector-react';
import { t } from 'i18next';

import { useGetSourceList } from 'services/payments/get/useGetSourceList';
import { CreateDepositResponse } from 'services/payments/mutations/useCreateDeposit';
import { SourceItem } from 'services/payments/get/types';

import { 
  $otpForm, $withdrawalSbm, accountChooseReset, pageDataResetEvent,
  resetCustomGatewayIdEvent, resetOtpFormEvent, resetWithdrawalSbm, setCustomGatewayIdEvent
} from './model';
import { WithdrawalForm } from './CutomFormWithdrawal/WithdrawalForm';

import { PostFrame } from '../../depositChoice/PostFrame';
import { onResetAmountEvent, onResetCalcAmountEvent, setDestenationEvent } from './CutomFormWithdrawal/model';
import { WithdrawalOtpForm } from './WithdrawalOtpForm';

import { TransactionStatus } from '../../molecules/transactionStatus/TransactionStatus';
import { ProcessingProvider } from 'services/money/get/types';
import { $customWithdrawalStore } from '../model/withdrawalStore';

const errorTransactionText = {
  title: t('Payment failed'),
  subTitle: 'The transaction has failed. Please, try again or contact our Customer Care for more details.'
}

const pendingTransactionText = {
  title: t('Payment is in process'),
  subTitle: t('We are processing your transaction now – it may take some time. See the current status of your transaction in the Transaction History (My Accounts section).')
}

export const CustomWithdrawalPage = () => {
  const { data: destinationsData } = useGetSourceList('withdrawal');
  const customWithdrawalStore = useUnit($customWithdrawalStore);
  
  const isOtp = useUnit($otpForm);

  const methods = useForm({
    defaultValues: {},
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const navigate = useNavigate();

  const customGatewayId = customWithdrawalStore?.gatewayId as string;
  const gatewayProvider = customWithdrawalStore?.provider as ProcessingProvider;
  
  const isInitalValueAcc = useRef(true);

  const withdrawalSbm = useUnit($withdrawalSbm)

  const [selectValue, setSelectValue] = useState<SourceItem>();
  const [customFrame, setCustomFrame] = useState<CreateDepositResponse | undefined>(undefined);

  const onSuccessCreateDep = (resp: CreateDepositResponse) => {
    if (!resp) return;
    
    setCustomFrame(resp);
  }

  const goBack = () => {
    navigate(-1)
  };

  useEffect(() => {
    return () => {
      accountChooseReset();
      resetCustomGatewayIdEvent();
      resetOtpFormEvent();
      pageDataResetEvent();
    }
  }, []);

  // сетинг дефолтного значения
  useEffect(() => {
    if (!destinationsData) return;
    if (!isInitalValueAcc.current) return;

    const chosenDestenation = customWithdrawalStore?.chosenDestenation as string;
    
    const finded = destinationsData.find(item => item.id === chosenDestenation);
    if (finded) {
      setDestenationEvent(finded);
      setCustomGatewayIdEvent(customGatewayId);
      isInitalValueAcc.current = true;
      setSelectValue(finded);
    }
  
    return;
  }, [destinationsData]);

  useEffect(() => {
    return () => {
      setDestenationEvent(null);
      onResetCalcAmountEvent();
      onResetAmountEvent();
      resetWithdrawalSbm();
      resetOtpFormEvent();
    }
  }, []);

  if (withdrawalSbm) {
    return (
      <>
        <TransactionStatus
          { ...withdrawalSbm }
          { ...(withdrawalSbm.status === 'pending' ? pendingTransactionText : errorTransactionText) }
        />
      </>
    )
  }
  
  if (isOtp) {
    return (
      <>
        <WithdrawalOtpForm
          triggerFn={methods.trigger}
          getValues={ methods.getValues }
          gatewayProvider={gatewayProvider}
        /> 
      </>
    )
  }
  
  return (
    <>
      <FormProvider {...methods}>
        <WithdrawalForm
          gatewayId={customGatewayId}
          onBack={goBack}
          gatewayProvider={ gatewayProvider }
          onSuccessCreateDep={ onSuccessCreateDep }
          destinationList={ destinationsData as SourceItem[] }
          destination={ selectValue }
        />

        { customFrame && 
          <PostFrame
            url={customFrame.URL}
            transactionID={customFrame.transactionID}
            payload={customFrame?.payload!}
          />
        }
      </FormProvider>
    </>
  )
}
