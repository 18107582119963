import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);

  @media (max-width: 599px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const Title = styled.div`
  margin-bottom: 8px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-small);
  font-weight: 500;
  color: var(--color-gray-600);

  @media (max-width: 599px) {
    font-size: var(--body-font-size-small);
  }
`

export const Text = styled.div`
  margin-bottom: 8px;
  overflow-wrap: anywhere;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ExpandButton = styled.button`
  display: inline;
  font: inherit;
  font-weight: 500;
  color: var(--color-bronze-500);
  transition: color var(--transition);

  &:hover {
    color: var(--color-bronze-600);
  }
`