export function generatePass() {
  const passwordLength = Math.floor(Math.random() * (32 - 8 + 1)) + 8
  const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz'
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numbers = '0123456789'
  const specialCharacters = '!@$%_#()'
  let password = ''

  // Добавляем минимум 1 букву в пароль
  password += lowercaseLetters.charAt(
    Math.floor(Math.random() * lowercaseLetters.length),
  )

  // Добавляем минимум 1 заглавную букву в пароль
  password += uppercaseLetters.charAt(
    Math.floor(Math.random() * uppercaseLetters.length),
  )

  // Добавляем минимум 1 цифру в пароль
  password += numbers.charAt(Math.floor(Math.random() * numbers.length))

  // Генерируем оставшуюся часть пароля
  for (let i = 0; i < passwordLength - 3; i++) {
    const characters =
      lowercaseLetters + uppercaseLetters + numbers + specialCharacters
    password += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return password
}
