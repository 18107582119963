import { QueryCache, QueryClient } from '@tanstack/react-query'
import { useStore } from 'effector-react'
import { RouterHistory } from 'features/router'
import { logout } from 'features/session'
import { useGetSidebar } from 'features/sidebar/useGetSidebar'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useIdleTimer } from 'react-idle-timer'
import { Outlet } from 'react-router-dom'
import { ZendeskAPI } from 'react-zendesk'
import styled from 'styled-components/macro'
import { Alerts, Breadcrumbs, LogoutModal, Menu, MenuItem } from 'ui/molecules'
import { Activities, GenericHeader } from 'ui/organisms'

import {
  $isGlobalError,
  $isLogoutModalVisible,
  setGlobalError,
  setIsLogoutModalVisible,
} from './model'

type Props = {
  withActivities?: boolean
  withCrumbs?: boolean
  contentWrapperClasses?: string
}

export const GenericTemplate = ({
  withActivities,
  withCrumbs,
  contentWrapperClasses = '',
}: Props) => {
  const [t] = useTranslation()

  const isGlobalError = useStore($isGlobalError)
  const isLogoutModalVisible = useStore($isLogoutModalVisible)

  const menuItems = useGetSidebar()

  const { reset: resetIdleTimer } = useIdleTimer({
    onIdle: () => setIsLogoutModalVisible(true),
    // 15 min
    timeout: 900_000,
    throttle: 500,
  })

  const handleLogout = () => {
    setIsLogoutModalVisible(false)

    const queryCache = new QueryCache()
    const queryClient = new QueryClient()

    queryClient.clear()
    queryCache.clear()
    logout()
  }

  useEffect(() => {
    resetIdleTimer()
  }, [])

  return (
    <>
      <RouterHistory />
      <GenericLayout>
        <GenericHeader />
        <GenericContainer>
          <GenericAside>
            <Menu items={menuItems} />
            <LogoutItem onClick={handleLogout}>
              <LogoutLink
                icon="arrowRightOnRectangle"
                path={''}
                label="Log out"
              />
            </LogoutItem>
          </GenericAside>
          <GenericSection>
            {isGlobalError && (
              <Alerts
                type="warning"
                title="Internal server error"
                onClose={() => setGlobalError(false)}
              >
                <Trans
                  i18nKey={
                    'We are fixing it already. Please try again later or contact support.'
                  }
                >
                  We are fixing it already. Please try again later or{' '}
                  <span
                    onClick={() => ZendeskAPI('messenger', 'open')}
                    className="!cursor-pointer !text-orange-500"
                  >
                    contact support
                  </span>
                  .
                </Trans>
              </Alerts>
            )}
            {withActivities && <Activities />}
            {withCrumbs && <Breadcrumbs />}
            <GenericSectionContent className={contentWrapperClasses}>
              <Outlet />
              <DisclaimerWrapper>
                <DisclaimerTitle>{t('Risk disclaimer')}</DisclaimerTitle>
                <div>
                  {t(
                    'Trading financial instruments with leverage carries a high risk to your capital.',
                  )}
                </div>
                <div>
                  {t(
                    'Headway Inc. does not offer its services to residents of countries that were, are, or will be blacklisted by the FATF.',
                  )}
                </div>
                <div>
                  {t(
                    'This website is not directed to the residents of countries where such financial services may violate the laws or regulations of the land. The information on this website is not aimed at solicitation or invitation.',
                  )}
                </div>
                <div>
                  {t(
                    'Anyone who accesses this website must therefore follow the laws and regulations applicable in their country of residence.',
                  )}
                </div>
              </DisclaimerWrapper>
            </GenericSectionContent>
          </GenericSection>
        </GenericContainer>
      </GenericLayout>

      {isLogoutModalVisible && (
        <LogoutModal
          onLogout={handleLogout}
          onReset={resetIdleTimer}
          onClose={() => setIsLogoutModalVisible(false)}
        />
      )}
    </>
  )
}

const GenericLayout = styled.div`
  background-color: var(--color-gray-300);
  min-height: 100vh;
`

const GenericContainer = styled.main`
  padding: 20px 20px 24px;
  display: grid;
  grid-template-columns: 240px minmax(0, 1fr);
  gap: 20px;
  min-height: calc(100vh - 56px);
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: 1050px) {
    grid-template-columns: minmax(0, 1fr);
  }

  @media (max-width: 599px) {
    padding: 16px 8px;
  }
`

const GenericSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* width: 100%; */

  @media (max-width: 1050px) {
    width: 100%;
  }
`

const GenericSectionContent = styled.div``

const GenericAside = styled.aside`
  display: flex;
  /* width: 240px; */
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 16px 16px;
  box-shadow: 0 4px 6px -1px rgba(38, 33, 25, 0.1),
    0px 2px 4px -1px rgba(38, 33, 25, 0.06);
  border-radius: 8px;
  background-color: var(--color-gray-100);
  height: calc(100vh - 68px - 40px);

  @media (max-width: 1050px) {
    display: none;
  }
`

export const LogoutLink = styled(MenuItem)`
  pointer-events: none;
`

export const LogoutItem = styled.div`
  cursor: pointer;
`

export const DisclaimerWrapper = styled.div`
  padding: 52px 0 20px;
  display: grid;
  color: var(--color-gray-600);
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);

  @media (max-width: 770px) {
    padding: 56px 12px 16px;
    gap: 1em;
  }
`

export const DisclaimerTitle = styled.div`
  margin-bottom: 8px;
  color: var(--color-gray-700);
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  text-transform: uppercase;
  font-weight: 500;
`
