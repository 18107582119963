import React from 'react'
import { ProfileContent } from 'services/profile/getProfile'
import { Block } from 'ui/atoms'

import { AvatarBlock } from './avatarBlock/AvatarBlock'
import {
  AccountSettings,
  InformationBlock,
  SecuritySettings,
} from './informationBlock'
import { ChangeUserDataModal } from './modals/ChangeUserDataModal'
import { PersonalFields } from './personalInfo/PersonalFields'
import { ProfileChangeRequest } from './personalInfo/ProfileChangeRequest'
import * as S from './profilePageStyles'
import { Social } from './socials/Social'

interface Props {
  content?: ProfileContent
  availableLanguages?: string[]
  isLoading: boolean
  error?: string
}

export const ProfilePage: React.FC<Props> = ({
  isLoading,
  content,
  availableLanguages,
}) => {
  if (isLoading || !content)
    return (
      <S.Container>
        <Block className="min-h-[500px]"></Block>
      </S.Container>
    )

  return (
    <S.Container>
      <Block className="flex gap-[2.4rem] flex-col">
        <AvatarBlock
          verification={content?.verification}
          firstName={content.firstName}
          middleName={content.middleName}
          lastName={content.lastName}
          profileStatus={content.profileChangeRequest?.status}
          updatingDocumentRequest={
            content.updatingDocumentRequest?.status === 'IN_PROGRESS'
          }
        />

        <div>
          <InformationBlock title="Information">
            <PersonalFields
              phone={content.phone}
              verificationStatus={content.verification.status}
              email={content.email}
              address={content.address}
              country={content.country}
              profileStatus={content.profileChangeRequest?.status}
              updatingDocumentRequest={
                content.updatingDocumentRequest?.status === 'IN_PROGRESS'
              }
            />
          </InformationBlock>

          {content.profileChangeRequest &&
            !content.profileChangeRequest.isClosed && (
              <ProfileChangeRequest
                status={content.profileChangeRequest.status}
              />
            )}
        </div>

        <InformationBlock title="Social media login">
          <Social />
        </InformationBlock>

        <InformationBlock title="Security settings">
          <SecuritySettings />
        </InformationBlock>

        <InformationBlock title="Account settings">
          <AccountSettings
            language={
              availableLanguages?.indexOf(content.language) !== -1
                ? content.language
                : content.availableLanguage
            }
            allLanguages={availableLanguages}
          />
        </InformationBlock>
      </Block>

      <ChangeUserDataModal />
    </S.Container>
  )
}
