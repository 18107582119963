import styled from 'styled-components/macro';

export const progressWrap = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: var(--color-gray-300);
  height: 2rem;
`

interface ProgressBarProps {
  width: number;
}
export const ProgressBar = styled.div<ProgressBarProps>`
  width: ${(props) => `${props.width}%`};
  background-color: var(--color-sunray-400);
  min-width: fit-content;
  border-radius: 3px;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 4px;
  text-wrap: nowrap;
`