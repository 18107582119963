import { useState } from "react";

import { useGetTraderStrategiesForFilter } from "services/copytrade";

import { Tabs } from 'ui/molecules';
import { FinancialHistoryTab } from './financialHistory';
import { StatisticsTab } from './statisticsTab';

export const TraderTables = () => {
  const [activeTab, setActiveTab] = useState('statistics');

  const { data, isLoading } = useGetTraderStrategiesForFilter({
    kind: 'KIND_FINANCIAL_OPERATIONS',
  });

  const isHaveStrategy = !isLoading && !!data?.items?.length;

  if (!isHaveStrategy) {
    return null;
  }
  
  return (
    <>
      <Tabs
        // defaultValue={activeTab}
        fullHeight={false}
        destroyUnactive
        items={[
          {
            label: 'Financial history',
            children: <FinancialHistoryTab />,
            onClick: () => setActiveTab('financialHistory'),
          },
          {
            label: 'Statistics',
            children: <StatisticsTab />,
            onClick: () => setActiveTab('statistics'),
          }
        ]}
      />
    </>
  )
}