import { saveAs } from 'file-saver'
import { useState } from 'react'

export const useDownloadFile = () => {
  const [isLoading, setIsLoading] = useState(false)

  const startDownload = async (url: string, fileName?: string) => {
    setIsLoading(true)

    try {
      const response = await fetch(url)
      const blob = await response.blob()
      saveAs(blob, fileName)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    startDownload,
  }
}
