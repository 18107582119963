import { createEvent, restore, sample } from 'effector';

import { fx } from 'libs';
import { Verification } from './types';



export const mounted = createEvent();
export const unmounted = createEvent();

export const getVerification = fx<void, Verification>({
  method: 'GET',
  url: `/user/v1/verification`,
});

export const onSetVerificationEditMod = createEvent<boolean>();
export const $verificationEditMod = restore<boolean>(onSetVerificationEditMod, false).reset(unmounted);

sample({
  clock: [mounted],
  fn: () => ({}),
  target: getVerification,
});

export const $verification = restore(
  getVerification.doneData.map((a) => {
    const { fileNames, ...rest } = a.body;
    
    return rest;
  }),
  null,
).reset([mounted, unmounted]);

// handle status

// sample({
//   clock: getVerification.doneData,
//   fn: (verification) => {
//     const status = verification.body.status

//     if (status === 'PAVRS_ACCEPTED') {
//       return path.dashboard.verification.verified()
//     }

//     if (status === 'PAVRS_BANNED') {
//       return path.dashboard.verification.entry();
//     }

//     if (status === 'PAVRS_NEW') {
//       return path.dashboard.verification.progress();
//     }

//     return path.dashboard.verification.entry();
//   },
//   filter: (is) => !!is,
//   target: toPath,
// })
