import { up } from 'styled-breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;

  ${up('sm')} {
    min-width: 375px;
    max-width: 576px;
  }

  ${up('md')} {
    min-width: 576px;
    max-width: 786px;
  }

  ${up('lg')} {
    min-width: 786px;
    max-width: 1280px;
  }

  ${up('xl')} {
    min-width: 1280px;
    max-width: 1440px;
  }
`
