import fromUnixTime from 'date-fns/fromUnixTime';
import formatFn from 'date-fns/format';

export const getDateFromUnix = (dateUnix: number, format = 'PPpp') => {
   const dateObj = fromUnixTime(dateUnix);

   return formatFn(dateObj, format);
}

export const getDateObjFromUnix = (dateUnix: number) => {
   const dateObj = fromUnixTime(dateUnix);

   return dateObj;
}

export const toUnixTimeString = (date: Date): string => {
   if (!date) return '';

   return Math.ceil(date.getTime() / 1000).toString();
}
  
export const fromDateToIso = (time: Date) => {
   if (!time) return '';
   return formatFn(time, 'dd/MM/yyyy');
 }