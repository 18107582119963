import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';

import { CreateWithdrawalParams } from 'services/payments/mutations/types';
import { usePostCreateWithdrawal } from 'services/payments/mutations/usePostCreateWithdrawal';
import { SourceItem } from 'services/payments/get/types';
import { Gateway } from 'services/money/get/types';

import { SpinnerWithBlur, Link, Text } from 'ui/atoms';

import { WithdrawalOtp } from '../otp/WithdrawalOtp';
import { $withdrawalPageStore, onStoreWithdrawal } from '../../model';

import { GatewayListHead } from './GatewayListHead';
import { GatewayBody } from './GatewayBody';
import { path } from 'libs/path';
import { Trans } from 'react-i18next';
import { SITE_EMAIL } from 'libs/constants';
import { setPraxisUrlEvent } from 'pages/money/WithdrawalPage/framePraxis/model';
import { setWithdrawalEvent } from 'pages/money/WithdrawalPage/model/withdrawalStore';


interface Props {
  onSelectGateway: (gateway: Gateway) => void;
}

export const WithdrawalGatewaysList: React.FC<Props> = ({ onSelectGateway }) => {
  const { mutateAsync, isLoading: isLoadingCreateDep, isError } = usePostCreateWithdrawal();

  const navigate = useNavigate();

  const [isOtp, setIsOtp] = useState(false);
  const [isOtpInvalid, setIsOtpInvalid] = useState(false);
  const [withdrawalSbmData, setWithdrawalSbmData] = useState<Omit<CreateWithdrawalParams, 'otp'>>();
  const withdrawalPageStore = useStore($withdrawalPageStore);

  const [selectValue, setSelectValue] = useState<SourceItem>(withdrawalPageStore?.source as SourceItem);

  const onChooseMethod = async(gateway: Gateway) => {
    if (!selectValue) return;

    const dataReq = {
      sourceID: selectValue.id,
      sourceType: selectValue.type,
      gatewayId: gateway.gatewayID,
    }

    onStoreWithdrawal({
      source: selectValue,
      gatewayId: gateway.gatewayID,
    });
    

    if (gateway.processingProvider !== 'PPP_PRAXIS') {
      setWithdrawalEvent({
        gatewayId: gateway.gatewayID,
        provider: gateway.processingProvider,
        chosenDestenation: selectValue?.id!,
        paymentMethodType: gateway.paymentMethodType,
      });

      if (gateway.openInNewTab) {
        window.open(
          `${path.money.customWithdrawal(gateway.gatewayID)}`,
          '_blank',
          'noreferrer'
        )
        return;
      }
      
      navigate(`${path.money.customWithdrawal(gateway.gatewayID)}`)
      return;
    }
  
    if (gateway.paymentMethodType === 'PMT_CREDIT_CARD') {
      onSelectGateway(gateway);
      return;
    }

    try {
      const resp = await mutateAsync(dataReq);

      if (resp.needOTP) {
        setIsOtp(true);
        setWithdrawalSbmData(dataReq);
        return;
      }

      if (resp.redirectUrl) {
        setPraxisUrlEvent(resp.redirectUrl);
        setWithdrawalEvent({
          gatewayId: gateway.gatewayID,
          provider: gateway.processingProvider,
          chosenDestenation: selectValue?.id!,
          paymentMethodType: gateway.paymentMethodType,
        });
        navigate(`${path.money.customWithdrawal(gateway.gatewayID)}`);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const onReCreateWithdrawal = async (code: string) => {
    if (!withdrawalSbmData) return;
    
    setIsOtpInvalid(false);
    try {
      const resp = await mutateAsync({ ...withdrawalSbmData, otp: code });
    
      if (code && resp.id === '0') {
        setIsOtpInvalid(true);
        return;
      }
    
      if (resp.redirectUrl) {
        setPraxisUrlEvent(resp.redirectUrl);
        setWithdrawalEvent({
          gatewayId: withdrawalSbmData.gatewayId,
          provider: 'PPP_PRAXIS',
          chosenDestenation: selectValue?.id!,
        });
        navigate(`${path.money.customWithdrawal(withdrawalSbmData.gatewayId)}`);
      }
      
    } catch (error) {
      
    }
  }

  const onReSendCode = async () => {
    if (!withdrawalSbmData) return;
    await mutateAsync({ ...withdrawalSbmData });
  }

  if (isOtp) {
    return (
      <WithdrawalOtp
        onResendCode={ onReSendCode }
        isLoading={ isLoadingCreateDep }
        onBack={() => setIsOtp(false)}
        isCodeError={ isError || isOtpInvalid }
        onCheckCode={onReCreateWithdrawal}
      /> 
    )
  }

  return (
    <div>
       { isLoadingCreateDep && (
          <SpinnerWithBlur size='xl' isShow />
      ) }

      <GatewayListHead onSelectSource={setSelectValue} />

      <GatewayBody
        isDisabled={!selectValue}
        onChooseGateway={onChooseMethod}
      />

    <div className='mt-[56px] w-full text-center'>
        <Text withTranslate={false} level={2}>
          <Trans i18nKey={"Didn't find the right method? Write us an email"} values={{
            email: SITE_EMAIL,
          }}>
            Didn't find the right method? Write us an email <Link to={`mailto: ${SITE_EMAIL}`} className='!text-bronze.500'>email</Link>
          </Trans>
        </Text>
      </div>
    </div>
  )
}

