import { yupResolver } from "@hookform/resolvers/yup"
import { useWindowSize } from '@uidotdev/usehooks'
import { updateUser } from 'features/user/model'
import { schema } from 'pages/dashboard/profile/utils/changeNameValidator';
import React from 'react'
import { useForm } from 'react-hook-form';
import { usePutChangeUserName } from 'services/user/mutations/usePutName';
import { Title } from 'ui/atoms';
import { FormInput } from 'ui/molecules';

import * as S from './styles'

interface Props {
  firstName: string;
  lastName: string;
}

interface FormModel {
  firstName: string;
  lastName: string;
}

export const ChangeNameDialog: React.FC<Props> = ({ lastName, firstName }) => {
  const { width } = useWindowSize()
  const isMobile = width && width < 800

  const { mutate, isLoading } = usePutChangeUserName({ onSuccessMutation: updateUser });

  const { 
    register, handleSubmit, reset,
    formState: { errors, dirtyFields }, 
  } = useForm<FormModel>({
    defaultValues: {
      firstName,
      lastName,
    },
    mode: 'onTouched',
    // без any почему то форма берет типы из резолвера
    // даже явное определение типа форма через дженерик не помогает
    resolver: yupResolver(schema as any),
  });

  const onSbm = (fields: FormModel) => {
    try {
      mutate(fields);
    } catch (error) {
      
    }
  };

  return (
    <div>
      <S.DialogContainer
        name="rename-account"
        confirm={{
          onConfirm: handleSubmit(onSbm),
          text: 'Confirm',
          design: 'primary',
          visibility: true,
        }}
        cancel={{
          onCancel: () => {
            reset({
              lastName,
              firstName,
            })
          },
          text: 'Cancel',
          design: 'tertiary',
          visibility: true,
        }}
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={(e) => e.stopPropagation()}
        positionX={isMobile ? 'right' : 'left'}
        content={
          <S.Block>
            <Title level={3}>Change name</Title>
            <S.Content>
              <FormInput
                label={'First name'}
                disabled={isLoading}
                state={'hint'}
                errorMessage={errors.firstName?.message}
                isTouchedForm={dirtyFields?.firstName}
                maxLength={32}
                {...register('firstName')}
              />
              <FormInput
                label={'Last name'}
                className="mt-[1.6rem]"
                errorMessage={errors.lastName?.message}
                isTouchedForm={dirtyFields?.lastName}
                maxLength={32}
                {...register('lastName')}
                disabled={isLoading}
              />
              <S.Tip>Limit first and last name to 30 characters</S.Tip>
            </S.Content>
          </S.Block>
        }
      >
        <S.EditIcon size="base" name="pencilSolid" />
      </S.DialogContainer>
    </div>
  )
}
