import { useStoreMap } from 'effector-react';
import React from 'react';
import { useGetTraderStrategiesForFilter } from 'services/copytrade';
import { Select } from 'ui/atoms';

import { GetStrategiesOptions, defaultStrategiesFilter, getStrategiesOptions } from '../common/utils/getStrategiesOptions';
import { $financialHistory, setStrategyFilterEvent } from './model/financialHistoryModel';

export const Filter = () => {
  const { data } = useGetTraderStrategiesForFilter<GetStrategiesOptions>({
    kind: 'KIND_FINANCIAL_OPERATIONS',
    select: getStrategiesOptions,
  })
  
  const strategyId = useStoreMap($financialHistory, (state) => state.strategyId);
  
  return (
    <>
      <Select
        options={data || defaultStrategiesFilter}
        placeholder="Level"
        value={strategyId}
        onSelect={setStrategyFilterEvent as (val: string) => void}
        className='!max-w-[363px]'
      />
    </>
  )
}
