import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { ProfileContent } from 'services/profile/getProfile'
import { GET_PROFILE_KEY } from 'services/profile/getProfile/useGetProfile'
import { langs } from 'ui/constants'

const putUserLanguage = async (language: string) => {
  await axios.put('/user/v1/change-language', { language })
}

export const usePutUserLanguage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: putUserLanguage,
    onSuccess(_, newLang) {
      const language = langs.find((lang) => lang.value === newLang)?.value || ''

      queryClient.setQueryData<ProfileContent>(
        GET_PROFILE_KEY,
        (oldData) =>
          oldData && {
            ...oldData,
            language,
            availableLanguage: language,
          },
      )
    },
  })
}
