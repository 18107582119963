import styled from 'styled-components/macro'
import { Button } from 'ui/atoms'
import { SelectWithModal } from 'ui/atoms/select/SelectWithModal'

export const SelectWrapper = styled(SelectWithModal)`
  margin: 24px 0;
  max-width: 460px;

  @media (max-width: 650px) {
    margin: 16px 0;
  }
`

export const SelectContent = styled.div`
  padding: 8px 0;
  display: grid;
  gap: 16px;
`

export const ContentTitle = styled.div`
  margin-bottom: 4px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);
  font-weight: 500;
`

export const SelectOption = styled.div`
  cursor: pointer;
  padding: 10px 16px;
  width: 100%;
  transition: background-color var(--transition);

  &:hover {
    background-color: var(--color-gray-200);
  }
`

export const CreateAccountButton = styled(Button)`
  margin-left: 16px;
  margin-right: 16px;
  width: calc(100% - 32px);
`