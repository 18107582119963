import { useMutation } from '@tanstack/react-query';

import { axios } from 'services/axios';


interface Params {
  documentNumber: string;
  fileNames: string[];
}

const putUpdateDocuments = async (data: Params) => {
  const res = await axios.put<{}>(`/user/v1/verification-document`, data);

  return res.data;
};

export const usePutUpdateDocuments = () => {
  return useMutation({
    mutationFn: putUpdateDocuments,
  })
};
