import { createEvent, createStore, restore, sample } from 'effector'

import { fx } from '../../../libs'
import { AccountResponse } from '../types'

type SwapFreeChangeProps = {
  accountID: string
  isSwapFree: boolean
}

export const mounted = createEvent()
export const submit = createEvent<SwapFreeChangeProps>()

export const patchSwapFree = fx<SwapFreeChangeProps, AccountResponse>({
  method: 'PATCH',
  url: `/account/v1/account/swap-free`,
})

export const $swapHasBeenChanged = restore(
  patchSwapFree.done.map(() => true),
  false,
).reset(submit)

sample({
  clock: submit,
  fn: ({ accountID, isSwapFree }) => ({
    body: {
      accountID,
      isSwapFree,
    },
  }),
  target: patchSwapFree,
})

// handle errors

export const $swapErrorMessage = createStore<string | null>(null)

$swapErrorMessage
  .on(patchSwapFree.failData, (_, failData) => failData.body.details?.msg || '')
  .reset([mounted, submit])
