import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'usehooks-ts'

import * as Styled from './styled'

export const Accordion = ({
  title,
  isOpened,
  onToggle,
  children,
}: {
  title: string
  isOpened: boolean
  onToggle: () => void
  children: ReactNode
}) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobileMode = width < 600

  return (
    <>
      <Styled.ChevronButton isOpened={isOpened} onClick={onToggle} />
      {isOpened ? (
        <Styled.InfoWrapper>
          <Styled.InfoTitle level={isMobileMode ? 3 : 2}>
            {t(title)}
          </Styled.InfoTitle>
          <Styled.InfoContent>{children}</Styled.InfoContent>
        </Styled.InfoWrapper>
      ) : (
        <Styled.SmallTitle onClick={onToggle}>{t(title)}</Styled.SmallTitle>
      )}
    </>
  )
}
