import React from 'react';
import { useStoreMap, useUnit } from 'effector-react';

import { Spinner2, Text } from 'ui/atoms';
import { $amountField, $calcAmount, $destenation } from '../model';

type Props = {
  defaultGatewayCurrency?: string;
  isLoadingCalc?: boolean;
}

export const CalculatedAmount = ({ defaultGatewayCurrency, isLoadingCalc }: Props) => {
  const calcAmount = useUnit($calcAmount);
  const activeCurrency = useStoreMap($amountField, (state) => state.activeCurrency);

  const destenationCurrency = useStoreMap($destenation, (state) => state?.currency || '');

  const getCalcCurrency = () => {
    if (activeCurrency === calcAmount.currency) return '';

    if (calcAmount.currency) return calcAmount.currency;

    return activeCurrency === defaultGatewayCurrency ? destenationCurrency : defaultGatewayCurrency;
  }
  

  return (
    <div className='flex mt-[12px]'>
      { isLoadingCalc 
        ? <Spinner2 size='small' /> 
        : <Text withTranslate={false} weight='medium' className='!text-gray.600'>
          { calcAmount.convertedAmount || '0.00' }
        </Text> 
      }
      &nbsp;
      <Text withTranslate={false} weight='medium' className='!text-gray.600'>
        {/* { calcAmount.currency || (activeCurrency === defaultGatewayCurrency ? destenationCurrency : defaultGatewayCurrency)  } */}
        {getCalcCurrency()}
      </Text>
    </div>
  )
}
