import styled, { css } from 'styled-components/macro'

export const InfoText = styled.div`
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);

  @media (max-width: 600px) {
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
  }
`

export const ContentWrapper = styled.div`
  margin-top: 20px;

  @media (max-width: 600px) {
    margin-top: 16px;
  }
`

export const Background = styled.div`
  padding: 24px 20px;
  display: grid;
  border-radius: 8px;
  background-color: var(--color-gray-100);

  @media (max-width: 600px) {
    padding: 16px 12px;
  }
`

export const Tabs = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: var(--color-gray-600);

  @media (max-width: 600px) {
    padding: 0;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 0;
    font-size: 20px;
    line-height: 25px;
    color: var(--color-gray-500);
    white-space: nowrap;
  }
`

export const Tab = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  padding: 2px 12px;
  transition: color var(--transition);

  ${({ isActive }) =>
    isActive &&
    css`
      pointer-events: none;
      color: var(--color-bronze-500);
    `}
`

export const Filters = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 16px;

  @media (max-width: 600px) {
    margin-bottom: 24px;
  }
`
