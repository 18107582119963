import { useEffect, useRef, useState } from "react";

import { useGetExchangerWallets } from "services/wallet/get/useGetExchangerWallets";
import { path } from "libs/path";
import { MenuItemProps } from "../../ui/molecules/menu";

export const menuItemsObj: MenuItemProps[] = [
  {
    icon: 'squareMenu',
    path: path.dashboard.entry(),
    label: 'Dashboard',
  },
  {
    icon: 'rectangleStack',
    path: path.accounts.entry(),
    label: 'My Accounts',
  },
  {
    icon: 'user',
    path: path.ib.entry(),
    label: 'IB Area',
  },
  {
    icon: 'banknotes',
    path: path.money.entry(),
    label: 'Finance',
  },
  {
    icon: 'gift',
    path: path.giftshop.entry(),
    label: 'Giftshop',
  },
  {
    icon: 'arrowsDiagonal',
    path: path.copytrade.investorEntry(),
    label: 'Copytrade',
  }
]

const exchangerMenuPoint = {
  icon: 'exchange',
  path: path.exchanger.entry(),
  label: 'Exchanger Area',
} as MenuItemProps;

export const useGetSidebar = () => {
  const isExchangerAreaAddRef = useRef(false); 
  const [menuItems, setMenuItems] = useState(menuItemsObj);
  const { data: exchangerWallets, isLoading, isRefetching } = useGetExchangerWallets({ noRefetch: true });

  // Exchanger Area
  useEffect(() => {
    if (isLoading || isRefetching) return;

    if (!exchangerWallets?.items?.length) {
      isExchangerAreaAddRef.current = false;
      return;
    }

    if (isExchangerAreaAddRef.current) return;

    setMenuItems(prev => {
      const newMenus = [...prev];
      newMenus.splice(3, 0, exchangerMenuPoint);

      return newMenus;
    });
    
    isExchangerAreaAddRef.current = true;
  }, [exchangerWallets]);
  
  return menuItems;
}