import * as React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components/macro'

import { toPath } from '../../../features/router'
import { path } from '../../../libs'
import { Block } from '../../../ui/atoms'
import { Steps } from '../../../ui/molecules'
import { useAccountCreate } from './hooks'
import { mapAccountPlatform } from './map'

export const AccountsCreate = () => {
  const { accountType, recordedAccountData } =
    useAccountCreate()

  return (
    <AccountBlock>
      <Steps
        items={[
          {
            label: recordedAccountData.activeTariffName || 'Account type',
            value: 'type',
            isActive: !!recordedAccountData.activeTariffName,
            onClick: () => {
              toPath(path.accounts.create.tariff(accountType))
            },
          },
          {
            label:
              (recordedAccountData.activePlatform &&
                mapAccountPlatform[recordedAccountData.activePlatform]) ||
              'Platform',
            value: 'platform',
            isActive: !!recordedAccountData.activePlatform,
            onClick: () => {
              toPath(path.accounts.create.platform(accountType))
            },
          },

          {
            label: 'Settings',
            value: 'settings',
            onClick: () => {
              toPath(path.accounts.create.settings(accountType))
            },
          },
        ]}
      />
      <Outlet />
    </AccountBlock>
  )
}

const AccountBlock = styled(Block)`
  min-height: calc(100vh - var(--header-height) - 33px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
