import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { PartnerWidget } from '../get/types';
import { PARTNER_WIDGET_KEY } from '../get/useGetPartnerPage';


const postJoinPartner = async () => {  
  const res = await axios.post<PartnerWidget>(`/ib/v1/partner`);
  return res.data;
};

export const usePostJoinPartner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postJoinPartner,
    onSuccess(data, variables, context) {
      queryClient.setQueryData([PARTNER_WIDGET_KEY], data)
    },
  })
};
