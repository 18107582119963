import React, { useMemo, useState } from 'react';
import { useStore } from 'effector-react';

import { PerfomanceResponse } from 'services/copytrade/get/useGetPerfomance';
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { $userProfile } from 'features/user';
import { Text } from 'ui/atoms';

import { Graph } from './Graph';
import { SignupTooltip } from '../signupTooltip';

import * as S from './styled';

type Props = {
 charts: PerfomanceResponse['charts'];
}

export const ChartsTabs: React.FC<Props> = ({ charts }) => {
  const [activeGraphIndex, setActiveGraphIndex] = useState(0);
  const { userID } = useStore($userProfile);

  const isNonAuthUser = !userID;

  const linesGraph = useMemo(() => {
    return charts[activeGraphIndex]?.lines || [];
  }, [activeGraphIndex, charts]);

  const defaultLines = useMemo(() => {
    if (isNonAuthUser) {
      return {
        [linesGraph?.[0]?.name]: true,
      };
    }
    const obj: Record<string, boolean> = {};

    linesGraph?.forEach(item => (obj[item.name] = true));
    return obj;
  }, [charts]);

  const [lines, setLines] = useState<Record<string, boolean>>(defaultLines);

  const chartsVariant = useMemo(() => {
    return charts.map(item => ({
      name: item.name,
      title: item.title || item.name,
    }))
  }, []);

  const isMobile = useIsMobile();

  const onToggleLine = (name: string) => {
    setLines(prev => {
      const isActive = prev[name];
    
      const newActiveLines = { ...prev, [name]: !isActive };
  
      // Проверка на то, есть ли выбранные линии
      // Если линий нет, то чекбокс не отжимается
      if (isActive) {
        let onlyDisabled = true;

        Object.values(newActiveLines).some(item => {
          if (item) onlyDisabled = false;
          return item;
        });

        if (onlyDisabled) {
          return prev;
        }
      }
  
      return newActiveLines;
    });
  };

  const changeActiveGraph = (index: number) => {
    setActiveGraphIndex(index);
    const newLines = charts[index]?.lines;
    if (!newLines?.length) return;

    const obj: Record<string, boolean> = {};

    newLines.forEach(item => {
      obj[item.name] = true;
    });

    setLines(obj);
  }

  const points = useMemo(() => {
    if (!linesGraph) return;

    return linesGraph.filter(item => lines[item.name]);
  }, [lines, linesGraph]);
  
  return (
    <div className='mt-[24px]'>

      <div className='flex justify-between'>
        <div
          className='flex lg:gap-[12px] gap-[4px]'
        >
          {chartsVariant.map((chartVariant, index) => (
            <div
              className={`
                lg:py-[8px] lg:px-[20px] py-[5px] px-[14px] 
                ${ activeGraphIndex === index ? 'bg-gray.400' : 'bg-gray.300' }
                lg:min-h-[3.2rem] min-h-[2.8rem] cursor-pointer
              `}
              onClick={() => changeActiveGraph(index)}
              key={chartVariant.name}
            >
              <Text
                level={isMobile ? 3 : 2 }
                className={ activeGraphIndex === index ? '!text-gray.800 select-none' : '!text-gray.600 select-none'}
                weight='medium'
              >
                {chartVariant.title}
              </Text>
            </div>
          ))}
        </div>

        <div className='flex lg:gap-[12px] gap-[4px]'>
          {linesGraph.map(lineItem => (
            <SignupTooltip key={lineItem.name} enabled={isNonAuthUser && !lines[lineItem.name]}>
              <S.CheckboxLineWrapper key={lineItem.name} 
                isCheked={lines[lineItem.name]}
                isDisabled={ isNonAuthUser }
                onClick={() => isNonAuthUser ? undefined : onToggleLine(lineItem.name)}
              >
                <S.Checkbox
                  name={lineItem.name}
                  design={'blue'}
                  color={lineItem.color}
                  disabled={ isNonAuthUser }
                  size={ 'small'}
                  checked={ lines[lineItem.name]}
                />
                <Text
                  className={`select-none !leading-[18px] ${(isNonAuthUser && !lines[lineItem.name]) ? '!text-gray.500' : '' }`}
                  level={isMobile ? 4 : 2 }
                  weight='medium'
                >
                  { lineItem.title }
                </Text>
            </S.CheckboxLineWrapper>
            </SignupTooltip>
          ))}
        </div>
      </div>

      { !!points?.length && <Graph lines={points} /> }
    </div>
  )
}
