import { path } from 'libs/path'
import React from 'react'
import { Block, Text, Title } from 'ui/atoms'
import { Breadcrumbs } from 'ui/molecules'

import * as S from './styles'
import { LoginHistoryTable } from './table/LoginHistoryTable'

export const LoginHistoryPage = () => {
  return (
    <>
      <Breadcrumbs
        className="mb-[2rem]"
        customPaths={[
          { name: 'Profile', pathName: path.dashboard.profile.entry() },
          {
            name: 'Login history',
            pathName: path.dashboard.profile.loginHistory(),
          },
        ]}
      />

      <S.Container>
        <Block className="flex flex-col gap-[3.2rem] overflow-auto">
          <div>
            <Title level={2}>Login history</Title>
            <Text className="mt-[0.8rem]">
              Here you can view all login activity of your account.
            </Text>
          </div>

          <LoginHistoryTable />
        </Block>
      </S.Container>
    </>
  )
}
