import * as Yup from 'yup'

const regexp = {
  email: /^[a-zA-Z0-9.'+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
}

export const name = Yup.string()
  .max(32, 'Must be at most 32 characters')
  .matches(
    /^[A-Za-z'-\s]*$/,
    'Use only Latin characters, spaces, hyphens, and apostrophes',
  )
  .matches(/\w/, 'Must be at least 1 Latin character')

export const yup = {
  login: {
    email: Yup.object().shape({
      email: Yup.string()
        .trim()
        .required('Required')
        .matches(regexp.email, 'Invalid email'),
      password: Yup.string().required('Required'),
    }),
  },
  email: {
    validate: Yup.object().shape({
      email: Yup.string()
        .trim()
        .required('Required')
        .matches(regexp.email, 'Invalid email'),
      firstName: name.required('Required'),
      lastName: name.required('Required'),
    }),
    verify: Yup.object().shape({
      email: Yup.string()
        .trim()
        .required('Required')
        .matches(regexp.email, 'Invalid email'),
    }),
  },
  password: {
    create: Yup.object({
      newPassword: Yup.string()
        .required('Required')
        .matches(
          /^[a-zA-Z0-9!@$%_#()]*$/,
          'Password must contain only Latin letters, numbers and ! @ $ % _ # ( )',
        )
        .min(8, 'Must be at least 8 characters')
        .max(32, 'Must be at most 32 characters')
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
          'Password must contain minimum 1 letter, 1 capital letter and 1 number',
        ),
      newPasswordRepeat: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    }),
    recovery: Yup.object().shape({
      email: Yup.string()
        .trim()
        .required('Required')
        .matches(regexp.email, 'Invalid email'),
    }),
  },
  code: Yup.object().shape({
    code: Yup.string().test('len', 'Must be exactly 6 characters', (val) => {
      return val?.length === 6
    }),
  }),
  verification: Yup.object().shape({
    firstName: name.required('Required'),
    lastName: name.required('Required'),
    middleName: name,
    dateOfBirth: Yup.string().required('Required'),
    address: Yup.string(),
    country: Yup.string().required('Required'),
    documentNumber: Yup.string().required('Required'),
  }),
  updateDocs: Yup.object().shape({
    documentNumber: Yup.string().required('Required'),
  }),
}

// If valid -> true
// If invalid -> false
export const validateNumberMax = (
  val?: string | number,
  max?: string | number,
): boolean | string => {
  const parsedVal =
    typeof val === 'number' ? val : Number(val?.replace(/[^\d.]/g, '') || 0)

  if (!parsedVal || !Number(max)) return true

  if (parsedVal > Number(max)) {
    return false
  }

  return true
}

// If valid -> true
// If invalid -> false
export const validateNumberMin = (
  val?: string | number,
  min?: string | number,
): boolean | string => {
  if (typeof min !== 'number' && typeof min !== 'string') return true;

  const minNumber = Number(min) || 0;

  const parsedVal =
    typeof val === 'number' ? val : Number(val?.replace(/[^\d.-]/g, '') || 0)

  if (!parsedVal) return false;

  if (parsedVal < minNumber) {
    return false
  }

  return true
}

// If valid -> true
// If invalid -> false
export const validateNumberMinMax = (
  val: string | number | undefined,
  range: {
    min?: string | number;
    max?: string | number;
  }
): boolean | string => {
  const { max, min } = range;
  if (!Number(min) && !Number(max)) return true;

  const parsedVal =
    typeof val === 'number' ? val : Number(val?.replace(/[^\d.]/g, '') || 0)

  if (!parsedVal) return false;

  if (parsedVal < Number(min) || parsedVal > Number(max)) {
    return false
  }

  return true
}