import React, { memo } from 'react';
import { useController } from 'react-hook-form';

import { BankCardListItem } from 'services/payments/get/types';

import { CardsList } from '../withdrawal/molecules';


interface Props {
  cardsLIst?: BankCardListItem[];
}

const Component: React.FC<Props> = ({ cardsLIst = [] }) => {
  const { field: cardField } = useController({
    name: 'cardItem',
    rules: {
      required: 'Required item',
    }
  });

  const onChooseCard = (card: BankCardListItem) => {
    cardField.onChange(card);
  }

  return (
    <>
      <CardsList
        title='Bank card'
        dataList={cardsLIst as any}
        onSelectCard={onChooseCard as any}
      />
    </>
  )
}

export const CardWrapper = memo(Component);