import * as React from 'react'
import styled from 'styled-components/macro'

import { Icon2 } from '../icon2'

type Props = {
  className?: string
  children?: React.ReactNode
  text?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
  isActive?: boolean
}

export const Tooltip = ({
  onClick,
  className,
  children,
  text,
  isActive,
}: Props) => {
  return (
    <TooltipContainer
      className={className}
      data-active={isActive}
      onClick={(e) => {
        onClick && onClick(e)
      }}
    >
      {children && <span>{children}</span>}
      <TooltipIcon size="small" name="questionMarkCircle" />
      {text && <TooltipText>{text}</TooltipText>}
    </TooltipContainer>
  )
}

const TooltipIcon = styled(Icon2)`
  margin-inline-start: 4px;
  transition: var(--transition);
  color: var(--color-gray-500);
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`

const TooltipText = styled.div`
  display: none;
`

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;

  &[data-active='true'] {
    ${TooltipIcon} {
      color: var(--color-sunray-500);
    }
  }

  &[data-active='false'] {
    ${TooltipIcon} {
      color: var(--color-gray-500);
    }
  }
`
