import { useTranslation } from 'react-i18next'
import { Icon2, Input } from 'ui/atoms'
import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon'

import * as Styled from './styled'

export const LinkForShare = ({
  link,
  className,
}: {
  link: string
  className?: string
}) => {
  const [t] = useTranslation()

  return (
    <Styled.Wrapper className={className}>
      <div className="mb-[4px] text-body.small leading-body.small text-gray.600">
        {t('Recommended link for share')}
      </div>
      <Input
        value={link}
        readOnly
        // @ts-expect-error
        prefix={<Icon2 name="link" className="text-gray.600" />}
        suffix={
          <CopyIcon
            tooltipText="Copied"
            copiedText={link}
            copyIconClass="text-bronze.500"
          />
        }
      />
    </Styled.Wrapper>
  )
}
