import {
  getCheckResetPasswordCode,
  postChangePassword,
  postResetPassword,
} from './model'

export const authService = {
  postResetPassword,
  postChangePassword,
  getCheckResetPasswordCode,
}

export type {
  ResetPasswordProps,
  CheckResetPasswordCodeProps,
  ChangePasswordProps,
} from './types'
