import {
  getInternalTransferCalculate,
  getInternalTransferDestinations,
  getInternalTransferSources,
  postInternalTransfer,
} from './model'

export const internalTransfersService = {
  postInternalTransfer,
  getInternalTransferCalculate,
  getInternalTransferDestinations,
  getInternalTransferSources,
}

export type { Wallet, Account, InternalTransferProps } from './types'