import { useWindowSize } from '@uidotdev/usehooks'
import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import { useNavigate } from 'react-router-dom'
import { Button, Icon2 } from 'ui/atoms'

import {
  $strategiesComparisonList,
  clearStrategiesComparisonList,
  removeStrategyFromCompare,
} from '../../model'
import * as Styled from './styled'

export const ComparisonModal = () => {
  const { width } = useWindowSize()
  const isSmallDesktop = width && width >= 750 && width < 1441

  const navigate = useNavigate()

  const { userID } = useStore($userProfile)
  const strategiesComparisonList = useStore($strategiesComparisonList)

  return (
    <Styled.ContentContainer>
      <Styled.TagsWrapper>
        {strategiesComparisonList.map((strategy) => (
          <Styled.Tag
            key={strategy.strategyID}
            onClick={() =>
              removeStrategyFromCompare({
                strategyID: strategy.strategyID,
                userID,
              })
            }
          >
            <span className="max-w-[114px] truncate">
              {strategy.strategyTitle}
            </span>
          </Styled.Tag>
        ))}
      </Styled.TagsWrapper>
      <Styled.ButtonsWrapper>
        <Button
          name="clear"
          size={isSmallDesktop ? 'small' : 'medium'}
          design="secondary"
          className={isSmallDesktop ? 'min-w-[123px]' : 'min-w-[148px]'}
          onClick={() => clearStrategiesComparisonList(userID)}
        >
          Clear
        </Button>
        <Button
          name="compare"
          size={isSmallDesktop ? 'small' : 'medium'}
          className={isSmallDesktop ? '' : 'min-w-[148px]'}
          onClick={() => navigate(path.copytrade.compareStrategies())}
          prefix={<Icon2 name="compare" size={isSmallDesktop ? 'small' : 'medium'} />}
        >
          Compare
        </Button>
      </Styled.ButtonsWrapper>
    </Styled.ContentContainer>
  )
}
