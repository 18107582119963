import React, { useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';


import { path } from 'libs/path';
import { ProfileStatus, VerificationProfileBlock } from 'services/profile/getProfile'
import { Icon2, Text } from 'ui/atoms';


interface Props {
  verification?: VerificationProfileBlock;
  updatingDocumentRequest?: boolean;
}

export const VerificationStatus: React.FC<Props> = ({ verification, updatingDocumentRequest }) => {  
  const [t] = useTranslation();
  const status = useMemo(() => {
    if (!verification?.status || verification?.status === ProfileStatus.REJECTED) {
        return (
          <>
            <Icon2
              size='base'
              name='exclamationCircleSolind'
              className='text-orange.500 me-[0.8rem]'
            />
            <Text
              level={2}
              className='text-gray.600'
            >
              Information missing
            </Text>&nbsp;
            <Link to={path.dashboard.verification.entry()}>
              <div className='text-button-s cursor-pointer text-sunray.500'>
                Reapply
              </div>
            </Link>
          </>
        );
    }

    if (verification?.status === ProfileStatus.EXPIRATION_DATE_COMING_UP ) {
      const expiredDate = format(new Date(verification?.documentExpirationDate), 'dd/MM/yyyy');
      return (
        <>
          <Icon2
            size='base'
            name='exclamationCircleSolind'
            className='text-orange.500 me-[0.8rem]'
          />
          <Text
            level={2}
            className='text-gray.600'
            withTranslate={false}
          >
            <Trans 
              key={'Verification will expire on'} 
              tOptions={{ interpolation: { escapeValue: true } }}
              shouldUnescape
              values={{
                date: expiredDate
              }}
            >
              Verification will expire on
            </Trans>
          </Text>&nbsp;
          {!updatingDocumentRequest && 
            <Link to={path.dashboard.verification.updateDocs()}>
              <div className='text-button-s cursor-pointer text-sunray.500'>
                Update documents
              </div>
            </Link>
          }
        </>
      );
  }

    if (verification?.status === ProfileStatus.EXPIRED) {
        return (
          <>
            <Icon2
              size='base'
              name='exclamationCircleSolind'
              className='text-opal.500 me-[0.8rem]'
            />
            <Text
              level={2}
              className='text-gray.600'
            >
              Verification document expired
            </Text>&nbsp;
            { !updatingDocumentRequest && 
              <Link to={path.dashboard.verification.updateDocs()}>
                <div className='text-button-s cursor-pointer text-sunray.500'>
                  Reapply
                </div>
              </Link>
            }
          </>
        );
    }

    if (verification?.status === ProfileStatus.BLOCKED) {
        return (
          <>
            <Icon2
              size='base'
              name='blockedIcon'
              className='text-opal.500 me-[0.8rem]'
            />
            <Text
              level={2}
              className='text-gray.600'
            >
              Blocked
            </Text>&nbsp;
            <span className='text-button-s cursor-pointer text-sunray.500'>Contact Customer Care</span>
          </>
        );
    }

    if (verification?.status === ProfileStatus.NOT_VERIFIED) {
        return (
          <>
            <Icon2
              size='base'
              name='xcircleIcon'
              className='text-opal.500 me-[0.8rem]'
            />
            
              <Text
                level={2}
                className='text-gray.600'
              >
                Not verified
              </Text>&nbsp;

            {/* 
              TODO --- Add inline Button component
            */}
            <Link to={path.dashboard.verification.entry()}>
              <div className='text-button-s cursor-pointer text-sunray.500'>Verify now</div>
            </Link>
          </>
        );
    }

    if (verification?.status === ProfileStatus.IN_PROGRESS) {
        return (
          <>
            <Icon2
              size='base'
              name='clockSolid'
              className='text-azure.500 me-[0.8rem]'
            />
            <Text
              level={2}
              className='text-gray.600'
            >
              Verification in process
            </Text>&nbsp;
          </>
        );
    }
    if (verification?.status === ProfileStatus.VERIFIED) {
      const date = format(new Date(verification?.documentExpirationDate), 'dd/MM/yyyy');
        return (
          <>
            <Icon2
              size='base'
              name='clockSolid'
              className='text-eucalyptus.500 me-[0.8rem]'
            />
            <Text
              level={2}
              className='text-gray.600'
              withTranslate={false}
            >
              <Trans 
                key={'Verified until'} 
                tOptions={{ interpolation: { escapeValue: true } }}
                shouldUnescape
                values={{
                  date
                }}
              >
                Verified until
              </Trans>
            </Text>&nbsp;
          </>
        );
    }
  }, [verification, updatingDocumentRequest, t]);

  return (
    <div className='flex'>{status}</div>
  )
}
