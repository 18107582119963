import { env } from 'libs/constants'
import { forwardRef } from 'react'
import { useRef, useState } from 'react'
import ReCaptcha from 'react-google-recaptcha'

export const useCaptcha = () => {
  const ref = useRef<ReCaptcha>(null)

  const [isLoading, setLoading] = useState(false)

  const execute = async (onSuccess?: () => void) => {
    if (!ref.current) {
      return
    }

    ref.current.reset()

    setLoading(true)

    try {
      await ref.current.executeAsync()

      onSuccess?.()
    } catch (err) {
      console.log('err=', err)
    } finally {
      setLoading(false)

      ref.current.reset()
    }
  }

  return { isLoading, execute, ref }
}

export const Captcha = forwardRef((_, ref) => {
  return (
    // @ts-expect-error
    <ReCaptcha ref={ref} size="invisible" sitekey={env.captchaInvisibleKey} />
  )
})
