import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePostJoinPartner } from 'services/ib/mutation';
import { Button, Icon2, Text, Title } from 'ui/atoms';

import * as S from './styled';

export const Steps = () => {
  const [t] = useTranslation()
  const { mutateAsync, isLoading } = usePostJoinPartner();

  return (
    <S.PartnerBlock className='mt-[2rem]'>
      <Title level={1} className='!font-medium'>{t('How to start earning?')}</Title>
      <Text className='mt-[8px]'>
        Here are the steps:
      </Text>

      <div className='w-full flex-col lg:flex-row flex gap-[2rem]'>
        <S.StepBlock className='h-fit'>
            <div className='w-full flex items-center justify-between'>
              <S.StepTitle level={1}>1</S.StepTitle>
              <Icon2 className='w-[64px] h-[64px] !text-eucalyptus.500' name='plus' size='custom' />
            </div>

            <div>
              <Title level={3} className='!font-medium'>Join the program </Title>
              <Text level={2} className='mt-[16px]'>
                After joining the Partnership, you will get access to your Personal Area. There you will find the referral link for sharing.
              </Text>
            </div>
        </S.StepBlock>

        <S.StepBlock className='h-fit'>
          <div className='w-full flex items-center justify-between'>
            <S.StepTitle level={1}>2</S.StepTitle>
            <Icon2 className='w-[64px] h-[64px] !text-azure.500' name='link' size='custom' />
          </div>

          <div>
            <Title level={3} className='!font-medium'>Publish your referral link</Title>
            <Text level={2} className='mt-[16px]'>
              Share your referral link with your network on social 
              media or other platforms to attract new clients; 
              don’t forget to use our effective tools and 
              advertising materials for better results.
            </Text>
          </div>
        </S.StepBlock>

        <S.StepBlock>
          <div className='w-full flex items-center justify-between'>
            <S.StepTitle level={1}>3</S.StepTitle>
            <Icon2 className='w-[64px] h-[64px] !text-sunray.500' name='diamond_outline' size='custom' />
          </div>

          <div>
            <Title level={3} className='!font-medium'>Get rewarded!</Title>
            <Text level={2} className='mt-[16px]'>
              After your attracted clients close their orders, 
              the money will be instantly added to your IB wallet. 
              The more clients you have in the network, the more commission you get. 
              Commission will be credited for the Partners you attracted as well.
            </Text>
          </div>
          <div className='flex justify-center w-full'>
            <Button
              design='primary'
              onClick={ () => mutateAsync() }
              loading={ isLoading }
              disabled={ isLoading }
              className='min-w-[19.2rem]'
              name='joinNow'
            >
              Join now
            </Button>
          </div>
        </S.StepBlock>
      </div>
    </S.PartnerBlock>
  )
}
