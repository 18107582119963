
import React from 'react';
import { useStore } from 'effector-react';
import { useFormikContext } from 'formik';

import { Button } from 'ui/atoms';

import { postVerification } from './model';
import { $uploadFiles } from '../../atoms/upload/model';
import { isEmpty } from 'libs/isEmpty';


export const RequestButton = () => {
  const loading = useStore(postVerification.pending);
  const uploadFiles = useStore($uploadFiles);

  const { errors } = useFormikContext();

  const isButtonDisabled = (uploadFiles?.length < 1) || !isEmpty(errors);
  
  return (
    <>
      <Button
        name="verification-request"
        type="submit"
        loading={loading}
        disabled={isButtonDisabled}
      >
        Submit request
      </Button>
    </>
  )
}
