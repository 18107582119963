import { useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { DepositForm } from './DepositFormTypes';

export const GET_DEPOSIT_FORM_KEY = ['deposit-form'];


const getDepositForm = async (gatewayID: string) => {
  const res = await axios.get<DepositForm>(`/ps-forms/v1/deposit/payment-form`, {
    params: {
      gatewayID,
    },
  });

  return res.data;
};

export const useGetDepositForm = (gatewayID: string) => {
  return useQuery({
    queryKey: GET_DEPOSIT_FORM_KEY,
    queryFn: () => getDepositForm(gatewayID),
    retry: retryFn,
    staleTime: 2000
  })
};
