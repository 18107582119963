import { useStore } from 'effector-react'
import { useParams } from 'react-router-dom'

import { $recordedAccountData, $tariffs } from '../model'
import { $activeTariffName } from './model'

export const useAccountTariff = () => {
  const { accountType = 'invalid' } = useParams()

  const tariffs = useStore($tariffs)
  const activeTariffName = useStore($activeTariffName)

  const recordedAccountData = useStore($recordedAccountData)

  return { tariffs, activeTariffName, accountType, recordedAccountData }
}
