import React from 'react'
import { useTranslation } from 'react-i18next'
import { GiftsHistoryOperationType } from 'services/giftshop/get/types'

import MiniCoinIcon from '../images/mini-coin.svg'
import * as S from './styled'

const filterTabs = [
  { label: 'All', value: 'ALL' },
  { label: 'Debited', value: 'DEBITED' },
  { label: 'Credited', value: 'CREDITED' },
]

type Props = {
  onChangeType: (val: GiftsHistoryOperationType) => void
  activeType: GiftsHistoryOperationType
}

export const Filter: React.FC<Props> = ({ onChangeType, activeType }) => {
  const [t] = useTranslation()

  return (
    <div className="w-full flex gap-[8px] justify-center">
      {filterTabs.map(({ label, value }) => (
        <S.FilterTab
          key={value}
          isActive={value === activeType}
          onClick={() => onChangeType(value as GiftsHistoryOperationType)}
        >
          {value !== 'ALL' && (
            <img
              src={MiniCoinIcon}
              alt="mini-coin icon"
              className="w-[16px] h-[16px]"
            />
          )}
          {t(label)}
        </S.FilterTab>
      ))}
    </div>
  )
}
