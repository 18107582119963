import { path } from 'libs/path'
import { useTranslation } from 'react-i18next'

import * as Styled from './styled'

export const DepositBanner = ({
  isDisabled,
  onClose,
}: {
  isDisabled: boolean
  onClose: () => void
}) => {
  const [t] = useTranslation()

  return (
    <Styled.Wrapper>
      <Styled.CloseButton onClick={onClose} disabled={isDisabled} />
      <Styled.TextWrapper>
        <Styled.Title>{t('Only in May')}</Styled.Title>
        {t(
          'Deposit $50 and get a chance to win real money, iPhone 15 Pro Max, and more!',
        )}
      </Styled.TextWrapper>
      <Styled.JoinButton
        name="see-details"
        to={path.contest.traders()}
        size="small"
        disabled={isDisabled}
      >
        See details
      </Styled.JoinButton>
    </Styled.Wrapper>
  )
}
