import styled, { css } from 'styled-components/macro'

export const Wrapper = styled.div<{
  size: 'medium' | 'large'
  isActive: boolean
  isDisabled?: boolean
}>`
  cursor: ${({isDisabled}) => isDisabled ? 'not-allowed' : 'pointer'};
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  transition: background-color var(--transition);
  border-radius: 8px;
  background-color: ${({ isActive, isDisabled }) =>
    isActive
      ? 'var(--color-sunray-500)'
      : isDisabled
      ? 'var(--color-gray-300)'
      : 'var(--color-gray-500)'};

  &:hover {
    background-color: ${({ isActive, isDisabled }) =>
      isActive
        ? 'var(--color-sunray-400)'
        : isDisabled
        ? 'var(--color-gray-300)'
        : 'var(--color-gray-400)'};
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 2px;
    width: 12px;
    height: 12px;
    transform: ${({ isActive }) =>
      isActive
        ? 'translateY(-50%) translateX(calc(100% + 4px))'
        : 'translateY(-50%)'};
    transition: transform var(--transition);
    background-color: var(--color-gray-100);
    border-radius: 50%;
    box-shadow: 0.5px 0.5px 2px 0px #1a28381a;
  }

  ${({ size }) => {
    if (size === 'large') {
      return css`
        width: 40px;
        height: 20px;
        border-radius: 10px;

        &::before {
          width: 16px;
          height: 16px;
          box-shadow: 0.75px 0.75px 2px 0px #1a28381a;
        }
      `
    }
  }}
`
