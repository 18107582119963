import { format } from 'date-fns'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ZendeskAPI } from 'react-zendesk'
import { Operation } from 'services/transaction-history/types'
import { Button, Money, Tag, TipyTooltip, Text } from 'ui/atoms'
import { useWindowSize } from 'usehooks-ts'

import {
  transactionIcons,
  transactionStatuses,
  transactionTypes,
} from 'pages/money/transactionsNew';
import * as Styled from './styled'

const formatDate = (date: string) =>
  format(new Date(+date * 1000), 'd LLL yyyy, hh:mm aaa')

export const Transaction = ({ data }: { data: Operation }) => {
  const [t] = useTranslation()

  const [isOpened, setIsOpened] = React.useState(false)

  const { width } = useWindowSize()
  const isMobileMode = width < 1200

  const destinationCurrency =
    data.operationType === 'OT_INTERNAL_TRANSFER'
      ? data.destinationCurrency
      : data.currency;

  const destinationAmount =
    data.operationType === 'OT_INTERNAL_TRANSFER'
      ? data.destinationAmount
      : data.amount;

  const destinationNode = (
    <>
      {data.operationType === 'OT_WITHDRAWAL' ||
      data.operationType === 'OT_BONUS111_PROFIT_WITHDRAWAL' ||
      data.operationType === 'OT_BONUS111_WITHDRAWAL'
        ? '- '
        : '+ '}
      <Money currency={destinationCurrency} value={+destinationAmount} />
    </>
  )

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${t('Created')}: ${formatDate(data.createdAt)}\n\nFrom: ${
        data.fromName
      }${
        data.operationType === 'OT_INTERNAL_TRANSFER'
          ? `\n- ${data.amount} ${data.currency}`
          : ''
      }\n\n${t('To')}: ${data.toName}${
        data.operationType === 'OT_INTERNAL_TRANSFER'
          ? `\n+ ${destinationAmount} ${destinationCurrency}`
          : ''
      }\n\n${
        data.operationType !== 'OT_INTERNAL_TRANSFER'
          ? `${t('Amount')}: ${data.amount} ${data.currency}`
          : ''
      }${t('Type')}: ${transactionTypes[data.operationType]}\n\n${t(
        'Transaction status',
      )}: ${transactionStatuses[data.operationStatus]}\n\n${t(
        'Status updated',
      )}: ${formatDate(data.updatedAt)}\n\nTransaction ID: ${data.operationID}`,
    )
  }

  const handleContactSupport = () => {
    ZendeskAPI('messenger', 'open')
  }
  
  return (
    <Styled.Wrapper>
      <Styled.VisibleInfo
        opened={isOpened}
        onClick={() => setIsOpened(!isOpened)}
      >
        <Styled.LeftIcon src={transactionIcons[data.operationType]} />
        <Styled.InfoGrid>
          <Styled.TypeWrapper>
            {t(transactionTypes[data.operationType])}{' '}
            {data.operationType === 'OT_BONUS111_DEPOSIT' && (
              <Tag design="sunray">{t('Bonus')}</Tag>
            )}
            {data.operationType === 'OT_IB_CLIENT_CASHBACK_DEPOSIT' && (
              <Tag design="eucalyptus">{t('Cashback')}</Tag>
            )}
          </Styled.TypeWrapper>
          <Styled.Status status={data.operationStatus} small>
            {t(transactionStatuses[data.operationStatus])}
          </Styled.Status>
          <Styled.InfoRow>
            <div>
              <Styled.LightText>{t('From')}</Styled.LightText>
              {data.fromName}
            </div>
            {data.operationType === 'OT_INTERNAL_TRANSFER' && (
              <Styled.RightAlign>
                - <Money currency={data.currency} value={+data.amount} />
              </Styled.RightAlign>
            )}
            {isMobileMode && data.operationType !== 'OT_INTERNAL_TRANSFER' && (
              <Styled.RightAlign>{destinationNode}</Styled.RightAlign>
            )}
            {!isMobileMode && <Styled.TransferArrow />}
            <div>
              <Styled.LightText>{t('To')}</Styled.LightText>
              {data.toName}
            </div>
            {isMobileMode && data.operationType === 'OT_INTERNAL_TRANSFER' && (
              <Styled.RightAlign>{destinationNode}</Styled.RightAlign>
            )}
          </Styled.InfoRow>
          {!isMobileMode && (
            <Styled.RightAlign>{destinationNode}</Styled.RightAlign>
          )}
        </Styled.InfoGrid>
      </Styled.VisibleInfo>
      {isOpened ? (
        <Styled.HiddenInfo>
          <Styled.DetailsGrid>
            <Details title="Created" value={formatDate(data.createdAt)} />
            <Details
              title="From"
              value={
                data.operationType === 'OT_INTERNAL_TRANSFER' ? (
                  <>
                    {data.fromName}, -{' '}
                    <Money currency={data.currency} value={+data.amount} />
                  </>
                ) : (
                  data.fromName
                )
              }
            />
            <Details
              title="To"
              value={
                data.operationType === 'OT_INTERNAL_TRANSFER' ? (
                  <>
                    {data.toName}, +{' '}
                    <Money
                      currency={destinationCurrency}
                      value={+destinationAmount}
                    />
                  </>
                ) : (
                  data.toName
                )
              }
            />
            <Details
              title="Type"
              value={t(transactionTypes[data.operationType])}
            />
            <Details
              title="Transaction status"
              value={
                <Styled.Status status={data.operationStatus}>
                  {t(transactionStatuses[data.operationStatus])}
                </Styled.Status>
              }
            />
            <Details
              title="Status updated"
              value={formatDate(data.updatedAt)}
            />
            <Details title="Transaction ID" value={data.operationID} />
            { data?.exchangerNote && (
              <Details
                title="Note"
                value={
                  <Text level={ isMobileMode ? 3 : 2} className='lg:max-w-[400px] max-w-[210px] break-words'>
                    {data.exchangerNote}
                  </Text>
                }
              />) 
            }
          </Styled.DetailsGrid>
          {data.rejectReason ? (
            <Styled.Warning error>
              {data.rejectReason}
            </Styled.Warning>
          ) : null}
          <Styled.Buttons>
          <TipyTooltip
            content={
              <div>
                <Text weight='medium' level={3}>{'Copied'}</Text>
              </div>
            }
            trigger='click'
            >
              <Button
                size="small"
                name="repeat"
                design="secondary"
                onClick={handleCopy}
              >
                Copy details
              </Button>
            </TipyTooltip>
            {data.operationStatus === 'OS_REJECTED' && (
              <Button size="small" name="repeat" onClick={handleContactSupport}>
                Contact support
              </Button>
            )}
          </Styled.Buttons>
        </Styled.HiddenInfo>
      ) : null}
    </Styled.Wrapper>
  )
}

const Details = ({
  title,
  value,
}: {
  title: string
  value: React.ReactNode
}) => {
  const [t] = useTranslation()

  return (
    <Styled.DetailsRow>
      <Styled.DetailsTitle>{t(title)}</Styled.DetailsTitle>
      <Styled.DetailsValue>{value}</Styled.DetailsValue>
    </Styled.DetailsRow>
  )
}
