import styled from 'styled-components'
import { Button } from 'ui/atoms'

export const RegisterSuccessSection = styled.div`
  text-align: left;
`

export const SubmitOathButton = styled(Button)`
  width: 100%;
`

export const OathForm = styled.div`
  margin: 32px 0;
`
