import arrow from './arrow-icon.svg'
import dots from './dots-icon.svg'
import pencil from './pencil-icon.svg'
import power from './power-icon.svg'
import share from './share-icon.svg'

export const icons = {
  arrow,
  pencil,
  power,
  share,
  dots,
}
