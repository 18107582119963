import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { Strategy } from '../types'

export const GET_STRATEGY_FOR_INVESTOR = ['copytrade-strategy-for-investor']

const getStrategyForInvestor = async (strategyID: string, isAuthorized: boolean) => {
  const res = await axios.get<Strategy>(
    isAuthorized
      ? '/copytrade/v1/strategy'
      : '/copytrade/v1/strategy-for-unauthorized',
    {
      params: { strategyID },
    },
  )

  return res.data
}

export const useGetStrategyForInvestor = (
  strategyID: string,
  isAuthorized: boolean,
) => {
  return useQuery({
    queryKey: GET_STRATEGY_FOR_INVESTOR,
    queryFn: () => getStrategyForInvestor(strategyID, isAuthorized),
  })
}
