import { useStore } from 'effector-react'
import * as React from 'react'
import styled from 'styled-components/macro'
import { Checkbox, Text } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'

import {
  $swapErrorMessage,
  $swapHasBeenChanged,
  mounted,
  patchSwapFree,
  submit,
} from './model'

type Props = {
  accountID: string
  isSwapFree: boolean
}

export const SwapFree = ({ accountID, isSwapFree }: Props) => {
  const isLoading = useStore(patchSwapFree.pending)
  const swapHasBeenChanged = useStore($swapHasBeenChanged)
  const swapErrorMessage = useStore($swapErrorMessage)

  const [errorVisible, setErrorVisible] = React.useState(false)
  const [warningVisible, setWarningVisible] = React.useState(false)

  React.useEffect(() => {
    mounted()
  }, [])

  React.useEffect(() => {
    if (swapErrorMessage) {
      setWarningVisible(false)
    }

    setErrorVisible(!!swapErrorMessage)
  }, [swapErrorMessage])

  React.useEffect(() => {
    if (swapHasBeenChanged) {
      setWarningVisible(false)
    }
  }, [swapHasBeenChanged])

  return (
    <>
      <Container>
        <Checkbox
          name="swap-free"
          design={'brand'}
          checked={isSwapFree}
          onChange={() => setWarningVisible(true)}
        />
      </Container>
      <ModalDialog
        isOpen={errorVisible}
        onOk={() => setErrorVisible(false)}
        onCancel={() => setErrorVisible(false)}
        title="Swap-Free change"
        okLabel="Get it"
        shouldCloseOnOverlayClick
      >
        <Text withTranslate={false}>{swapErrorMessage}</Text>
      </ModalDialog>
      <ModalDialog
        isOpen={warningVisible}
        onOk={() => submit({ isSwapFree: !isSwapFree, accountID })}
        onCancel={() => setWarningVisible(false)}
        title="Swap-Free change"
        cancelLabel="Cancel"
        okLabel="Change"
        disableOkBtn={isLoading}
      >
        <Text>
          Once you edit Swap-Free settings, you can change them again only after
          24 hours.
        </Text>
      </ModalDialog>
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`
