import { useStoreMap, useUnit } from 'effector-react';
import React, { useMemo } from 'react';

import { Text, Title } from 'ui/atoms';
import { $amountField, $calcAmount, $destenation } from './model';
import { delEmptyNumFloat } from 'ui/molecules/hooksForm/FormInput';

type Props = {
  defaultCurrency: string;
}

export const ReciveBlock = ({ defaultCurrency }: Props) => {
  const destenationCurrency = useStoreMap($destenation, (state) => state?.currency);
  const amountField = useUnit($amountField);
  const calcAmount = useUnit($calcAmount);

  // const isOneCurrency = destenationCurrency === defaultCurrency;

  const amount = useMemo(() => {
    if (amountField.errorType) {
      return '0.00'
    }

    return calcAmount.currency === destenationCurrency ? calcAmount.convertedAmount : amountField.amount;
  }, [amountField, destenationCurrency, calcAmount]);

  return (
    <div className='mt-[44px] flex flex-col items-center'>
      <Text weight='medium' level={2}  className='!text-gray.600'>money.customForm.willReceive</Text>
      <div className='flex items-end mt-[12px]'>
        <Title withTranslate={ false } level={1} className='!text-eucalyptus.600 mr-[8px]'>{delEmptyNumFloat(amount) || '0.00'}</Title>
        <Text withTranslate={ false } weight='medium' className='mb-[4px] !text-gray.600'>
          { destenationCurrency || 'USD' }
        </Text>
      </div>
    </div>
  )
}
