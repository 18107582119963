import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import { axios } from 'services/axios'

import { GET_TRADER_STRATEGIES_KEY } from '../get/useGetTraderStrategies'
import { TraderStrategiesList } from '../types'
import { GET_TRADER_STRATEGIES_FOR_FILTER } from '../get/useGetTraderStrategiesForFilter'

const removeStrategy = async (strategyID: string) => {
  await axios.delete('/copytrade/v1/trader/strategy', {
    params: { strategyID },
  })
}

export const useRemoveStrategy = () => {
  return useMutation({
    mutationFn: removeStrategy,
    onSuccess(_, strategyID) {
      // Сбрасываем валидцию запроса для списка стратегий для фильтра
      queryClient.invalidateQueries([GET_TRADER_STRATEGIES_FOR_FILTER]);
      // т.к. нам нужно корректно закрыть модальное окно, а только потом удалить стратегию из списка,
      // при помощи setTimeout "сдвигаем" это удаление в конец
      setTimeout(
        () =>
          queryClient.setQueryData<TraderStrategiesList['items']>(
            GET_TRADER_STRATEGIES_KEY,
            (oldData) =>
              oldData?.filter((strategy) => strategy.strategyID !== strategyID),
          ),
        20,
      )
    },
  })
}
