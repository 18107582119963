import { SocialMedia } from 'services/ib/get/useGetSocialMedia'
import { Button, Title } from 'ui/atoms'

import { useDownloadFile } from '../../utils/useDownloadFile'
import * as Styled from './styled'

export const MediaModalContent = (props: SocialMedia) => {
  const { isLoading, startDownload } = useDownloadFile()

  return (
    <Styled.Container>
      <Title withTranslate={false} level={3}>
        {props.title}
      </Title>
      <div className="mt-[4px] text-body.small leading-body.small text-gray.600 mobile:mt-[8px]">
        {props.dimensions}, {props.extension}, {props.size}
      </div>
      <Styled.MediaWrapper>
        {props.format === 'video' ? (
          <video controls preload="none">
            <source src={props.originLink} type="video/mp4" />
          </video>
        ) : (
          <img src={props.originLink} alt={props.title} />
        )}
      </Styled.MediaWrapper>
      {props.sharedLink && <Styled.LinkWrapper link={props.sharedLink} />}
      <Button
        size="medium"
        className="w-full"
        onClick={() => startDownload(props.originLink, props.name)}
        loading={isLoading}
        disabled={isLoading}
        name="download-media-from-modal"
      >
        Download
      </Button>
    </Styled.Container>
  )
}
