import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import React from 'react'
import questSrc from 'styles/img/question.png'
import questSrcWeb from 'styles/img/question.webp'
import { Button, Icon, Icon2, Text } from 'ui/atoms'
import { THead } from 'ui/molecules'

type Props = {
  isMobile: boolean
  onStartCopying: () => void
}

export const NoCopying: React.FC<Props> = ({ isMobile, onStartCopying }) => {
  const { userID } = useStore($userProfile)

  return (
    <div className="mt-[16px]">
      {!isMobile && (
        <div className="overflow-scroll scrollbar-hide lg:w-full w-[75vh] relative">
          <table className="w-full" border={1}>
            <THead
              trClasses=""
              textLevel={isMobile ? 4 : 3}
              heads={[
                { label: 'Instrument' },
                { label: 'Opening date' },
                { label: 'Opening price' },
                { label: 'Closing date' },
                { label: 'Closing price' },
                { label: 'Commission' },
                { label: 'Swap' },
                { label: 'Profit', align: 'right' },
              ]}
            />
            <tbody></tbody>
          </table>
        </div>
      )}

      <div className="flex flex-col w-full justify-center items-center">
        <picture>
          <source srcSet={questSrcWeb} type="image/webp" className="w-[8rem] mt-[56px]" />
          <img src={questSrc} alt="question" className="w-[8rem] mt-[56px]" />
        </picture>
        <Text level={isMobile ? 3 : 1} color="subtext">
          The history is available only to investors.
        </Text>
        {!userID ? (
          <Button
            className="min-w-[192px] mt-[24px] mb-[32px]"
            name="sign-up"
            prefix={<Icon2 name="arrowLeftOnRectangle" />}
            to={path.register.entry()}
          >
            Sign up
          </Button>
        ) : (
          <Button
            className="min-w-[240px] mt-[24px] mb-[32px]"
            type="button"
            name="start-copying"
            prefix={<Icon name="arrowsDiagonal" />}
            onClick={onStartCopying}
          >
            Start copying
          </Button>
        )}
      </div>
    </div>
  )
}
