import { useStore } from 'effector-react'
import { closeMobileAd } from 'features/session/model'
import { $userProfile } from 'features/user'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'usehooks-ts'

import appStore from './img/app-store.png'
import appStoreWeb from './img/app-store.webp'

import googlePlay from './img/google-play.png'
import googlePlayWeb from './img/google-play.webp'

import qrCodeIDN from './img/qr-idn.png'
import qrCodeIDNWeb from './img/qr-idn.webp'

import qrCode from './img/qr.png'
import qrCodeWeb from './img/qr.webp'

import * as Styled from './styled'

export const MobileApp = () => {
  const { country } = useStore($userProfile)

  const [t] = useTranslation()
  const { width } = useWindowSize()

  const handleClose = () => {
    closeMobileAd()
  }

  return (
    <Styled.Wrapper>
      <div>
        <Styled.BannerTitle level={width >= 700 ? 1 : 4}>
          Download the Headway Mobile App
        </Styled.BannerTitle>
        <div>{t('The best way to trade is your way')}</div>
      </div>
      <div className="flex items-center">
        <Styled.Icons>
          <a
            href="https://apps.apple.com/au/app/headway-trading-app/id6449708113"
            title="App Store"
            target="_blank"
            rel="noreferrer"
          >
            <Styled.Icon src={appStore} alt="App Store" srcSet={appStoreWeb} />
          </a>
          <a
            href={
              country === 'IDN'
                ? 'https://play.google.com/store/apps/details?id=com.hwindo.application&referrer=PAbanner'
                : 'https://play.google.com/store/apps/details?id=com.headliner'
            }
            title="Google Play"
            target="_blank"
            rel="noreferrer"
          >
            <Styled.Icon srcSet={googlePlayWeb} src={googlePlay} alt="Google Play" />
          </a>
        </Styled.Icons>
        <Styled.QR
          srcSet={country === 'IDN' ? qrCodeIDNWeb : qrCodeWeb}
          src={country === 'IDN' ? qrCodeIDN : qrCode}
        />
      </div>
      <Styled.Close onClick={handleClose} />
    </Styled.Wrapper>
  )
}
