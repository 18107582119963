import { path } from 'libs/path'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import type { TraderStrategy } from 'services/copytrade/types'
import { AccountLine, BottomSheet } from 'ui/molecules'

import { setRemovedStrategy } from '../../model'
import { BackButton } from '../backButton'
import { CloseOrdersModal } from '../closeOrdersModal'
import { ShareModal } from '../shareModal'
import { UnpublishModal } from '../unpublishModal'
import * as Styled from './styled'

const MAX_TITLE_LENGTH = 70

const ActionsButtons = ({
  previewVisible = true,
  strategyID,
  onUnpublish,
  onShare,
}: {
  previewVisible?: boolean
  strategyID: string
  onUnpublish: () => void
  onShare: () => void
}) => {
  const [t] = useTranslation()

  return (
    <>
      <Styled.ActionButton
        type="button"
        highlighted
        icon="power"
        title={t('Stop publishing') as string}
        onClick={onUnpublish}
      />
      {previewVisible && (
        <Styled.ActionButton
          as={Link}
          to={path.copytrade.strategyForTrader(strategyID)}
          icon="arrow"
          title={t('Preview') as string}
        />
      )}
      <Styled.ActionButton
        type="button"
        icon="share"
        title={t('Share') as string}
        onClick={onShare}
      />
      <Styled.ActionButton
        as={Link}
        to={path.copytrade.editStrategy(strategyID)}
        icon="pencil"
        title={t('Edit') as string}
      />
    </>
  )
}

export const StrategyRow = ({
  strategyID,
  title,
  imageLink,
  copyableAccount,
  commission,
  description,
  isMobile,
  isFull,
}: TraderStrategy & { isMobile: boolean; isFull?: boolean }) => {
  const [t] = useTranslation()

  const [isShareModalVisible, setIsShareModalVisible] = useState(false)
  const [isCloseOrdersModalVisible, setIsCloseOrdersModalVisible] =
    useState(false)
  const [isUnpublishModalVisible, setIsUnpublishModalVisible] = useState(false)
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false)

  const toggleUnpublishModal = () =>
    setIsUnpublishModalVisible(!isUnpublishModalVisible)
  const toggleBottomSheet = () => setIsBottomSheetVisible(!isBottomSheetVisible)

  const handleUnpublish = () => {
    toggleUnpublishModal()
    setRemovedStrategy(title)

    setTimeout(() => {
      setRemovedStrategy('')
    }, 10000)
  }

  return (
    <>
      {isFull && (
        <Styled.TopWrapper>
          <BackButton className="me-auto" to={path.copytrade.traderEntry()} />
          <ActionsButtons
            previewVisible={false}
            strategyID={strategyID}
            onUnpublish={() => {
              if (copyableAccount.isExistOpenedPos) {
                setIsCloseOrdersModalVisible(true)
              } else {
                toggleUnpublishModal()
              }
            }}
            onShare={() => setIsShareModalVisible(true)}
          />
        </Styled.TopWrapper>
      )}
      <Styled.ContentContainer isExtended={isFull}>
        <Styled.TitleWrapper>
          <Styled.StrategyImage src={imageLink} />
          <Styled.StrategyTitle>
            {!isFull && title.length > MAX_TITLE_LENGTH
              ? `${title.slice(0, MAX_TITLE_LENGTH).trim()}...`
              : title}
          </Styled.StrategyTitle>
        </Styled.TitleWrapper>
        <div>
          <Styled.ColumnTitle>{t('Copying account')}</Styled.ColumnTitle>
          {/* @ts-expect-error */}
          <AccountLine {...copyableAccount} balance={undefined} />
        </div>
        <Styled.ComissionWrapper>
          <Styled.ColumnTitle>{t('Commission')}</Styled.ColumnTitle>
          <Styled.ComissionValue>${commission}</Styled.ComissionValue>
        </Styled.ComissionWrapper>
        {!isFull && (
          <Styled.ActionsWrapper>
            {isMobile ? (
              <>
                <BottomSheet
                  isOpen={isBottomSheetVisible}
                  onClose={toggleBottomSheet}
                  title="Strategy actions"
                >
                  <Styled.MobileAction
                    icon="pencil"
                    as={Link}
                    to={path.copytrade.editStrategy(strategyID)}
                  >
                    Edit
                  </Styled.MobileAction>
                  <Styled.MobileAction
                    icon="share"
                    type="button"
                    onClick={() => {
                      setIsBottomSheetVisible(false);
                      setIsShareModalVisible(true);
                    }}
                  >
                    Share
                  </Styled.MobileAction>
                  <Styled.MobileAction
                    icon="arrow"
                    as={Link}
                    to={path.copytrade.strategyForTrader(strategyID)}
                  >
                    Preview
                  </Styled.MobileAction>
                  <Styled.MobileAction
                    icon="power"
                    type="button"
                    highlighted
                    onClick={() => {
                      toggleBottomSheet()

                      if (copyableAccount.isExistOpenedPos) {
                        setIsCloseOrdersModalVisible(true)
                      } else {
                        toggleUnpublishModal()
                      }
                    }}
                  >
                    Stop publishing
                  </Styled.MobileAction>
                </BottomSheet>
                <Styled.ActionButton
                  type="button"
                  icon="dots"
                  title={t('Preview') as string}
                  onClick={toggleBottomSheet}
                />
              </>
            ) : (
              <ActionsButtons
                strategyID={strategyID}
                onUnpublish={() => {
                  if (copyableAccount.isExistOpenedPos) {
                    setIsCloseOrdersModalVisible(true)
                  } else {
                    toggleUnpublishModal()
                  }
                }}
                onShare={() => setIsShareModalVisible(true)}
              />
            )}
          </Styled.ActionsWrapper>
        )}
        {isFull && description && <Styled.Description text={description} />}
      </Styled.ContentContainer>

      <CloseOrdersModal
        isOpen={isCloseOrdersModalVisible}
        onClose={() => setIsCloseOrdersModalVisible(false)}
      />

      <UnpublishModal
        isOpen={isUnpublishModalVisible}
        onCancel={toggleUnpublishModal}
        strategyID={strategyID}
        onSuccess={handleUnpublish}
      />

      <ShareModal
        isOpen={isShareModalVisible}
        onClose={() => setIsShareModalVisible(false)}
        strategyID={strategyID}
      />
    </>
  )
}
