import React, { useState, useMemo } from 'react';

import * as S from './styled';

interface Props {
  isVerified: boolean;
}


export const CardStatus: React.FC<Props> = ({ isVerified }) => {
  return (
    <>
      <S.VerifiedStatus  isVerified={ isVerified }>
        <S.Text
          level={2}
          className={`${isVerified ? '!text-eucalyptus.text' : '!text-opal.text'}`}
        >
          {isVerified ? 'Verified' : 'Unverified'}
        </S.Text>
      </S.VerifiedStatus>
    </>
  )
}
