import Tippy from '@tippyjs/react'
import styled, { css } from 'styled-components/macro'

import check from '../../images/check-icon.svg'
import cross from '../../images/close-icon.svg'
import notFound from '../../images/empty-list.svg'

const chevron = (isDisabled?: boolean) => {
  return `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="${
    isDisabled ? '%23B3B3B3' : '%23666'
  }" fill-rule="evenodd" d="M17.724 15.348a.9.9 0 0 1-1.273-.025L12 10.598l-4.451 4.725a.9.9 0 1 1-1.298-1.247l5.1-5.4a.9.9 0 0 1 1.298 0l5.1 5.4a.9.9 0 0 1-.025 1.272Z" clip-rule="evenodd"/></svg>`
}

export const Tooltip = styled(Tippy)`
  background-color: var(--color-gray-100);
  border-radius: 8px;

  .tippy-content {
    padding: 0;
  }
`

export const Button = styled.button`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: center center;
`

export const ChevronButton = styled(Button)<{ isOpened: boolean }>`
  transform: ${({ isOpened }) => (isOpened ? 'rotate(0)' : 'rotate(180deg)')};
  transition: transform var(--transition);
`

export const Wrapper = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  padding: 6px 10px;
  display: inline-flex;
  gap: 6px;
  align-items: center;
  font-size: var(--body-font-size-medium);
  line-height: 16px;
  font-weight: 500;
  white-space: nowrap;
  color: ${({ isActive, isDisabled }) =>
    isActive
      ? 'var(--color-gray-700)'
      : isDisabled
      ? 'var(--color-gray-500)'
      : 'var(--color-gray-600)'};
  background-color: ${({ isActive }) =>
    isActive ? 'var(--color-gray-400)' : 'var(--color-gray-300)'};
  border-radius: 4px;

  ${ChevronButton} {
    background-image: ${({isDisabled}) => `url('${chevron(isDisabled)}')`};
  }
`

export const CrossButton = styled(Button)`
  width: 20px;
  background-image: url(${cross});
`

export const OptionsWrapper = styled.div`
  padding: 8px 0;
`

export const Option = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  transition: color var(--transition);

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: opacity var(--transition);
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px auto;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 500;
      color: var(--color-bronze-500);

      &::after {
        opacity: 1;
      }
    `}
`

export const NotFoundBlock = styled.div`
  padding-top: 16px;
  padding-bottom: 26px;
  display: grid;
  justify-items: center;
  gap: 8px;
  text-align: center;

  &::before {
    content: '';
    width: 80px;
    height: 80px;
    background-image: url(${notFound});
    background-repeat: no-repeat;
    background-size: 80px auto;
    background-position: center center;
  }
`

export const NotFoundTitle = styled.div`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;
`

export const NotFoundText = styled.div`
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  color: var(--color-gray-600);
`
