import { useStore } from 'effector-react'
import { path } from 'libs/path'
import { yup } from 'libs/validators'
import * as React from 'react'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Button, Field, Form, Icon2, Text } from 'ui/atoms'

import { AuthLayout, AuthSectionTitle } from '../../layout'
import { useEmailConfirmPage } from './hooks'
import { $resendTimer, $timerInitial, submitForm, submitRepeat } from './model'

const initial = {
  code: '',
}

export const RegisterEmailConfirm = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [countOfRequestToEmail, setCountOfRequestToEmail] = useState(0)
  const { userProfile, timer, loading, error, resendCodeLoading } =
    useEmailConfirmPage()

  if (
    searchParams.get('code') &&
    userProfile.email &&
    countOfRequestToEmail < 1
  ) {
    submitForm({
      code: searchParams.get('code') || '',
    })
    setCountOfRequestToEmail((prev) => prev + 1)

    return null
  }

  return (
    <AuthLayout type="register">
      <AuthSectionTitle level={2}>Email verification</AuthSectionTitle>
      <ConfirmDescription>
        <ConfirmDescriptionText>
          The verification email is sent to
        </ConfirmDescriptionText>{' '}
        <Email>
          <span className="break-all">{userProfile.email.split('@')[0]}</span>@
          {userProfile.email.split('@')[1]}
        </Email>
        <br />
        <ConfirmDescriptionText>
          Click the link in the email or enter the 6-digit code below. The code
          is valid for 30 minutes.
        </ConfirmDescriptionText>
      </ConfirmDescription>
      <ConfirmForm>
        <Form
          initial={initial}
          onSubmit={submitForm}
          validation={yup.code}
          error={error?.form}
        >
          <ConfirmCodeField
            name="code"
            placeholder="Email verification code"
            autofocus
            suffix={resendCodeLoading ? null : <ResendTimer />}
            suffixType="button"
            error={error?.fields?.code}
          />
          <ConfirmSubmitButton
            name="confirm-code"
            size="large"
            design="primary"
            type="submit"
            loading={loading}
            disabled={loading}
          >
            Submit
          </ConfirmSubmitButton>
        </Form>
      </ConfirmForm>
      {timer === 0 && (
        <ResendCodeButton
          name="navigate-register-verificate-repeat"
          size="large"
          design="tertiary"
          onClick={() => {
            navigate(path.register.email.confirmRepeat())
          }}
        >
          Didn’t receive the Code?
        </ResendCodeButton>
      )}
    </AuthLayout>
  )
}

const ResendTimer = () => {
  const timer = useStore($resendTimer)
  const timerInitial = useStore($timerInitial)
  const isRunning = Boolean(timer) && timer <= timerInitial

  return (
    <Timer>
      {isRunning && <ConfirmTimerCount>{timer}</ConfirmTimerCount>}
      <ConfirmTimerIcon
        name={isRunning ? 'clock' : 'arrowPath'}
        data-running={isRunning}
        onClick={
          isRunning
            ? undefined
            : () => {
                submitRepeat()
              }
        }
      />
    </Timer>
  )
}

const ConfirmCodeField = styled(Field)`
  width: 100%;
`

const Timer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const ConfirmTimerCount = styled.span`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  color: var(--color-sunray-500);
  font-weight: 500;
  margin-inline-end: 10px;
`

const ConfirmTimerIcon = styled(Icon2)`
  color: var(--color-sunray);

  &[data-running='true'] {
    cursor: auto;
  }
`

const ConfirmForm = styled.div`
  margin-top: 32px;
`

const ConfirmSubmitButton = styled(Button)`
  background-color: var(--color-gray-200);
  margin-top: 32px;
  width: 100%;
`

const ConfirmDescription = styled.div`
  margin-top: 8px;
`

const ConfirmDescriptionText = styled(Text)`
  color: var(--color-gray-600);
  display: inline;
`

const Email = styled.span`
  display: inline;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  color: var(--color-gray-600);
`

const ResendCodeButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`
