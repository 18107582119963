import { combine, createEvent, createStore, restore, sample } from 'effector'
import { $copyingStrategy, setNewAccountForCopying } from 'pages/copytrade/model'

import { $params, toPath } from '../../../features/router'
import { fx } from '../../../libs'
import { path } from '../../../libs'
import { AccountResponse } from '../types'
import { mapAccountType } from './map'
import { $activePlatform } from './platform'
import {
  $activeCurrency,
  $activeFixRate,
  $activeLeverage,
  $activeSwapFree,
  $initialBalance,
} from './settings/model'
import { submitCreateAccount } from './settings/model'
import { $activeTariffName } from './tariff'
import { setActiveTariffName } from './tariff/model'
import {
  AccountCreate,
  AccountLeverage,
  AccountPlatform,
  AccountPlatformName,
  AccountSettings,
  AccountTariff,
  AccountTariffName,
  AccountTariffResponse,
  AccountType,
  AccountTypeClient,
} from './types'

export const accountCreateMounted = createEvent()

export const setAccountType = createEvent<AccountType>()
export const $accountType = restore<AccountType>(setAccountType, 'PAAT_INVALID')

export const getTariffs = fx<void, AccountTariffResponse>({
  method: 'GET',
  url: `/account/v1/tariffs`,
})

sample({
  clock: getTariffs.done,
  fn: (done) => {
    const accountType =
      (done.params.query?.accountType as AccountType) || 'PAAT_INVALID'
    return accountType
  },
  target: $accountType,
})

// set default tariff name

sample({
  clock: getTariffs.doneData,
  fn: (doneData) => doneData.body.defaultTariff,
  target: $activeTariffName,
})

// get tariffs on mount

sample({
  source: $params,
  clock: accountCreateMounted,
  fn: (params) => {
    const accountType = (params?.accountType ||
      'PAAT_INVALID') as AccountTypeClient
    return {
      query: {
        accountType: mapAccountType[accountType],
      },
    }
  },
  target: getTariffs,
})

export const defaultAccountSettings: AccountSettings = {
  leverages: {
    items: [],
    default: '1',
  },
  currencies: {
    items: [],
    default: 'USD',
  },
  swapFree: {
    default: false,
  },
  initialBalance: {
    default: 0,
  },
}

// set default tarrifs

export const $tariffs = createStore<AccountTariff[]>([])

sample({
  clock: getTariffs.doneData,
  fn: (doneData) => {
    return doneData.body.items
  },
  target: $tariffs,
})

// set default settings

export const $activeSettings = createStore<AccountSettings>(
  defaultAccountSettings,
)

sample({
  clock: getTariffs.doneData,
  fn: (doneData) => {
    const tariffs = doneData.body.items
    const defaultTariffName = doneData.body.defaultTariff

    const defaultPlatformName = tariffs.find(
      (t) => t.name === defaultTariffName,
    )?.defaultPlatform

    const defaultSettings = tariffs
      .find((t) => t.name === defaultTariffName)
      ?.platforms.find(
        ({ accountPlatform }) => accountPlatform === defaultPlatformName,
      )?.accountSettings

    return defaultSettings || defaultAccountSettings
  },

  target: $activeSettings,
})

// set default platforms

export const $activePlatforms = createStore<AccountPlatform[]>([])

sample({
  clock: getTariffs.doneData,
  fn: (doneData) => {
    const tariffs = doneData.body.items
    const defaultTariffName = doneData.body.defaultTariff

    const defaultPlatforms = tariffs.find(
      (t) => t.name === defaultTariffName,
    )?.platforms

    return defaultPlatforms || []
  },
  target: $activePlatforms,
})

// set default platform

sample({
  clock: getTariffs.doneData,
  fn: (doneData) => {
    const tariffs = doneData.body.items
    const defaultTariffName = doneData.body.defaultTariff

    const defaultPlatform = tariffs.find(
      (t) => t.name === defaultTariffName,
    )?.defaultPlatform

    return defaultPlatform || 'PAAP_MT4'
  },
  target: $activePlatform,
})

// set default leverage

sample({
  source: $activeSettings,
  fn: (activeSettings) => activeSettings.leverages.default,
  target: $activeLeverage,
})

// set default currency

sample({
  source: $activeSettings,
  fn: (activeSettings) => activeSettings.currencies.default,
  target: $activeCurrency,
})

// set default swap free

sample({
  source: $activeSettings,
  fn: (activeSettings) => activeSettings.swapFree?.default || false,
  target: $activeSwapFree,
})

// set default fix rate

sample({
  source: $activeSettings,
  fn: (activeSettings) => {
    return (
      activeSettings.currencies.items.find(
        (c) => c.currency === activeSettings.currencies.default,
      )?.fixRate?.default || false
    )
  },
  target: $activeFixRate,
})

// create account

export const createAccount = fx<AccountCreate, AccountResponse>({
  method: 'POST',
  url: `/account/v1/account`,
})

sample({
  source: combine(
    $accountType,
    $activeTariffName,
    $activePlatform,
    $activeLeverage,
    $activeCurrency,
    $activeFixRate,
    $activeSwapFree,
    $initialBalance,
  ),
  clock: submitCreateAccount,
  fn: (source) => {
    const [
      accountType,
      tariffName,
      accountPlatform,
      leverage,
      currency,
      isFixRate,
      isSwapFree,
      initialBalance,
    ] = source

    return {
      body: {
        accountType,
        tariffName,
        accountPlatform,
        leverage: leverage.replace('1:', '') as AccountLeverage,
        currency,
        isFixRate,
        isSwapFree,
        initialBalance,
      },
    }
  },
  target: createAccount,
})

// set active tariff

const $activeTariff = sample({
  source: $tariffs,
  clock: setActiveTariffName,
  fn: (tariffs, activeTariffName) => {
    const activeTariff = tariffs.find((t) => t.name === activeTariffName)
    const defaultTariff: AccountTariff = {
      name: 'cent',
      defaultPlatform: 'PAAP_MT4',
      accountType: 'PAAT_DEMO',
      isDisabled: false,
      disableReason: '',
      platforms: [],
    }

    return activeTariff || defaultTariff
  },
})

// set active platforms

sample({
  source: $activeTariff,
  fn: (activeTariff) => {
    return activeTariff.platforms || []
  },
  target: $activePlatforms,
})

// on success redirect to accounts or start copying

sample({
  source: $copyingStrategy,
  clock: createAccount.done,
  fn: (copyingStrategy, done) =>
    copyingStrategy
      ? path.copytrade.startCopying.accountSettings()
      : path.accounts.details(done.result.body.accountInfo.accountID),
  target: toPath,
})

sample({
  source: $copyingStrategy,
  clock: createAccount.done,
  filter: (copyingStrategy) => !!copyingStrategy,
  fn: (_, done) => done.result.body.accountInfo,
  target: setNewAccountForCopying
})

// for correct steps

export const setRecordedAccountData = createEvent<{
  activeTariffName?: AccountTariffName
  activePlatform?: AccountPlatformName
}>()

export const $recordedAccountData = restore(setRecordedAccountData, {})

// set active settings

sample({
  source: $activeTariff,
  clock: setRecordedAccountData,
  fn: (activeTariff, { activePlatform }) => {
    return (
      activeTariff.platforms.find((p) => p.accountPlatform === activePlatform)
        ?.accountSettings || defaultAccountSettings
    )
  },
  target: $activeSettings,
})
