import { createEvent, createStore, sample } from 'effector'

import { toPath } from '../../../../features/router/model'
import {
  Session,
  setAccessToken,
  setRefreshToken,
} from '../../../../features/session'
import { $userProfile, setUserProfile } from '../../../../features/user'
import { ClientError, fx, mapResponseError, path } from '../../../../libs'
import { RegisterByEmail } from './types'

export const mounted = createEvent()

export const submitForm = createEvent<RegisterByEmail>()

export const registerByEmail = fx<RegisterByEmail, Session>({
  method: 'POST',
  url: '/auth/v1/register-by-email',
})

sample({
  clock: submitForm,
  fn: (emailForm) => ({ body: emailForm }),
  target: registerByEmail,
})

// redirect to password if email is valid

sample({
  clock: registerByEmail.done,
  fn: () => path.register.email.password(),
  target: toPath,
})

// write session tokens if success

sample({
  clock: registerByEmail.doneData,
  fn: (doneData) => doneData.body.identificationResult.accessToken,
  target: setAccessToken,
})

sample({
  clock: registerByEmail.doneData,
  fn: (doneData) => doneData.body.identificationResult.refreshToken,
  target: setRefreshToken,
})

// save user information

sample({
  source: $userProfile,
  clock: submitForm,
  fn: (userProfile, { email, firstName, lastName }) => {
    userProfile.email = email
    userProfile.firstName = firstName
    userProfile.lastName = lastName

    return userProfile
  },
  target: setUserProfile,
})

// handle errors

export const $error = createStore<ClientError<RegisterByEmail> | null>(null)

$error
  .on(registerByEmail.failData, (_, failData) =>
    mapResponseError<RegisterByEmail>(failData.body),
  )
  .reset([mounted, submitForm])
