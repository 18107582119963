import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ZendeskAPI } from 'react-zendesk';

import { Button, Icon2, Text, Title } from 'ui/atoms';
import { FormInput } from 'ui/molecules';

import questionSrc from 'styles/img/question.png';
import questionSrcWeb from 'styles/img/question.webp';
import { ResendTimer } from './Timer';


interface Props {
  onBack: () => void;
  onCheckCode: (code: string) => void;
  onResendCode: () => Promise<void>;
  isLoading: boolean;
  isCodeError: boolean;
}

export const WithdrawalOtp: React.FC<Props> = ({ onBack, onCheckCode, isLoading, onResendCode, isCodeError }) => {

  const { register, handleSubmit, formState, setError, clearErrors } = useForm<{ code: string }>({
    defaultValues: {
      code: '',
    },
    mode: 'onTouched',
  });

  const onSbmCode = async (fields: { code: string }) => {
    onCheckCode(fields.code);
  };

  const codeField = register('code', {
    minLength: { value: 6, message: 'Must be exactly 6 characters' }
  });

  const onHandleContactSupport = () => {
    ZendeskAPI('messenger', 'open')
  };

  useEffect(() => {
    if (isCodeError) setError('code', { message: 'invalid code. Please try again', type: 'invalid' })
    else clearErrors('code')
  
  }, [isCodeError]);
  
  
  return (
    <div className='flex flex-col items-center justify-between'>
      <div className='flex flex-col items-center my-[4.8rem] '>
        <picture>
          <source srcSet={questionSrcWeb} type="image/webp" className="w-[8rem]" />
          <img src={questionSrc} alt="question" className='w-[8rem]' />
        </picture>
        <div className='flex flex-col gap-[2.4rem] mt-[4rem] items-center'>
          <Title level={2} className='text-center'>Withdrawal confirmation</Title>
          <Text className='lg:w-[68rem] w-full text-center'>
              To proceed with the withdrawal request, please enter the verification code sent to your registered 
              email address. The code is valid for 30 minutes.
          </Text>
          <FormInput
            placeholder="Enter code"
            autofocus
            autoComplete='off'
            suffix={<ResendTimer resendCode={ onResendCode } isError={ isCodeError } />}
            isTouchedForm={ formState.dirtyFields?.code }
            className='lg:!w-[424px] w-full'
            maxLength={6}
            suffixType='button'
            {...codeField}
            errorMessage={ formState.errors?.code?.message }
          />
        </div>
        <div className='mt-[4.8rem] flex gap-[2rem] !relative'>
          <Button design='secondary'
            className='w-[16rem]'
            name='back'
            onClick={onBack}
          >
            <Icon2 name='arrowLeft' className='me-[4px]'/>
            Back
          </Button>
          <Button
            design='primary'
            className='md:w-[16rem] w-[13rem]'
            name='sbm'
            disabled={ isLoading }
            loading={ isLoading }
            onClick={handleSubmit(onSbmCode)}
          >
            Continue
          </Button>
        </div>
      </div>

      <div className='flex lg:gap-3 mt-[4rem flex-col lg:flex-row'>
        <Text className='!font-medium'>Didn’t receive the email?</Text>
        <Text
          className='!font-medium !text-sunray.500 cursor-pointer'
          onClick={onHandleContactSupport}
        >
          Contact support
        </Text>
      </div>
    </div>
  )
}