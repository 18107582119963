import { createEvent, createStore } from 'effector';
import { ProcessingProvider, PaymentMethod } from 'services/money/get/types';


interface Store {
  provider: ProcessingProvider;
  chosenDestenation: string;
  gatewayId: string;
  paymentMethodType?: PaymentMethod,
}

const default_store = null;
export const resetWithdrawalEvent = createEvent();
export const setWithdrawalEvent = createEvent<Store>();
export const $customWithdrawalStore = createStore<Store | null>(default_store).reset(resetWithdrawalEvent);

$customWithdrawalStore.on(setWithdrawalEvent, (_, payload) => payload);