import React, { useCallback, useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CardStatus } from 'pages/money/molecules/cardStatus/CardStatus';

import { useIsMobile } from 'libs/hooks/useIsMobile';
import { WithdrawalCardItem } from 'services/payments/get/types';
import { Money, Text } from 'ui/atoms';

import * as S from './styled';

interface Props {
  title: string;
  dataList?: WithdrawalCardItem[];
  placeholder?: string;
  value?: WithdrawalCardItem;
  onSelectCard?: (card: WithdrawalCardItem) => void;
}

export const CardsList: React.FC<Props> = ({ title, onSelectCard, dataList = [], value, placeholder }) => {
  const [chooseCard, setChooseCard] = useState(value);

  const isMobile = useIsMobile();

  const getMaskLabel = useCallback((maskBe: string) => {
    return `${maskBe.slice(0, 4)} XXXX XXXX ${maskBe.slice(-4)}`;
  }, []);

  const options = useMemo(() => {
    if (!dataList.length) return [];

    return dataList.map(card => ({
      label: getMaskLabel(card.mask),
      value: card.id,
    }));
  }, [dataList]);

  return (
    <div className="mb-[2.4rem]">
      <S.SelectWrapper
        value={chooseCard?.id}
        name="wallets-select"
        autoComplete="off"
        label={title}
        placeholder={placeholder || "Choose card"}
        maxHeight={410}
        modalTitle='Account/wallet'
        showOptions={false}
        // onBlur={cardField.onBlur}
        // @ts-ignore
        prefix={
          chooseCard?.logo && (
            <LazyLoadImage
              src={chooseCard.logo}
              width={32}
              height={32}
              alt="card-logo"
              className="h-[3.2rem] w-[3.2rem]"
            />
          )
        }
        suffix={
          chooseCard?.id && <CardStatus isVerified={chooseCard?.isVerified} />
        }
        customContent={
          <S.SelectContent>
            <div>
              {dataList.map((card) => (
                <S.SelectOption
                  key={`${card.id}_${card.currency}`}
                  onClick={() => {
                    setChooseCard(card)
                    onSelectCard?.(card)
                  }}
                >
                  <div className="flex">
                    {card.logo && (
                      <LazyLoadImage
                        src={card.logo}
                        width={32}
                        height={32}
                        alt="card-logo"
                        className="h-[3.2rem] w-[3.2rem]"
                      />
                    )}

                    <div className="flex-1 ms-[1.2rem]">
                      <div className="flex justify-between">
                        <Text level={2}>{getMaskLabel(card.mask)}</Text>
                        <CardStatus isVerified={card.isVerified} />
                      </div>

                      <div className={`flex mt-[4px] ${ isMobile ? 'justify-between' : ''}`}>
                        <Text level={2} className="mb-[4px]">
                          Withdrawal limit
                        </Text>
                        &nbsp;
                        <Text
                          withTranslate={false}
                          level={2}
                          className="!font-medium"
                        >
                          <Money
                            value={+card.balance}
                            currency={card.currency}
                          />
                        </Text>
                      </div>
                    </div>
                  </div>
                </S.SelectOption>
              ))}
            </div>
          </S.SelectContent>
        }
        options={options}
      />

      {chooseCard?.balance && (
        <div className="flex justify-between max-w-[46rem] mt-[8px]">
          <Text level={2} className="mb-[4px]">
            Withdrawal limit
          </Text>
          <Text withTranslate={false} level={2} className="!font-medium">
            <Money value={+chooseCard.balance} currency={chooseCard.currency} />
          </Text>
        </div>
      )}
    </div>
  )
}
