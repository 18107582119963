import { useWindowSize } from '@uidotdev/usehooks'
import { path } from 'libs/path'
import { useTranslation } from 'react-i18next'

import * as Styled from './styled'

export const IbBanner = ({
  isDisabled,
  onClose,
}: {
  isDisabled: boolean
  onClose: () => void
}) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobile = width && width < 681

  return (
    <Styled.Wrapper>
      <Styled.CloseButton disabled={isDisabled} onClick={onClose} />
      <Styled.Title>{t('Headway Golden May')}</Styled.Title>
      <Styled.Text>
        {t('Celebrate our birthday in May. Motivate clients to win prizes.')}
      </Styled.Text>
      <Styled.JoinButton
        name="join-now"
        to={path.contest.traders()}
        design="secondary"
        size={isMobile ? 'small' : 'large'}
        disabled={isDisabled}
      >
        Join now
      </Styled.JoinButton>
    </Styled.Wrapper>
  )
}
