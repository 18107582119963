import React, { DetailedHTMLProps, TdHTMLAttributes } from 'react'


interface Props extends DetailedHTMLProps<TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>{
  children?: React.ReactNode | undefined;
}

export const Td: React.FC<Props> = ({ children, className = '', ...rest }) => {
  return (
    <td
      className={`px-[20px] py-[6px] ${className}`}
      align='left'
      {...rest}
    >
      {children}
    </td>
  )
}
