import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export const GET_IB_LANDINGS_KEY = ['ib-landings']

const getLandings = async (language: string) => {
  const res = await axios.get<{ items: { title: string; link: string }[] }>(
    '/ib-materials/v1/landings',
    {
      params: { 'filter.language': language },
      paramsSerializer: { indexes: true },
    },
  )

  return res.data.items
}

export const useGetLandings = (language: string) => {
  return useQuery({
    queryKey: [GET_IB_LANDINGS_KEY, language],
    queryFn: () => getLandings(language),
    keepPreviousData: true,
    enabled: false
  })
}
