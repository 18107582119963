import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { AccountCreate } from 'pages/accounts/create/types'
import {
  AccountPlatformName,
  AccountTariffName,
} from 'pages/accounts/create/types'
import { Account } from 'services/accounts/types'
import { axios } from 'services/axios'

const addAccount = async (account: Account) => {
  const res = await axios.post<
    {},
    AxiosResponse<{ accountInfo: Account }>,
    AccountCreate
  >('/account/v1/account', {
    accountType: 'PAAT_REAL',
    tariffName: account.tariffName as AccountTariffName,
    accountPlatform: account.platform as AccountPlatformName,
    leverage: account.leverage,
    currency: account.currency,
    isFixRate: account.isFixRate,
    isSwapFree: account.isSwapFree,
    initialBalance: 0,
    isIgnoreOptions: true,
  })

  return res.data.accountInfo
}

export const useAddAccount = () => {
  return useMutation({
    mutationFn: addAccount,
  })
}
