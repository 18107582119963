import { createEvent, restore } from 'effector'

import { AccountLeverage } from '../types'

export const submitCreateAccount = createEvent()

export const setActiveCurrency = createEvent<string>()
export const $activeCurrency = restore<string>(setActiveCurrency, 'USD')

export const setActiveFixRate = createEvent<boolean>()
export const $activeFixRate = restore<boolean>(setActiveFixRate, false)

export const setActiveLeverage = createEvent<AccountLeverage>()
export const $activeLeverage = restore<AccountLeverage>(setActiveLeverage, '1')

export const setActiveSwapFree = createEvent<boolean>()
export const $activeSwapFree = restore<boolean>(setActiveSwapFree, false)

export const setInitialBalance = createEvent<number>()
export const $initialBalance = restore<number>(setInitialBalance, 15000)
