import React, { lazy, Suspense } from 'react';

import { Spinner2 } from 'ui/atoms';

const TabLazy = lazy(() => import('./Tab'));

export const StatisticsTab = () => {
  return (
    <Suspense fallback={<Spinner2 size='large'/>}>
      <TabLazy />
    </Suspense>
  )
}
