import styled, { css } from 'styled-components/macro'
import { Plarform, Tariff, Wrapper } from 'ui/molecules/accountLines/styled'

import { icons } from '../../images/icons'
import { StrategyDescription } from '../strategyDescription'

export const ComissionWrapper = styled.div`
  padding-inline-start: 12px;

  @media (max-width: 1330px) {
    padding-inline-start: 0;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StrategyTitle = styled.div`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;

  @media (max-width: 599px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-small);
  }
`

export const ContentContainer = styled.div<{ isExtended?: boolean }>`
  border: 1px solid var(--color-gray-300);
  padding: 15px 20px;
  display: grid;
  grid-template-columns: 1fr 268px 120px auto;
  gap: 28px;
  align-items: center;
  border-radius: 8px;

  @media (max-width: 1330px) {
    gap: 16px;
  }

  @media (max-width: 1240px) {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  ${Wrapper} {
    font-size: var(--body-font-size-regular);
    line-height: var(--body-line-height-regular);
    font-weight: 500;

    @media (max-width: 599px) {
      font-size: var(--body-font-size-medium);
      line-height: var(--body-line-height-medium);
    }

    ${Plarform} {
      margin-inline-start: 12px;
      margin-inline-end: 8px;
      font-size: var(--body-font-size-medium);
      line-height: var(--body-line-height-medium);

      @media (max-width: 599px) {
        margin-inline-start: 6px;
        margin-inline-end: 4px;
      }
    }

    ${Tariff} {
      font-size: var(--body-font-size-medium);
      line-height: var(--body-line-height-medium);
    }
  }

  ${({ isExtended }) =>
    isExtended &&
    css`
      grid-template-columns: 1fr 268px 160px;
      gap: 24px 40px;

      @media (max-width: 1280px) {
        gap: 24px 40px;
      }

      @media (max-width: 820px) {
        grid-template-columns: 160px 1fr;
        gap: 16px 48px;
      }

      ${ComissionWrapper} {
        padding-inline-start: 0;
      }

      ${TitleWrapper} {
        @media (max-width: 820px) {
          margin-bottom: 4px;
          grid-column: span 2;
        }
      }

      ${StrategyTitle} {
        font-size: 20px;
        line-height: 25px;

        @media (max-width: 599px) {
          font-size: var(--body-font-size-medium);
          line-height: var(--body-line-height-medium);
        }
      }

      ${StrategyImage} {
        @media (max-width: 599px) {
          width: 48px;
          height: 48px;
        }
      }
    `}
`

export const TopWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`

export const StrategyImage = styled.img`
  flex-shrink: 0;
  margin-right: 12px;
  width: 56px;
  height: 56px;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;

  @media (max-width: 599px) {
    width: 40px;
    height: 40px;
  }
`

export const ColumnTitle = styled.div`
  margin-bottom: 6px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-small);
  font-weight: 500;
  color: var(--color-gray-600);

  @media (max-width: 599px) {
    margin-bottom: 4px;
    font-size: var(--body-font-size-small);
  }
`

export const ComissionValue = styled.div`
  line-height: 20px;
  font-weight: 500;

  @media (max-width: 599px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const ActionsWrapper = styled.div`
  display: flex;

  @media (max-width: 1240px) {
    margin-inline-start: auto;
    grid-column: 2;
    grid-row: 1;
  }
`

export const ActionButton = styled.button<{
  highlighted?: boolean
  icon: 'power' | 'pencil' | 'arrow' | 'share' | 'dots'
}>`
  margin-right: ${({ highlighted }) => (highlighted ? '16px' : '8px')};
  width: 32px;
  height: 32px;
  transition: background-color var(--transition);
  border-radius: 50%;
  background-color: ${({ highlighted }) =>
    highlighted ? 'var(--color-opal-100)' : 'var(--color-gray-200)'};
  background-image: url(${({ icon }) => icons[icon]});
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: center center;

  &:hover {
    background-color: ${({ highlighted }) =>
      highlighted ? 'var(--color-opal-200)' : 'var(--color-gray-300)'};
  }

  &:last-child {
    margin-right: 0;
  }
`

export const MobileAction = styled.button<{
  highlighted?: boolean
  icon: 'power' | 'pencil' | 'arrow' | 'share' | 'dots'
}>`
  padding-inline-start: 36px;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  color: ${({ highlighted }) =>
    highlighted ? 'var(--color-opal-600)' : 'var(--color-gray-700)'};
  background-image: url(${({ icon }) => icons[icon]});
  background-repeat: no-repeat;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'right center' : 'left center'};
  background-size: 24px auto;
`

export const Description = styled(StrategyDescription)`
  grid-column: span 3;

  @media (max-width: 820px) {
    grid-column: span 2;
  }
`
