import { createEvent, restore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { fx } from 'libs'
import { service } from 'services'

import { getAccount } from '../model'
import { patchAccountName } from '../rename/model'
import { Account } from '../types'
import {
  AccountLeverageResponse,
  PatchAccountLeverageParams,
  PatchAccountNameParams,
} from './types'

export const accountsListMounted = createEvent()

export const closeArchiveAlert = createEvent<boolean>()
export const $archiveAlertVisible = restore(closeArchiveAlert, true)

export const setActiveAccountId = createEvent<string>()
export const $activeAccountId = restore<string>(setActiveAccountId, '')

export const accountRestored = createEvent()

// leverage

export const getLeverage = fx<PatchAccountNameParams, AccountLeverageResponse>({
  method: 'GET',
  url: `/account/v1/account/available-leverages`,
})

export const $leverage = restore(
  getLeverage.doneData.map((d) => d.body),
  { current: '1', list: [] },
)

sample({
  clock: setActiveAccountId,
  fn: (accountID) => {
    return {
      query: {
        accountID,
      },
    }
  },
  target: getLeverage,
})

export const sumbmitPatchLeverage = createEvent<Pick<Account, 'accountID'>>()

export const patchLeverage = fx<
  PatchAccountLeverageParams,
  AccountLeverageResponse
>({
  method: 'PATCH',
  url: `/account/v1/account/leverage`,
})

export const setNewLeverage = createEvent<string>()

export const $newLeverage = restore<string>(setNewLeverage, '')

export const resetMSG = createEvent()

export const $msg = restore<string>(
  patchLeverage.failData.map((data) => {
    return data.body.details?.msg || ''
  }),
  null,
).reset(resetMSG)

sample({
  source: $newLeverage,
  clock: sumbmitPatchLeverage,
  fn: (leverage, { accountID }) => {
    return {
      body: {
        accountID,
        leverage,
      },
    }
  },
  target: patchLeverage,
})

sample({
  clock: patchLeverage.done,
  fn: (params) => {
    return {
      query: {
        accountID: params.params.body?.accountID || '',
      },
    }
  },
  target: [getAccount, getLeverage, setNewLeverage.prepend(() => '')],
})

sample({
  clock: patchLeverage.done,
  fn: () => ({}),
  target: [service.accounts.getAccounts],
})

sample({
  clock: getLeverage.doneData,
  fn: (doneData) => doneData.body.current,
  target: setNewLeverage,
})

// update accounts after changes

sample({
  clock: [accountsListMounted, patchAccountName.done, accountRestored],
  fn: () => ({}),
  target: service.accounts.getAccounts,
})

// close alerts

sample({
  clock: closeArchiveAlert,
  fn: () => false,
  target: $archiveAlertVisible,
})

// sync local storage

persist({
  store: $archiveAlertVisible,
  key: 'archiveAlertVisible',
})
