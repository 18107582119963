import React, { PropsWithChildren, useEffect, useRef } from 'react';

import styled from 'styled-components/macro';

interface Props extends PropsWithChildren {
  className?: string;
}

const END_OF_SCROLL_DIFERENCE = 10;

// Добавляет размытые края на элементах где можно скролить 
// При позиции скрола меняется эфект блюра
export const ScrollWithBlur: React.FC<Props> = ({ className, children }) => {
  const scrollElemRef = useRef<HTMLDivElement>(null);

  const onScrollFn = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft, classList, scrollWidth, clientWidth  } = e.target as HTMLDivElement;
    
    classList?.remove('initialScrollSides');
    classList?.remove('allScrollSides');
    classList?.remove('endScrollSides');
    
    if (scrollLeft !== 0) {
      classList?.add('allScrollSides');
    } else {
      classList?.add('initialScrollSides')
    }

    const scrollEndPos = Math.ceil(scrollWidth - scrollLeft) - clientWidth;
    
    // End of right scroll
    if (scrollEndPos >= 0 && scrollEndPos < END_OF_SCROLL_DIFERENCE) {
      classList?.add('endScrollSides');
    }
  }

    // Стартовый скролл есть или нет
    useEffect(() => {
      const elem = scrollElemRef.current;
      if (!elem) return;
  
      const { clientWidth, scrollWidth, classList } = elem;
      if (clientWidth === scrollWidth) {
        return;
      } else {
        classList?.add('initialScrollSides')
      }
    }, [])

  return (
    <NoBaseScroll ref={scrollElemRef} className={className}  onScroll={onScrollFn}>
      {children}
    </NoBaseScroll>
  )
}


const NoBaseScroll = styled.div`
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
      overflow-y: hidden;
    }
`;