import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export const GET_PRODUCT_LANGUAGES = ['product-languages']

const getProductLanguages = async () => {
  const res = await axios.get<{
    items: { product: string; languages: string[] }[]
  }>('/dictionary/v1/languages-by-products')

  return res.data.items.find((item) => item.product === 'headway')?.languages
}

export const useGetProductLanguages = () => {
  return useQuery({
    queryKey: GET_PRODUCT_LANGUAGES,
    queryFn: getProductLanguages,
  })
}
