import React from 'react';
import { Button, Icon2 } from 'ui/atoms';
import { FramePraxis } from '../WithdrawalPage/framePraxis/FramePraxis';

type Props = {
  url: string;
  resetUrl: () => void;
}

export const PraxisForm = ({ url, resetUrl }: Props) => {
  return (
    <>
      <FramePraxis url={ url } />
      <div className='w-full flex justify-end mt-[4rem]'>
        <Button
          name='backButton'
          design='secondary'
          className='w-[16rem]'
          onClick={ resetUrl }
        >
          <Icon2 name='arrowLeft' className='me-3'/>
          Back
        </Button>
      </div>
    </>
  )
}
