import { combine, createEvent, createStore, restore, sample } from 'effector'

import { service } from '../../../services'

export const transactionsMounted = createEvent()

export const resetFilters = createEvent()

export const setTransactionsType = createEvent<string>()
export const $transactionsType = restore(setTransactionsType, 'all').reset(
  resetFilters,
)

export const setTimeline = createEvent<{ from: string; to: string }>()
export const $timeline = restore(setTimeline, {
  from: '0',
  to: new Date().getTime().toString(),
}).reset(resetFilters)

export const setStatuses = createEvent<string[]>()
export const $statuses = restore(setStatuses, []).reset(resetFilters)

export const setAccountIDs = createEvent<string[]>()
export const $accountIDs = restore(setAccountIDs, []).reset(resetFilters)

export const setWalletIDs = createEvent<string[]>()
export const $walletIDs = restore(setWalletIDs, []).reset(resetFilters)

export const $total = createStore<number>(0)

export const setPage = createEvent<number>()
export const $page = restore<number>(setPage, 1)

export const setPerPage = createEvent<number>()
export const $perPage = restore<number>(setPerPage, 20)

export const $transactions = restore(
  service.transactionHistory.getTransactionHistory.doneData.map(
    (doneData) => doneData.body,
  ),
  null,
)

sample({
  source: $perPage,
  clock: service.transactionHistory.getTransactionHistory.doneData,
  fn: (perPage, doneData) => +doneData.body.totalPages * perPage,
  target: $total,
})

sample({
  // @ts-expect-error
  source: combine(
    $page,
    $perPage,
    $transactionsType,
    $timeline,
    $statuses,
    $walletIDs,
    $accountIDs,
  ),
  clock: [
    transactionsMounted,
    setTransactionsType,
    setTimeline,
    setStatuses,
    setWalletIDs,
    setAccountIDs,
    setPage,
    resetFilters,
  ],
  fn: (source) => {
    const [
      page,
      perPage,
      transactionsType,
      timeline,
      statuses,
      walletIDs,
      accountIDs,
    ] = source

    return {
      body: {
        filter: {
          includeDeposits:
            transactionsType === 'all' || transactionsType === 'deposit',
          includeWithdrawals:
            transactionsType === 'all' || transactionsType === 'withdrawal',
          includeInternalTransfers:
            transactionsType === 'all' ||
            transactionsType === 'internal_transfer',
          timeline,
          statuses,
          accountIDs: accountIDs,
          walletIDs: walletIDs,
        },
        page,
        perPage,
      },
    }
  },
  target: service.transactionHistory.getTransactionHistory,
})
