import React, { useEffect } from 'react';
import { useController, useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStoreMap } from 'effector-react';

import { WithdrawalCardItem } from 'services/payments/get/types';
import { useCalculatePayments } from 'services/payments/mutations/useCalculatePayments';

import { validateNumberMax, validateNumberMin } from 'libs/validators';
import { Money, Spinner2 } from 'ui/atoms';
import { AmountInput } from 'ui/molecules';

import { $withdrawalPageStore } from '../../model';

const errorMaxMessage = 'Limit exceeded. Please enter the maximum amount.';

type Props = {
  isOneCurrency: boolean;
}

export const Amount: React.FC<Props> = ({ isOneCurrency }) => {
  const { watch, trigger, setValue, getValues } = useFormContext();

  const [t] = useTranslation();

  const chooseCard: WithdrawalCardItem | undefined = watch('cardItem');
  
  const withdrawalSourceState = useStoreMap($withdrawalPageStore, (data) => data.source);
  
  const { mutateAsync, isLoading } = useCalculatePayments();
  
  const { field: amountField, fieldState: amountFieldState } = useController({
    name: 'amount',
    defaultValue: '',
    rules: {
      required: 'Required field',
      validate: (val) => {
        const numberVal = +val;

        if (!numberVal) {
          return 'Required field'
        }
        
        // Сравниваем с лимитом на вывод на карту
        if (!validateNumberMax(val, chooseCard?.balance)) {
          return errorMaxMessage;
        }
        
        if (!validateNumberMin(val, 0)) {
          return t('Insufficient funds') as string;
        }
    
        return true;
      },
    },
  });

  const onCalculateDst = async () => {
    const withdrawalAmountValue = getValues('withdrawalAmount');
    if (!withdrawalAmountValue) return;
    if (!chooseCard) return;

    const isValidEnteredNumber = await trigger('withdrawalAmount');
    if (!isValidEnteredNumber) return;
    

    const scrCurrency = withdrawalSourceState?.isFixRate ? 'USF' :  withdrawalSourceState?.currency!;

    try {
      const res = await mutateAsync({
        scrCurrency,
        dstCurrency: chooseCard?.currency,
        srcAmount: withdrawalAmountValue,
        dstAmount: '0',
      });
  
      amountField.onChange(res.dstAmount);
      trigger('amount');
    } catch (error) {
      // @ts-ignore
      console.log(error);
    }
  }

  useEffect(() => {
    if (!chooseCard || !withdrawalSourceState) return;

    setValue('amount', '', {
      shouldValidate: false
    });
    setValue('withdrawalAmount', '', {
      shouldValidate: false,
    });

  }, [chooseCard, withdrawalSourceState]);

  return (
    <div className='mt-[1.6rem]'>
        <Controller
          name='withdrawalAmount'
          defaultValue='' 
          rules= {{
            required: 'Required field',
            validate: (val: string) => {
              const numberVal = Number(val);
              if (!numberVal || numberVal === 0) {
                return 'Required field'
              }
              
              return validateNumberMax(val, withdrawalSourceState?.balance) || errorMaxMessage
            }
          }}
          render={({field, fieldState}) => 
            <AmountInput
              className='!max-w-[46rem]'
              value={field.value}
              label={'Withdrawal amount'}
              inputClasses='!ps-[50px]'
              autoComplete='off'
              onBlur={onCalculateDst}
              // @ts-ignore
              prefix={<Money currency={withdrawalSourceState?.currency} type='symbol' /> }
              onChange={field.onChange}
              ref={field.ref}
              errorMessage={ fieldState.error?.message }
            />
          } 
        />

      <AmountInput
        className='!max-w-[46rem] mt-[2rem]'
        value={amountField.value}
        label={'Recipient amount'}
        autoComplete='off'
        inputClasses='!ps-[50px] !pe-[40px]'
        onChange={amountField.onChange}
        // @ts-ignore
        prefix={
          <Money
            currency={ isOneCurrency ? withdrawalSourceState?.currency : chooseCard?.currency}
            value={0}
            type='symbol'
          />
        }
        suffixType='icon'
        suffix={isLoading && (
          <Spinner2 size='small' />
        )}
        disabled
        ref={amountField.ref}
        errorMessage={ amountFieldState.error?.message }
      />

    </div>
  )
}
