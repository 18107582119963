import styled from 'styled-components/macro';

export const StatusBlock = styled.div`
  margin-top: 2.4rem;
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const Container = styled.div`
  position: relative;
  border-radius: 8px;
  height: 100%;
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
    0px 4px 6px -1px rgba(38, 33, 25, 0.1);
`
export const Wrapper = styled.div`
  padding: 24px 20px;
  border-radius: 8px;
  height: 100%;
  background-color: var(--color-gray-100);
`