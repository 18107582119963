import React, { useMemo } from 'react';
import { useFieldArray, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ServerFile, UploadFiles } from 'ui/molecules';
import { DefaultUploadPlace } from 'ui/molecules/UploadFiles/upload/component';

import CardFront from '../img/cardFront.svg';
import CardFrontWebp from '../img/cardFront.webp';
import CardBack from '../img/cardBack.svg';
import CardBackWebp from '../img/cardBack.webp';

interface Props {
  control: Control<any>
}

export const UploadFieldsWrapp: React.FC<Props> = ({ control }) => {
  const [t] = useTranslation();

  const { fields, append, remove } = useFieldArray({
    name: 'fileNames',
    control: control,
  });

  const onChangeFiles = (files: ServerFile) => {
    append(files);
  }

  const onDeleteFile = (index: number) => {
    remove(index)
  }

  const defaultUploadPlace = useMemo((): DefaultUploadPlace[] => ([
    { 
      title: t('Upload Front') as string, 
      titleIcon: 'cloudArrowUp' as const,
      img: {
        main: CardFront,
        webp: CardFrontWebp
      },
      id: '1_card'
    }, 
    { 
      title: t('Upload Back') as string, 
      titleIcon: 'cloudArrowUp' as const,
      img: {
        main: CardBack,
        webp: CardBackWebp,
      },
      id: '2_card',
    }
  ]), [t]);
    
  return (
    <>
      <UploadFiles
        uploadUrl='payments-files/v1/card-verification'
        title='Upload photos'
        onChangeFiles={onChangeFiles}
        onDeleteFile={onDeleteFile}
        maxSize='10'
        files={fields as any}
        isHideAddButton
        defaultUploadPlace={defaultUploadPlace}
        subTitle={'Upload front and back photos of your bank card. Please hide the first 12 digits of the card number and CVV.'}
      />
    </>
  )
}
