import React, { useState, useCallback, useMemo } from 'react'
import { DefaultUploadPlace } from './component';

type Options = {
  defaultUploadPlace?: DefaultUploadPlace[];
}

export const useLoadingPosition = ({ defaultUploadPlace = []}: Options ) => {
  const [loadedPlaces, setLoadedPlaces] = useState<string[]>([]);
  
  const addLoadedPlaces = useCallback((id?: string) => {
    if (!id) return;

    setLoadedPlaces(prev => {
      if (prev.includes(id)) return prev;

      return [...prev, id];
    });
  }, [])

  const removeLoadedPlaces = useCallback((place?: string) => {
    if (!place) return;
    setLoadedPlaces(prev => prev.filter(item => item !== place));
  }, [])

  const defaultUploadArray = useMemo(() => {
    if (!loadedPlaces?.length) return defaultUploadPlace;

    if (!defaultUploadPlace?.length) return [];
    
    return defaultUploadPlace.filter(item => !loadedPlaces.includes(item.id));
  }, [defaultUploadPlace, loadedPlaces]);

  return ({ addLoadedPlaces, removeLoadedPlaces, loadedPlaces, defaultUploadArray })
}
