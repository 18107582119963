import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';

import { usePostLinkSocial } from 'services/user/mutations/usePostLinkSocial';
import { Spinner2, Text } from 'ui/atoms';

export const GoogleLink = () => {
  const { mutateAsync: linkSocialMutate, isLoading } = usePostLinkSocial();

  const loginGoogle = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    onSuccess: (response) => {
      linkSocialMutate({
        // @ts-ignore
        authorizationCode: response.code,
        socialType: 'google',
      });
    },
    onError: (response) => console.log(response, 'google-auth-error'),
  });

  if (isLoading) return <Spinner2 />;

  return (
    <Text
      className='cursor-pointer !text-sunray.500'
      onClick={loginGoogle}
    >
      Link the account
    </Text>
  )
}
