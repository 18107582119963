import React from 'react';

import { env } from 'libs/constants';

type Props = {
  children: React.ReactNode;
  fallback: React.ReactNode;
  isSHowComponentStack?: boolean;
}

type State = {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: Record<string, any>) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    console.log(error);
    if (env.isDev || this.props.isSHowComponentStack) console.log(info.componentStack);
    // TODO доделать логер
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}