import styled from 'styled-components/macro'
import { Swiper } from 'swiper/react'

import sliderArrow from './slider-arrow.svg'

export const Wrapper = styled(Swiper)`
  display: flex;
  flex-direction: column;

  .swiper-pagination {
    inset-inline-start: 32px;
    bottom: 0;
    height: 32px;
    width: calc(100% - 64px);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      height: 20px;
    }
  }

  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    background-color: var(--color-gray-300);
    border-radius: 4px;
    transform-origin: center center;
    transition: background-color var(--transition);
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    width: 12px;
    background-color: #dbb454;
  }
`

export const Arrows = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 600px) {
    margin-top: 8px;
    height: 20px;
  }

  .prev-slider-button,
  .next-slider-button {
    width: 32px;
    height: 32px;
    background-image: url(${sliderArrow});
    background-repeat: no-repeat;
    background-position: center center;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .next-slider-button {
    transform: rotate(180deg);
  }
`