import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'

export const GET_PERFOMANCE_KEY = 'GET_PERFOMANCE_KEY'

export type PerfomanceResponse = {
  balance: string
  equity: string
  gain: string
  grossProfit: string
  currency: string
  loss: string
  numLossOrders: number
  numProfitableOrders: number
  profit: string
  winRate: string
  charts: ChartsItem[]
}

export type ChartsItem = {
  name: string
  title: string
  lines: LinesItem[]
}

export type LinesItem = {
  name: string
  title: string
  color: string
  points: PointItem[]
}

export type PointItem = {
  value: string
  label: string
  timestamp: string
}

const getPerfomance = async (
  strategyId: string,
  chartTimeRange = undefined as undefined | string,
  isAuthorized: boolean,
) => {
  const res = await axios.get<PerfomanceResponse>(
    isAuthorized
      ? '/copytrade/v1/strategy/performance'
      : '/copytrade/v1/strategy/performance-for-unauthorized',
    {
      params: {
        strategyID: strategyId || undefined,
        chartTimeRange: chartTimeRange || undefined,
      },
    },
  )

  return res.data
}

interface Options {
  enabled?: boolean
  strategyId: string
  chartTimeRange?: string
  isAuthorized: boolean
}

export const useGetPerfomance = ({
  strategyId,
  enabled,
  chartTimeRange,
  isAuthorized,
}: Options) => {
  return useQuery({
    queryKey: [GET_PERFOMANCE_KEY, strategyId, chartTimeRange],
    queryFn: () => getPerfomance(strategyId, chartTimeRange, isAuthorized),
    retry: retryFn,
    staleTime: 5000,
    enabled,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })
}
