import { useStore } from 'effector-react'
import { path } from 'libs/path'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Text } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'

import {
  $userDataChangeModal,
  onHideUserDataChangeModal,
} from '../../model/profileModalsStore'

export const ChangeUserDataModal = () => {
  const navigate = useNavigate()

  const isShowModal = useStore($userDataChangeModal)

  const onAgreeModal = () => {
    // todo: почему-то не меняется isOpen у модального окна, если флаг хранится в эффекторе
    onHideUserDataChangeModal()
    navigate(path.dashboard.verification.editEntry())

    document.body.style.overflow = 'visible'
  }

  return (
    <ModalDialog
      isOpen={isShowModal}
      onOk={onAgreeModal}
      title="Update verified information"
      okLabel="I agree"
      cancelLabel="Cancel"
      onCancel={onHideUserDataChangeModal}
      shouldCloseOnOverlayClick={true}
    >
      <Text>
        If you change the verified information, you must verify your profile
        again.
      </Text>
    </ModalDialog>
  )
}
