import React from 'react'
import styled from 'styled-components/macro'

type Props = {
  className?: string
  children?: React.ReactNode
  scheme?: 'default' | 'ghost'
  onClick?: () => void
}



export const Block: React.FC<Props> = ({
  className,
  children,
  scheme = 'default',
  onClick,
}) => {
  return (
    <BlockContainer
      className={className}
      data-scheme={scheme}
      onClick={onClick}
    >
      {children}
    </BlockContainer>
  )
}

const BlockContainer = styled.div.attrs({ 'data-active': 'data-active' })`
  padding: 24px 20px;
  border-radius: 8px;
  transition: var(--transition);
  width: 100%;

  &[data-scheme='ghost'] {
    border: 1px solid var(--border-color-light);
  }

  &[data-scheme='default'] {
    background-color: var(--color-gray-100);
    box-shadow: 0px 0px 1.5px rgba(38, 33, 25, 0.085),
      0px 24px 48px -12px rgba(38, 33, 25, 0.16);
  }
`
