import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Title, Text, Button } from 'ui/atoms';

import successSrc from 'styles/img/success.png';
import successSrcWeb from 'styles/img/success.webp';
import clockSrc from 'styles/img/clock.png';
import clockSrcWeb from 'styles/img/clock.webp';

import { path } from 'libs/path';

interface Props {
  status: 'pending' | 'success',
}

const statusType = {
  success: {
    title: 'All bank card are verified',
    text: 'You have successfully verified your all bank card. Now you can use it for withdrawals.',
    iconSrc: successSrc,
    iconSrcWebp: successSrcWeb,
  },
  pending: {
    title: 'Bank card verification is in process',
    text: 'We have received your bank card verification request. It may take up to 24 hours to verify your bank card.',
    iconSrc: clockSrc,
    iconSrcWebp: clockSrcWeb,
  }
}


export const AllVerifiedCards: React.FC<Props> = ({ status }) => {
  const navigate = useNavigate();

  const pageObj = statusType[status];

  return (
    <div className='flex flex-col justify-between'>
      <div>
        <Title level={2} className='my-[24px]'>Bank card verification</Title>

        <div className='py-[16px] px-[24px]'>
          <picture>
            <source srcSet={pageObj.iconSrcWebp} type="image/webp" className='w-[9rem]' />
            <img src={pageObj.iconSrc} alt="question" className='w-[9rem]' />
          </picture>
        </div>

        <Title level={2} className='mt-[24px]'>{pageObj.title}</Title>
        <Text className='mt-[24px] md:w-[38rem]'>
          {pageObj.text}
        </Text>
        {/* <Text>Now you can use it for withdrawals.</Text> */}
      </div>


      <div className='flex gap-[20px] mt-[64px]'>
        <Button
          name='goToDashboard'
          design='secondary'
          className='!w-[20rem]'
          onClick={() => navigate(path.dashboard.entry())}
        >
          Dashboard
        </Button>

        <Button
          name='goToDashboard'
          design='primary'
          className='!w-[20rem]'
          onClick={() => navigate(path.money.depositTab())}
        >
          Deposit now
        </Button>
      </div>
    </div>
  )
}
