import styled from 'styled-components/macro'

export const OptionsWrapper = styled.div`
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: repeat(3, 64px);
  gap: 12px;
`

export const Option = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  height: 32px;
  display: grid;
  place-items: center;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-tiny);
  color: ${({ isActive }) =>
    isActive ? 'var(--color-gray-700)' : 'var(--color-gray-600)'};
  font-weight: 500;
  transition-property: color, background-color;
  transition-duration: var(--transition);
  background-color: ${({ isActive }) =>
    isActive ? 'var(--color-gray-400)' : 'var(--color-gray-300)'};
  border-radius: 4px;
`
