import { useStore } from 'effector-react'
import * as React from 'react'

import { $error, mounted, registerByEmail } from './model'

export const useEmailRegisterPage = () => {
  React.useEffect(() => {
    mounted()
  }, [])

  const error = useStore($error)
  const loading = useStore(registerByEmail.pending)

  return {
    form: {
      error,
      loading,
    },
  }
}
