import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'

const hideBanner = async (name: string) => {
  await axios.patch('/banners/v1/banner/hide', { name })
}

export const useHideBanner = () => {
  return useMutation({
    mutationFn: hideBanner,
  })
}
