import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { Icon2, TipyTooltip, Text, Button } from 'ui/atoms';
import { IconSizes } from 'ui/atoms/icon2';

type Props = {
  copiedText: string;
  tooltipText: string;
  className?: string;
  iconSize?: IconSizes;
  name?: string;
  copyIconClass?: string;
  onCopied?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const CopyButton: React.FC<Props> = ({ 
  copiedText, tooltipText, className, name,
  iconSize = 'medium', copyIconClass, onCopied,
}) => {
  const [isCopiedOwnState, setIsCopiedOwnState] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>();

  const TooltipBody = forwardRef((props, ref) => {
    return <Icon2 iconRef={ref} size={iconSize} name='check' className='text-eucalyptus.600' />;
  });

  const onCopy = (e: React.MouseEvent<any>) => {
    onCopied?.(e);
    setIsCopiedOwnState(true);
    navigator.clipboard.writeText(copiedText)
  }

  useEffect(() => {
    if (!isCopiedOwnState) {
      if (!!timerRef?.current) {
        clearTimeout(timerRef.current);
      }
      return;
    }

    if (!timerRef.current) {
      timerRef.current = setTimeout(() => {
        
        setIsCopiedOwnState(false);
        timerRef.current = null;
      }, 2000);
    }
  }, [isCopiedOwnState]);
  

  return (
    <Button
      className={ className }
      design="tertiary"
      name={name}
      onClick={onCopy}
      suffix={isCopiedOwnState 
        ? 
          <TipyTooltip
            content={
              <div>
                <Text weight='medium' level={3}>{tooltipText}</Text>
              </div>
            }
            showOnCreate
          >
            <TooltipBody />
          </TipyTooltip>
        : <Icon2
            className={`cursor-pointer ${copyIconClass}`}
            name="documentDuplicate"
            size={iconSize}
          />
      }
    >
    </Button>
  );
}
