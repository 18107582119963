import { useQuery } from '@tanstack/react-query';

import { MoneyHistoryFilterStore, Statuses } from 'pages/money/transactionsNew/model';

import { axios } from 'services/axios';
import { TransactionHistoryResponse } from './types';
import { mapFilterToReqData } from './mapper';

export const GET_MONEY_HISTORY_KEY = 'money-history';


type Options<T> = ReqOptions & {
  onSelect?: (data: TransactionHistoryResponse) => T;
}

type ReqOptions = {
  filter: MoneyHistoryFilterStore;
  page: number;
  perPage?: number;
}

export type ReqData = {
  timeline: {
    from: string;
    to: string;
  };

  exchangerWalletIDs?: string[];
  statuses?: Statuses[];
  walletIDs?: string[];
  accountIDs?: string[];
  includeInternalTransfers?: boolean;
  includeDeposits?: boolean;
  includeWithdrawals?: boolean;
}



const getMoneyHistory = async ({ filter, page, perPage }: ReqOptions) => {
  const reqData = mapFilterToReqData(filter);

  const resp = await axios.post<TransactionHistoryResponse>(`money-history/v1/user`, {
    page,
    perPage,
    filter: reqData,
  });

  return resp.data;
}

export const useGetMoneyHistory = <T=TransactionHistoryResponse>({ filter, page = 1, perPage = 20, onSelect }: Options<T>) => {
  return useQuery({
    // @ts-ignore
    queryKey: [GET_MONEY_HISTORY_KEY, page, perPage, filter],
    queryFn: () => getMoneyHistory({ filter, page, perPage }),
    staleTime: 200,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: onSelect,
  })
}
