import styled from 'styled-components/macro'

export const Container = styled.div`
  width: calc(75% - 8px);
  position: relative;

  @media (max-width: 1050px) {
    width: 100%;
  }

  & > div {
    gap: 32px;
    display: flex;
    flex-direction: column;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BenefitsDialog = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
