import * as Styled from './styled'

export const Toggle = ({
  checked,
  disabled,
  size = 'medium',
  onChange,
}: {
  checked: boolean
  disabled?: boolean
  size?: 'medium' | 'large'
  onChange: () => void
}) => {
  return (
    <Styled.Wrapper
      size={size}
      isActive={checked}
      isDisabled={disabled}
      onClick={() => !disabled && onChange()}
    />
  )
}
