import React, { useEffect, useState } from 'react';

interface Props {
  url?: string;
}

export const FramePraxis: React.FC<Props> = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const callBackFn = (e: any) => {
      // console.log(e, '--------Praxis mess---------');
		};

		// window.addEventListener('trigger_advance_cashier_event', callBackFn1);
		window.addEventListener('message', callBackFn);
		
		return () => {
			// window.removeEventListener('trigger_advance_cashier_event', callBackFn1);
			window.removeEventListener('message', callBackFn);
		};
	}, []);

  return (
    <>
      {/* {isLoading && <Spinner2 />} */}
      <iframe
        className='lg:h-[calc(100vh-285px)] min-h-[500px] w-full'
        src={url}
        title='praxis'
        id={'praxis_money'}
      />
    </>
  )
}
