import * as React from 'react'
import styled from 'styled-components/macro'

import { Icon, Icons } from '../index'

type Props = {
  name?: string
  className?: string
  onClick?: () => void
  isActive: boolean
  off: Icons
  on: Icons
}

export const Switch = ({
  className,
  onClick,
  isActive = false,
  on,
  off,
}: Props) => {
  const name = isActive ? on : off
  return (
    <>
      <ToggleButtonContainer
        id={`${name}-switch`}
        className={className}
        onClick={onClick}
      >
        <ToggleButtonIcon name={name} />
      </ToggleButtonContainer>
    </>
  )
}

const ToggleButtonContainer = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`

const ToggleButtonIcon = styled(Icon)`
  width: 30px;
  height: 28px;
`
