import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { retryFn } from 'services/helpers/retryFn';
import { axios } from 'services/axios';

import { ProfileContent, onSuccessOption } from './type';
import mock from './mock.json';

export const GET_PROFILE_KEY = ['profile'];

const getProfile = async (onSuccess?: onSuccessOption) => {
  const profileData = await axios.get<ProfileContent>('/user/v1/profile');

  onSuccess?.(profileData.data);
  
  // return mock as ProfileContent;
  return profileData.data;
}

export const useGetProfile = (onSuccess?: onSuccessOption) => {
  return useQuery({
    queryKey: GET_PROFILE_KEY,
    queryFn: () => getProfile(onSuccess),
    retry: retryFn,
    staleTime: 1000,
  })
}