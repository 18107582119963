import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import * as React from 'react'

import { resendEmailConfirmationRequest } from '../emailConfirmRepeat'
import { $error, $resendTimer, checkEmailConfirmation, mounted } from './model'

export const useEmailConfirmPage = () => {
  React.useEffect(() => {
    mounted()
  }, [])

  const checkEmailLoading = useStore(checkEmailConfirmation.pending)
  const resendCodeLoading = useStore(resendEmailConfirmationRequest.pending)
  const userProfile = useStore($userProfile)
  const error = useStore($error)
  const timer = useStore($resendTimer)

  return {
    loading: checkEmailLoading,
    resendCodeLoading,
    error,
    userProfile,
    timer,
  }
}
