import React, { forwardRef, ForwardRefRenderFunction, useMemo } from 'react';
import {} from 'react-hook-form';

import { Input, InputProps, InputState } from 'ui/atoms/input';

import * as S from './styles';


interface Props extends InputProps {
  prefix?: string;
  errorMessage?: string;
  isTouchedForm?: boolean;
}


const Component: ForwardRefRenderFunction<HTMLInputElement, Props> = (props, ref) => {
  const {state = 'hint', isTouchedForm, ...rest} = props;

  const inputState = useMemo((): InputState => {
    if (Boolean(props.errorMessage)) return 'error';

    if (isTouchedForm && !props.errorMessage) return 'valid';

    return 'hint'
  }, [isTouchedForm, props.errorMessage]);

  return (
    <>
      <Input
        {...rest }
        state={ inputState }
        ref={ref}
      />
    </>
 
  )
}

export const FormInput = forwardRef(Component);