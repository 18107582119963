import { path } from 'libs/path'
import { useNavigate } from 'react-router-dom'
import { Text } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'

export const ThanksModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const navigate = useNavigate()

  const handleOk = () => {
    navigate(path.giftshop.entry())

    onClose()
  }

  const handleCancel = () => {
    navigate(path.giftshop.history())

    onClose()
  }

  return (
    <ModalDialog
      title="Thank you!"
      closeModalButton={false}
      isOpen={isOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      cancelButtonType="secondary"
      okLabel="Gift list"
      cancelLabel="Order history"
      shouldCloseOnOverlayClick={false}
      contentStyles={{ width: 'auto' }}
    >
      <div style={{ maxWidth: 340 }}>
        <Text>We will update you on the status of your order via email.</Text>
      </div>
    </ModalDialog>
  )
}
