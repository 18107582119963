import { createEvent, restore, createStore } from 'effector';
import { SourceItem } from 'services/payments/get/types';


export const onSetAccount = createEvent<SourceItem>();
export const onSetAccountReset = createEvent();

export const $accountStore = restore(onSetAccount, null)
.reset(onSetAccountReset);


// Withdraw page store
interface WithdrawalPageStore {
  gatewayId: string;
  source: SourceItem;
  otp?: string;
}
export const onStoreWithdrawal = createEvent<WithdrawalPageStore>();
export const onStoreSourceWithdrawal = createEvent<{source: SourceItem}>();
export const resetWithdrawalPageStore = createEvent();
export const $withdrawalPageStore = createStore<Partial<WithdrawalPageStore>>({}).reset(resetWithdrawalPageStore);

$withdrawalPageStore.on(onStoreWithdrawal, (state, data) => data);
$withdrawalPageStore.on(onStoreSourceWithdrawal, (state, data) => {
    return {
      ...state,
      source: data.source,
    }
});