import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetSourceList } from 'services/payments/get/useGetSourceList';
import { CreateDepositResponse } from 'services/payments/mutations/useCreateDeposit';
import { SourceItem } from 'services/payments/get/types';

import { accountChooseReset, resetCustomGatewayIdEvent, setCustomGatewayIdEvent } from '../depositChoice/model';
import { CustomFormDep } from '../depositChoice/CutomFormDep/CustomFormDep';
import { path } from 'libs/path';

import * as S from './styled';
import { setDestenationEvent } from '../depositChoice/CutomFormDep/model';
import { PostFrame } from '../depositChoice/PostFrame';


export const DepositCustomPage = () => {
  const { data: destinationsData } = useGetSourceList('deposit');
  let [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const customGatewayId = searchParams.get('customGatewayId') as string;
  
  const isInitalValueAcc = useRef(true);

  const [selectValue, setSelectValue] = useState<SourceItem>();
  const [customFrame, setCustomFrame] = useState<CreateDepositResponse | undefined>(undefined);

  const onSuccessCreateDep = (resp: CreateDepositResponse) => {
    if (!resp) return;
    
    setCustomFrame(resp);
  }

  const goBack = () => {
    navigate(-1)
  };

  useEffect(() => {
    return () => {
      accountChooseReset();
      resetCustomGatewayIdEvent();
    }
  }, []);

  // сетинг дефолтного значения
  useEffect(() => {
    if (!destinationsData) return;
    if (!isInitalValueAcc.current) return;

    const chosenDestenation = searchParams.get('chosenDestenation') as string;
    
    const finded = destinationsData.find(item => item.id === chosenDestenation);
    if (finded) {
      setDestenationEvent(finded);
      setCustomGatewayIdEvent(customGatewayId);
      isInitalValueAcc.current = true;
      setSelectValue(finded);
    }
  
    return;
  }, [destinationsData]);
  
  return (
    <S.Wrapper>
      <CustomFormDep
        gatewayId={customGatewayId}
        onBack={goBack}
        onSuccessCreateDep={ onSuccessCreateDep }
        destinationList={ destinationsData as SourceItem[] }
        destination={ selectValue }
      />

      { customFrame && 
        <PostFrame
          url={customFrame.URL}
          transactionID={customFrame.transactionID}
          payload={customFrame?.payload!}
        />
      }
    </S.Wrapper>
  )
}
