import { useEffect } from 'react'
import { useRemoveStrategy } from 'services/copytrade'
import { Text } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'

export const UnpublishModal = ({
  isOpen,
  strategyID,
  onSuccess,
  onCancel,
}: {
  isOpen: boolean
  strategyID: string
  onSuccess: () => void
  onCancel: () => void
}) => {
  const { isLoading, mutateAsync, isSuccess } = useRemoveStrategy()

  useEffect(() => {
    if (isSuccess) {
      onSuccess()
    }
  }, [isSuccess])

  return (
    <ModalDialog
      title="Stop publishing?"
      isOpen={isOpen}
      onOk={() => mutateAsync(strategyID)}
      okLabel="Stop"
      onCancel={onCancel}
      cancelLabel="Cancel"
      loading={isLoading}
      disableCancelBtn={isLoading}
      disableOkBtn={isLoading}
    >
      <Text>When you stop publishing a strategy, all statistics are lost.</Text>
    </ModalDialog>
  )
}
