import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';

import { Button, Icon2 } from 'ui/atoms'
import { FramePraxis } from './FramePraxis'
import { setPraxisUrlEvent, $praxisUrl } from './model';
import { path } from 'libs/path';

export const PraxisWithdrawalPage = () => {
  const navigate = useNavigate();

  const url = useUnit($praxisUrl);
  

  useEffect(() => {
    return () => {
      setPraxisUrlEvent('');
    }
  }, []);

  useEffect(() => {
    if (url) return;

    navigate(path.money.entry());
  }, []);

  return (
    <>
    <FramePraxis url={ url } />
    <div className='w-full flex justify-end mt-[4rem]'>
      <Button
        name='backButton'
        design='secondary'
        className='w-[16rem]'
        onClick={() => navigate(-1) }
      >
        <Icon2 name='arrowLeft' className='me-3'/>
        Back
      </Button>
    </div>
    </>
  )
}
