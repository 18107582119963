import React from 'react';
import { useUnit } from 'effector-react';

import { CreateDepositResponse, useCreateDeposit } from 'services/payments/mutations/useCreateDeposit';

import { Button, Text } from 'ui/atoms';
import { $amountField, $destenation, setAmountErrorEvent } from './model';
import { $customGatewayId } from '../model';
import { useFormContext } from 'react-hook-form';

type Props = {
  onSuccessCreateDep: (url: CreateDepositResponse) => void;
  isDisabled: boolean | undefined;
}


export const SubmitButton = ({ onSuccessCreateDep, isDisabled }: Props) => {
  const { errorType, activeCurrency, amount } = useUnit($amountField);
  const customGatewayId = useUnit($customGatewayId);
  const destenation = useUnit($destenation);

  const { handleSubmit } = useFormContext();

  const { mutateAsync, isLoading } = useCreateDeposit();
  
  const onCreateDep = async (formData?: Record<string, any>) => {
    if (!destenation || !activeCurrency) return;
    if (!amount) {
      setAmountErrorEvent('EMPTY');
      return;
    }

    try {
      const resp = await mutateAsync({
        destinationID: destenation.id,
        destinationType: destenation.type,
        gatewayId: customGatewayId,
        currency: activeCurrency,
        amount,
        formData,
      });

      if (resp?.URL) {
        onSuccessCreateDep(resp);
      }
    } catch (error) {
    }
  };

  return (
    <div className='flex w-full justify-center mt-[6rem]'>
      <Button
        name='sbm'
        design='primary'
        disabled={ errorType === 'LIMIT_ERROR' || isDisabled }
        loading={ isLoading }
        className='min-w-[23rem]'
        onClick={handleSubmit(onCreateDep)}
      >
        <Text>Deposit</Text>
      </Button>
    </div>
  )
}
