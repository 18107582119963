import * as React from 'react'
import styled from 'styled-components/macro'
import { Button, Icon } from 'ui/atoms'
import { Search, Widget } from 'ui/molecules'

export const WithdrawalWidget = () => {
  return (
    <Widget>
      <WithdrawalFrom
        name="withdrawal-from"
        label="From"
        placeholder="Choose wallet"
      />

      <WithdrawalMethod
        name="withdrawal-from"
        label="Withdrawal method"
        placeholder="Choose withdrawal method"
      />
      <WithdrawalAmount
        name="withdrawal-amount"
        label="Amount"
        placeholder="Amount"
      />
      <WithdrawalFooter>
        <WithdrawalSubmitButton
          name="deposit-submit"
          size="small"
          prefix={<Icon name="arrowUpOnSquare" />}
        >
          Withdrawal
        </WithdrawalSubmitButton>
      </WithdrawalFooter>
    </Widget>
  )
}

const WithdrawalFrom = styled(Search)`
  margin-bottom: 16px;
`

const WithdrawalMethod = styled(Search)`
  margin-bottom: 16px;
`

const WithdrawalAmount = styled(Search)`
  margin-bottom: 16px;
`

const WithdrawalFooter = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const WithdrawalSubmitButton = styled(Button)``
