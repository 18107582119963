import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Account } from 'pages/accounts/types'
import { axios } from 'services/axios'

import { GET_ARCHIVED_ACCOUNTS_KEY } from '../get/useGetArchivedAccounts'

const restoreAccount = async (accountID: string) => {
  await axios.patch('/account/v1/account/restore', { accountID })
}

export const useRestoreAccount = (accountID: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: restoreAccount,
    onSuccess() {
      queryClient.setQueryData<Account[]>(
        GET_ARCHIVED_ACCOUNTS_KEY,
        (oldData) => {
          const filteredData = oldData?.filter(
            (account) => account.accountID !== accountID,
          )

          return !filteredData || filteredData.length === 0
            ? undefined
            : filteredData
        },
      )
    },
  })
}
