import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'

import { LinesItem } from './useGetPerfomance'

export type StrategyStatsResponse = {
  total: {
    investors: number
    likes: number
  }
  popularityChart: {
    lines: LinesItem[]
  }
}

export type StrategyStatsItem = {
  date: string
  investors: number
  likes: number
}

export type ChartTimeRange =
  | 'TIME_RANGE_WEEK'
  | 'TIME_RANGE_MONTH'
  | 'TIME_RANGE_THREE_MONTH'

export const GET_STRATEGY_STATISTICS_KEY = 'GET_STRATEGY_STATISTICS_KEY'

const getTraderStrategyStatistics = async (
  strategyId: string,
  chartTimeRange?: ChartTimeRange,
) => {
  const res = await axios.get<StrategyStatsResponse>(
    `/copytrade/v2/trader/statistics`,
    {
      params: {
        strategyID: strategyId || undefined,
        chartTimeRange: chartTimeRange || undefined,
      },
    },
  )

  return res.data
}

interface Options {
  enabled?: boolean
  strategyId: string
  chartTimeRange?: ChartTimeRange
}

export const useGetTraderStrategyStatistics = ({
  strategyId,
  enabled,
  chartTimeRange,
}: Options) => {
  return useQuery({
    queryKey: [GET_STRATEGY_STATISTICS_KEY, strategyId, chartTimeRange],
    queryFn: () => getTraderStrategyStatistics(strategyId, chartTimeRange),
    retry: retryFn,
    staleTime: 2000,
    enabled,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })
}
