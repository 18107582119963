import styled from 'styled-components/macro'
import { Title } from 'ui/atoms'

import notFound from '../images/not-found.svg'

export const Wrapper = styled.div`
  padding: 20px;
  min-height: 540px;
  overflow-x: hidden;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  box-shadow: 0px 4px 6px -1px rgba(38, 33, 25, 0.1),
    0px 2px 4px -1px rgba(38, 33, 25, 0.06);

  @media (max-width: 599px) {
    padding: 20px 8px;
  }
`

export const InfiniteScroll = styled.div`
  height: 70vh;
  overflow-x: hidden;

  .ReactVirtualized__Grid,
  .ReactVirtualized__Grid__innerScrollContainer {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const NoResultsWrapper = styled.div`
  margin-top: 60px;
  display: grid;
  justify-content: center;
  justify-items: center;
  text-align: center;

  @media (max-width: 599px) {
    margin-top: 16px;
  }
`

export const NoResultsTitle = styled(Title)`
  padding-top: 128px;
  background-image: url(${notFound});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 128px auto;

  @media (max-width: 599px) {
    padding-top: 92px;
    background-size: 88px auto;
  }
`

export const NoResultsText = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
  max-width: 540px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  color: var(--color-gray-600);

  @media (max-width: 599px) {
    margin-bottom: 16px;
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
  }
`
