import styled from 'styled-components/macro'
import { Button, Icon, Text, Title } from 'ui/atoms'

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ModalTitle = styled(Title)`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ModalCloseIcon = styled(Icon)`
  cursor: pointer;
`

export const ModalText = styled(Text)`
  color: var(--color-gray-600);
`

export const ButtonsWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 400px) {
    gap: 10px;
  }

  &:first-child {
    margin-top: 0;
  }
`

export const SimpleButton = styled(Button)`
  min-width: 153px;

  @media (max-width: 400px) {
    min-width: 140px;
  }

  &:first-child {
    margin-top: 0;
  }
`
