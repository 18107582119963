import styled from 'styled-components/macro'

import { Icon2 } from 'ui/atoms';

export const BannerBlock = styled.div<{ url: string }>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  padding: 2rem;
  background-color: var(--color-neutral);
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
    0px 4px 6px -1px rgba(38, 33, 25, 0.1);
  position: relative;
  background-image: url(${p => p.url});
  background-size: cover;
  background-position: center right;

  @media (max-width: 540px) {
    height: 100%;
    min-height: 100px;
    background-image: url(${p => p.url});
    background-position: 92% center;
    text-align: left;
  }
`

export const Icon = styled(Icon2)`
  position: absolute;
  right: 1rem;
  top: 10px;
  cursor: pointer;
  z-index: 40;
  border-radius: 100px;
  background-color: var(--color-gray-600);
  display: flex;
  align-items: center;
  justify-content: center;
  /* absolute right-[1rem] top-[10px] cursor-pointer z-40 w-[24px] h-[24px] bg-gray.600 rounded-full */
  & > svg {
    width: 20px;
    height: 20px;
  }
`;