import React, { PropsWithChildren, useRef, useEffect } from 'react';

import * as S from './styled';

type Props = {
  className?: string;
} & PropsWithChildren;

const END_OF_SCROLL_DIFERENCE = 10;

export const TableWrapper: React.FC<Props> = ({ className, children }) => {
  const tableRef = useRef<HTMLDivElement>(null);
  // Меняются инлайн стили
  // Чтобы на каждое изменение скрол не перерендеривать таблицу целиком
  const onScrollFn = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft, classList, scrollWidth, clientWidth  } = e.target as HTMLDivElement;
    
    classList?.remove('initialScrollSides');
    classList?.remove('allScrollSides');
    classList?.remove('endScrollSides');
    
    if (scrollLeft !== 0) {
      classList?.add('allScrollSides');
    } else {
      classList?.add('initialScrollSides')
    }

    const scrollEndPos = Math.ceil(scrollWidth - scrollLeft) - clientWidth;
    
    // End of right scroll
    if (scrollEndPos >= 0 && scrollEndPos < END_OF_SCROLL_DIFERENCE) {
      classList?.add('endScrollSides');
    }
  }

  // Стартовый скролл есть или нет
  useEffect(() => {
    const elem = tableRef.current;
    if (!elem) return;

    const { clientWidth, scrollWidth, classList } = elem;
    if (clientWidth === scrollWidth) {
      return;
    } else {
      classList?.add('initialScrollSides')
    }
  }, [])
  

  return (
    <S.TableWrapper
      className={`${className}`}
      scrollState='initial'
      onScroll={onScrollFn}
      ref={tableRef}
    >
      {children}
    </S.TableWrapper>
  )
}
