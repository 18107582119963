import { IbBanner } from 'pages/contest/molecules/ibBanner'
import React from 'react'
import { useGetBanners } from 'services/banners/get/useGetBanners'
import { useHideBanner } from 'services/banners/mutations/useHideBanner'
import { PartnerWidget } from 'services/ib/get/types'
import { Tabs } from 'ui/molecules'

import { ClientTab, PartnersTab } from './molecules';
import { RefererLink, Status, StatusInformation, Wallet } from './widgets';
import { BannersSlider } from './molecules/bannersSlider';

const widgetBlock = 'lg:w-3/6 w-full flex flex-col gap-[2rem]'

export const Dashboard = ({ data }: { data: PartnerWidget }) => {
  const { data: banners, refetch: refetchBanners } = useGetBanners('SECTION_IB')
  const { mutateAsync: hideBanner, isLoading: hideBannerLoading } =
    useHideBanner()

  const handleHideBanner = async (name: string) => {
    try {
      await hideBanner(name)
      refetchBanners()
    } catch {
      // наверное, ошибки не нужны, просто ничего не выводим и всё
    }
  }

  return (
    <>
      {banners &&
        banners.items.find(
          (banner) => banner.name === 'ib-area.anniversary-contest.join',
        ) && (
          <IbBanner
            onClose={() => handleHideBanner('ib-area.anniversary-contest.join')}
            isDisabled={hideBannerLoading}
          />
        )}
      <div className="flex lg:flex-row gap-[2rem] w-full flex-col">
        <div className={widgetBlock}>
          <Wallet amount={data.widgetInfo.withdrawalAmount} />
          <Status status={data.widgetInfo.partnerStatus} />
          {!!data.widgetInfo.confirmStatus && (
            <RefererLink
              partnerId={data.widgetInfo.partnerID}
              partnerLink={data.widgetInfo.partnerLink}
            />
          )}
        </div>
        <div className={widgetBlock}>
          {
            <StatusInformation
              currentStatus={data.widgetInfo.partnerStatus}
              upgrading={data.widgetInfo?.upgrading || {}}
              confirmStatus={data.widgetInfo.confirmStatus}
            />
          }
          {!data.widgetInfo.confirmStatus && (
            <RefererLink
              partnerId={data.widgetInfo.partnerID}
              partnerLink={data.widgetInfo.partnerLink}
            />
          )}
        </div>
      </div>

      <BannersSlider />

      <div className="mt-[2rem]">
        <Tabs
          destroyUnactive
          // contentWrapClasses='overflow-scroll'
          items={[
            {
              label: 'Clients',
              children: <ClientTab />,
            },
            {
              label: 'Partners',
              children: <PartnersTab />,
            },
          ]}
        />
      </div>
    </>
  )
}
