export enum ProfileStatus {
  EXPIRATION_DATE_COMING_UP = 'EXPIRATION_DATE_COMING_UP',
  NOT_VERIFIED = 'NOT_VERIFIED',
  IN_PROGRESS = 'IN_PROGRESS',
  VERIFIED = 'VERIFIED',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  BLOCKED = 'BLOCKED',
}

export interface VerificationProfileBlock {
  status: ProfileStatus
  documentExpirationDate: string // format YYYY-MM-DD
}

export type ProfileRequestStatus = 'REJECTED' | 'IN_PROGRESS' | 'ACCEPTED'

export interface ProfileContent {
  firstName: string
  lastName: string
  middleName: string
  verification: VerificationProfileBlock
  profileChangeRequest?: {
    status: ProfileRequestStatus
    isClosed: boolean // признак того что пользователь закрыл уведомление о статусе заявки на смену профиля
  }
  updatingDocumentRequest?: {
    status: string
  }
  phone: string
  email: string
  dateOfBirth: string // format YYYY-MM-DD
  country: string
  address: string
  is_blocked: boolean
  language: string
  availableLanguage: string
}

// MUTATIONS TYPE

export type onSuccessOption = (data: ProfileContent) => void
