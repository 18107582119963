import { UserProfile } from 'features/user'
import styled from 'styled-components/macro'
import { Icon } from 'ui/atoms'

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  padding: 76px 16px 16px 16px;
  width: 100%;
  max-width: 100%;
  background-color: var(--color-gray-100);
`

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const HeaderUser = styled(UserProfile)`
  margin-inline-start: 4rem;

  @media (max-width: 1050px) {
    margin-bottom: 16px;
    margin-inline-start: 6px;
    padding: 4px 0;
  }
`

export const ButtonMenuBlock = styled.div`
  margin-top: 16px;
`

export const ButtonMenu = styled.div<{ color?: string }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ color }) => color};
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
`

export const ButtonMenuText = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
`

export const ButtonIcon = styled(Icon)``

export const LangBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const LangItem = styled.div`
  padding-inline-start: 56px;
  padding-inline-end: 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: 30px auto;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'right 10px center' : 'left 10px center'};

  &:hover {
    background-color: var(--color-gray-200);
  }
`

export const ActiveLang = styled.div`
  padding-inline-start: 40px;
  background-repeat: no-repeat;
  background-size: 24px auto;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'right center' : 'left center'};
`
