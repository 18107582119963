import React, { useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useStore } from 'effector-react';
import { t } from 'i18next';

import { Title, Text, Button, Icon2 } from 'ui/atoms';
import { TransactionStatus } from 'pages/money/molecules/transactionStatus/TransactionStatus';
import { AccountSelect } from 'pages/money/molecules/newAccountSelect';

import { usePostCreateCardWithdrawal } from 'services/payments/mutations/usePostCreateCardWithdrawal';
import { useGetSourceList } from 'services/payments/get/useGetSourceList';

import { Amount, UploadCardFiles, WithdrawalOtp } from '..';
import { WithdrawalFormModel } from './types';
import { getCardType } from './utils';
import { 
  $withdrawalPageStore,
  onStoreSourceWithdrawal,
} from '../../model';
import { SourceItem, WithdrawalCardItem } from 'services/payments/get/types';
import { isEmpty } from 'libs/isEmpty';
import { CardsWrapper } from './CardsWrapper';


interface WithdrawalSbmState {
  id: string;
  status: 'pending' | 'error',
  amount: string;
  currency: string;
}
interface Props {
  onBackToList: () => void;
}

const errorTransactionText = {
  title: 'Payment failed',
  subTitle: 'The transaction has failed. Please, try again or contact our Customer Care for more details.'
}

const pendingTransactionText = {
  title: t('Payment is in process'),
  subTitle: t('We are processing your transaction now – it may take some time. See the current status of your transaction in the Transaction History (My Accounts section).')
}

export const WithdrawalCardForm: React.FC<Props> = ({ onBackToList }) => {
  const { mutateAsync, isLoading: isLoadingCreateWithdraw, isError } = usePostCreateCardWithdrawal();
  const { data: sorceData } = useGetSourceList('withdrawal');

  const withdrawalPageStore = useStore($withdrawalPageStore);

  const [isOtp, setIsOtp] = useState(false);
  const [isOtpInvalid, setIsOtpInvalid] = useState(false);
  const [withdrawalSbm, setWithdrawalSbm] = useState<WithdrawalSbmState>();

  const methods = useForm<WithdrawalFormModel>({
    defaultValues: {
      amount: '',
      withdrawalAmount: '',
      cardItem: {},
    },
    mode: 'onTouched',
    shouldUnregister: false,
  });

  const filesWatch = methods.watch('fileNames');
  const chooseCard: WithdrawalCardItem = methods.watch('cardItem');
  const amountWatch: string = methods.watch('amount');
  const withdrawalAmountWatch: string = methods.watch('withdrawalAmount');

  const isNeedVerifyCard = !isEmpty(chooseCard) && !chooseCard?.isVerified;

  const isOneCurrency = useMemo(() => {
    if (!chooseCard?.currency || !withdrawalPageStore?.source) return true;
 
    const withdrawalCurrency = withdrawalPageStore.source?.isFixRate ? 'USF' : withdrawalPageStore.source?.currency;
 
     return (chooseCard.currency === withdrawalCurrency);
   }, [chooseCard, withdrawalPageStore]);

  const idButtonDisabled = useMemo(() => {
    if (isEmpty(chooseCard)) return true;
    if (!amountWatch && !withdrawalAmountWatch) return true;
    if (isNeedVerifyCard && filesWatch?.length !== 2) return true
    
    if (!isEmpty(methods.formState.errors)) return true;
  },
  [
    amountWatch, chooseCard, filesWatch?.length, 
    isNeedVerifyCard, withdrawalAmountWatch, methods.formState
  ]);


  const onSbm = async (fields: WithdrawalFormModel) => {
    try {
      setIsOtpInvalid(false);
      if (!withdrawalPageStore?.source || !fields.cardItem) return;
      const amount = (fields.withdrawalAmount).replace(/[^\d.]/g, '') || '';
      
      const resp = await mutateAsync({
        sourceID: withdrawalPageStore.source.id,
        sourceType: getCardType(withdrawalPageStore.source.type),
        destinationID: fields.cardItem.destinationID,
        destinationCurrency: fields.cardItem.currency,
        amount,
        fileNames: fields.fileNames?.map(file => file.fileName),
        otp: fields.code,
      });

      if (fields.code && resp.id === '0') {
        setIsOtpInvalid(true);
        return;
      }

      if (resp.needOTP && !fields.code) {
        setIsOtp(true);
        return;
      }

      setIsOtp(false);
      setWithdrawalSbm({
        id: resp.id as string,
        currency: fields.cardItem.currency,
        amount: fields.amount,
        status: 'pending',
      });
    } catch (error) {
      console.log(error);
      
      setIsOtp(false);
      setWithdrawalSbm({
        id: '',
        currency: fields.cardItem.currency,
        amount: fields.amount,
        status: 'error',
      });
    }
  };

  const onReCreateWithdrawal = (code: string) => {
    const formValues = methods.getValues();
    onSbm({...formValues, code });
  }

  const onReSendCode = async () => {
    const formValues = methods.getValues();
    await onSbm({...formValues });
  }

  const onSaveNewSource = (source: SourceItem) => {
    methods.resetField('amount');
    methods.resetField('withdrawalAmount');
    onStoreSourceWithdrawal({ source });
  }
  

  if (isOtp) {
    return (
      <WithdrawalOtp
        onBack={() => setIsOtp(false)}
        onResendCode={ onReSendCode }
        isCodeError={ isError || isOtpInvalid }
        onCheckCode={onReCreateWithdrawal}
        isLoading={isLoadingCreateWithdraw}
      /> 
    )
  }

  if (withdrawalSbm) {
    return <TransactionStatus
      { ...withdrawalSbm }
      { ...(withdrawalSbm.status === 'pending' ? pendingTransactionText : errorTransactionText) }
    />
  }

  return (
    <div className='h-full'>
        <Title level={2}>Credit and debit card withdrawal</Title>
        <AccountSelect
          onSelect={onSaveNewSource}
          defaultValue={withdrawalPageStore?.source?.id}
          data={ sorceData }
        />
        <FormProvider {...methods}>
          <CardsWrapper/>
          <Amount isOneCurrency={ isOneCurrency } />
          <UploadCardFiles />
        </FormProvider>
        
        <div className='mb-[3.2rem]'>

        </div>


        <div className='flex justify-between'>
          <Text>Commission</Text>
          <Text>$10</Text>
        </div>
        <div className='flex justify-between'>
          <Text>Process time</Text>
          <Text>15-20 minutes</Text>
        </div>

        <div className='mt-[8rem] flex justify-end'>
          <Button
            design='secondary'
            name='confirm'
            className='me-[2rem] w-[16rem]'
            onClick={onBackToList}
          >
            <Icon2 name='arrowLeft' className='me-[8px]' />
            Back
          </Button>
          <Button
            design='primary'
            name='confirm'
            className='w-[16rem]'
            disabled={ idButtonDisabled }
            onClick={methods.handleSubmit(onSbm)}
          >
            Confirm
          </Button>
        </div>
    </div>
  )
}
