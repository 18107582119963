import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { GET_BANK_CARDS_KEY } from '../get/useGetCardsList';

type PostVerifyCard = {
  cardID: string;
  fileNames: string[];
}

const postVerifyCard = async (data: PostVerifyCard) => {
  const res = await axios.post<{}>(`/payments/v1/verify-card`, data);

  return res.data;
};

export const usePostVerifyCard = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PostVerifyCard) => postVerifyCard(data),
    retry: retryFn,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(GET_BANK_CARDS_KEY)
    },
  })
};
