import { InternalAxiosRequestConfig } from 'axios'
import { env } from 'libs/constants'
import { getCookie } from 'libs/cookie'
import i18n from 'libs/i18n'

import { AUTH_HEADER, DEBUG_COUNTRY, RECAPTCHA_TOKEN } from './headersConstants'

export const successReq = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  const accessToken = localStorage.getItem('accessToken')?.replaceAll(`"`, '')
  const captchaToken = localStorage.getItem('captchaToken')?.replaceAll(`"`, '')

  if (accessToken) config.headers.set(AUTH_HEADER, `Bearer ${accessToken}`)
  if (env.isDev) config.headers.set(DEBUG_COUNTRY, 'IDN')
  if (captchaToken) config.headers.set(RECAPTCHA_TOKEN, captchaToken)

  config.headers.set('X-Application-ID', '1')
  config.headers.set('X-Application-Platform', 'web')
  config.headers.set('X-Application-Product', 'headway')
  config.headers.set('X-Application-Web-Version', env.appVersion)
  config.headers.set(
    'X-Analytics-Firebase-ID',
    getCookie('_ga')?.substring(6) || '',
  )
  config.headers.set(
    'X-User-Language',
    i18n.language.slice(0, 2) || localStorage.i18nextLng.slice(0, 2) || 'en',
  )

  return config
}
