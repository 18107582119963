import { Trans, useTranslation } from 'react-i18next'

export const Contacts = () => {
  const [t] = useTranslation()

  return (
    <div className="mt-[64px] mobile:mt-[32px] p-[16px] pt-[32px] text-center text-body.medium leading-body.medium">
      <Trans
        t={t}
        i18nKey="Need something else? Contact us at IB@hw.site"
        values={{ email: 'partner@hw.site' }}
        defaults="Need something else? Contact us at <2>{{ email }}</2>"
        components={{
          1: (
            <a
              href="mailto:partner@hw.site"
              target="_blank"
              className="font-medium text-sunray.700"
              rel="noreferrer"
            />
          ),
        }}
      />
    </div>
  )
}
