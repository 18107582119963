import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { delEmptyNumFloat } from 'ui/molecules/hooksForm/FormInput';


export type CreateWithdrawalResponse = {
  id: string;
  needOTP: boolean;
  redirectUrl: string;
}


type FormData = Record<string, any> & {
  amount: string;
}

type CreateWithdrawalReqBody = {
  gatewayId: string
  sourceId: string
  sourceType: 'wallet' | 'account' | string;
  amount?: string;
  currency?: string;
  formData: FormData;
  otp?: string;
}

// Error Type
export type ErrorCreateWithdrawal = {
  code: number;
  msg: string;
  details?: {
    validationErrors: {
      items: {
        field: string;
        msg: string;
        tKey: string;
      }[];
    };
  }
};

const createWithdrawal = async ({
  gatewayId,
  sourceId,
  sourceType,
  amount, currency,
  otp,
  formData,
}: CreateWithdrawalReqBody) => {
  let amountParse = delEmptyNumFloat(amount!);
  
  const response = await axios.post<CreateWithdrawalResponse>(
    '/payments/v2/create-withdrawal',
    {
      gatewayId,
      sourceID: sourceId,
      sourceType,
      amount: amountParse,
      currency,
      formData: {
        ...formData,
        amount: amountParse,
      },
      otp,
    },
  )

  return response.data;
}

export const useCreateWithdrawal = () => {
  return useMutation({
    mutationFn: createWithdrawal,
  })
}
