import { useMutation } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { CreateCardWithdrawalParams, CreateWithdrawalResp } from './types';

const postCreateCardWithdrawal = async (data: CreateCardWithdrawalParams) => {
  const res = await axios.post<CreateWithdrawalResp>(`/payments/v1/create-card-withdrawal`, data);

  return res.data;
};

export const usePostCreateCardWithdrawal = () => {
  return useMutation({
    mutationFn: (data: CreateCardWithdrawalParams) => postCreateCardWithdrawal(data),
    retry: retryFn,
  })
};
