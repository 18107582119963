import { useTranslation } from 'react-i18next'

import { formatNumber } from 'pages/copytrade/utils'
import * as Styled from './styled'

export const WinRateProgress = ({
  isExtended,
  valuesPosition,
  profit,
  loss,
  className = '',
  profitProgress,
  profitableOrders,
  lossOrders,
}: {
  isExtended?: boolean
  valuesPosition?: 'top' | 'bottom'
  profit: string;
  className?: string;
  loss: string;
  profitableOrders?: number
  lossOrders?: number
  profitProgress: number
}) => {
  const [t] = useTranslation()

  return (
    <Styled.Container className={ className }>
      {valuesPosition === 'bottom' && (
        <Styled.Line
          progress={profitProgress}
          type="profit"
          isRounded={!isExtended}
        />
      )}

      <div className="flex items-center justify-between">
        <Styled.ParamWrapper>
          {+profit !== 0 ? '+' : ''}${formatNumber(profit)}{' '}
          <Styled.ParamTitle>{t('Profit')}</Styled.ParamTitle>
        </Styled.ParamWrapper>
        <Styled.ParamWrapper>
          {+loss !== 0 ? '-' : ''}${formatNumber(Math.abs(+loss))}{' '}
          <Styled.ParamTitle>{t('Loss')}</Styled.ParamTitle>
        </Styled.ParamWrapper>
      </div>

      {valuesPosition !== 'bottom' ? (
        <div>
          <Styled.Line
            progress={profitProgress}
            type="profit"
            isRounded={!isExtended}
          />
          {isExtended && profitableOrders && lossOrders ? (
            <Styled.Line
              progress={profitableOrders / (profitableOrders + lossOrders)}
              type="orders"
            />
          ) : null}
        </div>
      ) : null}
    </Styled.Container>
  )
}
