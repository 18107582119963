import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { PARTNER_WIDGET_KEY } from '../get/useGetPartnerPage'

const withdrawalPartner = async (amount: string) => {
  const response = await axios.post(
    '/ib/v1/partner/withdraw',
    {
      withdrawalAmount: amount,
    },
  )

  return response
}

export const useWithdrawalPartner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: withdrawalPartner,
    onSuccess() {
      queryClient.invalidateQueries([PARTNER_WIDGET_KEY]);
    },
  })
}
