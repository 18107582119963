import * as React from 'react'
import styled from 'styled-components/macro'

import { Icon, Input, InputProps } from '../../atoms'

export type SearchProps = {} & Pick<
  InputProps,
  'className' | 'name' | 'label' | 'placeholder'
>

export const Search = ({
  className,
  name,
  label,
  placeholder,
}: SearchProps) => {
  return (
    <SearchContainer className={className}>
      <Input
        name={name}
        label={label}
        placeholder={placeholder}
        suffix={<Icon name="chevronDown" />}
      />
    </SearchContainer>
  )
}

const SearchContainer = styled.div``
