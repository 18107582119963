import styled from 'styled-components/macro'

export const Icon = styled.svg`
  path {
    stroke: var(--color-gray-500);
    transition: stroke var(--transition);
  }
`

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover ${Icon} path {
    stroke: var(--color-gray-700);
  }

  .tippy-content {
    padding: 8px;
    min-width: 200px;
  }
`