import React from 'react';
import { Text, Icon, Spinner2 } from 'ui/atoms';

import * as S from './styles';

export const SocialPreloader = () => {
  return (
    <>
      <S.InformationBlockItem>
        <div className='flex'>
          <Icon name="google" size="medium" />
          <Text className='ms-[0.8rem]'>Facebook</Text>
        </div>
        <Spinner2 />
      </S.InformationBlockItem>

      <S.InformationBlockItem>
        <div className='flex'>
          <Icon name="apple" size="medium" />
          <Text className='ms-[0.8rem]'>Apple</Text>
        </div>
        <Spinner2 />
      </S.InformationBlockItem>

      <S.InformationBlockItem>
        <div className='flex'>
          <Icon name="facebook" size="medium" />
          <Text className='ms-[0.8rem]'>Facebook</Text>
        </div>
        <Spinner2 />
      </S.InformationBlockItem>
    </>
  )
}
