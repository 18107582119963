import { useUnit } from 'effector-react';
import { useIsMobile } from 'libs/hooks/useIsMobile';
import React from 'react';
import { useGetFinancialOperations } from 'services/copytrade/get/useGetFinancialOperations';
import { Spinner2, SpinnerWithBlur, Text } from 'ui/atoms';
import { Pagination } from 'ui/atoms/pagination/Pagination';
import { THead, TableWrapper, Td } from 'ui/molecules';

import { $financialHistory, setPageFinancialEvent } from './model/financialHistoryModel';

export const Table = () => {
  const { strategyId, page, perPage } = useUnit($financialHistory);

  const { data, isInitialLoading, isRefetching } = useGetFinancialOperations({ page, strategyId });

  const isMobile = useIsMobile();

  if (!data || isInitialLoading) { 
    return (
      <div className='mt-[16px] w-full flex justify-center'>
        <Spinner2 />
      </div>
    )
  }

  return (
    <div className="mt-[16px]">
      <TableWrapper className="relative">
        <SpinnerWithBlur size="xl" isShow={isRefetching} />
        <table className="w-full" border={1}>
          <THead
            trClasses=""
            textLevel={isMobile ? 4 : 3}
            heads={[
              { label: 'Strategy' },
              { label: 'Period' },
              {
                label: 'Commission for copying',
                align: 'right',
                textClass: 'lg:!text-[12px] !text-[9px] !leading-[1.2rem]',
              },
            ]}
          />
          <tbody>
            {data.items?.map((item, index) => (
              <tr
                key={`${item.operationDate}-${index}`}
                className={
                  index !== data.items.length - 1
                    ? 'border-b-[0.75px] border-gray.300 border-solid'
                    : ''
                }
              >
                <Td width={'40%'}>
                  <Text weight="medium" level={isMobile ? 4 : 2}>
                    {item.strategyName}
                  </Text>
                </Td>
                <Td width={'20%'}>
                  <Text level={isMobile ? 4 : 3}>{item.operationDate}</Text>
                </Td>
                <Td align="right" width={'40%'}>
                  <Text
                    level={isMobile ? 4 : 2}
                    withTranslate={false}
                    weight="medium"
                    className="!text-eucalyptus.600"
                  >
                    ${item.profit}
                  </Text>
                </Td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>

      {data && Number(data?.totalRecords) > perPage && (
        <Pagination
          pageSize={perPage}
          current={page}
          total={Number(data.totalRecords)}
          onChange={setPageFinancialEvent}
          classNames="mt-[2.4rem]"
        />
      )}
    </div>
  )
}
