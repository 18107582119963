import styled from 'styled-components/macro'
import { Button, Title } from 'ui/atoms'

import background from './background.jpg'
import backgroundWeb from './background.webp'

export const Wrapper = styled.div`
  padding: 16px;
  min-height: 426px;
  display: flex;
  flex-direction: column;
  background-color: #eae8e8;
  background-image: url(${background});
  background-image: image-set(url(${backgroundWeb}), url(${background}));
  background-size: cover;
  background-position: bottom center;
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
    0px 4px 6px -1px rgba(38, 33, 25, 0.1);
  border-radius: 8px;
`

export const BannerTitle = styled(Title)`
  margin-bottom: 16px;
`

export const GetButton = styled(Button)`
  width: 153px;
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
    0px 4px 6px -1px rgba(38, 33, 25, 0.1);
`
