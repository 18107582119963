import { useStore } from 'effector-react'
import RcUpload from 'rc-upload'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { useIsMobile } from 'libs/hooks/useIsMobile'
import { Text } from 'ui/atoms';
import { Icon2 } from 'ui/atoms/icon2'
import {
  $loading,
  $uploadFiles,
  $uploadingErrors,
  setUploadFile,
  upload,
} from './model'
import { mounted } from './model'


export type AcceptTypes = 'gif' | 'jpg' | 'jpeg' | 'png' | 'pdf'

type Props = {
  name: string
  multiple?: boolean
  type?: 'drag'
  accept?: AcceptTypes[]
  label?: React.ReactNode
  limit?: number
  error?: string
  disabled?: boolean
  defaultUploadPlace?: {
    title: JSX.Element | string;
    icon: 'plus' | 'cloudArrowUp';
  }[];
  // TODO флаг костыль, весь компонент нужно переписать по хорогему и заюзать новый
  // UploadFiles новый компонент
  isResetDefaultFiles?: boolean
}

export const UploadDocuments = ({
  type = 'drag',
  accept = [],
  multiple = false,
  limit = 5,
  name,
  error,
  isResetDefaultFiles,
  defaultUploadPlace = [],
  disabled,
}: Props) => {
  const [t] = useTranslation();
  const isMobile = useIsMobile();

  const uploadFiles = useStore($uploadFiles)
  const loading = useStore($loading)
  const uploadingErrors = useStore($uploadingErrors)

  useEffect(() => {
    mounted()
  }, [])

  const defaultUploadArray = useMemo(() => {
    if (!uploadFiles?.length) return defaultUploadPlace;
  
    return defaultUploadPlace.slice(uploadFiles.length - 1, defaultUploadPlace.length - 1);
  }, [defaultUploadPlace, uploadFiles]);

  return (
    <Container data-disabled={disabled}>
      <Files>
        {uploadFiles.map((file, key) => {
          const { uid, src } = file

          const isLoading = loading.includes(uid)
          const error = uploadingErrors.find((e) => e.uid === uid)

          return (
            <LocalFile
              key={key}
              image={src}
              data-loading={isLoading}
              data-error={Boolean(error)}
              data-disabled={disabled}
            >
              <embed src={src} width={100} height={100}></embed>
              {error && <FileError>{error.error}</FileError>}
              <Delete onClick={() => setUploadFile(file)}>✕</Delete>
            </LocalFile>
          )
        })}

        {
          defaultUploadArray?.map((item, index) => (
            <Add data-disabled={disabled} key={`default_upload_${index}`} isBorder>
            <RcUpload
              name={`${name}${type && `-${type}`}`}
              onStart={(file) => upload({ file })}
              customRequest={() => {}}
              type={type}
              multiple={multiple}
              accept={accept?.map((a) => `.${a}`).join(',')}
            />

            <Label>
              <LabelIcon>
                <UploadIcon name={item.icon || 'plus'} />
              </LabelIcon>
              <LabelText>{item.title || t('Add More')}</LabelText>
            </Label>
          </Add>
          ))
        }
        
        {(uploadFiles.length < limit && !isMobile) && (
          <Add data-disabled={disabled}>
            <RcUpload
              name={`${name}${type && `-${type}`}`}
              onStart={(file) => upload({ file })}
              customRequest={() => {}}
              type={type}
              multiple={multiple}
              accept={accept?.map((a) => `.${a}`).join(',')}
            />

            { <Label>
                <LabelIcon>
                  <UploadIcon name="plus" />
                </LabelIcon>
                <LabelText>{t('Add More')}</LabelText>
              </Label>
            }
          </Add>
        )}
      </Files>

      {uploadFiles.length < limit && isMobile && (
        <MAdd data-disabled={disabled}>
          <RcUpload
            name={`${name}${type && `-${type}`}`}
            onStart={(file) => upload({ file })}
            customRequest={() => {}}
            type={type}
            multiple={multiple}
            accept={accept?.map((a) => `.${a}`).join(',')}
          />

          { <div className='mt-[16px] flex'>
              <UploadIconMobile size='base' name='plus' />
              <Text
                className='!text-bronze.500 ml-[8px]'
                withTranslate={false}
                level={2}
              >
                {t('Add More')}
              </Text>
            </div>
          }
        </MAdd>
      )}

      {error ||
        (uploadFiles.length >= limit && (
          <FilesError>
            {uploadFiles.length >= limit && (
              <>
                <FilesErrorIcon name="exclamationCircle" size="small" /> You can
                upload maximum {limit} documents
              </>
            )}
            {error}
          </FilesError>
        ))}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  &[data-disabled='true'] {
    cursor: no-drop;
    opacity: 0.75;
  }
`

//

const UploadIcon = styled(Icon2)`
  width: 14px;
  height: 14px;
  color: var(--color-bronze-500);
`

const UploadIconMobile = styled(Icon2)`
  /* width: 20x !important;
  height: 20px !important; */
  color: var(--color-bronze-500);
`

const LabelIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: none;
  background-color: var(--color-sunray-100);
  transition: var(--transition);
`

const Files = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  @media (max-width: 599px) {
    gap: 35px;
  }
`

const LocalFile = styled.div<{ image: string | null }>`
  width: 140px;
  height: 140px;
  display: block;
  position: relative;
  transition: var(--transition);

  &:before {
    content: '';
    background-image: url(${(p) => p.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
    width: 140px;
    height: 140px;
    position: absolute;
    z-index: 3;
    top: 0;
    inset-inline-start: 0;
    border-radius: 8px;
  }

  embed {
    width: 140px;
    height: 140px;
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    border-radius: 8px;
  }

  &[data-loading='true'] {
    opacity: 0.75;
  }

  &[data-loading='false'] {
    opacity: 1;
  }

  &[data-error='true'] {
    margin-bottom: 24px;
  }

  &[data-disabled='true'] {
    pointer-events: none;
  }
`

const FileError = styled.div`
  position: absolute;
  bottom: 0;
  inset-inline-start: 0;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-opal-500);
  transform: translateY(calc(100% + 6px));
`

const Delete = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  inset-inline-end: -7px;
  top: -7px;
  background-color: rgba(38, 33, 25, 0.8);
  color: var(--color-gray-400);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background-color: rgba(38, 33, 25, 0.6);
  }
`

const Add = styled.div<{isBorder?: boolean}>`
  width: 140px;
  height: 140px;
  position: relative;
  border: ${(p) => p.isBorder ? '1.5px dashed var(--color-bronze-500)': ''};
  border-radius: 8px;

  & > span {
    width: 140px;
    height: 140px;
    display: block;
    cursor: pointer;
  }

  &:hover {
    ${LabelIcon} {
      background-color: var(--color-sunray-200);
    }
  }
  &[data-disabled='true'] {
    pointer-events: none;
  }
`

const MAdd = styled.div<{isBorder?: boolean}>`
  position: relative;

  & > span {
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    cursor: pointer;
  }

  &:hover {
    ${LabelIcon} {
      background-color: var(--color-sunray-200);
    }
  }
  &[data-disabled='true'] {
    pointer-events: none;
  }
`

const Label = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  inset-inline-start: 0;
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const MobileAddButton = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  inset-inline-start: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
`;

const LabelText = styled.div`
  margin-top: 12px;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.8rem;
  text-align: center;
  padding: 0 5px;
`

const FilesError = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
`

const FilesErrorIcon = styled(Icon2)`
  color: var(--color-opal-500);
  margin-inline-end: 6px;
  margin-bottom: -1px;
`