import { formatNumber } from 'pages/copytrade/utils';
import React from 'react';
import { Progress } from 'ui/molecules';

import { ProgressTitle } from './ProgressTitle';

type Props = {
  numProfitableOrders: number;
  numLossOrders: number;
  profit: number;
  loss: number;
}

export const PerfomanceProgress: React.FC<Props> = ({ numLossOrders, numProfitableOrders, profit, loss }) => {
  const getOrderProgress = () => {
    if (!numProfitableOrders) return 0; 
    if (numProfitableOrders && !numLossOrders) return 100;

    return Math.floor(numProfitableOrders / (numProfitableOrders + numLossOrders) * 100);
  }

  const getProfitProgress = () => {
    if (!profit) return 0; 
    if (profit && !loss) return 100;

    return Math.floor(profit / (profit + Math.abs(+loss)) * 100);
  }

  return (
    <div className="mt-[2.4rem] prevent-rtl">
      <ProgressTitle
        startValue={`${+profit !== 0 ? '+' : ''}$${formatNumber(profit)}`}
        endValue={`${+loss !== 0 ? '-' : ''}$${formatNumber(Math.abs(+loss))}`}
        startLabel="Profit"
        endLabel="Loss"
        className="lg:mb-[8px] mb-[6px]"
      />
      <Progress
        progress={getProfitProgress()}
        isSkipIsNull
        wrapClasses="lg:!h-[12px] !h-[8px] !rounded-none !bg-opal.500"
        progressClasses="!rounded-none !bg-eucalyptus.500"
      />

      <Progress
        progress={getOrderProgress()}
        wrapClasses="lg:!h-[12px] !h-[8px] !rounded-none !bg-orange.500"
        progressClasses="!rounded-none !bg-azure.500"
        isSkipIsNull
      />
      <ProgressTitle
        startValue={numProfitableOrders}
        endValue={numLossOrders}
        startLabel="Successful orders"
        endLabel='Failed orders'
        className="lg:mt-[8px] mt-[6px]"
      />
    </div>
  )
}
