import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styled'

// todo: если понадобится, сделать более универсальной и вынести в ui/atoms

export const StrategyTextArea = ({
  value,
  onChange,
  className,
  error,
}: {
  value: string
  onChange: (value: string) => void
  className?: string
  error?: string
}) => {
  const [t] = useTranslation()

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value)
  }

  return (
    <div className={className}>
      <Styled.Label htmlFor="strategy-name">
        {t('Description')}{' '}
        <span className="text-gray.600">({t('optional')})</span>
      </Styled.Label>
      <Styled.TextArea
        id="strategy-name"
        placeholder={t('Enter a description (max. 1000 characters)') || ''}
        maxLength={1000}
        onChange={handleChange}
        value={value}
        isError={!!error}
      />
      <Styled.Error>{error}</Styled.Error>
    </div>
  )
}
