import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { axios } from 'services/axios'

import type { StrategyForm } from '../types'

const updateStrategy = async (strategyForm: StrategyForm) => {
  const res = await axios.patch<{}, AxiosResponse<{}>, StrategyForm>(
    '/copytrade/v1/trader/strategy',
    strategyForm,
  )

  return res.data
}

export const useUpdateStrategy = () => {
  return useMutation({
    mutationFn: updateStrategy,
  })
}
