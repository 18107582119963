import styled from 'styled-components/macro'
import { Wrapper as AccountLineWrapper, Tariff } from 'ui/molecules/accountLines/styled'

export const Wrapper = styled.div`
  padding: 14px 12px;
  background-color: var(--color-gray-200);
  border-radius: 8px;

  ${AccountLineWrapper} {
    font-size: var(--body-font-size-regular);
    line-height: var(--body-line-height-regular);
  }

  ${Tariff} {
    font-weight: 500;
  }
`