import { createEvent, restore, sample } from 'effector'

import { fx } from '../../../libs'
import { AccountResponse } from '../types'

export const submitPasswordChange = createEvent<string>()

export const putAccountPassword = fx<{ accountID: string }, AccountResponse>({
  method: 'PUT',
  url: `/account/v1/account/password`,
})

export const $passwordHasBeenChanged = restore(
  putAccountPassword.done.map(() => true),
  false,
).reset(submitPasswordChange)

sample({
  clock: submitPasswordChange,
  fn: (accountID) => ({
    body: {
      accountID,
    },
  }),
  target: putAccountPassword,
})
