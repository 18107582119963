import styled from 'styled-components';


export const Wrapper = styled.div`
  padding: 24px 20px;
  background-color: var(--color-gray-100);
  box-shadow: 0px 4px 6px -1px rgba(38, 33, 25, 0.1),
    0px 2px 4px -1px rgba(38, 33, 25, 0.06);
  border-radius: 0px 8px 8px 8px;
  height: auto;
`