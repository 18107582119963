import styled, { css } from 'styled-components/macro'
import { Button as UiButton } from 'ui/atoms'

export const Wrapper = styled.div<{ hasBorder: boolean }>`
  padding: 40px;
  display: grid;
  justify-items: center;
  align-content: center;
  text-align: center;
  gap: 40px;

  @media (max-width: 599px) {
    padding: 16px;
    gap: 24px;
  }

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      background-color: var(--color-gray-100);
      border-radius: 8px;
      box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
        0px 4px 6px -1px rgba(38, 33, 25, 0.1);
    `}
`

export const Button = styled(UiButton)`
  min-width: 240px;

  @media (max-width: 430px) {
    width: 100%;
  }
`

export const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 600px;
  height: auto;
`
