import styled, { css } from 'styled-components/macro'
import { Title as UiTitle } from 'ui/atoms'

import cash from '../../images/cash-icon.svg'
import coin from '../../images/coin-icon.png'
import ticket from '../../images/ticket-small.png'

export const Wrapper = styled.div`
  margin-top: 56px;
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 599px) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
`

export const Title = styled(UiTitle)`
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: 599px) {
    margin-bottom: 16px;
    line-height: 40px !important;
  }
`

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 130px 120px 160px;

  @media (max-width: 1130px) {
    grid-template-columns: 90px 1fr 130px 120px 130px;
  }

  @media (max-width: 799px) {
    padding: 16px 12px;
    grid-template-columns: 36px 102px 1fr max-content;
    gap: 16px 0;
  }
`

export const TableHead = styled(TableRow)`
  margin-bottom: 4px;
`

export const HeadCell = styled.div<{ align?: 'left' | 'center' | 'right' }>`
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  padding: 0 16px;
  height: 40px;
  display: grid;
  align-items: center;
  text-align: ${({ align }) => align || 'center'};
  color: var(--color-gray-600);
`

export const TableBody = styled.div`
  display: grid;
  gap: 16px;

  @media (max-width: 599px) {
    gap: 8px;
  }
`

export const BodyRow = styled(TableRow)<{ highlighted: boolean }>`
  min-height: 100px;
  border-radius: 16px;
  background-image: ${({ highlighted }) =>
    highlighted
      ? 'linear-gradient(90deg, #fefaf1 0%, #ffe78f 100%)'
      : 'linear-gradient(90deg, #fefaf1 0%, #edfff6 100%)'};

  @media (max-width: 799px) {
    min-height: auto;
    background-image: ${({ highlighted }) =>
      highlighted
        ? 'linear-gradient(90deg, #fffdf4 0%, #ffe891 100%)'
        : 'linear-gradient(90deg, #fffdf4 0%, #eefff6 100%)'};
  }
`

export const MedalWrapper = styled.div`
  display: grid;
  place-items: center;
  font-weight: 500;

  img {
    width: 48px;
    height: auto;

    @media (max-width: 799px) {
      margin-top: -15px;
    }
  }

  @media (max-width: 799px) {
    margin-top: 15px;
    margin-inline-start: -6px;
    width: 48px;
    grid-row: span 2;
    align-items: start;
    font-size: var(--body-font-size-medium);
  }
`

export const NameWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline-start: 16px;
  display: grid;
  align-content: center;
  gap: 8px;
  line-height: 20px;
  font-weight: 500;
  word-break: break-word;

  @media (max-width: 799px) {
    padding-top: 0;
    padding-bottom: 0;
    padding-inline-start: 12px;
    min-height: 50px;
    gap: 4px;
    grid-column: span 2;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const PointsWrapper = styled.div`
  margin: auto;
  text-align: right;

  @media (max-width: 799px) {
    margin: auto 0;
  }
`

export const PointsValue = styled.div`
  padding-inline-start: 24px;
  background-image: url(${coin});
  background-size: contain;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'right center' : 'left center'};
  background-repeat: no-repeat;

  @media (max-width: 799px) {
    padding-inline-start: 20px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const CashPrize = styled.div`
  margin: auto;

  @media (max-width: 799px) {
    margin: auto 0;
    padding-inline-start: 12px;
  }
`

export const CashValue = styled.div`
  padding-inline-start: 24px;
  background-image: url(${cash});
  background-size: 27px auto;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'right center' : 'left center'};
  background-repeat: no-repeat;

  @media (max-width: 799px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const ChanceWrapper = styled.div`
  display: grid;
  gap: 4px;
  align-content: center;
  justify-items: center;
  text-align: center;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);

  @media (max-width: 799px) {
    justify-items: start;
    color: var(--color-gray-600);
  }
`

export const Ticket = styled.div`
  width: 80px;
  font-size: var(--body-font-size-regular);
  line-height: 32px;
  text-align: center;
  color: var(--color-gray-700);
  background-image: url(${ticket});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 799px) {
    width: 70px;
    line-height: 28px;
    font-size: var(--body-font-size-medium);
  }
`

export const PrizeWrapper = styled.div`
  padding: 10px;
  display: grid;
  place-items: center;
  gap: 2px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  text-align: center;
  word-break: break-word;

  @media (max-width: 799px) {
    padding: 0;
    grid-column: span 2;
    grid-template-columns: 32px 1fr;
    justify-items: start;
    align-items: center;
    gap: 6px;
    text-align: left;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const ImageWrapper = styled.div`
  @media (max-width: 799px) {
    overflow: hidden;
    height: 50px;
    border-radius: 4px;
    background-image: radial-gradient(
      50% 50% at 50% 50%,
      #67d7b2 0%,
      #45b892 100%
    );
  }

  img {
    width: 60px;
    height: auto;

    @media (max-width: 799px) {
      width: 100%;
      height: 100%;
      transform: translateX(-30%) scale(1.5, 1.5);
      object-fit: contain;
      object-position: center center;
    }
  }
`

export const MobileTitle = styled.div`
  margin-bottom: 4px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);
`

export const TableWrapper = styled.div<{
  moreColumns: boolean
  isSimple: boolean
}>`
  ${({ moreColumns }) =>
    moreColumns &&
    css`
      ${TableRow} {
        grid-template-columns: 90px 1fr 130px 101px 120px 160px;

        @media (max-width: 1130px) {
          grid-template-columns: 90px 1fr 130px 101px 120px 130px;
        }

        @media (max-width: 799px) {
          grid-template-columns: 36px 102px 1fr max-content;
        }
      }
    `}

  ${({ isSimple }) =>
    isSimple &&
    css`
      ${BodyRow} {
        background-image: none;
        box-shadow: inset 0px 0px 0px 1px var(--color-gray-400);
      }
    `}
`
