import { createEvent, restore } from 'effector'
import { persist } from 'effector-storage/local'

export const setIBDashboardInfoVisibility = createEvent<boolean>()
export const $isIBDashboardInfoVisible = restore(setIBDashboardInfoVisibility, true)

persist({
  store: $isIBDashboardInfoVisible,
  key: 'isIBDashboardInfoVisible',
})

export const setIBMaterialsInfoVisibility = createEvent<boolean>()
export const $isIBMaterialsInfoVisible = restore(setIBMaterialsInfoVisibility, true)

persist({
  store: $isIBMaterialsInfoVisible,
  key: 'isIBMaterialsInfoVisible',
})
