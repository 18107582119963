import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { WithdrawalCardsList } from './types';

export const GET_WITHDRAWAL_CARDS_KEY = ['withdrawal-cards'];

const getWithdrawalCards = async () => {
  const res = await axios.get<WithdrawalCardsList>(`/payments/v1/withdrawal-cards-list`);

  return res.data;
};

export const useGetWithdrawalCards = (): UseQueryResult<WithdrawalCardsList> => {
  return useQuery({
    queryKey: GET_WITHDRAWAL_CARDS_KEY,
    queryFn: getWithdrawalCards,
    select(data) {
      if (!data) return {
        destinationInfo: [],
      };

      const mappedArray = data.destinationInfo?.map((card) => ({
        ...card,
        id: `${card.destinationID}_${card.currency}`,
      }));

      return {
        destinationInfo: mappedArray,
      }
    },
    retry: retryFn,
    staleTime: 1000,
  })
};
