import React from 'react';
import { DepositFormListItem } from 'services/payments/get/DepositFormTypes';

import { TextField, NumberField, SelectDynamic } from './elements';

type Props = {
  formFields?: DepositFormListItem[];
}

export const Form: React.FC<Props> = ({ formFields = [] }) => {
  const isNonEmptyFields = formFields?.length > 1;
  
  const getFieldItem = (item: DepositFormListItem, index: number) => {
    if (!isNonEmptyFields) return null;

    if (item.type === 'FIELD_TYPE_AMOUNT') return null;

    if (item.type === 'FIELD_TYPE_TEXT') {
      return <TextField item={item} key={index} />
    }

    if (item.type === 'FIELD_TYPE_NUMBER') {
      return <NumberField item={item} key={index} />
    }

    if (item.type === 'FIELD_TYPE_DROP_DOWN_LIST') {
      return <SelectDynamic item={item} key={index} />
    }

    return null;
  };
  
  if (!isNonEmptyFields) return null;

  return (
    <div className='mt-[48px] flex flex-col gap-[16px] w-full items-center'>
      {
        formFields.map((fieldItem, index) => getFieldItem(fieldItem, index))
      }
    </div>
  )
}
