import React from 'react';
import styled from "styled-components/macro";
import { THead, TableHeadItem } from './THead';

interface Props extends React.PropsWithChildren {
  heads: TableHeadItem[];
  isLoading?: boolean;
  classes?: string;
}

// TODO доработать концепт
export const Table: React.FC<Props> = ({ heads, children, classes = '' }) => {
  return (
    <TableUiWrapper className={classes}>
      <table className='w-full' border={1}>
        <THead
          heads={heads}
        />
        <tbody>
          { children }
        </tbody>
      </table>
    </TableUiWrapper>
  )
}

const TableUiWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-width: 100%;

  @media (max-width: 500) {
    width: 100%;
    display: block;
  }
`