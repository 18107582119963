import styled from 'styled-components/macro'
import { Title } from 'ui/atoms'
import { Link } from 'react-router-dom'

import { TraderPreview as TraderPreviewMolecule } from '../traderPreview'

export const Container = styled(Link)`
  cursor: pointer;
  position: relative;
  border: 1px solid var(--color-gray-300);
  padding: 16px 16px 20px;
  display: grid;
  gap: 12px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 0px rgba(38, 33, 25, 0.1);

  @media (max-width: 599px) {
    gap: 2px;
  }
`

export const TopRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const TraderPreview = styled(TraderPreviewMolecule)`
  margin-inline-end: auto;
  max-width: 170px;
`

export const MiddleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const GainWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`

export const GainValue = styled(Title)<{ color: string }>`
  color: ${({ color }) => color};
`

export const BottomRow = styled.div``
