import { useQuery } from '@tanstack/react-query'
import qs from 'qs'
import { axios } from 'services/axios'

export const GET_IB_WEBSITE_BANNERS_KEY = ['ib-website-banners']

type Filter = Record<
  string,
  string | number | number[] | string[] | boolean | null
>

export type WebsiteBanner = {
  id: number
  dimensions: string
  extension: string
  format: string
  name: string
  originLink: string
  previewLink: string
  sharedLink: string
  size: string
  title: string
}

const getWebsiteBanners = async (
  filters: Filter,
  page: number,
  perPage: number,
) => {
  const res = await axios.get<{
    items: WebsiteBanner[]
    sharedLink: string
    totalPages: number
    totalRecords: number
  }>('/ib-materials/v1/website-banners', {
    params: { ...filters, page, perPage },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })

  return res.data
}

export const useGetWebsiteBanners = (
  filters: Filter,
  page: number,
  perPage: number,
) => {
  return useQuery({
    queryKey: [GET_IB_WEBSITE_BANNERS_KEY, { filters, page, perPage }],
    queryFn: () => getWebsiteBanners(filters, page, perPage),
    keepPreviousData: true,
    enabled: false,
  })
}
