import styled from 'styled-components/macro'
import { Button } from 'ui/atoms'

import { StatisticsTable } from '../statisticsTable'

export const Wrapper = styled.div<{ noMargin: boolean }>`
  border: 1px solid var(--color-sunray-300);
  padding: 32px 48px;
  display: grid;
  justify-items: center;
  text-align: center;
  background-color: var(--color-sunray-050);
  border-radius: 16px;

  @media (max-width: 599px) {
    padding: 24px 16px;
    margin-bottom: ${({ noMargin }) => (noMargin ? '20px' : '0')};
  }
`

export const Text = styled.div`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);

  @media (max-width: 599px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const DepositButton = styled(Button)`
  margin-top: 24px;

  @media (max-width: 599px) {
    width: 100%;
  }
`

export const Table = styled(StatisticsTable)`
  margin-bottom: 32px;

  @media (max-width: 599px) {
    margin-bottom: 24px;
  }
`
