import {
  Effect,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { persist } from 'effector-storage/local'
import { toPath } from 'features/router'
import { setUserNonAuthRole } from 'features/user/model'
import { $isTokenRefreshing } from 'services/axios/axiosStateModel'

import { $userProfile, UserRole, getUser } from '../../features/user'
import { $userRole } from '../../features/user'
import { fx, path } from '../../libs'
import { Request } from '../../libs/request'
import { service } from '../../services'
import { $queue, clearQueue } from '../domain/generic'
import { Session } from './types'
import { isNeedRefreshReq } from './utils'

export const refreshToken = fx<{ refreshToken: string }, Session>({
  method: 'PATCH',
  url: '/auth/v1/login/refresh',
})

export const logout = createEvent()
export const appMounted = createEvent()

export const setAccessToken = createEvent<string>()
export const $accessToken = restore(setAccessToken, null)

export const setRefreshToken = createEvent<string>()
export const $refreshToken = restore(setRefreshToken, null)

export const $mobileAdVisible = createStore(true)
export const closeMobileAd = createEvent()

export const $bonusVisible = createStore(true)
export const closeBonus = createEvent()

// sync setting tokens with session storage

persist({
  store: $accessToken,
  key: 'accessToken',
})

persist({
  store: $refreshToken,
  key: 'refreshToken',
})

// sync banners

persist({
  store: $mobileAdVisible,
  key: 'mobileAdVisible',
})

persist({
  store: $bonusVisible,
  key: 'bonusVisible',
})

// update session if refresh success

$accessToken
  .on(
    refreshToken.doneData,
    (_, doneData) => doneData.body.identificationResult.accessToken,
  )
  .on(
    service.auth.getCheckResetPasswordCode.doneData,
    (_, doneData) => doneData.body.identificationResult.accessToken,
  )
  .on(logout, () => null)

$refreshToken
  .on(
    refreshToken.doneData,
    (_, doneData) => doneData.body.identificationResult.refreshToken,
  )
  .on(
    service.auth.getCheckResetPasswordCode.doneData,
    (_, doneData) => doneData.body.identificationResult.refreshToken,
  )
  .on(logout, () => null)

// if refresh invalid requests => update refresh/access
// Рефетч
sample({
  clock: $queue,
  source: $refreshToken,
  filter: (src, clk) => {
    return isNeedRefreshReq(clk)
  },
  fn: (refreshToken, clk) => {
    return {
      body: {
        refreshToken: refreshToken || '',
      },
    }
  },
  target: refreshToken,
})

// Сеттим состояние загрузки
sample({
  clock: $queue,
  filter: (clk) => {
    return isNeedRefreshReq(clk)
  },
  fn: () => true,
  target: $isTokenRefreshing,
})

// Скидываем состояние загрузки
sample({
  clock: [refreshToken.doneData, refreshToken.failData],
  fn: () => {
    return false
  },
  target: $isTokenRefreshing,
})

const multipleRequestsFx = createEffect(
  async ({
    payloads,
  }: {
    payloads: {
      fx: Effect<unknown, unknown, unknown>
      params: Request<object>
    }[]
  }) => {
    for (const payload of payloads) {
      try {
        await payload.fx(payload.params)
      } catch (error) {
        // console.log(error, 'refresh-Error');
      }
    }
  },
)

sample({
  source: $queue,
  clock: refreshToken.doneData,
  fn: (payloads, doneData) => ({
    payloads,
    accessToken: doneData.body.identificationResult.accessToken,
  }),
  target: [multipleRequestsFx],
})

sample({
  clock: multipleRequestsFx.done,
  target: clearQueue,
})

//

sample({
  clock: [appMounted, setAccessToken],
  fn: () => ({}),
  target: getUser,
})

// if refresh token not valid - change role

sample({
  clock: refreshToken.failData,
  filter: (failData) => failData.body.code === 7,
  fn: () => {
    const role: UserRole = 'unauthorized'
    return role
  },
  target: $userRole,
})

// if no tokens - change role

sample({
  clock: [$accessToken, $refreshToken],
  source: [$accessToken, $refreshToken],
  fn: ([accessToken, refreshToken]) => {
    let role: UserRole = 'unauthorized'
    if (Boolean(accessToken) && Boolean(refreshToken)) {
      role = 'authorized'
    }
    return role
  },
  target: $userRole,
})

// close ads

sample({
  clock: closeMobileAd,
  fn: () => false,
  target: $mobileAdVisible,
})

sample({
  clock: closeBonus,
  fn: () => false,
  target: $bonusVisible,
})

// clear user
sample({
  clock: logout,
  fn: () => ({
    userID: '',
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    isEmailConfirmed: false,
    avatarURL: '',
    isVerified: false,
    userRoles: null
  }),
  target: $userProfile,
})

sample({
  clock: logout,
  target: [setUserNonAuthRole],
})
