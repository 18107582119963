export const getFullName = (
  firstName?: string,
  middleName?: string,
  lastName?: string,
) => (
  <>
    {lastName ? `${lastName} ` : ''}
    {firstName ? `${firstName} ` : ''}
    {middleName || ''}
  </>
)