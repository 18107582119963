import * as React from 'react'
import styled from 'styled-components/macro'
import { Button, Icon } from 'ui/atoms'
import { Search, Widget } from 'ui/molecules'

export const DepositWidget = () => {
  return (
    <Widget>
      <DepositAccount
        name="deposit-account"
        label="To"
        placeholder="Choose account"
      />
      <DepositMethod
        name="deposit-method"
        label="Deposit method"
        placeholder="Choose deposit method"
      />
      <DepositDetails>
        <DepositCurrencyInput
          name="deposit-currency"
          label="Currency"
          placeholder="USD"
        />
        <DepositAmountInput
          name="deposit-amount"
          label="Amount"
          placeholder="0"
        />
      </DepositDetails>
      <DepositFooter>
        <DepositSubmit
          name="deposit-submit"
          size="small"
          prefix={<Icon name="arrowDownOnSquare" />}
        >
          Deposit
        </DepositSubmit>
      </DepositFooter>
    </Widget>
  )
}

const DepositAccount = styled(Search)`
  margin-bottom: 16px;
`

const DepositMethod = styled(Search)`
  margin-bottom: 16px;
`

const DepositDetails = styled.div`
  display: flex;
  gap: 16px;
`

const DepositCurrencyInput = styled(Search)`
  width: 25%;
`

const DepositAmountInput = styled(Search)`
  width: 75%;
`

const DepositFooter = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const DepositSubmit = styled(Button)``
