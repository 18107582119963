import { useMutation, useQueryClient } from '@tanstack/react-query';

import { retryFn } from 'services/helpers/retryFn';
import { axios } from 'services/axios';
import { GET_PROFILE_KEY } from 'services/profile/getProfile/useGetProfile';
import { ProfileContent } from 'services/profile/getProfile';


interface Options {
  onSuccessMutation?: (params: OnSuccessVariables) => void;
}

type OptionsType = Options | undefined;
type OnSuccessVariables = {
  firstName: string;
  lastName: string;
}

interface RequestData {
  firstName: string;
  lastName: string;
}

const putChangeUserName = async (fields: RequestData) => {
  await axios.put('/user/v1/name', fields);
}


export const usePutChangeUserName = ({ onSuccessMutation }: OptionsType = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putChangeUserName,
    retry: retryFn,
    onSuccess(data, variables) {
      onSuccessMutation?.(variables);
      // Эта функция перезаписывает кэш
      queryClient.setQueryData
        (
          GET_PROFILE_KEY, 
          (oldData: any) => ({ 
            ...oldData as ProfileContent, 
            firstName: variables.firstName,
            lastName: variables.lastName 
          }),
        );
    },
  })
}