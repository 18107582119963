import { useMutation } from '@tanstack/react-query';

import { retryFn } from 'services/helpers/retryFn';
import { axios } from 'services/axios';
import { Account } from '../types';


interface DataObj {
  accountID: string;
  amount: string;
}

interface Response {
  accountInfo: Account;
}

const addBalanceDemo = async (data: DataObj) => {  
  const resp = await axios.post<Response>('/account/v1/account/demo-deposit', data);

  return resp.data;
}

export const useAddBalanceDemo = () => {
  return useMutation({
    mutationFn: addBalanceDemo,
    retry: retryFn,
  })
}