import { StrategyFilterResp } from "services/copytrade/get/useGetTraderStrategiesForFilter";

export const defaultStrategiesFilter = [{ label: 'All strategies', value: '' }];

export type GetStrategiesOptions = {value: string, label: string}[];
export const getStrategiesOptions = (data?: StrategyFilterResp): GetStrategiesOptions => {
  const defaultFilter = [{ label: 'All strategies', value: '' }];
  if (!data?.items?.length) return defaultFilter;

  data.items.forEach(item => defaultFilter.push({
    value: item.strategyID,
    label: item.title,
  }));

  return defaultFilter;
}