import styled, { css } from 'styled-components/macro'
import { Button } from 'ui/atoms'
import { Text } from 'ui/atoms'

import target from '../images/target.svg'
import { GiftPrice } from '../molecules/giftPrice'

export const Container = styled.div`
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--color-gray-100);
  width: 100%;

  @media (max-width: 470px) {
    padding: 12px 12px 20px;
  }
`

export const SpinnerWrapper = styled.div`
  min-height: calc(100vh - 210px);
  display: grid;
  place-items: center;
`

export const ContentWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 16px 0 0;
  }
`

export const ImageWrapper = styled.div<{ isTarget: boolean }>`
  position: relative;
  margin: 0 auto 40px auto;
  width: 440px;
  height: 480px;
  border-radius: 8px;
  background-color: var(--color-gray-300);

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }

  @media (max-width: 570px) {
    padding-bottom: 112%;
    width: 100%;
    height: 0;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  ${({ isTarget }) =>
    isTarget &&
    css`
      &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 44px;
        height: 44px;
        background-image: url(${target});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        @media (max-width: 570px) {
          top: 12px;
          right: 12px;
          width: 32px;
          height: 32px;
        }
      }
    `}
`

export const Price = styled(GiftPrice)`
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;

  @media (max-width: 570px) {
    top: 12px;
    left: 12px;
  }
`

export const TextWrapper = styled.div`
  margin: 0 auto 40px auto;
  max-width: 50%;
  display: grid;
  gap: 24px;
  justify-items: center;

  @media (max-width: 768px) {
    margin-bottom: 32px;
    max-width: 100%;
    gap: 12px;
  }
`

export const Buttons = styled.div`
  margin: 0 auto 64px auto;
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }

  @media (max-width: 420px) {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
`

export const OrderButton = styled(Button)`
  min-width: 240px;

  @media (max-width: 640px) {
    min-width: auto;
  }

  @media (max-width: 420px) {
    width: 100%;
  }
`

export const OrderWrapper = styled.div`
  margin-top: 64px;
  width: 100%;
  display: grid;
  justify-items: center;

  @media (max-width: 768px) {
    margin-top: 32px;
  }
`

export const OrderText = styled(Text)`
  margin-top: 24px;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`