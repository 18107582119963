import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { toPath } from '../../../../features/router'
import { path } from '../../../../libs'
import { Button, RadioSingle, Title } from '../../../../ui/atoms'
import { setRecordedAccountData } from '../model'
import { useAccountTariff } from './hooks'
import { setActiveTariffName } from './model'

export const AccountsCreateChooseTariff = () => {
  const [t] = useTranslation()

  const {
    //
    tariffs,
    activeTariffName,
    accountType,
    recordedAccountData,
  } = useAccountTariff();

  return (
    <>
      <AccountTypes>
        <Title level={1}>Choose Account Type</Title>
        <AccountTypesList>
          {tariffs.map(({ name, isDisabled }, key) => {
            const isActive = name === activeTariffName

            return (
              <DisableContainer isDisabled={isDisabled} key={key}>
                <AccountType
                  isActive={isActive}
                  onClick={() => {
                    setActiveTariffName(name)

                    if (recordedAccountData.activeTariffName) {
                      setRecordedAccountData({
                        activeTariffName: name,
                      })
                    }
                  }}
                >
                  <TypeState>
                    <RadioSingle value="" checked={isActive} size="large" />
                  </TypeState>
                  <TypeDetails>
                    <AccountTypeName level={2}>
                      {name.replace('demo', '')}
                    </AccountTypeName>
                    <AccountTypeDetails>
                      <AccountTypeDetail>
                        <span>{t('Minimum lot size')}</span>
                        <span>{name === 'pro' ? '0.1' : '0.01'}</span>
                      </AccountTypeDetail>
                      <AccountTypeDetail>
                        <span>{t('Spread')}</span>
                        <span>floating from {name === 'pro' ? '0.0' : '0.3'}</span>
                      </AccountTypeDetail>
                      <AccountTypeDetail>
                        <span>{t('Commission')}</span>
                        <span>
                          {name === 'pro'
                            ? 'Up to 1.5$ each side per lot'
                            : 'No'}
                        </span>
                      </AccountTypeDetail>
                    </AccountTypeDetails>
                  </TypeDetails>
                </AccountType>
                {isDisabled && (
                  <DisableWarning>
                    You have accessed limits of account. Please{' '}
                    <Link to={path.deposit.entry()}>deposit</Link> amount in
                    order to create Cent Accounts
                  </DisableWarning>
                )}
              </DisableContainer>
            )
          })}
        </AccountTypesList>
      </AccountTypes>
      <AccountCreateNextButton
        name="account-create-to-platform"
        size="medium"
        onClick={() => {
          setActiveTariffName(activeTariffName);
          setRecordedAccountData({ 
            activeTariffName,
          });
          toPath(path.accounts.create.platform(accountType))
        }}
        disabled={!activeTariffName}
      >
        Next
      </AccountCreateNextButton>
    </>
  )
}

const AccountTypes = styled.div`
  margin-top: 60px;
`

const AccountTypesList = styled.div`
  margin-top: 60px;
  display: flex;
  gap: 20px;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`

const AccountType = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  border: 1px solid var(--color-gray-300);
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: border var(--transition);

  &:hover {
    border-color: var(--color-sunray-500);
  }

  ${(p) =>
    p.isActive &&
    css`
      outline: 1px solid var(--color-sunray-500);
      border-color: var(--color-sunray-500);
    `}
`

const AccountTypeName = styled(Title)`
  text-transform: capitalize;
  margin-bottom: 20px;
  width: 100%;
`

const TypeState = styled.div`
  margin-inline-end: 10px;
`

const TypeDetails = styled.div`
  width: 100%;
`

const AccountTypeDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);

  span:last-child {
    font-weight: 500;
    max-width: 110px;
    text-align: end;
  }
`

const AccountTypeDetail = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

const AccountCreateNextButton = styled(Button)`
  min-width: 260px;
  margin-top: auto;
  margin-inline-start: auto;

  @media (max-width: 850px) {
    width: 100%;
  }
`

const DisableContainer = styled.div<{ isDisabled: boolean }>`
  cursor: pointer;
  width: 100%;

  ${(p) => p.isDisabled && `cursor: no-drop;`}

  ${AccountType} {
    ${(p) =>
      p.isDisabled &&
      `
      pointer-events: none;
      opacity: 0.7;
    `}
  }
`

const DisableWarning = styled.div`
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);
  padding: 8px;
  cursor: auto;

  a {
    color: var(--color-azure-500);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`
