
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'libs/hooks/useIsMobile';
import { Button, Checkbox, Select } from 'ui/atoms';
import { AccountLine, WalletLine } from 'ui/molecules';

import { useGetWallets } from 'services/wallet/get/useGetWallets';
import { useGetAccounts } from 'services/accounts/get/useGetAccounts';

import {
  setAccountIdsEvent,
  setWalletIdsEvent,
} from 'pages/money/transactionsNew/model/moneyHistoryModel';

import * as Styled from './styled';

export const AccountsSelect: React.FC = () => {
  const [t] = useTranslation();

  const isMobile = useIsMobile();

  const { data: accounts } = useGetAccounts({});
  const {data: wallets} = useGetWallets({});

  const [accountsSelectValue, setAccountsSelectValue] = React.useState('');
  const [selectedAccounts, setSelectedAccounts] = React.useState<string[]>([]);
  const [selectedWallets, setSelectedWallets] = React.useState<string[]>([]);

  const changeAccounts = () => {
    setAccountIdsEvent(selectedAccounts)
    setWalletIdsEvent(selectedWallets)

    setAccountsSelectValue('filled')
  }

  const resetAccounts = () => {
    setSelectedAccounts([])
    setAccountIdsEvent([])

    setAccountIdsEvent([])
    setWalletIdsEvent([])

    setAccountsSelectValue('')
  }

  return (
    <>
      <Select
        value={accountsSelectValue}
        placeholder="Account / Wallet"
        autoComplete="off"
        optionsWidth={isMobile ? undefined : 343}
        maxHeight={2000}
        options={[
          {
            value: 'filled',
            label: `Account / Wallet (${
              selectedAccounts.length + selectedWallets.length
            })`,
            hidden: true,
          },
        ]}
        customContent={
          <Styled.AccountsWrapper>
            {wallets && wallets.items?.length > 0 && (
              <div>
                <Styled.ContentTitle>{t('Wallets')}</Styled.ContentTitle>
                <div>
                  <Styled.SelectOption>
                    <Checkbox
                      design="brand"
                      checked={selectedWallets.length === wallets.items.length}
                      onChange={(checked) =>
                        checked
                          ? setSelectedWallets(
                              wallets.items.map((wallet) => wallet.walletID),
                            )
                          : setSelectedWallets([])
                      }
                    />
                    {t('All Wallets')}
                  </Styled.SelectOption>
                  {wallets?.items?.map((wallet) => (
                    <Styled.SelectOption key={wallet.walletID}>
                      <Checkbox
                        design="brand"
                        checked={selectedWallets.indexOf(wallet.walletID) > -1}
                        onChange={(checked) =>
                          setSelectedWallets(
                            checked
                              ? [...selectedWallets, wallet.walletID]
                              : selectedWallets.filter(
                                  (id) => id !== wallet.walletID,
                                ),
                          )
                        }
                      />
                      <WalletLine {...wallet} />
                    </Styled.SelectOption>
                  ))}
                </div>
              </div>
            )}
            { !!accounts?.items?.length && (
              <div>
                <Styled.ContentTitle>{t('Accounts')}</Styled.ContentTitle>
                <div>
                  <Styled.SelectOption>
                    <Checkbox
                      design="brand"
                      checked={selectedAccounts.length === accounts.items.length}
                      onChange={(checked) =>
                        checked
                          ? setSelectedAccounts(
                              accounts.items.map((account) => account.accountID),
                            )
                          : setSelectedAccounts([])
                      }
                    />
                    {t('All Accounts')}
                  </Styled.SelectOption>
                  {accounts.items.map((account) => (
                    <Styled.SelectOption key={account.accountID}>
                      <Checkbox
                        design="brand"
                        checked={
                          selectedAccounts.indexOf(account.accountID) > -1
                        }
                        onChange={(checked) =>
                          setSelectedAccounts(
                            checked
                              ? [...selectedAccounts, account.accountID]
                              : selectedAccounts.filter(
                                  (id) => id !== account.accountID,
                                ),
                          )
                        }
                      />
                      <AccountLine {...account} />
                    </Styled.SelectOption>
                  ))}
                </div>
              </div>
            )}
            <Styled.Buttons>
              <Button
                name="cancel"
                size="medium"
                design="tertiary"
                onClick={resetAccounts}
              >
                Cancel
              </Button>
              <Button name="cancel" size="medium" onClick={changeAccounts}>
                Select
              </Button>
            </Styled.Buttons>
          </Styled.AccountsWrapper>
        }
      />
    </>
  )
}
