import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useHideRequestStatus } from 'services/profile/mutations/useHideRequestStatus';
import { ProfileRequestStatus } from 'services/profile/getProfile';
import { path } from 'libs/path';

import { Alerts } from 'ui/molecules';


interface Props {
  status: ProfileRequestStatus;
}

export const ProfileChangeRequest: React.FC<Props> = ({ status }) => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useHideRequestStatus();

  const onCloseAlert = () => {
    try {
      mutate();
    } catch (error) {
      
    }
  };

  return (
    <div className='mt-[1.6rem]'>
        {
          status === 'IN_PROGRESS' && (
            <Alerts
              type='pending'
              title='Your request to verify the updates is in process…'
            />
          )
        }

        {
          status === 'ACCEPTED' && (
            <Alerts
              type='success'
              title='We have verified the requested updates.'
              onClose={isLoading ? undefined : onCloseAlert}
            />
          )
        }

        {
          status === 'REJECTED' && (
            <>
              <Alerts
                type='warning'
                title='Your request has been rejected'
                onClose={isLoading ? undefined : onCloseAlert}
              >
                <div>
                  <span className='!font-normal'>
                    We couldn't verify the requested updates with the currently uploaded documents. <br />
                    <span
                      className='cursor-pointer text-orange-500 !font-medium'
                      onClick={() => navigate(path.dashboard.verification.editEntry())}
                    >
                      Apply again
                    </span> or&nbsp;
                    <span
                      className='cursor-pointer text-orange-500 !font-medium'
                      onClick={isLoading ? undefined : onCloseAlert}
                    >
                      Cancel the request
                    </span>.
                  </span>
                </div>
              </Alerts>
            </>
            )
          }
    </div>
  )
}
