import {
  Formik,
  Field as FormikField,
  FieldProps as FormikFieldProps,
  Form as FormikForm,
  FormikValues,
} from 'formik'
import React, { ForwardRefRenderFunction, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { Icon2 } from '../icon2'
import { Input, InputProps, InputState } from '../input'
import { Select, SelectProps } from '../select'

type Props<T> = {
  name?: string
  className?: string
  initial: T
  validation?: any
  error?: string | null
  children?: React.ReactNode
  onSubmit: (e: T) => void
  withCaptcha?: boolean
}

export function Form<T extends FormikValues>({
  name,
  className,
  initial,
  validation,
  onSubmit,
  children,
  error,
}: Props<T>) {
  const handleSubmit = (e: T) => {
    onSubmit(e)
  }

  const [t] = useTranslation()

  return (
    <FormikContainer className={className}>
      <Formik
        id={`${name}-form`}
        onSubmit={handleSubmit}
        initialValues={initial}
        validationSchema={validation}
        validateOnChange
        enableReinitialize
      >
        {() => (
          <FormWrap>
            {error && <FormikError>{t(`${error}`)}</FormikError>}
            {children}
          </FormWrap>
        )}
      </Formik>
    </FormikContainer>
  )
}

const FormikError = styled.div`
  color: var(--color-opal);
  margin-bottom: 24px;
  font-size: 1.4rem;
  line-height: 2rem;
  top: 0;
  width: 100%;
  text-align: center;
`

const FormikContainer = styled.div`
  margin-top: -42px;
  padding-top: 42px;
  position: relative;
`

type FieldProps = {
  name: string
  validate?: boolean
  error?: string
} & Pick<
  InputProps,
  | 'type'
  | 'placeholder'
  | 'autofocus'
  | 'showPassword'
  | 'suffix'
  | 'className'
  | 'label'
  | 'optional'
  | 'disabled'
>

interface FieldInputProps extends InputProps {
  validate?: boolean
  error?: string
  name: string
}

export const Component: ForwardRefRenderFunction<
  HTMLInputElement,
  FieldInputProps
> = (
  {
    name,
    type,
    label,
    placeholder = '',
    autofocus,
    validate = true,
    showPassword,
    error,
    suffix,
    suffixType,
    className,
    optional,
    maxLength,
    disabled,
    onChange
  },
  ref,
) => {
  const [t] = useTranslation()

  return (
    <FormikField name={name}>
      {(props: FormikFieldProps) => {
        const { field, form, meta } = props
        const { touched } = meta
        const { onChange: onFieldChange, onBlur } = field

        const isError =
          touched && validate && (Boolean(error) || Boolean(meta.error))

        const isValid =
          touched && validate && !(Boolean(meta.error) || Boolean(error))

        let errMsg = 'error'

        if (error) errMsg = error
        if (meta.error) errMsg = meta.error

        let state: InputState = 'hint'

        if (isError) state = 'error'
        if (isValid) state = 'valid'

        return (
          <FieldWrap className={className} data-name={name}>
            <FieldInput
              name={name}
              label={label}
              autofocus={autofocus}
              type={type}
              value={field.value}
              placeholder={placeholder}
              optional={optional}
              disabled={disabled}
              onChange={(e) => {
                onChange?.(e)
                onFieldChange(e)
              }}
              onBlur={(e) => {
                onBlur(e)
                form.setFieldTouched(name)
              }}
              state={state}
              suffix={
                suffix ||
                (isValid && type !== 'password' && (
                  <ValidIcon name="check" size="small" />
                ))
              }
              suffixType={suffixType}
              showPassword={showPassword}
              ref={ref}
              maxLength={ maxLength }
            />
            {isError && <FieldState state={state}>{t(errMsg)}</FieldState>}
          </FieldWrap>
        )
      }}
    </FormikField>
  )
}
export const Field = forwardRef(Component)

type FieldSelectProps = { validate?: boolean; error?: string } & Pick<
  FieldProps,
  'name' | 'className'
> &
  Pick<
    SelectProps,
    'label' | 'options' | 'placeholder' | 'onSelect' | 'value' | 'disabled'
  >

export const FieldSelect = ({
  className,
  value,
  name,
  label,
  placeholder,
  options = [],
  onSelect,
  disabled,
  error,
  validate = true,
}: FieldSelectProps) => {
  const [t] = useTranslation()

  return (
    <FormikField name={name}>
      {(props: FormikFieldProps) => {
        const { field, meta } = props
        const { touched } = meta
        const { onBlur } = field

        const isError =
          touched && validate && (Boolean(error) || Boolean(meta.error))
        const isValid =
          touched && validate && !(Boolean(meta.error) || Boolean(error))

        let errMsg = 'error'

        if (error) errMsg = error
        if (meta.error) errMsg = meta.error

        let state: InputState = 'hint'

        if (isError) state = 'error'
        if (isValid) state = 'valid'

        return (
          <FieldWrap className={className} data-name={name}>
            <Select
              name={name}
              className={className}
              value={value}
              label={label}
              options={options}
              placeholder={placeholder}
              disabled={disabled}
              onBlur={onBlur}
              onSelect={onSelect}
              state={state}
            />
            {isError && <FieldState state={state}>{t(errMsg)}</FieldState>}
          </FieldWrap>
        )
      }}
    </FormikField>
  )
}

const FieldWrap = styled.div``

const FieldInput = styled(Input)`
  width: 100%;
`

type FieldStateProps = {
  state: InputState
}

const fieldMap = ({ state }: FieldStateProps) => ({
  'data-state': state,
})

const FieldState = styled.div.attrs(fieldMap)<FieldStateProps>`
  font-size: var(--body-font-size-medium);
  line-height: 1;
  color: var(--color-gray-600);
  margin-top: 6px;

  &[data-state='error'] {
    color: var(--color-opal-600);
  }

  &[data-state='validated'] {
    color: var(--color-eucalyptus-600);
  }
`

const FormWrap = styled(FormikForm)`
  ${FieldWrap} {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ValidIcon = styled(Icon2)`
  color: var(--color-eucalyptus-600);
`
