import { useStore } from 'effector-react'
import * as React from 'react'

import { $accounts } from '../model'
import {
  $activeAccountId,
  $archiveAlertVisible,
  $leverage,
  $msg,
  $newLeverage,
  accountsListMounted,
  closeArchiveAlert,
  patchLeverage,
} from './model'

export const useAccountsList = () => {
  React.useEffect(() => {
    accountsListMounted()
  }, [])

  const accounts = useStore($accounts)
  const archiveAlertVisible = useStore($archiveAlertVisible)

  return { accounts, archiveAlertVisible, closeArchiveAlert }
}

export const useAccauntLeverage = () => {
  const leverage = useStore($leverage)
  const newLeverage = useStore($newLeverage)
  const leveragePatchLoading = useStore(patchLeverage.pending)
  const msg = useStore($msg)
  const id = useStore($activeAccountId)

  return {
    accountID: id,
    leverage,
    newLeverage,
    leveragePatchLoading,
    msg,
  }
}
