import { useQuery } from '@tanstack/react-query'
import { Account } from 'pages/accounts/types'
import { axios } from 'services/axios'

export const GET_TRADER_ACCOUNT = ['copytrade-trader-account']

const getTraderAccount = async (strategyID: string) => {
  const res = await axios.get<Account>('/copytrade/v1/strategy/account', {
    params: { strategyID },
  })

  return res.data
}

export const useGetTraderAccount = (strategyID: string) => {
  return useQuery({
    queryKey: GET_TRADER_ACCOUNT,
    queryFn: () => getTraderAccount(strategyID),
  })
}
