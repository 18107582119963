import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styled'

const MAX_TEXT_LENGTH = 400

export const StrategyDescription = ({
  text,
  className,
}: {
  text: string
  className?: string
}) => {
  const [t] = useTranslation()

  const [isOpened, setIsOpened] = useState(false)

  const toggleLength = () => setIsOpened(!isOpened)

  return (
    <Styled.Wrapper className={className}>
      <Styled.Title>{t('Description')}</Styled.Title>
      <Styled.Text>
        {!isOpened && text.length > MAX_TEXT_LENGTH ? (
          <>
            {text.slice(0, MAX_TEXT_LENGTH).trim()}...{' '}
            <Styled.ExpandButton type="button" onClick={toggleLength}>
              {t('Show more')}
            </Styled.ExpandButton>
          </>
        ) : (
          text
        )}
      </Styled.Text>
      {isOpened && (
        <Styled.ExpandButton type="button" onClick={toggleLength}>
          {t('Show less')}
        </Styled.ExpandButton>
      )}
    </Styled.Wrapper>
  )
}
