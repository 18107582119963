import styled, { css } from 'styled-components/macro'
import { Input } from 'ui/atoms'

const star = (isDisabled: boolean) => {
  return `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path stroke="${
    isDisabled ? '%23B3B3B3' : '%23262119'
  }" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.565 2.916a.469.469 0 0 1 .865 0l1.772 4.26c.067.161.22.272.395.286l4.599.369a.469.469 0 0 1 .267.823l-3.503 3.002a.469.469 0 0 0-.151.464l1.07 4.488a.469.469 0 0 1-.7.508l-3.937-2.404a.469.469 0 0 0-.489 0l-3.937 2.404a.469.469 0 0 1-.7-.508l1.07-4.488a.469.469 0 0 0-.15-.464L2.531 8.654a.469.469 0 0 1 .267-.823l4.599-.369a.469.469 0 0 0 .395-.287l1.772-4.26Z"/></svg>`
}

export const Wrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  @media (max-width: 949px) {
    margin-bottom: 20px;
  }
`

export const Button = styled.button<{ isActive?: boolean }>`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  transition: background-color var(--transition);
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px auto;

  &:hover {
    background-color: var(--color-gray-300);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: var(--color-gray-300);

      &:hover {
        opacity: 1;
      }
    `}
`

export const SortingButton = styled(Button)`
  margin-inline-end: 10px;

  @media (max-width: 949px) {
    margin-inline-end: 4px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: transparent;

      path {
        stroke: var(--color-bronze-500);
      }
    `}
`

export const FiltersRow = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
`

export const DesktopFavoritesCheckbox = styled.div<{
  isActive: boolean
  isDisabled: boolean
}>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-inline-start: 36px;
  padding-inline-end: 10px;
  color: ${({ isActive, isDisabled }) =>
    isActive
      ? 'var(--color-gray-700)'
      : isDisabled
      ? 'var(--color-gray-500)'
      : 'var(--color-gray-600)'};
  font-size: var(--body-font-size-medium);
  line-height: 16px;
  font-weight: 500;
  background-image: ${({ isDisabled }) => `url('${star(isDisabled)}')`};
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'right 10px center' : 'left 10px center'};
  background-color: ${({ isActive }) =>
    isActive ? 'var(--color-gray-400)' : 'var(--color-gray-300)'};
  border-radius: 4px;
`

export const SearchInput = styled(Input)`
  margin-inline-start: auto;
  margin-inline-end: 8px;
  max-width: 300px;

  @media (max-width: 1289px) {
    max-width: 200px;
  }

  @media (min-width: 1051px) and (max-width: 1199px) {
    // todo: нужны макеты 😞
    display: none;
  }

  @media (max-width: 949px) {
    order: -1;
    margin-inline-start: 0;
    max-width: 100%;
  }

  &:last-child {
    margin-inline-end: 0;

    @media (max-width: 949px) {
      margin-inline-end: 8px;
    }
  }

  input {
    padding: 7px 12px;
  }
`

export const CommissionInput = styled(Input)`
  input {
    padding: 7px 12px;
  }
`

export const CommissionFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`

export const CommissionModal = styled.div`
  position: relative;
  padding: 20px;
  display: grid;
  gap: 20px;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.28033 5.21967C5.98744 4.92678 5.51256 4.92678 5.21967 5.21967C4.92678 5.51256 4.92678 5.98744 5.21967 6.28033L8.93934 10L5.21967 13.7197C4.92678 14.0126 4.92678 14.4874 5.21967 14.7803C5.51256 15.0732 5.98744 15.0732 6.28033 14.7803L10 11.0607L13.7197 14.7803C14.0126 15.0732 14.4874 15.0732 14.7803 14.7803C15.0732 14.4874 15.0732 14.0126 14.7803 13.7197L11.0607 10L14.7803 6.28033C15.0732 5.98744 15.0732 5.51256 14.7803 5.21967C14.4874 4.92678 14.0126 4.92678 13.7197 5.21967L10 8.93934L6.28033 5.21967Z" fill="%23262119"/></svg>');
`

export const MobileSortingOption = styled.div<{ isActive: boolean }>`
  height: 44px;
  display: flex;
  align-items: center;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  color: ${({ isActive }) => isActive && 'var(--color-bronze-500)'};
`
