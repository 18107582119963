import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/axios';

import { GiftsHistoryResponse, GiftsHistoryOperationType } from './types';


export const GET_GIFTSHOP_HISTORY_KEY = 'GET_GIFTSHOP_HISTORY_KEY';

export const getGiftsHistory = async (data: Omit<Options, 'enabled'>) => {
  const params = {
    page: data.page,
    perPage: data.perPage,
    'filter.typeOperation': data.typeOperation,
  }
  const res = await axios.get<GiftsHistoryResponse>('/gift-shop/v1/history-operations', {
    params,
  })

  return res.data;
}

interface Options {
  enabled?: boolean;
  typeOperation: GiftsHistoryOperationType;
  page: number;
  perPage: number;
}

export const useGetGiftsHistory = ({enabled = true, typeOperation, page, perPage }: Options) => {
  return useQuery({
    queryKey: [GET_GIFTSHOP_HISTORY_KEY, typeOperation, page, perPage],
    queryFn: () => getGiftsHistory({
      typeOperation,
      page,
      perPage,
    }),
    staleTime: 500,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled,
  })
}
