import React from 'react';

import { Gateway } from 'services/money/get/types';
import { useGetGateways } from 'services/money/get/useGetGateways';

import { GatewaysGrid } from 'pages/money/molecules/gatewaysGrid';

interface Props {
  isDisabled?: boolean;
  onChooseGateway: (gateway: Gateway) => Promise<void>
}

export const GatewayBody: React.FC<Props> = ({ isDisabled, onChooseGateway }) => {
  const { data } = useGetGateways('withdrawal');

  const onClickOnGateway = (gateway: Gateway) => {
    onChooseGateway(gateway);
  }

  return (
    <>
      {data ? (
        <GatewaysGrid
          data={data}
          disabled={isDisabled}
          onChoose={onClickOnGateway}
        />
      ) : null}
    </>
  )
}

