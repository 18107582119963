import { useStore } from 'effector-react'
import { path } from 'libs/path'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch, useNavigate } from 'react-router-dom'
import { useGetGiftList, useRegister } from 'services/giftshop'
import { useGetGiftsHistory } from 'services/giftshop/get/useGetGiftsHistory'
import { Text, Title } from 'ui/atoms'
import { useIsFirstRender, useWindowSize } from 'usehooks-ts'

import { ActivityHistory } from './activityHistory'
import { GiftList } from './giftList'
import { useLanguageChange } from './hooks'
import { $widgetInfo } from './model'
import { GiftshopBanner } from './molecules/banner'
import * as Styled from './styled'

export const Giftshop = () => {
  const [t, i18n] = useTranslation()

  const { data: giftList, refetch: refetchGiftList } = useGetGiftList()
  const { mutateAsync: register, isLoading } = useRegister()

  useLanguageChange(refetchGiftList)

  const navigate = useNavigate()
  const matchParams = useMatch(path.giftshop.history())

  const { width } = useWindowSize()
  const isMobileMode = width < 571

  const isFirst = useIsFirstRender()

  const { data, isInitialLoading } = useGetGiftsHistory({
    page: 0,
    perPage: 10,
    typeOperation: 'ALL',
    enabled: isFirst,
  })

  const tabs = useMemo(() => {
    if (data && data?.totalRecords >= 1 && !isInitialLoading) {
      return {
        list: 'Gift list',
        history: 'Activity history',
      }
    }

    return {
      list: 'Gift list',
    }
  }, [data, isInitialLoading])

  const widgetInfo = useStore($widgetInfo)

  const [activeTab, setActiveTab] = useState<keyof typeof tabs>()

  const onClickTab = (key: keyof typeof tabs) => {
    if (key === 'history') {
      navigate(path.giftshop.history(), { replace: true })
    } else {
      navigate(path.giftshop.entry(), { replace: true })
    }

    setActiveTab(key)
  }

  useEffect(() => {
    // почему-то при переходе на страницу после успешного заказа оверфлоу не сбрасывается 
    document.body.style.overflow = 'visible'
  }, [])

  useEffect(() => {
    setActiveTab(!!matchParams ? 'history' : 'list')
  }, [matchParams])

  return (
    <Styled.Container>
      <Styled.TitleWrapper>
        <Styled.PageTitle>{t('Giftshop')}</Styled.PageTitle>
      </Styled.TitleWrapper>
      <Styled.AboutWrapper>
        <Title level={width < 670 ? 2 : 1}>How it works</Title>
        <div>
          <Text level={isMobileMode ? 2 : 1}>
            Improve your life with Headway! Trade lots, collect Crowns, and
            exchange them for guaranteed prizes.
          </Text>
          <Text level={isMobileMode ? 2 : 1}>
            Pick any item from the list to monitor your progress. You can always
            change your mind and select another gift.
          </Text>
        </div>
        <Styled.Link href={path.giftshop.terms(i18n.language)} target="_blank">
          {t('Terms and conditions')}
        </Styled.Link>
      </Styled.AboutWrapper>
      {widgetInfo ? (
        widgetInfo.isRegistered ? (
          <Styled.ProgressTitle>{t('Progress')}</Styled.ProgressTitle>
        ) : (
          <Styled.JoinButton
            name="join-now"
            onClick={() => register()}
            loading={isLoading}
            disabled={isLoading}
          >
            Join now
          </Styled.JoinButton>
        )
      ) : null}
      <GiftshopBanner inner />
      <Styled.ContentWrapper>
        <Styled.TabsWrapper id="tabs">
          {Object.entries(tabs).map(([key, title]) => (
            <Styled.Tab
              key={key}
              level={isMobileMode ? 3 : 2}
              isActive={activeTab === key}
              onClick={() => onClickTab(key as keyof typeof tabs)}
            >
              {title}
            </Styled.Tab>
          ))}
        </Styled.TabsWrapper>
        {activeTab === 'history' ? (
          <ActivityHistory />
        ) : giftList ? (
          <GiftList data={giftList} />
        ) : null}
      </Styled.ContentWrapper>
    </Styled.Container>
  )
}
