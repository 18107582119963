import { createEvent, restore, sample } from 'effector'
import {
  getUserAfterLoginBySocial,
  getUserAfterRegisterBySocial,
} from 'features/user/model'
import i18next from 'i18next'
import { path } from 'libs/path'
import { fx } from 'libs/request'

import { toPath } from '../router'
import { Session, setAccessToken, setRefreshToken } from '../session'
import { OAuthBySocial, RegistrationParams } from './types'

export const submitOAuthToken = createEvent<OAuthBySocial>()

export const submitOAuthError = createEvent<string>()

export const $error = restore(submitOAuthError, null)

export const oAuthBySocial = fx<OAuthBySocial, Session>({
  url: '/auth/v1/oauth-by-social',
  method: 'POST',
})

export const setAuthSocialName = createEvent<string>()

export const $authSocialName = restore(setAuthSocialName, null)

export const setIsRegistrationOath = createEvent<boolean>()

export const setRegistrationParams = createEvent<
  RegistrationParams | undefined
>()

export const $registrationParams = restore(setRegistrationParams, null)

export const $isRegistrationOath = restore(setIsRegistrationOath, false)

sample({
  clock: submitOAuthToken,
  fn: (token) => ({ body: token }),
  target: oAuthBySocial,
})

sample({
  clock: oAuthBySocial.doneData,
  fn: (doneData) => doneData.body.identificationResult.accessToken,
  target: setAccessToken,
})

sample({
  clock: oAuthBySocial.doneData,
  fn: (doneData) => doneData.body.identificationResult.refreshToken,
  target: setRefreshToken,
})

sample({
  clock: oAuthBySocial.doneData,
  fn: (doneData) => doneData.body.isRegistration || false,
  target: setIsRegistrationOath,
})

sample({
  clock: oAuthBySocial.doneData,
  fn: (doneData) => doneData.body.registrationParams,
  target: setRegistrationParams,
})

// if auth succeseed redirect to dashboard

sample({
  clock: oAuthBySocial.doneData,
  filter: ({ body }) =>
    Boolean(body.isRegistration) &&
    !(
      body.registrationParams?.firstName &&
      body.registrationParams?.lastName &&
      body.registrationParams?.email
    ),
  target: toPath.prepend(path.register.oOath.entry),
})

sample({
  clock: oAuthBySocial.doneData,
  filter: ({ body }) =>
    body.identificationResult.action === 'PUBLIC_API_AUTH_ACTION_REGISTRATION',
  target: getUserAfterRegisterBySocial.prepend(() => ({})),
})

sample({
  clock: oAuthBySocial.doneData,
  filter: ({ body }) =>
    Boolean(!body.isRegistration) &&
    !(
      body.identificationResult.action === 'PUBLIC_API_AUTH_ACTION_REGISTRATION'
    ),
  target: getUserAfterLoginBySocial.prepend(() => ({})),
})

sample({
  clock: getUserAfterLoginBySocial.doneData,
  fn: (doneData) => {
    const { user, userRoles } = doneData.body

    // @ts-expect-error
    window.dataLayer.push({
      user: {
        auth_status: 1,
        country: user.country,
        language:
          i18next.language.slice(0, 2) ||
          localStorage.i18nextLng.slice(0, 2) ||
          'en',
        id: user.userID,
        fname: user.firstName,
        lname: user.lastName,
        email: user.email,
        verifyemail: user.isEmailConfirmed ? 1 : 0,
        person_type: {
          trader: !!userRoles?.isTrader,
          partner: !!userRoles?.isPartner,
          nova: !!userRoles?.isNova,
          investor: !!userRoles?.isInvestor,
          strategist: !!userRoles?.isStrategist,
        },
      },
    })
  },
})

sample({
  clock: getUserAfterRegisterBySocial.doneData,
  fn: (doneData) => {
    const { user, userRoles } = doneData.body

    // @ts-expect-error
    window.dataLayer.push({
      user: {
        auth_status: 1,
        country: user.country,
        language:
          i18next.language.slice(0, 2) ||
          localStorage.i18nextLng.slice(0, 2) ||
          'en',
        id: user.userID,
        fname: user.firstName,
        lname: user.lastName,
        email: user.email,
        verifyemail: user.isEmailConfirmed ? 1 : 0,
        person_type: {
          trader: !!userRoles?.isTrader,
          partner: !!userRoles?.isPartner,
          nova: !!userRoles?.isNova,
          investor: !!userRoles?.isInvestor,
          strategist: !!userRoles?.isStrategist,
        },
      },
    })
  },
  target: toPath.prepend(path.register.email.confirmSuccess),
})
