export const getStrFromAmount = (value?: string, withOutDot?: boolean) => {
  const oldValue = String(value);
  if (!value) return '';

  const pattern = withOutDot ? /[^\d]/g : /[^\d.]/g;
  
  // удаляем все кроме цифир и точки
  let newVal = oldValue.replace(pattern, '').trim();
  if (withOutDot) return newVal;
  // удаляем все точки кроме одной
  const valWithoutDot = newVal.replace(/^([^\.]*\.)|\./g, '$1').trim();

  return valWithoutDot;
}