import { useStore } from 'effector-react'

import { registerByEmail } from '../email'
import { $error } from './model'

export const useEmailConfirmRepeatPage = () => {
  const loading = useStore(registerByEmail.pending)
  const error = useStore($error)

  return {
    error,
    loading,
  }
}
