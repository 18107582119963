import styled, { css } from 'styled-components/macro'

import { Icon2 } from '../icon2'
import { InputState, InputVariant } from '.'

export const InputWrap = styled.div`
  position: relative;
  width: 100%;
`

type FieldStateProps = {
  state: InputState
}

const fieldMap = ({ state }: FieldStateProps) => ({
  'data-state': state,
})

export const FieldState = styled.div.attrs(fieldMap)<FieldStateProps>`
  font-size: var(--body-font-size-medium);
  line-height: 1;
  font-weight: 400;
  color: var(--color-opal-600);
  margin-top: 6px;
`

interface InputProps {
  variant: InputVariant
  hasError?: boolean
}
export const InputField = styled.input<InputProps>`
  color: var(--color-gray-700);
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 400;
  transition: var(--transition);
  /* height: 52px; */
  min-width: 52px;
  width: 100%;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  ${({ variant, hasError }) => {
    if (variant === 'inline')
      return css`
      border: ${
        hasError
          ? '1px solid var(--color-opal-600);'
          : '1px solid var(--border-color);'
      }
      border-radius: 8px;
      padding: 13px 12px;
      background-color: var(--color-gray-100);
      &:hover {
        background-color: var(--color-gray-200);
      }
    `

    if (variant === 'outline') {
      return css`
        border-bottom: ${
          hasError
            ? '1px solid var(--color-opal-600);'
            : '1px solid var(--border-color);'
        }
        padding: 0 12px 4px 12px;
      `
    }
  }}

  &:focus {
    ${(props) =>
      props.variant === 'inline'
        ? css`
            border: 1px solid var(--color-sunray);
          `
        : css`
            border-bottom: 1px solid var(--color-sunray);
          `}

    & + div {
      color: var(--color-sunray);
    }
  }

  &[data-state='error'] {
    border: 1px solid var(--color-opal-600);
  }

  &[data-state='valid'] {
    border: 1px solid var(--color-eucalyptus-600);
  }

  &[data-prefix='true'] {
    padding-inline-start: 46px;
  }

  &:disabled {
    cursor: no-drop;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-gray-200);
    color: var(--color-gray-500);
  }
`

export const InputPrefix = styled.div`
  position: absolute;
  inset-inline-start: 10px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
  transition: var(--transition);
`

export const InputSuffix = styled.div`
  position: absolute;
  inset-inline-end: 16px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
  transition: var(--transition);

  &[data-interactive='true'] {
    pointer-events: all;
  }
`

export const ShowPassword = styled.div`
  position: absolute;
  inset-inline-end: 16px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:active {
    opacity: 0.7;
  }
`

export const ShowPasswordIcon = styled(Icon2)``

export const InputLabel = styled.label`
  font-weight: 400;
  font-size: var(--body-font-size-small);
  line-height: 1.8rem;
  margin-bottom: 4px;
  display: inline-block;
  cursor: pointer;
`

export const Optional = styled.span`
  color: var(--color-gray-500);
`
