import { useStore } from 'effector-react'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { $recordedAccountData, accountCreateMounted, setRecordedAccountData } from './model'
import { $activePlatform } from './platform'
import { $activeTariffName } from './tariff'

export const useAccountCreate = () => {
  const { accountType = 'invalid' } = useParams()

  const activeTariffName = useStore($activeTariffName)
  const activePlatform = useStore($activePlatform)

  const recordedAccountData = useStore($recordedAccountData)

  React.useEffect(() => {
    accountCreateMounted()

    return () => {
      setRecordedAccountData({})
    }
  }, [])

  return { accountType, activeTariffName, activePlatform, recordedAccountData }
}
