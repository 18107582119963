import styled from 'styled-components/macro'
import { Button as UiButton, Title as UiTitle } from 'ui/atoms'

import background from '../../images/trader-area-background.jpg'
import backgroundWeb from '../../images/trader-area-background.webp'

import icon_1 from '../../images/trader-area-icon-1.png'
import icon_1Web from '../../images/trader-area-icon-1.webp'
import icon_2 from '../../images/trader-area-icon-2.png'
import icon_2Web from '../../images/trader-area-icon-2.webp'
import icon_3 from '../../images/trader-area-icon-3.png'
import icon_3Web from '../../images/trader-area-icon-3.webp'

import mobileBackground from '../../images/trader-area-mobile-background.jpg'
import mobileBackgroundWeb from '../../images/trader-area-mobile-background.webp';

export const ContentContainer = styled.div`
  padding: 101px 92px;
  background-image: url(${background});
  background-image: image-set( url(${backgroundWeb}),  url(${background}));
  background-repeat: no-repeat;
  background-size: 640px auto;
  background-position: bottom 24px left;

  @media (max-width: 1110px) {
    padding: 101px 16px;
  }

  @media (min-width: 600px) and (max-width: 1010px) {
    background-image: none;
  }

  @media (max-width: 599px) {
    margin: 0 -12px;
    padding-top: 484px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
    background-image: url(${mobileBackground});
    background-image: image-set( url(${mobileBackgroundWeb}), url(${mobileBackground}));
    background-size: 368px auto;
    background-position: top 8px center;
  }
`

export const TextWrapper = styled.div`
  margin-left: auto;
  width: 440px;
  display: grid;
  justify-items: start;

  @media (max-width: 1010px) {
    margin-left: 0;
  }

  @media (max-width: 599px) {
    width: 100%;
    justify-items: center;
  }
`

export const Text = styled.div`
  margin-bottom: 48px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);

  @media (max-width: 599px) {
    margin-bottom: 24px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
    text-align: center;
  }
`

export const ListWrapper = styled.div`
  margin-bottom: 48px;
  display: grid;
  grid-template-columns: repeat(3, 128px);
  gap: 16px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);

  @media (max-width: 599px) {
    margin-bottom: 24px;
    grid-template-columns: 1fr;
    width: 100%;
  }
`

export const ListItem = styled.div`
  padding-top: 96px;
  background-repeat: no-repeat;
  background-size: 80px auto;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'top right' : 'top left'};

  @media (max-width: 599px) {
    padding-top: 0;
    padding-inline-start: 64px;
    height: 48px;
    display: flex;
    align-items: center;
    background-size: 48px auto;
    background-position: ${({ theme }) =>
      theme.dir === 'rtl' ? 'center right' : 'center left'};
  }

  &:first-child {
    background-image: url(${icon_1});
    background-image: image-set(url(${icon_1Web}), url(${icon_1}));
  }

  &:nth-child(2) {
    background-image: url(${icon_2});
    background-image: image-set(url(${icon_2Web}), url(${icon_2}));
  }

  &:nth-child(3) {
    background-image: url(${icon_3});
    background-image: image-set(url(${icon_3Web}), url(${icon_3}));
  }
`

export const Title = styled(UiTitle)`
  margin-bottom: 20px;

  @media (max-width: 599px) {
    text-align: center;
  }
`

export const Button = styled(UiButton)`
  min-width: 208px;

  @media (max-width: 599px) {
    width: 100%;
  }
`