export const tooltipCss = {
  backgroundColor: '#F5F5F5',
  cornerRadius: '3px',
  
  titleFont: {
    size: 12,
    weight: 'normal',
    lineHeight: '18px',
    family: 'Satoshi", sans-serif',
  },
  bodyFont: {
    size: 12,
    weight: 'bold',
    lineHeight: '18px',
    family: 'Satoshi", sans-serif',
  },
  bodyColor: '#262119',
  boxPadding: 6,
  padding: {
    x: 10,
    y: 6
  },
  boxShadow: "0px 0px 1px -1px #2621190F, 0px 4px 4px -1px #2621190F",
  titleColor: '#262119',
}