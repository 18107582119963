import styled, { css } from 'styled-components/macro'
import { Icon2, Text } from 'ui/atoms'

interface Props {
  isError?: boolean;
}

export const ConfirmTimerCount = styled.span<Props>`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  color: ${({isError}) =>isError ? css`var(--color-opal-500);` : css`var(--color-sunray-500);`};
  font-weight: 500;
  margin-inline-end: 10px;
`

export const ConfirmTimerIcon = styled(Icon2)<Props>`
  color: ${({isError}) =>isError ? css`var(--color-opal-500);` : css`var(--color-sunray-500);`};

  &[data-running='true'] {
    cursor: auto;
  }
`

export const ResendText = styled(Text)<Props>`
 color: ${({isError}) =>isError ? css`var(--color-opal-500);` : css`var(--color-sunray-500);`};
 margin-inline-end: 4px;
 font-weight: 500;
`