import styled, { css } from 'styled-components'
import { Button } from 'ui/atoms'

import metaGold from './img/meta-gold.svg'
import mt4 from './img/mt4.png'
import mt4Web from './img/mt4.webp'
import mt5 from './img/mt5.png'
import mt5Web from './img/mt5.webp'

export const TitleWrapper = styled.div`
  margin-bottom: 20px;
  padding-inline-end: 32px;
  display: inline-flex;
  align-items: center;
  background-image: url(${metaGold});
  background-size: 24px auto;
  background-repeat: no-repeat;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'center left' : 'center right'};
`

export const AppWrapper = styled.div<{ type: 'mt4' | 'mt5' }>`
  border-bottom: 1px solid var(--color-gray-300);
  padding-top: 16px;
  padding-inline-end: 0;
  padding-bottom: 16px;
  padding-inline-start: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: 24px auto;
  background-repeat: no-repeat;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'center right' : 'center left'};
  background-image: ${({ type}) => (type === 'mt4' ? `url(${mt4})` : `url(${mt5})`)};
  background-image: ${({ type }) => (type === 'mt4' ? 
    `image-set(url(${mt4Web}), url(${mt4}))` : 
    `image-set(url(${mt5Web}), url(${mt5}))`
  )};

  &:last-child {
    border-bottom: 0;
  }
`

export const AppTitle = styled.div`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-medium);
  word-break: break-all;
  font-weight: 500;
`

export const ButtonsRow = styled.div`
  margin-inline-start: 8px;
  display: grid;
  gap: 20px;
  grid-template-columns: 90px 90px;

  @media (max-width: 470px) {
    grid-template-columns: 64px 64px;
    gap: 12px;
  }
`

export const DownloadButton = styled(Button)`
  margin-inline-start: 12px;
  min-width: 90px;
`

export const Wrapper = styled.div<{ isSmall: boolean }>`
  padding: 24px 20px;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px rgba(38, 33, 25, 0.06),
    0px 4px 6px -1px rgba(38, 33, 25, 0.1);

  ${({ isSmall }) =>
    isSmall &&
    css`
      padding: 16px 12px;

      @media (min-width: 1050px) and (max-width: 1185px), (max-width: 1000px) {
        padding: 24px 20px;
      }

      ${AppWrapper} {
        padding-inline-start: 32px;
      }

      ${AppTitle} {
        font-size: var(--body-font-size-medium);
      }

      ${ButtonsRow} {
        grid-template-columns: 64px 64px;
        gap: 12px;
      }
    `};
`
