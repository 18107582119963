import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/axios';


interface BodyData {
  accountID: string;
  cashbackPercentage: number;
}

const patchCashbackPartner = async (data: BodyData) => {
  const response = await axios.patch('/ib/v1/partner/cashback', data);

  return response
}

export const usePatchCashbackPartner = () => {
  return useMutation({
    mutationFn: patchCashbackPartner,
  })
}
