import React from 'react';
import { useIsMobile } from 'libs/hooks/useIsMobile';

import { Text } from 'ui/atoms';

type ProgressTitleProps = {
  startValue: React.ReactNode;
  startLabel: string;
  endValue: React.ReactNode;
  endLabel: string;
  className?: string;
}

export const ProgressTitle: React.FC<ProgressTitleProps> = ({ startLabel, endLabel, startValue, endValue, className }) => {
  const isMobile = useIsMobile();

  return (
    <div className={`flex justify-between items-center ${className}`}>
      <div className='flex items-baseline'>
        <Text
          level={2}
          withTranslate={false}
          weight='medium'
          className='lg:!text-body-l'
        >
          {startValue}
        </Text>
        <Text level={isMobile ? 4: 2} weight='medium' className='ml-[4px] !text-gray.600'>{startLabel}</Text>
      </div>
    
      <div className='flex items-baseline'>
        <Text
          level={2}
          withTranslate={false}
          weight='medium'
          className='lg:!text-body-l'
        >
          {endValue}{' '}
        </Text>
        <Text level={isMobile ? 4 : 2} weight='medium' className='ml-[4px] !text-gray.600'>{endLabel}</Text>
      </div>
    </div>
  )
}