import { RoutingPaths } from 'pages/routing/types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatches } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Icon2, Link } from 'ui/atoms'

type Props = {
  className?: string
  customPaths?: {
    name: string
    pathName: string
  }[]
}

export const Breadcrumbs: React.FC<Props> = ({ className, customPaths }) => {
  const [t] = useTranslation()

  let matches = useMatches()

  const customPathRoute = useMemo(() => {
    if (!customPaths || !customPaths?.length) return undefined

    return customPaths.map((path) => ({
      handle: { name: path.name },
      params: {},
      pathname: path.pathName,
    }))
  }, [customPaths])

  const customMatches = matches.map((item: any) => ({
    ...item,
    pathname: RoutingPaths[item?.handle?.name as keyof typeof RoutingPaths],
  }))

  const paths: any[] = customPathRoute ? customPathRoute : customMatches

  const breadcrumbs = paths.filter(({ handle }: any) => handle && handle?.name)

  return Boolean(breadcrumbs.length) ? (
    <Container className={className}>
      <HomeIcon name="home" size="small" />
      <Crumbs>
        {breadcrumbs.map((breadcrumb: any, key, arr) => {
          let label = t(breadcrumb.handle.name)
          const isLast =
            // @ts-ignore
            arr.slice(-1)[0].handle?.name === breadcrumb.handle.name

          return (
            <React.Fragment key={key}>
              <Breadcrumb>
                <BreadcrumbLabel>
                  {isLast ? (
                    label
                  ) : breadcrumb.pathname ? (
                    <BreadcrumbLink to={breadcrumb.pathname}>
                      {label}
                    </BreadcrumbLink>
                  ) : (
                    label
                  )}
                </BreadcrumbLabel>
                {!isLast && <Slash>/</Slash>}
              </Breadcrumb>
            </React.Fragment>
          )
        })}
      </Crumbs>
    </Container>
  ) : null
}

const Container = styled.div`
  padding-top: 3px;
  display: flex;
  align-items: center;
  gap: 7px;
  line-height: 18px;
`

const HomeIcon = styled(Icon2)`
  color: var(--color-gray-600);
  margin-top: -1px;

  &[data-size='small'] {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
`

const Breadcrumb = styled.div`
  font-weight: 400;
  color: var(--color-gray-600);
  display: flex;
`

const Crumbs = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: var(--body-font-size-medium);
  line-height: 1;

  ${Breadcrumb} {
    &:last-child {
      color: var(--color-gray-700);
      font-weight: 500;
    }
  }
`

const BreadcrumbLink = styled(Link)`
  color: inherit;
  font: var(--body-font-size-medium);
`
const BreadcrumbLabel = styled.div``

const Slash = styled.div`
  margin-inline-start: 8px;
`
