import { useQuery } from '@tanstack/react-query'
import { ActivitiesData } from 'services/activities/get/types'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'

export const GET_ACTIVITIES_KEY = ['activities']
const getActivities = async () => {
  try {
    const activities = await axios.get<ActivitiesData>('/user/v1/activities')

    return activities.data
  } catch (e) {
    console.log(e)
  }
}

export const useGetActivities = () => {
  return useQuery({
    queryKey: GET_ACTIVITIES_KEY,
    queryFn: getActivities,
    retry: retryFn,
    staleTime: 10000,
  })
}
