import { BankCardStatus } from 'services/payments/get/types';
import styled, { css } from 'styled-components/macro';
import { Text } from 'ui/atoms';

export const CardItem = styled.div`
  width: 100%;
  margin: 8px 0;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  margin-inline-end: 12px;
`

export const Wrapper = styled.div`
  padding: 24px 20px;
  background-color: var(--color-gray-100);
  box-shadow: 0px 4px 6px -1px rgba(38, 33, 25, 0.1),
    0px 2px 4px -1px rgba(38, 33, 25, 0.06);
  border-start-start-radius: 0;
  border-start-end-radius: 8px;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  height: auto;
`

interface StatusType {
  status: BankCardStatus;
}
export const Status = styled(Text)<StatusType>`
  padding: 0 4px;
  width: fit-content;
  font-weight: 500;
  ${(props) => {
    if (props.status === 'pending') return css`background-color: #E4EAFF; color: #004DA1;`
    if (props.status === 'rejected') return css`background-color: #FCE7E7; color: #B2423E;`
    if (props.status === 'verified') return css`background-color: #CBECDF; color: #245947;`
    if (props.status === 'expired') return css`background-color: #F5F5F5; color: #40372A;`

    return '';
  }}
`

export const CommentBlock = styled.div`
  padding: 12px;
  margin-top: 12px;
  border-radius: 8px;
  background: var(--color-gray-300);
`