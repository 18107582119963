import React, { useState } from 'react'

import { usePutChangeUserPhone } from 'services/user/mutations/usePutPhone';
import { Input, Text, Icon2 } from 'ui/atoms';

import * as S from '../informationBlock/styles';

interface Props {
  canChange?: boolean;
  currentPhone?: string;
}

export const PhoneChangeForm: React.FC<Props> = ({ currentPhone = '' }) => {
  const { mutateAsync, isLoading } = usePutChangeUserPhone();

  const [isChangeMod, setIsChangeMod] = useState<boolean>(false);

  const [newPhone, setNewPhone] = useState(currentPhone);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /^[0-9\b]+$/;
    const val = e.target.value.replace(' ', '');

    if (val === '' || reg.test(val)) {
      setNewPhone(e.target.value)
    }
  }

  const onChangePhone = async() => {
    try {
      const phone = Boolean(newPhone) ? `+${newPhone}` : '';
      await mutateAsync(phone);
      setIsChangeMod(false);
    } catch (error) {
      console.log(error);
    }
  }

  const onDropChangeMod = () => {
    setNewPhone(currentPhone);
    setIsChangeMod(false);
  }

  return(
    <>
      { isChangeMod ?
        <div className='flex items-center'>
          <Input
            value={ newPhone }
            onChange={ onChange }
            type='text'
            variant='outline'
            prefix='+'
            maxLength={15}
            inputClasses='!ps-[2.5rem]'
            className='!w-[17rem]'
            disabled={ isLoading }
          />
          <Icon2
            size='base'
            name='check'
            onClick={isLoading ? undefined : onChangePhone}
            className='text-sunray.500 ms-[1rem] cursor-pointer'
          />

          <Icon2
            size='base'
            name='xMarkIcon'
            onClick={ onDropChangeMod }
            className='text-gray.500 ms-[0.8rem] cursor-pointer'
          />
        </div>
        : (
          <>
            {currentPhone &&
              <div className='flex'>
                {currentPhone}
                <Icon2
                  size='base'
                  name='pencilSolid'
                  onClick={() => setIsChangeMod(true)}
                  className='text-gray.500 ms-[0.8rem] cursor-pointer'
                />
              </div>
            }
            {!currentPhone &&
              <div onClick={() => setIsChangeMod(true)}>
                <Text className='cursor-pointer !text-sunray.500'>
                  Add number
                </Text>
              </div>
            }
          </>
        )
      }
    </>
  );
}
