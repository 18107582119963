import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { path } from 'libs/path';
import { Title } from 'ui/atoms';

import { dropFilterMoneyHistoryEvent } from 'pages/money/transactionsNew/model/moneyHistoryModel';
import { noTransactions, notFound } from 'styles/img';

import * as Styled from './styled'


type Props = {
  notFoundClickFn?: () => void;
  hasFilters?: boolean;
}

export const NotFound = ({ hasFilters = false, notFoundClickFn }: Props) => {
  const [t] = useTranslation();

  const nav = useNavigate();

  const onResetFilter = () => {
    notFoundClickFn?.();
    dropFilterMoneyHistoryEvent();

    if (!hasFilters) {
      nav(path.money.entry());
    }
  }

  return (
    <Styled.Wrapper
      style={{
        backgroundImage: `url(${hasFilters ? notFound : noTransactions})`,
      }}
    >
      <Title level={2}>
        {hasFilters ? 'No results found' : 'No transactions yet'}
      </Title>
      <Styled.Text>
        {hasFilters
          ? t('There are no transactions matching your criteria. Please try to reset the filters.')
          : t('Here you will find the history of all transactions: deposits, withdrawals, and internal transfers.')}
      </Styled.Text>
      <Styled.Button
        name="button"
        design={hasFilters ? 'secondary' : 'primary'}
        onClick={onResetFilter}
      >
        {hasFilters ? 'Reset filters' : 'Deposit'}
      </Styled.Button>
    </Styled.Wrapper>
  )
}
