import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { BankCardsList } from './types';

export const GET_BANK_CARDS_KEY = ['cards-list'];

type SelectMapper = (data: BankCardsList) => BankCardsList;

const getCardsList = async () => {
  const res = await axios.get<BankCardsList>(`/payments/v1/bank-cards-list`);

  return res.data;
};

export const useGetCardsList = (selectMapper?: SelectMapper): UseQueryResult<BankCardsList> => {
  return useQuery({
    queryKey: GET_BANK_CARDS_KEY,
    queryFn: getCardsList,
    retry: retryFn,
    select: selectMapper,
    staleTime: 1000,
  })
};
