import styled from 'styled-components/macro'

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 1fr;
  gap: 20px;
  align-items: start;

  @media (max-width: 1010px) {
    grid-template-columns: minmax(0, 1fr);
  }
`

export const LongBanner = styled.div`
  grid-column: span 2;

  @media (max-width: 1010px) {
    grid-column: span 1;
  }
`
