import { useParams } from 'react-router-dom'
import { useGetStrategyForTrader } from 'services/copytrade'

import { StrategyRow } from '../../molecules/strategyRow'

export const StrategyPreviewForTraders = () => {
  const { strategyID } = useParams()

  const { data, isLoading } = useGetStrategyForTrader(strategyID as string)

  const isEmpty = !data

  return isLoading ? (
    'loading...'
  ) : isEmpty ? (
    'not found'
  ) : (
    <StrategyRow {...data} isMobile={false} isFull />
  )
}
