import React, { useState } from 'react';

import { usePatchCashbackPartner } from 'services/ib/mutation';
import { Icon2, Input, Spinner2, Text } from 'ui/atoms';

interface Props {
  cashbackPercentage: number;
  accountId: string;
  isDisabled?: boolean;
}

export const EditCashback: React.FC<Props> = ({ cashbackPercentage, accountId, isDisabled }) => {
  const [isChangeCashback, setIsChangeCashback] = useState(false);
  const [cashback, setCashback] = useState(cashbackPercentage);

  const { mutateAsync, isLoading } = usePatchCashbackPartner();

  const onChangeCashback = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value.replace(/[^.\d]/g, ''));
    
    if (val >= 0 && val <= 100) setCashback(val);
  }

  const onDropChangeMod = () => {
    setCashback(cashbackPercentage);
    setIsChangeCashback(false);
  }

  const onSbm = async () => {
    await mutateAsync({
      accountID: accountId,
      cashbackPercentage: cashback,
    });

    setIsChangeCashback(false);
  }

  if (isChangeCashback) {
    return (
      <div className='flex items-center'>
      <Input
        value={ cashback }
        onChange={ onChangeCashback }
        type='text'
        inputClasses='!h-[16px] !text-[14px]'
        className='!w-[10rem]'
        suffix={<span className='text-[14px]'>%</span>}
        disabled={ isLoading }
      />
      {isLoading 
        ? <Spinner2 />
        : (
          <>
            <Icon2
              size='base'
              name='check'
              onClick={isLoading ? undefined : onSbm}
              className='text-sunray.500 ms-[1rem] cursor-pointer'
            />
            <Icon2
              size='base'
              name='xMarkIcon'
              onClick={ onDropChangeMod }
              className='text-gray.500 ms-[0.8rem] cursor-pointer'
            />
          </>
        )
      }
    </div>
    )
  }

  return (
    <>
      <Text level={2} withTranslate={ false }>
        {cashback}%
      </Text>
      { !isDisabled && 
        <Icon2
          size='small'
          name='pencilSolid'
          onClick={() => setIsChangeCashback(true)}
          className='text-gray.500 ms-[0.8rem] cursor-pointer'
        />
      }
    </>
  )
}
