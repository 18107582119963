import React from 'react'
import { useGetProductLanguages } from 'services/profile/getProductLanguages/useGetProductLanguages'
import { useGetProfile } from 'services/profile/getProfile'

import { ProfilePage } from '../organisms/ProfilePage'

export const ProfileContainer: React.FC = () => {
  const { data, error, isLoading } = useGetProfile()
  const { data: availableLanguages } = useGetProductLanguages()

  return (
    <ProfilePage
      isLoading={isLoading}
      content={data}
      availableLanguages={availableLanguages}
    />
  )
}
