import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'

export type Bonus = {
  participantInfo: {
    status:
      | 'INVALID'
      | 'NOT_PARTICIPANT'
      | 'ACTIVE'
      | 'CLOSING_IN_PROGRESS'
      | 'CLOSED_NO_PROFIT'
      | 'CLOSED_RECENTLY_WITH_PROFIT'
      | 'CLOSED_HAS_PROFIT'
      | 'CLOSED_ALL_PROFIT_WITHDRAWN'
    account: {
      accountID: string
      balance: string
      closingAt: string
    }
    isClosedRecently: boolean
    profitStatistics: {
      availableProfit: string
      totalProfit: string
      totalWithdrawn: string
      availableWithdraw: string
      availableWithdrawalNextMonth: string
      lotsRequired: string
      totalLots: string
      currentMonthLots: string
      calculatedAt: string
    }
  }
}

export const GET_BONUS_INFO_KEY = ['bonus', 'info']

const getBonusInfo = async () => {
  try {
    const bonusData = await axios.get<Bonus>('/bonus111/v1/info')

    return bonusData.data
  } catch (error) {}
}

export const useGetBonusInfo = () => {
  return useQuery({
    queryKey: GET_BONUS_INFO_KEY,
    queryFn: getBonusInfo,
    retry: retryFn,
    staleTime: 10000,
  })
}
