import { useWindowSize } from '@uidotdev/usehooks'
import {
  NoResultsText,
  NoResultsTitle,
  NoResultsWrapper,
} from 'pages/copytrade/tradersList/styled'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetWebsiteBanners } from 'services/ib/get/useGetWebsiteBanners'
import { useGetWebsiteBannersFilters } from 'services/ib/get/useGetWebsiteBannersFilters'
import { Button, Icon2, Pagination, Title } from 'ui/atoms'
import { CheckboxSelect } from 'ui/atoms/select_draft/checkboxSelect'
import { BottomSheet } from 'ui/molecules'
import { Modal } from 'ui/molecules/modal_draft'

import { Filters } from '../../styled'
import { useDownloadFile } from '../../utils/useDownloadFile'
import { LanguageSelect } from '../filters'
import { LinkForShare } from '../linkForShare'
import { MediaModalContent } from '../mediaModalContent'
import { PageSizes } from '../pageSizes'
import * as Styled from './styled'

const DownloadButton = (props: { url: string; name: string }) => {
  const { isLoading, startDownload } = useDownloadFile()

  return (
    <Button
      prefix={<Icon2 name="arrowDownTray" size="small" />}
      design="secondary"
      size="small"
      disabled={isLoading}
      loading={isLoading}
      onClick={() => startDownload(props.url, props.name)}
    />
  )
}

export const WebsiteBanners = () => {
  const [t] = useTranslation()

  const containerRef = useRef<HTMLDivElement>(null)

  const { width } = useWindowSize()
  const isDesktop = width && width >= 815
  const isMobile = width && width < 600

  const [filters, setFilters] = useState({
    'filter.language': '',
    'filter.offerIDs': [] as number[],
    'filter.sizes': [] as string[],
    'filter.formats': [] as string[],
  })

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(12)

  const { data: defaultFilters, isLoading: filtersLoading } =
    useGetWebsiteBannersFilters()
  const {
    data,
    refetch,
    isLoading: dataLoading,
  } = useGetWebsiteBanners(filters, page, perPage)

  const [openedMediaId, setOpenedMediaId] = useState<number>()

  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false)

  // при смене языка нужно очищать другие фильтры
  const [languageHasChanged, setLanguageHasChanged] = useState(false)

  // для красивого скролла при смене страницы
  const containerOffsetTop = containerRef.current
    ? containerRef.current.getBoundingClientRect().top +
      (window.pageYOffset || document.documentElement.scrollTop)
    : 0

  const clearFilters = () => {
    setFilters({
      ...filters,
      'filter.offerIDs': [],
      'filter.sizes': [],
      'filter.formats': [],
    })
  }

  useEffect(() => {
    if (defaultFilters) {
      setFilters({
        ...filters,
        'filter.language':
          defaultFilters.languages.find((language) => language.isSelected)
            ?.value || '',
      })
    }
  }, [defaultFilters])

  useEffect(() => {
    if (languageHasChanged) {
      clearFilters()
    } else if (isDesktop && page === 1 && filters['filter.language']) {
      refetch()
    }

    setPage(1)

    setLanguageHasChanged(false)
  }, [filters])

  useEffect(() => {
    if (!isDesktop && filters['filter.language']) {
      clearFilters()

      setTimeout(() => refetch(), 0)
    }
  }, [filters['filter.language']])

  useEffect(() => {
    if (filters['filter.language']) {
      refetch()

      if (containerOffsetTop > 0) {
        window.scrollTo({ top: containerOffsetTop, behavior: 'smooth' })
      }
    }
  }, [page])

  useEffect(() => {
    if (page === 1 && filters['filter.language']) {
      refetch()

      if (containerOffsetTop > 0) {
        window.scrollTo({ top: containerOffsetTop, behavior: 'smooth' })
      }
    } else {
      setPage(1)
    }
  }, [perPage])

  return (
    <div ref={containerRef}>
      {defaultFilters && filters['filter.language'] && (
        <Filters>
          <LanguageSelect
            className="w-[180px]"
            onChange={(value) => {
              setLanguageHasChanged(true)

              setFilters({ ...filters, 'filter.language': value })
            }}
            languages={defaultFilters.languages.map(
              (language) => language.value,
            )}
            defaultLanguage={filters['filter.language']}
          />
          {isDesktop ? (
            <>
              {/* todo: вынести отдельно, убрать дублирование */}
              <CheckboxSelect
                className="w-[180px]"
                options={
                  defaultFilters.offersByLanguage
                    .find(
                      (filter) =>
                        filter.language === filters['filter.language'],
                    )
                    ?.offers.map((offer) => ({
                      value: offer.offerID,
                      label: offer.title,
                    })) || []
                }
                allOptionsLabel="All offers"
                onChange={(value) =>
                  setFilters({
                    ...filters,
                    'filter.offerIDs': value as number[],
                  })
                }
                values={filters['filter.offerIDs']}
              />
              <CheckboxSelect
                className="w-[180px]"
                options={defaultFilters.sizes.map((size) => ({
                  value: size.value,
                  label: size.title,
                }))}
                allOptionsLabel="All sizes"
                onChange={(value) =>
                  setFilters({
                    ...filters,
                    'filter.sizes': value as string[],
                  })
                }
                values={filters['filter.sizes']}
              />
              <CheckboxSelect
                className="w-[140px]"
                options={defaultFilters.formats.map((format) => ({
                  value: format.value,
                  label: format.title,
                }))}
                allOptionsLabel="All formats"
                onChange={(value) =>
                  setFilters({
                    ...filters,
                    'filter.formats': value as string[],
                  })
                }
                values={filters['filter.formats']}
              />
            </>
          ) : (
            <>
              <button
                className="ms-auto w-[36px] h-[36px] flex items-center justify-center"
                onClick={() => setIsBottomSheetVisible(true)}
                type="button"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 6L20.25 6M10.5 6C10.5 6.82843 9.82843 7.5 9 7.5C8.17157 7.5 7.5 6.82843 7.5 6M10.5 6C10.5 5.17157 9.82843 4.5 9 4.5C8.17157 4.5 7.5 5.17157 7.5 6M3.75 6H7.5M10.5 18H20.25M10.5 18C10.5 18.8284 9.82843 19.5 9 19.5C8.17157 19.5 7.5 18.8284 7.5 18M10.5 18C10.5 17.1716 9.82843 16.5 9 16.5C8.17157 16.5 7.5 17.1716 7.5 18M3.75 18L7.5 18M16.5 12L20.25 12M16.5 12C16.5 12.8284 15.8284 13.5 15 13.5C14.1716 13.5 13.5 12.8284 13.5 12M16.5 12C16.5 11.1716 15.8284 10.5 15 10.5C14.1716 10.5 13.5 11.1716 13.5 12M3.75 12H13.5"
                    stroke="#666666"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <BottomSheet
                title="Filters"
                isOpen={isBottomSheetVisible}
                onClose={() => setIsBottomSheetVisible(false)}
              >
                <div className="grid gap-[8px]">
                  <CheckboxSelect
                    size="large"
                    options={
                      defaultFilters.offersByLanguage
                        .find(
                          (filter) =>
                            filter.language === filters['filter.language'],
                        )
                        ?.offers.map((offer) => ({
                          value: offer.offerID,
                          label: offer.title,
                        })) || []
                    }
                    allOptionsLabel="All offers"
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        'filter.offerIDs': value as number[],
                      })
                    }
                    values={filters['filter.offerIDs']}
                  />
                  <CheckboxSelect
                    size="large"
                    options={defaultFilters.sizes.map((size) => ({
                      value: size.value,
                      label: size.title,
                    }))}
                    allOptionsLabel="All sizes"
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        'filter.sizes': value as string[],
                      })
                    }
                    values={filters['filter.sizes']}
                  />
                  <CheckboxSelect
                    size="large"
                    options={defaultFilters.formats.map((format) => ({
                      value: format.value,
                      label: format.title,
                    }))}
                    allOptionsLabel="All formats"
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        'filter.formats': value as string[],
                      })
                    }
                    values={filters['filter.formats']}
                  />
                </div>
                <div className="mt-[16px] grid gap-[16px]">
                  <Button
                    className="w-full"
                    onClick={() => {
                      if (page === 1) {
                        refetch()
                      } else {
                        setPage(1)
                      }

                      setIsBottomSheetVisible(false)
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    className="w-full"
                    design="tertiary"
                    onClick={clearFilters}
                  >
                    Clear
                  </Button>
                </div>
              </BottomSheet>
            </>
          )}
        </Filters>
      )}
      {data && data.items.length > 0 ? (
        <>
          {data.sharedLink && (
            <LinkForShare className="max-w-[387px]" link={data.sharedLink} />
          )}
          <Styled.WebsiteBannersGrid>
            {data.items.map((item, index) => (
              <Fragment key={item.id}>
                <Styled.MediaWrapper key={index}>
                  <Styled.MediaFile isBanner>
                    <img
                      src={item.previewLink}
                      alt={item.title}
                      loading="lazy"
                    />
                    <Styled.MediaActions>
                      <Button
                        prefix={<Icon2 name="arrowsPointingOut" size="small" />}
                        design="secondary"
                        size="small"
                        onClick={() => setOpenedMediaId(item.id)}
                      />
                      <DownloadButton url={item.originLink} name={item.name} />
                    </Styled.MediaActions>
                    <Styled.FormatTag>
                      {item.extension.toUpperCase()}
                    </Styled.FormatTag>
                  </Styled.MediaFile>
                  <div className="py-[12px]">
                    <Title level={4} withTranslate={false}>
                      {item.title}
                    </Title>
                    <div className="mt-[4px] text-body.small leading-body.small text-gray.600 mobile:text-body.medium mobile:leading-body.medium">
                      {item.dimensions}, {item.extension}, {item.size}
                    </div>
                  </div>
                </Styled.MediaWrapper>

                <Modal
                  open={openedMediaId === item.id}
                  onClose={() => setOpenedMediaId(undefined)}
                  width={isMobile ? 343 : 626}
                >
                  <MediaModalContent {...item} />
                </Modal>
              </Fragment>
            ))}
          </Styled.WebsiteBannersGrid>

          {data.totalRecords > 12 && (
            <div className="mt-[24px] mobile:mt-[16px] flex items-center">
              {isDesktop && (
                <PageSizes
                  sizes={[12, 24, 42]}
                  onChange={setPerPage}
                  activeSize={perPage}
                />
              )}
              {data.totalPages > 1 && (
                <div className="ms-auto mobile:ms-0 mobile:w-full">
                  <Pagination
                    pageSize={perPage}
                    current={page}
                    total={data.totalRecords}
                    onChange={setPage}
                  />
                </div>
              )}
            </div>
          )}
        </>
      ) : filtersLoading || dataLoading ? null : (
        <NoResultsWrapper>
          <NoResultsTitle level={isMobile ? 4 : 2}>
            {t('No results found')}
          </NoResultsTitle>
          <NoResultsText>
            {t(
              'There are no media matching your criteria. Please try to reset the filters.',
            )}
          </NoResultsText>
          <Button
            name="reset-filters"
            design="secondary"
            size={isMobile ? 'small' : 'large'}
            onClick={() => {
              clearFilters()
              refetch()
            }}
            prefix={<Icon2 name="XCircleIconOutline" />}
          >
            Reset filters
          </Button>
        </NoResultsWrapper>
      )}
    </div>
  )
}
