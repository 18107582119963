import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';

import { GET_PROFILE_KEY } from 'services/profile/getProfile/useGetProfile';
import { ProfileContent } from 'services/profile/getProfile';


const patchHideRequestStatus = async () => {  
  await axios.patch('/user/v1/hide-profile-change-result');
}

export const useHideRequestStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchHideRequestStatus,
    retry: retryFn,
    onSuccess(data, address) {
    // Эта функция перезаписывает кэш
    queryClient.setQueryData
      (
        GET_PROFILE_KEY, 
        // @ts-ignore
        (oldData: ProfileContent) => ({ 
          ...oldData, 
          profileChangeRequest: {
            ...oldData.profileChangeRequest,
            isClosed: true,
          }
        }),
      );
    },
  })
}