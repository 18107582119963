import styled from 'styled-components/macro'

import refresh from '../../images/refresh-icon.svg'

export const RefreshButton = styled.button`
  padding-left: 24px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  color: var(--color-bronze-600);
  font-weight: 500;
  background-image: url(${refresh});
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'right center' : 'left center'};
`