import { useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { WithdrawalForm } from './WithdrawalFormTypes';

export const GET_DEPOSIT_FORM_KEY = ['deposit-form'];


const getWithdrawalForm = async (gatewayID: string) => {
  const res = await axios.get<WithdrawalForm>(`/ps-forms/v1/withdrawal/payment-form`, {
    params: {
      gatewayID,
    },
  });

  return res.data;
};

export const useGetWithdrawalForm = (gatewayID: string) => {
  return useQuery({
    queryKey: [GET_DEPOSIT_FORM_KEY, gatewayID],
    queryFn: () => getWithdrawalForm(gatewayID),
    retry: retryFn,
    enabled: !!gatewayID,
    staleTime: 2000
  })
};
