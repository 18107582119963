import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { BankCardListItem } from 'services/payments/get/types';

import { Money, Text, Icon2 } from 'ui/atoms';
import { getCardNumber } from '../utils/getCardNumber';
import * as S from './styled';


interface Props {
  card: BankCardListItem;
}

const statuses = {
  pending: {
    title: 'Pending',
    color: '#004DA1',
    bg: '#E4EAFF',
  },
  verified: {
    title: 'Verified',
    color: '#245947',
    bg: '#CBECDF',
  },
  rejected: {
    title: 'Rejected',
    color: '#B2423E',
    bg: '#FCE7E7',
  },
  expired: {
    title: 'Expired',
    color: '#40372A',
    bg: '#F5F5F5',
  },
}

export const CardStatusItem: React.FC<Props> = ({ card }) => {
  // @ts-ignore
  const statusObj = statuses[card.status];

  const [isShowComment, setIsShowComment] = useState(false);

  const onToggleComment = () => setIsShowComment(prev => !prev);

  return (
    <S.CardItem>
      <div
        className='flex w-full items-center'

      >
        <LazyLoadImage
          src={card.logo}
          width={32}
          height={32}
          alt="card-logo"
          className="h-[3.2rem] w-[3.2rem]"
        />

        <div
          className={`w-full flex ms-[12px] ${card.comment ? 'cursor-pointer' : ''}`}
          onClick={card.comment ? onToggleComment : undefined}
        >
          <div className='flex-1'>
            <Text level={2}>{getCardNumber(card.mask)}</Text>
            <Text level={2} className='mt-[4px]'>Withdrawal limit</Text>
          </div>

          <div className='flex items-center'>
            {card.comment && 
              <Icon2
                size='base'
                name={isShowComment ? 'chevronUp' : 'chevronDown'}
                className='cursor-pointer'
              />
            }
            <div className='flex-1 ms-[8px] flex flex-col items-end'>
                <S.Status
                  level={3}
                  withTranslate={false}
                  status={card.status}
                >
                  {statusObj.title}
                </S.Status>
                <Text level={2} withTranslate={false} className='!font-medium'>
                <Money
                  value={+card.balance}
                  currency={card.currency}
                />
              </Text>
            </div>
          </div>
        </div>
      </div>
      
    { isShowComment && (
      <S.CommentBlock>
        <Text withTranslate={false} level={2}>
          { card.comment }
        </Text>
      </S.CommentBlock>
    )}
    </S.CardItem>
  )
}
