import React from 'react';

import { PartnerStatuses } from 'services/ib/get/types';

import * as S from './styled';

interface Props {
  label: string;
  comission: string;
  description: string;
  status: PartnerStatuses;
}

export const ProgressStatus: React.FC<Props> = ({ label, comission, description, status }) => {
  return (
    <>
      <S.ProgressWrapper status={ status }>
        <div className='flex'>
          <S.Title
            level={1}
            className='!font-medium flex-1'
            status={status}
            isMainColor={
              status === 'PARTNER_STATUS_ROYAL'
            }
          >
            {label}
          </S.Title>
          <S.Icon
            name='mainLogo'
            size='custom'
            className={`w-[3.2rem] h-[3.2rem]` }
            status={status}
          />
        </div>

        <div className='flex items-baseline'>
          <S.Title
            status={ status }
            level={1}
            className='!font-medium me-4'
            withTranslate={false}
            
          >
          {comission}%
          </S.Title>
          <S.Text status={status} level={3}>commission</S.Text>
        </div>

        <S.Text status={status} level={2}>
          { description } 
        </S.Text>
      </S.ProgressWrapper>
    </>
  )
}
