import React from 'react';
import { useFacebookLogin } from 'facebook-oauth-react';

import { usePostLinkSocial } from 'services/user/mutations/usePostLinkSocial';
import { Text } from 'ui/atoms';
import { Spinner2 } from 'ui/atoms';


export const FacebookLogin = () => {
  const { mutateAsync: linkSocialMutate, isLoading } = usePostLinkSocial();

  const facebookLogin = useFacebookLogin({
    onSuccess: (response) => {
      linkSocialMutate({
        accessToken: response.accessToken,
        socialType: 'facebook',
      })
    },
    onError: () => console.log('Facebook oauth error'),
  })

  if (isLoading) return <Spinner2 />

  return (
    <Text
      className='cursor-pointer !text-sunray.500'
      onClick={ () => facebookLogin() }
    >
      Link the account
    </Text>
  )
}
