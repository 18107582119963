import ReactPagination from 'rc-pagination'
import styled from 'styled-components/macro'

import { Button } from '../button'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--body-font-size-medium);
  font-weight: 500;
`

export const SizeChanger = styled.div`
  display: flex;
  align-items: center;
`

export const ArrowButton = styled(Button)`
  margin-inline-start: 20px;
  padding: 6px;
  width: 32px;
  height: 32px !important;
  transform: rotate(-90deg);
  border-radius: 4px;

  &[name='prev'] {
    margin-inline-start: 0;
    margin-inline-end: 20px;
    transform: rotate(90deg);
  }
`

export const Pagination = styled(ReactPagination)<{ fullwidth: boolean }>`
  display: flex;
  align-items: center;
  width: ${(props) => (props.fullwidth ? '100%' : 'auto')};

  & > .rc-pagination-prev {
    margin-inline-end: auto;
  }

  & > .rc-pagination-next {
    margin-inline-start: auto;
  }
`

export const Page = styled.div<{ active?: boolean }>`
  cursor: pointer;
  pointer-events: ${(props) => (props.active ? 'none' : 'default')};
  padding: 6px 12px;
  border-radius: 4px;
  transition-property: background-color, color;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  background-color: ${(props) =>
    props.active ? 'var(--color-sunray-500)' : 'transparent'};

  &:hover {
    background-color: var(--color-gray-300);
  }

  &:active {
    color: var(--color-sunray-500);
    background-color: var(--color-sunray-100);
  }
`
