import styled, { css } from 'styled-components'
import { Button, Title } from 'ui/atoms'

import success from '../images/success.png'
import successWeb from 'styles/img/success.webp';

export const Wrapper = styled.div`
  padding: 20px;
  padding-bottom: 48px;
  display: grid;
  justify-items: start;
  gap: 16px;
  background-color: var(--color-gray-100);
  box-shadow: 0px 4px 6px -1px rgba(38, 33, 25, 0.1),
    0px 2px 4px -1px rgba(38, 33, 25, 0.06);
  border-radius: 8px;
  height: auto;

  @media (max-width: 599px) {
    padding: 16px 12px;
    gap: 20px;
  }
`

export const ContentContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 392px;
`

export const TransferTitle = styled(Title)`
  text-align: center;
`

export const Fields = styled.div`
  margin-top: 32px;
  display: grid;
  gap: 10px;

  @media (max-width: 599px) {
    margin-top: 20px;
  }
`

export const Amount = styled.div`
  margin-top: 4px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  color: var(--color-gray-600);
`

export const Actions = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 599px) {
    margin-top: 36px;
    grid-template-columns: 1fr;
    gap: 12px;
  }
`

export const TransferButton = styled(Button)`
  @media (max-width: 599px) {
    grid-row: 1 / 2;
  }
`

export const RadioWrapper = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  margin: 16px 0;
  display: flex;

  @media (max-width: 599px) {
    margin: 12px 0;
  }

  &::before {
    content: '';
    margin: 2px;
    margin-inline-end: 10px;
    border: 2px solid var(--color-gray-400);
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      pointer-events: none;

      &::before {
        border: 7px solid var(--color-sunray-500);
      }
    `}
`

export const SuccessScreen = styled.div`
  margin: 0 auto;
  padding-top: 152px;
  width: 100%;
  max-width: 580px;
  text-align: center;
  background-image: url(${success});
  background-image: image-set(url(${successWeb}), url(${success}));
  background-repeat: no-repeat;
  background-size: 128px auto;
  background-position: top 16px center;

  @media (max-width: 599px) {
    padding-top: 140px;
    background-position: top center;
  }
`

export const SuccessText = styled.div`
  margin-top: 16px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);

  @media (max-width: 599px) {
    margin-top: 12px;
  }
`

export const TransactionInfo = styled.div`
  margin: 32px 0;
  border: 1px solid var(--color-gray-300);
  padding: 16px;
  display: grid;
  gap: 16px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  border-radius: 8px;

  @media (max-width: 599px) {
    padding: 16px 12px;
    gap: 12px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const DashboardLink = styled(Button)`
  margin: 0 auto;
  min-width: 204px;
  display: inline-flex;

  @media (max-width: 599px) {
    width: 100%;
  }
`