import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';

import { $customWithdrawalStore } from './model/withdrawalStore';

import { CustomWithdrawalPage } from './customWithdrawal/CustomWithdrawalPage';
import { resetWithdrawalEvent } from './model/withdrawalStore';
import { PraxisWithdrawalPage } from './framePraxis';
import { path } from 'libs/path';

import * as S from './styled';


export const ResolveWithdrawalPage = () => {
  const nav = useNavigate();
  const customWithdrawalStore = useUnit($customWithdrawalStore);

  const provider = customWithdrawalStore?.provider;

  useEffect(() => {
    if (!customWithdrawalStore || !customWithdrawalStore?.gatewayId) {
      nav(path.money.withdrawalTab());
    }
  }, []);

  useEffect(() => {
    return () => {
      resetWithdrawalEvent();
    }
  }, []);
  
  
  if (provider === 'PPP_PRAXIS') return (
    <S.Wrapper>
      <PraxisWithdrawalPage/>
    </S.Wrapper>
  )

  return (
    <S.Wrapper>
      <CustomWithdrawalPage />
    </S.Wrapper>
  )
}
