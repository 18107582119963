import React, { useEffect, useState, useRef } from 'react';
import { Text} from 'ui/atoms';

import * as S from './styled';

// Sec
const TIME_TO_RESEND_CODE = 60;

interface Props {
  resendCode: () => Promise<void>;
  isError?: boolean;
}

export const ResendTimer: React.FC<Props> = ({ resendCode, isError }) => {
  const [timer, setTimer] = useState(TIME_TO_RESEND_CODE);
  const timerId = useRef<NodeJS.Timer>();
  const isRunning = timer > 0;

  const resendCodeAndNewTimer = async () => {
    await resendCode();
    setTimer(TIME_TO_RESEND_CODE);
  }


  useEffect(() => {
    if (timerId.current || timer === 0) return;

    timerId.current = setInterval(() => {
      
      setTimer(prev => {
        if (prev === 1) {
          clearInterval(timerId.current);
          timerId.current = undefined;
          return prev - 1;
        }

        return prev - 1;
      });
    }, 1000);
  }, [timer]);
  

  return (
    <div className='flex h-full items-center'>
      {isRunning && <S.ConfirmTimerCount isError={ isError }>{timer}</S.ConfirmTimerCount>}
      {!isRunning && <S.ResendText isError={ isError }>Resend</S.ResendText>} 
      <S.ConfirmTimerIcon
        isError={ isError }
        name={isRunning ? 'clock' : 'arrowPath'}
        data-running={isRunning}
        onClick={
          isRunning
            ? undefined
            : resendCodeAndNewTimer
        }
      />
    </div>
  )
}