import styled from 'styled-components'
import { Button } from 'ui/atoms'

export const Wrapper = styled.div`
  padding: 20px;
  padding-bottom: 24px;
  background-color: var(--color-gray-100);
  box-shadow: 0px 4px 6px -1px rgba(38, 33, 25, 0.1),
    0px 2px 4px -1px rgba(38, 33, 25, 0.06);
  border-radius: 8px;
  height: auto;

  @media (max-width: 599px) {
    padding: 16px 12px;
  }
`

export const AmountLabel = styled.div`
  margin-top: 24px;
  margin-bottom: 4px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  color: var(--color-gray-600);

  @media (max-width: 599px) {
    margin-top: 20px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 790px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export const WalletActions = styled.div`
  display: flex;

  @media (max-width: 790px) {
    margin-top: 20px;
    margin-inline-start: auto;
  }

  @media (max-width: 599px) {
    margin-inline-start: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 6px;
  }
`

export const TransferButton = styled(Button)`
  @media (max-width: 599px) {
    grid-row: 1 / 2;
    grid-column: span 2;
  }
`
