import { Account } from 'services/accounts/types'
import { AccountLine as UiAccountLine } from 'ui/molecules'

import * as Styled from './styled'

export const AccountLine = ({
  account,
  className,
}: {
  account: Account
  className?: string
}) => {
  return (
    <Styled.Wrapper className={className}>
      <UiAccountLine {...account} />
    </Styled.Wrapper>
  )
}
