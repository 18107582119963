import { useQuery } from '@tanstack/react-query';
import { omitBy } from 'lodash';

import { axios } from 'services/axios';
import { GetClientsList } from './types';
import { SortObjParams } from 'pages/ib/models/tableSortStore';
import { FilterStore } from 'pages/ib/models/tableFilterStore';


export const PARTNER_CLIENTS_LIST_KEY = 'PARTNER_CLIENTS_LIST_KEY';

const getClients = async ({ filter = {}, sort,  ...params}: Options) => {
  const sortDirection = sort?.direction && (sort?.direction === 'asc' ? 'PASD_ASC' : 'PASD_DESC');
  
  const paramsObj = {
    page: params.page,
    perPage: params.perPage,
    'filter.login': filter?.login,
    'filter.commissionPeriod.from': filter?.commissionPeriod?.from,
    'filter.commissionPeriod.to': filter?.commissionPeriod?.to,
    'sorting.field': sort?.name,
    'sorting.direction': sortDirection,
  };

  const res = await axios.get<GetClientsList>(`/ib/v1/partner/clients-accounts`, {
    params: omitBy(paramsObj, (val) => !val)
  });
  return res.data;
};

interface Options {
  page: number;
  perPage: number;
  sort?: SortObjParams | null;
  filter: FilterStore;
}

export const useGetClients = ({ page = 1, perPage = 10, filter, sort }: Options) => {
  return useQuery({
    queryKey: [PARTNER_CLIENTS_LIST_KEY, page, perPage, filter, sort],
    queryFn: () => getClients({ page, perPage, filter, sort }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // staleTime: 1000,
  })
};
