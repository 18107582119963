import { useQuery } from '@tanstack/react-query'
import { Account } from 'pages/accounts/types'
import { axios } from 'services/axios'

export const GET_ARCHIVED_ACCOUNTS_KEY = ['archived-accounts']

const getArchivedAccounts = async () => {
  const res = await axios.get<{ items: Account[] }>(
    '/account/v1/archived-accounts',
  )

  return res.data.items
}

export const useGetArchivedAccounts = () => {
  return useQuery({
    queryKey: GET_ARCHIVED_ACCOUNTS_KEY,
    queryFn: getArchivedAccounts,
  })
}
