import { useIsMobile } from 'libs/hooks/useIsMobile'
import { path } from 'libs/path'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AccountSourceItem, SourceItem } from 'services/payments/get/types'
import { Icon2, Money } from 'ui/atoms'
import { toCurrency } from 'ui/atoms/money'
import { AccountLine, WalletLine } from 'ui/molecules'
import { mapAccountPlatform, mapAccountTariff } from 'ui/molecules/accountLines'

import * as Styled from './styled'

type Props = {
  defaultValue?: string
  data?: SourceItem[]
  label?: string
  classes?: string
  inputMode?: HTMLInputElement['inputMode']
  isDisabled?: boolean
  onSelect: (value: any) => void
  className?: string
  placeholder?: string
  createAccountEnabled?: boolean
  onCreateAccount?: () => void
}

const walletsType = ['OT_WALLET', 'OT_EXCHANGER_WALLET'];

export const AccountSelect: React.FC<Props> = ({
  onSelect,
  defaultValue = '',
  classes = '',
  label,
  data,
  inputMode,
  isDisabled,
  className,
  placeholder,
  createAccountEnabled = false,
  onCreateAccount
}) => {
  const [t] = useTranslation()

  const navigate = useNavigate()

  const [currentValue, setCurrentValue] = useState<string>(defaultValue)

  const isMobile = useIsMobile();

  const handleSelect = (source: SourceItem) => {
    setCurrentValue(source.id)
    onSelect(source)
  }

  const handleCreateAccount = () => {
    if (onCreateAccount) {
      onCreateAccount()
    }

    navigate(path.accounts.create.tariff('real'))
  }

  const accounts = useMemo(() => {
    if (!data) return []

    return data.filter((item) => item.type === 'OT_ACCOUNT')
  }, [data])

  const wallets = useMemo(() => {
    if (!data) return []

    return data.filter((item) => walletsType.includes(item.type))
  }, [data])

  const options = useMemo(() => {
    if (!data) return []

    const resultOpt: { label: string; value: string }[] = []

    data?.forEach((source) => {
      const money = isMobile ? toCurrency(+source.balance, source.currency) : '';
      let label = ``;

      if (source.type === 'OT_ACCOUNT') {
        label = `${source.name || source.accountName || source.id} ${
          mapAccountPlatform[source.accountPlatform] || ''
        } ${mapAccountTariff[source.accountType] || ''} ${money}`.trim();
      } else if (walletsType.includes(source.type)) {
        label = source.name || `Wallet ${source.currency} ${isMobile ? money : ''}`;
      }

      resultOpt.push({
        label,
        value: source.id,
      })
    })

    return resultOpt
  }, [data, isMobile]);

  const getChoosedValue = useMemo(() => {
    const valueObj = data?.find((item) => item.id === currentValue)
    return valueObj
  }, [currentValue, data]);

  const getSuffix = useCallback((isDisabledSuffix?: boolean) => {
    if (!getChoosedValue?.type) return;

    const sufixComponent = (
      <div className={isDisabledSuffix ? 'text-gray.500' : ''}>
        <Money
          value={+getChoosedValue.balance}
          currency={getChoosedValue.currency}
        />
      </div>
    )

    // Если кошелек
    if (walletsType.includes(getChoosedValue.type)) {
      // Если мобилка, то суффикса нет
      return isMobile ? null : sufixComponent;
    }

    // Если аккаунт и не мобилка, то возвращаем суффикс
    if (!isMobile) return sufixComponent;


  }, [getChoosedValue, isMobile]);

  useEffect(() => {
    if (!defaultValue) return
    setCurrentValue(defaultValue)
  }, [defaultValue]);

  return (
    <>
      <Styled.SelectWrapper
        value={currentValue}
        name="wallets-select"
        placeholder={placeholder || 'Choose wallet or account'}
        className={`${classes} ${className}`}
        inputClasses={'!pr-[40px]'}
        label={label}
        modalTitle='Account/wallet'
        // @ts-ignore
        inputMode={inputMode}
        maxHeight={410}
        showOptions={false}
        disabled={isDisabled}
        suffix={getSuffix(isDisabled)}
        autoComplete="off"
        customContent={
          <Styled.SelectContent>
            {createAccountEnabled && (
              <Styled.CreateAccountButton
                name="create-account"
                size="small"
                design="secondary"
                prefix={<Icon2 name="plus" size='small' />}
                onClick={handleCreateAccount}
              >
                Create new account
              </Styled.CreateAccountButton>
            )}
            {wallets?.length > 0 && (
              <div>
                <div className="flex justify-between">
                  <Styled.ContentTitle>{t('Wallets')}</Styled.ContentTitle>
                  <Styled.ContentTitle>
                    {t('Available for transfer')}
                  </Styled.ContentTitle>
                </div>
                <div>
                  {wallets.map((wallet) => (
                    <Styled.SelectOption
                      key={wallet.id}
                      onClick={() => handleSelect(wallet)}
                    >
                      <WalletLine
                        // @ts-ignore
                        walletID={wallet.sourceID}
                        name={wallet.name}
                        // @ts-ignore
                        balance={wallet.balance}
                        currency={wallet.currency}
                      />
                    </Styled.SelectOption>
                  ))}
                </div>
              </div>
            )}
            {accounts?.length > 0 && (
              <div>
                <div className="flex justify-between">
                  <Styled.ContentTitle>{t('Accounts')}</Styled.ContentTitle>
                  <Styled.ContentTitle>
                    {t('Available for transfer')}
                  </Styled.ContentTitle>
                </div>
                <div>
                  {(accounts as AccountSourceItem[]).map((account) => (
                    <Styled.SelectOption
                      key={account.id}
                      onClick={() => handleSelect(account)}
                    >
                      {/* @ts-ignore */}
                      <AccountLine
                        {...account}
                        tariffName={account.accountType}
                        platform={account.accountPlatform}
                      />
                    </Styled.SelectOption>
                  ))}
                </div>
              </div>
            )}
          </Styled.SelectContent>
        }
        options={options}
      />
    </>
  )
}
