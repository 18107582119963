import { Icon2 } from '../icon2'
import * as Styled from './styled'

export const Pagination = ({
  showSizeChanger,
  showLessItems,
  ...props
}: {
  pageSize: number
  current: number
  total: number
  showLessItems?: boolean;
  onChange: (current: number, pageSize: number) => void
  showSizeChanger?: boolean
}) => {
  return (
    <Styled.Wrapper>
      {showSizeChanger ? (
        <Styled.SizeChanger>
          {[20, 40, 60].map((count) => (
            <Styled.Page
              key={count}
              active={count === props.pageSize}
              onClick={() => props.onChange(props.current, count)}
            >
              {count}
            </Styled.Page>
          ))}
        </Styled.SizeChanger>
      ) : null}
      <Styled.Pagination
        fullwidth={!showSizeChanger}
        showLessItems={showLessItems}
        itemRender={(current, type) => {
          switch (type) {
            case 'prev':
              return (
                <Styled.ArrowButton
                  name="prev"
                  size="small"
                  design="secondary"
                  suffix={<Icon2 name="chevronDown" size="small" />}
                />
              )
            case 'next':
              return (
                <Styled.ArrowButton
                  name="next"
                  size="small"
                  design="secondary"
                  suffix={<Icon2 name="chevronDown" size="small" />}
                />
              )
            case 'page':
              return (
                <Styled.Page active={current === props.current}>
                  {current}
                </Styled.Page>
              )
            case 'jump-next':
              return <Styled.Page>...</Styled.Page>
          }

          return <div />
        }}
        {...props}
      />
    </Styled.Wrapper>
  )
}
