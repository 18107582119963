import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { SourceItem, SourceList } from './types';

export const GET_DESTINATIONS_LIST_KEY = 'destinations-list';

type Orient = 'deposit' | 'withdrawal';

const getSourceList = async (orient: Orient) => {
  if (orient === 'withdrawal') {
    const res = await axios.get<SourceList>(`/payments/v2/withdrawal-sources-list`);
    return res.data?.targets;
  } else {
    const res = await axios.get<SourceList>(`/payments/v2/deposit-destinations-list`);
    return res.data?.targets;
  }
};

export const useGetSourceList = (orient: Orient = 'deposit'): UseQueryResult<SourceItem[]> => {
  return useQuery({
    queryKey: [GET_DESTINATIONS_LIST_KEY, orient],
    queryFn: () => getSourceList(orient),
    // refetchOnWindowFocus: false,
    retry: retryFn,
    staleTime: 1000,
  })
};
