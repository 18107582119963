import React from 'react';

import { Title } from 'ui/atoms';

export const Head = () => {
  return (
    <thead>
      <tr className='text-left'>
      <th className='p-[1.2rem] pe-[4.8rem]'><Title level={4}>Platform</Title></th>
      <th className='p-[1.2rem] pe-[4.8rem]'><Title level={4}>IP Address</Title></th>
      <th className='p-[1.2rem] pe-[4.8rem]'><Title level={4}>Log time</Title></th>
      </tr>
  </thead>
  )
}
