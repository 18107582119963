import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  background-color: var(--color-gray-300);
`

export const ContentContainer = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1140px;

  @media (max-width: 599px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`