import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export const GET_IB_WEBSITE_BANNERS_FILTERS_KEY = ['ib-website-banners-filters']

const getWebsiteBannersFilters = async () => {
  const res = await axios.get<{
    languages: { value: string; isSelected: string }[]
    offersByLanguage: {
      language: string
      offers: { title: string; offerID: number }[]
    }[]
    sizes: { title: string; value: string }[]
    formats: { title: string; value: string }[]
  }>('/ib-materials/v1/website-banners-filters')

  return res.data
}

export const useGetWebsiteBannersFilters = () => {
  return useQuery({
    queryKey: GET_IB_WEBSITE_BANNERS_FILTERS_KEY,
    queryFn: getWebsiteBannersFilters,
  })
}
