import { useDebounce } from '@uidotdev/usehooks'
import { useWindowSize } from '@uidotdev/usehooks'
import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useGetCopyingAccountsForFilter } from 'services/copytrade'
import { Button, Icon2, Input, Select, Text, Title, Toggle } from 'ui/atoms'
import { Alerts, BottomSheet } from 'ui/molecules'

import {
  $strategiesFilters,
  changeStrategiesFiltersField,
  resetStrategiesFilters,
} from '../../model'
import { FilterSelect } from '../filterSelect'
import { SignupTooltip } from '../signupTooltip'
import * as Styled from './styled'

const SortingButton = ({
  isActive,
  onClick,
}: {
  isActive: boolean
  onClick?: () => void
}) => {
  return (
    <Styled.SortingButton isActive={isActive} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
      >
        <path
          stroke="#262119"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25"
        />
      </svg>
    </Styled.SortingButton>
  )
}

export const TraderFilter = ({
  currentView,
  onChangeView,
}: {
  currentView: 'cards' | 'list'
  onChangeView?: (view: 'cards' | 'list') => void
}) => {
  const [t] = useTranslation()

  const navigate = useNavigate()

  const { userID } = useStore($userProfile)

  const { width } = useWindowSize()
  const isMobileMode = width && width < 950

  const strategiesFilters = useStore($strategiesFilters)

  const { data: accounts } = useGetCopyingAccountsForFilter(!!userID)

  const [commissionValue, setCommissionValue] = useState<{
    from: string
    to: string
  }>({ from: '', to: '' })
  const [isCommissionModalVisible, setIsCommissionModalVisible] =
    useState(false)

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 300)

  useEffect(() => {
    changeStrategiesFiltersField({
      field: 'filter.strategyName',
      value: debouncedSearchValue,
    })
  }, [debouncedSearchValue])

  const [isSortingModalVisible, setIsSortingModalVisible] = useState(false)
  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false)

  const [mobileFiltersParams, setMobileFiltersParams] = useState<{
    copies: string | null
    risk: string | null
    platform: string | null
    isFavorites: boolean
    from: string | null
    to: string | null
  }>({
    copies: null,
    risk: null,
    platform: null,
    isFavorites: false,
    from: null,
    to: null,
  })

  const handleChangeCommission = (
    event: ChangeEvent<HTMLInputElement>,
    field: 'from' | 'to',
  ) => {
    const output = event.target.value.replace(/[^.\d]/g, '').split('.')
    const value =
      output.length > 1 ? output.shift() + '.' + output.join('') : output[0]

    if (isMobileMode) {
      setMobileFiltersParams({ ...mobileFiltersParams, [field]: value })
    } else {
      setCommissionValue({ ...commissionValue, [field]: value })
    }
  }

  const handleMobileFiltersSubmit = () => {
    changeStrategiesFiltersField({
      field: 'filter.copyingAccountIDs',
      value:
        mobileFiltersParams.copies === 'all' && accounts
          ? accounts.map((account) => account.accountID)
          : [mobileFiltersParams.copies],
    })

    changeStrategiesFiltersField({
      field: 'filter.risk',
      value: mobileFiltersParams.risk,
    })

    changeStrategiesFiltersField({
      field: 'filter.platform',
      value: mobileFiltersParams.platform,
    })

    changeStrategiesFiltersField({
      field: 'filter.commission.from',
      value: mobileFiltersParams.from,
    })

    changeStrategiesFiltersField({
      field: 'filter.commission.to',
      value: mobileFiltersParams.to,
    })

    changeStrategiesFiltersField({
      field: 'filter.isFavorites',
      value: mobileFiltersParams.isFavorites,
    })

    setIsFiltersModalVisible(false)
  }

  const handleClearFilters = () => {
    resetStrategiesFilters()

    if (isMobileMode) {
      setMobileFiltersParams({
        copies: null,
        risk: null,
        platform: null,
        isFavorites: false,
        from: null,
        to: null,
      })

      setIsFiltersModalVisible(false)
    }
  }

  const sortingOptions = [
    { title: t('AI algorithm'), value: 'FIELD_AI_ASSESSMENT' },
    { title: t('Profit'), value: 'FIELD_GROSS_PROFIT' },
    { title: t('Win rate'), value: 'FIELD_WIN_RATE' },
    { title: t('Active days'), value: 'FIELD_ACTIVE_DAYS' },
    { title: t('Popularity'), value: 'FIELD_POPULARITY' },
  ]

  const copiesOptions =
    accounts && accounts.length > 0
      ? [
          { title: t('All Accounts'), value: 'all' },
          ...accounts.map((account) => ({
            value: account.accountID,
            title: account.accountName,
          })),
        ]
      : []

  const platformOptions = [
    { title: 'MT4', value: 'PAAP_MT4' },
    { title: 'MT5', value: 'PAAP_MT5' },
  ]

  const riskOptions = [
    { title: t('Low'), value: 'PASR_LOW' },
    { title: t('Medium'), value: 'PASR_MEDIUM' },
    { title: t('High'), value: 'PASR_HIGH' },
  ]

  return (
    <Styled.Wrapper>
      {!isMobileMode ? (
        <FilterSelect
          defaultValue={strategiesFilters['sorting.field']}
          options={sortingOptions}
          onChange={(value) =>
            changeStrategiesFiltersField({ field: 'sorting.field', value })
          }
          contentWidth={160}
        >
          <SortingButton isActive={!!strategiesFilters['sorting.field']} />
        </FilterSelect>
      ) : (
        <>
          <SortingButton
            isActive={!!strategiesFilters['sorting.field']}
            onClick={() => setIsSortingModalVisible(true)}
          />
          <BottomSheet
            isOpen={isSortingModalVisible}
            title="Sorting"
            onClose={() => setIsSortingModalVisible(false)}
          >
            {sortingOptions.map((option) => (
              <Styled.MobileSortingOption
                key={option.value}
                isActive={strategiesFilters['sorting.field'] === option.value}
                onClick={() => {
                  changeStrategiesFiltersField({
                    field: 'sorting.field',
                    value: option.value,
                  })

                  setIsSortingModalVisible(false)
                }}
              >
                {option.title}
              </Styled.MobileSortingOption>
            ))}
          </BottomSheet>

          <Styled.Button onClick={() => setIsFiltersModalVisible(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                stroke="#262119"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
              />
            </svg>
          </Styled.Button>
          <BottomSheet
            isOpen={isFiltersModalVisible}
            title="Filter"
            onClose={() => setIsFiltersModalVisible(false)}
          >
            <div className="grid gap-[8px]">
              <Select
                label="My copies"
                placeholder="Select account name"
                value={mobileFiltersParams.copies || ''}
                options={copiesOptions.map(({ title, value }) => ({
                  label: title,
                  value,
                }))}
                onSelect={(value) =>
                  setMobileFiltersParams({
                    ...mobileFiltersParams,
                    copies: value,
                  })
                }
                disabled={!userID}
              />
              {!userID ? (
                <Alerts type="info">
                  <Text level={2}>Sign up on Headway to start copying</Text>
                  <br />
                  <Text
                    level={2}
                    weight="medium"
                    className="!text-azure.500"
                    onClick={() => {
                      // сначала нужно корректно закрыть шторку
                      setIsFiltersModalVisible(false)

                      setTimeout(() => {
                        navigate(path.register.entry())
                      }, 10)
                    }}
                  >
                    Sign up
                  </Text>
                </Alerts>
              ) : null}
              <Select
                label="Risk"
                placeholder="Select risk"
                value={mobileFiltersParams.risk || ''}
                options={riskOptions.map(({ title, value }) => ({
                  label: title,
                  value,
                }))}
                onSelect={(value) =>
                  setMobileFiltersParams({
                    ...mobileFiltersParams,
                    risk: value,
                  })
                }
              />
              <Select
                label="Platform"
                placeholder="Select platform"
                value={mobileFiltersParams.platform || ''}
                options={platformOptions.map(({ title, value }) => ({
                  label: title,
                  value,
                }))}
                onSelect={(value) =>
                  setMobileFiltersParams({
                    ...mobileFiltersParams,
                    platform: value,
                  })
                }
              />
            </div>
            <div className="mt-[24px]">
              <Title level={4}>Commission</Title>
              <div className="mt-[12px] grid grid-cols-2 gap-[12px]">
                <Input
                  placeholder="From"
                  value={
                    mobileFiltersParams.from
                      ? `$${mobileFiltersParams.from}`
                      : ''
                  }
                  onChange={(event) => handleChangeCommission(event, 'from')}
                />
                <Input
                  placeholder="To"
                  value={
                    mobileFiltersParams.to ? `$${mobileFiltersParams.to}` : ''
                  }
                  onChange={(event) => handleChangeCommission(event, 'to')}
                />
              </div>
            </div>
            <div className="flex items-center justify-between mt-[24px] mb-[16px] py-[16px]">
              <Title level={4} className={!userID ? 'text-gray.500' : ''}>
                Favorites
              </Title>
              <Toggle
                size="large"
                checked={mobileFiltersParams.isFavorites}
                disabled={!userID}
                onChange={() =>
                  setMobileFiltersParams({
                    ...mobileFiltersParams,
                    isFavorites: !mobileFiltersParams.isFavorites,
                  })
                }
              />
            </div>
            <Button
              name="apply-filters"
              className="w-full mb-[16px]"
              onClick={handleMobileFiltersSubmit}
            >
              Apply
            </Button>
            <Button
              name="reset-filters"
              className="w-full"
              design="tertiary"
              onClick={handleClearFilters}
            >
              Clear
            </Button>
          </BottomSheet>
        </>
      )}
      {!isMobileMode && (
        <Styled.FiltersRow>
          <SignupTooltip enabled={!userID}>
            <Styled.DesktopFavoritesCheckbox
              isActive={strategiesFilters['filter.isFavorites']}
              isDisabled={!userID}
              onClick={() =>
                !!userID &&
                changeStrategiesFiltersField({
                  field: 'filter.isFavorites',
                  value: !strategiesFilters['filter.isFavorites'],
                })
              }
            >
              Favorite
            </Styled.DesktopFavoritesCheckbox>
          </SignupTooltip>
          <FilterSelect
            title={t('My copies')}
            options={copiesOptions}
            onChange={(value) =>
              changeStrategiesFiltersField({
                field: 'filter.copyingAccountIDs',
                value:
                  value === 'all' && accounts
                    ? accounts.map((account) => account.accountID)
                    : [value],
              })
            }
            isDisabled={!userID}
            contentWidth={232}
            notFoundTitle={t('No copies yet')}
            notFoundText={t(
              'Invest in a strategy to view and manage your copying accounts.',
            )}
          />
          <FilterSelect
            title={t('Platform')}
            options={platformOptions}
            onChange={(value) =>
              changeStrategiesFiltersField({
                field: 'filter.platform',
                value,
              })
            }
            contentWidth={136}
          />
          <FilterSelect
            title={t('Risk')}
            options={riskOptions}
            onChange={(value) =>
              changeStrategiesFiltersField({ field: 'filter.risk', value })
            }
            contentWidth={160}
          />
          <FilterSelect
            title={
              strategiesFilters['filter.commission.from'] ||
              strategiesFilters['filter.commission.to']
                ? `$${strategiesFilters['filter.commission.from'] || 1} – $${
                    strategiesFilters['filter.commission.to'] || '∞'
                  }`
                : t('Commission')
            }
            options={[]}
            onChange={() => {}}
            isContentVisible={isCommissionModalVisible}
            onVisibilityChange={(isVisible) => {
              setIsCommissionModalVisible(isVisible)

              if (isVisible) {
                setCommissionValue({
                  from: strategiesFilters['filter.commission.from'] || '',
                  to: strategiesFilters['filter.commission.to'] || '',
                })
              }
            }}
            customDropdownContent={
              <Styled.CommissionModal>
                <Styled.CloseButton
                  onClick={() => setIsCommissionModalVisible(false)}
                />
                <Styled.CommissionFields>
                  <Styled.CommissionInput
                    label="From"
                    placeholder="$1"
                    value={
                      commissionValue.from ? `$${commissionValue.from}` : ''
                    }
                    onChange={(event) => handleChangeCommission(event, 'from')}
                  />
                  <Styled.CommissionInput
                    label="To"
                    placeholder="No limit"
                    value={commissionValue.to ? `$${commissionValue.to}` : ''}
                    onChange={(event) => handleChangeCommission(event, 'to')}
                  />
                </Styled.CommissionFields>
                <Styled.CommissionFields>
                  <Button
                    name="clear-commission"
                    size="small"
                    design="tertiary"
                    onClick={() => setCommissionValue({ from: '', to: '' })}
                  >
                    Clear
                  </Button>
                  <Button
                    name="apply-commission"
                    size="small"
                    onClick={() => {
                      changeStrategiesFiltersField({
                        field: 'filter.commission.from',
                        value: commissionValue.from,
                      })
                      changeStrategiesFiltersField({
                        field: 'filter.commission.to',
                        value: commissionValue.to,
                      })

                      setIsCommissionModalVisible(false)
                    }}
                  >
                    Apply
                  </Button>
                </Styled.CommissionFields>
              </Styled.CommissionModal>
            }
            contentWidth={319}
          />
        </Styled.FiltersRow>
      )}

      <Styled.SearchInput
        suffix={<Icon2 name="magnifyingGlassIcon" size="small" />}
        placeholder="Search strategy"
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
      />
      {!!onChangeView && (
        <>
          <Styled.Button
            isActive={currentView === 'list'}
            onClick={() => onChangeView('list')}
          >
            {/* todo: заменить либо на Icon, либо на background-image */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                stroke="#262119"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>
          </Styled.Button>
          <Styled.Button
            isActive={currentView === 'cards'}
            onClick={() => onChangeView('cards')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                stroke="#262119"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
              />
            </svg>
          </Styled.Button>
        </>
      )}
    </Styled.Wrapper>
  )
}
