import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export const GET_COPYTRADE_COPYING_ACCOUNTS_FOR_FILTER_KEY = [
  'copying-copytrade-acounts-for-filter',
]

const getCopyingAccountsForFilter = async () => {
  const res = await axios.get<{
    items: { accountID: string; accountName: string }[]
  }>('/copytrade/v1/copying-accounts-for-filter')

  return res.data.items
}

export const useGetCopyingAccountsForFilter = (enabled: boolean) => {
  return useQuery({
    queryKey: GET_COPYTRADE_COPYING_ACCOUNTS_FOR_FILTER_KEY,
    queryFn: getCopyingAccountsForFilter,
    enabled,
  })
}
