import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { CardsList } from './CardsList';
import { WithdrawalCardItem } from 'services/payments/get/types';
import { useGetWithdrawalCards } from 'services/payments/get/useGetWithdrawalCards';


export const CardsWrapper = () => {
  const { trigger, formState } = useFormContext();
  const { data } = useGetWithdrawalCards();

  const { field: cardField } = useController({
    name: 'cardItem',
    rules: {
      required: 'Required item',
    }
  });

  const onChooseCard = (card: WithdrawalCardItem) => {
    if (formState.dirtyFields?.amount) {
      trigger('amount');
    }

    if (formState.dirtyFields?.withdrawalAmount) {
      trigger('withdrawalAmount');
    }

    cardField.onChange(card);
  }

  return (
    <>
      <CardsList
        title='Withdrawal to'
        onSelectCard={onChooseCard}
        dataList={data?.destinationInfo}
      />
    </>
  )
}
