import { useTranslation } from 'react-i18next'
import { Select } from 'ui/atoms/select_draft'
import { langs } from 'ui/constants'
import * as Flags from 'ui/molecules/language/images'

export const LanguageSelect = ({
  onChange,
  className,
  languages,
  defaultLanguage,
}: {
  onChange: (value: string) => void
  className?: string
  languages: string[]
  defaultLanguage: string
}) => {
  const [t] = useTranslation()

  return (
    <Select
      defaultValue={defaultLanguage}
      className={className}
      onChange={onChange}
      options={languages.map((language) => {
        const currentLang = langs.find(
          (systemLang) => language === systemLang.value,
        )

        return currentLang
          ? {
              value: language,
              label: (
                <div className="flex items-center gap-[8px]">
                  <img
                    className="w-[24px] h-auto"
                    // @ts-expect-error
                    src={Flags[currentLang.icon]}
                    alt={currentLang.value}
                  />
                  <span className="truncate">{t(currentLang.label)}</span>
                </div>
              ),
            }
          : { value: language, label: language }
      })}
    />
  )
}
