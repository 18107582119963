import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { Button, Icon, Icons } from '../../atoms'

export type DropdownItemProps = {
  label: string | React.ReactNode
  icon?: Icons
  onClick?: () => void
}

type Props = {
  name: string
  className?: string
  items: DropdownItemProps[]
  label: string | React.ReactNode
  icon?: Icons
  withCarret?: boolean
  withTranslate?: boolean
}

export const Dropdown = ({
  className,
  label,
  items,
  icon,
  name,
  withCarret,
  withTranslate = true
}: Props) => {
  const [t] = useTranslation()

  return (
    <DropdownContainer className={className}>
      <DropdownButton
        design="tertiary"
        name={`toggle-dropdown-${name}`}
        prefix={icon && <DropdownPrefix name={icon} size="small" />}
        suffix={withCarret && <DropdownSuffix name="angleDown" />}
      >
        {typeof label === 'string' ? t(label) : label}
      </DropdownButton>
      <DropdownSection>
        <DropdownList>
          {items.map(({ icon, label, onClick }, key) => (
            <DropdownItem key={key} onClick={onClick}>
              {icon && <DropdownItemIcon name={icon} size="small" />}
              <DropdownItemLabel>{withTranslate && typeof label === 'string' ? t(label) : label}</DropdownItemLabel>
            </DropdownItem>
          ))}
        </DropdownList>
      </DropdownSection>
    </DropdownContainer>
  )
}

const DropdownSuffix = styled(Icon)`
  width: 22px;
  height: 22px;
`

const DropdownPrefix = styled(Icon)``

const DropdownSection = styled.div`
  position: absolute;
  display: none;
  z-index: 100;
  top: 44px;
  inset-inline-end: 0;
  min-width: 152px;
  transition: var(--transition);
`

const DropdownContainer = styled.div`
  position: relative;

  &:hover {
    ${DropdownSection} {
      display: block;
    }

    ${DropdownSuffix} {
      transform: rotate(180deg);
    }
  }
`

const DropdownButton = styled(Button)`
  &[data-size='large'] {
    height: 44px;
    padding: 12px;
  }
`

const DropdownList = styled.div`
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  box-shadow: var(--box-shadow-6);
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 16px;
  background-color: var(--color-gray-100);
`

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: 9px 16px 9px 16px;
  transition: var(--transition);
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: var(--color-sunray-500);
  }
`

const DropdownItemIcon = styled(Icon)`
  margin-inline-end: 10px;
`

const DropdownItemLabel = styled.span``
