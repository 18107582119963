import styled from 'styled-components/macro'
import { Button } from 'ui/atoms'

export const Container = styled.div`
  width: calc(75% - 8px);
  height: 486px;
  position: relative;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const Image = styled.div<{ url: string }>`
  height: 128px;
  width: 128px;
  min-width: 32px;
  border-radius: 50%;
  /* background-color: rgba(0, 0, 0, 0.1); */
  background-image: url(${(p) => p.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const ChangePasswordFormContainer = styled.div`
  width: 520px;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const ChangePasswordButtonContainer = styled.div`
  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`

export const ChangePasswordButton = styled(Button)`
  @media (max-width: 800px) {
    width: 100%;
  }
`
