import styled from 'styled-components/macro'
import { Button as UiButton, Title as UiTitle } from 'ui/atoms'

import points from '../../images/points-existing.png'
import pointsMore from '../../images/points-new.png'

export const Wrapper = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;
`

export const Title = styled(UiTitle)`
  margin-bottom: 12px;
  text-align: center;

  @media (max-width: 599px) {
    line-height: 40px !important;
  }
`

export const Text = styled.div`
  padding: 0 40px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);

  @media (max-width: 599px) {
    padding: 0;
  }
`

export const JoinButton = styled(UiButton)`
  margin-top: 48px;
  min-width: 314px;

  @media (max-width: 599px) {
    margin-top: 40px;
    width: 100%;
  }
`

export const TermsLink = styled(UiButton)`
  margin-top: 16px;
`

export const PointsWrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 12px;
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 599px) {
    margin-top: 16px;
    margin-bottom: 32px;
    grid-template-columns: 1fr;
    gap: 16px;
  }
`

export const PointsColumn = styled.div`
  padding: 116px 16px 16px 16px;
  background-image: url(${points});
  background-size: 150px auto;
  background-repeat: no-repeat;
  background-position: top 16px center;

  @media (max-width: 599px) {
    padding: 98px 0 8px;
    background-position: top 8px center;
    background-size: auto 90px;
  }

  &:last-child {
    background-image: url(${pointsMore});
  }
`

export const PointsAmount = styled.div`
  margin-top: 2px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;

  @media (max-width: 599px) {
    margin-top: 5px;
  }
`
