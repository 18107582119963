import { useIsMobile } from 'libs/hooks/useIsMobile';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetBalanceOperations } from 'services/copytrade/get/useGetBalanceOperations';
import { SpinnerWithBlur } from 'ui/atoms';
import { Pagination } from 'ui/atoms/pagination/Pagination';
import { THead, TableWrapper } from 'ui/molecules';

import { OperationItem } from './OperationItem';

const PER_PAGE = 10;

export const BalanceOperation = () => {
  const { strategyID } = useParams();

  const [page, setPage] = useState(1);
  // const [perPage, setPerPage] = useState(20);

  const isMobile = useIsMobile();
  const { data, isRefetching } = useGetBalanceOperations({ strategyID: strategyID!, page, perPage: PER_PAGE });

  const onChangePage = (newPage: number) => {
    setPage(newPage);
  }

  if (!data) return null;

  return (
    <>
      <TableWrapper className="lg:w-full w-[90vw] relative">
        <SpinnerWithBlur size="xl" isShow={isRefetching} />
        <table className="w-full" border={1}>
          <THead
            trClasses=""
            textLevel={isMobile ? 4 : 3}
            heads={[
              { label: 'Type' },
              { label: 'Closing date' },
              { label: 'Amount', align: 'right' },
            ]}
          />
          <tbody>
            {data.items?.map((item, index) => (
              <OperationItem
                index={index}
                item={item}
                key={`${item.closingAt}-${index}`}
                isNoLastElem={index !== data.items.length - 1}
              />
            ))}
          </tbody>
        </table>
      </TableWrapper>

      {data && Number(data?.totalRecords) > PER_PAGE && (
        <Pagination
          pageSize={PER_PAGE}
          current={page}
          total={Number(data.totalRecords)}
          onChange={onChangePage}
          classNames="mt-[2.4rem]"
        />
      )}
    </>
  )
}
