import * as React from 'react'
import styled from 'styled-components/macro'

import { Icon2 } from '../icon2'

type SpinnerProps = {
  isLoading: boolean
  isDisabled?: boolean
  color?: 'white' | 'black'
}

const design = {
  white: 'spinnerWhite',
  black: 'spinnerWhite',
} as const

export const Spinner = ({
  isLoading,
  isDisabled,
  color = 'white',
}: SpinnerProps) => {
  return (
    <Container name={design[color]} disabled={isDisabled} visible={isLoading} />
  )
}

const Container = styled(Icon2)`
  width: 26px;
  height: 26px;
  animation: spin 0.3s linear infinite;
  animation-delay: 0.1s;
  position: absolute;
  transition: var(--transition);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
`
