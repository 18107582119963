type Lang = {
  label: string
  value: string
  icon: string
}

export const langs: Lang[] = [
  {
    label: 'English',
    value: 'en',
    icon: 'englishFlag',
  },
  {
    label: 'Indonesian',
    value: 'id',
    icon: 'indonesianFlag',
  },
  {
    label: 'Arabic',
    value: 'ar',
    icon: 'arabicFlag',
  },
  {
    label: 'Thai',
    value: 'th',
    icon: 'thaiFlag',
  },
  {
    label: 'Portuguese',
    value: 'pt',
    icon: 'portugueseFlag',
  },
  {
    label: 'Malay',
    value: 'ms',
    icon: 'malayFlag',
  },
  {
    label: 'Spanish',
    value: 'es',
    icon: 'spanishFlag',
  },
  {
    label: 'Vietnamese',
    value: 'vi',
    icon: 'vietnameseFlag',
  },
  {
    label: 'Japanese',
    value: 'ja',
    icon: 'japaneseFlag',
  },
  {
    label: 'French',
    value: 'fr',
    icon: 'frenchFlag',
  },
]
