import styled from 'styled-components/macro'
import { Title as UiTitle } from 'ui/atoms'

import randomBackgroundMobile from '../../images/random-background-mobile.jpg'
import randomBackground from '../../images/random-background.jpg'
import top10BackgroundMobile from '../../images/top10-background-mobile.jpg'
import top10Background from '../../images/top10-background.jpg'
import top5BackgroundMobile from '../../images/top5-background-mobile.jpg'
import top5Background from '../../images/top5-background.jpg'

export const Wrapper = styled.div`
  margin-bottom: 48px;

  @media (max-width: 599px) {
    margin-bottom: 40px;
  }
`

export const Title = styled(UiTitle)`
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: 599px) {
    margin-bottom: 20px;
    line-height: 40px !important;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 440px 1fr;
  gap: 20px 10px;

  @media (max-width: 860px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`

export const Item = styled.div`
  padding: 24px;
  border-radius: 8px;
  background-color: var(--color-sunray-100);
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 480px) {
    min-height: 250px;
    background-size: cover;
  }
`

export const Top5Winners = styled(Item)`
  grid-row: span 2;
  min-height: 440px;
  background-image: url(${top5Background});
  background-position: bottom left;
  background-color: #fee08f;

  @media (max-width: 860px) {
    min-height: 520px;
    background-size: cover;
  }

  @media (max-width: 720px) {
    min-height: 480px;
  }

  @media (max-width: 615px) {
    min-height: 440px;
  }

  @media (max-width: 480px) {
    min-height: 556px;
    background-image: url(${top5BackgroundMobile});
  }

  @media (max-width: 425px) {
    min-height: 510px;
  }

  @media (max-width: 390px) {
    min-height: 480px;
  }
`

export const Top10Winners = styled(Item)`
  display: grid;
  align-content: start;
  grid-template-columns: 0.6fr;
  background-image: url(${top10Background});
  background-position: bottom right;
  background-color: #ffecb8;

  @media (max-width: 480px) {
    background-image: url(${top10BackgroundMobile});
  }
`

export const RandomWinners = styled(Item)`
  background-image: url(${randomBackground});
  background-position: bottom right;

  @media (max-width: 480px) {
    background-image: url(${randomBackgroundMobile});
  }
`

export const ItemTitle = styled(UiTitle)`
  margin-bottom: 16px;

  @media (max-width: 599px) {
    margin-bottom: 12px;
  }
`

export const PrizeTitle = styled.div`
  margin-bottom: 4px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;
`

export const PrizeValue = styled.div`
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
`

export const PrizeInfo = styled.div`
  margin-top: 8px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;
`
