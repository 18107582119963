import { accountsService } from './accounts'
import {
  ChangePasswordProps,
  CheckResetPasswordCodeProps,
  ResetPasswordProps,
  authService,
} from './auth'
import {
  Account,
  InternalTransferProps,
  Wallet,
  internalTransfersService,
} from './internal-transfers'
import { transactionHistoryService } from './transaction-history'

export const service = {
  auth: authService,
  internalTransfers: internalTransfersService,
  accounts: accountsService,
  transactionHistory: transactionHistoryService,
}

export type {
  ResetPasswordProps,
  CheckResetPasswordCodeProps,
  ChangePasswordProps,
  Wallet,
  Account,
  InternalTransferProps,
}
