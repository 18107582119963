import { useTranslation } from 'react-i18next'
import { Hint } from 'ui/atoms'

import { GeneratePassword } from '../generatePassword'
import { DetailsBlock, DetailsCopy, DetailsRow, DetailsValue } from './page'

export const MetaTraderSettings = ({
  login,
  mtServerInfo,
  password,
  accountID,
  className
}: {
  login: string
  mtServerInfo: {
    name: string
    address: string
  }
  password: string
  accountID: string
  className?: string
}) => {
  const [t] = useTranslation()

  return (
    <DetailsBlock scheme="ghost" className={className}>
      <DetailsRow>
        <Hint
          textContent={t(
            'A login for your trading account on the MetaTrader platform.',
          )}
        >
          {t('Login')}
        </Hint>
        <DetailsValue>
          {login} <DetailsCopy iconSize="small" copiedText={login} />
        </DetailsValue>
      </DetailsRow>
      <DetailsRow>
        <Hint textContent={t('The name of Headway’s server in MetaTrader.')}>
          {t('Server')}
        </Hint>
        <DetailsValue>
          {mtServerInfo.name}
          <DetailsCopy iconSize="small" copiedText={mtServerInfo.name} />
        </DetailsValue>
      </DetailsRow>
      <DetailsRow>
        <Hint
          textContent={t(
            'The IP address of the Headway’s server in MetaTrader.',
          )}
        >
          {t('Server ip')}
        </Hint>
        <DetailsValue>
          {mtServerInfo.address}
          <DetailsCopy iconSize="small" copiedText={mtServerInfo.address} />
        </DetailsValue>
      </DetailsRow>
      <DetailsRow>
        <Hint
          textContent={t(
            'A password for your trading account on the MetaTrader platform.',
          )}
        >
          {t('Password')}
        </Hint>
        <DetailsValue>
          <GeneratePassword password={password} accountID={accountID} />
        </DetailsValue>
      </DetailsRow>
    </DetailsBlock>
  )
}
