import { useStore } from 'effector-react'
import * as React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  $isNavigateExist,
  $location,
  $navigate,
  $params,
  mounted,
  toPath,
} from './model'

type Props = {
  children?: React.ReactNode
}

export const RouterHistory = ({ children }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const isExist = useStore($isNavigateExist)

  React.useEffect(() => {
    mounted({ navigate, location, params })
  }, [navigate, location, params])

  if (isExist) return <>{children}</>

  return null
}

export { toPath, $location, $params, $navigate }
