import { AcceptTypes } from 'pages/dashboard/verification/atoms/upload/component'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Title, Text } from 'ui/atoms';

import * as S from './styled';
import { DefaultUploadPlace, Uploader } from './upload/component';
import { ServerFile } from './upload/types';

const availibleFormat: AcceptTypes[] = ['jpg', 'jpeg', 'png', 'pdf', 'gif'];

interface Props {
  format?: AcceptTypes[];
  title?:  React.ReactNode;
  subTitle?: React.ReactNode;
  maxSize?: string;
  uploadUrl: string;
  limit?: number;
  isHideAddButton?: boolean;
  onChangeFiles: (files: ServerFile) => void;
  onDeleteFile: (index: number) => void;
  files?: ServerFile[];
  withBorder?: boolean;
  defaultUploadPlace?: DefaultUploadPlace[];
}

export const UploadFiles: React.FC<Props> = (props) => {
  const {
    files = [], onDeleteFile, onChangeFiles, withBorder,
    format = availibleFormat,  maxSize = '1', defaultUploadPlace = [],
    title, subTitle, uploadUrl, limit = 2, isHideAddButton,
  } = props;

  const [t] = useTranslation();

  return (
    <div className='flex flex-col gap-[12px]'>
      {typeof title === 'string' 
        ? (<Title level={3}>
           {t(title)}
          </Title>
        ) 
        : title
      }
      <Text level={1} withTranslate={ false }>
        {typeof subTitle === 'string' ? t(subTitle) : subTitle}
      </Text>
      <Uploader
        name='uploadFiles'
        accept={format}
        limit={limit}
        onChangeFiles={ onChangeFiles }
        onDeleteFile={onDeleteFile}
        defaultUploadPlace={defaultUploadPlace}
        files={files}
        uploadUrl={ uploadUrl }
        disabled={false}
        withBorder={ withBorder }
        isHideAddButton={ isHideAddButton }
      />
      <S.UploadsFormat>
        {t('Supported formats:')}{' '}
        {availibleFormat.map((f) => f.toLocaleUpperCase()).join(', ')}.&nbsp;
        {t('Max file size: 1 MB.', { number: maxSize})}
      </S.UploadsFormat>
      {/* <S.UploadError>{23}</S.UploadError> */}
    </div>
  )
}
