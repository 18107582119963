import styled from 'styled-components/macro'
import { Button, Input } from 'ui/atoms'

export const Filters = styled.div`
  position: relative;
  margin-bottom: 24px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TabsWrapper = styled.div`
  position: relative;
  width: 50%;

  @media (max-width: 880px) {
    width: calc(100% - 44px);
  }

  @media (max-width: 540px) {
    width: 300px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    inset-inline-end: -1px;
    width: 30px;
    height: 100%;
    background-image: linear-gradient(
      270deg,
      #ffffff 30.65%,
      rgba(255, 255, 255, 0) 79.03%
    );
  }
`

export const Tabs = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Tab = styled.div<{ active: boolean }>`
  cursor: pointer;
  pointer-events: ${(props) => (props.active ? 'none' : 'all')};
  padding: 0 12px;
  line-height: 3.6rem;
  font-weight: 500;
  white-space: nowrap;
  transition: background-color var(--transition);
  background-color: ${(props) =>
    props.active ? 'var(--color-gray-400)' : 'var(--color-gray-300)'};
  border-radius: 8px;

  &:hover {
    background-color: var(--color-gray-400);
  }

  &:last-child {
    margin-inline-end: 20px;

    @media (max-width: 400px) {
      margin-inline-end: 44px;
    }
  }
`

export const SearchWrapper = styled.div<{ searchVisible: boolean }>`
  display: flex;

  @media (max-width: 880px) {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    width: ${(props) => (props.searchVisible ? '100%' : 'auto')};
  }
`

export const SearchButton = styled(Button)`
  flex-shrink: 0;

  @media (max-width: 880px) {
    margin-inline-start: auto;
  }

  &[data-size='medium'] {
    padding: 0;
    width: 44px;
    height: 40px;
  }
`

export const SearchInput = styled(Input)`
  input {
    padding: 12px;
    width: 340px;
    height: 40px;
    min-height: auto;

    @media (max-width: 880px) {
      width: 100%;
    }
  }
`

export const Wrapper = styled.div<{ disabled: boolean }>`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  @media (max-width: 880px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  &::before {
    content: ${(props) => (props.disabled ? '' : 'none')};
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
`

export const GatewayContainer = styled.div`
  cursor: pointer;
  border: 1px solid var(--color-gray-300);
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  @media (max-width: 650px) {
    padding: 12px;
  }
`

export const GatewayHeader = styled.div<{ logo: string }>`
  position: relative;
  margin-bottom: auto;
  padding-inline-start: ${(props) => (props.logo ? '56px' : '0')};
  min-height: 44px;
  display: flex;
  align-items: center;
  font-weight: 500;

  @media (max-width: 650px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    inset-inline-start: 0;
    width: 44px;
    height: 44px;
    transform: translateY(-50%);
    border-radius: 50%;
    background-image: url(${(props) => props.logo});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
`

export const Verification = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  font-weight: 400;
  color: var(--color-gray-600);
`

export const Limits = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);
`

