import { PartnerLevelType } from "services/ib/get/types";

export const levelLabelsObj: Record<PartnerLevelType, string> = {
  'PAPL_FIRST': 'I',
  'PAPL_SECOND': 'II',
  'PAPL_THIRD': 'III',
  'PAPL_INVALID': ''
};

export const levelOptions = [
  { label: 'All Levels', value: 'PAPL_INVALID' },
  { label: levelLabelsObj.PAPL_SECOND, value: 'PAPL_SECOND' },
  { label: levelLabelsObj.PAPL_THIRD, value: 'PAPL_THIRD' },
];