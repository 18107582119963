import React from 'react';

import { HaventIbBanner, Steps, Progress, ThreeLevels } from './molecules/promo';


export const HaventIbPartner = () => {
  return (
    <>
      <HaventIbBanner />
      <Steps />
      <Progress />
      <ThreeLevels />
    </>
  )
}
