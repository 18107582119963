import styled, { css } from 'styled-components/macro'

export const Item = styled.button<{ isActive: boolean }>`
  padding: 6px;
  min-width: 42px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  font-weight: 500;
  text-align: center;
  transition-property: background-color, color;
  transition-duration: var(--transition);
  border-radius: 4px;

  &:hover {
    background-color: var(--color-gray-300);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      pointer-events: none;
      background-color: var(--color-sunray-500);
    `}
`
