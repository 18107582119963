import * as React from 'react'
import styled from 'styled-components/macro'

const todayYear = new Date().getFullYear()

export const Footer: React.FC = () => (
  <FooterContainer>Headway © 2012 – {todayYear}</FooterContainer>
)

const FooterContainer = styled.footer``
