import { useQuery } from '@tanstack/react-query'
import { Account } from 'services/accounts/types'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'
import { Wallet } from 'services/wallet/get/types'

export const GET_BONUS_DESTINATIONS_KEY = ['bonus', 'destinations']

const getBonusDestinations = async () => {

    const res = await axios.get<{ wallets: Wallet[]; accounts: Account[] }>(
      '/bonus111/v1/transfer-destinations',
    )

    return res.data
}

export const useGetBonusDestinations = () => {
  return useQuery({
    queryKey: GET_BONUS_DESTINATIONS_KEY,
    queryFn: getBonusDestinations,
    retry: retryFn,
    staleTime: 10000,
  })
}
