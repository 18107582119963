import { MoneyHistoryFilterStore, Statuses } from "pages/money/transactionsNew/model";

import { ReqData } from "./useGetMoneyHistory";

const allStatuses = [
  'OS_PENDING',
  'OS_MISSING_INFORMATION',
  'OS_SUCCESS',
  'OS_REJECTED',
] as Statuses[];

export const mapFilterToReqData = (filter: MoneyHistoryFilterStore) => {
  const reqData: ReqData = {
    timeline: filter.timeline,
    walletIDs: filter.walletIDs,
    accountIDs: filter.accountIDs,
    exchangerWalletIDs: filter.exchangerWalletIDs,

    includeInternalTransfers: filter.transactionType === 'all' || filter.transactionType === 'internal_transfer',
    includeDeposits: filter.transactionType === 'all' || filter.transactionType === 'deposit',
    includeWithdrawals: filter.transactionType === 'all' || filter.transactionType === 'withdrawal',
  };

  if (filter.statuses) {
    reqData.statuses = filter.statuses === 'all' ? allStatuses : [filter.statuses]
  }

  return reqData;
}