import React from 'react'
import styled from 'styled-components/macro';

import { Icon } from '../icon';

interface Props {
  className?: string;
  isShow?: boolean;
  size?: 'medium' | 'large' | 'xl';
}

export const SpinnerWithBlur: React.FC<Props> = ({ className, size = 'medium', isShow }) => {
  if (!isShow) return null;

  return (
    <div className='w-full h-full absolute flex items-center justify-center'>
      <div className='absolute bg-white h-full w-full opacity-50 z-10'/>
      <Spinner
        name="spinner"
        data-visible={true}
        // @ts-ignore
        size={ size }
        className={ `${className} z-20` }
      /> 
    </div>
  )
}


const Spinner = styled(Icon)`
  &[data-size='xl'] {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
  margin-inline-start: 1rem;
  animation: spin 0.5s linear infinite;
  animation-delay: 0.1s;
  transition: var(--transition);
  opacity: 1;
`