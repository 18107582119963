import styled from 'styled-components/macro';

import { Input } from 'ui/atoms';


export const SearchInput = styled(Input)`
  width: 42rem;

  @media (max-width: 880px) {
    width: 100%;
  }
`;