import React from 'react';
import { useStore } from 'effector-react';

import { GetClientItem, GetClientsList } from 'services/ib/get/types';
import { getDateFromUnix } from 'libs/dateUtils';
import { $tableSortStore, changeSortEvent } from 'pages/ib/models/tableSortStore';

import { mapAccountPlatform } from 'ui/molecules/accountLines';
import { Text } from 'ui/atoms';
import { THead, Td, TdHead, TableWrapper } from 'ui/molecules';
import { EditCashback } from './EditCashback';


interface Props {
  items?: GetClientItem[];
  totals?: GetClientsList['totalsForClients'];
}

const subHead = [
  { title: 'Acount' }, 
  { title: 'Platform' }, 
  { title: 'Joining date', name: 'FIELD_BINDING_DATE', sortable: true }, 
  { title: 'Lots', name: 'FIELD_SUM_LOTS', sortable: true }, 
  { title: 'Comission', name: 'FIELD_SUM_COMMISSION', sortable: true }, 
  { title: 'Cashback paid', name: 'FIELD_SUM_CASHBACK', sortable: true }, 
  { title: 'Cashback' },
];

export const ClientsTable: React.FC<Props> = ({ items = [], totals }) => {
  const sort = useStore($tableSortStore);

  
  if (!items && !totals) return null;

  return (
    <TableWrapper className='w-full'>
      <table className='w-full' border={1}>
        { totals && 
          <THead
            trClasses='border-b-[0.75px] border-gray.300 border-solid'
            heads={[
              { label: totals.numClients },
              { label: '' },
              { label: '' },
              { label: totals.sumLots },
              { label: totals.sumCommission
                ? `$${totals.sumCommission}`
                : ''
              },
              { label: totals.sumCashback
                ? `$${totals.sumCashback}`
                : ''
                },
              { label: '' },
            ]}
          />
        }
        <tbody>
          <tr className='border-b-[0.75px] border-gray.300 border-solid'>
            {subHead.map((sub, index) => (
              <TdHead
                key={index}
                sortable={ sub.sortable }
                name={sub.name}
                isEmptySort={ !sort }
                sortDirection={ sort?.name === sub.name ? sort?.direction : 'asc'}
                isSortActive={ sort?.name === sub.name }
                onChangeSort={changeSortEvent}
              >
                <Text className={'!font-medium'} level={2}>{sub.title}</Text>
              </TdHead>))
            }
          </tr>
          {items?.map(item => (
            <tr key={item.accountID}>
              <Td>
                <Text level={2}>{item.login}</Text>
              </Td>
              <Td>
                <Text level={2}>{mapAccountPlatform[item?.accountPlatform]}</Text>
              </Td>
              <Td>
                <Text level={2}>{getDateFromUnix(+item?.bindingDate, 'dd.MM.yyyy')}</Text>
              </Td>
              <Td>
                <Text level={2}>{item.sumLots}</Text>
              </Td>
              <Td>
                <Text level={2}>{item.sumCommission && `$${item.sumCommission}`}</Text>
              </Td>
              <Td>
                <Text level={2}>{item.sumCashback && `$${item.sumCashback}`}</Text>
              </Td>
              <Td className='flex !h-[4.1rem]'>
                <EditCashback
                  cashbackPercentage={item.cashbackPercentage}
                  accountId={item.accountID}
                  isDisabled={ item.isDetached }
                />
              </Td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableWrapper>
  )
}
