import { Effect, createDomain, createEvent, createStore } from 'effector'

import { Request, Response, ResponseError } from '../../libs/request'

export const genericDomain = createDomain('genericDomain')

// todo refactor rename

export const clearQueue = createEvent('')

export const $queue = createStore<
  {
    fx: Effect<unknown, unknown, unknown>
    params: Request<object>
  }[]
>([]).reset(clearQueue)

// export const $queue = createStore([]).reset(clearQueue)

// catch not authed requests

genericDomain.onCreateEffect((fx: Effect<unknown, unknown, unknown>) => {
  $queue.on(fx.fail, (queue, body) => {
    const { error, params } = body as {
      params: Request<object>
      error: Response<ResponseError<object>>
    }

    if (error.body?.code === 16) {
      return queue.concat([{ fx, params }])
    }
  })
})
