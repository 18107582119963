import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'

const createBonusAccount = async () => {
  const accountData = await axios.post('/bonus111/v1/open-account', {})

  return accountData.data
}

export const useCreateBonusAccount = () => {
  return useMutation({
    mutationFn: createBonusAccount,
    retry: retryFn,
  })
}
