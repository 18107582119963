import React, { useState } from 'react';
import { useController, useForm } from 'react-hook-form';

import { validateNumberMax } from 'libs/validators';
import { useWithdrawalPartner } from 'services/ib/mutation';
import successSrc from 'styles/img/success.png';
import successSrcWeb from 'styles/img/success.webp';

import { Title, Text } from 'ui/atoms';
import { FormInput, ModalDialog } from 'ui/molecules';

interface Props {
  maxAmount: number;
  isOpen: boolean;
  onClose: () => void;
}

type FormModelType = {
  amount: string;
}

export const WithdrawalModal: React.FC<Props> = ({ isOpen, onClose, maxAmount }) => {  
  const { mutateAsync, isLoading, isError } = useWithdrawalPartner();

  const [isSuccess, setIsSuccess] = useState(false);

  const { handleSubmit, control, reset, setError } = useForm<FormModelType>({
    defaultValues: {
      amount: '',
    },
    mode: 'onTouched',
  });

  const { field: amountField, fieldState: amountFieldState } = useController({
    name: 'amount',
    defaultValue: '',
    rules: {
      required: 'Required field',
      validate: (val) => validateNumberMax(val, maxAmount) || 'Limit exceeded. Please enter the maximum amount.',
    },
    control
  });

  const onCloseModal = () => {
    if (isLoading) return;
    reset();
    onClose();
  }

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace(/[^.\d]/g, '');
    amountField.onChange(val);
  }

  const setMaxAmount = () => {
    amountField.onChange(maxAmount.toString());
  }

  const onSbm = async (fields: FormModelType) => {
    try {
      await mutateAsync(fields.amount);
      setIsSuccess(true);
    } catch (error) {
      setError('amount', {
        message: 'Tech error. Try again later',
        type: 'techError'
      })
    }
  }

  const onOkayFn = () => {
    onCloseModal();
    setIsSuccess(false);
  }

  if (isSuccess) {
    return (
      <ModalDialog
        isOpen={isOpen}
        onOk={onOkayFn}
        onCancel={onOkayFn}
        title=""
        okLabel="Okay"
        loading={ isLoading }
        disableCancelBtn={ isLoading } 
        disableOkBtn={ isLoading }
        shouldCloseOnOverlayClick
        style={{
          overlay: {
            zIndex: 20,
          }
        }}
      >
          <picture>
            <source srcSet={successSrcWeb} className='w-[8rem m-auto' />
            <img src={successSrc} alt="question" className='w-[8rem] m-auto'/>
          </picture>
          <Title className='text-center w-full mt-[20px]' level={3}>Transfer complete</Title>
          <Text className='mt-[16px] text-center lg:w-auto min-w-[300px]'>
            You have successfully transferred money to your USD wallet.
          </Text>

      </ModalDialog>
    )
  }
  
  return (
    <>
      <ModalDialog
        isOpen={isOpen}
        onOk={handleSubmit(onSbm)}
        onCancel={onCloseModal}
        title="Withdraw to USD wallet"
        okLabel="Withdraw"
        cancelLabel='Cancel'
        loading={ isLoading }
        disableCancelBtn={ isLoading } 
        disableOkBtn={ isLoading }
        shouldCloseOnOverlayClick
        style={{
          overlay: {
            zIndex: 20,
          }
        }}
      >
        <FormInput
          className='!max-w-[46rem] mt-[2rem] text-[16px]'
          value={amountField.value}
          label={'Amount'}
          disabled={ isLoading }
          autoComplete='off'
          onChange={onChangeAmount}
          suffixType='button'
          suffix={
            <span
              className='text-sunray.500 cursor-pointer'
              onClick={setMaxAmount}
            >
              Max
            </span>
          }
          ref={amountField.ref}
          errorMessage={ amountFieldState.error?.message }
        />
      </ModalDialog>
    </>
  )
}
