import { Text } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'

export const CloseOrdersModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <ModalDialog isOpen={isOpen} onOk={onClose} title="Close open orders">
      <Text>
        You can’t unpublish a strategy if it has open positions. Close all
        orders before unpublishing the strategy.
      </Text>
    </ModalDialog>
  )
}
