import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
  display: flex;
  gap: 40px;

  @media (max-width: 650px) {
    margin-top: 16px;
    margin-bottom: 24px;
    flex-direction: column;
    gap: 8px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--color-gray-600);
  font-weight: 500;
`