import { ResponseError } from '../libs/request'

export type ClientError<T> = {
  msg: string
  form: string | null
  fields: Record<keyof T, string> | null
}

export function mapResponseError<T>(
  responseError: ResponseError<T>,
): ClientError<T> {
  const ignoreErrors = ['validation error']
  const responseErrors = responseError?.details?.validationErrors?.items

  let formError = null
  // @ts-ignore
  let fieldsErrors: Record<keyof T, string> = {}

  if (!ignoreErrors.includes(responseError?.msg)) {
    formError = responseError?.details?.msg || responseError?.msg
  }

  if (responseErrors && responseErrors.length) {
    for (const errorItem of responseErrors) {
      fieldsErrors[errorItem.field] = errorItem.msg
    }
  }

  return {
    msg: responseError?.msg || '',
    form: formError,
    fields: fieldsErrors,
  }
}
