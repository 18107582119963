import { Button, Title } from 'ui/atoms'
import { useWindowSize } from 'usehooks-ts'

import * as Styled from './styled'

export const getMetaTraderLink = (
  version: 'mt4' | 'mt5' | 'mt4-web' | 'mt5-webDemo' | 'mt5-webReal',
): string | undefined => {
  switch (version) {
    case 'mt4': {
      switch (true) {
        case navigator.userAgent.indexOf('Android') > -1:
          return 'https://download.mql5.com/cdn/mobile/mt4/android?server=Headway-Demo,Headway-Real'
        case navigator.userAgent.indexOf('iPhone') > -1:
          return 'https://download.mql5.com/cdn/mobile/mt4/ios?server=Headway-Demo,Headway-Real'
        case navigator.userAgent.indexOf('Windows') > -1:
          return 'https://download.mql5.com/cdn/web/jarocel.pty.ltd/mt4/headway4setup.exe'
        case navigator.userAgent.indexOf('Mac') > -1:
          return 'https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.pkg.zip'
        default:
          return undefined
      }
    }
    case 'mt5': {
      switch (true) {
        case navigator.userAgent.indexOf('Android') > -1:
          return 'https://download.mql5.com/cdn/mobile/mt5/android?server=Headway-Server'
        case navigator.userAgent.indexOf('iPhone') > -1:
          return 'https://download.mql5.com/cdn/mobile/mt5/ios?server=Headway-Server'
        case navigator.userAgent.indexOf('Windows') > -1:
          return 'https://download.mql5.com/cdn/web/jarocel.pty.ltd/mt5/headway5setup.exe'
        case navigator.userAgent.indexOf('Mac') > -1:
          return 'https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.pkg.zip'
        default:
          return undefined
      }
    }
    case 'mt4-web':
      return 'https://metatraderweb.app/trade?startup_mode=login&save_password=on&colorScheme=black_on_white&servers=Headway-Demo,Headway-Real'
    case 'mt5-webDemo':
      return `https://demomt5.${window.location.hostname}/terminal?mode=connect&utm_campaign=webterminal&utm_source=PA`
    case 'mt5-webReal':
      return `https://webtrading.${window.location.hostname}/terminal?mode=connect&utm_campaign=webterminal&utm_source=PA`
    default:
      return undefined
  }
}

export const MetaTraders = ({ aside }: { aside?: boolean }) => {
  const { width } = useWindowSize()

  return (
    <Styled.Wrapper isSmall={!!aside}>
      <Styled.TitleWrapper>
        <Title level={3}>MetaTraders</Title>
      </Styled.TitleWrapper>
      <div>
        {getMetaTraderLink('mt4') ? (
          <Styled.AppWrapper type="mt4">
            <Styled.AppTitle>MetaTrader 4</Styled.AppTitle>
            <Styled.DownloadButton
              name="mt-4"
              size="small"
              design="secondary"
              href={getMetaTraderLink('mt4')}
            >
              Download
            </Styled.DownloadButton>
          </Styled.AppWrapper>
        ) : null}
        {getMetaTraderLink('mt5') ? (
          <Styled.AppWrapper type="mt5">
            <Styled.AppTitle>MetaTrader 5</Styled.AppTitle>
            <Styled.DownloadButton
              name="mt-5"
              size="small"
              design="secondary"
              href={getMetaTraderLink('mt5')}
            >
              Download
            </Styled.DownloadButton>
          </Styled.AppWrapper>
        ) : null}
        {width >= 1024 && (
          <Styled.AppWrapper type="mt4">
            <Styled.AppTitle>MetaTrader 4 Web</Styled.AppTitle>
            <Styled.DownloadButton
              name="mt-4-web"
              size="small"
              design="secondary"
              href={getMetaTraderLink('mt4-web')}
              target="_blank"
            >
              Open
            </Styled.DownloadButton>
          </Styled.AppWrapper>
        )}
        <Styled.AppWrapper type="mt5">
          <Styled.AppTitle>MetaTrader 5 Web</Styled.AppTitle>
          <Styled.ButtonsRow>
            <Button
              name="mt-5-web"
              size="small"
              design="secondary"
              href={getMetaTraderLink('mt5-webDemo')}
              target="_blank"
            >
              Demo
            </Button>
            <Button
              name="mt-5-web"
              size="small"
              design="secondary"
              href={getMetaTraderLink('mt5-webReal')}
              target="_blank"
            >
              Real
            </Button>
          </Styled.ButtonsRow>
        </Styled.AppWrapper>
      </div>
    </Styled.Wrapper>
  )
}
