import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { retryFn } from 'services/helpers/retryFn';
import { axios } from 'services/axios';

import { UserSocials } from './type';


export const GET_USER_SOCIALS_KEY = ['user', 'socials'];

const getUserSocials = async () => {
  try {
    const profileData = await axios.get<UserSocials>('/auth/v1/user-socials');
  
    return profileData.data;
  } catch (error) {
    
  }
}


export const useGetUserSocials = () => {
  return useQuery({
    queryKey: GET_USER_SOCIALS_KEY,
    queryFn: getUserSocials,
    retry: retryFn,
    // TODO пока что удаляем эпл из списка
    select: (data) => {
      return {
        socials: data?.socials.filter(social => social.name !== 'apple') || [],
      }
    },
    staleTime: 10000,
  })
}