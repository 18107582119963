import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Tab = styled.div<{ active: boolean }>`
  cursor: pointer;
  pointer-events: ${(props) => (props.active ? 'none' : 'all')};
  padding: 0 12px;
  line-height: 3.6rem;
  font-weight: 500;
  white-space: nowrap;
  transition: background-color var(--transition);
  background-color: ${(props) =>
    props.active ? 'var(--color-gray-400)' : 'var(--color-gray-300)'};
  border-radius: 8px;

  &:hover {
    background-color: var(--color-gray-400);
  }

  &:last-child {
    margin-inline-end: 20px;

    @media (max-width: 400px) {
      margin-inline-end: 44px;
    }
  }
`