import styled, { css } from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Button = styled.button<{
  isDisabled?: boolean
  isActive: boolean
  activeStrokeColor?: string
}>`
  svg rect {
    transition: fill var(--transition);
  }

  svg path[fill] {
    opacity: 0;
  }

  svg path[stroke] {
    stroke: var(--color-neutral);
  }

  ${({ isActive, activeStrokeColor, isDisabled }) => {
    if (isActive && !activeStrokeColor) {
      return css`
        svg path[fill] {
          opacity: 1;
        }

        svg path[stroke] {
          opacity: 0;
        }
      `
    }

    if (isActive && activeStrokeColor) {
      return css`
        svg path[stroke] {
          stroke: ${activeStrokeColor};
        }
      `
    }

    if (!isActive && !isDisabled) {
      return css`
        &:hover svg rect {
          fill: var(--color-gray-300);
        }
      `
    }

    if (isDisabled) {
      return css`
        cursor: not-allowed;

        svg path[stroke] {
          stroke: var(--color-gray-500);
        }
      `
    }
  }}
`

export const ExtendedButton = styled.button<{
  isDisabled?: boolean
  isActive?: boolean
  activeColor?: string
}>`
  padding-top: 4px;
  padding-inline-start: 8px;
  padding-inline-end: 10px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  font-weight: 500;
  transition: background-color var(--transition);
  background-color: var(--color-gray-200);
  border-radius: 20px;

  &:hover {
    background-color: var(--color-gray-300);
  }

  svg path[fill] {
    opacity: 0;
  }

  svg path[stroke] {
    stroke: var(--color-neutral);
  }

  ${({ isActive, activeColor }) =>
    isActive &&
    css`
      color: var(--color-gray-100);
      background-color: ${activeColor};

      &:hover {
        background-color: ${activeColor};
      }

      svg path[fill] {
        opacity: 1;
      }

      svg path[stroke] {
        opacity: 0;
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      color: var(--color-gray-500);
      background-color: var(--color-gray-300);

      svg path[stroke] {
        stroke: var(--color-gray-500);
      }
    `}
`
