import styled from "styled-components/macro";

import { Icon2 } from "ui/atoms";
import { Dialog } from "ui/molecules";

export const EditIcon = styled(Icon2)`
  color: var(--color-gray-500);
  cursor: pointer;
`
export const DialogContainer = styled(Dialog)`
  &:hover {
    ${EditIcon} {
      opacity: 1;
    }
  }
`

export const Block = styled.div`
  margin-bottom: 32px;
`

export const Content = styled.div`
  margin-top: 24px;
`
export const Tip = styled.div`
  margin-top: 2rem;
  color: var(--color-gray-600);
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  font-weight: 400;
`

export const EditIcon1 = styled(Icon2)`
  opacity: 0;

  &[data-size='medium'] {
    min-width: 15px;
    width: 15px;
    height: 18px;
    margin-inline-start: 6px;
  }
`