import { useEffect, useState } from 'react'
import { useGetLandings } from 'services/ib/get/useGetLandings'
import { useGetLandingsFilters } from 'services/ib/get/useGetLandingsFilters'
import { Button, Icon2 } from 'ui/atoms'
import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon'

import { Filters } from '../../styled'
import { LanguageSelect } from '../filters'
import * as Styled from './styled'

export const LandingPages = () => {
  const [activeLanguage, setActiveLanguage] = useState('')

  const { data: languages } = useGetLandingsFilters()
  const { data, refetch } = useGetLandings(activeLanguage)

  useEffect(() => {
    if (languages) {
      setActiveLanguage(
        languages.find((language) => language.isSelected)?.value || '',
      )
    }
  }, [languages])

  useEffect(() => {
    if (activeLanguage) {
      refetch()
    }
  }, [activeLanguage])

  return (
    <div>
      {activeLanguage && (
        <Filters>
          <LanguageSelect
            className="w-[170px] mobile:w-full"
            onChange={setActiveLanguage}
            languages={languages?.map((language) => language.value) || []}
            defaultLanguage={activeLanguage}
          />
        </Filters>
      )}

      {data && (
        <div className="grid grid-cols-1">
          {data.map((item, index) => (
            <Styled.LandingWrapper key={index}>
              <Styled.LandingName>{item.title}</Styled.LandingName>
              <div className="flex items-center justify-between w-full min-w-0">
                <Styled.LandingLink>{item.link}</Styled.LandingLink>
                <Styled.LandingButtons>
                  <Button
                    prefix={
                      <CopyIcon copiedText={item.link} iconSize="small" />
                    }
                    design="tertiary"
                    size="small"
                  />
                  <Button
                    href={item.link}
                    target="_blank"
                    prefix={<Icon2 name="arrowTopRightOnSquare" size="small" />}
                    design="tertiary"
                    size="small"
                  />
                </Styled.LandingButtons>
              </div>
            </Styled.LandingWrapper>
          ))}
        </div>
      )}
    </div>
  )
}
