import RcUpload from 'rc-upload';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { RcFile } from 'rc-upload/lib/interface';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';


import { fetcher } from './fetcher';
import { Icon2, Text } from 'ui/atoms';
import * as S from './styled';
import { LocalFile, ServerFile } from './types'
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { useLoadingPosition } from './useLoadingPosition';

export type AcceptTypes = 'gif' | 'jpg' | 'jpeg' | 'png' | 'pdf';

type Props = {
  name: string;
  multiple?: boolean;
  type?: 'drag';
  accept?: AcceptTypes[];
  label?: React.ReactNode;
  limit?: number;
  error?: string;
  disabled?: boolean;
  uploadUrl: string;
  onChangeFiles: (files: ServerFile) => void;
  onDeleteFile: (index: number) => void;
  files?: ServerFile[];
  withBorder?: boolean;
  defaultUploadPlace?: DefaultUploadPlace[];
  isHideAddButton?: boolean;
}

export type DefaultUploadPlace = {
  title: JSX.Element | string;
  titleIcon?: 'plus' | 'cloudArrowUp';
  img?: {
    main: string;
    webp?: string;
  };
  icon?: 'plus' | 'cloudArrowUp';
  id: string;
}

export const Uploader = ({
  type = 'drag',
  accept = [],
  multiple = false,
  limit = 5,
  uploadUrl,
  onChangeFiles,
  onDeleteFile,
  defaultUploadPlace = [],
  isHideAddButton,
  files = [],
  name,
  // error,
  disabled,
}: Props) => {
  const [t] = useTranslation();
  const isMobile = useIsMobile();

  const [loading, setLoading] = useState<string[]>([]);
  const [uploadingError, setUploadingError] = useState<string>();

  const { addLoadedPlaces, removeLoadedPlaces, defaultUploadArray } = useLoadingPosition({ defaultUploadPlace });

  const onStartUpload = async (file: RcFile, loadedId?: string) => {
    try {
      setLoading(prev => [...prev, file.uid]);
      const newFile = await fetcher(uploadUrl, file);

      if (newFile) {
        setUploadingError(undefined);
        onChangeFiles({...newFile, placeId: loadedId});
        if (!!loadedId) {
          addLoadedPlaces(loadedId);
        }
    
      }

    } catch (error) {
      setUploadingError('File upload error. Try Again');
      if (loadedId) {
        removeLoadedPlaces(loadedId);
      }
    }
    finally {
      setLoading(prev => prev.filter(loadingFile => loadingFile !== file.uid ));
    }
  }

  const onDeleteFileFn = ({id, index, placeId}: {id: string, index: number, placeId?: string}) => {
    onDeleteFile(index);
    setUploadingError(undefined);
    setLoading(prev => prev.filter(loadingFile => loadingFile !== id ));
    if (placeId) {
      removeLoadedPlaces(placeId);
    }
  };

  return (
    <S.Container data-disabled={disabled}>
      <S.Files>

        {
          files.map((file: LocalFile, index) => {
            const { uid, src } = file
            const isLoading = loading.includes(uid)

            return (
              <S.LocalFile
                key={file.id}
                image={src}
                data-loading={isLoading}
                data-error={Boolean(uploadingError)}
                data-disabled={disabled}
              >
                <embed src={src} width={100} height={100}></embed>
                <S.Delete onClick={() => onDeleteFileFn({id: uid, index, placeId: file.placeId})}>✕</S.Delete>
              </S.LocalFile>
            )
          })
        }

        {
          defaultUploadArray?.map((item, index) => (
            <S.Add data-disabled={disabled} key={`default_upload_${index}`} isBorder>
            <RcUpload
              name={`${name}${type && `-${type}`}`}
              onStart={(files) => onStartUpload(files, item.id)}
              customRequest={() => {}}
              type={type}
              multiple={multiple}
              accept={accept?.map((a) => `.${a}`).join(',')}
            />

            <S.Label>
              { item.icon && 
                <S.LabelIcon>
                  <S.UploadIcon name={item.icon || 'plus'} />
                </S.LabelIcon>
              }
              <S.LabelImg>
                {!!item.img && <LazyLoadImage alt="upload card" src={item.img?.main} srcSet={item.img?.webp} />}
              </S.LabelImg>
              <S.LabelText>
                {item.titleIcon && (
                  <S.UploadIcon className='!text-gray-600 mr-[6px]' name={item.titleIcon} size='base' />)
                }
                {item.title || t('Add More')}
              </S.LabelText>
            </S.Label>
          </S.Add>
          ))
        }

        {(files.length < limit) && !isMobile && !isHideAddButton && (
          <S.Add data-disabled={disabled}>
            <RcUpload
              name={`${name}${type && `-${type}`}`}
              onStart={(newFiles) => onStartUpload(newFiles, defaultUploadArray[0]?.id)}
              customRequest={() => {}}
              type={type}
              multiple={multiple}
              accept={accept?.map((a) => `.${a}`).join(',')}
            />

            <S.Label>
              <S.LabelIcon>
                <S.UploadIcon name="plus" />
              </S.LabelIcon>
              <S.LabelText>
                {t('Add More')}
              </S.LabelText>
            </S.Label>
          </S.Add>
        )}
      </S.Files>

      {files.length < limit && isMobile && !isHideAddButton && (
        <S.MAdd data-disabled={disabled}>
          <RcUpload
            name={`${name}${type && `-${type}`}`}
            onStart={(newFiles) => onStartUpload(newFiles, defaultUploadArray[0]?.id)}
            customRequest={() => {}}
            type={type}
            multiple={multiple}
            accept={accept?.map((a) => `.${a}`).join(',')}
          />

          { <div className='mt-[16px] flex'>
              <Icon2 size='base' name='plus' className='!text-bronze.500' />
              <Text
                className='!text-bronze.500 ml-[8px]'
                withTranslate={false}
                level={2}
              >
                {t('Add More')}
              </Text>
            </div>
          }
        </S.MAdd>
      )}

      {uploadingError && (
        <S.FilesError>
          {uploadingError}
        </S.FilesError>)
      }

      { files.length >= limit && (!uploadingError) &&
        <S.FilesError>
          {files.length >= limit && (
            <>
              <S.FilesErrorIcon name="exclamationCircle" size="small" /> You can
              upload maximum {limit} files
            </>
          )}
        </S.FilesError>
      }

    </S.Container>
  )
}