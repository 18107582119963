import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { path } from 'libs/path';
import { Block, Title, Text, Icon2 } from 'ui/atoms';

import { WithdrawalModal } from 'pages/ib/modals/WithdrawalModal';

interface Props {
  amount: string;
}

export const Wallet: React.FC<Props> = ({ amount }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();

  const onRouteToWithdrawal = () => {
    navigate(path.money.withdrawalTab());
  }

  return (
    <Block>
      <div className='flex'>
        <Title level={3}>IB wallet</Title>
        <Icon2 name='wallet' className='ms-[8px] text-sunray.500' />
      </div>

      <div className='mt-[2.4rem]'>
         <Text className='!text-gray.600'>Amount</Text>
         <div className='flex justify-between items-center mt-[8px]'>
            <Title level={1} withTranslate={ false } >${amount}</Title>
            <div className='flex items-center cursor-pointer' onClick={() => setIsOpenModal(true)}>
              <Icon2 name='arrowDownOnSquare' className='me-[8px] text-sunray.500'/>
              <Text className='!text-sunray.500 !font-medium'>
                Withdraw
              </Text>
            </div>
         </div>
      </div>
        <WithdrawalModal
          isOpen={ isOpenModal }
          onClose={ () => setIsOpenModal(false) }
          maxAmount={ +amount }
        />
    </Block>
  )
}
