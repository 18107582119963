import styled from 'styled-components/macro'

export const InfoWrapper = styled.div`
  display: flex;
  width: 58rem;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`

export const CopytradeInfo = styled.div`
  margin-top: 8px;
  text-align: center;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
`
