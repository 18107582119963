import React from 'react';

import { Spinner2 } from '../spinner2/Spinner2';
import { SpinnerWithBlur } from '../spinner2';

interface Props {
  isLoading: boolean;
  hasData: boolean;
  // TODO добавить так, чтобы можно было прокинуть компонент скелетона, и он будет отображаться вместо spinner
  skeleton?: React.ReactNode;
  children: React.ReactNode;
  emptyWrappClasses?: string; 
  haveDataWrappClasses?: string;
}

export const DataPreloader: React.FC<Props> = ({ 
    haveDataWrappClasses = '', emptyWrappClasses = '',
    isLoading, children, hasData
}) => {

  // спиннер, когда нет даты
  if (isLoading && !hasData) {
    return (
      <div 
        className={`
          flex items-center justify-center
          ${ emptyWrappClasses }
        `}
      >
        <Spinner2 />
      </div>
    )
  }
    
  return (
    <div 
      className={`
        relative
        ${ haveDataWrappClasses }
      `}
    >
      { isLoading && hasData && <SpinnerWithBlur size='xl' isShow />}
      { children }
    </div>
  )
}
