import { AccountTariffName } from 'pages/accounts/create/types'
import { Account } from 'pages/accounts/types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetTraderAccount } from 'services/copytrade'
import { mapAccountPlatform, mapAccountTariff } from 'ui/molecules/accountLines'

import * as Styled from './styled'

export const StrategyAccountInfo = ({
  strategyID,
  onGetData,
  isDisabled,
}: {
  strategyID: string
  onGetData: (data: Account) => void
  isDisabled: boolean
}) => {
  const [t] = useTranslation()

  const { data } = useGetTraderAccount(strategyID)

  useEffect(() => {
    if (data) {
      onGetData(data)
    }
  }, [data])

  if (!data) {
    return null
  }

  return (
    <>
      <Styled.Wrapper isDisabled={isDisabled}>
        <div>
          <Styled.ParameterTitle>{t('Account type')}</Styled.ParameterTitle>
          <Styled.ParameterValue>
            {mapAccountTariff[data.tariffName as AccountTariffName]}
          </Styled.ParameterValue>
        </div>
        <div>
          <Styled.ParameterTitle>{t('Platform')}</Styled.ParameterTitle>
          <Styled.ParameterValue>
            {mapAccountPlatform[data.platform]}
          </Styled.ParameterValue>
        </div>
        <div>
          <Styled.ParameterTitle>{t('Leverage')}</Styled.ParameterTitle>
          <Styled.ParameterValue>
            {data.leverage === '210000000'
              ? t('Unlimited')
              : `1:${data.leverage}`}
          </Styled.ParameterValue>
        </div>
        <div>
          <Styled.ParameterTitle>{t('Swap-Free')}</Styled.ParameterTitle>
          <Styled.ParameterValue>
            {data.isSwapFree ? 'On' : 'Off'}
          </Styled.ParameterValue>
        </div>
      </Styled.Wrapper>
      {isDisabled && (
        <Styled.Warning>
          {t(
            'Account limit reached. You have opened the maximum number of accounts of this type.',
          )}
        </Styled.Warning>
      )}
    </>
  )
}
