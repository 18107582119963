import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';

import { $tableSortStore, sortResetEvent } from 'pages/ib/models/tableSortStore';
import { 
  $tableFilterStore, filterResetEvent,
  changeLoginEvent, changePeriodEvent,
} from 'pages/ib/models/tableFilterStore';
import { useGetClients } from 'services/ib/get/useGetClients';
import { Pagination } from 'ui/atoms/pagination/Pagination';
import { DateRange } from 'ui/molecules/datePickers';
import { DataPreloader, Icon2 } from 'ui/atoms';
import { fromDateToIso } from 'libs/dateUtils';

import { ClientsTable } from './ClientsTable';
import * as S from './styled';


const INITIAL_PER_PAGE = 20;

export const ClientTab: React.FC = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(INITIAL_PER_PAGE);

  const sort = useStore($tableSortStore);
  const filter = useStore($tableFilterStore);

  // Data fetch
  const { data, isLoading, isRefetching } = useGetClients({ page, perPage, sort, filter });

  const onChangePage = (a: number) => {
    setPage(a);
  };

  const onChangeInput = (val: string) => {
    changeLoginEvent(val);
  }  

  useEffect(() => {
    return () => {
      sortResetEvent();
      filterResetEvent();
    }
  }, []);

  return (
    <>
      <div className='flex gap-[10px] flex-col lg:flex-row'>
        <S.SearchInput
          placeholder="Search"
          debounceCallbackFn={ onChangeInput }
          maxLength={20}
          suffix={<Icon2 name={'magnifyingGlassIcon'}/>}
          withDebaunce
        />

        <DateRange
          setTimeline={changePeriodEvent}
          className='lg:ms-auto'
          customMapperDates={fromDateToIso}
          optionsListClasses='lg:!left-auto' 
        />
      </div>


      <DataPreloader isLoading={isLoading || isRefetching} hasData={!!data}>
        <div className="mt-[2.4rem] w-full">
          <ClientsTable items={data?.items} totals={data?.totalsForClients} />

          {data && Number(data?.totalRecords) > INITIAL_PER_PAGE && (
            <Pagination
              pageSize={perPage}
              current={page}
              total={Number(data.totalRecords)}
              onChange={onChangePage}
              onChangePerPage={setPerPage}
              classNames="mt-[2.4rem]"
              showSizeChanger
            />
          )}
        </div>
      </DataPreloader>
    </>
  )
}
