import 'chartjs-adapter-date-fns'
import { addMinutes, format } from 'date-fns'
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js'
import { useUnit } from 'effector-react'

import { getDateObjFromUnix } from 'libs/dateUtils'
import { tooltipCss } from 'pages/copytrade/traderTables/statisticsTab/TooltipCss'
import React, { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { LinesItem } from 'services/copytrade/get/useGetPerfomance'
import { externalTooltipHandler } from 'ui/molecules/graphs'

import { $perfomanceFilterTime } from './model'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
)

// @ts-ignore
Tooltip.positioners.customPosition = function (elements, position) {
  if (!elements.length) {
    return false
  }

  let offset = 0
  // @ts-ignore
  const chart = this?.chart

  //adjust the offset left or right depending on the event position
  if (chart?.width / 2 > position?.x) {
    offset = 20
  } else {
    offset = -80
  }

  return {
    x: position.x + offset,
    y: position.y + 5,
  }
}

type Props = {
  lines: LinesItem[]
}

const dateRangeScale = {
  TIME_RANGE_WEEK: 'day',
  TIME_RANGE_MONTH: 'day',
  TIME_RANGE_THREE_MONTH: 'week',
  TIME_RANGE_SIX_MONTH: 'month',
  TIME_RANGE_OVERALL: 'month',
} as const

export const Graph: React.FC<Props> = ({ lines }) => {
  const chartTimeRange = useUnit($perfomanceFilterTime)

  const data = useMemo(() => {
    const obj: ChartData<'line', any, string> = {
      datasets: [],
    }

    obj.datasets = lines.map((item) => {
      const lineGrapData = {
        fill: false,
        borderColor: item.color,
        pointBackgroundColor: item.color,
        pointBorderColor: item.color,
        tension: 0.1,
        pointRadius: 3,
        label: item.title,
        data: [],
      }

      // @ts-ignore
      lineGrapData.data = item.points.map(point => {
        const time = getDateObjFromUnix(+point.timestamp);
        const timeUTC = format(addMinutes(time, time.getTimezoneOffset()), ' dd/MM/yyyy p');
        return {
          x: Number(point.timestamp) * 1000,
          y: point.value,
          label: point.label,
          title: timeUTC,       }
      });

      return lineGrapData
    })

    return obj
  }, [lines])

  const options = useMemo(() => {
    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time',
          display: true,
          time: {
            unit: dateRangeScale[chartTimeRange],
          },
        },
        y: {
          offset: true,
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
          position: 'customPosition',
          external: externalTooltipHandler,
          callbacks: {
            title: (ctxArr: any[]) => {
              const firstCtx = ctxArr?.[0]
              if (!firstCtx) return ''

              return firstCtx.raw.title;
            },

            label: function (context: any) {
              let label = context.label
              if (context.raw.label) {
                return context.raw.label
              }
              return label
            },
          },
          ...tooltipCss,
        },
      },
    }
  }, [chartTimeRange])

  if (!data) return

  return (
    <div className='!overflow-hidden w-full mt-[1.8rem]'>
      <Line
        // @ts-ignore
        options={options}
        data={ data }
        className='lg:!h-[550px] !w-full h-[151px] !overflow-hidden' 
      />
    </div>
  )
}
