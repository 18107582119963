import { createEvent, restore, sample } from 'effector'
import { condition } from 'patronum/condition'

import { toPath } from '../../../../../features/router'
import { $userProfile, getUser } from '../../../../../features/user'
import { $isUserEmailConfirmed } from '../../../../../features/user/model'
import { fx } from '../../../../../libs'

export const updateCountry = fx<{ country: string }, void>({
  method: 'PATCH',
  url: '/user/v1/country',
})

export const submitCountry = createEvent<string>()

sample({
  clock: submitCountry,
  fn: (country) => {
    return {
      body: {
        country,
      },
    }
  },
  target: updateCountry,
})

// update user profile

sample({
  source: $userProfile,
  clock: submitCountry,
  fn: (userProfile, updateCountry) => {
    userProfile.country = updateCountry
    return { ...userProfile }
  },
  target: $userProfile,
})

// to dashboard

export const proceed = createEvent<unknown>()

condition({
  source: proceed,
  if: $isUserEmailConfirmed,
  then: toPath,
  else: getUser.prepend(() => ({})),
})
