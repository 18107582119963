import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { GiftList } from '../types'

export const GET_GIFTSHOP_LIST_KEY = ['gift-list']

export const getGiftList = async () => {
  const res = await axios.get<GiftList>('/gift-shop/v1/gifts')

  return res.data
}

export const useGetGiftList = (enabled = true) => {
  return useQuery({
    queryKey: GET_GIFTSHOP_LIST_KEY,
    queryFn: getGiftList,
    enabled,
  })
}
