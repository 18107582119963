import { MetaTraderSettings } from 'pages/accounts/details/MetaTraderSettings'
import { AccountSelect as UiAccountSelect } from 'pages/money/molecules/newAccountSelect'
import styled, { css } from 'styled-components/macro'
import { Button } from 'ui/atoms'

import info from '../images/info-icon.svg'
import warning from '../images/warning-icon.svg'
import { AccountLine } from '../molecules/accountLine'

export const ContentContainer = styled.div`
  margin-top: 24px;
  padding-bottom: 8px;
  min-height: 587px;
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    margin-top: 20px;
    padding-bottom: 0;
    min-height: auto;
  }
`

export const CopyingInfo = styled.div`
  margin-top: 8px;
  line-height: var(--body-line-height-regular);

  @media (max-width: 599px) {
    margin-top: 16px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const Steps = styled.div`
  counter-reset: counter;
  margin: 32px 0;
  display: flex;
  align-items: center;

  @media (max-width: 699px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
  }

  @media (max-width: 599px) {
    margin: 24px 0;
    padding: 8px 0;
  }
`

export const Step = styled.div<{ isChecked: boolean; isActive: boolean }>`
  counter-increment: counter;
  position: relative;
  margin-right: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: var(--body-line-height-regular);

  @media (max-width: 699px) {
    margin-right: 0;
    padding-top: 52px;
    justify-content: center;
  }

  @media (max-width: 599px) {
    padding-top: 38px;
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
  }

  &::before {
    content: ${({ isChecked }) =>
      isChecked
        ? `url('data:image/svg+xml,<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.7206 2.37197C15.2021 1.69786 15.046 0.76103 14.3719 0.279517C13.6977 -0.201998 12.7609 -0.0458597 12.2794 0.62826L5.80859 9.68739L2.56066 6.43946C1.97487 5.85367 1.02513 5.85367 0.43934 6.43946C-0.146447 7.02524 -0.146447 7.97499 0.43934 8.56078L4.93934 13.0608C5.25112 13.3726 5.68429 13.5314 6.12372 13.495C6.56315 13.4586 6.96431 13.2308 7.2206 12.872L14.7206 2.37197Z" fill="white"/></svg>')`
        : 'counter(counter)'};
    margin-right: 12px;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    color: var(--color-gray-100);
    font-weight: 700;
    transition: background-color var(--transition);
    background-color: ${({ isActive, isChecked }) =>
      isActive || isChecked
        ? 'var(--color-bronze-400)'
        : 'var(--color-gray-500)'};
    border-radius: 50%;

    @media (max-width: 699px) {
      position: absolute;
      top: 0;
      left: 50%;
      margin-right: 0;
      transform: translateX(-50%);
    }

    @media (max-width: 599px) {
      width: 32px;
      height: 32px;
      font-size: var(--body-font-size-medium);
    }

    ${({ isChecked }) =>
      isChecked &&
      css`
        padding-top: 2px;
      `}
  }

  &::after {
    content: '';
    margin-left: 16px;
    width: 72px;
    height: 2px;
    background-color: ${({ isChecked }) =>
      isChecked ? 'var(--color-bronze-400)' : 'var(--color-gray-400)'};

    @media (max-width: 699px) {
      position: absolute;
      top: 35px;
      right: 0;
      width: 48px;
      transform: translateX(50%);
    }

    @media (max-width: 599px) {
      top: 27px;
      width: 28px;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        background-image: linear-gradient(
          to right,
          var(--color-bronze-400) 50%,
          var(--color-gray-400) 50%
        );
      `}
  }

  &:last-child::after {
    content: none;
  }
`

export const AccountRadioWrapper = styled.div`
  margin-bottom: 24px;
  padding-left: 36px;
  max-width: 580px;

  @media (max-width: 599px) {
    padding-left: 0;
  }
`

// todo: отрефакторить uiRadio :/
export const Radio = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
  cursor: pointer;
  margin-bottom: 12px;
  margin-left: -36px;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  line-height: var(--body-line-height-regular);
  font-weight: ${({ isActive }) => (isActive ? 500 : 400)};

  @media (max-width: 599px) {
    gap: 4px;
    margin-left: 0;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-small);
  }

  &::before {
    content: '';
    margin: 2px;
    border: ${({ isActive }) =>
      isActive
        ? '7px solid var(--color-sunray-500)'
        : '2px solid var(--color-gray-400)'};
    width: 20px;
    height: 20px;
    transition: border-color var(--transition);
    border-radius: 50%;

    @media (max-width: 599px) {
      margin: 5px;
      width: 14px;
      height: 14px;

      ${({ isActive }) =>
        isActive &&
        css`
          border-width: 5px;
        `}
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      color: var(--color-gray-500);

      &::before {
        border: none;
        background-color: var(--color-gray-300);
      }
    `}
`

export const WarningText = styled.div<{ icon?: 'warning' | 'info' }>`
  padding-left: 24px;
  margin-top: 10px;
  font-size: var(--body-font-size-small);
  line-height: 20px;
  background-image: ${({ icon }) =>
    icon === 'warning' ? `url(${warning})` : `url(${info})`};
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: top left;
`

export const StepsButtons = styled.div`
  margin-top: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 599px) {
    margin-left: 0;
    flex-wrap: wrap;
  }
`

export const ChangeStepButton = styled(Button)`
  min-width: 216px;

  @media (max-width: 599px) {
    flex-grow: 1;
    min-width: auto;
  }
`

export const GridColumn = styled.div`
  display: grid;
  gap: 32px;

  @media (max-width: 599px) {
    gap: 24px;
  }
`

export const DuplicatedAccountLine = styled(AccountLine)`
  margin-top: 20px;
  max-width: 580px;

  @media (max-width: 599px) {
    margin-top: 16px;
  }
`

export const MetaTraderInfo = styled(MetaTraderSettings)`
  margin-top: 20px;
  max-width: 580px;

  @media (max-width: 599px) {
    margin-top: 16px;
  }
`

export const CopyingRadioWrapper = styled.div`
  margin-top: 20px;
  padding-left: 36px;
  display: grid;

  @media (max-width: 599px) {
    margin-top: 16px;
    padding-left: 0;
  }

  ${Radio} {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ProportionText = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
  line-height: var(--body-line-height-regular);

  @media (max-width: 599px) {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const ProportionWrapper = styled.div`
  max-width: 256px;

  @media (max-width: 599px) {
    max-width: 100%;
  }
`

export const AccountSelect = styled(UiAccountSelect)`
  margin: 0;
  max-width: 100%;
`
