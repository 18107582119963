import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";

import { usePostJoinPartner } from 'services/ib/mutation';
import { Title, Text, Button } from 'ui/atoms';

import ibThreeLevelsSrc from 'pages/ib/img/ibThreeLevels.png';
import ibThreeLevelsSrcWeb from 'pages/ib/img/ibThreeLevels.webp';


import * as S from './styled';

export const ThreeLevels = () => {
  const { mutateAsync, isLoading } = usePostJoinPartner();

  return (
    <S.PartnerBlock className='mt-[2rem] flex lg:flex-row flex-col-reverse'>
      <div className='lg:!w-[50.3rem] w-full'>
        <Title className='!font-medium mt-[2.4rem] lg:mt-0 text-center lg:text-start'>
          Join the one and only three-level Partnership program
        </Title>
        <Text className='mt-[1.6rem]'>
          You will get a percentage not only for the partners attracted by you but also for the partners attracted by your partners.
          Create your partnership net and enjoy high profit!
        </Text>
        <div className='mt-[2.4rem] p-[16px] rounded-4 bg-gray.300'>
          <Text level={2}>
            Create your own multi-level network of Partners and get the maximum of your Partners’ network. 
            Get 10% of your second level partners income and 5% of your third level partners income.
          </Text>
        </div>
        
        <Button
          design='primary'
          onClick={ () => mutateAsync() }
          loading={ isLoading }
          disabled={ isLoading }
          className='min-w-[24rem] mt-[3.2rem] mr-auto ml-auto lg:mr-0 lg:ml-0'
          name='joinNow'
        >
          Join now
        </Button>
      </div>
      <div className='ms-auto flex items-center'>
        <LazyLoadImage
          src={ibThreeLevelsSrc}
          srcSet={ibThreeLevelsSrcWeb}
          height={400}
          alt='person'
          className='h-[30rem] rounded-[8px]'
        />
      </div>
    </S.PartnerBlock>
  )
}
