import axios, { AxiosError } from 'axios'
import { ResponseError } from 'libs/request'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useUploadImage } from 'services/copytrade'

import * as Styled from './styled'

export const ImageUpload = ({
  imageUrl,
  onChange,
}: {
  imageUrl?: string
  onChange: (options: { fileName: string; imageUrl: string }) => void
}) => {
  const { data, mutateAsync, isLoading, isSuccess } = useUploadImage()

  const inputRef = useRef<HTMLInputElement>(null)

  const [error, setError] = useState<string>()

  useEffect(() => {
    if (isSuccess) {
      setError('')

      onChange({fileName: data.fileName, imageUrl: data.fileURL})
    }
  }, [isSuccess])

  const handleButtonClick = () => {
    inputRef.current?.click()
  }

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const formData = new FormData()

      formData.append('file', event.target.files?.[0])

      try {
        await mutateAsync(formData)
      } catch (error: unknown) {
        // todo: возможно, нужно сделать общий обработчик ошибок в react-query
        if (axios.isAxiosError(error)) {
          const knownError = error as AxiosError<ResponseError>

          setError(
            knownError.response?.data?.details?.validationErrors.items[0].msg,
          )
        }
      }
    }
  }

  return (
    <Styled.Wrapper>
      <input
        type="file"
        ref={inputRef}
        hidden
        accept="image/*"
        onChange={handleUpload}
      />
      <Styled.Preview background={imageUrl} />
      <Styled.UploadButton
        name="upload-image"
        size="small"
        design="secondary"
        onClick={handleButtonClick}
        disabled={isLoading}
        loading={isLoading}
      >
        Change image
      </Styled.UploadButton>
      {error && <Styled.ErrorText>{error}</Styled.ErrorText>}
    </Styled.Wrapper>
  )
}
