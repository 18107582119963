import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetDepositForm } from 'services/payments/get/useGetDepositForm';

import { Button, Icon2, Spinner2, Text, Title } from 'ui/atoms';
import { Alert } from 'ui/molecules/alerts/styled';

type Props = {
  gatewayId: string;
  onBack: () => void;
}


export const Exchanger: React.FC<Props> = ({ gatewayId, onBack }) => {
  const { data, isFetching } = useGetDepositForm(gatewayId);

  const [t] = useTranslation();

  const goToExchangerBtn = () => {
    if (!data?.submitButton) return;

    if (data.submitButton.isNewTab) {
      window.open(data.submitButton.url, '_blank', 'noreferrer');
      return;
    }

    window.location.href = data.submitButton.url;
  }

  return (
    <div className='w-full mb-[4rem]'>
    <Button
      name='backButton'
      design='tertiary'
      // className='w-[16rem]'
      onClick={ onBack }
    >
      <Icon2 name='arrowLeft' className='me-3'/>
      <Text level={2} weight='medium'>Back</Text>
    </Button>
    {isFetching
      ? (
        <div className='w-full flex justify-center'>
          <Spinner2 size='large'/>
        </div>
      ) : (
        <>
          <div className='flex justify-center w-full items-center flex-col gap-[24px]'>
            {data?.maintenanceInfo?.maintenanceMessage && (
              <Alert type='pending' className='lg:w-[520px] w-full'>
                { data.maintenanceInfo.maintenanceMessage }
              </Alert>)
            }
            <img src={data?.gatewayLogo} alt='gateway_logo' className='w-[64px] h-[64px] rounded-full' />
            <Title level={3}>{data?.gatewayName}</Title>

            {data?.limits && 
              <Text level={3} color='subtext' className='mt-[-14px]' withTranslate={false}>
                {t('Limits')}: {data.limits}
              </Text>
            }

            {data?.description && 
              <Text level={2} className='mt-[16px] max-w-[44rem] whitespace-pre-wrap'>
                {data.description}
              </Text>
            }
          </div>

          <div className='mt-[40px] w-full flex items-start justify-center'>
            <Icon2 size='small' name='exclamationCircle' className='text-opal.500 mr-[6px]' />
            <Text level={3} className='max-w-[366px] whitespace-pre-wrap' withTranslate={ false }>
              {data?.warningMessage}
            </Text>
          </div>

         {data?.submitButton && 
            <div className='flex justify-center'>
              <Button
                className='mt-[4rem] min-w-[28rem]'
                onClick={goToExchangerBtn}
              >
                { data.submitButton?.text }
                <Icon2 name='arrowTopRightOnSquare' className='ml-[8px]' />
              </Button>
            </div>
          }

        </>
      )}
  </div>
  )
}
