import styled, { css } from 'styled-components/macro'

import coin from '../../images/color-coin.png'
import coinWeb from '../../images/color-coin.webp'

export const Wrapper = styled.div<{ size: 'medium' | 'large' }>`
  pointer-events: none;
  border: 1px solid rgba(223, 222, 222, 0.4);
  display: inline-flex;
  gap: 20px;
  align-items: center;
  font-weight: 500;
  background-color: var(--color-gray-100);
  background-image: url(${coin});
  background-image: image-set(url(${coinWeb}), url(${coin}));
  background-repeat: no-repeat;

  ${({ size }) =>
    size === 'large'
      ? css`
          padding: 11px 16px 11px 52px;
          font-size: 20px;
          line-height: 25px;
          border-radius: 50px;
          background-size: 32px auto;
          background-position: left 12px center;
        `
      : css`
          padding: 7px 12px 7px 41px;
          font-size: var(--body-font-size-regular);
          line-height: var(--body-line-height-regular);
          border-radius: 20px;
          background-size: 23px auto;
          background-position: left 10px center;
        `}
`
