import React from 'react';
import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import { CreateWithdrawalResponse, ErrorCreateWithdrawal, useCreateWithdrawal } from 'services/payments/mutations/useCreateWithdrawal';
import { ProcessingProvider } from 'services/money/get/types';

import { $amountField, $destenation, setAmountErrorEvent } from '../CutomFormWithdrawal/model';
import { $customGatewayId, $pageDataStore } from '../model';
import { path } from 'libs/path';
import { ResultWithdrawalStore, setResultPageEvent } from 'pages/money/resultPages/withdrawal/model';
import { $customWithdrawalStore } from '../../model/withdrawalStore';


export type AdditionFields = {
  [key: string]: string;
} & {
  address?: string;
  code?: string;
}

type UseSbmWithdrawal = {
  isLoading: boolean;
  isError: boolean;
  onCreateWithdrawal: (fields: AdditionFields) => Promise<CreateWithdrawalResponse | void>;
}

type Options = {
  gatewayProvider: ProcessingProvider;
  setError?: UseFormSetError<Record<string, any>>;
}

export const useSbmWithdrawal = ({ gatewayProvider, setError }: Options): UseSbmWithdrawal => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { activeCurrency, amount } = useUnit($amountField);
  const pageDataStore = useUnit($pageDataStore);
  const customGatewayId = useUnit($customGatewayId);
  const source = useUnit($destenation);
  const customWithdrawalStore = useUnit($customWithdrawalStore);
  
  const { mutateAsync, isLoading, isError } = useCreateWithdrawal();
  
  const onCreateWith = async (fields: AdditionFields) => {
    if (!source || !activeCurrency) return;
    if (!amount) {
      setAmountErrorEvent('EMPTY');
      return;
    }

    const { code, ...rest } = fields;

    try {
      const resp = await mutateAsync({
        sourceId: source.id,
        sourceType: source.type,
        gatewayId: customGatewayId,
        currency: activeCurrency,
        amount,
        formData: {
          amount,
          ...rest
        },
        otp: code,
      });


      if (resp.id && !resp.needOTP) {
        if (gatewayProvider === 'PPP_MANUAL') {
          const obj: ResultWithdrawalStore = {
            status: 'pending',
            id: resp.id,
            currency: activeCurrency,
            provider: gatewayProvider,
            amount,
            isLinkOpenNewTab: true,
            title: '',
            subTitle: t('We are processing your transaction now – it may take some time. See the current status of your transaction in the Transaction History (My Accounts section).')!
          }

          if (customWithdrawalStore?.paymentMethodType === 'PMT_EXCHANGER') {
            obj.buttonText = t('Go to Exchanger')!;
            obj.buttonLink = pageDataStore?.gatewayNameLink;
            obj.subTitle = 'Your request is in process now. The funds will go to the Exchanger’s wallet within 24 hours. Please contact the Exchanger to receive your withdrawal.';
          }
  
          setResultPageEvent(obj);
          navigate({
            pathname: `/${path.money.resultWithdrawal()}`,
            search: `?status=pending&amount=${amount}&id=${resp.id}&currency=${activeCurrency}&isDynamic=true`
          });
          return;
        }
  
        navigate({
          pathname: `/${path.money.resultWithdrawal()}`,
          search: `?status=pending&amount=${amount}&id=${resp.id}&currency=${activeCurrency}&isResultAmount=true`
        });
      }

      return resp;
    } catch (error: any) {
      const isAxiosError = error?.request
      
      // Обработка ошибки валидации на беке
      if (!!isAxiosError) {
        const axiosErrorData = (error as AxiosError<ErrorCreateWithdrawal, any>).response?.data?.details?.validationErrors?.items;
        if (!axiosErrorData?.length) return;
        
        axiosErrorData.forEach((item) => {
          if (fields[item.field]) {
            setError?.(item.field, {
              type: 'validate',
              message: item.msg
            })
          }
        });
      }
      
    }
  };

  return ({
    onCreateWithdrawal: onCreateWith,
    isLoading,
    isError,
  })
}
