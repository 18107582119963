import { createEvent, restore } from "effector";

const default_store = 'TIME_RANGE_WEEK';

export type ChartTimeRange =
  | 'TIME_RANGE_WEEK'
  | 'TIME_RANGE_MONTH'
  | 'TIME_RANGE_THREE_MONTH'
  | 'TIME_RANGE_SIX_MONTH'
  | 'TIME_RANGE_OVERALL';

export const setPerfomanceTime = createEvent<ChartTimeRange>();
export const resetPerfomanceStore = createEvent();
export const $perfomanceFilterTime = restore<ChartTimeRange>(setPerfomanceTime, default_store).reset(resetPerfomanceStore);