import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { BalanceOperations } from './types/BalanceOperations';

export const GET_BALANCE_OPERATIONS = 'GET_BALANCE_OPERATIONS';

const getBalanceOperations = async ({ strategyID, page, perPage }: Options) => {
  const res = await axios.get<BalanceOperations>('/copytrade/v1/strategy/balance-operations', {
    params: { strategyID, page, perPage },
  })

  return res.data
}

interface Options {
  strategyID: string;
  page: number;
  perPage?: number;
}

export const useGetBalanceOperations = ( { strategyID, page, perPage = 20 }: Options) => {
  return useQuery({
    queryKey: [GET_BALANCE_OPERATIONS, page, perPage, strategyID],
    queryFn: () => getBalanceOperations({ strategyID, page, perPage }),
    keepPreviousData: true
  })
}
