import { path } from 'libs/path'
import { accountChoosed } from 'pages/money/depositChoice/model'
import {
  Filters,
  OuterWrapper,
  TransactionList,
  useResetStore,
} from 'pages/money/transactionsNew'
import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExchangerWallets } from 'services/wallet/get/types'
import { Spinner2, Title } from 'ui/atoms'

export const MoneyHistory = ({
  data,
  isLoading,
}: {
  data?: ExchangerWallets
  isLoading: boolean
}) => {
  useResetStore()

  const nav = useNavigate()

  const exchangerWallets = useMemo(() => {
    if (!data) return {}

    const walletsIds = data.items?.map((item) => item.exchangerWalletID)
    if (!walletsIds?.length) return {}

    return {
      exchangerWalletIDs: walletsIds,
    }
  }, [data]);

  const notFoundClickFn = () => {
    if (!exchangerWallets?.exchangerWalletIDs?.length) return;

    accountChoosed(exchangerWallets?.exchangerWalletIDs[0]);
  }


  useEffect(() => {
    if (isLoading || !data) return

    if (data.items?.length === 0) {
      nav(path.dashboard.entry())
    }
  }, [data, isLoading])

  if (isLoading || !data?.items?.length) {
    return <Spinner2 size="large" />
  }

  return (
    <OuterWrapper>
      <Title level={2} className="mb-[8px]">
        Transaction History
      </Title>
      <Filters type="exchanger" />
      <TransactionList defaultFilter={exchangerWallets} notFoundClickFn={ notFoundClickFn } />
    </OuterWrapper>
  )
}
