import { useStore, useUnit } from 'effector-react'
import { ErrorBoundary } from 'features/errorBoundary'
import { $userProfile } from 'features/user'
import { useIsMobile } from 'libs/hooks/useIsMobile'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetPerfomance } from 'services/copytrade/get/useGetPerfomance'
import { Icon2, Money, Text, TipyTooltip } from 'ui/atoms'

import { ChartsTabs } from './ChartsTabs'
import { GainTooltip } from './GainTooltip';
import { HeadBlock } from './HeadBlock'
import { $perfomanceFilterTime, resetPerfomanceStore } from './model'
import { PerfomanceProgress } from './PerfomanceProgress'

export const Perfomance = () => {
  const { strategyID } = useParams<{ strategyID: string }>();
  const [t] = useTranslation();

  const isMobile = useIsMobile();

  const { userID } = useStore($userProfile);

  const chartTimeRange = useUnit($perfomanceFilterTime);

  const { data, isLoading } = useGetPerfomance({
    strategyId: strategyID!,
    chartTimeRange,
    isAuthorized: !!userID,
  });

  const isPositiveProfit = Number(data?.grossProfit) >= 0
  const isPositiveGain = Number(data?.gain) >= 0

  useEffect(() => {
    return () => {
      resetPerfomanceStore();
    }
  }, []);

  if (isLoading || !data) return null;

  return (
    <div>
      <HeadBlock />

      <div className="flex lg:gap-[44px] lg:mt-[12px] mt-[20px] flex-wrap gap-x-[20px] gap-y-[6px]">
        <div className="flex gap-[8px] lg:w-auto">
          <Text
            level={isMobile ? 3 : 1}
            className="!text-gray.600"
            weight="medium"
          >
            Win rate
          </Text>
          <div className="flex gap-[4px] items-center">
            <Text
              level={isMobile ? 3 : 1}
              className="!text-eucalyptus.600"
              weight="medium"
              withTranslate={false}
            >
              {data?.winRate}%
            </Text>
            <TipyTooltip
              content={
                <Text level={3} className="max-w-[200px]">
                  The ratio of successful trades to the total number of orders.
                </Text>
              }
            >
              <div>
                <Icon2
                  name="questionMarkCircle"
                  className="!text-bronze.500"
                  size="small"
                />
              </div>
            </TipyTooltip>
          </div>
        </div>

        <div className="flex gap-[8px] lg:w-auto">
          <Text
            level={isMobile ? 3 : 1}
            className="!text-gray.600"
            weight="medium"
          >
            {isMobile ? 'Profit' : 'Gross profit'}
          </Text>
          <Text
            level={isMobile ? 3 : 1}
            className={`${
              isPositiveProfit ? '!text-eucalyptus.600' : '!text-opal.500'
            } prevent-rtl`}
            weight="medium"
            withTranslate={false}
          >
            {isPositiveProfit
              ? `$${data.grossProfit}`
              : `-$${data.grossProfit.replace('-', '')}`}
          </Text>
        </div>

        <div className="flex gap-[8px] lg:w-auto">
          <Text
            level={isMobile ? 3 : 1}
            className="!text-gray.600"
            weight="medium"
          >
            Gain
          </Text>

          <div className="flex gap-[4px] items-center prevent-rtl">
            <Text
              level={isMobile ? 3 : 1}
              className={`${
                isPositiveGain ? '!text-eucalyptus.600' : '!text-opal.500'
              }`}
              weight="medium"
              withTranslate={false}
            >
              {data.gain}%
            </Text>
            <GainTooltip />
          </div>
        </div>

        <div className="flex gap-[8px] lg:w-auto">
          <Text
            level={isMobile ? 3 : 1}
            className="!text-gray.600"
            weight="medium"
          >
            Balance
          </Text>
          <Text level={isMobile ? 3 : 1} weight="medium" withTranslate={false}>
            <Money value={+data.balance} currency={data.currency} />
          </Text>
        </div>

        <div className="flex gap-[8px] lg:w-auto">
          <Text
            level={isMobile ? 3 : 1}
            className="!text-gray.600"
            weight="medium"
          >
            Equity
          </Text>
          <Text level={isMobile ? 3 : 1} weight="medium" withTranslate={false}>
            <Money value={+data.equity} currency={data.currency} />
          </Text>
        </div>
      </div>

      <ErrorBoundary fallback="Error">
        {data?.charts && <ChartsTabs charts={data.charts} />}
      </ErrorBoundary>

      <div className="mt-[2.4rem]">
        <PerfomanceProgress
          numLossOrders={data.numLossOrders}
          numProfitableOrders={data.numProfitableOrders}
          profit={+data.profit}
          loss={+data.loss}
        />
      </div>
    </div>
  )
}
