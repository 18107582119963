import React, { PropsWithChildren } from 'react'
import { Icon2 } from 'ui/atoms'
import { Icons } from 'ui/atoms/icon2'

import * as S from './styled'

export type AlertType = 'success' | 'warning' | 'pending' | 'info'

interface Props extends PropsWithChildren {
  type: AlertType
  title?: string
  onClose?: () => void
  className?: string
}

const icons: Record<AlertType, Icons> = {
  pending: 'clockSolid',
  success: 'checkCircle',
  warning: 'exclamationTriangleSolid',
  info: 'informationCircleSolid',
}

export const Alerts: React.FC<Props> = ({ type, children, title, onClose, className }) => {
  return (
    <S.Alert type={type} className={className}>
      <Icon2 name={icons[type]} />

      <div className='w-full'>
        {title && <S.Title>{title}</S.Title>}
        {children}
      </div>

      {onClose && <S.CloseButton type="button" onClick={onClose} />}
    </S.Alert>
  )
}
