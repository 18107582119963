import { useStore } from 'effector-react'
import { path } from 'libs/path'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import check from './images/check.svg'
import user from './images/user.svg'
import { $userProfile } from './model'

type Props = {
  className?: string
}

export const UserProfile = ({ className }: Props) => {
  const navigate = useNavigate()
  const userProfile = useStore($userProfile)

  const { firstName, lastName, avatarURL, isVerified } = userProfile

  const onClickFn = () => {
    navigate(path.dashboard.profile.entry())
  }

  return (
    <UserContainer className={className} onClick={onClickFn}>
      <div className="relative">
        <div
          className="w-[32px] h-[32px] rounded-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${avatarURL || user})`,
          }}
        />
        {isVerified && (
          <span
            className="absolute bottom-[-1px] right-[-1px] w-[14px] h-[14px] bg-cover"
            style={{
              backgroundImage: `url(${check})`,
            }}
          />
        )}
      </div>
      <div className="font-medium text-body.medium leading-1 ms-[12px]">
        {firstName}
        {lastName && ` ${lastName[0]}.`}
      </div>
    </UserContainer>
  )
}

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 6px;
  cursor: pointer;
`
