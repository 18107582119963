import { useEffect, useState } from 'react'
import { useCancelCopying } from 'services/copytrade'
import { Text } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'
import { useWindowSize } from 'usehooks-ts'

import { setStopCopyingAlertVisible } from '../../model'

export const StopCopyingModals = ({
  onClose,
  subscriptionID,
}: {
  onClose: () => void
  subscriptionID: string
}) => {
  const { width } = useWindowSize()
  const isMobileMode = width < 430

  const [visibleModalNumber, setVisibleModalNumber] = useState<1 | 2>(1)

  const { mutateAsync, isSuccess } = useCancelCopying()

  const handleCancel = (isCloseOpenedPos: boolean) => {
    mutateAsync({ subscriptionID, isCloseOpenedPos })
  }

  useEffect(() => {
    if (isSuccess) {
      setStopCopyingAlertVisible(true)

      setTimeout(() => {
        setStopCopyingAlertVisible(false)
      }, 10000)
      
      onClose()
    }
  }, [isSuccess])

  return (
    <>
      <ModalDialog
        isOpen={visibleModalNumber === 1}
        okLabel="Stop"
        onOk={() => setVisibleModalNumber(2)}
        cancelLabel="Cancel"
        onCancel={onClose}
        title="Stop copying"
      >
        <Text>Are you sure you want to stop copying this strategy?</Text>
      </ModalDialog>
      <ModalDialog
        isOpen={visibleModalNumber === 2}
        okLabel="Leave open"
        onOk={() => handleCancel(false)}
        cancelLabel="Close automatically"
        onCancel={() => handleCancel(true)}
        cancelButtonType="primary"
        title="Opened orders"
        contentStyles={{ width: isMobileMode ? 340 : 400 }}
      >
        <Text>What do you want to do with the opened orders?</Text>
      </ModalDialog>
    </>
  )
}
