import * as React from 'react'
import styled from 'styled-components/macro'

import { Text, Title } from '../../ui/atoms'

type RegisterLayoutProps = {
  children?: React.ReactNode
  type: 'login' | 'register' | 'reset'
}

const title = {
  login: 'Sign in to your Headway account',
  register: 'Register your trading account on Headway',
  reset: 'Start your way with Headway',
}

export const AuthLayout = ({ children, type }: RegisterLayoutProps) => {
  return (
    <AuthContainer>
      <AuthHeader>
        <AuthTitle level={1}>
          {type === 'reset' ? 'Sign In' : 'Personal Area'}
        </AuthTitle>
        <Text>{title[type]}</Text>
      </AuthHeader>
      <AuthSection>{children}</AuthSection>
    </AuthContainer>
  )
}

const AuthContainer = styled.div`
  max-width: 424px;
  width: 100%;
`

const AuthHeader = styled.div`
  text-align: center;
  margin-bottom: 80px;

  @media (max-width: 540px) {
    margin-bottom: 40px;
  }
`

const AuthSection = styled.div``

const AuthTitle = styled(Title)`
  margin-bottom: 8px;
`

//

export const AuthSectionTitle = styled(Title)``

export const AuthSectionText = styled(Text)`
  margin-top: 8px;
  position: relative;
`
