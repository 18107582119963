import * as React from 'react'
import styled from 'styled-components/macro'

import { yup } from '../../../../../libs/validators'
import {
  Button,
  Field,
  Form,
  Icon2,
  Input,
  Title,
} from '../../../../../ui/atoms'
import { AuthLayout, AuthSectionText, AuthSectionTitle } from '../../../layout'
import { usePasswordPage } from './hooks'
import { setCreateMode, setGeneratePassword, submitForm } from './model'
import { CopyButton } from 'ui/molecules/CopyButton/CopyButton';

const initial = {
  newPassword: '',
  newPasswordRepeat: '',
}

export const PasswordRecoveryChange = () => {
  const { temporaryPassword, form, isCreateMode } = usePasswordPage()

  return (
    <AuthLayout type="register">
      <AuthSectionTitle level={2}>Password</AuthSectionTitle>
      <AuthSectionText>Your temporary password</AuthSectionText>
      <PasswordGenerator>
        <PasswordInput
          value={temporaryPassword}
          showPassword
          disabled
          onClick={() => {
            navigator.clipboard.writeText(temporaryPassword)
          }}
        />
        <PasswordRegenerateButton
          name="submit-regenerate-pass"
          design="tertiary"
          suffix={<Icon2 name="arrowPath" size="medium" />}
          onClick={() => setGeneratePassword()}
        />
        <PasswordCopyButton
          name="submit-copy-pass"
          copiedText={temporaryPassword}
          tooltipText='Copied'
        />
      </PasswordGenerator>
      <CreatePassword>
        <Or level={4}>Or</Or>
        <ProceedButton
          name="proceed"
          size="large"
          design="secondary"
          onClick={() => setCreateMode(!isCreateMode)}
          type="submit"
        >
          Create your own password
        </ProceedButton>
      </CreatePassword>
      <PasswordManual>
        <Form
          onSubmit={submitForm}
          initial={initial}
          validation={isCreateMode && yup.password.create}
        >
          {isCreateMode && (
            <>
              <Empty />
              <Field
                name="newPassword"
                placeholder="New password"
                type="password"
                showPassword
              />
              <Field
                name="newPasswordRepeat"
                placeholder="Repeat New password"
                type="password"
                showPassword
              />
            </>
          )}
          <ProceedButton
            name="proceed"
            size="large"
            design="primary"
            type="submit"
            loading={form.loading}
            disabled={form.loading}
          >
            Proceed
          </ProceedButton>
        </Form>
      </PasswordManual>
    </AuthLayout>
  )
}

const PasswordGenerator = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 32px;
`
const height = `48px`
const width = `48px`

const PasswordInput = styled(Input)`
  width: 100%;

  input {
    font-size: 2.4rem;
    line-height: 0;
    height: ${height};
    background-color: var(--color-gray-200);
    color: var(--color-gray-700);
    cursor: pointer;
  }
`

const PasswordCopyButton = styled(CopyButton)`
  margin-inline-start: 20px;

  &[data-design='tertiary'] {
    background-color: var(--color-gray-200);
    width: ${width};
    height: ${height};
  }
`
const PasswordRegenerateButton = styled(Button)`
  margin-inline-start: 20px;

  &[data-design='tertiary'] {
    background-color: var(--color-gray-200);
    width: ${width};
    height: ${height};
  }
`

const PasswordManual = styled.div``

const ProceedButton = styled(Button)`
  margin-top: 32px;
  background-color: var(--color-gray-200);
  width: 100%;
`

const CreatePassword = styled.div`
  position: relative;
  z-index: 100;
`

const Or = styled(Title)`
  text-align: center;
`

const Empty = styled.div`
  height: 32px;
`
