import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { axios } from 'services/axios'

const uploadImage = async (formData: FormData) => {
  const res = await axios.post<
    {},
    AxiosResponse<{ fileName: string; fileURL: string }>,
    FormData
  >('/copytrade-files/v1/upload ', formData)

  return res.data
}

export const useUploadImage = () => {
  return useMutation({
    mutationFn: uploadImage,
  })
}
