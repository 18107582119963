import styled from 'styled-components/macro'
import { Button } from 'ui/atoms'

import success from './img/success.png'
import successWeb from 'styles/img/successYellow.webp';

export const TransferSelect = styled.div`
  margin-bottom: 16px;
  width: 100%;
`

export const TransferDetails = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  gap: 20px;
`

export const TransferFooter = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const ExchangeRate = styled.div`
  margin-bottom: 20px;
  font-size: var(--body-font-size-small);
  color: var(--color-gray-600);
`

export const CompletedWrapper = styled.div`
  padding-top: 152px;
  min-height: 309px;
  display: grid;
  align-content: start;
  justify-items: center;
  background-image: url(${success});
  background-image: image-set(url(${successWeb}), url(${success}));
  background-size: 128px auto;
  background-position: top 24px center;
  background-repeat: no-repeat;
`

export const CompletedText = styled.div`
  margin-top: 8px;
  line-height: var(--body-line-height-regular);
  color: var(--color-gray-600);
`

export const CompletedButton = styled(Button)`
  margin-top: 24px;
  width: 140px;
`