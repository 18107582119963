import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { Gift } from '../types'

export const GET_GIFT_DETAILS_KEY = ['gift-details']

export const getGiftDetails = async (id: number) => {
  const res = await axios.get<Gift>('/gift-shop/v1/gift', {
    params: { giftID: id },
  })

  return res.data
}

export const useGetGiftDetails = (id: number) => {
  return useQuery({
    queryKey: GET_GIFT_DETAILS_KEY,
    queryFn: () => getGiftDetails(id),
    enabled: false
  })
}
