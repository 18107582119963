import styled from 'styled-components'
import { Button as UiButton } from 'ui/atoms'

import close from '../../images/close-banner.svg'
import backgroundMobile from '../../images/ib-banner-mobile.jpg'
import background from '../../images/ib-banner.jpg'

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding: 32px;
  min-height: 140px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px #2621190f;
  background-color: #ffcb3e;
  background-image: url(${background});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center right;

  @media (max-width: 880px) {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px 25px;
    background-position: center center;
  }

  @media (max-width: 680px) {
    margin-bottom: 16px;
    padding: 12px;
    min-height: 100px;
    background-image: url(${backgroundMobile});
    background-position: center right;
  }
`

export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
  width: 24px;
  height: 24px;
  background-image: url(${close});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 680px) {
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
  }
`

export const Title = styled.div`
  margin-inline-end: auto;
  font-size: 44px;
  line-height: 40px;
  font-weight: 500;

  @media (max-width: 680px) {
    font-size: var(--body-font-size-regular);
    line-height: 20px;
  }
`

export const Text = styled.div`
  margin-inline-start: 48px;
  max-width: 211px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);

  @media (max-width: 880px) {
    margin-inline-start: 0;
    grid-column: 1 / 2;
  }

  @media (max-width: 680px) {
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
  }
`

export const JoinButton = styled(UiButton)`
  margin-inline-start: 48px;
  min-width: 161px;

  @media (max-width: 880px) {
    margin-inline-start: 0;
    min-width: 123px;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
`