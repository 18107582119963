import { AccountTariffName } from 'pages/accounts/create/types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Account } from 'services/accounts/types'
import { Wallet } from 'services/wallet/get/types'
import { Select } from 'ui/atoms'
import { AccountLine, WalletLine } from 'ui/molecules'
import { mapAccountPlatform, mapAccountTariff } from 'ui/molecules/accountLines'

import * as Styled from './styled'

type Props = {
  accounts?: Account[]
  wallets?: Wallet[]
  defaultValue?: string
  label?: string
  onSelect: (value?: { id: string; type: 'wallet' | 'account' }) => void
}

export const AccountSelect: React.FC<Props> = ({
  accounts,
  wallets,
  onSelect,
  label,
  defaultValue = '',
}) => {
  const [t] = useTranslation()

  const [currentValue, setCurrentValue] = useState<string>(defaultValue)

  const handleSelect = (id: string, type: 'wallet' | 'account') => {
    setCurrentValue(id)
    
    onSelect({ id, type })
  }

  const options = useMemo(() => {
    if (!accounts && !wallets) return []

    const resultOpt: { label: string; value: string }[] = []

    wallets?.forEach((wallet) =>
      resultOpt.push({
        label: `Wallet ${wallet.currency}`,
        value: wallet.walletID,
      }),
    )

    accounts?.forEach((account) =>
      resultOpt.push({
        label: `${account.name || account.accountName || account.accountID} ${
          mapAccountPlatform[account.platform] || ''
        } ${
          mapAccountTariff[
            (account.tariff || account.tariffName) as AccountTariffName
          ] || ''
        }`.trim(),
        value: account.accountID,
      }),
    )

    return resultOpt
  }, [accounts, wallets])

  return (
    <>
      <Select
        value={currentValue}
        name="wallets-select"
        placeholder="Choose wallet or account"
        maxHeight={410}
        showOptions={false}
        label={label}
        autoComplete="off"
        customContent={
          <Styled.SelectContent>
            {wallets && wallets.length > 0 && (
              <div>
                <div className="flex justify-between">
                  <Styled.ContentTitle>{t('Wallets')}</Styled.ContentTitle>
                  <Styled.ContentTitle>
                    {t('Available for transfer')}
                  </Styled.ContentTitle>
                </div>
                <div>
                  {wallets?.map((wallet) => (
                    <Styled.SelectOption
                      key={wallet.walletID}
                      onClick={() => handleSelect(wallet.walletID, 'wallet')}
                    >
                      <WalletLine {...wallet} />
                    </Styled.SelectOption>
                  ))}
                </div>
              </div>
            )}
            {accounts && accounts.length > 0 && (
              <div>
                <div className="flex justify-between">
                  <Styled.ContentTitle>{t('Accounts')}</Styled.ContentTitle>
                  <Styled.ContentTitle>
                    {t('Available for transfer')}
                  </Styled.ContentTitle>
                </div>
                <div>
                  {accounts.map((account) => (
                    <Styled.SelectOption
                      key={account.accountID}
                      onClick={() => handleSelect(account.accountID, 'account')}
                    >
                      <AccountLine {...account} />
                    </Styled.SelectOption>
                  ))}
                </div>
              </div>
            )}
          </Styled.SelectContent>
        }
        options={options}
      />
    </>
  )
}
