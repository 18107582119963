import * as React from 'react'

type Props = {
  value: number
  currency?: string
  type?: 'symbol'
}

export const Money = ({ value, currency, type }: Props) => {  
  const formatedValue = toCurrency(value, currency || 'USD');

  if (type === 'symbol') return <>{formatedValue.replace(/[0-9, .]/g, '')}</>
  return <>{formatedValue}</>
}

export function toCurrency(value: number = 0, currency: string) {
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    }).format(value)
  } catch (error) {
    return `${currency} ${value}`;
  }
}
