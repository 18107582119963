import { AxiosError } from 'axios'
import { useStore } from 'effector-react'
import { $authSocialName, $registrationParams } from 'features/oath/model'
import { EAuthSocialName } from 'features/oath/types'
import { toPath } from 'features/router'
import { setAccessToken, setRefreshToken } from 'features/session'
import { mapResponseError } from 'libs/map'
import { path } from 'libs/path'
import {
  AuthLayout,
  AuthSectionText,
  AuthSectionTitle,
} from 'pages/auth/layout'
import { ucFirst } from 'pages/auth/register/oathRecordsCheck/utils'
import { useState } from 'react'
import { useRegisterBySocial } from 'services/registration/oath/mutations/useRegisterBySocial'
import { Field, Form } from 'ui/atoms'

import * as Styled from './styled'

const initial = {
  firstName: '',
  lastName: '',
  email: '',
}

type OathRecord = {
  firstName: string
  lastName: string
  email: string
}

export const OathRecordsCheck = () => {
  const registrationParams = useStore($registrationParams)
  const authSocialName = useStore($authSocialName)

  // если пользователь оказался на этой странице после регистрации через соцсети на лендинге
  const localRegistrationParams = JSON.parse(
    localStorage.getItem('registrationParams') || '{}',
  )

  const [errors, setErrors] = useState()

  const { mutateAsync, isSuccess } = useRegisterBySocial()

  const onSubmitOathRecord = async (params: OathRecord) => {
    try {
      const res = await mutateAsync({
        firstName:
          registrationParams?.firstName ||
          localRegistrationParams.firstName ||
          params.firstName,
        lastName:
          registrationParams?.lastName ||
          localRegistrationParams.lastName ||
          params.lastName,
        email:
          registrationParams?.email ||
          localRegistrationParams.email ||
          params.email,
        socialType:
          localRegistrationParams.socialType ||
          EAuthSocialName[authSocialName as keyof typeof EAuthSocialName],
        socialAccessToken:
          registrationParams?.socialAccessToken ||
          localRegistrationParams.socialAccessToken ||
          '',
        partnerKey: (localStorage.getItem('partnerId') as string) || '',
      })

      // @ts-ignore
      setAccessToken(res.identificationResult.accessToken)
      // @ts-ignore
      setRefreshToken(res.identificationResult.refreshToken)
    } catch (error) {
      if (error instanceof AxiosError) {
        // @ts-ignore
        setErrors(mapResponseError<OathRecord>(error?.response?.data))
      }
    } finally {
      localStorage.removeItem('registrationParams')
    }
  }

  if (isSuccess) {
    if (registrationParams?.email) {
      toPath(path.register.email.confirmSuccess())
    } else {
      toPath(path.register.email.confirm())
    }
  }

  return (
    <AuthLayout type="reset">
      <Styled.RegisterSuccessSection>
        <AuthSectionTitle level={2}>Personal records check</AuthSectionTitle>
        <AuthSectionText withTranslate={false}>
          {`${ucFirst(
            authSocialName,
          )} didn’t provide enough data to proceed. Please share your name
            in Latin characters.`}
        </AuthSectionText>
        <Styled.OathForm>
          <Form
            initial={initial}
            onSubmit={onSubmitOathRecord}
            // validation={yup.email.validate}
          >
            {!(
              registrationParams?.firstName || localRegistrationParams.firstName
            ) && (
              <Field
                name="firstName"
                label="First name"
                placeholder="Type your first name"
                // @ts-ignore
                error={errors?.fields?.firstName}
                autofocus
              />
            )}
            {!(
              registrationParams?.lastName || localRegistrationParams.lastName
            ) && (
              <Field
                name="lastName"
                label="Last name"
                placeholder="Type your last name"
                // @ts-ignore
                error={errors?.fields?.lastName}
              />
            )}
            {!(registrationParams?.email || localRegistrationParams.email) && (
              <Field
                name="email"
                label="Email"
                placeholder="Type your email"
                // @ts-ignore
                error={errors?.fields?.email}
              />
            )}
            <Styled.SubmitOathButton
              name="deposit"
              size="large"
              design="primary"
              type="submit"
            >
              Submit
            </Styled.SubmitOathButton>
          </Form>
        </Styled.OathForm>
      </Styled.RegisterSuccessSection>
    </AuthLayout>
  )
}
