import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import { axios } from 'services/axios'

import { GET_STRATEGY_FOR_INVESTOR } from '../get/useGetStrategyForInvestor'
import type { Strategy } from '../types'

const likeStrategy = async (params: {
  strategyID: string
  isLiked: boolean
}) => {
  await axios.patch('/copytrade/v1/strategy/like', { ...params })
}

export const useLikeStrategy = () => {
  return useMutation({
    mutationFn: likeStrategy,
    onSuccess(_, { isLiked }) {
      queryClient.setQueryData<Strategy>(
        GET_STRATEGY_FOR_INVESTOR,
        (oldData) =>
          oldData && {
            ...oldData,
            isLiked,
            numLikes: isLiked ? oldData.numLikes + 1 : oldData.numLikes - 1,
          },
      )
    },
  })
}
