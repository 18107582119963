import React from 'react'
import { usePostJoinPartner } from 'services/ib/mutation'
import { Button, Text, Title } from 'ui/atoms'

import * as S from './styled'

export const HaventIbBannerWidget: React.FC = () => {
  const { mutateAsync, isLoading } = usePostJoinPartner()

  return (
    <>
      <S.PromoBanner isWidget>
        <S.JoinIBBannerWrapper
          isWidget
          className="flex flex-col justify-between items-start h-full"
        >
          <div>
            <Title className="lg:w-[30rem] !font-medium" level={2}>
              Your Most Promising Partnership Program
            </Title>
            <Text className="mt-[1.2rem]">Get partner commission</Text>
            <Text>up to 42% of our income</Text>
          </div>

          <Button
            design="primary"
            onClick={() => mutateAsync()}
            loading={isLoading}
            disabled={isLoading}
            name="joinNow"
          >
            Become an IB
          </Button>
        </S.JoinIBBannerWrapper>
      </S.PromoBanner>
    </>
  )
}
