import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient } from '@tanstack/query-core'
import { QueryClientProvider } from '@tanstack/react-query'
import { useStore } from 'effector-react'
import { FacebookOAuthProvider } from 'facebook-oauth-react'
import { $userProfile } from 'features/user'
import { $userRole } from 'features/user/model'
import { PartnerContainer } from 'features/user/PartnerContainer'
import { UserContainer } from 'features/user/UserContainer'
import i18next from 'i18next'
import { env } from 'libs/constants'
import { Router } from 'pages/routing'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Zendesk from 'react-zendesk'
import { retryFn } from 'services/helpers/retryFn'
import { ThemeProvider } from 'styled-components/macro'
import { theme } from 'styles'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: retryFn,
      refetchOnWindowFocus: false,
    },
  },
})

export const App = () => {
  const userProfile = useStore($userProfile)
  const userRole = useStore($userRole)

  const [userDirection, setUserDirection] = useState(i18next.dir())

  const userLanguage =
    i18next.language.slice(0, 2) || localStorage.i18nextLng.slice(0, 2) || 'en'

  useEffect(() => {
    document.documentElement.setAttribute('lang', userLanguage)
    document.documentElement.setAttribute('dir', i18next.dir())

    i18next.on('languageChanged', (lang) => {
      document.documentElement.setAttribute('lang', lang)
      document.documentElement.setAttribute('dir', i18next.dir())

      setUserDirection(i18next.dir())
    })

    if (userProfile.userID) {
      // @ts-expect-error
      window.dataLayer.push({
        user: {
          auth_status: 1,
          country: userProfile.country,
          language: userLanguage,
          id: userProfile.userID,
          fname: userProfile.firstName,
          lname: userProfile.lastName,
          email: userProfile.email,
          verifyemail: userProfile.isEmailConfirmed ? 1 : 0,
          person_type: {
            trader: !!userProfile.userRoles?.isTrader,
            partner: !!userProfile.userRoles?.isPartner,
            nova: !!userProfile.userRoles?.isNova,
            investor: !!userProfile.userRoles?.isInvestor,
            strategist: userProfile.userRoles?.isStrategist,
          },
        },
      })
    }
  }, [userProfile])

  useEffect(() => {
    if (userRole === 'unauthorized') {
      // @ts-expect-error
      window.dataLayer.push({
        user: {
          auth_status: 0,
          language: userLanguage,
        },
      })
    }
  }, [userRole])

  return (
    <>
      <Helmet>
        <title>Headway – your reliable broker for smart Forex trading</title>
        <meta
          name="description"
          content="Full-service trading broker Headway offers a user-friendly ecosystem and accessible trading for everybody: from novice to professional traders."
        />
      </Helmet>

      <FacebookOAuthProvider
        appId={env.facebook.appId}
        appVersion={env.facebook.appVersion}
      >
        <GoogleOAuthProvider clientId={env.google.clientId}>
          <ThemeProvider theme={{ ...theme, dir: userDirection }}>
            <QueryClientProvider client={queryClient}>
              <UserContainer>
                <PartnerContainer>
                  <GTMProvider
                    state={
                      env.isDev
                        ? undefined
                        : {
                            id: 'GTM-TK9ZP3R',
                            dataLayer: {},
                            customDomain: `${window.location.protocol}//g.${window.location.host}`,
                          }
                    }
                  >
                    <Router />
                  </GTMProvider>
                </PartnerContainer>
              </UserContainer>
            </QueryClientProvider>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </FacebookOAuthProvider>

      <Zendesk defer zendeskKey="84faf6b6-64bf-41ed-ad24-ace5a7992428" />
    </>
  )
}
