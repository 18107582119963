import { useWindowSize } from '@uidotdev/usehooks'
import { path } from 'libs/path'
import { useTranslation } from 'react-i18next'

import * as Styled from './styled'

export const PublicTraderArea = () => {
  const [t, i18n] = useTranslation()

  const { width } = useWindowSize()

  const isMobileMode = width && width < 600

  return (
    <Styled.ContentContainer>
      <Styled.TextWrapper>
        <Styled.Title level={isMobileMode ? 2 : 1}>
          Earn more from trading
        </Styled.Title>
        <Styled.Text>
          {t(
            'In Headway Copytrade, investors pay for copying your trades – and you get extra profits for your positions.',
          )}
          <br />
          {t('Create a strategy, set a commission from $1, and keep trading.')}
          <br />
          <a
            className="font-medium text-bronze.500"
            href={path.copytrade.terms(i18n.language)}
            target="_blank"
            rel="noreferrer"
          >
            {t('Terms and conditions')}
          </a>
        </Styled.Text>
        <Styled.ListWrapper>
          <Styled.ListItem>
            {t('Open multiple strategies at once')}
          </Styled.ListItem>
          <Styled.ListItem>
            {t('Get an unlimited number of investors')}
          </Styled.ListItem>
          <Styled.ListItem>
            {t('Receive commission every month')}
          </Styled.ListItem>
        </Styled.ListWrapper>
        <Styled.Button to={path.register.entry()} name="create-strategy">
          Create strategy
        </Styled.Button>
      </Styled.TextWrapper>
    </Styled.ContentContainer>
  )
}
