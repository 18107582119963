import styled from 'styled-components/macro'

export const Plarform = styled.div`
  /* margin-inline-start: 8px;
  margin-inline-end: 4px; */
  padding: 0 4px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-700);
  background-color: var(--color-gray-300);
  font-weight: 500;
  border-radius: 4px;

  @media (max-width: 470px) {
    /* margin-left: 0;
    padding-left: 0; */
    /* margin-inline-start: 8px;
    margin-inline-end: 4px; */
  }
`


export const Tariff = styled.div`
  margin-inline-end: 12px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
`

export const Wrapper = styled.div<{ isLarge?: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
`
