import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import { axios } from 'services/axios'

import { GET_STRATEGY_FOR_INVESTOR } from '../get/useGetStrategyForInvestor'
import type { Strategy } from '../types'

const editProportion = async (params: {
  subscriptionID: string
  proportion: number
}) => {
  await axios.patch('/copytrade/v1/strategy/subscription', { ...params })
}

export const useEditProportion = () => {
  return useMutation({
    mutationFn: editProportion,
    onSuccess(_, { subscriptionID, proportion }) {
      queryClient.setQueryData<Strategy>(
        GET_STRATEGY_FOR_INVESTOR,
        (oldData) => {
          if (!oldData) {
            return
          }

          return (
            oldData && {
              ...oldData,
              subscriptions: oldData.subscriptions?.map((subscription) =>
                subscription.subscriptionID === subscriptionID
                  ? { ...subscription, proportion }
                  : subscription,
              ),
            }
          )
        },
      )
    },
  })
}
