import { createEvent, createStore, restore } from 'effector';
import { WithdrawalForm } from 'services/payments/get/WithdrawalFormTypes';
import { SourceItem } from 'services/payments/get/types';

export const accountChoosed = createEvent<string>();
export const accountChooseReset = createEvent();
export const $accountDeposit = restore<string>(accountChoosed, null).reset(accountChooseReset);


export const setCustomGatewayIdEvent = createEvent<string>();
export const resetCustomGatewayIdEvent = createEvent();
// 2791
export const $customGatewayId = restore<string>(setCustomGatewayIdEvent, '').reset(resetCustomGatewayIdEvent);


// OTP form
export const setOtpFormEvent = createEvent<boolean>();
export const resetOtpFormEvent = createEvent();
export const $otpForm = restore<boolean>(setOtpFormEvent, false).reset(resetOtpFormEvent);

// sbm result 
interface WithdrawalSbmState {
  id: string;
  status: 'pending' | 'error',
  amount: string;
  currency: string;
}

export const resetWithdrawalSbm = createEvent();
export const setWithdrawalSbm = createEvent<WithdrawalSbmState>();
export const $withdrawalSbm = restore<WithdrawalSbmState>(setWithdrawalSbm, null).reset(resetWithdrawalSbm);


export const pageDataResetEvent = createEvent();
export const setPageDataEvent = createEvent<WithdrawalForm | null>();
export const $pageDataStore = restore(setPageDataEvent, null).reset(pageDataResetEvent);