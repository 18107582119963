import { Text } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'

import { useTarget } from '../../hooks'

export const ChangeTargetModal = ({
  giftId,
  isOpen,
  onClose,
}: {
  giftId?: number
  isOpen: boolean
  onClose: () => void
}) => {
  const { mutateAsync, isLoading } = useTarget()

  const handleOk = async () => {
    await mutateAsync(giftId as number)

    onClose()
  }

  return (
    <ModalDialog
      title="Change the target gift"
      isOpen={isOpen}
      onOk={handleOk}
      okLabel="Yes"
      cancelLabel="No"
      cancelButtonType="secondary"
      disableOkBtn={isLoading}
      onCancel={onClose}
    >
      <Text>
        Are you sure you want to add a new target?
      </Text>
    </ModalDialog>
  )
}
