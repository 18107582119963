import { useStoreMap } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs'
import { A3codes, getCodeByCountry, getCountryByCode } from 'libs/A3codes'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Button, Icon } from 'ui/atoms'
import { DropdownSearch } from 'ui/molecules'

import { AuthLayout, AuthSectionText, AuthSectionTitle } from '../../layout'
import { proceed, submitCountry } from './model'

export const RegisterConfirmSuccess = () => {
  const [t] = useTranslation()
  const country = useStoreMap($userProfile, (user) => user.country)

  return (
    <AuthLayout type="register">
      <RegisterSuccessSection>
        <RegisterSuccessLogo name="registerSuccessIcon" size="large" />
        <AuthSectionTitle level={2}>
          You have successfully registered.
        </AuthSectionTitle>
        <AuthSectionText withTranslate={false}>
          {t('Our system detected that your country is')}{' '}
          <CountrySearch
            trigger={
              <CountryChoiceButton>
                {getCountryByCode(country) || 'unknown'}
              </CountryChoiceButton>
            }
            placeholder="Search"
            onSelect={(selectedCountry) => {
              const code = getCodeByCountry(selectedCountry)
              submitCountry(code)
            }}
            items={A3codes.map(({ country }) => country)}
          />
        </AuthSectionText>
        <RegisterSuccessDespositButton
          name="deposit"
          size="large"
          design="primary"
          onClick={() => {
            proceed(path.deposit.entry())
          }}
        >
          Deposit
        </RegisterSuccessDespositButton>
        <RegisterSuccessDashbaordButton
          name="deposit"
          size="large"
          design="tertiary"
          onClick={() => {
            proceed(path.dashboard.entry())
          }}
        >
          Go to dashboard
        </RegisterSuccessDashbaordButton>
      </RegisterSuccessSection>
    </AuthLayout>
  )
}

const RegisterSuccessSection = styled.div`
  text-align: center;
`

const RegisterSuccessLogo = styled(Icon)`
  margin: 0 auto;
  margin-bottom: 48px;

  &[data-size='large'] {
    width: 112px;
    height: 112px;
  }
`

const RegisterSuccessDespositButton = styled(Button)`
  width: 100%;
  margin-top: 80px;
`

const RegisterSuccessDashbaordButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`

const CountryChoiceButton = styled.span`
  font-size: 16px;
  color: var(--color-azure-500);
  text-decoration: underline;
  white-space: nowrap;
  position: relative;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

const CountrySearch = styled(DropdownSearch)`
  position: absolute;
  top: auto;
  inset-inline-end: 34px;
  width: 100%;
  max-width: 240px;
`
