import styled from 'styled-components/macro';

import { Carousel } from 'react-responsive-carousel';

export const CarouselUi = styled(Carousel)`
  height: fit-content;
 & .control-dots {
  width: auto !important;
  left: 49%;
 }

 & .slider {
  display: flex;
  justify-content: left !important;
 }
`;

export const DotsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  /* width: 100%; */
  display: flex;
  left: 49%;
  justify-content: center;
  gap: 4px;
`;

export const DotItem = styled.li`
   height: 6px; 
   border-radius: 100px;
   background-color: var(--color-gray-300);
   display: inline-block;
   cursor: pointer;
`;