import { createEvent, restore } from 'effector'

import { AccountTariffName } from '../types'

export const setActiveTariffName = createEvent<AccountTariffName>()

export const $activeTariffName = restore<AccountTariffName>(
  setActiveTariffName,
  'cent',
)
