import { useQuery } from '@tanstack/react-query'
import qs from 'qs';
import { axios } from 'services/axios'

export const GET_IB_SOCIAL_MEDIA_KEY = ['ib-social-media']

type Filter = Record<string, string | number | number[] | string[] | boolean | null>

export type SocialMedia = {
  id: number
  dimensions: string
  extension: string
  format: string
  name: string
  originLink: string
  previewLink: string
  sharedLink: string
  size: string
  title: string
}

const getSocialMedia = async (
  filters: Filter,
  page: number,
  perPage: number,
) => {
  const res = await axios.get<{
    items: SocialMedia[]
    sharedLink: string
    totalPages: number
    totalRecords: number
  }>('/ib-materials/v1/social-media', {
    params: { ...filters, page, perPage },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })

  return res.data
}

export const useGetSocialMedia = (
  filters: Filter,
  page: number,
  perPage: number,
) => {
  return useQuery({
    queryKey: [GET_IB_SOCIAL_MEDIA_KEY, { filters, page, perPage }],
    queryFn: () => getSocialMedia(filters, page, perPage),
    keepPreviousData: true,
    enabled: false,
  })
}
