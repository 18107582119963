import { useStore } from 'effector-react'
import { path } from 'libs/path'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useGetWidgetInfo, useRegister } from 'services/giftshop'
import { Button, Title } from 'ui/atoms'
import { useWindowSize } from 'usehooks-ts'

import { useLanguageChange } from '../../hooks'
import { $widgetInfo } from '../../model'
import * as Styled from './styled'

export const GiftshopBanner = ({
  inner = false,
  className,
}: {
  inner?: boolean
  className?: string
}) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()

  const widgetInfo = useStore($widgetInfo)

  const { refetch: refetchWidgetInfo } = useGetWidgetInfo()
  const { mutateAsync: register, isLoading } = useRegister()

  useLanguageChange(refetchWidgetInfo)

  const hasTarget = !!widgetInfo?.targetGift
  const targetCrowns = widgetInfo?.targetGift?.requiredCrowns.target
  const currentCrowns = widgetInfo?.targetGift?.requiredCrowns.current

  return (
    widgetInfo &&
    !(inner && !widgetInfo.isRegistered) && (
      <Styled.Container
        isActive={widgetInfo.isRegistered}
        isInner={inner}
        className={className}
      >
        {widgetInfo.isRegistered ? (
          <>
            <Styled.TotalCrowns>
              <Title level={width < 768 ? 3 : 2}>{widgetInfo.balance}</Title>
              <Styled.TotalText>{t('Total Crowns')}</Styled.TotalText>
            </Styled.TotalCrowns>
            <Styled.ProgressWrapper>
              <Title
                level={width < 768 ? 4 : 2}
                withTranslate={
                  !hasTarget ||
                  +(currentCrowns as string) !== +(targetCrowns as string)
                }
              >
                {hasTarget ? (
                  +(currentCrowns as string) < +(targetCrowns as string) ? (
                    'You are close. Keep trading!'
                  ) : (
                    <Trans
                      i18nKey="Congratulations! Claim your gift"
                      components={[
                        <Link
                          to={
                            path.giftshop.details(
                              `${widgetInfo.targetGift?.giftID}`,
                            ) || ''
                          }
                          className="text-bronze.500"
                        />,
                      ]}
                    />
                  )
                ) : (
                  'Select a gift to set your target'
                )}
              </Title>
              <div>
                <Styled.ProgressLine>
                  {hasTarget ? (
                    <Styled.ProgressValue
                      style={{
                        minWidth: `${
                          +(currentCrowns as string) >=
                          +(targetCrowns as string)
                            ? 100
                            : (+(currentCrowns as string) /
                                +(targetCrowns as string)) *
                              100
                        }%`,
                      }}
                    >
                      {currentCrowns}
                    </Styled.ProgressValue>
                  ) : (
                    t('No target yet')
                  )}
                </Styled.ProgressLine>
                <Styled.Range>
                  <div>0</div>
                  <div>{targetCrowns?.split('.')[0] || 0}</div>
                </Styled.Range>
              </div>
            </Styled.ProgressWrapper>
            <Styled.GiftWrapper
              giftImage={widgetInfo.targetGift?.imageURL}
              to={path.giftshop.entry()}
              onClick={
                inner
                  ? () => document.querySelector('#tabs')?.scrollIntoView()
                  : undefined
              }
            >
              {targetCrowns ? widgetInfo.targetGift!.title : t('Select gift')}
            </Styled.GiftWrapper>
          </>
        ) : (
          <>
            <Title level={width < 1220 ? 3 : 1}>
              Guaranteed gifts for your trading
            </Title>
            <Styled.Subtitle>
              {t('Get 2 Crowns after joining in!')}
            </Styled.Subtitle>
            <Styled.Buttons>
              <Button
                name="join-now"
                onClick={() => register()}
                loading={isLoading}
                disabled={isLoading}
                size={width < 1220 ? 'medium' : 'large'}
                design={width < 541 ? 'secondary' : 'primary'}
              >
                Join now
              </Button>
              <Link to={path.giftshop.entry()}>
                <Styled.LearnLink>{t('Learn more')}</Styled.LearnLink>
              </Link>
            </Styled.Buttons>
          </>
        )}
      </Styled.Container>
    )
  )
}
