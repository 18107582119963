import React, { useState } from 'react';

import { useGetLoginHistoryList } from 'services/auth/get/useGetLoginHistoryList';
import { Spinner2, Pagination } from 'ui/atoms';

import { Body } from './Body';
import { Head } from './Head';

const PER_PAGE_ITEMS = 12;


export const LoginHistoryTable = () => {
  const [page, setPage] = useState(1);

  const { data, isLoading, isPreviousData } = useGetLoginHistoryList({ page, perPage: PER_PAGE_ITEMS });

  if (!data || isLoading) return (
    <div className='flex justify-center w-full'>
      <Spinner2 />
    </div>
  );
  
  const onChange = (a: number) => {
    setPage(a)
  }

  return (
    <>
      <div>
        <table className='w-full'>
          <Head />
          <Body
            list={data.items}
            isLoading={isLoading}
            isPreviousData={isPreviousData}
          />
        </table>
      </div>

      <div>
        {data && Number(data?.totalPages) > 1 &&
          <Pagination
            pageSize={PER_PAGE_ITEMS}
            current={page}
            total={Number(data?.totalRecords)}
            onChange={ onChange }
          />
        }
      </div>
    </>
  )
}
