import { useEffect, useState } from 'react'

import { WithdrawalCardForm, WithdrawalGatewaysList } from './molecules';
import { Gateway } from 'services/money/get/types';
import { accountChooseReset } from '../depositChoice/model';
import { resetWithdrawalPageStore } from './model';

type Mode = 'list' | 'form';

export const WithdrawalPage: React.FC = () => {

  const [mode, setMode] = useState<Mode>('list');
  const [gateway, setGateway] = useState<Gateway>();

  const onSelectGateway = (gate: Gateway) => {
    setGateway(gate);
    setMode('form');
  }

  useEffect(() => {
    return () => {
      accountChooseReset()
      resetWithdrawalPageStore();
    }
  }, [])

  return (
    <div>
      {
        mode === 'list' 
          ? <WithdrawalGatewaysList
              onSelectGateway={onSelectGateway}
            /> 
          : <WithdrawalCardForm
              onBackToList={() => setMode('list')}
            /> 
      }
    </div>
  )
}
