import { format } from 'date-fns'
import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import { AccountTariffName } from 'pages/accounts/create/types'
import { BalanceOperation } from 'pages/copytrade/molecules/BalanceOperation'
import { StrategyHistory } from 'pages/copytrade/molecules/strategyHistory'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetStrategyForInvestor } from 'services/copytrade'
import { Subscription } from 'services/copytrade/types'
import { Icon, Icon2, Text, Title } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'
import { mapAccountPlatform, mapAccountTariff } from 'ui/molecules/accountLines'
import { TabContent, Tabs } from 'ui/molecules/tabs'
import { useWindowSize } from 'usehooks-ts'

import { NoCopying } from 'pages/copytrade/molecules/strategyHistory/NoCopying'

import calendarIcon from '../../images/calendar-icon.svg'
import userIcon from '../../images/user-icon.svg'
import {
  resetCopyingSettings,
  resetCopyingStrategy,
  setCopyingStrategy,
} from '../../model'
import { BackButton } from '../../molecules/backButton'
import { InteractButtons } from '../../molecules/interactButtons'
import { Perfomance } from '../../molecules/perfomance'
import { ProportionModal } from '../../molecules/proportionModal'
import { RiskLabel } from '../../molecules/riskLabel'
import { StopCopyingModals } from '../../molecules/stopCopyingModals'
import { StrategyDescription } from '../../molecules/strategyDescription'
import { TraderPreview } from '../../molecules/traderPreview'
import { formatNumber, getNumberColor } from '../../utils'
import * as Styled from './styled'

export const StrategyPreviewForInvestors = () => {
  const navigate = useNavigate()
  const { strategyID } = useParams()

  const [t] = useTranslation()

  const { userID } = useStore($userProfile)

  const [
    visibleProportionModalSubscription,
    setVisibleProportionModalSubscription,
  ] = useState<Subscription>()
  const [visibleStopCopyingModalId, setVisibleStopCopyingModalId] =
    useState<string>()
  const [isStartCopyingModalVisible, setIsStartCopyingModalVisible] =
    useState(false)

  const { width } = useWindowSize()
  const isMobileMode = width < 600

  const { data, isFetching } = useGetStrategyForInvestor(
    strategyID as string,
    !!userID,
  )

  const isEmpty = !data;

  const hasSubscriptions =
    data && data.subscriptions && data.subscriptions.length > 0

  const handleStartCopying = () => {
    if (!data) {
      return
    }

    if (+data.balance <= 0.1) {
      setIsStartCopyingModalVisible(true)
    } else {
      resetCopyingStrategy()
      resetCopyingSettings()
       
      setCopyingStrategy(data)

      navigate(path.copytrade.startCopying.selectAccount())
    }
  }

  // bc modals always opened
  useEffect(() => {
    document.body.style.overflow = visibleProportionModalSubscription
      ? 'hidden'
      : 'visible'
  }, [visibleProportionModalSubscription])
  useEffect(() => {
    document.body.style.overflow = visibleStopCopyingModalId
      ? 'hidden'
      : 'visible'
  }, [visibleStopCopyingModalId])

  return isFetching ? (
    <TabContent>loading...</TabContent>
  ) : isEmpty ? (
    <TabContent>not found</TabContent>
  ) : (
    <>
      <TabContent>
        <Styled.ContentContainer>
          <div className="flex justify-between">
            <BackButton to={path.copytrade.investorEntry()} />
            <InteractButtons
              isExtended
              disabledOptions={hasSubscriptions ? [] : ['like']}
              strategyData={data}
            />
          </div>
          <Styled.BlockWrapper>
            <Styled.TopInfoWrapper>
              <TraderPreview
                imageUrl={data.strategyImageLink}
                name={data.strategyTitle}
                size={isMobileMode ? 'medium' : 'large'}
              />
              <Styled.TopValuesWrapper>
                <div>
                  <Styled.TopInfoTitle>{t('Commission')}</Styled.TopInfoTitle>
                  <Styled.TopInfoValue isLarge>
                    ${formatNumber(data.commission)}
                  </Styled.TopInfoValue>
                </div>
                <div>
                  <Styled.TopInfoTitle>{t('Gain')}</Styled.TopInfoTitle>
                  <Styled.TopInfoValue
                    isLarge
                    color={getNumberColor(data.gain)}
                    className="prevent-rtl"
                  >
                    {+data.gain > 0 ? '+' : ''}
                    {formatNumber(data.gain)}%
                  </Styled.TopInfoValue>
                </div>
                <div>
                  <Styled.TopInfoTitle>{t('Active days')}</Styled.TopInfoTitle>
                  <Styled.TopInfoValue isLarge>
                    {data.activeDays}
                  </Styled.TopInfoValue>
                </div>
                <RiskLabel
                  risk={data.risk}
                  size={isMobileMode ? 'medium' : 'large'}
                />
              </Styled.TopValuesWrapper>
            </Styled.TopInfoWrapper>
            {data.strategyDescription && (
              <StrategyDescription text={data.strategyDescription} />
            )}
            <Styled.BottomInfoWrapper>
              <Styled.BottomValuesWrapper>
                <div>
                  <Styled.TopInfoTitle>{t('Currency')}</Styled.TopInfoTitle>
                  <Styled.TopInfoValue>{data.currency}</Styled.TopInfoValue>
                </div>
                <div>
                  <Styled.TopInfoTitle>{t('Leverage')}</Styled.TopInfoTitle>
                  <Styled.TopInfoValue>
                    {data.leverage === '210000000'
                      ? t('Unlimited')
                      : `1:${data.leverage}`}
                  </Styled.TopInfoValue>
                </div>
                <div>
                  <Styled.TopInfoTitle>{t('Platform')}</Styled.TopInfoTitle>
                  <Styled.PlatformTag>
                    {mapAccountPlatform[data.platform]}
                  </Styled.PlatformTag>
                </div>
                <div>
                  <Styled.TopInfoTitle>{t('Account type')}</Styled.TopInfoTitle>
                  <Styled.TopInfoValue>
                    {mapAccountTariff[data.tariffName]}
                  </Styled.TopInfoValue>
                </div>
                <div>
                  <Styled.TopInfoTitle>{t('Published')}</Styled.TopInfoTitle>
                  <Styled.TopInfoValue icon={calendarIcon} isLong>
                    {data.joiningDate}
                  </Styled.TopInfoValue>
                </div>
                <div>
                  <Styled.TopInfoTitle>{t('Investors')}</Styled.TopInfoTitle>
                  <Styled.TopInfoValue icon={userIcon}>
                    {data.numInvestors}
                  </Styled.TopInfoValue>
                </div>
              </Styled.BottomValuesWrapper>
              {!userID ? (
                <Styled.StartCopyingButton
                  type="button"
                  name="sign-up"
                  prefix={<Icon2 name="arrowLeftOnRectangle" />}
                  to={path.register.entry()}
                >
                  Sign up to copy
                </Styled.StartCopyingButton>
              ) : (
                <Styled.StartCopyingButton
                  type="button"
                  name="start-copying"
                  prefix={<Icon name="arrowsDiagonal" />}
                  onClick={handleStartCopying}
                >
                  Start copying
                </Styled.StartCopyingButton>
              )}
            </Styled.BottomInfoWrapper>
          </Styled.BlockWrapper>
          {hasSubscriptions ? (
            <Styled.BlockWrapper>
              <Title level={isMobileMode ? 3 : 2}>Investor information</Title>
              <Styled.CopyingGrid>
                {data.subscriptions?.map((subscription) => (
                  <Styled.CopyingInfoWrapper key={subscription.subscriptionID}>
                    <Styled.CopyingInfoValues>
                      <div>
                        <Styled.TopInfoTitle>
                          {t('Copying account')}
                        </Styled.TopInfoTitle>
                        <div className="flex items-center font-medium">
                          <Styled.AccountName>
                            {subscription.copyingAccount.accountName}
                          </Styled.AccountName>
                          <Styled.PlatformTag>
                            {
                              mapAccountPlatform[
                                subscription.copyingAccount.platform
                              ]
                            }
                          </Styled.PlatformTag>
                          <Styled.AccountTariff>
                            {
                              mapAccountTariff[
                                subscription.copyingAccount
                                  .tariffName as AccountTariffName
                              ]
                            }
                          </Styled.AccountTariff>
                        </div>
                      </div>
                      <div>
                        <Styled.TopInfoTitle>
                          {t('Proportion')}
                        </Styled.TopInfoTitle>
                        <Styled.EditProportionWrapper>
                          <Styled.TopInfoValue>
                            {subscription.proportion}
                          </Styled.TopInfoValue>
                          <Styled.EditButton
                            onClick={() =>
                              setVisibleProportionModalSubscription(
                                subscription,
                              )
                            }
                          >
                            {t('Edit')}
                          </Styled.EditButton>
                        </Styled.EditProportionWrapper>
                      </div>
                      {isMobileMode ? null : (
                        <div>
                          <Styled.TopInfoTitle>
                            {t('Copying since')}
                          </Styled.TopInfoTitle>
                          <Styled.TopInfoValue>
                            {format(
                              new Date(+subscription.startedTs * 1000),
                              'd LLL yyyy, h:mm',
                            )}
                          </Styled.TopInfoValue>
                        </div>
                      )}
                    </Styled.CopyingInfoValues>
                    <Styled.StopCopyingButton
                      type="button"
                      name="stop-copying"
                      design="secondary"
                      prefix={
                        <Icon
                          name="xMark"
                          size={isMobileMode ? 'small' : 'medium'}
                        />
                      }
                      size={isMobileMode ? 'small' : 'large'}
                      onClick={() =>
                        setVisibleStopCopyingModalId(
                          subscription.subscriptionID,
                        )
                      }
                    >
                      Stop copying
                    </Styled.StopCopyingButton>
                  </Styled.CopyingInfoWrapper>
                ))}
              </Styled.CopyingGrid>

              {visibleProportionModalSubscription && (
                <ProportionModal
                  initialValue={visibleProportionModalSubscription.proportion}
                  onCancel={() =>
                    setVisibleProportionModalSubscription(undefined)
                  }
                  onSuccess={() =>
                    setVisibleProportionModalSubscription(undefined)
                  }
                  subscriptionID={
                    visibleProportionModalSubscription.subscriptionID
                  }
                />
              )}
              {visibleStopCopyingModalId && (
                <StopCopyingModals
                  onClose={() => setVisibleStopCopyingModalId(undefined)}
                  subscriptionID={visibleStopCopyingModalId}
                />
              )}
            </Styled.BlockWrapper>
          ) : null}
          <Styled.BlockWrapper>
            <Perfomance />
          </Styled.BlockWrapper>
        </Styled.ContentContainer>

        <ModalDialog
          title="Copying is currently unavailable"
          isOpen={isStartCopyingModalVisible}
          onOk={() => setIsStartCopyingModalVisible(false)}
        >
          <Text>
            The strategy balance is below the minimum required for adding new
            investors. Please try again later.
          </Text>
        </ModalDialog>
      </TabContent>
      <Tabs
        fullHeight={false}
        classes="lg:mt-[20px] mt-[24px]"
        destroyUnactive
        items={[
          {
            label: 'Strategy history',
            children: data.isAvailableListsOperations 
              ? <StrategyHistory
                  onStartCopying={handleStartCopying}
                  isSkipRequest={!data.isAvailableListsOperations}
                />
              : <NoCopying onStartCopying={handleStartCopying} isMobile={ isMobileMode } />
          },
          {
            label: 'Balance operations',
            children: <BalanceOperation />,
            disabled: !data.isAvailableListsOperations,
          },
        ]}
      />
    </>
  )
}
