import styled, { css } from 'styled-components/macro'

import warning from '../../images/warning-icon.svg'

export const ParameterTitle = styled.div`
  margin-bottom: 6px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);

  @media (max-width: 599px) {
    font-size: var(--body-font-size-small);
  }
`

export const ParameterValue = styled.div`
  line-height: var(--body-line-height-regular);

  @media (max-width: 599px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-small);
  }
`

export const Wrapper = styled.div<{ isDisabled: boolean }>`
  border: 1px solid var(--color-gray-400);
  padding: 16px 24px;
  display: grid;
  grid-template-columns: repeat(4, minmax(max-content, 86px));
  justify-content: space-between;
  font-weight: 500;
  border-radius: 8px;

  @media (max-width: 599px) {
    padding: 16px;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      ${ParameterTitle} {
        color: var(--color-gray-500);
      }

      ${ParameterValue} {
        color: var(--color-gray-500);
      }
    `}
`

export const Warning = styled.div`
  margin-top: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 26px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  background-image: url(${warning});
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: left center;
`
