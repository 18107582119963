import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import * as React from 'react'
import styled from 'styled-components/macro'

import { path } from '../../../../libs/path'
import { yup } from '../../../../libs/validators'
import { Back, Button, Field, Form } from '../../../../ui/atoms'
import { AuthLayout, AuthSectionText, AuthSectionTitle } from '../../layout'
import { useEmailConfirmRepeatPage } from './hooks'
import { submitEmailVerifyRepeatForm } from './model'

export const RegisterEmailConfirmRepeat = () => {
  const { loading, error } = useEmailConfirmRepeatPage()
  const { email } = useStore($userProfile)

  return (
    <AuthLayout type="register">
      <AuthSectionTitle level={2}>Didn’t receive the Email?</AuthSectionTitle>
      <AuthSectionText>
        Check if it's in your spam folder or enter a valid email address again
      </AuthSectionText>
      <Form
        initial={{
          email,
        }}
        onSubmit={submitEmailVerifyRepeatForm}
        validation={yup.email.verify}
        error={error?.form}
      >
        <EmailConfirmRepeatField
          name="email"
          label="Email"
          placeholder="Type your Email"
          validate
          autofocus
          error={error?.fields?.email}
        />
        <EmailConfirmRepeatButton
          name="repeat-code"
          size="large"
          design="primary"
          type="submit"
          loading={loading}
        >
          Get a new code
        </EmailConfirmRepeatButton>
      </Form>
      <BackButton to={path.register.email.confirm()} />
    </AuthLayout>
  )
}

const EmailConfirmRepeatButton = styled(Button)`
  background-color: var(--color-gray-200);
  width: 100%;
  margin-top: 32px;
`

const EmailConfirmRepeatField = styled(Field)`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`

const BackButton = styled(Back)`
  width: 100%;
`
