import { useStore } from 'effector-react'
import * as React from 'react'

import {
  $currencies,
  $walletCreated,
  $wallets,
  createWallet,
  walletsMounted,
} from './model'

export const useWalletsWidget = () => {
  React.useEffect(() => {
    walletsMounted()
  }, [])

  const currencies = useStore($currencies)
  const wallets = useStore($wallets)

  const loading = useStore(createWallet.pending)

  const walletCreated = useStore($walletCreated)

  return {
    currencies,
    wallets,
    loading,
    walletCreated,
  }
}
