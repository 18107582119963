import { useStore } from 'effector-react'

import { service } from '../../../../../services'
import { $error, $isCreateMode, $temporaryPassword } from './model'

export const usePasswordPage = () => {
  const temporaryPassword = useStore($temporaryPassword)
  const changePasswordLoading = useStore(
    service.auth.postChangePassword.pending,
  )
  const isCreateMode = useStore($isCreateMode)
  const error = useStore($error)

  return {
    isCreateMode,
    temporaryPassword,

    form: {
      error,
      loading: changePasswordLoading,
    },
  }
}
