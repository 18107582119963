import UiTippy from '@tippyjs/react'
import styled, { css } from 'styled-components/macro'

export const Container = styled.div<{ size?: 'small' | 'large' }>`
  cursor: pointer;
  border: 1px solid var(--color-gray-400);
  padding: ${({ size }) => (size === 'large' ? '11px 12px' : '7px 12px')};
  display: inline-flex;
  justify-content: space-between;
  border-radius: 8px;
`

export const ValueField = styled.input`
  pointer-events: none;
  width: 100%;
  font: inherit;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Chevron = styled.div<{ isOpened: boolean }>`
  margin-inline-start: 8px;
  display: grid;
  place-items: center;
  transform: ${({ isOpened }) => (isOpened ? 'rotate(0)' : 'rotate(180deg)')};
  transition: transform var(--transition);
`

export const Tippy = styled(UiTippy)`
  padding: 8px;
  width: 100vw;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  border-radius: 8px;
  background-color: var(--color-gray-100);
  box-shadow: 0px 2px 4px -1px #2621190f, 0px 4px 6px -1px #2621191a;

  .tippy-content {
    padding: 0;
  }
`

export const OptionsList = styled.div`
  display: grid;
  max-height: 50vh;
  overflow-y: auto;
`

export const OptionWrapper = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  padding: 0 12px;
  min-height: 40px;
  min-width: 0;
  display: flex;
  align-items: center;
  transition-property: background-color, color;
  transition-duration: var(--transition);
  border-radius: 4px;

  &:hover {
    background-color: var(--color-gray-200);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      pointer-events: none;
      background-color: var(--color-gray-200);
      color: var(--color-sunray-500);
    `}
`
