import { useHref } from 'react-router-dom'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import { useTranslation } from 'react-i18next'

import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon'
import { Icon2, Text, TipyTooltip } from 'ui/atoms'
import { ModalDialog } from 'ui/molecules'

import { useGetPartnerPage } from 'services/ib/get/useGetPartnerPage';
import { path } from 'libs/path'

import * as Styled from './styled'
import { useIsMobile } from 'libs/hooks/useIsMobile'

export const ShareModal = ({
  isOpen,
  onClose,
  strategyID,
}: {
  isOpen: boolean
  onClose: () => void
  strategyID: string
  
}) => {
  const [t] = useTranslation();
  const isMobile = useIsMobile();
  const { data: partnerId } = useGetPartnerPage({ select: (data) => data?.widgetInfo?.partnerKey });
  
  const link = `${window.location.origin}${useHref(
    `${path.copytrade.strategyForInvestor(strategyID)}${partnerId ? `?hwp=${partnerId}` : ''}`,
    { relative: 'path' },
  )}`
  
  return (
    <ModalDialog
      title="Share your strategy"
      isOpen={isOpen}
      okLabel="Close"
      onOk={onClose}
      shouldCloseOnOverlayClick={true}
      onCancel={onClose}
      contentStyles={{
        width: isMobile ? '340px' : '580px'
      }}
    >
      <div className='mb-[13px] flex items-end'>
        { partnerId 
          ? <Icon2 name='checkBadge' className='mr-[6px] !text-eucalyptus.500' size='small' />
          : <Icon2 name='informationCircleSolid' className='mr-[6px] !text-azure.500' size='small' />
        }
        <Text withTranslate={false} level={3}>
          {t('Partner comission', { status:  partnerId ? t('Active'): t('Inactive')})}
        </Text>
        <TipyTooltip content={partnerId ? t('ib.partners.active') : t('ib.partners.inactive')} placement='auto'>
          <div>
            <Icon2 name='questionMarkCircle' className='ml-[6px] !text-gray.500 cursor-pointer' size='small' />
          </div>
        </TipyTooltip>
      </div>
      <Styled.Input
        value={link}
        // @ts-expect-error
        prefix={<Icon2 name="link" />}
        suffix={
          <CopyIcon
            tooltipText='Copied'
            copiedText={link}
            className='text-bronze.500'
          />
        }
      />
      <div className='mt-[20px] flex gap-[16px] justify-center'>
        <FacebookShareButton url={link}>
          <FacebookIcon round size={40} />
        </FacebookShareButton>
        <TelegramShareButton url={link}>
          <TelegramIcon round size={40} />
        </TelegramShareButton>
        <WhatsappShareButton url={link}>
          <WhatsappIcon round size={40} />
        </WhatsappShareButton>
        <LinkedinShareButton url={link}>
          <LinkedinIcon round size={40} />
        </LinkedinShareButton>
      </div>
    </ModalDialog>
  )
}
