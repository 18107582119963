import { splitArray } from 'libs/array'
import { path } from 'libs/path'
import { accountChoosed } from 'pages/money/depositChoice/model'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { SwiperSlide } from 'swiper/react'
import { Button, Icon, Money, Select, Title } from 'ui/atoms'
import { ModalDialog, Widget, WidgetSlider } from 'ui/molecules'

import { useWalletsWidget } from './hooks'
import { submitWallet } from './model'
import { Wallet } from './types'

const currenciesSymbols: Record<string, string> = {
  USD: '$',
  EUR: '€',
  IDR: 'Rp',
  THB: '฿',
  PHP: '₱',
  VND: '₫',
  MYR: 'RM',
}

export const WalletsWidget = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [selectValue, setSelectValue] = React.useState('')

  const { currencies, wallets, walletCreated, loading } = useWalletsWidget()

  React.useEffect(() => {
    if (walletCreated) {
      setIsModalVisible(false)
    }
  }, [walletCreated])

  return (
    <Widget
      extra={
        Boolean(currencies.length) && (
          <Button
            prefix={<Icon name="plus" size="small" />}
            name="create-account"
            design="secondary"
            size="small"
            onClick={() => setIsModalVisible(true)}
          >
            Create Wallet
          </Button>
        )
      }
    >
      <WalletsList items={wallets} hasAllWallets={!currencies.length} />
      <ModalDialog
        isOpen={isModalVisible}
        onOk={() => submitWallet(selectValue)}
        onCancel={() => setIsModalVisible(false)}
        disableOkBtn={loading}
        okLabel="Create"
        cancelLabel="Cancel"
        title="New wallet"
      >
        <Select
          label="Currrency"
          placeholder="Select currency"
          value={selectValue}
          options={currencies.map((currency) => ({
            value: currency,
            label: currency,
            hidden: true,
          }))}
          customContent={currencies.map((currency) => (
            <SelectOption onClick={() => setSelectValue(currency)}>
              <WalletIcon>
                {currenciesSymbols[currency] || (
                  <span className="text-sm">{currency}</span>
                )}
              </WalletIcon>
              {currency}
            </SelectOption>
          ))}
        />
      </ModalDialog>
    </Widget>
  )
}

//

type WalletsListProps = {
  items: Wallet[]
  hasAllWallets: boolean
}

const WalletsList = ({ items, hasAllWallets }: WalletsListProps) => {
  const [t] = useTranslation()

  const navigate = useNavigate()

  const handleDeposit = (id: string) => {
    accountChoosed(id)
    navigate(path.money.entry())
  }

  const getBalance = (balance?: number) => {
    if (!balance) return '';

    const [decimal, float] = String(balance).split('.');

    if (!float) return decimal;

    return `${decimal}.${float.slice(0,2)}`
  };

  return (
    <div style={{ marginTop: hasAllWallets ? '0' : 24, height: '100%' }}>
      {items.length > 4 ? (
        <WidgetSlider fullHeight={hasAllWallets}>
          {splitArray(items, 4).map((walletsSet, index) => (
            <SwiperSlide key={index}>
              {walletsSet.map((wallet) => (
                <WalletRow key={wallet.walletID}>
                  <WalletDeatails>
                    <WalletIcon>
                      {currenciesSymbols[wallet.currency] || (
                        <span className="text-sm">{wallet.currency}</span>
                      )}
                    </WalletIcon>
                    <WalletTitle level={4}>
                      Wallet {wallet.currency}
                    </WalletTitle>
                  </WalletDeatails>
                  <WalletBalance>
                    <Money
                      value={wallet.balance}
                      currency={wallet.currency}
                      type="symbol"
                    />{' '}
                    &nbsp;
                    {getBalance(wallet.balance)}
                  </WalletBalance>
                  <DepositLink onClick={() => handleDeposit(wallet.walletID)}>
                    {t('Deposit')}
                  </DepositLink>
                </WalletRow>
              ))}
            </SwiperSlide>
          ))}
        </WidgetSlider>
      ) : (
        items.map((wallet) => (
          <WalletRow key={wallet.walletID}>
            <WalletDeatails>
              <WalletIcon>
                {currenciesSymbols[wallet.currency] || (
                  <span className="text-sm">{wallet.currency}</span>
                )}
              </WalletIcon>
              <WalletTitle level={4}>Wallet {wallet.currency}</WalletTitle>
            </WalletDeatails>
            <RightBlock>
              <WalletBalance>
                <Money
                  value={wallet.balance}
                  currency={wallet.currency}
                  type="symbol"
                />
                {getBalance(wallet.balance)}
              </WalletBalance>
              <DepositLink onClick={() => handleDeposit(wallet.walletID)}>
                {t('Deposit')}
              </DepositLink>
            </RightBlock>
          </WalletRow>
        ))
      )}
    </div>
  )
}

const WalletRow = styled.div`
  margin-bottom: 8px;
  border: 1px solid var(--color-gray-300);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

const RightBlock = styled.div`
  display: grid;
  gap: 12px;
  justify-items: end;
`

const DepositLink = styled.span`
  cursor: pointer;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-small);
  color: var(--color-bronze-500);
  font-weight: 500;

  @media (max-width: 470px) {
    font-size: var(--body-font-size-medium);
  }
`

const WalletDeatails = styled.div`
  display: flex;
  align-items: center;
`

const WalletBalance = styled.div`
  font-weight: 500;
  line-height: 20px;
`

const WalletTitle = styled(Title)``

const WalletIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  color: #c5a24c;
  background-color: var(--color-sunray-100);
  margin-inline-end: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SelectOption = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  font-size: var(--body-font-size-regular);
  align-items: center;
  transition: background-color var(--transition);

  &:hover {
    background-color: var(--color-gray-200);
  }
`
