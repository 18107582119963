import { AccountSelect } from 'pages/money/molecules/newAccountSelect'
import styled from 'styled-components/macro'

import { AgreementCheckbox } from '../agreementCheckbox'

export const ContentContainer = styled.div`
  margin-top: 24px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    padding: 0;
  }
`

export const TextWrapper = styled.div`
  margin-bottom: 32px;
  display: grid;
  gap: 8px;

  @media (max-width: 599px) {
    margin-bottom: 24px;
    gap: 16px;
  }
`

export const FieldsWrapper = styled.div`
  max-width: 580px;
`

export const ButtonsWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  @media (max-width: 599px) {
    margin-top: 24px;
    justify-content: stretch;
    flex-direction: column-reverse;

    & > * {
      width: 100%;
    }
  }
`

export const Select = styled(AccountSelect)`
  margin: 0;
  max-width: 100%;
`

export const BottomWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 32px;
  align-items: flex-start;

  @media (max-width: 599px) {
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
`

export const CheckboxWrapper = styled(AgreementCheckbox)`
  margin-top: 32px;
`