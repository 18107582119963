import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { useGetUser } from 'services/user/get/useGetUser'
import { Background } from 'ui/atoms'

import { setUserInitial, setUserNonAuthRole } from './model'

export const UserContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { data, isInitialLoading, isLoading, isError, error } = useGetUser()

  const ifFirstData = useRef(true)

  useEffect(() => {
    if (!data && isLoading) return
    if (!data && !isLoading) {
      setUserNonAuthRole()
      return
    }
    if (!ifFirstData.current) return

    setUserInitial({ ...data.user, userRoles: data.userRoles })
    ifFirstData.current = false
  }, [data, isLoading])

  if (isInitialLoading) return <Background />

  return <>{children as React.ReactNode}</>
}
