
import React from 'react';
import formatFn from 'date-fns/format';

import { Td } from 'ui/molecules';
import { Icon2, Text } from 'ui/atoms';
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { getDateObjFromUnix } from 'libs/dateUtils';
import { BalanceOperationItem } from 'services/copytrade/get/types/BalanceOperations';

interface Props {
  item: BalanceOperationItem;
  isNoLastElem?: boolean;
  index: number;
}

export const OperationItem: React.FC<Props> = ({ item, isNoLastElem }) => {
  const isMobile = useIsMobile();

  const openTimeObj = getDateObjFromUnix(+item.closingAt);

  const isDeposit = item.operationType === 'OPERATION_TYPE_DEPOSIT';

  return (
    <tr
        className={ isNoLastElem ? 'border-b-[0.75px] border-gray.300 border-solid' : '' }
      >
        <Td width={isMobile ? undefined: '20%'}>
          <div className='flex items-center'>
              { isDeposit
                ? <Icon2
                  name='arrowUpCircle'
                  size='medium'
                  className='text-eucalyptus.500 mr-[8px] rotate-[135deg]'
                />
                : <Icon2
                  name='arrowDioganalCircle'
                  size='medium'
                  className='mr-[8px]'
                />
              }
              <Text weight='medium' level={isMobile ? 4 : 3 } withTranslate={ false }>
                {isDeposit ? 'Deposit' : 'Withdrawal'}
              </Text>
          </div>
        </Td>
  
        <Td width={isMobile ? undefined: '20%'}>
          {item.closingAt 
            ? (
            <>
              <Text level={isMobile ? 4 : 3} withTranslate={false}>
                {formatFn(openTimeObj, 'dd/MM/yyyy')}
              </Text>
              <Text level={isMobile ? 4 : 3} color='subtext'>
                {formatFn(openTimeObj, ' HH:mm:ss')}
              </Text>
            </>
          )
            : '-'
          }
        </Td>

        <Td>
          <Text
            level={isMobile ? 4 : 2}
            weight='medium'
            withTranslate={ false }
            className={`${isDeposit ? '!text-eucalyptus.500' : '!text-orange.500'} text-right`}
          >
            {isDeposit ? `$${item.amount}` : `-$${item.amount?.replace('-', '')}`}
          </Text>
        </Td>
    </tr>
  )
}
