import styled, { css } from 'styled-components/macro'
import { SelectWithModal } from 'ui/atoms/select/SelectWithModal'


export const SelectWrapper = styled(SelectWithModal)`
  max-width: 460px;

  @media (max-width: 650px) {
    margin: 16px 0;
  }
`

export const SelectContent = styled.div`
  padding: 8px 0;
  display: grid;
  gap: 16px;
`

export const SelectOption = styled.div`
  cursor: pointer;
  padding: 10px 16px;
  width: 100%;
  transition: background-color var(--transition);

  &:hover {
    background-color: var(--color-gray-200);
  }
`

export const VerifiedStatus = styled.div<{isVerified: boolean}>`
  padding: 0px 4px;
  border-radius: 4px;
  background-color: var(--color-opal-100);
  ${p => p.isVerified 
    ? css`
      color: #245947 !important;
      background-color: var(--color-eucalyptus-200);
    ` 
    : css`
      background-color: var(--color-opal-100);
      color: #B2423E !important;
    ` 
  }
`

///

export const Uploads = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const UploadError = styled.div`
  font-size: var(--body-font-size-medium);
  color: var(--color-opal-600);
`

export const UploadsFormat = styled.div`
  font-size: 14px;
  line-height: 21px;
`
