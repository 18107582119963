import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { path } from 'libs/path';
import { Spinner2 } from 'ui/atoms';

type Props = {
  payload: Record<string, any>;
  url: string;
  transactionID: string;
  isNewTab?: boolean;
}

export const PostFrame: React.FC<Props> = ({ url, payload, transactionID, isNewTab }) => {
  const buttonRef = useRef(null);
  // const { state = {} } = useLocation();
  const navigate = useNavigate();

  const state = {
    payload,
    url,
  }

  useEffect(() => {
    if (!buttonRef.current) return;
    if (!payload || !url) return;

    // @ts-ignore
    buttonRef.current?.click();
    if (isNewTab) {
      navigate(`/${path.money.resultDeposit()}?status=pending&amount=${payload?.Amount}&id=${transactionID}&currency=${payload?.Currency}`);
    }
   
  }, [payload]);

  if (!payload || !url) return;
  
  return (
    <>
      <div className='w-full flex justify-center items-center mt-[4rem]'>
      <Spinner2 size='large'/>
      <form method="post" action={state.url} id='form1' target={isNewTab ? '_blank' : '_self'}>
          <input type="hidden" name="Merchant" value={state.payload?.Merchant} />
          <input type="hidden" name="Currency" value={state.payload?.Currency} />
          <input type="hidden" name="Customer" value={state.payload?.Customer} />
          <input type="hidden" name="Reference" value={state.payload?.Reference} />
          <input type="hidden" name="Key" value={state.payload?.Key} />
          <input type="hidden" name="Amount" value={state.payload?.Amount} />
          <input type="hidden" name="Note" value={state.payload?.Note} />
          <input type="hidden" name="Datetime" value={state.payload?.Datetime} />
          <input type="hidden" name="FrontURI" value={state.payload?.FrontURI}/>
          <input type="hidden" name="BackURI" value={state.payload?.BackURI} />
          <input type="hidden" name="Language" value={state.payload?.Language} />
          <input type="hidden" name="Bank" value={state.payload?.Bank} />
          <input type="hidden" name="ClientIP" value={state.payload?.ClientIP} />
          <input type="hidden" name="CompanyName" value={state.payload?.CompanyName} />
          
        </form>
        <button
          type="submit"
          form="form1"
          value="Submit"
          ref={buttonRef}
          className='hidden'
        >
          Submit
        </button>
      </div>
    </>
  )
}
