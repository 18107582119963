
import React, { useCallback } from 'react';
import formatFn from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';

import { Td } from 'ui/molecules';
import { Icon2, Text } from 'ui/atoms';
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { getDateObjFromUnix } from 'libs/dateUtils';
import { StrategyHistoryItem } from 'services/copytrade/get/types/StrategyHistory';

interface Props {
  item: StrategyHistoryItem;
  isNoLastElem?: boolean;
  index: number;
}

export const HistoryItem: React.FC<Props> = ({ item, isNoLastElem }) => {
  const isMobile = useIsMobile();
  
  const openTimeObj = getDateObjFromUnix(+item.openedTs);
  const closeTimeObj = getDateObjFromUnix(+item.closedTs);

  const openTimeGMT = addMinutes(openTimeObj, openTimeObj.getTimezoneOffset());
  const closeTimeGMT = addMinutes(closeTimeObj, closeTimeObj.getTimezoneOffset());

  const getComissionOrSwap = useCallback((item: string) => {
    if (typeof item !== 'string') return '-';

    if (item === '0') return '-';

    return `$${item}`;
  }, []);

  const IsProfitPositive = +item.profit >= 0;

  return (
    <tr
        className={ isNoLastElem ? 'border-b-[0.75px] border-gray.300 border-solid' : '' }
      >
        <Td>
          <div className='flex items-center'>
              { item.action === 'ACTION_BUY' 
                ? <Icon2
                  name='arrowUpCircle'
                  size='medium'
                  className='text-eucalyptus.500 mr-[8px]'
                />
                : <Icon2
                  name='arrowDownCircle'
                  size='medium'
                  className='mr-[8px]'
                />
              }
              <Text weight='medium' level={isMobile ? 4 : 3 } withTranslate={ false }>{item.symbol}</Text>
          </div>
        </Td>
        <Td>
          <Text level={isMobile ? 4 : 3} withTranslate={false}>
            {formatFn(openTimeGMT, 'dd/MM/yyyy')}
          </Text>
          <Text level={isMobile ? 4 : 3} color='subtext'>
            {openTimeObj && formatFn(openTimeGMT, ' HH:mm:ss')}
          </Text>
        </Td>
        <Td>
          <Text level={isMobile ? 4 : 3}>{item.openPrice}</Text>
        </Td>
        <Td>
        <Text level={isMobile ? 4 : 3}>{item.lots}</Text>
        </Td>
        <Td>
          {item.closedTs 
            ? (
            <>
              <Text level={isMobile ? 4 : 3} withTranslate={false}>
                {formatFn(closeTimeGMT, 'dd/MM/yyyy')}
              </Text>
              <Text level={isMobile ? 4 : 3} color='subtext'>
                {closeTimeObj && formatFn(closeTimeGMT, ' HH:mm:ss')}
              </Text>
            </>
          )
            : '-'
          }
        </Td>
        <Td>
          <Text level={isMobile ? 4 : 3}>{item.closePrice}</Text>
        </Td>
        <Td>
          <Text level={isMobile ? 4 : 2} weight='medium'>{getComissionOrSwap(item.commission)}</Text>
        </Td>
        <Td>
          <Text level={isMobile ? 4 : 2} weight='medium'>{getComissionOrSwap(item.swaps)}</Text>
        </Td>
        <Td className='text-right'>
          <Text
            level={isMobile ? 4 : 2}
            weight='medium'
            withTranslate={ false }
            className={`${IsProfitPositive ? '!text-eucalyptus.500' : '!text-opal.500'} text-right`}
          >
            {IsProfitPositive ? `$${item.profit}` : `-$${item.profit.replace('-', '')}`}
          </Text>
        </Td>
    </tr>
  )
}
