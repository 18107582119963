interface StatusObjItem {
  iconName: 'bronzeStatus' | 'silverStatus' | 'goldStatus' | 'royalStatus';
  label: string;
  comission: string;
  step?: number;
}

export const statusInfoObj: Record<string, StatusObjItem> = {
  PARTNER_STATUS_BRONZE: {
    iconName: 'bronzeStatus',
    label: 'Bronze',
    comission: '30',
    step: 0,
  },

  PARTNER_STATUS_SILVER: {
    iconName: 'silverStatus',
    label: 'Silver',
    comission: '35',
    step: 1,
  },

  PARTNER_STATUS_GOLD: {
    iconName: 'goldStatus',
    label: 'Gold',
    comission: '38',
    step: 2,
  },

  PARTNER_STATUS_ROYAL: {
    iconName: 'royalStatus',
    label: 'Royal',
    comission: '42',
    step: 3,
  }
}