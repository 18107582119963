import { path } from 'libs/path';
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { BonusBanner, MetaTraders, Tabs } from 'ui/molecules'

import { AccountsList } from './list'
import { TransactionHistory } from './transactions'

export const AccountsPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const val = React.useMemo(() => {
    if (location.pathname === path.accounts.entry()) return 'My Accounts'
    if (location.pathname === path.accounts.moneyHistoryAccounts())
      return 'Transaction History'
  }, [location?.pathname])

  return (
    <AccountsContainer>
      <AccountsContent>
        <Tabs
          defaultValue={val}
          value={val}
          items={[
            {
              label: 'My Accounts',
              children: <AccountsList withDetails={true} />,
              onClick: () => navigate(path.accounts.entry()),
            },
            {
              label: 'Transaction History',
              children: <TransactionHistory />,
              onClick: () => navigate(path.accounts.moneyHistoryAccounts()),
            },
          ]}
        />
      </AccountsContent>
      <AccountsAside>
        <MetaTraders aside />
        <BonusBanner />
      </AccountsAside>
    </AccountsContainer>
  )
}

const AccountsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (min-width: 1050px) and (max-width: 1185px), (max-width: 1000px) {
    flex-direction: column;
  }
`

const AccountsContent = styled.div`
  width: 70%;

  @media (min-width: 1050px) and (max-width: 1185px), (max-width: 1000px) {
    width: 100%;
  }
`

const AccountsAside = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 20px;
  padding-top: 42px;

  @media (min-width: 1050px) and (max-width: 1185px), (max-width: 1000px) {
    padding-top: 0;
    width: 100%;
  }
`
