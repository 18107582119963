import styled, { css } from 'styled-components/macro'

import { Text as TextUi } from 'ui/atoms';

export const Text = styled(TextUi)`
  font-weight: 500;
`;

export const VerifiedStatus = styled.div<{ isVerified: boolean }>`
  padding: 0px 4px;
  border-radius: 4px;
  background-color: var(--color-opal-100);
  ${p => p.isVerified 
    ? css`
      color: #245947 !important;
      background-color: var(--color-eucalyptus-200);
    ` 
    : css`
      background-color: var(--color-opal-100);
      color: #B2423E !important;
    ` 
  }
`