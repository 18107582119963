import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { StrategyHistory } from './types/StrategyHistory'

export const GET_STRATEGY_HISTORY = 'GET_STRATEGY_HISTORY'

const getStrategyHistory = async ({ strategyID, page, perPage }: Options) => {
  const res = await axios.get<StrategyHistory>(
    '/copytrade/v1/strategy/trading-operations',
    {
      params: { strategyID, page, perPage },
    },
  )

  return res.data
}

interface Options {
  strategyID: string
  page: number
  perPage?: number
  enabled?: boolean
}

export const useGetStrategyHistory = ({
  strategyID,
  page,
  perPage = 20,
  enabled,
}: Options) => {
  return useQuery({
    queryKey: [GET_STRATEGY_HISTORY, strategyID, page, perPage],
    queryFn: () => getStrategyHistory({ strategyID, page, perPage }),
    keepPreviousData: true,
    enabled,
  })
}
