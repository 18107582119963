import React, { useEffect } from 'react';

import { resetMoneyHistoryEvent } from '../model';

export const useResetStore = () => {
  useEffect(() => {
    return () => {
      resetMoneyHistoryEvent();
    }
  }, [])
  
  return null;
}