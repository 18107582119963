import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';

import { GET_USER_SOCIALS_KEY } from '../get/useGetUserSocials';
import { SocialNameType } from '../get/type';

const socialTypeMap = {
  'apple': 'PUBLIC_API_SOCIAL_TYPE_APPLE',
  'google': 'PUBLIC_API_SOCIAL_TYPE_GOOGLE',
  'facebook': 'PUBLIC_API_SOCIAL_TYPE_FACEBOOK',
}


const postUnlinkSocial = async (social: SocialNameType) => {
  await axios.post('/auth/v1/unlink-social', {
    socialType: socialTypeMap[social],
  });
}

export const usePostUnlinkSocial = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, SocialNameType>({
    mutationFn: postUnlinkSocial,
    // функция срабатывает, если была ошибки
    // Заставляет сделать запрос еще раз
    retry: retryFn,
    onSuccess(data) {
      queryClient.invalidateQueries(GET_USER_SOCIALS_KEY);
    },
  })
}