import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import { MenuIconComponent } from 'ui/atoms/menuIcon'
import { Icons } from 'ui/atoms/menuIcon'
import { Link } from 'ui/atoms'

export type MenuItemProps = {
  className?: string
  icon?: Icons
  path?: string
  label: string
  isDisabled?: boolean
  items?: MenuItemProps[]
}

export interface Props {
  items: MenuItemProps[];
}

export const Menu: React.FC<Props> = ({ items }) => {
  return (
    <MenuContainer>
      {items.map(({ path, label, icon, isDisabled, items }, key) => {
        return (
          <MenuItem
            isDisabled={isDisabled}
            key={key}
            path={path}
            label={label}
            icon={icon}
            items={items}
          />
        )
      })}
    </MenuContainer>
  )
}

export const MenuItem = (props: MenuItemProps) => {
  const [t] = useTranslation()

  const { pathname: currentPath } = useLocation()

  const { isDisabled, icon, path, label, className, items } = props

  const isSelectedMenuItem = Boolean(path && currentPath.includes(path))

  const [isOpened, setIsOpened] = React.useState(false)

  return (
    <>
      <MenuRow
        className={className}
        data-disabled={isDisabled}
        $selected={isSelectedMenuItem}
      >
        <MenuLink
          as={path ? Link : 'div'}
          to={path}
          $selected={isSelectedMenuItem}
          onClick={items ? () => setIsOpened(!isOpened) : undefined}
        >
          {icon && (
            <MenuIcon
              name={icon}
              data-size="medium"
              selected={isSelectedMenuItem}
            />
          )}
          <MenuLabel>{t(label)}</MenuLabel>
          {items && (
            <Arrow
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              isRotated={!isOpened}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7698 12.7906C14.4713 13.0777 13.9965 13.0684 13.7094 12.7698L10 8.83208L6.29062 12.7698C6.00353 13.0684 5.52875 13.0777 5.23017 12.7906C4.93159 12.5035 4.92228 12.0287 5.20937 11.7302L9.45937 7.23017C9.60078 7.08311 9.79599 7 10 7C10.204 7 10.3992 7.08311 10.5406 7.23017L14.7906 11.7302C15.0777 12.0287 15.0684 12.5035 14.7698 12.7906Z"
                fill="#666666"
              />
            </Arrow>
          )}
        </MenuLink>
      </MenuRow>
      {items &&
        isOpened &&
        items.map((item, index) => (
          <MenuRow
            key={index}
            className={className}
            data-disabled={item.isDisabled}
            $selected={Boolean(item.path && currentPath === item.path)}
          >
            <MenuLink
              to={item.path || ''}
              $selected={Boolean(item.path && currentPath === item.path)}
              isInner
            >
              {item.icon && (
                <MenuIcon
                  name={item.icon}
                  data-size="medium"
                  selected={Boolean(item.path && currentPath === item.path)}
                />
              )}
              <MenuLabel>{t(item.label)}</MenuLabel>
            </MenuLink>
          </MenuRow>
        ))}
    </>
  )
}

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const MenuRow = styled.div<{ $selected?: boolean }>`
  width: 100%;
  background-color: ${(props) =>
    props.$selected ? 'var(--color-gray-200)' : `var(--color-gray-100)`};
  transition: var(--transition);
  border-radius: 3px;

  &[data-disabled='true'] {
    opacity: 0.25;

    &:hover {
      background-color: var(--color-gray-400);
    }
  }

  &:hover {
    background-color: var(--color-gray-200);
  }
`

const MenuLink = styled(Link)<{ $selected?: boolean; isInner?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-inline-start: ${({ isInner }) => (isInner ? '32px' : '10px')};
  color: ${(props) =>
    props.$selected ? 'var(--color-bronze-500)' : 'var(--color-gray-700)'};
  font-weight: 500;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
`

const MenuIcon = styled(MenuIconComponent)`
  margin-inline-end: 16px;
`

const MenuLabel = styled.span`
  /* white-space: nowrap; */
`

const Arrow = styled.svg<{ isRotated: boolean }>`
  margin-inline-start: auto;
  margin-inline-end: 10px;
  transform: ${({ isRotated }) => (isRotated ? 'rotate(180deg)' : 'none')};
  transition: transform var(--transition);
`
