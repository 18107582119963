import * as React from 'react'
import styled from 'styled-components/macro'

import * as icons from './images'

export type Icons = keyof typeof icons

type IconProps = {
  className?: string
  size?: 'small' | 'medium' | 'large'
  name: Icons
  onClick?: () => void
  color?: string
  disabled?: boolean
}

export const Icon: React.FC<IconProps> = ({
  size = 'medium',
  className,
  onClick,
  name,
  disabled,
}) => {
  return (
    <IconContainer
      className={className}
      name={name}
      onClick={onClick}
      data-size={size}
      data-disabled={disabled}
    />
  )
}

const IconContainer = styled.div<IconProps>`
  background-image: url('${(p) => icons[p.name]}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &[data-size='small'] {
    min-width: 18px;
    width: 18px;
    height: 18px;
  }

  &[data-size='medium'] {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  &[data-size='large'] {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }

  &[data-disabled='true'] {
    opacity: 0.25;
  }
`
