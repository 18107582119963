import styled from 'styled-components/macro'

import background from '../../images/first-participant.png'

export const Wrapper = styled.div`
  margin-top: 56px;
  margin-bottom: 56px;
  padding: 184px 32px 32px 32px;
  text-align: center;
  border-radius: 16px;
  background-color: var(--color-eucalyptus-200);
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: top 32px center;
  background-size: 128px auto;

  @media (max-width: 599px) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
`
