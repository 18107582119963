import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'ui/molecules';

import { path } from 'libs/path';
import { resetResultPageEvent, $resultWithdrawalPage } from './model';
import { TransactionStatus, TransactionStatusProps } from '../../molecules/transactionStatus/TransactionStatus';


const errorStatuses = ['declined', 'cancelled', 'rejected'];
const pendingStatuses = ['authorized', 'initialized'];


export const ResultWithdrawalDynamic = () => {
  const pageStore = useUnit($resultWithdrawalPage);
  
  const navigate = useNavigate();
  const [t] = useTranslation();

  const resultProps = () => {
    if (!pageStore) return;
    const obj = {
      status: pageStore.status || 'pending',
      title: '',
      subTitle: '',
    }

    if (errorStatuses.includes(obj.status)) obj.status = 'error';
    if (pendingStatuses.includes(obj.status)) obj.status = 'pending';

    if (obj.status === 'pending') {
      obj.title = pageStore?.title || t('Payment is in process');
      obj.subTitle = pageStore?.subTitle || ''
    }

    if (obj.status === 'error') {
      obj.title = pageStore?.title || 'Payment failed'
      obj.subTitle = pageStore?.subTitle ||
        'The transaction has failed. Please, try again or contact our Customer Care for more details.'
    }
    
    return obj as TransactionStatusProps;
  }

  useEffect(() => {
    if (!pageStore || !pageStore.id) {
      navigate(path.accounts.moneyHistoryAccounts(), { replace: true });
    }
  }, []);

  useEffect(() => {
    return () => {
      resetResultPageEvent();
    }
  }, []);


  if (!pageStore) return null;
  
  return (
    <Tabs
      defaultValue={'Withdrawal'}
      fullHeight={ false }
      items={[
        {
          label: 'Deposit',
          children: 
            <>
            </>,
          onClick: () => navigate(path.money.entry())
        },
        {
          label: 'Withdrawal',
          children: 
            <>
              <TransactionStatus
               {...resultProps()}
               currency={pageStore.currency}
               amount={pageStore.amount}
               id={pageStore.id}
               isLinkOpenNewTab={ pageStore.isLinkOpenNewTab }
               buttonLink={pageStore.buttonLink}
               buttonText={pageStore.buttonText}
             />
             </>,
          onClick: () => navigate(path.money.withdrawalTab())
        },
      ]}
    />
  )
}