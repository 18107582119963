import React from 'react';

import { PartnerStatuses, PartnerWidgetInfo } from 'services/ib/get/types';
import { Block, Title, Icon2 } from 'ui/atoms';
import { ProgressBlock } from './ProgressBlock';

import * as S from './styled';


interface Props {
  // amount: string;
  upgrading: PartnerWidgetInfo['upgrading'];
  confirmStatus?: PartnerWidgetInfo['confirmStatus'];
  currentStatus: PartnerStatuses;
}


export const StatusInformation: React.FC<Props> = ({  upgrading = {}, confirmStatus, currentStatus }) => {  
  return (
    <Block className='h-full'>
      <div className='flex'>
        <Title level={3}>Status information</Title>
        <Icon2 name='informationCircleSolid' className='ms-[4px] text-sunray.500' />
      </div>

      <ProgressBlock
        confirmStatus={confirmStatus }
        currentStatus={ currentStatus }
        upgrading={upgrading as PartnerWidgetInfo['upgrading']}
      />

    </Block>
  )
}
