import React from 'react';

import { usePostUnlinkSocial } from 'services/user/mutations/usePostUnlinkSocial';
import { useGetUserSocials } from 'services/user/get/useGetUserSocials';
import { SocialNameType } from 'services/user/get/type';
import { Text, Icon } from 'ui/atoms';

import { SocialPreloader } from './SocialPreloader';
import { GoogleLink } from './GoogleLink';
import { FacebookLogin } from './FacebookLink';

import * as S from './styles';

const socialsTitleMap = {
  'apple': 'Apple',
  'google': 'Google',
  'facebook': 'Facebook',
}

export const Social: React.FC = () => {
  const { data, isLoading } = useGetUserSocials();

  const { mutateAsync: unlinkSocialMutate } = usePostUnlinkSocial();

  const unLinkSocial = async(social: SocialNameType) => {
    try {
      await unlinkSocialMutate(social)
    } catch (error) {
      // TODO добавить обработчик ошибок
    }
  }

  const getSocialLinkComponent = (socialName: SocialNameType) => {
    if (socialName === 'google') return <GoogleLink />;
    if (socialName === 'facebook') return <FacebookLogin />;

    return (
      <Text
        className='cursor-pointer !text-sunray.500'
      >
        Link the account
      </Text>
    )
  }

  if (isLoading && !data) return <SocialPreloader />;
  
  return (
    <>
      {data?.socials.map((social, index) => (
        <S.InformationBlockItem key={`${social.name}_${index}`}>
         <div className='flex'>
            <Icon name={social.name} size="medium" />
            <Text className='ms-[0.8rem]'>{socialsTitleMap[social.name]}</Text>
          </div>

          {social.isLinked 
           ? <Text
                className='cursor-pointer !text-sunray.500'
                onClick={() => unLinkSocial(social.name)}
              >
                Unlink the account
              </Text>
           : getSocialLinkComponent(social.name)
          }
        </S.InformationBlockItem>
      ))}
    </>
  )
}
