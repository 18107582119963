import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useOnClickOutside } from 'usehooks-ts'

import { Icon2 } from '../icon2'
import { Input, InputProps, InputState } from '../input'

export type SelectOption = {
  value: string;
  label: string;
  hidden?: boolean;
}

export type SelectProps = {
  className?: string
  state?: InputState
  value?: string
  size?: 'small' | 'medium' | 'large';
  options?: SelectOption[]
  isHaveSearch?: boolean;
  defaultValue?: string;
  onSelect?: (value: string) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  customContent?: React.ReactNode;
  inputRef?: any;
  showOptions?: boolean
  errorMessage?: string;
  inputClasses?: string;
  maxHeight?: number
  listClassName?: string;
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  optionsWidth?: number
} & Pick<InputProps, 'name' | 'label' | 'placeholder' | 'disabled' | 'autoComplete' | 'suffix' | 'prefix' | 'inputMode'>

export const SelectWithSearch = ({
  name = 'default',
  className,
  options = [],
  label,
  state = 'hint',
  value,
  placeholder,
  disabled,
  size = 'medium',
  onSelect,
  onBlur,
  onChangeInput,
  isHaveSearch,
  errorMessage,
  customContent,
  listClassName,
  showOptions = true,
  maxHeight = 260,
  defaultValue = '',
  optionsWidth,
  suffix,
  inputClasses,
  inputRef,
  ...rest
}: SelectProps) => {
  const [t] = useTranslation()

  const ref = React.useRef(null)

  const [isDropdownVisible, setDropdownVisibility] = React.useState(false);
  const [search, setSearch] = useState(defaultValue);

  useOnClickOutside(ref, () => {
    if (isHaveSearch && isDropdownVisible) {
      const findedValue = options.find((v) => (v.label?.toLocaleLowerCase() === search?.toLocaleLowerCase()))?.value;
      onSelect?.(findedValue ?? '');
      if (!findedValue) setSearch('');
    }
    
    setDropdownVisibility(false)
  });
  
  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeInput?.(e);
    if (isHaveSearch) {
      setSearch(e.target.value);
      onSelect?.('');
    }
  }
  
  const optionsFiltered = React.useMemo(() => {
    const optFiltered: SelectOption[] = [];

    options.forEach(item => {
      if (!search) {
        optFiltered.push(item);

        return;
      }

      if (item.label?.toLocaleLowerCase().includes(search?.toLocaleLowerCase())) {
        optFiltered.push(item)
      }
    });

    return optFiltered;
  }, [options, search]);

  useEffect(() => {
    const findedValue = options.find((v) => (v.value === value))?.label;
    setSearch(findedValue ?? '');
  }, [value]);
  
  
  return (
    <SelectContainer className={className} ref={ref}>
      <SearchInput
        state={state}
        onClick={() => setDropdownVisibility(!isDropdownVisible)}
        onBlur={onBlur}
        errorMessage={ errorMessage }
        name={name}
        label={label}
        value={search}
        placeholder={placeholder}
        disabled={disabled}
        inputClasses={`${inputClasses} !pe-[36px]`}
        ref={inputRef}
        suffix={
          <>
            {suffix}
            <Shevron data-disabled={disabled} className='ms-3'>
              {isDropdownVisible ? (
                <Icon2 size={size} name="chevronUp" />
              ) : (
                <Icon2 size={size} name="chevronDown" />
              )}
            </Shevron>
          </>
        }
        {...rest}
        onChange={onChangeFn}
      />

      <OptionContainer data-visible={isDropdownVisible} id={'options_container'}>
        <OptionList style={{ maxHeight, width: optionsWidth }} className={ listClassName }>
          {showOptions &&
            optionsFiltered.map((option, key) => (
              <Option
                key={option.value}
                onClick={() => {
                  setSearch(option.label);
                  setDropdownVisibility(false)
                  onSelect?.(option.value)
                }}
                hidden={option.hidden}
              >
                {t(option.label)}
              </Option>
            ))}
          {customContent}
        </OptionList>
      </OptionContainer>
    </SelectContainer>
  )
}

const SearchInput = styled(Input)`
  input {
    cursor: pointer;
  }
`

const SelectContainer = styled.div``

const OptionContainer = styled.div`
  position: relative;
  top: 8px;
  transition: var(--transition);

  &[data-visible='false'] {
    opacity: 0;
    z-index: -1;
  }

  &[data-visible='true'] {
    opacity: 1;
    z-index: 100;
  }
`

const OptionList = styled.div`
  padding: 8px 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: #fff;
  box-shadow: var(--box-shadow-5);
  border-radius: 8px;
  min-height: 52px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Option = styled.div`
  padding: 9px 16px;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-200);
  }
`

const Shevron = styled.div`
  &[data-disabled='true'] {
    opacity: 0.25;
  }
`
