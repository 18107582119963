import styled from 'styled-components/macro'
import { DropdownSearch } from 'ui/molecules'

export const InformationBlock = styled.div`
  padding: 1.2rem;
  margin-top: 1.2rem;
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

export const InformationBlockItem = styled.div`
  width: 100%;
  height: 4.8rem;
  padding: 1.2rem 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
  gap: 30px;
`

export const LanguageSearch = styled(DropdownSearch)`
  position: absolute;
  top: auto;
  inset-inline-end: 34px;
  width: 100%;
  max-width: 240px;
`