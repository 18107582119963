import styled from 'styled-components/macro'

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Date = styled.div`
  margin-bottom: 12px;
  font-size: var(--body-font-size-medium);
  color: var(--color-gray-600);
`
