import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'

const transferBonus = async (data: {
  destinationType: 'ACCOUNT' | 'WALLET'
  destinationID: string
  amount: string
}) => {
  const transferData = await axios.post('/bonus111/v1/transfer', data)

  return transferData.data
}

export const useTransferBonus = () => {
  return useMutation({
    mutationFn: transferBonus,
    retry: retryFn,
  })
}
