import * as React from 'react'
import styled from 'styled-components/macro'

import { Icon2, Icons } from '../icon2'
import { Design } from '../types'

type Props = {
  icon?: Icons
  className?: string
  title: React.ReactNode
  children: React.ReactNode;
  textClasses?: string;
  titleClaases?: string;
  design?: Design
}

export const Banner = ({ className, title, children, icon, design, textClasses = '', titleClaases = '' }: Props) => {
  return (
    <Container className={className} data-design={design}>
      {icon && (
        <Image data-design={design}>
          <Icon2 name={icon} />
        </Image>
      )}
      <Content>
        <Title className={titleClaases}>{title}</Title>
        <Text className={textClasses}>{children}</Text>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 20px;
  gap: 12px;

  &[data-design='sunray'] {
    background-color: var(--color-sunray-100);
    border: 1px solid var(--color-sunray-300);
  }

  &[data-design='sunray-dark'] {
    background-color: var(--color-sunray-100);
    border: 1px solid var(--color-sunray-300);
  }

  &[data-design='opal'] {
    background-color: var(--color-opal-100);
    border: 1px solid var(--color-opal-300);
  }
`

const Image = styled.div`
  transform: translateY(4px);

  &[data-design='sunray'] {
    color: var(--color-sunray-500);
  }

  &[data-design='sunray-dark'] {
    color: var(--color-orange-500);
  }

  &[data-design='opal'] {
    color: var(--color-opal-500);
  }
`

const Content = styled.div``

const Title = styled.div`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;
`

const Text = styled.div`
  margin-top: 6px;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
`
