import { useMutation, useQueryClient } from '@tanstack/react-query'
import { setWidgetInfo } from 'pages/giftshop/model'
import { axios } from 'services/axios'

import { GET_GIFTSHOP_LIST_KEY } from '../get/useGetGiftList'
import { GiftList, WidgetInfo } from '../types'

const register = async () => {
  const res = await axios.post<{widgetInfo: WidgetInfo}>('/gift-shop/v1/register')

  setWidgetInfo(res.data.widgetInfo)

  return res.data.widgetInfo
}

export const useRegister = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: register,
    onSuccess() {
      queryClient.setQueryData<GiftList>(
        GET_GIFTSHOP_LIST_KEY,
        (oldData) =>
          oldData && {
            ...oldData,
            isRegistered: true
          },
      )
    },
  })
}
