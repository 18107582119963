import React from 'react';

import { Text } from 'ui/atoms';
import { Progress } from 'ui/molecules';

interface Props {
  startLabel?: string;
  endLabel?: string;
  curentProgressLabel?: string;
  progress: number;
}

export const ProgressIb: React.FC<Props> = ({ 
    startLabel, endLabel, curentProgressLabel, 
    progress,
}) => {
 
  return (
    <div className='w-full'>
      <Progress 
        progress={progress}
        wrapClasses="mt-[8px] p-[2px]"
        curentProgressLabel={curentProgressLabel}
      />
      <div className='flex justify-between mt-[8px]'>
        <Text level={3} className='!text-gray.600'>{startLabel}</Text>
        <Text level={3} className='!text-gray.600'>{endLabel}</Text>
      </div>
    </div>
  )
}
