import styled, { css } from 'styled-components/macro';

type ScrollState = 'initial' | 'end' | 'start' | 'all' | 'noBlur';

export const allScrollSidesClassName = 'allScrollSides'

export const TableWrapper = styled.div<{scrollState: ScrollState}>`
  /* mask: linear-gradient(90deg,#0000, #000 10% 90%, #0000); */
  /* mask: ${({ scrollState }) => {
    if (scrollState === 'initial') {
      return 'linear-gradient(90deg,#0000, #000 00% 85%, #0000);';
    }

    if (scrollState === 'all') {
      return 'linear-gradient(90deg, #0000, #000 10% 90%, #0000);'
    }

    if (scrollState === 'noBlur') {
      return 'none'
    }

    return 'none';
  }}; */
  overflow-x: scroll;
`;

