import React from 'react'

export const MainLogo = () => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.3062 9.98801L14.5391 31.9999H18.1296L26.0182 9.64502L22.3062 9.98801Z" fill='currentColor' strokeWidth={0} />
      <path d="M28.2893 13.5515L21.7812 31.9946H25.3761L32.0014 13.2085L28.2893 13.5515Z" fill='currentColor' strokeWidth={0} />
      <path d="M17.184 6.28345L15.8468 7.8551L10.8279 22.0825L11.184 14.2025L9.69478 9.98683L5.98272 9.64384L10.5978 22.7251L8.77436 27.9002L3.71206 13.5556L0 13.2126L6.62962 31.9987H7.32861H10.2201H10.9191L19.4286 7.88984L17.184 6.28345Z" 
        fill='currentColor'
        strokeWidth={0}
      />
      <path d="M19.2999 2.49208L17.1812 0L15.0625 2.49208L17.1812 4.98415L19.2999 2.49208Z" fill='currentColor' strokeWidth={0}/>
    </svg>
  )
}



