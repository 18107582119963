import { createEffect, createEvent, createStore, sample } from 'effector'

import { fx } from '../../../../../libs'
import { $verificationEditMod, getVerification } from '../../model'
import {
  ServerFile,
  VerificationFile,
  VerificationFileProps,
  VerificationFileResponse,
} from './types'

export const mounted = createEvent()

export const getVerificationFile = fx<ServerFile, VerificationFile>({
  method: 'GET',
  url: `/user-files/v1/verification`,
})

export const postVerificationFile = fx<
  VerificationFileProps,
  VerificationFileResponse
>({
  method: 'POST',
  url: `/user-files/v1/verification`,
})

export const upload = createEvent<VerificationFileProps>()

sample({
  clock: upload,
  fn: (formData) => ({ formData }),
  target: postVerificationFile,
})

// manage server files

export const $uploadFiles = createStore<ServerFile[]>([]).reset([mounted])

export const setUploadFile = createEvent<ServerFile>()

$uploadFiles.on(setUploadFile, (state, payload) => {
  const isExist = state.find((s) => s.uid === payload.uid)
  if (isExist) {
    return state.filter((s) => s.uid !== payload.uid)
  }
  return [...state, payload]
})

sample({
  clock: upload,
  fn: ({ file }) => {
    return {
      uid: file.uid,
      src: URL.createObjectURL(file),
      fileName: '',
    }
  },
  target: setUploadFile,
})

sample({
  clock: postVerificationFile.done,
  fn: (done) => {
    // @ts-ignore
    const body = JSON.parse(done.result.body) as VerificationFileResponse

    const fileName = body.fileName
    const uid = done.params.formData?.file.uid || ''

    return {
      query: {
        fileName,
        uid,
      },
    }
  },
  target: getVerificationFile,
})

sample({
  source: $uploadFiles,
  clock: getVerificationFile.done,
  fn: (files, done) => {
    const uid = done.params.query?.uid || ''
    const fileName = done.params.query?.fileName || ''
    const src = done.result.body.src || ''

    const payload = {
      uid,
      fileName,
      src,
    }

    const hasLocal = files.find((f) => f.uid === uid)

    if (hasLocal) {
      hasLocal.fileName = fileName
      return [...files]
    }

    return [...files, payload]
  },
  target: $uploadFiles,
})

sample({
  clock: getVerification.doneData,
  source: $verificationEditMod,
  fn: (isEditMod, doneData) => {
    if (doneData.body.status === 'PAVRS_REJECTED' || isEditMod) {
      return []
    }
    return doneData.body.fileNames
  },
  target: createEffect(async (fileNames: string[]) => {
    for (const fileName of fileNames) {
      getVerificationFile({
        query: {
          fileName,
          uid: `${Date.now() + Math.random()}`,
        },
      })
    }
  }),
})

// handle uploading status

export const $loading = createStore<string[]>([])

sample({
  source: $loading,
  clock: upload,
  fn: (files, { file }) => [...files, file.uid],
  target: $loading,
})

sample({
  source: $loading,
  clock: [postVerificationFile.done, postVerificationFile.fail],
  fn: (files, done) => {
    return files.filter((uid) => uid !== done.params.formData?.file.uid)
  },
  target: $loading,
})

// handle errors

export const $uploadingErrors = createStore<{ uid: string; error: string }[]>(
  [],
)

sample({
  source: $uploadingErrors,
  clock: postVerificationFile.fail,
  fn: (errors, fail) => {
    const uid = fail.params.formData?.file.uid || ''
    let error = ''

    if (fail.error.status === 413)
      error = 'The file is too large. Maximum file size: 10 MB'

    return [...errors, { uid, error }]
  },
  target: $uploadingErrors,
})
