import React, { useMemo } from 'react';
import { PartnerStatuses } from 'services/ib/get/types';
import { Block, Text, Icon2, Title } from 'ui/atoms';
import { Stepper } from 'ui/molecules';
import { statusInfoObj } from '../utils/statuses';
// import { Stepper } from 'ui/molecules/stepper/Stepper2';

interface Props {
  status: PartnerStatuses;
}

export const Status: React.FC<Props> = ({ status }) => {
  const statusObj = statusInfoObj[status];
  return (
    <Block className='grow'>
        <div className='flex items-center'>
          <Title level={3}>Status</Title>
          <Icon2 name='checkBadge' className='ms-[8px] text-sunray.500' />
        </div>

        <Text className='!text-gray.600 mt-[2.4rem]'>Current status</Text>
        <div className='flex mt-[1.2rem] items-end'>
            <Icon2 size='custom' name={statusObj.iconName} className='w-[44px] h-[44px] me-[1.2rem]'/>
            <Title level={1}>{statusObj.label}</Title>

            <div className='ms-auto'>
              <Text className='!inline !text-gray.600'>Commission</Text>
              <Text
                className='!font-medium !inline ms-2'
                withTranslate={ false }
              >
                {statusObj.comission}%
              </Text>
            </div>
        </div>

        <div className='mt-[3rem]'>
          <Stepper
            steps={[
              { label: 'Bronze', helperText: '30%' },
              { label: 'Silver', helperText: '35%' },
              { label: 'Gold', helperText: '38%' },
              { label: 'Royal', helperText: '42%' },
            ]}
            currentStep={statusObj.step as number}
          />
        </div>
    </Block>
  )
}
