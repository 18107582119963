export const onlyUnique = (value: string, index: number, self: string[]) =>
  self.indexOf(value) === index

export const splitArray = <T>(arr: T[], chunkSize: number) => {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [] as T[][])
}
