import React from 'react';

import { Text } from 'ui/atoms';

interface Props {
  heads: TableHeadItem[];
  trClasses?: string;
  textLevel?: 1 | 2 | 3 | 4;
}

export type TableHeadItem = {
  label: React.ReactNode;
  name?: string;
  align?: 'center' | 'left' | 'right' | 'justify' | 'char' | string;
  renderTh?: (val: any) => React.ReactNode;
  isSortable?: boolean;
  textLevel?: 1 | 2 | 3 | 4;
  textClass?: string;
} 

export const THead: React.FC<Props> = ({ heads, trClasses = '', textLevel = 2 }) => {
  return (
    <thead>
      <tr className={`border-b-[0.75px] border-gray.300 border-solid ${trClasses}`}>
        {heads.map((head, index) => (
          <th 
            scope="col" key={index}
            // @ts-ignore
            align={head.align || 'left'}
            className='px-[20px] py-[6px]'
          >
            { head.renderTh 
              ? head.renderTh(head.label)
              : <Text className={`!font-medium ${ head.textClass || ''}`} level={ head.textLevel || textLevel}>{head.label}</Text>
            }
          </th>
        ))}
      </tr>
    </thead>
  );
}