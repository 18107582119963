import { createStore, createEvent } from "effector";

export interface SortObjParams {
  name: string;
  direction: 'asc' | 'desc';
}

export const changeSortEvent = createEvent<SortObjParams>();
export const sortResetEvent = createEvent();

export const $tableSortStore = createStore<SortObjParams | null>(null);

$tableSortStore.on(changeSortEvent, (state, newSort) => newSort);
$tableSortStore.reset(sortResetEvent);