
import React from 'react';
import { addDays } from 'date-fns';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'libs/hooks/useIsMobile';
import { toUnixTimeString } from 'libs/dateUtils';
import { Button, DatePicker, Select } from 'ui/atoms';

import {
  setStatusesEvent,
  Statuses,
  setTimelineEvent,
  setTransactionsTypeEvent,
  $moneyHistoryFilter,
} from 'pages/money/transactionsNew/model/moneyHistoryModel';

import * as Styled from './styled';
import { AccountsSelect } from './AccountsSelect';

type Props = {
  type?: 'exchanger';
}

export const Filters: React.FC<Props> = ({ type }) => {
  const isMobile = useIsMobile();
  const isExchanger = type === 'exchanger';

  const [t] = useTranslation();

  const { transactionType, statuses } = useUnit($moneyHistoryFilter);

  const [dateSelectValue, setDateSelectValue] = React.useState('');
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)
  }

  const changeDates = () => {
    if (startDate && endDate) {
      setDateSelectValue('custom')
    }
  }

  const resetDates = () => {
    setStartDate(null)
    setEndDate(null)
    setDateSelectValue('all')
  }

  React.useEffect(() => {
    // todo: перенести в хук @_@
    if (dateSelectValue) {
      if (dateSelectValue !== 'custom') {
        setStartDate(null)
        setEndDate(null)
      }

      setTimelineEvent({
        from:
          dateSelectValue === 'all'
            ? '0'
            : dateSelectValue === 'custom'
            ? toUnixTimeString(startDate as Date)
            : toUnixTimeString(addDays(new Date(), -dateSelectValue)),
        to:
          dateSelectValue === 'custom'
            ? toUnixTimeString(endDate as Date)
            : toUnixTimeString(new Date()),
      })
    }
  }, [dateSelectValue])

  return (
    <Styled.Wrapper>
      <Select
        placeholder="Transaction Type"
        value={ transactionType }
        autoComplete="off"
        options={[
          { label: t('All types'), value: 'all' },
          { label: t('Deposit'), value: 'deposit' },
          { label: t('Withdrawal'), value: 'withdrawal' },
          { label: t('Internal transfer'), value: 'internal_transfer' },
        ]}
        onSelect={(setTransactionsTypeEvent as (val: string) => void)}
      />
      <Select
        value={dateSelectValue}
        placeholder="Timeframe"
        autoComplete="off"
        options={[
          { label: t('All time'), value: 'all' },
          { label: t('Last week'), value: '7' },
          { label: t('Last month'), value: '30' },
          { label: t('Last 3 months'), value: '90' },
          { label: t('Custom date'), value: 'custom', hidden: true },
        ]}
        onSelect={setDateSelectValue}
        optionsWidth={isMobile ? undefined : 375}
        maxHeight={690}
        customContent={
          <Styled.DatePickerWrapper>
            <DatePicker
              selected={startDate}
              // @ts-expect-error
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              // @ts-expect-error
              selectsRange
            />
            {startDate && endDate && (
              <Styled.PickerButtons>
                <Button
                  name="cancel"
                  size="medium"
                  design="tertiary"
                  onClick={resetDates}
                >
                  Cancel
                </Button>
                <Button name="cancel" size="medium" onClick={changeDates}>
                  Select
                </Button>
              </Styled.PickerButtons>
            )}
          </Styled.DatePickerWrapper>
        }
      />

      {!isExchanger && <AccountsSelect />}

      <Select
        placeholder="Status"
        autoComplete="off"
        inputClasses='lg:!w-auto !min-w-[110px]'
        value={ statuses }
        onSelect={(val) => setStatusesEvent(val as Statuses | 'all')}
        options={[
          { label: t('Any status'), value: 'all' },
          { label: t('Pending'), value: 'OS_PENDING' },
          { label: t('Missed information'), value: 'OS_MISSING_INFORMATION' },
          { label: t('Declined'), value: 'OS_REJECTED' },
          { label: t('Approved'), value: 'OS_SUCCESS' },
        ]}
      />
    </Styled.Wrapper>
  )
}
