import React from 'react';
import { useWindowSize } from 'usehooks-ts';

import { Icon2 } from '../icon2'
import * as Styled from './styled'


interface Props {
  pageSize: number;
  current: number;
  total: number;
  perPageVariants?: number[];
  showSizeChanger?: boolean;
  onChangePerPage?: (pageSize: number) => void;
  onChange: (current: number, pageSize: number) => void;
  classNames?: string;
}

const defaultsPerPage = [20, 40, 60];

export const Pagination: React.FC<Props> = ({
  showSizeChanger,
  classNames = '',
  perPageVariants = defaultsPerPage,
  onChangePerPage,
  ...props
}) => {
  const { width } = useWindowSize();
  const isMobileMode = width < 500;

  return (
    <Styled.Wrapper className={classNames}>
      {showSizeChanger ? (
        <Styled.SizeChanger>
          {perPageVariants.map((count) => (
            <Styled.Page
              key={count}
              active={count === props.pageSize}
              onClick={() => onChangePerPage?.(count)}
            >
              {count}
            </Styled.Page>
          ))}
        </Styled.SizeChanger>
      ) : null}
      <Styled.Pagination
        fullwidth={!showSizeChanger}
        showLessItems={ isMobileMode }
        itemRender={(current, type) => {
          switch (type) {
            case 'prev':
              return (
                <Styled.ArrowButton
                  name="prev"
                  size="small"
                  design="secondary"
                  suffix={<Icon2 name="chevronDown" size="small" />}
                />
              )
            case 'next':
              return (
                <Styled.ArrowButton
                  name="next"
                  size="small"
                  design="secondary"
                  suffix={<Icon2 name="chevronDown" size="small" />}
                />
              )
            case 'page':
              return (
                <Styled.Page active={current === props.current}>
                  {String(current)}
                </Styled.Page>
              )
            case 'jump-next':
              return <Styled.Page>...</Styled.Page>
          }

          return <div />
        }}
        {...props}
      />
    </Styled.Wrapper>
  )
}
