import React, { useState } from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";

import { GiftsHistoryOperationType } from 'services/giftshop/get/types'
import { useGetGiftsHistory } from 'services/giftshop/get/useGetGiftsHistory'
import { Spinner2, SpinnerWithBlur, Text } from 'ui/atoms'
import { Pagination } from 'ui/atoms/pagination/Pagination'

import { useLanguageChange } from '../hooks'
import coinImg from '../images/color-coin.png'
import coinImgWeb from '../images/color-coin.webp';
import { Filter } from './Filter'
import { Table } from './Table'

export const ActivityHistory: React.FC = () => {
  const [searchParams, setSearchParams] = useState<{
    page: number
    perPage: number
    typeOperation: GiftsHistoryOperationType
  }>({
    page: 1,
    perPage: 10,
    typeOperation: 'ALL',
  })

  const {
    data,
    isFetching,
    isRefetching,
    refetch: refetchGiftHistory,
  } = useGetGiftsHistory({
    page: searchParams.page,
    perPage: searchParams.perPage,
    typeOperation: searchParams.typeOperation,
  })

  useLanguageChange(refetchGiftHistory)

  const onChangePage = (newPage: number) => {
    setSearchParams({ ...searchParams, page: newPage })
  }

  const onChangeType = (newType: GiftsHistoryOperationType) => {
    setSearchParams({ ...searchParams, typeOperation: newType, page: 1 })
  }

  if (!data && isFetching) {
    return (
      <div className="w-full flex justify-center">
        <Spinner2 size="large" />
      </div>
    )
  }

  return (
    <div className="md:w-full">
      <Filter
        activeType={searchParams.typeOperation}
        onChangeType={onChangeType}
      />

      {data?.totals?.sumCrowns && (
        <div className="mt-[36px] flex gap-[40px] w-full justify-center">
          <Text weight="medium">Total Crowns</Text>
          <Text
            withTranslate={false}
            weight="medium"
            className="flex items-center"
          >
            <LazyLoadImage
              src={coinImg}
              srcSet={coinImgWeb}
              alt="coin"
              className="w-[16px] h-[16px] mr-[8px]"
            />
            {data.totals?.sumCrowns}
          </Text>
        </div>
      )}

      {data?.items && (
        <div className="mt-[24px] relative w-[calc(100vw-50px)] md:w-full">
          <SpinnerWithBlur size="xl" isShow={isRefetching} />
          <Table items={data?.items} />
        </div>
      )}

      {data && Number(data?.totalPages) > 1 && (
        <Pagination
          pageSize={searchParams.perPage}
          current={searchParams.page}
          total={Number(data?.totalRecords)}
          onChange={onChangePage}
          classNames="mt-[2.4rem] w-full lg:max-w-[56rem] lg:mx-auto"
        />
      )}
    </div>
  )
}
