import { logout } from 'features/session'
import { useGetSidebar } from 'features/sidebar/useGetSidebar'
import { path } from 'libs/path'
import React, { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { langs } from 'ui/constants'
import { Menu } from 'ui/molecules'
import { LogoutItem, LogoutLink } from 'ui/templates/generic'

import * as Flags from '../language/images'
import * as Styled from './styled'

const defaultLang = langs[0]

export const MobileMenu = forwardRef<HTMLDivElement, { onClose: () => void }>(
  ({ onClose }, ref) => {
    const [isLanguagePanel, setLanguagePanel] = useState(false)

    const items = useGetSidebar()

    const navigate = useNavigate()

    const { i18n, t } = useTranslation()

    const activeLanguage = langs.some((lang) => lang.value === i18n.language)
      ? i18n.language
      : defaultLang.value

    return (
      <Styled.Container ref={ref}>
        {!isLanguagePanel ? (
          <Styled.MenuWrapper>
            <div>
              <div>
                <Styled.HeaderUser />
                <Menu items={items} />
              </div>

              <Styled.ButtonMenuBlock>
                <Styled.ButtonMenu
                  onClick={() => navigate(path.money.entry())}
                  color="#DBB454"
                >
                  <Styled.ButtonMenuText>
                    <Styled.ButtonIcon name="arrowDownOnSquare" />
                    <div>Deposit</div>
                  </Styled.ButtonMenuText>
                  <Styled.ButtonIcon name="arrowLongRigth" />
                </Styled.ButtonMenu>
              </Styled.ButtonMenuBlock>
            </div>

            <div>
              <div
                className="flex justify-between items-center h-[40px] px-[10px]"
                onClick={() => setLanguagePanel(true)}
              >
                <Styled.ButtonMenuText>
                  <Styled.ActiveLang
                    style={{
                      backgroundImage: `url(${
                        // @ts-expect-error
                        Flags[
                          langs.find((lang) => lang.value === activeLanguage)
                            ?.icon || 'englishFlag'
                        ]
                      })`,
                    }}
                  >
                    {activeLanguage.toLocaleUpperCase()}
                  </Styled.ActiveLang>
                </Styled.ButtonMenuText>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z"
                    fill="#666666"
                  />
                </svg>
              </div>
              <LogoutItem
                onClick={() => {
                  logout()
                  onClose()
                }}
              >
                <LogoutLink
                  icon="arrowRightOnRectangle"
                  path={''}
                  label="Logout"
                />
              </LogoutItem>
            </div>
          </Styled.MenuWrapper>
        ) : (
          <Styled.LangBlock>
            <div
              className="mb-[16px] flex items-center h-[36px] px-[10px]"
              onClick={() => setLanguagePanel(false)}
            >
              <Styled.ButtonIcon name="arrowLeftOutline" size="small" />
            </div>

            {langs.map((lang, index) => (
              <Styled.LangItem
                key={index}
                onClick={() => {
                  i18n.changeLanguage(lang.value)
                  setLanguagePanel(false)
                }}
                style={{
                  backgroundImage: `url(${
                    // @ts-expect-error
                    Flags[lang.icon]
                  })`,
                }}
              >
                <div>{t(lang.label, { lng: lang.value })}</div>
              </Styled.LangItem>
            ))}
          </Styled.LangBlock>
        )}
      </Styled.Container>
    )
  },
)
