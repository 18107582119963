import { path } from 'libs/path'
import { MouseEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { Gift } from 'services/giftshop'
import { Button, Icon2 } from 'ui/atoms'

import { useTarget } from '../../hooks'
import * as Styled from './styled'

export const GiftCard = (
  props: Gift & {
    onClick?: (event: MouseEvent<HTMLElement>) => void
    onTarget?: () => void
  },
) => {
  const { mutateAsync, isLoading } = useTarget()

  const [isActive, setIsActive] = useState(false)

  return (
    <Styled.Wrapper
      isTarget={props.isTarget}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <Styled.Image src={props.imageURL} loading="lazy" alt={props.title} />
      <Styled.Price value={props.price} />
      <Styled.Name level={3} withTranslate={false}>{props.title}</Styled.Name>
      {isActive ? (
        <Styled.Buttons>
          <Link
            to={path.giftshop.details(`${props.id}`)}
            onClick={props.onClick}
          >
            <Button
              name="details"
              design="secondary"
              size="large"
              className="w-full"
            >
              Details
            </Button>
          </Link>
          {!props.isTarget ? (
            <Button
              name="target"
              prefix={<Icon2 name="star" />}
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                // не лучшее в плане универсальности решение,
                // но вставлять по две модалки в каждую карточку ещё хуже :/
                if (props.onClick) {
                  props.onClick(event)
                } else if (props.onTarget) {
                  props.onTarget()
                } else {
                  mutateAsync(props.id)
                }
              }}
              disabled={isLoading}
              loading={isLoading}
            >
              Target
            </Button>
          ) : null}
        </Styled.Buttons>
      ) : null}
    </Styled.Wrapper>
  )
}
