import React from 'react'
import styled from 'styled-components/macro';


type SizeType = 'md' | 'sm';

interface Props {
  size?: SizeType;
  url?: string;
  classes?: string;
}

export const Avatar: React.FC<Props> = ({ size = 'sm', url = '', classes }) => {
  return (
    <UserAvatar url={ url } size={ size } className={classes} />
  )
};

const UserAvatar = styled.div<{ url: string, size?: SizeType }>`
  height: ${props => props.size === 'sm' ? '32px' : '64px'};
  width: ${props => props.size === 'sm' ? '32px' : '64px'};
  min-width: ${props => props.size === 'sm' ? '32px' : '64px'};
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url(${(p) => p.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
