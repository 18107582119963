import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

export const GET_BANNERS_KEY = ['banners']

const getBanners = async (section?: string) => {
  const res = await axios.get<{
    items: { name: string; canBeClosed: boolean }[]
  }>('/banners/v1/banners', { params: { section } })

  return res.data
}

export const useGetBanners = (section: string) => {
  return useQuery({
    queryKey: GET_BANNERS_KEY,
    queryFn: () => getBanners(section),
  })
}
