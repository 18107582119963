import * as React from 'react'
import styled from 'styled-components/macro'

import { path } from '../../../../../libs'
import { Button, Icon } from '../../../../../ui/atoms'
import { AuthLayout, AuthSectionText, AuthSectionTitle } from '../../../layout'
import { proceed } from './model'

export const PasswordRecoverySuccess = () => {
  return (
    <AuthLayout type="register">
      <Section>
        <Logo name="checkCircle" size="large" />
        <AuthSectionTitle level={2}>Password updated</AuthSectionTitle>
        <AuthSectionText>
          Your password has been changed successfully.
        </AuthSectionText>
        <br />
        <DashboardButton
          name="navigate-to-dashboard"
          size="large"
          design="primary"
          onClick={() => proceed(path.dashboard.entry())}
        >
          Go to dashboard
        </DashboardButton>
      </Section>
    </AuthLayout>
  )
}

const Section = styled.div`
  text-align: center;
`

const Logo = styled(Icon)`
  margin: 0 auto;
  margin-bottom: 48px;

  &[data-size='large'] {
    width: 112px;
    height: 112px;
  }
`

const DashboardButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`
