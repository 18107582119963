import styled, { css } from 'styled-components/macro'
import { Button, Title as UiTitle } from 'ui/atoms'

import confetty2Background from '../../images/confetty-2.png'
import confettyBackgroundMobile from '../../images/confetty-mobile.png'
import confettyBackground from '../../images/confetty.png'
import finish from '../../images/finish.png'
import prize from '../../images/prize.png'
import ticket from '../../images/ticket.png'
import { StatisticsTable } from '../statisticsTable'

export const Wrapper = styled.div<{ isWinner: boolean }>`
  margin-bottom: 8px;
  padding: 32px 48px;
  display: grid;
  justify-items: center;
  background-color: var(--color-eucalyptus-200);
  border-radius: 16px;
  background-image: url(${confetty2Background});
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;

  @media (max-width: 599px) {
    margin-top: 16px;
    padding: 32px 24px;
    background-image: url(${confettyBackgroundMobile});
  }

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    margin-bottom: 16px;
    width: 150px;
    height: 150px;
    background-image: url(${finish});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }

  ${({ isWinner }) =>
    isWinner &&
    css`
      background-image: url(${confettyBackground});

      &::before {
        background-image: url(${prize});
      }
    `}
`

export const Title = styled(UiTitle)`
  margin-bottom: 16px;
`

export const Text = styled.div`
  max-width: 520px;
  text-align: center;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
`

export const Socials = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;

  img {
    width: 40px;
    height: auto;
  }
`

export const Statistics = styled.div`
  margin-top: 24px;
  padding: 40px 48px;
  width: 100%;
  display: grid;
  place-items: center;
  border-radius: 16px;
  background-color: var(--color-gray-200);

  @media (max-width: 599px) {
    padding: 24px;
  }
`

export const Ticket = styled.div`
  width: 94px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  text-align: center;
  background-image: url(${ticket});
  background-size: 94px auto;
  background-position: center center;

  @media (max-width: 599px) {
    font-size: 20px;
    line-height: 32px;
  }
`

export const WatchButton = styled(Button)`
  margin-top: 40px;
  min-width: 308px;

  @media (max-width: 599px) {
    margin-top: 32px;
    min-width: auto;
  }
`

export const SmallTitle = styled.div`
  margin-bottom: 8px;
  color: var(--color-gray-600);
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
`

export const Table = styled(StatisticsTable)`
  margin-bottom: 40px;

  @media (max-width: 599px) {
    margin-bottom: 23px;
  }
`

export const RaffleDate = styled(Title)`
  margin-top: 8px;
`

export const PrizesWrapper = styled.div`
  display: grid;
  grid-template-columns: 212px 204px;
  gap: 40px;

  @media (max-width: 599px) {
    grid-template-columns: 1fr;
    gap: 8px;
  }
`

export const Prize = styled.div`
  padding: 4px 12px 8px;
  min-height: 192px;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: 599px) {
    min-height: auto;
  }
`

export const PrizeTitle = styled(Title)`
  margin-bottom: 8px;
`

export const PrizeImage = styled.img`
  width: 180px;
  height: auto;
  max-height: 140px;
  object-fit: contain;
  object-position: center center;
`

export const CashValue = styled.div`
  margin: auto;
  padding: 0 32px;
  min-width: 104px;
  height: 56px;
  display: grid;
  place-items: center;
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  border-radius: 4px;
  background-image: radial-gradient(
    50.08% 64.29% at 50% 50%,
    #d1ede3 0%,
    #85d8b9 100%
  );
`

